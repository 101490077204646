

import "./MonthlyBSPScopeCompletedTable.css"
import { useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { Person } from "../../../FirebaseModel/Person";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { useEffect, useState } from "react";
import { Project } from "../../../FirebaseModel/Project";
import { Client } from "../../../FirebaseModel/Client";
import { MonthTotal } from "../../../FirebaseModel/MonthTotal";
import MonthlyBSPHeader from "../MonthlyBSPHeader/MonthlyBSPHeader";
import MonthlyBSPRow from "../MonthlyBSPRow/MonthlyBSPRow";
import { prettyNum } from "../../../Utils/formatting";
import DownloadableTable from "../../DownloadableTable/DownloadableTable";
interface MonthlyBSPScopeCompletedTableProps{
	month:number,
	year:number,
	monthTotal:MonthTotal,
}



export default function MonthlyBSPScopeCompletedTable(props:MonthlyBSPScopeCompletedTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const roles = useSelector((state:{roles:Role[]}) => state.roles)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const projects = useSelector((state:{projects:Project[]}) => state.projects)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)


	const [num0PercentCompleted, setNum0PercentCompleted] = useState(0)
	const [num1PercentCompleted, setNum1PercentCompleted] = useState(0)
	const [num2To9PercentCompleted, setNum2To9PercentCompleted] = useState(0)
	const [num10To99PercentCompleted, setNum10To99PercentCompleted] = useState(0)
	const [num100PercentCompleted, setNum100PercentCompleted] = useState(0)

	useEffect(() => {
		let num0PercentCompleted = 0
		let num1PercentCompleted = 0
		let num2To9PercentCompleted = 0
		let num10To99PercentCompleted = 0
		let num100PercentCompleted = 0
		props.monthTotal.bspProjects.forEach((bspProject) => {
			if(bspProject.lastDayPercentComplete ==0){
				num0PercentCompleted++
			}else{
				if(bspProject.lastDayPercentComplete <0.02){
					num1PercentCompleted++
				}else{
					if(bspProject.lastDayPercentComplete <0.1){
						num2To9PercentCompleted++
					}else{
						if(bspProject.lastDayPercentComplete <1){
							num10To99PercentCompleted++
						}else{
							num100PercentCompleted++
						}
					}
				}
			}
		})
		setNum0PercentCompleted(num0PercentCompleted)
		setNum1PercentCompleted(num1PercentCompleted)
		setNum2To9PercentCompleted(num2To9PercentCompleted)
		setNum10To99PercentCompleted(num10To99PercentCompleted)
		setNum100PercentCompleted(num100PercentCompleted)

	}, [props.monthTotal,props.monthTotal.bspProjects])



  return (
		<div className={`MonthlyBSPScopeCompletedTableContainer`}>
			<DownloadableTable className={`MonthlyBSPScopeCompletedTable`} fileName="Monthly BSP Scope Complete">
				<thead>
					<tr>
						<th>% Scope Completed</th>
						<th>Quantity</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>0% Completed</td>
						<td>{prettyNum(num0PercentCompleted)}</td>
					</tr>
					<tr>
						<td>1% Completed</td>
						<td>{prettyNum(num1PercentCompleted)}</td>
					</tr>
					<tr>
						<td>2-9% Completed</td>
						<td>{prettyNum(num2To9PercentCompleted)}</td>
					</tr>
					<tr>
						<td>10-99% Completed</td>
						<td>{prettyNum(num10To99PercentCompleted)}</td>
					</tr>
					<tr className="total">
						<td>Total Incomplete</td>
						<td>{prettyNum(num0PercentCompleted+num1PercentCompleted+num2To9PercentCompleted+num10To99PercentCompleted)}</td>
					</tr>
					<tr>
						<td>100% Completed</td>
						<td>{prettyNum(num100PercentCompleted)}</td>
					</tr>

				</tbody>
			</DownloadableTable>
		</div>
  )
}