
import React, { useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ProjectPortalHeader.css"
import { useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role, rolesExistAM } from "../../../FirebaseModel/Role";
import { padDecimal, prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowDown19, faArrowUp, faArrowUp19, faChevronDown, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { View } from "../../../FirebaseModel/View";

export enum ProjectPortalHeaderType{
	type1,
	type2,
	grandTotal
}

interface ProjectPortalHeaderProps{
	columns:string[],
	type:ProjectPortalHeaderType

	sfClosedWon?:number;
	budget?:number;
	hours?:number;
	hoursByRole?:any;
	fixedCosts?:number;
	extraMargin?:number;

	onSortYearAscending?:(ascending:boolean)=>void;
	onSortMonthAscending?:(ascending:boolean)=>void;
	onSelectAllProjects?:(selected:boolean)=>void;
}

export default function ProjectPortalHeader(props:ProjectPortalHeaderProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)

	const [sortYearAscending, setSortYearAscending] = useState<boolean>(true);
	const [sortMonthAscending, setSortMonthAscending] = useState<boolean>(true);


	const type1HeaderForColumn = (column:string, index:number) =>{
		switch(column){
			case "Month Closed Won":
				return <th className="monthClosedWon" key={`type1_${index}`}>Month Closed Won</th>;
			case "Year Closed Won":
				return <th className="yearClosedWon"key={`type1_${index}`}>Year Closed Won</th>;
			case "Project Status":
				return <th className="projectStatus"key={`type1_${index}`}>Project Status</th>;
			case "Client":
				return <th className="client"key={`type1_${index}`}>Client</th>;
			case "Project #":
				return <th className="projectNumber"key={`type1_${index}`}>Project #</th>;
			case "Project Name":
				return <th className="projectName"key={`type1_${index}`}>Project Name</th>;
			case "Account Manager":
				return <th className="accountManager"key={`type1_${index}`}>Account Manager</th>
			case "Project Manager":
				return <th className="accountManager"key={`type1_${index}`}>Project Manager</th>
			case "Regional Sales Manager":
				return <th className="rsm"key={`type1_${index}`}>Regional Sales Manager</th>
			case "SF Closed Won":
				return <th className="sfClosedWon"key={`type1_${index}`}>SF Closed Won</th>;
			case `${organization && (organization.shortName??"")} Production Budget`:
				return <th className="budget" key={`type1_${index}`}>{organization && (<>{organization.shortName??""}</>)} Production Budget</th>;
			case "Total Hours":
				return <th className="totalHours"key={`type1_${index}`}>Total Hours</th>;
			case "Fixed Costs":
				return <th className="fixedCosts" key={`type1_${index}`}>Fixed Costs</th>;
			case "Extra Margin":
				return <th className="extraMargin" key={`type1_${index}`}>Extra Margin</th>;
			case "Comments":
				return <th className="comments" key={`type1_${index}`}>Comments</th>;
			case "Project Type":
				return <th className="projectType" key={`type1_${index}`}>Project Type</th>;
			case "Signed Contract?":
				return <th className="signedContract" key={`type1_${index}`}>Signed Contract?</th>;
			case "% of Budget Used":
				return <th className="budgetUsed" key={`type1_${index}`}>% of Budget Used</th>;
			case "PM Estimated % Complete":
				return <th className="pmEstimatedComplete" key={`type1_${index}`}>PM Estimated % Complete</th>;
			case "AM % to Invoice":
				return <th className="amPercentToInvoice" key={`type1_${index}`}>AM % to Invoice</th>;
			case "Latest Activity":
				return <th className="latestActivity" key={`type1_${index}`}>Latest Activity</th>;
			case "Current +/- based on % complete":
				return <th className="currentPlusMinus" key={`type1_${index}`}>Current +/- based on % complete</th>;
			case "AM Notes":
				return <th className="amNotes" key={`type1_${index}`}>AM Notes</th>;
			case "PM Notes":
				return <th className="pmNotes" key={`type1_${index}`}>PM Notes</th>;
			case "Admin Notes":
				return <th className="adminNotes" key={`type1_${index}`}>Admin Notes</th>;
			case "Difference Actual to Billed":
				return <th className="differenceActualToBilled" key={`type1_${index}`}>Difference Actual to Billed</th>;
			case "Total Invoiced":
				return <th className="totalInvoiced" key={`type1_${index}`}>Total Invoiced</th>;
			case "Invoices Collected":
				return <th className="invoicesCollected" key={`type1_${index}`}>Invoices Collected</th>;
			case "Invoices Outstanding":
				return <th className="invoicesOutstanding" key={`type1_${index}`}>Invoices Outstanding</th>;
		}

		for(var i in roles){
			let role = roles[i];
			if(role.name === column){
				return <th className="role" key={`type1_${index}`}>{role.name}</th>;
			}
		}
		console.log("unfound column 1",column);
		return <></>
	}


	const type2HeaderForColumn = (column:string, index:number) =>{
		switch(column){
			case "Month Closed Won":
				return <th className={`monthClosedWon ${sortMonthAscending?"ascending":"descending"}`} key={`type2_${index}`}
				onClick={() =>{
					let newSortMonthAscending = !sortMonthAscending;
					if(props.onSortMonthAscending){
						props.onSortMonthAscending(newSortMonthAscending);
					}
					setSortMonthAscending(newSortMonthAscending);
				}}>MM <FontAwesomeIcon icon={sortMonthAscending?faArrowUp:faArrowDown}></FontAwesomeIcon></th>
			case "Year Closed Won":
				return <th className={`yearClosedWon ${sortYearAscending?"ascending":"descending"}`} key={`type2_${index}`}
				onClick={() =>{
					let newSortYearAscending = !sortYearAscending;
					if(props.onSortYearAscending){
						props.onSortYearAscending(newSortYearAscending);
					}
					setSortYearAscending(newSortYearAscending);
				}}>YY <FontAwesomeIcon icon={sortYearAscending?faArrowUp:faArrowDown}></FontAwesomeIcon></th>
			case "Project Status":
				return <th className="projectStatus" key={`type2_${index}`}>Status</th>;
			case "Client":
				return <th className="client" key={`type2_${index}`}>Client</th>;
			case "Project #":
				return <th className="projectNumber" key={`type2_${index}`}>##</th>;
			case "Project Name":
				return <th className="projectName" key={`type2_${index}`}>Name</th>;
			case "Account Manager":
				return <th className="accountManager" key={`type2_${index}`}>Account Manager</th>
			case "Regional Sales Manager":
				return <th className="rsm" key={`type2_${index}`}>Regional Sales Manager</th>
			case "Project Manager":
				return <th className="rsm" key={`type2_${index}`}>Project Manager</th>
			case "SF Closed Won":
				return <th className="sfClosedWon" key={`type2_${index}`}>SF Closed Won</th>;
			case `${organization && (organization.shortName??"")} Production Budget`:
				return <th className="budget" key={`type2_${index}`}>Budget</th>;
			case "Total Hours":
				return <th className="totalHours" key={`type2_${index}`}>Total Hours</th>;
			case "Fixed Costs":
				return <th className="fixedCosts" key={`type2_${index}`}>FC</th>;
			case "Extra Margin":
				return <th className="extraMargin" key={`type2_${index}`}>EM</th>;
			case "Comments":
				return <th className="comments" key={`type2_${index}`}>Comments</th>;
			case "Project Type":
				return <th className="projectType" key={`type2_${index}`}>Type</th>;
			case "Signed Contract?":
				return <th className="signedContract" key={`type2_${index}`}>Signed?</th>;
			case "% of Budget Used":
				return <th className="budgetUsed" key={`type2_${index}`}>% Used</th>;
			case "PM Estimated % Complete":
				return <th className="pmEstimatedComplete" key={`type2_${index}`}>PM % Complete</th>;
			case "AM % to Invoice":
				return <th className="amPercentToInvoice" key={`type2_${index}`}>AM % to Invoice</th>;
			case "Latest Activity":
				return <th className="latestActivity" key={`type2_${index}`}>Latest Activity</th>;
			case "Current +/- based on % complete":
				return <th className="currentPlusMinus" key={`type2_${index}`}>Current +/-</th>;
			case "AM Notes":
				return <th className="amNotes" key={`type2_${index}`}>AM Notes</th>;
			case "PM Notes":
				return <th className="pmNotes" key={`type2_${index}`}>PM Notes</th>;
			case "Admin Notes":
				return <th className="adminNotes" key={`type2_${index}`}>Admin Notes</th>;
			case "Difference Actual to Billed":
				return <th className="differenceActualToBilled" key={`type2_${index}`}>Diff Actual to Billed</th>;
			case "Total Invoiced":
				return <th className="totalInvoiced" key={`type2_${index}`}>Total Invoiced</th>;
			case "Invoices Collected":
				return <th className="invoicesCollected" key={`type2_${index}`}>Invoices Collected</th>;
			case "Invoices Outstanding":
				return <th className="invoicesOutstanding" key={`type2_${index}`}>Invoices Outstanding</th>;
		}

		for(var i in roles){
			let role = roles[i];
			if(role.name === column){
				return <th className="role" key={`type2_${index}`}>{role.abbreviation}</th>;
			}
		}
		console.log("unfound column 2",column);
		return <></>
	}

	const grandTotalHeaderForColumn = (column:string, index:number) =>{
		let firstColumnStuff = index == 0?<>
			<input type='checkbox' onChange={(e) =>{
				if(props.onSelectAllProjects){
					props.onSelectAllProjects(e.target.checked);
				}
			}}></input><br/>Select All</>:<></>

		switch(column){
			case "Month Closed Won":
				return <th>{firstColumnStuff}</th>
			case "Year Closed Won":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>
			case "Project Status":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>
			case "Client":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>
			case "Project #":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>
			case "Project Name":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>
			case "Account Manager":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>
			case "Project Manager":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>
			case "Regional Sales Manager":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>
			case "SF Closed Won":
				return <th className="money" key={`grand_total_column_${index}`}>{firstColumnStuff}{padDecimal(prettyCurrency(props.sfClosedWon??0),2)}</th>;
			case `${organization && (organization.shortName??"")} Production Budget`:
				return <th className="money" key={`grand_total_column_${index}`}>{firstColumnStuff}{padDecimal(prettyCurrency(props.budget??0),2)}</th>;
			case "Total Hours":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}{prettyNum(props.hours??0)}</th>;
			case "Fixed Costs":
				return <th className="money" key={`grand_total_column_${index}`}>{firstColumnStuff}{padDecimal(prettyCurrency(props.fixedCosts??0),2)}</th>;
			case "Extra Margin":
				return <th className="money" key={`grand_total_column_${index}`}>{firstColumnStuff}{padDecimal(prettyCurrency(props.extraMargin??0),2)}</th>;
			case "Comments":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
			case "Project Type":
				return <th className="projectType" key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
			case "Signed Contract?":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
			case "% of Budget Used":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
			case "PM Estimated % Complete":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
			case "AM % to Invoice":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
			case "Latest Activity":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
			case "Current +/- based on % complete":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
			case "AM Notes":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
			case "PM Notes":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
			case "Admin Notes":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
			case "Difference Actual to Billed":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
			case "Total Invoiced":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
			case "Invoices Collected":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
			case "Invoices Outstanding":
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}</th>;
		}

		for(var i in roles){
			let role = roles[i];
			if(role.name === column){
				return <th key={`grand_total_column_${index}`}>{firstColumnStuff}{padDecimal(prettyNum(props.hoursByRole[role.id]),2)}</th>
			}
		}
		console.log("unfound column 3",column);
		return <></>
	}




  return (
		<>
		{props.type==ProjectPortalHeaderType.type1 && (
			<tr className={`ProjectPortalHeader type1`}>
				{props.columns.map((column:string, index:number) =>{
					return type1HeaderForColumn(column, index);
				})}
			</tr>
		)}

		{props.type==ProjectPortalHeaderType.type2 && (
			<tr className={`ProjectPortalHeader type2`}>
				{props.columns.map((column:string, index:number) =>{
					return type2HeaderForColumn(column, index);
				})}
			</tr>
		)}

		{props.type==ProjectPortalHeaderType.grandTotal && (
			<tr className={`ProjectPortalHeader grandTotal`}>
				{props.columns.map((column:string, index:number) =>{
					return grandTotalHeaderForColumn(column, index);
				})}
			</tr>
		)}
		</>
  )
}