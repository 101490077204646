

import "./SoldVSBilledTableRow.css"
import { useSelector } from "react-redux";
import { Role, rolePersonsDescription, rolePersonsWeightDescription, rolePersonsWeightDescription2 } from "../../../FirebaseModel/Role";
import { Organization } from "../../../FirebaseModel/Organization";
import { useEffect, useState } from "react";
import { Project } from "../../../FirebaseModel/Project";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { Person } from "../../../FirebaseModel/Person";
import { SoldVSBilledTableRowState } from "../SoldVSBilledTable/SoldVSBilledTable";
import { forceDecimal, prettyCurrency, prettyNum } from "../../../Utils/formatting";
interface SoldVSBilledTableRowProps{
	role:Role,
	isLastRow?:boolean,
	weeksPerYear:number,
	reportState:SoldVSBilledTableRowState,
	startDate:Date,
	endDate:Date,
	onResourcesHaveChange:(roleID:string, resourcesHave:number)=>void,
	onBSPPerWeekChange:(roleID:string, bspWeek:number)=>void,
}

export default function SoldVSBilledTableRow(props:SoldVSBilledTableRowProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)


	// bshSold:number,
	// bshSoldPercent:number,
	// actualHrs:number,
	// actualHrsPercent:number,
	// estVsAct:number,
	// budgetBsh:number,
	// bshMo:number,
	// capacityMo:number,
	// dollarMo:number,
	// annualized:number,
	// neededResources:number,
	// resourcesHave:number,
	// difference:number,
	// bspWeek:number,
	// avgAct:number,
	// bspCapacityYr:number,
	// resources:number,
	// roleWeight:number,
	// weightedNum:number,

	const [bshSold, setBshSold] = useState(props.reportState.bshSoldByRole[props.role.id] || 0);
	const [bshSoldPercent, setBshSoldPercent] = useState(props.reportState.bshSoldByRole[props.role.id] / props.reportState.total1.bshSold || 0);
	const [actualHrs, setActualHrs] = useState(props.reportState.actualHrsByRole[props.role.id] || 0);
	const [actualHrsPercent, setActualHrsPercent] = useState(props.reportState.actualHrsByRole[props.role.id] / props.reportState.total1.actualHrs || 0);
	let tempEstVsAct =  props.reportState.actualHrsByRole[props.role.id]!=0?(props.reportState.bshSoldByRole[props.role.id] / props.reportState.actualHrsByRole[props.role.id] || 0):0;
	const [estVsAct, setEstVsAct] = useState(tempEstVsAct);
	const [budgetBsh, setBudgetBsh] = useState(props.reportState.budgetBshByRole[props.role.id] || 0);
	const [bshMo, setBshMo] = useState(props.reportState.bshSoldByRole[props.role.id] / props.reportState.months || 0);
	let capacity = props.reportState.resourcesHaveByRole[props.role.id] * props.reportState.bspWeekByRole[props.role.id] * props.reportState.months / 12 * 52;
	const [capacityMo, setCapacityMo] = useState(capacity);
	const [dollarMo, setDollarMo] = useState(organization.hourlyRate * (props.reportState.bshSoldByRole[props.role.id] / props.reportState.months || 0));
	const [annualized, setAnnualized] = useState(12 * (organization.hourlyRate * (props.reportState.bshSoldByRole[props.role.id] / props.reportState.months || 0)));
	let resourceNeeded = (props.reportState.budgetBshByRole[props.role.id] / props.reportState.months || 0) * 12 / 52 / props.reportState.bspWeekByRole[props.role.id];
	const [neededResources, setNeededResources] = useState(resourceNeeded);
	const [resourcesHave, setResourcesHave] = useState(props.reportState.resourcesHaveByRole[props.role.id] || 0);
	const [difference, setDifference] = useState(resourceNeeded - (props.reportState.resourcesHaveByRole[props.role.id] || 0));
	const [bspWeek, setBSPWeek] = useState(props.reportState.bspWeekByRole[props.role.id] || 0);
	let tempAvgAct = props.reportState.resourcesHaveByRole[props.role.id] > 0 ?(props.reportState.actualHrsByRole[props.role.id] / props.reportState.months || 0)*12/52 / (props.reportState.resourcesHaveByRole[props.role.id] || 0):0;
	const [avgAct, setAvgAct] = useState(tempAvgAct);
	let bspCap = (props.reportState.resourcesHaveByRole[props.role.id] || 0) * props.reportState.bspWeekByRole[props.role.id] * props.weeksPerYear * organization.hourlyRate;
	const [bspCapacityYr, setBSPCapacityYr] = useState(bspCap);


	useEffect(() =>{
		if(props.reportState){
			setBshSold(props.reportState.bshSoldByRole[props.role.id] || 0);
			setBshSoldPercent(props.reportState.bshSoldByRole[props.role.id] / props.reportState.total1.bshSold || 0);
			setActualHrs(props.reportState.actualHrsByRole[props.role.id] || 0);
			setActualHrsPercent(props.reportState.actualHrsByRole[props.role.id] / props.reportState.total1.actualHrs || 0);
			let tempEstVsAct =  props.reportState.actualHrsByRole[props.role.id]!=0?(props.reportState.bshSoldByRole[props.role.id] / props.reportState.actualHrsByRole[props.role.id] || 0):0;
			setEstVsAct(tempEstVsAct);
			setBudgetBsh(props.reportState.budgetBshByRole[props.role.id] || 0);
			setBshMo(props.reportState.bshSoldByRole[props.role.id] / props.reportState.months || 0);
			let capacity = props.reportState.resourcesHaveByRole[props.role.id] * props.reportState.bspWeekByRole[props.role.id] * organization.hourlyRate / 12 * 52;
			setCapacityMo(capacity);
			setDollarMo(organization.hourlyRate * (props.reportState.bshSoldByRole[props.role.id] / props.reportState.months || 0));
			setAnnualized(12 * (organization.hourlyRate * (props.reportState.bshSoldByRole[props.role.id] / props.reportState.months || 0)));
			let resourceNeeded = (props.reportState.bshSoldByRole[props.role.id] / props.reportState.months || 0) * 12 / 52 / props.reportState.bspWeekByRole[props.role.id];
			setNeededResources(resourceNeeded);
			setResourcesHave(props.reportState.resourcesHaveByRole[props.role.id] || 0);
			setDifference(resourceNeeded - (props.reportState.resourcesHaveByRole[props.role.id] || 0));
			setBSPWeek(props.reportState.bspWeekByRole[props.role.id] || 0);
			let tempAvgAct = props.reportState.resourcesHaveByRole[props.role.id] > 0 ?(props.reportState.actualHrsByRole[props.role.id] / props.reportState.months || 0)*12/52 / (props.reportState.resourcesHaveByRole[props.role.id] || 0):0;
			setAvgAct(tempAvgAct);
			let bspCap = (props.reportState.resourcesHaveByRole[props.role.id] || 0) * props.reportState.bspWeekByRole[props.role.id] * props.weeksPerYear * organization.hourlyRate;
			setBSPCapacityYr(bspCap);
		}
	},[props.reportState])

  return (
    <tr className={`SoldVSBilledTableRow ${props.isLastRow&&"last"}`} key={`role_${props.role.id}`}>
			<td>{props.role.name}</td>
			<td>{prettyNum(bshSold)}</td>
			<td>{Math.round(bshSoldPercent*1000)/10}%</td>
			<td>{prettyNum(actualHrs)}</td>
			<td>{Math.round(actualHrsPercent*1000)/10}%</td>
			<td>{Math.round(estVsAct*1000)/10}%</td>
			<td>{prettyCurrency(budgetBsh)}</td>
			<td>{prettyNum(bshMo)}</td>
			<td>{prettyCurrency(capacityMo)}</td>
			<td>{prettyCurrency(dollarMo)}</td>
			<td>{prettyCurrency(annualized)}</td>
			<td>{Math.round(neededResources*100)/100}</td>
			<td>
				<input type='text' 
					defaultValue={resourcesHave} 
					onChange={(e)=>{
						setResourcesHave(parseFloat(e.target.value)); 
						props.onResourcesHaveChange(props.role.id, parseFloat(e.target.value))
					}}
					onKeyPress={(event)=>{
						forceDecimal(event);
					}}/>

			</td>
			<td>{Math.round(difference*100)/100}</td>
			<td>
				<input type='text' 
					value={bspWeek} 
					onChange={(e)=>{
						setBSPWeek(parseFloat(e.target.value)); 
						props.onBSPPerWeekChange(props.role.id, parseFloat(e.target.value))
					}}
					onKeyPress={(event)=>{
						forceDecimal(event);
					}}/>
			</td>
			<td>{Math.round(avgAct*100)/100}</td>
			<td>{prettyCurrency(bspCapacityYr)}</td>
			<td>{rolePersonsWeightDescription2(props.role,persons,props.startDate,props.endDate)}</td>
			{/* <td>Role Weight</td>
			<td>Weighted #</td> */}
		</tr>
  )
}