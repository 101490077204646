
import React, { useEffect, useState } from "react"
import "./OrganizationYearHolidaysTable.css"
import { useDispatch, useSelector } from "react-redux";
import { Organization, OrganizationHoliday } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import OrganizationScreenRolesRow, { OrganizationScreenRolesRowProps } from "../OrganizationScreenRolesRow/OrganizationScreenRolesRow";
import DraggableTableBody from "../../DraggableTableBody/DraggableTableBody";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { saveOrganizationAttributeChange } from "../../../Utils/SaveOrganizationFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Department } from "../../../FirebaseModel/Department";
import OrganizationScreenDepartmentsRow from "../OrganizationScreenDepartmentsRow/OrganizationScreenDepartmentsRow";

import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { shortDate } from "../../../Utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface OrganizationYearHolidaysTableProps{
}




export default function OrganizationYearHolidaysTable(props:OrganizationYearHolidaysTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)

	const allDepartments = useSelector((state:{departments:Department[]}) => state.departments)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setDepartments:ActionCreators.setDepartments,
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	
	const [holidaysThisYear, setHolidaysThisYear] = useState<OrganizationHoliday[]>([]);

	const [year, setYear] = useState<number>(0);

	useEffect(() =>{
		let today = new Date();
		let year = today.getFullYear();
		setYear(year);	
		let holidaysThisYear = organization.holidaysByYear[year]??[];
		holidaysThisYear.sort((a,b) => {
			let dateA = new Date(a.date);
			let dateB = new Date(b.date);
			if(dateA < dateB){
				return -1;
			}else if(dateA > dateB){
				return 1;
			}else{
				return 0;
			}
		});
		setHolidaysThisYear(holidaysThisYear);
	},[organization]);
	
  return (
    <table 
			className={`OrganizationYearHolidaysTable`}>
			<thead>
				<tr>
					<th>Date</th>
					<th>Description</th>

				</tr>
			</thead>
			<tbody>
				{holidaysThisYear.map((holiday, index) =>{
					// row for editable description and date. use DatePicker for date
					return <tr key={`${index}`}>
						<td>
			        <DatePicker 
			          selected={(new Date(holiday.date))}
								className="date"
			          onChange={(date:Date) =>{
			            if(date){
										let dateString = shortDate(date);
										let newHolidaysThisYear = [...holidaysThisYear];
										newHolidaysThisYear[index].date = dateString;
										let newHolidaysByYear = {...organization.holidaysByYear};
										newHolidaysByYear[year][index].date = dateString;
										saveOrganizationAttributeChange(organization, "holidaysByYear", newHolidaysByYear, entry =>{
											AC.submitUndoStackEntry(entry);
										});
			            }
			          }}></DatePicker>
						</td>
						<td>
							<input
								type="text"
								className="description"
								value={holiday.description}
								onChange={(e) =>{
									let newHolidaysThisYear = [...holidaysThisYear];
									newHolidaysThisYear[index].description = e.target.value;
									let newHolidaysByYear = {...organization.holidaysByYear};
									newHolidaysByYear[year][index].description = e.target.value;
									saveOrganizationAttributeChange(organization, "holidaysByYear", newHolidaysByYear, entry =>{
										AC.submitUndoStackEntry(entry);
									});
								}}></input>

								<div className="buttonDelete" onClick={() =>{
									let newHolidaysThisYear = [...holidaysThisYear];
									newHolidaysThisYear.splice(index,1);
									let newHolidaysByYear = {...organization.holidaysByYear};
									newHolidaysByYear[year].splice(index,1);
									saveOrganizationAttributeChange(organization, "holidaysByYear", newHolidaysByYear, entry =>{
										AC.submitUndoStackEntry(entry);
									});
								}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon> </div>
						</td>

					</tr>
				})}
			</tbody>
    </table>
  )
}