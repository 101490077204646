


import React, { useEffect, useState } from "react"
import { sharedFirebaseAuth, sharedFirestore } from "../../Utils/SharedFirebase"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where } from "firebase/firestore";
import "./LoginScreen.css"
import { Project } from "../../FirebaseModel/Project";
import { useDispatch, useSelector } from "react-redux";
import { browserLocalPersistence, browserSessionPersistence, createUserWithEmailAndPassword, inMemoryPersistence, sendPasswordResetEmail, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import { listUserOrganizations } from "../../Utils/LoginFunctions";
import { Organization } from "../../FirebaseModel/Organization";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { send } from "process";
interface LoginScreenProps {
}

export default function LoginScreen(props: LoginScreenProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setOrganizationID: ActionCreators.setOrganizationID
	}, dispatch);

	const [registering, setRegistering] = useState(false);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [error, setError] = useState("");

	const register = () => {
		if(password != confirmPassword){
			setError("Passwords do not match");
			return;
		}
		if(email == ""){
			setError("Email cannot be blank");
			return;
		}
		if(password == ""){
			setError("Password cannot be blank");
			return;
		}

		createUserWithEmailAndPassword(sharedFirebaseAuth, email, password)
			.then((userCredential) => {
				// Signed in 
				const user = userCredential.user;
				// ...
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
			});
	}

	const login = () => {
		setPersistence(sharedFirebaseAuth, browserLocalPersistence)
			.then(() => {
				signInWithEmailAndPassword(sharedFirebaseAuth, email, password)
					.then((userCredential) => {
						// Signed in 
						const user = userCredential.user;
						// ...
					})
					.catch((error) => {
						const errorCode = error.code;
						const errorMessage = error.message;
						setError(errorMessage);
					});
			})
	}


	return (
		<div className="screen LoginScreen">
			{registering ? (
				<>
					{error != "" && (
						<div className="error">{error}</div>
					)}
					<h1>Register</h1>
					<form onSubmit={(e) => { e.preventDefault(); register(); }}>
						<input type="text" placeholder="Email" autoComplete="email" onChange={(event) => { setEmail(event.target.value) }} />
						<input type="password" placeholder="Password" autoComplete="password" onChange={(event) => { setPassword(event.target.value) }} />
						<input type="password" placeholder="Confirm Password" autoComplete="confirm-password" onChange={(event) => { setConfirmPassword(event.target.value) }} />
						<div className="button" onClick={() => {
							register();
						}}>Register</div>
					</form>
					<p className="register">
						Already have an account? <span onClick={() => { setRegistering(false) }}>Login</span>
					</p>
				</>
			) : (
				<>
					{error != "" && (
						<div className="error">{error}</div>
					)}
					<h1>Login</h1>
					<form onSubmit={(e) => { e.preventDefault(); login(); }}>
						<input type="text" placeholder="Email" autoComplete="email" onChange={(event) => { setEmail(event.target.value) }} />
						<input type="password" placeholder="Password" autoComplete="password" onChange={(event) => { setPassword(event.target.value) }} />
						<div className="button" onClick={() => {
							login();
						}}>Login</div>
					</form>
					<p className="register">
						Don't have an account? <span onClick={() => { setRegistering(true) }}>Register</span><br />
						<span onClick={() => {
							sendPasswordResetEmail(sharedFirebaseAuth, email).then(() => {
								alert("Email sent");
							});
						}}>Forgot Password?</span>
					</p>
				</>
			)}
		</div>
	)
}