
import "./ProjectProfitabilityTableTotalRow.css"
import { Project } from "../../../FirebaseModel/Project";
import { prettyCurrency } from "../../../Utils/formatting";
interface ProjectProfitabilityTableTotalRowProps{
	className?:string,
	label:string,
	sfClosedWon:number;
	profit:number;
	profitMarginPercent:number;
	markupPercent:number;

}

export default function ProjectProfitabilityTableTotalRow(props:ProjectProfitabilityTableTotalRowProps){


  return (
		<tr className={`ProjectProfitabilityTableTotalRow ${props.className??""}`}>
			<td>{props.label}</td>
			<td></td>
			<td></td>
			<td>{prettyCurrency(props.sfClosedWon!)}</td>
			<td></td>
			<td></td>
			<td>{prettyCurrency(props.profit!)}</td>
			<td>{Math.round(props.profitMarginPercent!*100)}%</td>
			<td>{Math.round(props.markupPercent!*100)}%</td>
		</tr>
    
  )
}