

import React, { useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./MonthlyBSPHeader.css"
import { useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { ReactNode } from "react";
import { MonthTotal } from "../../../FirebaseModel/MonthTotal";
import { Tooltip } from "@mui/material";


interface MonthlyBSPHeaderProps{
	month: number,
	year: number,
	monthTotal:MonthTotal
	onPullFromLastMonth:() => void
	onPushToNextMonth:() => void
}

export default function MonthlyBSPHeader(props:MonthlyBSPHeaderProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)

	let lastMonth = new Date(props.year, props.month-1, 1);
	lastMonth.setDate(lastMonth.getDate() - 1);

	let lastDayOfThisMonth = new Date(props.year, props.month, 0);
	lastDayOfThisMonth.setDate(lastDayOfThisMonth.getDate() - 1);

	let monthAbbrevs = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


  return (
		<tr className={`MonthlyBSPHeader`}>
			<th>Client</th>
			<th>Project #</th>
			<th>Project Name</th>
			<th>PM</th>
			<th>SF Closed Won</th>
			<th>
				<div className="float">
					<Tooltip title="Pull % complete from last month">
						<div className="pullFromLastMonth" onClick={props.onPullFromLastMonth}>Pull</div>
					</Tooltip>
				</div>
				% Complete ({monthAbbrevs[lastMonth.getMonth()]}, {lastMonth.getFullYear()})
			</th>
			<th>
				<div className="float">
					<Tooltip title="Push % complete to next month">
						<div className="pushToNextMonth" onClick={props.onPushToNextMonth}>Push</div>
					</Tooltip>
				</div>
				% Complete ({monthAbbrevs[props.month-1]}, {props.year})
			</th>
			<th>% Scope Completed</th>
			<th>$ Scope Remaining (Day 1)</th>
			<th>$ Scope Remaining (Day {lastDayOfThisMonth.getDate()})</th>
			<th>$ Scope Produced</th>
		</tr>
  )
}