
import React, { ReactNode, useEffect, useState } from "react"
import { sharedFirebaseAuth, sharedFirestore } from "../../Utils/SharedFirebase"
import { addDoc, collection, deleteDoc, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where } from "firebase/firestore";
import "./ProjectPortalScreen.css"
import PopupAddProject from "../../Popups/PopupAddProject/PopupAddProject";
import ProjectPortalTable from "../../Components/ProjectPortal/ProjectPortalTable/ProjectPortalTable";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../FirebaseModel/Organization";
import addFakeData from "../../Utils/FakeData";
import { Project } from "../../FirebaseModel/Project";
import PopupAddPerson from "../../Popups/PopupAddPerson/PopupAddPerson";
import PopupAddClient from "../../Popups/PopupAddClient/PopupAddClient";
import { saveProjectAttributeChange, saveProjectPlannedHoursChange } from "../../Utils/SaveProjectFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faExplosion, faGear, faGears, faPencil, faPeopleGroup, faPlus, faRocket, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { Screens } from "../../Redux/Navigation";
import NavigationButtons from "../../Components/NavigationButtons/NavigationButtons";
import { UndoStackEntry } from "../../Utils/UndoStackEntry";
import ProjectProfitabilityTable from "../../Components/ProjectProfitability/ProjectProfitabilityTable/ProjectProfitabilityTable";
import { MonthTotal } from "../../FirebaseModel/MonthTotal";
import { YearTotal } from "../../FirebaseModel/YearTotal";
import { ProjectRoleActualHours } from "../../FirebaseModel/ProjectRoleActualHours";
import { Role, rolesExistAM, rolesExistPM, rolesExistRSM } from "../../FirebaseModel/Role";
import { View } from "../../FirebaseModel/View";
import ViewDropdown from "../../Components/ViewDropdown/ViewDropdown";
import PopupAddView from "../../Popups/PopupAddView/PopupAddView";
import { Person } from "../../FirebaseModel/Person";
import { Tooltip } from "@mui/material";
import { Client } from "../../FirebaseModel/Client";
import ProjectTypeDropdown from "../../Components/ProjectTypeDropdown/ProjectTypeDropdown";
interface ProjectPortalScreenProps {
}


let slowSearchTimeout: NodeJS.Timeout | null = null;

export default function ProjectPortalScreen(props: ProjectPortalScreenProps) {


	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		submitUndoStackEntry: ActionCreators.submitUndoStackEntry
	}, dispatch);

	const inputSearchRef = React.createRef<HTMLInputElement>();

	const [showingProjectPopup, setShowingProjectPopup] = useState(false)
	const [showingClientPopup, setShowingClientPopup] = useState(false)
	const [showingPersonPopup, setShowingPersonPopup] = useState(false)
	const [showingViewPopup, setShowingViewPopup] = useState(false)
	let organization = useSelector((state: { organization: Organization | null }) => state.organization)
	let clients = useSelector((state: { clients: Client[] }) => state.clients)
	let permissionsWrite = useSelector((state: { permissionsWrite: string[] }) => state.permissionsWrite)
	const organizationID = useSelector((state: { organizationID: string }) => state.organizationID)
	const roles = useSelector((state: { roles: Role[] }) => state.roles)
	const views = useSelector((state: { views: View[] }) => state.views)
	const persons = useSelector((state: { persons: Person[] }) => state.persons)
	const person = persons.find((person: Person) => person.email.toLowerCase() == sharedFirebaseAuth.currentUser!.email!.toLowerCase())!;

	const projects = useSelector((state: { projects: Project[] }) => state.projects)
	const monthTotals = useSelector((state: { monthTotals: MonthTotal[] }) => state.monthTotals)
	const yearTotals = useSelector((state: { yearTotals: YearTotal[] }) => state.yearTotals)
	const actuals = useSelector((state: { actuals: ProjectRoleActualHours[] }) => state.actuals)

	const [selectedView, setSelectedView] = useState<View | null>(views.find((view: View) => view.id == localStorage.getItem("projectPortalSelectedView")) || null);
	useEffect(() => {
		localStorage.setItem("projectPortalSelectedView", selectedView?.id || "");
	}, [selectedView]);

	const [selectedProject, setSelectedProject] = useState<Project | null>(null);
	const [maxMonths, setMaxMonths] = useState<number>(12);

	const projectPortalColumns = [
		"Month Closed Won",
		"Year Closed Won",
		"Project Status",
		"Client",
		"Project #",
		"Project Name",
		rolesExistAM(roles) ? "Account Manager" : "",
		rolesExistPM(roles) ? "Project Manager" : "",
		rolesExistRSM(roles) ? "Regional Sales Manager" : "",
		"SF Closed Won",
		`${organization && (organization.shortName ?? "")} Production Budget`,
		"Total Hours",
		...organization?.roleIDOrder.map((roleID: string) => {
			for (var i in roles) {
				let role = roles[i];
				if (role.id === roleID && role.isBillable) {
					return role.name;
				}
			}
			return "";
		}) || [],
		"Fixed Costs",
		"Extra Margin",
		"Comments",
		"Project Type",
		"Signed Contract?",
		"% of Budget Used",
		"PM Estimated % Complete",
		"AM % to Invoice",
		"Latest Activity",
		"Current +/- based on % complete",
		"AM Notes",
		"PM Notes",
		"Admin Notes",
		"Difference Actual to Billed",
		"Total Invoiced",
		"Invoices Collected",
		"Invoices Outstanding",
	]
	while (projectPortalColumns.includes("")) {
		projectPortalColumns.splice(projectPortalColumns.indexOf(""), 1);
	}

	const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);

	const [filterFlag, setFilterFlag] = useState<string>(localStorage.getItem("projectPortalFilterFlag") || "");
	useEffect(() => {
		localStorage.setItem("projectPortalFilterFlag", filterFlag);
	}, [filterFlag]);

	const [filterStatus, setFilterStatus] = useState<string>(localStorage.getItem("projectPortalFilterStatus") || "");
	useEffect(() => {
		localStorage.setItem("projectPortalFilterStatus", filterStatus);
	}, [filterStatus]);

	const [filterEndYear, setFilterEndYear] = useState<string>(localStorage.getItem("projectPortalFilterEndYear") || "");
	useEffect(() => {
		localStorage.setItem("projectPortalFilterEndYear", filterEndYear);
	}, [filterEndYear]);

	const [filterStartYear, setFilterStartYear] = useState<string>(localStorage.getItem("projectPortalFilterStartYear") || "");
	useEffect(() => {
		localStorage.setItem("projectPortalFilterStartYear", filterStartYear);
	}, [filterStartYear]);
	const [filterStartMonth, setFilterStartMonth] = useState<string>(localStorage.getItem("projectPortalFilterStartMonth") || "");
	useEffect(() => {
		localStorage.setItem("projectPortalFilterStartMonth", filterStartMonth);
	}, [filterStartMonth]);

	const [filterEndMonth, setFilterEndMonth] = useState<string>(localStorage.getItem("projectPortalFilterEndMonth") || "");
	useEffect(() => {
		localStorage.setItem("projectPortalFilterEndMonth", filterEndMonth);
	}, [filterEndMonth]);

	const [filterBillable, setFilterBillable] = useState<string>(localStorage.getItem("projectPortalFilterBillable") || "");
	useEffect(() => {
		localStorage.setItem("projectPortalFilterBillable", filterSearch);
	}, [filterBillable]);

	const [filterSearch, setFilterSearch] = useState<string>(localStorage.getItem("projectPortalFilterSearch") || "");
	useEffect(() => {
		localStorage.setItem("projectPortalFilterSearch", filterSearch);
	}, [filterSearch]);

	const [filterProjectType, setFilterProjectType] = useState<string>(localStorage.getItem("projectPortalFilterProjectType") || "");
	useEffect(() => {
		localStorage.setItem("projectPortalFilterProjectType", filterProjectType);
	}, [filterProjectType]);

	const [tableOffsetTop, setTableOffsetTop] = useState<number>(0);
	const tableRef = React.createRef<HTMLDivElement>();
	const resize = () => {
		setTableOffsetTop(tableRef.current!.offsetTop);
	}
	useEffect(() => {
		window.addEventListener("resize", resize);
		resize();
		return () => {
			window.removeEventListener("resize", resize);
		}
	}, [])


	useEffect(() => {
		let table = tableRef.current!;
		if (table) {
			const overrideWheel = (e: WheelEvent) => {
				if (table.scrollLeft === 0 && e.deltaX < 0) {
					e.preventDefault();
				}
			};
			table.addEventListener("wheel", overrideWheel);
			return () => {
				table.removeEventListener("wheel", overrideWheel);
			};
		}
	}, [tableRef]);

	return (
		<>
			<div className="screen ProjectPortalScreen">

				<h1>Project Portal</h1>

				{permissionsWrite.includes("Projects") && (
					<Tooltip title="Create a new project">
						<div className="buttonAdd" onClick={() => {
							setSelectedProject(null);
							setShowingProjectPopup(true);
						}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Project</div>
					</Tooltip>
				)}

				{(permissionsWrite.includes("Projects") && selectedProjects.length > 0) && (
					<div className="buttonNuke" onClick={() => {
						if (window.confirm(`You are about to delete ${selectedProjects.length} projects, are you sure?`)) {
							if (window.confirm(`This is irreversible, are you super duper sure?`)) {
								let nukeEmAll = async () => {
									for (var i in selectedProjects) {
										deleteDoc(doc(sharedFirestore, "Project", selectedProjects[i].id));
									}
								}
								nukeEmAll();

								let recalculateProjectTotalPlannedBours = () => {

									projects.forEach((project: Project) => {
										if (project.calculated.plannedHours == 0) {
											saveProjectPlannedHoursChange(project, "", roles, organization!, clients, 0, (event) => {
												console.log("boop");
												//AC.submitUndoStackEntry(event)
											})
										}
									});
								}
							}
						}
					}}><FontAwesomeIcon icon={faRocket}></FontAwesomeIcon> Delete {selectedProjects.length} Projects! <FontAwesomeIcon icon={faExplosion}></FontAwesomeIcon></div>
				)}


				<NavigationButtons />

				<div className="viewSelector">
					<h3>View</h3>
					<div className="row">
						<Tooltip title="Control which columns show up below">
							<span>
								<ViewDropdown
									defaultValue={selectedView ? selectedView.id : ""}
									prompt="All Columns"
									viewType="projectPortal"
									columns={projectPortalColumns}
									onChange={(viewID: string) => {
										setSelectedView(views.find((view: View) => view.id == viewID) || null);
										tableRef.current!.scrollTop = 0;
										setMaxMonths(12);
									}}
									onCreateNew={() => {
										setSelectedView(null);
										setShowingViewPopup(true);
									}}></ViewDropdown>
							</span>
						</Tooltip>
						{selectedView && selectedView.creatorPersonID == person.id && (
							<>
								<Tooltip title="Edit View">
									<div className="buttonEditView" onClick={() => {
										setShowingViewPopup(true);
									}}><FontAwesomeIcon icon={faPencil}></FontAwesomeIcon></div>
								</Tooltip>
								<Tooltip title="Delete View">
									<div className="buttonDeleteView" onClick={() => {
										if (window.confirm(`Are you sure you want to delete this view?`)) {
											deleteDoc(doc(sharedFirestore, "View", selectedView.id)).then(() => {
												setSelectedView(null);
											});
										}
									}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
								</Tooltip>
							</>
						)}
					</div>
				</div>

				<div className="filters">
					<h3>Filter</h3>
					<div className="row">
						<div className="col">
							<div className="label">Project Status Flag</div>
							<select value={filterFlag}
								onChange={(event) => {
									setFilterFlag(event.target.value);
									tableRef.current!.scrollTop = 0;
									setMaxMonths(12);
								}}>
								<option value="">All</option>
								<option value="bidding">Bidding</option>
								<option value="sold">Sold</option>
								<option value="active">Active</option>
								<option value="completed">Completed</option>
							</select>
						</div>
						<div className='col'>
							<div className="label">Project Status</div>
							<select className={`status`}
								value={filterStatus}
								onChange={(event) => {
									setFilterStatus(event.target.value)
									tableRef.current!.scrollTop = 0;
									setMaxMonths(12);
								}}>
								<option value="">All</option>
								{organization?.projectStatusList.map((option) => {
									return <option value={option}>{option}</option>
								})}
							</select>
						</div>
						<div className="col">
							<div className="label">Start Month</div>
							<select value={filterStartMonth}
								onChange={(event) => {
									setFilterStartMonth(event.target.value)
									// if(filterStartYear == ""){
									// 	setFilterStartYear(new Date().getFullYear().toString());
									// }
									// if(filterEndMonth != "" && parseInt(filterEndMonth) < parseInt(event.target.value) &&
									// 	(filterEndYear == "" || parseInt(filterEndYear) <= parseInt(filterStartYear))){
									// 	setFilterEndMonth("")
									// }
								}}>
								<option value="">--</option>
								<option value="1">January</option>
								<option value="2">February</option>
								<option value="3">March</option>
								<option value="4">April</option>
								<option value="5">May</option>
								<option value="6">June</option>
								<option value="7">July</option>
								<option value="8">August</option>
								<option value="9">September</option>
								<option value="10">October</option>
								<option value="11">November</option>
								<option value="12">December</option>
							</select>
						</div>
						<div className="col">
							<div className="label">Start Year</div>
							<select value={filterStartYear}
								onChange={(event) => {
									setFilterStartYear(event.target.value)
									tableRef.current!.scrollTop = 0;
									setMaxMonths(12);
								}}>
								<option value="">----</option>
								{(() => {
									let years = [];
									let currentYear = new Date().getFullYear();
									for (let i = currentYear; i > currentYear - 30; i--) {
										years.push(<option key={`year_${i}`} value={i}>{i}</option>)
									}
									return years;
								})()}
							</select>
						</div>
						<div className="col">
							<div className="label">End Month</div>
							<select value={filterEndMonth}
								onChange={(event) => {
									setFilterEndMonth(event.target.value)
									// if(filterEndYear == ""){
									// 	setFilterEndYear(new Date().getFullYear().toString());
									// }
									// if(filterStartMonth != "" && parseInt(filterStartMonth) > parseInt(event.target.value) &&
									// 	(filterEndYear == "" || parseInt(filterEndYear) >= parseInt(filterStartYear))){
									// 	setFilterEndMonth("")
									// }
								}}>
								<option value="">--</option>
								<option value="1">January</option>
								<option value="2">February</option>
								<option value="3">March</option>
								<option value="4">April</option>
								<option value="5">May</option>
								<option value="6">June</option>
								<option value="7">July</option>
								<option value="8">August</option>
								<option value="9">September</option>
								<option value="10">October</option>
								<option value="11">November</option>
								<option value="12">December</option>
							</select>
						</div>
						<div className="col">
							<div className="label">End Year</div>
							<select value={filterEndYear}
								onChange={(event) => {
									setFilterEndYear(event.target.value)
									tableRef.current!.scrollTop = 0;
									setMaxMonths(12);

								}}>
								<option value="">----</option>
								{(() => {
									let years = [];
									let currentYear = new Date().getFullYear();
									for (let i = currentYear; i > currentYear - 30; i--) {
										years.push(<option key={`year_${i}`} value={i}>{i}</option>)
									}
									return years;
								})()}
							</select>
						</div>
						<div className="col">
							<div className="label">Billable</div>
							<select value={filterBillable}
								onChange={(event) => {
									setFilterBillable(event.target.value)
									tableRef.current!.scrollTop = 0;
									setMaxMonths(12);

								}}>
								<option value="">All</option>
								<option value="billable">Billable</option>
								<option value="non-billable">Non-Billable</option>
							</select>
						</div>
						<div className="col">
							<div className="label">Project Type</div>
							<ProjectTypeDropdown
								prompt="All"
								defaultValue={filterProjectType}
								onChange={value => {
									setFilterProjectType(value);
									tableRef.current!.scrollTop = 0;
									setMaxMonths(12);
								}}></ProjectTypeDropdown>
						</div>
						<div className="col">
							<div className="label">search</div>
							<div className="row">
								<input type='text'
									defaultValue={filterSearch}
									ref={inputSearchRef}
									className="search"
									onChange={(event) => {
										if (slowSearchTimeout != null) {
											clearTimeout(slowSearchTimeout);
										}
										slowSearchTimeout = setTimeout(() => {
											setFilterSearch(event.target.value)
											tableRef.current!.scrollTop = 0;
											setMaxMonths(12);
										}, 500);
									}}>
								</input>

								<div className='searchIcon'>
									<FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="label">Clear Filters</div>
							<Tooltip title="Clear Filters">
								<div className='buttonClearFilters'
									onClick={() => {
										setFilterFlag("");
										setFilterStatus("");
										setFilterStartMonth("");
										setFilterStartYear("");
										setFilterEndMonth("");
										setFilterEndYear("");
										setFilterBillable("");
										setFilterProjectType("");
										setFilterSearch("");
										inputSearchRef.current!.value = "";
										// scroll tableref to top
										tableRef.current!.scrollTop = 0;
										setMaxMonths(12);
									}}>
									Clear
								</div>
							</Tooltip>
						</div>
					</div>
				</div>


				<div className="content"
					ref={tableRef}
					style={{ maxHeight: `calc(100vh - ${tableOffsetTop}px)`, display: (showingClientPopup || showingPersonPopup || showingProjectPopup || showingViewPopup) ? "none" : "block" }}

					onScroll={(event) => {
						// add 12 months to the max months when the user scrolls to the bottom of the table

						if (slowSearchTimeout != null) {
							clearTimeout(slowSearchTimeout);
						}
						slowSearchTimeout = setTimeout(() => {
							let table = event.target as HTMLTableElement;
							let scrollBottom = table.scrollHeight - table.scrollTop - table.clientHeight;
							if (scrollBottom < 10) {
								setMaxMonths(maxMonths + 12);
							}
						}, 500);
					}}>
					<ProjectPortalTable
						filterFlag={filterFlag}
						filterStatus={filterStatus}
						filterStartMonth={filterStartMonth}
						filterEndMonth={filterEndMonth}
						filterStartYear={filterStartYear}
						filterEndYear={filterEndYear}
						filterBillable={filterBillable}
						filterProjectType={filterProjectType}
						filterSearch={filterSearch}
						columns={selectedView ? selectedView.selectedColumns : projectPortalColumns}
						maxMonths={maxMonths}
						onCreateNewClientPressed={(project: Project) => {
							setSelectedProject(project);
							setShowingClientPopup(true);
						}}
						onCreateNewPersonPressed={(project: Project) => {
							setSelectedProject(project);
							setShowingPersonPopup(true);
						}}
						onEditProjectPressed={(project: Project) => {
							setSelectedProject(project);
							setShowingProjectPopup(true);
						}}
						onSelectedProjectsChanged={(projects) => {
							setSelectedProjects(projects);
						}}></ProjectPortalTable>
				</div>

			</div>
			{showingViewPopup && (
				<PopupAddView
					type="projectPortal"
					columns={projectPortalColumns}
					view={selectedView ?? undefined}
					onCancel={() => {
						setShowingViewPopup(false);
					}} onSave={(view: View) => {
						setShowingViewPopup(false);
						let tempView = { ...view }
						setSelectedView(tempView);
						setTimeout(() => {
							setSelectedView(views.find((view: View) => view.id == tempView.id) || null);
							//setSelectedView(tempView);
						}, 100);
					}} />
			)}
			{showingProjectPopup && (
				<PopupAddProject
					project={selectedProject ?? undefined}
					onCancel={() => {
						setSelectedProject(null);
						setShowingProjectPopup(false);
					}} onSave={(project) => {
						setSelectedProject(null);
						setShowingProjectPopup(false);
					}}></PopupAddProject>
			)}
			{showingClientPopup && (
				<PopupAddClient
					onCancel={() => {
						setShowingClientPopup(false);
					}} onSave={(client) => {
						setShowingClientPopup(false);
						if (selectedProject) {
							saveProjectAttributeChange(selectedProject, organization!, clients, "clientID", client.id, (entry: UndoStackEntry) => {
								AC.submitUndoStackEntry(entry);
							})
						}
					}}></PopupAddClient>
			)}
			{showingPersonPopup && (
				<PopupAddPerson
					roleFilter="AMPM"
					onCancel={() => {
						setShowingPersonPopup(false);
					}} onSave={(person) => {
						setShowingPersonPopup(false);
						if (selectedProject) {
							saveProjectAttributeChange(selectedProject, organization!, clients, "accountManagerID", person.id, (entry: UndoStackEntry) => {
								AC.submitUndoStackEntry(entry);
							})
						}
					}}></PopupAddPerson>
			)}
		</>
	)
}