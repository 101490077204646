import React, { useContext, useEffect } from 'react';
import logo from './logo.svg';
import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css';
import './Fonts/style.css';
import './App.css';
import './Screens/Screens.css';
import './Popups/Popups.css';
import './Reports/Reports.css';
import { useDispatch, useSelector } from 'react-redux';
import { Screens } from './Redux/Navigation';
import ProjectPortalScreen from './Screens/ProjectPortalScreen/ProjectPortalScreen';
import OrganizationMonitor from './Utils/OrganizationMonitor';
import OrganizationScreen from './Screens/OrganizationScreen/OrganizationScreen';
import TeamScreen from './Screens/TeamScreen/TeamScreen';
import ClientsScreen from './Screens/ClientsScreen/ClientsScreen';
import ReportPortalScreen from './Screens/ReportPortalScreen/ReportPortalScreen';
import { onAuthStateChanged, reauthenticateWithCredential, signInWithCustomToken } from 'firebase/auth';
import { sharedFirebaseAuth } from './Utils/SharedFirebase';
import LoginScreen from './Screens/LoginScreen/LoginScreen';
import { Organization } from './FirebaseModel/Organization';
import { listUserOrganizations } from './Utils/LoginFunctions';
import { bindActionCreators } from 'redux';
import ActionCreators from './Redux/ActionCreators';
import { Person } from './FirebaseModel/Person';
import TimesheetScreen from './Screens/TimesheetScreen/TimesheetScreen';
import ImportScreen from './Screens/ImportScreen/ImportScreen';
import { AuthContext } from './Auth/AuthContext';
import UndoStackOverlay from './Components/UndoStackOverlay/UndoStackOverlay';
import HomeScreen from './Screens/HomeScreen/HomeScreen';
import IndividualProfitSharingScreen from './Screens/IndividualProfitSharingScreen/IndividualProfitSharingScreen';
import ProjectProfitabilityScreen from './Screens/ProjectProfitabilityScreen/ProjectProfitabilityScreen';
import ProductionScheduleScreen from './Screens/ProductionScheduleScreen/ProductionScheduleScreen';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

let pervUserAny:any = "not a user";

function App() {
  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setOrganizationID:ActionCreators.setOrganizationID,
    setPermissions:ActionCreators.setPermissions,
    setPermissionsWrite:ActionCreators.setPermissionsWrite,
    setScreen:ActionCreators.setScreen},dispatch);  


	const user = useContext(AuthContext);


  const currentScreen = useSelector((state:{screen:Screens}) => state.screen)
  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const permissions = useSelector((state:{permissions:string[]}) => state.permissions)

	const [signedIn, setSignedIn] = React.useState(false);
	const [uid, setUID] = React.useState("");

	const whichScreen = () =>{
		switch(currentScreen){
			case Screens.Home:{
				return <HomeScreen></HomeScreen>
			}
			case Screens.ProjectPortal:{
				return <ProjectPortalScreen></ProjectPortalScreen>
			}
			case Screens.ProjectProfitability:{
				return <ProjectProfitabilityScreen></ProjectProfitabilityScreen>
			}
			case Screens.Organization:{
				return <OrganizationScreen></OrganizationScreen>
			}
			case Screens.Team:{
				return <TeamScreen></TeamScreen>
			}
			case Screens.Clients:{
				return <ClientsScreen></ClientsScreen>
			}
			case Screens.Reports:{
				return <ReportPortalScreen></ReportPortalScreen>
			}
			case Screens.Timesheet:{
				return <TimesheetScreen></TimesheetScreen>
			}
			case Screens.IndividualProfitSharing:{
				return <IndividualProfitSharingScreen></IndividualProfitSharingScreen>
			}
			case Screens.ProductionSchedule:{
				return <ProductionScheduleScreen></ProductionScheduleScreen>
			}			
			case Screens.Import:{
				return <ImportScreen></ImportScreen>
			}
		}
		return <></>
	}

	useEffect(() => {
		if(organizationID != ""){
			// AC.setScreen(Screens.Home);
			// return;
			let changeScreen = false;
			if(currentScreen == Screens.ProjectPortal && permissions.indexOf("Projects") == -1){
				changeScreen = true;
			}
			if(currentScreen == Screens.Organization && permissions.indexOf("Organization") == -1){
				changeScreen = true;
			}
			if(currentScreen == Screens.Team && permissions.indexOf("Team") == -1){
				changeScreen = true;
			}
			if(currentScreen == Screens.Clients && permissions.indexOf("Clients") == -1){
				changeScreen = true;
			}
			if(currentScreen == Screens.Reports && permissions.indexOf("Reports") == -1){
				changeScreen = true;
			}
			if(currentScreen == Screens.Timesheet && permissions.indexOf("Personal Timesheet") == -1){
				changeScreen = true;
			}
			if(changeScreen){
				AC.setScreen(Screens.Home);
				return;
				if(permissions.indexOf("Projects") != -1){
					AC.setScreen(Screens.ProjectPortal);
				}else if(permissions.indexOf("Organization") != -1){
					AC.setScreen(Screens.Organization);
				}else if(permissions.indexOf("Team") != -1){
					AC.setScreen(Screens.Team);
				}else if(permissions.indexOf("Clients") != -1){
					AC.setScreen(Screens.Clients);
				}else if(permissions.indexOf("Reports") != -1){
					AC.setScreen(Screens.Reports);
				}else if(permissions.indexOf("Personal Timesheet") != -1){
					AC.setScreen(Screens.Timesheet);
				}else{
					sharedFirebaseAuth.signOut();
				}
			}
		}
	},[permissions,organizationID])

	useEffect(() => {
		if(user){
			if(organizationID != ""){
				return;
			}
			if(organizationID == ""){
				user.reload().then(()=>{
					listUserOrganizations(user.email!).then((result:{persons:Person[], organizations:Organization[]}) =>{
						
						if(result.organizations.length == 0){
							//setError("You are not a member of any organizations. Please contact your organization administrator to add you to an organization.");
						}else{
							if(result.organizations.length == 1){
								if(result.persons[0].active){
									AC.setPermissions(result.persons[0].permissions??[]);
									AC.setPermissionsWrite(result.persons[0].permissionsWrite??[]);
									AC.setOrganizationID(result.organizations[0].id);
								}else{ // no permissions if inactive.
									AC.setPermissions([]);
									AC.setPermissionsWrite([]);
									AC.setOrganizationID("");
								}
							}else{
								// multiple organizations. show picker.
								//setOrganizations(result.organizations);
								//setPersons(result.persons);
								//setShowOrganizationPicker(true);
							}
						}
					})
				})
			}
		}else{
			AC.setOrganizationID("");
		}
	},[user]);

	function handleGoogleCredentialResponse(response:any){
		console.log(response);
	}

	// useEffect(() => {
	// 	// Global google
	// 	console.log("google object:",google);

	// 	google.accounts.id.initialize({
	// 		client_id: "",
	// 		callback: handleGoogleCredentialResponse,
	// 	})
	// },[]);

	
  return (
    <DndProvider backend={HTML5Backend}>
	    <div className="App">
				{whichScreen()}
				<UndoStackOverlay></UndoStackOverlay>
				{(organizationID == "") ? (
					<>
						{signedIn && (<span>signed in</span>)}
						{organizationID == "" && (<span>organizationID == ""</span>)}
						<LoginScreen></LoginScreen>
					</>
				):(
					<OrganizationMonitor></OrganizationMonitor>
				)}
	    </div>
		</DndProvider>
  );
}

export default App;
