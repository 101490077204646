


import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where } from "firebase/firestore";
import "./ChangeOrderHoursBreakdownByRole.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { forceDecimal, forceNumeric, prettyCurrency, prettyNum } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import { Person } from "../../FirebaseModel/Person";
import { Client } from "../../FirebaseModel/Client";
import { ProjectRoleActualHours } from "../../FirebaseModel/ProjectRoleActualHours";
import { saveProjectAttributeChange } from "../../Utils/SaveProjectFunctions";
import { UndoStackEntry, UndoStackEntryType, UndoStackEntryObjectType } from "../../Utils/UndoStackEntry";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
interface ChangeOrderHoursBreakdownByRoleProps {
	project: Project,
	onUpdated: () => void
}

export default function ChangeOrderHoursBreakdownByRole(props: ChangeOrderHoursBreakdownByRoleProps) {

	const organization = useSelector((state: { organization: Organization }) => state.organization)
	const roles = useSelector((state: { roles: Role[] }) => state.roles)
	const clients = useSelector((state: { clients: Client[] }) => state.clients)
	const actuals = useSelector((state: { actuals: ProjectRoleActualHours[] }) => state.actuals)

	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry: ActionCreators.submitUndoStackEntry
	}, dispatch);

	let completedStatuses: string[] = (organization.projectStatusCategories as any)["completed"] as string[];
	let biddingStatuses: string[] = (organization.projectStatusCategories as any)["bidding"] as string[];

	const [totalHours, setTotalHours] = useState(0);
	const [totalBudget, setTotalBudget] = useState(0);
	const [totalProductionBudget, setTotalProductionBudget] = useState(0);

	const recalculateTotals = () => {
		let totalHours = 0;
		let totalBudget = 0;
		let totalProductionBudget = 0;
		let client = clients.find((client: Client) => client.id == props.project.clientID);
		props.project.plannedHours.forEach((entry) => {
			totalHours += entry.hours;
			let role = roles.find((role) => role.id === entry.roleID);
			if (role && role.isBillable) {
				let hourlyRate = organization.hourlyRate;
				if (client != null && client.useOverrideHourlyRate) {
					hourlyRate = client.overrideHourlyRate;
				}
				if (props.project.overrideHourlyRate) {
					hourlyRate = props.project.overrideHourlyRate;
				}
				totalBudget += entry.hours * hourlyRate;
				totalProductionBudget += entry.hours * hourlyRate;
			}
		});
		totalProductionBudget += props.project.extraMargin;
		totalProductionBudget += props.project.fixedCosts;

		setTotalHours(totalHours);
		setTotalBudget(totalBudget);
		setTotalProductionBudget(totalProductionBudget);
	}

	useEffect(() => {
		recalculateTotals();
	}, [props.project.plannedHours, props.project.extraMargin, props.project.fixedCosts, props.project.overrideHourlyRate, organization.hourlyRate, clients, props.project.clientID])

	const status = props.project.status;

	return (
		<>
			{biddingStatuses.includes(status) == false && (
				<>
					<div className="bottomArea">
						<div className="leftSide">
							<h1>Hours Breakdown By Role (Change order - {props.project.projectNumber})</h1>
							<div className="roles">


								{organization.roleIDOrder.map((roleID: string, index) => {
									let role: Role | null = null;
									for (var i in roles) {
										let tempRole = roles[i];
										if (tempRole.id === roleID) {
											role = tempRole;
											break;
										}
									}
									if (role == null) {
										return <></>
									}

									if (role.isBillable == false) {
										return <></>
									}
									return (
										<div key={`role_input_${role.id}`} className='role'>
											<div className="label">{role.name}</div>
											{completedStatuses.includes(status) == false ? (
												<input
													type='text'
													onKeyPress={(event) => { forceDecimal(event) }}
													onFocus={(event) => {
														let input = event.target as HTMLInputElement
														input.setSelectionRange(0, input.value.length)
													}}
													onBlur={(event) => {
														event.target.value = prettyNum(event.target.value)
														if (event.target.value == "") {
															event.target.value = "0";
														}

														let newPlannedHours: {
															roleID: string,
															hours: number
														}[] = [ ...JSON.parse(JSON.stringify(props.project.plannedHours)) ];

														let oldProject: Project = { ...JSON.parse(JSON.stringify(props.project)) };
														props.project.plannedHours = newPlannedHours;
														let hours = parseFloat(event.target.value.replace("$", "").replace(/,/g, ""))
														let planned = newPlannedHours.find(planned => planned.roleID == role!.id)
														if (planned == null) {
															planned = {
																roleID: role!.id,
																hours: 0
															}
															newPlannedHours.push(planned)
														}

														planned!.hours = hours
														saveProjectAttributeChange(props.project, organization, clients, "plannedHours", newPlannedHours, entry => {
															let undoEntry: UndoStackEntry = {
																type: UndoStackEntryType.create,
																objectType: UndoStackEntryObjectType.project,
																objectID: props.project.id,
																objectBeforeState: { ...oldProject },
																objectAfterState: { ...props.project },
																description: "Update Project"
															}
															AC.submitUndoStackEntry(undoEntry);
															recalculateTotals();
															props.onUpdated();
														})
													}}
													defaultValue={props.project.plannedHours.find(planned => planned.roleID == role!.id)?.hours ?? "0"} ></input>
											) : (
												<>{props.project.plannedHours.find(planned => planned.roleID == role!.id)?.hours ?? "0"}</>
											)}
											{props.project && (
												<div className="usedHours">
													{(
														() => {
															let usedHours = 0;
															let roleActuals = actuals.filter(actual => (actual.roleID == role!.id && actual.projectID == props.project.id), []);
															usedHours = roleActuals.reduce((total, actual) => total + actual.calculated.totalHours, 0)
															return usedHours > 0 ? `Used: ${prettyNum(usedHours)}` : "";
														}
													)()}
												</div>
											)}
										</div>
									)
								})}


								<div className="role">
									<div className="label">Fixed Costs</div>
									{completedStatuses.includes(status) == false ? (
										<>
											<input className={`fixedCosts`}
												defaultValue={prettyCurrency(props.project.fixedCosts)}
												onFocus={(event) => {
													let input = event.target as HTMLInputElement
													input.setSelectionRange(0, input.value.length)
												}}
												onKeyPress={(event) => {
													forceDecimal(event);
												}}
												onBlur={(event) => {
													event.target.value = prettyCurrency(event.target.value);
													let newFixedCosts = parseFloat(event.target.value.replace("$", "").replace(/,/g, ""))

													let oldProject: Project = { ...JSON.parse(JSON.stringify(props.project)) };
													props.project.fixedCosts = newFixedCosts;

													saveProjectAttributeChange(props.project, organization, clients, "fixedCosts", newFixedCosts, entry => {
														let undoEntry: UndoStackEntry = {
															type: UndoStackEntryType.create,
															objectType: UndoStackEntryObjectType.project,
															objectID: props.project.id,
															objectBeforeState: { ...oldProject },
															objectAfterState: { ...props.project },
															description: "Update Project"
														}
														AC.submitUndoStackEntry(undoEntry);
														recalculateTotals();
														props.onUpdated();
													})
												}}></input>
											{props.project && <div className="usedHours"></div>}
										</>
									) : (
										<>{prettyCurrency(props.project.fixedCosts)}<div className="usedHours"></div></>
									)}
								</div>

								<div className="role">
									<div className="label">Extra Margin</div>
									{completedStatuses.includes(status) == false ? (
										<>
											<input className={`extraMargin`}
												defaultValue={prettyCurrency(props.project.extraMargin)}
												onFocus={(event) => {
													let input = event.target as HTMLInputElement
													input.setSelectionRange(0, input.value.length)
												}}
												onKeyPress={(event) => {
													forceDecimal(event);
												}}
												onBlur={(event) => {
													event.target.value = prettyCurrency(event.target.value);
													let newExtraMargin = parseFloat(event.target.value.replace("$", "").replace(/,/g, ""))

													let oldProject: Project = { ...JSON.parse(JSON.stringify(props.project)) };
													props.project.extraMargin = newExtraMargin;

													saveProjectAttributeChange(props.project, organization, clients, "extraMargin", newExtraMargin, entry => {
														let undoEntry: UndoStackEntry = {
															type: UndoStackEntryType.create,
															objectType: UndoStackEntryObjectType.project,
															objectID: props.project.id,
															objectBeforeState: { ...oldProject },
															objectAfterState: { ...props.project },
															description: "Update Project"
														}
														AC.submitUndoStackEntry(undoEntry);
														recalculateTotals();
														props.onUpdated();
													})
												}}></input>
											{props.project && <div className="usedHours"></div>}
										</>
									) : (
										<>{prettyCurrency(props.project.extraMargin)}<div className="usedHours"></div></>
									)}

								</div>

								<div className="role wide">
									<div className="label">Comments</div>

									{completedStatuses.includes(status) == false ? (
										<>
											<input className={`comments`}
												defaultValue={props.project.comments}
												onBlur={(event) => {
													// setComments(event.target.value);

													let oldProject: Project = { ...JSON.parse(JSON.stringify(props.project)) };
													props.project.comments = event.target.value;

													saveProjectAttributeChange(props.project, organization, clients, "comments", event.target.value, entry => {
														let undoEntry: UndoStackEntry = {
															type: UndoStackEntryType.create,
															objectType: UndoStackEntryObjectType.project,
															objectID: props.project.id,
															objectBeforeState: { ...oldProject },
															objectAfterState: { ...props.project },
															description: "Update Project"
														}
														AC.submitUndoStackEntry(undoEntry);
													})
												}}></input>
											{props.project && <div className="usedHours"></div>}
										</>
									) : (
										<>{props.project.comments}<div className="usedHours"></div></>
									)}
								</div>
							</div>
						</div>
						<div className="rightSide">
							<div className="label">Total Hours</div>
							<div className="value">{prettyNum(totalHours)}</div>
							<div className="label">{organization.shortName} Production Budget</div>
							<div className="value">{prettyCurrency(totalBudget)}</div>
							<div className="label">Total Production Budget</div>
							<div className="value">{prettyCurrency(totalProductionBudget)}</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}