


import React, { useEffect, useState } from "react"
import { addDoc, collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./ProjectTasksTaskRow.css"
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Role } from "../../../FirebaseModel/Role";
import { ProjectTask } from "../../../FirebaseModel/Project";
import { forcePercentage, prettyNum, shortDate } from "../../../Utils/formatting";
import PersonDropdown from "../../PersonDropdown/PersonDropdown";
import ProjectTaskTaskPerson from "../ProjectTasksTaskPerson/ProjectTaskTaskPerson";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { savePersonAttributeChange } from "../../../Utils/SavePersonFunctions";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { UndoStackEntryType, UndoStackEntryObjectType } from "../../../Utils/UndoStackEntry";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
interface ProjectTasksTaskRowProps{
	projectRoleTasks:{
		roleID:string,
		tasks:ProjectTask[]
	}[];
	projectID:string,
	roleID:string,
	taskID:string,
	isReadOnly?:boolean,
	className?:string,
	onDeleteTask:(taskID:string) => void
	onUpdateTask:(taskID:string, taskName:string, plannedHours:number, percentComplete:number, persons:string[], dueDate:string) => void
}

export default function ProjectTasksTaskRow(props:ProjectTasksTaskRowProps){
  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	let task:ProjectTask|null = null;
	for(let i in props.projectRoleTasks){
		let projectRoleTask = props.projectRoleTasks[i];
		if(projectRoleTask.roleID === props.roleID){
			task = projectRoleTask.tasks.find((task) => task.id === props.taskID)??null;
		}
	}
	const [taskName, setTaskName] = useState<string>(task?.name??"");
	const [taskPlannedHours, setTaskPlannedHours] = useState<number>(task?.plannedHours??0);
	const [taskPercentComplete, setTaskPercentComplete] = useState<number>(task?.percentComplete??0);
	const [taskPersons, setTaskPersons] = useState<string[]>(task?.assignedPersonIDs??[]);
	const [dueDate, setDueDate] = useState<string>(task?.dueDate??"");
	
  return (
    <tr className={`ProjectTasksTaskRow ${props.className??""}`}>
			<td></td>
			<td>
				<input type='text'
					className="taskName"
					defaultValue={taskName}
					onChange={(event) =>{
						setTaskName(event.target.value);
						props.onUpdateTask(props.taskID, event.target.value, taskPlannedHours, taskPercentComplete, taskPersons,dueDate);
					}}></input>
			</td>
			<td>
				<input type='text'
					defaultValue={prettyNum(taskPlannedHours)}
					className="taskPlannedHours"
					onBlur={(event) =>{
						let taskPlannedHours = parseInt(event.target.value.replace(/,/g, ""));
						setTaskPlannedHours(taskPlannedHours);
						props.onUpdateTask(props.taskID, taskName, taskPlannedHours, taskPercentComplete, taskPersons,dueDate);
					}}
					onKeyPress={(event) => {
						forcePercentage(event);
					}}></input>
			</td>
			<td>
				<input type='text'
					defaultValue={Math.round(taskPercentComplete*100)+"%"}
					className="taskPercentComplete"
					onBlur={(event) =>{
						let percentComplete = parseInt(event.target.value.replace("%", ""))/100;
						event.target.value = Math.round(percentComplete*100)+"%";
						setTaskPercentComplete(percentComplete);
						props.onUpdateTask(props.taskID, taskName, taskPlannedHours, percentComplete, taskPersons,dueDate);
					}}
					onKeyPress={(event) => {
						forcePercentage(event);
					}}></input>
			</td>
			<td>
				<div className="taskPersons">
					{taskPersons.map((personID) => {
						return <ProjectTaskTaskPerson 
							personID={personID} 
							isReadOnly={props.isReadOnly}
							onDelete={(personID) =>{
								let newTaskPersons = taskPersons.filter((taskPersonID) => taskPersonID !== personID);
								setTaskPersons(newTaskPersons);
								props.onUpdateTask(props.taskID, taskName, taskPlannedHours, taskPercentComplete, newTaskPersons,dueDate);
							}}></ProjectTaskTaskPerson>
					})}

				<PersonDropdown
					roleID={props.roleID}
					prompt="Assign Task"
					clearOnChange={true}
					onChange={(personID) =>{
						if(taskPersons.includes(personID)){
							return;
						}
						//create a project role actual for this person if it doesn't exist
						let actual = actuals.find((actual) => actual.roleID === props.roleID && actual.personID === personID && actual.projectID === props.projectID);
						if(!actual){
							let newProjectRoleActualHours = {
								id: "",
								organizationID: organizationID,
								projectID: props.projectID,
								personID: personID,
								year:(new Date()).getFullYear(),
								roleID: props.roleID,
								billable: true,
								order:0,
								hours: [],
								calculated: {
									totalHours: 0
								}
							}
							addDoc(collection(sharedFirestore, "ProjectRoleActualHours"), newProjectRoleActualHours).then((docRef) => {
								newProjectRoleActualHours.id = docRef.id;
								AC.submitUndoStackEntry({
									type: UndoStackEntryType.create,
									objectType: UndoStackEntryObjectType.actual,
									objectID: docRef.id,
									objectBeforeState: undefined,
									objectAfterState: {...newProjectRoleActualHours},
									description: "Add Project Role Actual Hours"
								})
							})
						}

						setTaskPersons([...taskPersons, personID]);
						props.onUpdateTask(props.taskID, taskName, taskPlannedHours, taskPercentComplete, [...taskPersons, personID],dueDate);
					}}></PersonDropdown>
				</div>

				{!props.isReadOnly && (
					<Tooltip title="Delete this task from the project" placement="bottom">
						<div className="buttonDelete" onClick={() => props.onDeleteTask(props.taskID)}>
							<FontAwesomeIcon icon={faTrash} />
						</div>
					</Tooltip>
				)}

			</td>
			<td>
        <DatePicker 
					className={"dueDate"}
					disabled={props.isReadOnly??undefined}
          selected={dueDate!=""?(new Date(dueDate)):null}
          onChange={(date:Date) =>{
            if(date){
							let dateString = shortDate(date);//`${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
							setDueDate(dateString);
							props.onUpdateTask(props.taskID, taskName, taskPlannedHours, taskPercentComplete, taskPersons,dateString);
            }else{
							setDueDate("");
							props.onUpdateTask(props.taskID, taskName, taskPlannedHours, taskPercentComplete, taskPersons,"");
						}
          }}></DatePicker>
			</td>

		</tr>
  )
}