


import React, { useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ProjectPortalProjectRow.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Role, rolesExistAM } from "../../../FirebaseModel/Role";
import { Organization } from "../../../FirebaseModel/Organization";
import { Client } from "../../../FirebaseModel/Client";
import { Person } from "../../../FirebaseModel/Person";
import { padDecimal, prettyCurrency, prettyNum, shortDate } from "../../../Utils/formatting";
import ProjectPortalInput from "../ProjectPortalInput/ProjectPortalInput";
import ClientDropdown from "../../ClientDropdown/ClientDropdown";
import { deleteProject, saveProjectAttributeChange } from "../../../Utils/SaveProjectFunctions";
import PersonDropdown from "../../PersonDropdown/PersonDropdown";
import { faBars, faCheck, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { Tooltip } from "@mui/material";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { UndoStackEntry } from "../../../Utils/UndoStackEntry";
import { View } from "../../../FirebaseModel/View";
import ProjectTypeDropdown from "../../ProjectTypeDropdown/ProjectTypeDropdown";
interface ProjectPortalProjectRowProps{
	columns:string[],
	project:Project,
	projectSelected:boolean,
	onCreateNewClientPressed:(project:Project)=>void,
	onCreateNewPersonPressed:(project:Project)=>void,
	onEditProjectPressed:(project:Project)=>void
	setProjectSelected:(project:Project, projectSelected:boolean)=>void
}

export default function ProjectPortalProjectRow(props:ProjectPortalProjectRowProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);


  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const roles = useSelector((state:{roles:Role[]}) => state.roles)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)

	const [comments, setComments] = useState(props.project.comments);

	const [budget, setBudget] = useState(organization.hourlyRate);

	const [projectComplete, setProjectComplete] = useState(false);

	const [selected, setSelected] = useState(props.projectSelected);
	useEffect(() =>{
		setSelected(props.projectSelected);
	},[props.projectSelected])

	useEffect(() =>{
		let hourlyRate = organization.hourlyRate;
		let client = clients.find((client:Client) => client.id == props.project.clientID);
		if(client !=null && client.useOverrideHourlyRate){
			hourlyRate = client.overrideHourlyRate;
		}
		if(props.project.useOverrideHourlyRate){
			hourlyRate = props.project.overrideHourlyRate;
		}
		let budget = props.project.calculated.plannedHours * hourlyRate;
		// budget += props.project.fixedCosts??0;
		// budget += props.project.extraMargin??0;
		setBudget(budget)
		setComments(props.project.comments)

		let completedStatuses:string[] = (organization.projectStatusCategories as any)["completed"] as string[];
		let projectComplete = completedStatuses.includes(props.project.status);
		setProjectComplete(projectComplete);
				
	},[organization, props.project])




	const buildEditableColunmForColumn = (column:string, index:number) =>{
		let firstColumnStuff = index ==0?<>
			<FontAwesomeIcon icon={faBars} style={{pointerEvents:"none", fontSize:"50%"}}></FontAwesomeIcon>
				<input type='checkbox' checked={selected} onChange={(e) =>{
					props.setProjectSelected(props.project,e.target.checked);
				}}></input></>:<></>

		let lastColumnStuff = index == props.columns.length-1?<>
			<Tooltip title={`Delete`} placement="bottom">
				<div className="buttonDelete"
					onClick={() =>{
						if(window.confirm("Are you sure you'd like to delete this project?")){
							deleteProject(props.project,actuals,(entry:UndoStackEntry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}
					}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
			</Tooltip>
			<Tooltip title={`Edit`} placement="bottom">
				<div className="buttonEdit"
					onClick={() =>{
						props.onEditProjectPressed(props.project);
					}}><FontAwesomeIcon icon={faPencil}></FontAwesomeIcon></div>
			</Tooltip></>:<></>

		switch(column){
			case "Month Closed Won":
				return <td className="monthClosedWon" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}<ProjectPortalInput project={props.project} attributeKey={'monthClosedWon'} forceNumeric={true}></ProjectPortalInput></td>
			case "Year Closed Won":
				return <td className="yearClosedWon" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}<ProjectPortalInput project={props.project} attributeKey={'yearClosedWon'} forceNumeric={true}></ProjectPortalInput></td>
			case "Project Status":
				return <td className="projectStatus" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}
						<select className={`status`}
							defaultValue={props.project.status}
							onChange={(event) => {

								let newStatus = {
									date: shortDate(new Date()),
									status: event.target.value,
								};
								let newStatusHistory = [...props.project.statusHistory];
								newStatusHistory.push(newStatus);
								saveProjectAttributeChange(props.project,organization, clients,["status","statusHistory"],[event.target.value,newStatusHistory],(entry:UndoStackEntry) =>{
									AC.submitUndoStackEntry(entry);
								});

							}}>
							<option value="">Select Status</option>
							{organization.projectStatusList.map((option) => {
								return <option value={option}>{option}</option>
							})}
						</select>
				</td>
			case "Client":
				return <td className="client" key={`${props.project.id}_${index}`}>
					{firstColumnStuff}{lastColumnStuff}
					<ClientDropdown 
						defaultValue={props.project.clientID}
						onChange={(value) =>{
							saveProjectAttributeChange(props.project,organization, clients,"clientID",value,(entry:UndoStackEntry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}}
						onCreateNew={() =>{
							props.onCreateNewClientPressed(props.project);
						}}></ClientDropdown>
				</td>
			case "Project #":
				return <td className="projectNum" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}<ProjectPortalInput project={props.project} attributeKey={'projectNumber'}></ProjectPortalInput></td>
			case "Project Name":
				return <td className="projectName" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}<ProjectPortalInput project={props.project} attributeKey={'projectName'}></ProjectPortalInput></td>
			case "Account Manager":
				return <td className="accountManager" key={`${props.project.id}_${index}`}>
						{firstColumnStuff}{lastColumnStuff}
						<PersonDropdown
							defaultValue={props.project.accountManagerID}
							isAM={true}
							prompt="Select Account Manager"
							onChange={(value) =>{
								saveProjectAttributeChange(props.project,organization, clients,"accountManagerID",value,(entry:UndoStackEntry) =>{
									AC.submitUndoStackEntry(entry);
								});
							}}
							onCreateNew={() =>{
								props.onCreateNewPersonPressed(props.project);
							}}></PersonDropdown>
					</td>
			case "Project Manager":
				return <td className="accountManager" key={`${props.project.id}_${index}`}>
						{firstColumnStuff}{lastColumnStuff}
						<PersonDropdown
							defaultValue={props.project.projectManagerID}
							isAM={true}
							prompt="Select Project Manager"
							onChange={(value) =>{
								saveProjectAttributeChange(props.project,organization,clients,"projectManagerID",value,(entry:UndoStackEntry) =>{
									AC.submitUndoStackEntry(entry);
								});
							}}
							onCreateNew={() =>{
								props.onCreateNewPersonPressed(props.project);
							}}></PersonDropdown>
							</td>
			case "Regional Sales Manager":
				return <td className="rsm" key={`${props.project.id}_${index}`}>
						{firstColumnStuff}{lastColumnStuff}
						<PersonDropdown
							defaultValue={props.project.regionalSalesManagerID}
							isRSM={true}
							prompt="Select RSM"
							onChange={(value) =>{
								saveProjectAttributeChange(props.project,organization,clients,"regionalSalesManagerID",value,(entry:UndoStackEntry) =>{
									AC.submitUndoStackEntry(entry);
								});
							}}
							onCreateNew={() =>{
								props.onCreateNewPersonPressed(props.project);
							}}></PersonDropdown>
					</td>
			case "SF Closed Won":
				return <td className="sfClosedWon" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}<ProjectPortalInput project={props.project} attributeKey={'sfClosedWon'} forceNumeric={true} isCurrency={true}></ProjectPortalInput></td>
			case `${organization && (organization.shortName??"")} Production Budget`:
				return <td className="budget" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}{lastColumnStuff}{prettyCurrency(budget)}</td>
			case "Total Hours":
				return <td className="totalHours" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}{prettyNum(props.project.calculated.plannedHours)}</td>
			case "Fixed Costs":
				return <td className="fixedCosts" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}<ProjectPortalInput project={props.project} attributeKey={'fixedCosts'} forceNumeric={true} isCurrency={true}></ProjectPortalInput></td>
			case "Extra Margin":
				return <td className="extraMargin" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}<ProjectPortalInput project={props.project} attributeKey={'extraMargin'} forceNumeric={true} isCurrency={true}></ProjectPortalInput></td>
			case "Comments":
				return <td className="comments" key={`${props.project.id}_${index}`}>
					{firstColumnStuff}{lastColumnStuff}
					<Tooltip title={comments} placement="bottom">
						<span>{comments}</span>
					</Tooltip>
				</td>
			case "Project Type":
				return <td className="projectType" key={`${props.project.id}_${index}`}>
					{firstColumnStuff}{lastColumnStuff}
					<ProjectTypeDropdown
						defaultValue={props.project.projectType}
						onChange={(value) =>{
							saveProjectAttributeChange(props.project,organization,clients,"projectType",value,(entry:UndoStackEntry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}}></ProjectTypeDropdown>
				</td>
			case "Signed Contract?":
				return <td className="signedContract" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}
					<input type='checkbox'
						defaultChecked={props.project.isContractSigned}
						onChange={(event)=>{
							saveProjectAttributeChange(props.project,organization, clients,"isContractSigned",event.target.checked,(entry:UndoStackEntry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}}></input>
				</td>
			case "% of Budget Used":
				return <td className="percentBudgetUsed" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}
					{(()=>{
						let hourlyRate = organization.hourlyRate;
						let client = clients.find((client:Client) => client.id == props.project.clientID);
						if(client !=null && client.useOverrideHourlyRate){
							hourlyRate = client.overrideHourlyRate;
						}
						if(props.project.useOverrideHourlyRate){
							hourlyRate = props.project.overrideHourlyRate;
						}
						let used = props.project.calculated.actualHours * hourlyRate;
						let percent = 0;
						if(props.project.sfClosedWon > 0){
							percent = used / props.project.sfClosedWon;
						}
						return padDecimal((Math.round(percent*10000)/100).toString(),2)+"%";
					})()}
				</td>
			case "PM Estimated % Complete":
				return <td className="percentComplete" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}
					<ProjectPortalInput project={props.project} attributeKey={'pmEstimatedPercentComplete'} forcePercentage={true}></ProjectPortalInput>
				</td>
			case "AM % to Invoice":
				return <td className="percentToInvoice" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}
					{<ProjectPortalInput project={props.project} attributeKey={'amPercentToInvoice'} forcePercentage={true}></ProjectPortalInput>}
				</td>
			case "Latest Activity":
				return <td className="latestActivity" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}
					{props.project.calculated.latestActivity}
				</td>
			case "Current +/- based on % complete":
				return <td className="currentPlusMinus" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}
					{prettyCurrency(props.project.calculated.currentPlusMinus)}
				</td>
			case "AM Notes":
				return <td className="amNotes" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}
					<ProjectPortalInput project={props.project} attributeKey={'amNotes'}></ProjectPortalInput>
				</td>
			case "PM Notes":
				return <td className="pmNotes" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}
					<ProjectPortalInput project={props.project} attributeKey={'pmNotes'}></ProjectPortalInput>
				</td>
			case "Admin Notes":
				return <td className="adminNotes" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}
					<ProjectPortalInput project={props.project} attributeKey={'adminNotes'}></ProjectPortalInput>
				</td>
			case "Difference Actual to Billed":
				return <td className="differenceActualToBilled" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}
					{prettyCurrency(props.project.calculated.differenceActualToBilled)}
				</td>
			case "Total Invoiced":
				return <td className="totalInvoiced" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}
					{prettyCurrency(props.project.calculated.totalInvoiced)}
				</td>
			case "Invoices Collected":
				return <td className="invoicesCollected" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}
					{prettyCurrency(props.project.calculated.invoicesCollected)}
				</td>
			case "Invoices Outstanding":
				return <td className="invoicesOutstanding" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}
					{prettyCurrency(props.project.calculated.invoicesOutstanding)}
				</td>
		}

		for(var i in roles){
			let role = roles[i];
			if(role.name === column){
				return <td className="roleHours" key={`${props.project.id}_${index}`}>{firstColumnStuff}{lastColumnStuff}<ProjectPortalInput project={props.project} roleID={role.id} forceNumeric={true}></ProjectPortalInput></td>
			}
		}
		console.log("column not found 5 ",column);
		return <></>
	}
				

	const buildReadOnlyColunmForColumn = (column:string, index:number) =>{

		let lastColumnStuff = index == props.columns.length-1?<>
			<Tooltip title={`Edit`} placement="bottom">
				<div className="buttonEdit"
					onClick={() =>{
						props.onEditProjectPressed(props.project);
					}}><FontAwesomeIcon icon={faPencil}></FontAwesomeIcon></div>
			</Tooltip></>:<></>

		switch(column){
			case "Month Closed Won":
				return <td className="monthClosedWon" key={`${props.project.id}_${index}`}>{lastColumnStuff}{props.project.monthClosedWon}</td>
			case "Year Closed Won":
				return <td className="yearClosedWon" key={`${props.project.id}_${index}`}>{lastColumnStuff}{props.project.yearClosedWon}</td>
			case "Project Status":
				return <td className="projectStatus" key={`${props.project.id}_${index}`}>{lastColumnStuff}{props.project.status}</td>
			case "Client":
				let client = clients.find((client:Client) => client.id == props.project.clientID);
				return <td className="client" key={`${props.project.id}_${index}`}>{lastColumnStuff}{client?.name}</td>
			case "Project #":
				return <td className="projectNum" key={`${props.project.id}_${index}`}>{lastColumnStuff}{props.project.projectNumber}</td>
			case "Project Name":
				return <td className="projectName" key={`${props.project.id}_${index}`}>{lastColumnStuff}{props.project.projectName}</td>
			case "Account Manager":
				let person = persons.find((person:Person) => person.id == props.project.accountManagerID);
				return <td className="accountManager" key={`${props.project.id}_${index}`}>{lastColumnStuff}{person?.name}</td>
			case "Regional Sales Manager":
				let person2 = persons.find((person:Person) => person.id == props.project.regionalSalesManagerID);
				return <td className="accountManager" key={`${props.project.id}_${index}`}>{lastColumnStuff}{person2?.name}</td>
			case "Project Manager":
				let person3 = persons.find((person:Person) => person.id == props.project.projectManagerID);
				return <td className="accountManager" key={`${props.project.id}_${index}`}>{lastColumnStuff}{person3?.name}</td>
			case "SF Closed Won":
				return <td className="sfClosedWon" key={`${props.project.id}_${index}`}>{lastColumnStuff}{padDecimal(prettyCurrency(props.project.sfClosedWon??0),2)}</td>
			case `${organization && (organization.shortName??"")} Production Budget`:
				return <td className="budget" key={`${props.project.id}_${index}`}>{lastColumnStuff}{prettyCurrency(budget)}</td>
			case "Total Hours":
				return <td className="totalHours" key={`${props.project.id}_${index}`}>{lastColumnStuff}{prettyNum(props.project.calculated.plannedHours)}</td>
			case "Fixed Costs":
				return <td className="fixedCosts" key={`${props.project.id}_${index}`}>{lastColumnStuff}{padDecimal(prettyCurrency(props.project.fixedCosts??0),2)}</td>
			case "Extra Margin":
				return <td className="extraMargin" key={`${props.project.id}_${index}`}>{lastColumnStuff}{padDecimal(prettyCurrency(props.project.extraMargin??0),2)}</td>
			case "Comments":
				return <td className="comments">
					<Tooltip title={comments} placement="bottom">
						<span>{comments}</span>
					</Tooltip>
				</td>
			case "Project Type":
				return <td className="projectType" key={`${props.project.id}_${index}`}>{lastColumnStuff}{props.project.projectType}</td>
			case "Signed Contract?":
				return <td className="signedContract" key={`${props.project.id}_${index}`}>
					{lastColumnStuff}
					{props.project.isContractSigned && <>
						<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
					</>}
				</td>
			case "% of Budget Used":
				return <td className="percentBudgetUsed" key={`${props.project.id}_${index}`}>
					{lastColumnStuff}
					{(()=>{
						let hourlyRate = organization.hourlyRate;
						let client = clients.find((client:Client) => client.id == props.project.clientID);
						if(client !=null && client.useOverrideHourlyRate){
							hourlyRate = client.overrideHourlyRate;
						}
						if(props.project.useOverrideHourlyRate){
							hourlyRate = props.project.overrideHourlyRate;
						}
						let used = props.project.calculated.actualHours * hourlyRate;
						let percent = 0;
						if(props.project.sfClosedWon > 0){
							percent = used / props.project.sfClosedWon;
						}
						return padDecimal((Math.round(percent*10000)/100).toString(),2)+"%";
					})()}
				</td>
			case "PM Estimated % Complete":
				return <td className="percentComplete" key={`${props.project.id}_${index}`}>
					{lastColumnStuff}
					{padDecimal(prettyNum(props.project.pmEstimatedPercentComplete*100),2)}%
				</td>
			case "AM % to Invoice":
				return <td className="percentToInvoice" key={`${props.project.id}_${index}`}>
					{lastColumnStuff}
					{padDecimal(prettyNum(props.project.amPercentToInvoice*100),2)}%
				</td>
			case "Latest Activity":
				return <td className="latestActivity" key={`${props.project.id}_${index}`}>
					{lastColumnStuff}
					{props.project.calculated.latestActivity}
				</td>
			case "Current +/- based on % complete":
				return <td className="currentPlusMinus" key={`${props.project.id}_${index}`}>
					{lastColumnStuff}
					{prettyCurrency(props.project.calculated.currentPlusMinus)}
				</td>
			case "AM Notes":
				return <td className="amNotes" key={`${props.project.id}_${index}`}>
					{lastColumnStuff}
					{props.project.amNotes}
				</td>
			case "PM Notes":
				return <td className="pmNotes" key={`${props.project.id}_${index}`}>
					{lastColumnStuff}
					{props.project.pmNotes}
				</td>
			case "Admin Notes":
				return <td className="adminNotes" key={`${props.project.id}_${index}`}>
					{lastColumnStuff}
					{props.project.adminNotes}
				</td>
			case "Difference Actual to Billed":
				return <td className="differenceActualToBilled" key={`${props.project.id}_${index}`}>
					{lastColumnStuff}
					{prettyCurrency(props.project.calculated.differenceActualToBilled)}
				</td>
			case "Total Invoiced":
				return <td className="totalInvoiced" key={`${props.project.id}_${index}`}>
					{lastColumnStuff}
					{prettyCurrency(props.project.calculated.totalInvoiced)}
				</td>
			case "Invoices Collected":
				return <td className="invoicesCollected" key={`${props.project.id}_${index}`}>
					{lastColumnStuff}
					{prettyCurrency(props.project.calculated.invoicesCollected)}
				</td>
			case "Invoices Outstanding":
				return <td className="invoicesOutstanding" key={`${props.project.id}_${index}`}>
					{lastColumnStuff}
					{prettyCurrency(props.project.calculated.invoicesOutstanding)}
				</td>
		}

		for(var i in roles){
			let role = roles[i];
			if(role.name === column){

				let hours = props.project.plannedHours.reduce((accumulator,hours) =>(accumulator + (hours.roleID==role.id?hours.hours:0)),0)
				return  <td className="roleHours" key={`${props.project.id}_${index}`}>{padDecimal(prettyNum(hours),2)}</td>
			}
		}
		console.log("column not found 6 ",column);
		return <></>
	}


  return (
		<>
			{(permissionsWrite.includes("Projects") && projectComplete == false) ? (
				<>
					{props.columns.map((column,index) =>{
						return buildEditableColunmForColumn(column,index);
					})}
				</>

			):(
				<>
					{props.columns.map((column,index) =>{
						return buildReadOnlyColunmForColumn(column,index);
					})}
				</>
			)}
		</>
    
  )
}