
import React, { ReactNode, useEffect, useState } from "react"
import "./ProjectProfitabilityTable.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Client } from "../../../FirebaseModel/Client";
import { getOrCreateMonthTotal, saveMonthTotalAttributeChange } from "../../../Utils/SaveMonthTotalFunctions";
import { MonthTotal } from "../../../FirebaseModel/MonthTotal";
import DraggableTableBody from "../../DraggableTableBody/DraggableTableBody";
import { Person } from "../../../FirebaseModel/Person";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Role } from "../../../FirebaseModel/Role";
import ProjectProfitabilityTableHeader, { ProjectProfitabilityTableHeaderType } from "../ProjectProfitabilityTableHeader/ProjectProfitabilityTableHeader";
import ProjectProfitabilityTableTotalRow from "../ProjectProfitabilityTableTotalRow/ProjectProfitabilityTableTotalRow";
import ProjectProfitabilityTableRow from "../ProjectProfitabilityTableRow/ProjectProfitabilityTableRow";
import DownloadableTable from "../../DownloadableTable/DownloadableTable";

interface ProjectProfitabilityTableProps{

	mergeChangeOrders?:boolean,
	filterFlag:string,
	filterStartMonth:string,
	filterEndMonth:string,
	filterStartYear:string,
	filterEndYear:string,
	filterSearch:string,
	filterProjectType:string,

	maxMonths:number,

	onViewProjectDetailsPressed:(group:Project[])=>void

}

interface ProjectProfitabilityMonth{
	month:number,
	year:number,
	sfClosedWon:number,
	totalResourceUsage:number,
	profit:number,
	profitMarginPercent:number,
	markupPercent:number,
	groups:Project[][]
}

interface ProjectProfitabilityYear{
	year:number,
	sfClosedWon:number,
	totalResourceUsage:number,
	profit:number,
	profitMarginPercent:number,
	markupPercent:number
	months:ProjectProfitabilityMonth[]
}

var projectProfitabilityLoadTimeout: NodeJS.Timeout | null = null;
var projectProfitabilityLoadFullTimeout: NodeJS.Timeout | null = null;



export default function ProjectProfitabilityTable(props:ProjectProfitabilityTableProps){

	const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const allMonthTotals = useSelector((state:{monthTotals:MonthTotal[]}) => state.monthTotals)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const roles = useSelector((state:{roles:Role[]}) => state.roles)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)


	const [sortAscending, setSortAscending] = useState<boolean>(true);

	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	const [projectProfitabilityYears, setProjectProfitabilityYears] = useState<ProjectProfitabilityYear[]>([]);
	const [groupedProjects, setGroupedProjects] = useState<Project[][]>([]);

	useEffect(() =>{
		let tempProjects:Project[] = [];
		projects.forEach(proj =>{

			if(props.filterFlag !== ""){
				let statuses:string[] = (organization.projectStatusCategories as any)[props.filterFlag] as string[];
				if(statuses.filter(status => status === proj.status).length === 0){
					return;
				}
			}
			let statusDate = new Date();
			proj.statusHistory.forEach(status => {
				if(status.status === proj.status){
					statusDate = new Date(status.date);
				}
			})
			// if the a filter is not "" then filter the projects based on filters and month and year closed won
			if((props.filterStartYear !== "" && statusDate.getFullYear() < parseInt(props.filterStartYear))){
				return;
			}
			if(props.filterEndYear !== "" && statusDate.getFullYear() > parseInt(props.filterEndYear)){
				return;
			}
			if(props.filterStartMonth !== "" && (statusDate.getMonth()+1) < parseInt(props.filterStartMonth) &&
				(props.filterStartYear === "" || statusDate.getFullYear() < parseInt(props.filterStartYear))){
				return;
			}
			if(props.filterEndMonth !== "" && (statusDate.getMonth()+1) > parseInt(props.filterEndMonth) &&
				(props.filterEndYear === "" || statusDate.getFullYear() > parseInt(props.filterEndYear))){
				return;
			}
			if(props.filterProjectType !== "" && proj.projectType !== props.filterProjectType){
				return;
			}

			if(props.filterSearch !== "" && clients && persons){
				let lowerSearch = props.filterSearch.toLowerCase();
				let projNum = proj.projectNumber.toLowerCase();
				let projName = proj.projectName.toLowerCase();
				let projComments = proj.comments.toLowerCase();
				let client:Client | undefined = clients.find(c => c.id === proj.clientID);
				let AM:Person | undefined = persons.find(p => p.id === proj.accountManagerID);
				if(projNum.indexOf(lowerSearch) !== -1 || 
						projName.indexOf(lowerSearch) !== -1 ||
						projComments.indexOf(lowerSearch) !== -1 ||
						(client && client.name.toLowerCase().indexOf(lowerSearch) !== -1) ||
						(AM && AM.name.toLowerCase().indexOf(lowerSearch) !== -1)){
						tempProjects.push(proj);
				 }
				 return;
			}

			tempProjects.push(proj);
		})

		if(props.mergeChangeOrders){
			projects.forEach(proj =>{
				if(proj.projectNumber.indexOf("-")!=-1){
					let parentProjectNumber = proj.projectNumber.substring(0,proj.projectNumber.indexOf("-"));
					let parentProject = tempProjects.find(p => p.projectNumber === parentProjectNumber);
					if(parentProject){
						if(tempProjects.indexOf(proj) === -1){
							tempProjects.push(proj);
						}
					}
				}
			})
		}

		tempProjects.sort((a,b) =>{
			let dateA:Date|null = null;
			let dateB:Date|null = null;
			for(let i in a.statusHistory){
				let status = a.statusHistory[i];
				if(status.status === a.status){
					dateA = new Date(status.date);
				}
			}
			for(let i in b.statusHistory){
				let status = b.statusHistory[i];
				if(status.status === b.status){
					dateB = new Date(status.date);
				}
			}
			if(dateA != null && dateB != null){
				return (dateA.getTime() - dateB.getTime()) * (sortAscending?1:-1);
			}
			if(dateA == null && dateB != null){
				return (sortAscending?-1:1);
			}
			if(dateA != null && dateB == null){
				return (sortAscending?1:-1);
			}
			return 0;
		})
		if(tempProjects.length > 20){
			//first 100 projects are displayed
			setFilteredProjects(tempProjects.slice(0, 20));
			if(projectProfitabilityLoadFullTimeout){
				clearTimeout(projectProfitabilityLoadFullTimeout);
				projectProfitabilityLoadFullTimeout = null;
			}
			projectProfitabilityLoadFullTimeout =setTimeout(() => {
				console.log("hit htis line");
				setFilteredProjects(tempProjects)
			}, 200);
		}else{
			setFilteredProjects(tempProjects)
		}
	},[projects, props.mergeChangeOrders, props.filterFlag,props.filterStartMonth,props.filterEndMonth,props.filterStartYear,props.filterEndYear, props.filterSearch, clients,persons,sortAscending]);

	useEffect(() =>{
		let tempGroupedProjects:Project[][] = [];
		for(let i in filteredProjects){
			let proj = filteredProjects[i];
			let found = false;
			if(props.mergeChangeOrders){
				for(let g in tempGroupedProjects){
					let group = tempGroupedProjects[g];
					let projectNumber = proj.projectNumber;
					if(projectNumber.indexOf("-")!=-1){
						projectNumber = projectNumber.substring(0,projectNumber.indexOf("-"));
					}
					let otherGroupProjectNumber = group[0].projectNumber;
					if(otherGroupProjectNumber.indexOf("-")!=-1){
						otherGroupProjectNumber = otherGroupProjectNumber.substring(0,otherGroupProjectNumber.indexOf("-"));
					}
					if(otherGroupProjectNumber === projectNumber){
						group.push(proj);
						found=true;
						break;
					}
				}
				if(!found){
					tempGroupedProjects.push([proj]);
				}
			}else{
				tempGroupedProjects.push([proj]);
			}
		}

		tempGroupedProjects.forEach(group =>{
			group.sort((a,b) =>{
				let aProjectNumber = a.projectNumber;
				let bProjectNumber = b.projectNumber;
				if(aProjectNumber.indexOf("-") === -1 && bProjectNumber.indexOf("-") === -1){
					return aProjectNumber.localeCompare(bProjectNumber);
				}
				if(aProjectNumber.indexOf("-") === -1 && bProjectNumber.indexOf("-") !== -1){
					return -1;
				}
				if(aProjectNumber.indexOf("-") !== -1 && bProjectNumber.indexOf("-") === -1){
					return 1;
				}
				
				let aCONumber = aProjectNumber.split("-")[1];
				let bCONumber = bProjectNumber.split("-")[1];
				return parseInt(aCONumber) - parseInt(bCONumber);
			});
		});

		setGroupedProjects(tempGroupedProjects);

	},[filteredProjects]);

	useEffect(() =>{
		let tempProjectProfitabilityYears:ProjectProfitabilityYear[] = [];
		let currentProjectProfitabilityYear:ProjectProfitabilityYear|null = null;
		let currentProjectProfitabilityMonth:ProjectProfitabilityMonth|null = null;

		let totalResourceUsage = 0;
		let totalProfit = 0;
		let totalSFClosedWon = 0;


		for(let i in groupedProjects){
			let group = groupedProjects[i];
			let proj = group[0];
			let statusDate = new Date();
			proj.statusHistory.forEach(status => {
				if(status.status === proj.status){
					statusDate = new Date(status.date);
				}
			})
			let year = statusDate.getFullYear();
			let month = statusDate.getMonth()+1;

			if(currentProjectProfitabilityYear == null || currentProjectProfitabilityYear.year !== year){
				if(currentProjectProfitabilityYear != null){
					currentProjectProfitabilityYear.profitMarginPercent = currentProjectProfitabilityYear.profit / currentProjectProfitabilityYear.sfClosedWon;
					currentProjectProfitabilityYear.markupPercent = (currentProjectProfitabilityYear.sfClosedWon - currentProjectProfitabilityYear.totalResourceUsage) / currentProjectProfitabilityYear.totalResourceUsage;
				}
				currentProjectProfitabilityYear = {
					year:year,
					months:[],
					sfClosedWon:0,
					totalResourceUsage:0,
					profit:0,
					profitMarginPercent:0,
					markupPercent:0
				}
				tempProjectProfitabilityYears.push(currentProjectProfitabilityYear);
			}

			if(currentProjectProfitabilityMonth == null || currentProjectProfitabilityMonth.month !== month){
				if(currentProjectProfitabilityMonth != null){
					currentProjectProfitabilityMonth.profitMarginPercent = currentProjectProfitabilityMonth.profit / currentProjectProfitabilityMonth.sfClosedWon;
					currentProjectProfitabilityMonth.markupPercent = (currentProjectProfitabilityMonth.sfClosedWon - currentProjectProfitabilityMonth.totalResourceUsage) / currentProjectProfitabilityMonth.totalResourceUsage;
				}
				currentProjectProfitabilityMonth = {
					month:month,
					year:year,
					groups:[],
					sfClosedWon:0,
					totalResourceUsage:0,
					profit:0,
					profitMarginPercent:0,
					markupPercent:0
				}
				currentProjectProfitabilityYear.months.push(currentProjectProfitabilityMonth);
			}


			let averageResourceHourlyCost = organization.averageResourceCost / organization.averageWorkWeeksPerYear / organization.targetAverageHoursPerWeek;
			
			// sum of the groups projects calculated actual hours
			let sum = group.reduce((a,b) => a + (b.calculated.actualHours??0), 0);

			let resourceUsage = group.reduce((a,b) => a + (b.calculated.actualHours??0), 0) * averageResourceHourlyCost;
			let profit = group.reduce((a,b) => a + (b.sfClosedWon??0), 0) - resourceUsage - group.reduce((a,b) => a + (b.fixedCosts??0),0);
			let profitMarginPercent = profit / group.reduce((a,b) => a + (b.sfClosedWon??0), 0);
			let markupPercent = (group.reduce((a,b) => a + (b.sfClosedWon??0), 0) - resourceUsage) / resourceUsage;

			currentProjectProfitabilityMonth.groups.push(group);
			currentProjectProfitabilityMonth.sfClosedWon += group.reduce((a,b) => a + (b.sfClosedWon??0), 0);
			currentProjectProfitabilityMonth.profit += profit;
			currentProjectProfitabilityMonth.totalResourceUsage += resourceUsage;

			currentProjectProfitabilityYear.sfClosedWon += group.reduce((a,b) => a + (b.sfClosedWon??0), 0);
			currentProjectProfitabilityYear.profit += profit;
			currentProjectProfitabilityYear.totalResourceUsage += resourceUsage;

			totalResourceUsage += resourceUsage;
			totalProfit += profit;
			totalSFClosedWon += group.reduce((a,b) => a + (b.sfClosedWon??0), 0);


		}
		if(currentProjectProfitabilityYear != null){
			currentProjectProfitabilityYear.profitMarginPercent = currentProjectProfitabilityYear.profit / currentProjectProfitabilityYear.sfClosedWon;
			currentProjectProfitabilityYear.markupPercent = (currentProjectProfitabilityYear.sfClosedWon - currentProjectProfitabilityYear.totalResourceUsage) / currentProjectProfitabilityYear.totalResourceUsage;
		}

		if(currentProjectProfitabilityMonth != null){
			currentProjectProfitabilityMonth.profitMarginPercent = currentProjectProfitabilityMonth.profit / currentProjectProfitabilityMonth.sfClosedWon;
			currentProjectProfitabilityMonth.markupPercent = (currentProjectProfitabilityMonth.sfClosedWon - currentProjectProfitabilityMonth.totalResourceUsage) / currentProjectProfitabilityMonth.totalResourceUsage;
		}

		let profitMarginPercent = totalProfit / totalSFClosedWon;
		let markupPercent = (totalSFClosedWon - totalResourceUsage) / totalResourceUsage;

		setSfClosedWon(totalSFClosedWon);
		setProfit(totalProfit);
		setProfitMarginPercent(profitMarginPercent);
		setMarkupPercent(markupPercent);

		setProjectProfitabilityYears(tempProjectProfitabilityYears);
	},[groupedProjects, props.maxMonths])

	const [sfClosedWon, setSfClosedWon] = useState<number>(0);
	const [profit, setProfit] = useState<number>(0);
	const [totalResourceUsage, setTotalResourceUsage] = useState<number>(0);
	const [profitMarginPercent, setProfitMarginPercent] = useState<number>(0);
	const [markupPercent, setMarkupPercent] = useState<number>(0);

	
  return (
    <DownloadableTable
			fileName="Profitability Table" 
			className={`ProjectProfitabilityTable`} >
				<thead>
					<ProjectProfitabilityTableHeader 
						type={ProjectProfitabilityTableHeaderType.type1}></ProjectProfitabilityTableHeader>
					<ProjectProfitabilityTableHeader
						type={ProjectProfitabilityTableHeaderType.grandTotal}
						sfClosedWon={sfClosedWon}
						profit={profit}
						profitMarginPercent={profitMarginPercent}
						markupPercent={markupPercent}></ProjectProfitabilityTableHeader>
					<ProjectProfitabilityTableHeader 
						type={ProjectProfitabilityTableHeaderType.type2}
						onSortAscending={(ascending) =>{
							setSortAscending(ascending);
						}}></ProjectProfitabilityTableHeader>
				</thead>
				<tbody>
					{projectProfitabilityYears.map((projectProfitabilityYear, index) => {
						if(index * 12 >= props.maxMonths && (projectProfitabilityYears.reduce((a,b) => a + b.months.reduce((a,b) => a + b.groups.length,0), 0) > 50)){
							return <></>
						}
						return <>
							{projectProfitabilityYear.months.map((projectProfitabilityMonth, index) => {
								return <>
									{projectProfitabilityMonth.groups.map((group, index) => {
										let project = group[0];
										return (
											<ProjectProfitabilityTableRow 
												key={`project_${project.id}`}
												mergeChangeOrders={props.mergeChangeOrders}
												group={group} 
												onProjectDetailsPressed={(group) =>{
													props.onViewProjectDetailsPressed(group);
												}}></ProjectProfitabilityTableRow>	
											)
										})}
									<ProjectProfitabilityTableTotalRow
										key={`total_${projectProfitabilityMonth.month}_${projectProfitabilityYear.year}`}
										label={`Totals (${projectProfitabilityMonth.month}/${projectProfitabilityMonth.year})`}
										sfClosedWon={projectProfitabilityMonth.sfClosedWon}
										profit={projectProfitabilityMonth.profit}
										profitMarginPercent={projectProfitabilityMonth.profitMarginPercent}
										markupPercent={projectProfitabilityMonth.markupPercent}></ProjectProfitabilityTableTotalRow>
								</>
							})}
							<ProjectProfitabilityTableTotalRow
								key={`total_${projectProfitabilityYear.year}`}
								label={`Totals (${projectProfitabilityYear.year})`}
								sfClosedWon={projectProfitabilityYear.sfClosedWon}
								profit={projectProfitabilityYear.profit}
								profitMarginPercent={projectProfitabilityYear.profitMarginPercent}
								markupPercent={projectProfitabilityYear.markupPercent}></ProjectProfitabilityTableTotalRow>
						</>
					})}
				</tbody>
    </DownloadableTable>
  )
}