import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./OrganizationScreenDepartmentsInput.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { forceNumeric, prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { saveProjectAttributeChange, saveProjectPlannedHoursChange } from "../../../Utils/SaveProjectFunctions";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Department } from "../../../FirebaseModel/Department";
import { saveDepartmentAttributeChange } from "../../../Utils/SaveDepartmentFunctions";
interface OrganizationScreenDepartmentsInputProps{
	className?:string,
	department:Department,
	attributeKey:string,
	forceNumeric?:boolean,
	isCurrency?:boolean
}

export default function OrganizationScreenDepartmentsInput(props:OrganizationScreenDepartmentsInputProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)

	const [defaultValue, setDefaultValue] = useState("");
	
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	useEffect(() =>{
		if(props.attributeKey){
			if(props.isCurrency){
				setDefaultValue(prettyCurrency((props.department as any)[props.attributeKey] as number))
			}else{
				setDefaultValue((props.department as any)[props.attributeKey])
			}
		}
	},[props.department,props.attributeKey])

  return (
    <input 
			className={`OrganizationScreenDepartmentsInput ${props.className??""} ${props.attributeKey??""}`}
			disabled={props.className=="disabled"}
			onBlur={(event) =>{

				if(props.forceNumeric && event.target.value ==""){
					event.target.value = "0";
				}
				if(props.isCurrency){
					event.target.value = prettyCurrency(event.target.value);
				}

				if(props.forceNumeric){
					saveDepartmentAttributeChange(props.department,props.attributeKey,parseFloat(event.target.value.replace("$","").replace(/,/g,"")),(entry) =>{
						AC.submitUndoStackEntry(entry);
					});
				}else{
					saveDepartmentAttributeChange(props.department,props.attributeKey,event.target.value,(entry) =>{
						AC.submitUndoStackEntry(entry);
					});
				}
			}}
			onFocus={(event)=>{
				if(props.forceNumeric){
					let input = event.target as HTMLInputElement
					input.setSelectionRange(0,input.value.length)
				}
			}}
			onKeyPress={(event) =>{
				if(props.forceNumeric){
					forceNumeric(event);
				}
			}}
			defaultValue={defaultValue}>
    </input>
  )
}