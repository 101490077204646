import React, { useEffect, useState } from "react"
import { sharedFirebaseAuth, sharedFirestore } from "./SharedFirebase"
import { collection, doc, DocumentData, DocumentSnapshot, getDoc, getDocs, onSnapshot, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";
import { Project, ProjectBidWorksheet, ProjectInvoice, ProjectTask } from "../FirebaseModel/Project";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../FirebaseModel/Organization";
import { bindActionCreators } from "redux";
import ActionCreators from "../Redux/ActionCreators";
import { Client } from "../FirebaseModel/Client";
import { Person, PersonNonProjectTask } from "../FirebaseModel/Person";
import { Role } from "../FirebaseModel/Role";
import { TimeOff } from "../FirebaseModel/TimeOff";
import { MonthTotal } from "../FirebaseModel/MonthTotal";
import { ProjectRoleActualHours } from "../FirebaseModel/ProjectRoleActualHours";
import { shortDate } from "./formatting";
import { Department } from "../FirebaseModel/Department";
import { ClientContact } from "../FirebaseModel/ClientContact";
import { View } from "../FirebaseModel/View";
import ProjectMessageBoard from "../FirebaseModel/ProjectMessageBoard";
import { Backup } from "../FirebaseModel/Backup";
import { saveOrganizationAttributeChange } from "./SaveOrganizationFunctions";
interface OrganizationChildrenMonitorProps {
}



export default function OrganizationChildrenMonitor(props: OrganizationChildrenMonitorProps) {

	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setOrganization: ActionCreators.setOrganization,
		setProjects: ActionCreators.setProjects,
		setClients: ActionCreators.setClients,
		setClientContacts: ActionCreators.setClientContacts,
		setPersons: ActionCreators.setPersons,
		setDepartments: ActionCreators.setDepartments,
		setRoles: ActionCreators.setRoles,
		setTimeOffList: ActionCreators.setTimeOffList,
		setMonthTotals: ActionCreators.setMonthTotals,
		setActuals: ActionCreators.setActuals,
		setPermissions: ActionCreators.setPermissions,
		setPermissionsWrite: ActionCreators.setPermissionsWrite,
		setViews: ActionCreators.setViews,
		setProjectMessageBoards: ActionCreators.setProjectMessageBoards,
		setBackups: ActionCreators.setBackups,
	}, dispatch);

	let organization = useSelector((state: { organization: Organization | null }) => state.organization)
	let roles = useSelector((state: { roles: Role[] }) => state.roles)

	useEffect(() => {
		//remove duplicats from organization.roleIDOrder
		if (organization == null) {
			return;
		}
		if(roles == null || roles.length == 0){
			return;
		}
		let roleIDs: string[] = [];
		organization.roleIDOrder.forEach(roleID => {
			if (roleIDs.indexOf(roleID) == -1) {
				roleIDs.push(roleID);
			}
		})

		//add any missing roleIDs to the end of the list
		roles.forEach(role => {
			if (roleIDs.indexOf(role.id) == -1) {
				roleIDs.push(role.id);
			}
		});

		let roleIDOrderChanged = false;
		if (roleIDs.length != organization.roleIDOrder.length) {
			roleIDOrderChanged = true;
		} else {
			for (var i = 0; i < roleIDs.length; i++) {
				if (roleIDs[i] != organization.roleIDOrder[i]) {
					roleIDOrderChanged = true;
					break;
				}
			}
		}
		if(roleIDOrderChanged){
			saveOrganizationAttributeChange(organization, "roleIDOrder", roleIDs, (entry) =>{

			});
		}
	}, [organization,roles]);


	const organizationID = useSelector((state: { organizationID: string }) => state.organizationID)
	const permissions = useSelector((state: { permissions: string[] }) => state.permissions)
	const permissionsWrite = useSelector((state: { permissionsWrite: string[] }) => state.permissionsWrite)
	const actuals = useSelector((state: { actuals: ProjectRoleActualHours[] }) => state.actuals)

	const projectCollection = collection(sharedFirestore, "Project");
	const projectMessageBoardCollection = collection(sharedFirestore, "ProjectMessageBoard");
	const clientCollection = collection(sharedFirestore, "Client");
	const clientContactCollection = collection(sharedFirestore, "ClientContact");
	const personCollection = collection(sharedFirestore, "Person");
	const departmentCollection = collection(sharedFirestore, "Department");
	const roleCollection = collection(sharedFirestore, "Role");
	const timeOffCollection = collection(sharedFirestore, "TimeOff");
	const monthTotalCollection = collection(sharedFirestore, "MonthTotal");
	const projectRoleActualHoursCollection = collection(sharedFirestore, "ProjectRoleActualHours");
	const viewCollection = collection(sharedFirestore, "View");
	const backupCollection = collection(sharedFirestore, "Backup");


	const monthTotalUpdated = (snapshot: QuerySnapshot<DocumentData>) => {
		let tempMonthTotals: MonthTotal[] = [];
		snapshot.forEach(projSnapshot => {
			let proj = projSnapshot.data() as any;
			if (proj.calculated.bsp == null) {
				proj.calculated.bsp = {
					projectsCount: 0,
					projectsStalled: 0,
					projects1Percent: 0,
					projects2to9percent: 0,
					projects10to100percent: 0,
					projectsCompleted: 0,
					totalContractDollars: 0,
					averageProjectValue: 0,
					beginningScope: 0,
					endingScope: 0,
					resourcesHave: 0,
					averageBSPPerResourcePerWeek: 0,
					billableScopeProduced: 0,
					productionBudgetPreviousMonth: 0,
					bspToSalesRatio: 0
				}
			}
			if (proj.bspProjects == null) {
				proj.bspProjects = [] as {
					projectID: string;
					firstDayPercentComplete: number;
					lastDayPercentComplete: number;
				}[]
			}

			tempMonthTotals.push({ ...proj, id: projSnapshot.id } as MonthTotal);
		})

		AC.setMonthTotals(tempMonthTotals);
	}



	const projectListUpdated = (snapshot: QuerySnapshot<DocumentData>) => {
		let tempProjects: Project[] = [];
		snapshot.forEach(projSnapshot => {
			let proj = projSnapshot.data();
			if (proj.status == null) {
				proj.status = "";
			}
			if (proj.statusHistory == null) {
				proj.statusHistory = [] as { date: string, status: string }[];
			}
			if (proj.departmentIDs == null) {
				proj.departmentIDs = [] as string[];
			}
			if (proj.projectClientContacts == null) {
				proj.projectClientContacts = [] as { clientContactID: string, primaryContact: boolean }[];
			}
			if (proj.projectRoleTasks == null) {
				proj.projectRoleTasks = [] as { roleID: string, tasks: ProjectTask[] }[];
			}
			if (proj.fixedCosts == null) {
				proj.fixedCosts = 0;
			}
			if (proj.regionalSalesManagerID == null) {
				proj.regionalSalesManagerID = "";
			}
			if (proj.isContractSigned == null) {
				proj.isContractSigned = false;
			}
			if (proj.pmEstimatedPercentComplete == null) {
				proj.pmEstimatedPercentComplete = 0;
			}
			if (proj.amPercentToInvoice == null) {
				proj.amPercentToInvoice = 0;
			}
			if (proj.pmNotes == null) {
				proj.pmNotes = "";
			}
			if (proj.amNotes == null) {
				proj.amNotes = "";
			}
			if (proj.adminNotes == null) {
				proj.adminNotes = "";
			}
			if (proj.invoices == null) {
				proj.invoices = [] as ProjectInvoice[];
			}
			if (proj.projectType == null) {
				proj.projectType = "";
			}
			if (proj.calculated == null) {
				proj.calculated = {
					actualHours: 0,
					plannedHours: 0,
					differenceActualToBilled: 0,
					latestActivity: "",
					currentPlusMinus: 0,
					totalInvoiced: 0,
					invoicesCollected: 0,
					invoicesOutstanding: 0,
				}
			}
			if(proj.isChangeOrder == null){
				proj.isChangeOrder = proj.projectNumber.indexOf("-") > -1;
				if(proj.isChangeOrder){
					proj.changeOrderParentProjectNumber = proj.projectNumber.split("-")[0];
				}else{
					proj.changeOrderParentProjectNumber = proj.projectNumber;
				}
			}
			if (proj.calculated.differenceActualToBilled == null) {
				proj.calculated.differenceActualToBilled = 0;
			}
			if (proj.calculated.totalInvoiced == null) {
				proj.calculated.totalInvoiced = 0;
			}
			if (proj.calculated.invoicesCollected == null) {
				proj.calculated.invoicesCollected = 0;
			}
			if (proj.calculated.invoicesOutstanding == null) {
				proj.calculated.invoicesOutstanding = 0;
			}
			if (proj.calculated.latestActivity == null) {
				proj.calculated.latestActivity = "";
			}
			if (roles != null && organization != null) {
				if (proj.bidWorksheet == null) {
					proj.bidWorksheet = {
						roles: [],
						fixedCosts: [],
						discount: 0,
						bidNotes: "",
						calculated: {
							totalHours: 0,
							totalSOWPrice: 0,
							totalFixedCosts: 0,
							totalProductionBudget: 0,
							totalExtraMargin: 0,
						}
					} as ProjectBidWorksheet;
					roles.forEach(role => { proj.bidWorksheet.roles.push({ roleID: role.id, hours: 0, sowPrice: 0 }) });
					organization!.fixedCostItemsList.forEach(fixedCost => { proj.bidWorksheet.fixedCosts.push({ fixedCostItem: fixedCost, cost: 0, sowPrice: 0 }) })
				}
			}
			if (proj.bidWorksheet != null && proj.bidWorksheet.bidNotes == null) {
				proj.bidWorksheet.bidNotes = "";
			}

			tempProjects.push({ ...proj, id: projSnapshot.id } as Project);
		})


		tempProjects.sort((a, b) => {
			if (a.yearClosedWon < b.yearClosedWon) {
				return -1;
			}
			if (a.yearClosedWon > b.yearClosedWon) {
				return 1;
			}
			if (a.monthClosedWon < b.monthClosedWon) {
				return -1;
			}
			if (a.monthClosedWon > b.monthClosedWon) {
				return 1;
			}
			return 0;
		})

		AC.setProjects(tempProjects);
	}

	const projectMessageBoardsUpdated = (snapshot: QuerySnapshot<DocumentData>) => {
		let tempProjects: ProjectMessageBoard[] = [];
		snapshot.forEach(projSnapshot => {
			let proj = projSnapshot.data();
			tempProjects.push({ ...proj, id: projSnapshot.id } as ProjectMessageBoard);
		})
		AC.setProjectMessageBoards(tempProjects);
	}


	const clientsUpdated = (snapshot: QuerySnapshot<DocumentData>) => {
		let tempClients: Client[] = [];
		for (var i in snapshot.docs) {
			let doc = snapshot.docs[i];
			let data = doc.data() as any;
			if (data.contacts == null) {
				data.contacts = [];
			}
			tempClients.push({ ...data, id: doc.id } as Client);
		}


		tempClients.sort((a, b) => {
			if (a.name.toLowerCase() < b.name.toLowerCase()) {
				return -1;
			}
			if (a.name.toLowerCase() > b.name.toLowerCase()) {
				return 1;
			}
			return 0;
		});

		AC.setClients(tempClients!);
		console.log("organization clients updated");
	}

	const clientContactsUpdated = (snapshot: QuerySnapshot<DocumentData>) => {
		let tempClientContacts: ClientContact[] = [];
		for (var i in snapshot.docs) {
			let doc = snapshot.docs[i];
			let data = doc.data() as any;
			tempClientContacts.push({ ...data, id: doc.id } as ClientContact);
		}


		AC.setClientContacts(tempClientContacts!);
		console.log("organization client contacts updated");
	}

	const viewsUpdated = (snapshot: QuerySnapshot<DocumentData>) => {
		let tempViews: View[] = [];
		for (var i in snapshot.docs) {
			let doc = snapshot.docs[i];
			let data = doc.data() as any;
			tempViews.push({ ...data, id: doc.id } as View);
		}

		AC.setViews(tempViews!);
		console.log("organization views updated");
	}

	const personsUpdated = (snapshot: QuerySnapshot<DocumentData>) => {
		let tempPersons: Person[] = [];
		for (var i in snapshot.docs) {
			let doc = snapshot.docs[i];
			let data = doc.data();
			if (data.active === undefined) {
				data.active = true;
			}
			if (data.birthday === undefined) {
				data.birthday = "";
			}
			if (data.startDate === undefined) {
				data.startDate = "";
			}
			if (data.endDate === undefined) {
				data.endDate = "";
			}
			if (data.phone === undefined) {
				data.phone = "";
			}
			if (data.permissionsWrite === undefined) {
				data.permissionsWrite = [] as string[];
			}
			if (data.nonProjectTasks === undefined) {
				data.nonProjectTasks = [] as PersonNonProjectTask[];
			}
			if (data.eligibleForProfitSharing === undefined) {
				data.eligibleForProfitSharing = false;
			}
			if ((data.actualsOrder instanceof Map) == false) {
				let map: { [year: string]: string[] } = {};
				map["" + new Date().getFullYear()] = data.actualsOrder;
				data.actualsOrder = map;
			}
			if (data.PTOPerYear === undefined) {
				data.PTOPerYear = 0;
			}
			if (data.STOPerYear === undefined) {
				data.STOPerYear = 0;
			}
			if (data.PTOSTORenewal === undefined) {
				data.PTOSTORenewal = "";
			}
			if (data.PTOExpiration === undefined) {
				data.PTOExpiration = "";
			}
			if (data.STOExpiration === undefined) {
				data.STOExpiration = "";
			}

			if (data.assignedTaskOrder === undefined) {
				data.assignedTaskOrder = [];
			}

			tempPersons.push({
				id: doc.id,
				...data
			} as Person)
		}

		tempPersons.sort((a, b) => {
			if (a.active && !b.active) {
				return -1;
			} else if (!a.active && b.active) {
				return 1;
			}
			let aFirstName = a.name.split(" ")[0]
			let bFirstName = b.name.split(" ")[0]
			let aLastName = a.name.split(" ")[a.name.split(" ").length - 1]
			let bLastName = b.name.split(" ")[b.name.split(" ").length - 1]
			if (aFirstName.toLowerCase() < bFirstName.toLowerCase()) {
				return -1;
			}
			if (aFirstName.toLowerCase() > bFirstName.toLowerCase()) {
				return 1;
			}
			if (aLastName.toLowerCase() < bLastName.toLowerCase()) {
				return -1;
			}
			if (aLastName.toLowerCase() > bLastName.toLowerCase()) {
				return 1;
			}
			return 0;
		});

		tempPersons.forEach(person => {
			if (person.actualsOrder == null) {
				person.actualsOrder = {} as { [year: string]: string[] };
			} else {
				person.actualsOrder = {} as { [year: string]: string[] };
			}
			if (person.email == sharedFirebaseAuth.currentUser?.email) {
				// if permissions are different from current user, update permissions
				let found = person.permissions?.find(permission => { return permissions.indexOf(permission) == -1 });
				found = found || permissions.find(permission => { return person.permissions?.indexOf(permission) == -1 });
				if (found) {
					AC.setPermissions(person.permissions ?? []);
				}
				found = person.permissionsWrite?.find(permission => { return permissionsWrite.indexOf(permission) == -1 });
				found = found || permissionsWrite.find(permission => { return person.permissionsWrite?.indexOf(permission) == -1 });
				if (found) {
					AC.setPermissionsWrite(person.permissionsWrite ?? []);
				}
			}
		})

		AC.setPersons(tempPersons);
		console.log("organization persons updated");
	}

	const departmentsUpdated = (snapshot: QuerySnapshot<DocumentData>) => {
		let tempDepartments: Department[] = [];
		for (var i in snapshot.docs) {
			let doc = snapshot.docs[i];
			let data = doc.data() as Department;
			tempDepartments.push({
				id: doc.id,
				organizationID: data.organizationID,
				name: data.name,
				abbreviation: data.abbreviation,
				overrideHourlyRate: data.overrideHourlyRate,
				useOverrideHourlyRate: data.useOverrideHourlyRate,
				persons: data.persons
			})
		}
		if (organization == null) {
			return;
		} else {
			tempDepartments = tempDepartments.sort((a, b) => {
				if (organization!.departmentIDOrder.indexOf(a.id) < organization!.departmentIDOrder.indexOf(b.id)) {
					return -1;
				}
				if (organization!.departmentIDOrder.indexOf(a.id) > organization!.departmentIDOrder.indexOf(b.id)) {
					return 1;
				}
				return 0;
			})
		}
		AC.setDepartments(tempDepartments);
		console.log("organization departments updated");

	}

	const rolesUpdated = (snapshot: QuerySnapshot<DocumentData>) => {
		let tempRoles: Role[] = [];
		for (var i in snapshot.docs) {
			let doc = snapshot.docs[i];
			let data = doc.data() as Role;
			if (data.billableWeight == null) {
				data.billableWeight = 1;
			}
			tempRoles.push({
				id: doc.id,
				organizationID: data.organizationID,
				name: data.name,
				billableWeight: data.billableWeight,
				abbreviation: data.abbreviation,
				overrideHourlyRate: data.overrideHourlyRate,
				useOverrideHourlyRate: data.useOverrideHourlyRate,
				persons: data.persons,
				isAM: data.isAM ? true : false,
				isPM: data.isPM ? true : false,
				isRSM: data.isRSM ? true : false,
				isAdmin: data.isAdmin ? true : false,
				isBillable: data.isBillable ? true : false,
			})
		}
		if (organization == null) {
			return;
		} else {
			tempRoles = tempRoles.sort((a, b) => {
				if (organization!.roleIDOrder.indexOf(a.id) < organization!.roleIDOrder.indexOf(b.id)) {
					return -1;
				}
				if (organization!.roleIDOrder.indexOf(a.id) > organization!.roleIDOrder.indexOf(b.id)) {
					return 1;
				}
				return 0;
			})
		}
		AC.setRoles(tempRoles);
		console.log("organization roles updated");

	}


	const timeOffUpdated = (snapshot: QuerySnapshot<DocumentData>) => {
		let tempProjects: TimeOff[] = [];
		snapshot.forEach(projSnapshot => {
			let timeOff = projSnapshot.data();

			for (var i in timeOff.hoursTakenPTO) {
				let entry = timeOff.hoursTakenPTO[i];
				if (entry.date.seconds) {
					let date = new Date(entry.date.seconds * 1000);
					entry.date = shortDate(date);//`${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
				}
			}
			for (var i in timeOff.hoursTakenSTO) {
				let entry = timeOff.hoursTakenSTO[i];
				if (entry.date.seconds) {
					let date = new Date(entry.date.seconds * 1000);
					entry.date = shortDate(date);//`${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
				}
			}

			tempProjects.push({ ...timeOff, id: projSnapshot.id } as TimeOff);
		})

		AC.setTimeOffList(tempProjects);
	}


	let projectRoleActualHoursUpdated = (snapshot: QuerySnapshot<DocumentData>) => {

		let tempProjectRoleActualHours: ProjectRoleActualHours[] = [];
		snapshot.forEach(projSnapshot => {
			let actual = projSnapshot.data();

			for (var i in actual.hours) {
				let entry = actual.hours[i];
				if (entry.date.seconds) {
					let date = new Date(entry.date.seconds * 1000);
					entry.date = shortDate(date);//`${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
				}
			}
			if (actual.year == null) {
				actual.year = new Date().getFullYear();
			}
			tempProjectRoleActualHours.push({ ...actual, id: projSnapshot.id } as ProjectRoleActualHours);
		})
		AC.setActuals(tempProjectRoleActualHours);
	}

	let backupsUpdated = (snapshot: QuerySnapshot<DocumentData>) => {
		let tempBackups: Backup[] = [];
		snapshot.forEach(projSnapshot => {
			let backup = projSnapshot.data();
			tempBackups.push({ ...backup, id: projSnapshot.id } as Backup);
		})
		AC.setBackups(tempBackups);
	}


	useEffect(() => {
		if (organizationID == "") {
			return;
		}

		let projectQuery = query(projectCollection, where("organizationID", "==", organizationID))
		let unsubScribeProjects = onSnapshot(projectQuery, projectListUpdated)

		let projectMessageBoardQuery = query(projectMessageBoardCollection, where("organizationID", "==", organizationID))
		let unsubscribeProjectMessageBoards = onSnapshot(projectMessageBoardQuery, projectMessageBoardsUpdated)

		let clentQuery = query(clientCollection, where("organizationID", "==", organizationID))
		const unsubscribeClients = onSnapshot(clentQuery, clientsUpdated);

		let clientContactQuery = query(clientContactCollection, where("organizationID", "==", organizationID))
		const unsubscribeClientContacts = onSnapshot(clientContactQuery, clientContactsUpdated);

		let personQuery = query(personCollection, where("organizationID", "==", organizationID))
		const unsubscribePerson = onSnapshot(personQuery, personsUpdated);

		let departmentQuery = query(departmentCollection, where("organizationID", "==", organizationID))
		const unsubscribeDepartment = onSnapshot(departmentQuery, departmentsUpdated);

		let rolesQuery = query(roleCollection, where("organizationID", "==", organizationID))
		const unsubscribeRole = onSnapshot(rolesQuery, rolesUpdated);

		let timeOffQuery = query(timeOffCollection, where("organizationID", "==", organizationID))
		const unsubscribeTimeOff = onSnapshot(timeOffQuery, timeOffUpdated);

		let monthTotalQuery = query(monthTotalCollection, where("organizationID", "==", organizationID))
		const unsubscribeMonthTotal = onSnapshot(monthTotalQuery, monthTotalUpdated);

		let projectRoleActualHoursQuery = query(projectRoleActualHoursCollection, where("organizationID", "==", organizationID))
		const unsubScribeProjectRoleActuals = onSnapshot(projectRoleActualHoursQuery, projectRoleActualHoursUpdated)


		let viewQuery = query(viewCollection, where("organizationID", "==", organizationID))
		const unsubScribeViews = onSnapshot(viewQuery, viewsUpdated)

		let backupQuery = query(backupCollection, where("organizationID", "==", organizationID))
		const unsubScribeBackups = onSnapshot(backupQuery, backupsUpdated)

		return () => {
			unsubScribeProjects();
			unsubscribeProjectMessageBoards();
			unsubscribeClients();
			unsubscribePerson();
			unsubscribeDepartment();
			unsubscribeRole();
			unsubscribeTimeOff();
			unsubscribeMonthTotal();
			unsubscribeClientContacts();
			unsubScribeProjectRoleActuals();
			unsubScribeViews();
			unsubScribeBackups();
		}

	}, [organizationID])


	return (
		<div className={`ChildrenMonitor`}>
		</div>
	)
}