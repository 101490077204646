import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { Person } from "../FirebaseModel/Person";
import { Role } from "../FirebaseModel/Role";
import { sharedFirestore } from "./SharedFirebase";
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from "./UndoStackEntry";

export function savePersonAttributeChange(person:Person,  attributeKey:string|string[], value:any|any[],undoEntryHandler:(entry:UndoStackEntry)=>void){


	const projectRef = doc(sharedFirestore, "Person", person.id);

	let updatedPerson:{ [x: string]: any; } = {};
	for(var key in person){
		if(attributeKey instanceof Array){
			for(var key2 in attributeKey){
				if(key == attributeKey[key2]){
					updatedPerson[key] = value[key2];
					break;
				}else{
					updatedPerson[key] = (person as any)[key];
				}
			}
		}else{
			if(attributeKey){
				if(key == attributeKey){
					updatedPerson[key] = value;
				}else{
					updatedPerson[key] = (person as any)[key];
				}
			}else{
				updatedPerson[key] = (person as any)[key];
			}
		}
	}
	let undoEntry:UndoStackEntry = {
		type: UndoStackEntryType.update,
		objectType: UndoStackEntryObjectType.person,
		objectID: person.id,
		objectBeforeState: {...person},
		objectAfterState: {...updatedPerson},
		description: "Update Person"
	}
	undoEntryHandler(undoEntry);

	return updateDoc(projectRef,updatedPerson)
}


export function deletePerson(person:Person,undoEntryHandler:(entry:UndoStackEntry)=>void){
	let undoEntry:UndoStackEntry = {
		type: UndoStackEntryType.delete,
		objectType: UndoStackEntryObjectType.person,
		objectID: person.id,
		objectBeforeState: {...person},
		objectAfterState: undefined,
		description: "Delete Person"
	}
	undoEntryHandler(undoEntry);
	return deleteDoc(doc(sharedFirestore, "Person", person.id));
}