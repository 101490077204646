import { Person } from "./Person";

export interface Department{
	id:string;
  organizationID:string;
  name:string;
  abbreviation:string;
  overrideHourlyRate:number;
  useOverrideHourlyRate:boolean;
	persons:{
		personID:string,
		weight:number
	}[]; // list of person IDs
}


export function departmentPersonsDescription(department:Department, persons:Person[]):string{
	let description = "";
	for(var j in persons){
		let person = persons[j];
		if(person.departmentID === department.id && person.active){
			if(description != ""){
				description += ", ";
			}
			description += person.name;
		}
	}
	return description;
}
export function departmentPersonsWeightDescription(department:Department, persons:Person[]):string{
	let description = "";
	if(department.persons){
		for(var i in department.persons){
			let person = department.persons[i];
			for(var j in persons){
				let person2 = persons[j];
				if(person.personID === person2.id && person2.active){
					if(description != ""){
						description += ", ";
					}
					description += person2.name;
					if(person.weight != 1){
						description += " (" + person.weight + ")";
					}
				}
			}
		}
	}
	return description;
}