
import React, { useEffect, useState } from "react"
import { addDoc, collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./PopupAddRole.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import { Person } from "../../FirebaseModel/Person";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import { forceDecimal, forceMaxValue, forcePercentage, prettyCurrency } from "../../Utils/formatting";
import { saveOrganizationAttributeChange } from "../../Utils/SaveOrganizationFunctions";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { UndoStackEntry, UndoStackEntryType, UndoStackEntryObjectType } from "../../Utils/UndoStackEntry";
interface PopupAddRoleProps{
	onCancel:()=>void;
	onSave:(role:Role) =>void;
	allowMultiple?:boolean;
}

export default function PopupAddRole(props:PopupAddRoleProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const roles = useSelector((state:{roles:Role[]}) => state.roles)

	const saveNewRole = () =>{
		return new Promise((resolve:(role:Role)=>void,reject) =>{
			let doc = {
				organizationID: organization.id,
				name: name,
				abbreviation: abbreviation,
				overrideHourlyRate: overrideHourlyRate,
				useOverrideHourlyRate: useOverrideHourlyRate,
				isPM: isPM,
				isAM: isAM,
				isRSM: isRSM,
				isAdmin: isAdmin,
				isBillable: isBillable,
				billableWeight: billableWeight,
				persons: []
			}
			addDoc(collection(sharedFirestore, "Role"),doc).then((docRef) =>{
				let newRole:Role = {id:docRef.id, ...doc} as Role;
				let undoEntry:UndoStackEntry = {
					type: UndoStackEntryType.batchStart,
					objectType: UndoStackEntryObjectType.role,
					objectID: newRole.id,
					objectBeforeState: undefined,
					objectAfterState: undefined,
					description: "Create Role"
				}
				AC.submitUndoStackEntry(undoEntry);

				undoEntry = {
					type: UndoStackEntryType.create,
					objectType: UndoStackEntryObjectType.role,
					objectID: newRole.id,
					objectBeforeState: undefined,
					objectAfterState: {...newRole},
					description: "Create Role"
				}
				AC.submitUndoStackEntry(undoEntry);
				
				let newRoleIDOrder = [...organization.roleIDOrder];
				newRoleIDOrder.push(docRef.id);
				saveOrganizationAttributeChange(organization,"roleIDOrder",newRoleIDOrder ,(entry) =>{
					AC.submitUndoStackEntry(entry);
				})
				.then(() =>{
					let undoEntry:UndoStackEntry = {
						type: UndoStackEntryType.batchEnd,
						objectType: UndoStackEntryObjectType.role,
						objectID: newRole.id,
						objectBeforeState: undefined,
						objectAfterState: {...newRole},
						description: "Create Role"
					}
					AC.submitUndoStackEntry(undoEntry);
					resolve(newRole);
				});
			})
		})
	}

	const clearForm = () =>{
		setName("");
		setAbbreviation("");
		setUseOverrideHourlyRate(false);
		setOverrideHourlyRate(organization.hourlyRate);
		setClearingForm(true);
		setTimeout(() =>{setClearingForm(false)}, 100);
	}


	const [name, setName] = useState("");
	const [abbreviation, setAbbreviation] = useState("");
	const [useOverrideHourlyRate, setUseOverrideHourlyRate] = useState(false);
	const [isPM, setIsPM] = useState(false);
	const [isAM, setIsAM] = useState(false);
	const [isRSM, setIsRSM] = useState(false);
	const [isBillable, setIsBillable] = useState(true);
	const [isAdmin, setIsAdmin] = useState(false);
	const [billableWeight, setBillableWeight] = useState(1.0);
	const [overrideHourlyRate, setOverrideHourlyRate] = useState(organization.hourlyRate);

	const [clearingForm, setClearingForm] = useState(false);
	return (
		<div className={`popup PopupAddRole`}>
			<h1>New Role</h1>
			{clearingForm == false && (
				<div className="content">

					<div className="topArea">

					<div className="row">
						<div className="col">
							<div className="label">Role Name</div>
							<input className={`name`} 
								defaultValue={name}
								onBlur={(event) =>{
									setName(event.target.value)
								}}></input>
						</div>
						<div className="col">
							<div className="label">Abbreviation</div>
							<input className={`abbreviation`} 
								defaultValue={abbreviation}
								onBlur={(event) =>{
									setAbbreviation(event.target.value)
								}}></input>
						</div>
						<div className="col">
							<div className="label">Role is Project Manager</div>
							<input className={`isPM`} 
								type="checkbox"
								checked={isPM}
								onChange={(event) =>{
									setIsPM(event.target.checked)
								}}></input>
						</div>
						<div className="col">
							<div className="label">Role is Account Manager</div>
							<input className={`isAM`} 
								type="checkbox"
								checked={isAM}
								onChange={(event) =>{
									setIsAM(event.target.checked)
								}}></input>
						</div>
						<div className="col">
							<div className="label">Role is Regional Sales Manager</div>
							<input className={`isRSM`} 
								type="checkbox"
								checked={isRSM}
								onChange={(event) =>{
									setIsRSM(event.target.checked)
								}}></input>
						</div>
						<div className="col">
							<div className="label">Role is Admin</div>
							<input className={`isAdmin`} 
								type="checkbox"
								checked={isAdmin}
								onChange={(event) =>{
									setIsAdmin(event.target.checked)
								}}></input>
						</div>
						<div className="col">
							<div className="label">Role is Billable</div>
							<input className={`isBillable`} 
								type="checkbox"
								checked={isBillable}
								onChange={(event) =>{
									setIsBillable(event.target.checked)
								}}></input>
						</div>
						{isBillable && (
							<div className="col">
								<div className="label">Billable Weight (%)</div>
								<input type="text"
									className="roleWeight"
									defaultValue={Math.round(billableWeight *100 ).toString() + "%"}
									onFocus={(event) =>{
										event.target.select();
									}}
									onBlur={(event) =>{
										if(event.target.value ==""){
											event.target.value = "0";
										}
										event.target.value = Math.round(billableWeight *100 ).toString() + "%"
									}}
									onKeyPress={(event) =>{
										forcePercentage(event);
										forceMaxValue(event,100)
									}}
									onChange={(event) =>{
										setBillableWeight( Math.round(parseFloat(event.target.value.replace(/,/g, '').replace("%","")))/100);
									}}></input>
							</div>
						)}

						{/* <div className="col">
							<div className="label">Override Hourly Rate

								<input type='checkbox' checked={useOverrideHourlyRate} onChange={(event) =>{
									setUseOverrideHourlyRate(event.target.checked);
								}}></input>
							</div>
							<input type='text' 
								disabled={useOverrideHourlyRate == false}
								className="overrideHourlyRate"
								onKeyPress={(event)=>{forceDecimal(event)}} 
								defaultValue={prettyCurrency(overrideHourlyRate)}
								onBlur={(event) =>{
									event.target.value = prettyCurrency(event.target.value)
									setOverrideHourlyRate(parseFloat(event.target.value.replace("$","").replace(/,/g,"")));
								}}></input>
						</div> */}
						</div>
					</div>
				</div>
			)}

			<div className="floatingButtons">
				<div className="buttonCancel"
					onClick={() =>{
						props.onCancel();
					}}>Cancel</div>
				{/* <div className="buttonSave"
					onClick={() =>{
						let docData = {
							organizationID:organization.id,
							name:name,
							abbreviation:abbreviation,
							overrideHourlyRate:overrideHourlyRate,
							useOverrideHourlyRate:useOverrideHourlyRate,
							order:roles.length,
							persons:[]
						}
						addDoc(collection(sharedFirestore,"Role"),docData).then((docref) =>{
							let newRole = {... docData, id:docref.id} as Role
							organization.roleIDOrder.push(docref.id);
							saveOrganizationAttributeChange(organization,"roleIDOrder",organization.roleIDOrder )
								.then(() =>{
									props.onSave(newRole);
								});
						})
					}}>Save &amp; Close</div> */}

				<DropdownButton 
						items={["Save & Close","Save & Add New"]} 
						selectedIndex={0} 
						executeFunction={(functionIndex: number) =>{
							switch(functionIndex){
								case 0:
									saveNewRole().then((newRole:Role) =>{
										props.onSave(newRole);
									});
									return;
								case 1:
									saveNewRole().then((newRole:Role) =>{
										clearForm();
									});
									return;
							}
					} } ></DropdownButton>
		</div>
	</div>
)
}