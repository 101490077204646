
import React, { useEffect, useState } from "react"
import { addDoc, collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./PopupSchedulePTO.css"
import { useDispatch, useSelector } from "react-redux";
import { Project, ProjectTask, ProjectTaskScheduleBlock } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import { prettyNum, forceNumeric, prettyCurrency, forceDecimal, shortDate } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { saveClientAttributeChange } from "../../Utils/SaveClientFunctions";
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from "../../Utils/UndoStackEntry";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { ClientContact } from "../../FirebaseModel/ClientContact";
import { Person } from "../../FirebaseModel/Person";
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { saveProjectAttributeChange } from "../../Utils/SaveProjectFunctions";
import { TimeOff } from "../../FirebaseModel/TimeOff";
import { saveTimeOffAttributeChange } from "../../Utils/SaveTimeOffFunctions";


const uuid = require('uuid');

interface PopupSchedulePTOProps{
	timeOff:TimeOff;
	startDate?:string;

	onCancel:()=>void;
	onSave:() =>void;
}

export default function PopupSchedulePTO(props:PopupSchedulePTOProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)

	const [project, setProject] = useState<Project|null>(null);
	const [role, setRole] = useState<Role|null>(null);
	const [person, setPerson] = useState<Person|null>(null);

	const [tasks, setTasks] = useState<ProjectTask[]>([]);
	const [selectedTask, setSelectedTask] = useState<ProjectTask|null>(null);

	const [startDate, setStartDate] = useState<string>(props.startDate??shortDate(new Date()));
	const [endDate, setEndDate] = useState<string>(props.startDate??shortDate(new Date()));

	let tempPTOHours:{[date:string]:number} = {};
	const [PTOHours, setPTOHours] = useState<{[date:string]:number}>(tempPTOHours);

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	// a function that returns a date offset by a certain number of days
	const addDays = function(date:Date, days:number) {
		var date = new Date(date.valueOf());
		date.setDate(date.getDate() + days);
		return date;
	}

	useEffect(() =>{
		if(props.timeOff && props.startDate){

			let person = persons.find(person => person.id == props.timeOff.personID);
			setPerson(person!);

			// build a time block starting at startdate and move forward and backword until we find the first weekday that has no scheduled PTO hours
			let startDate = new Date(props.startDate);
			let endDate = new Date(props.startDate);
			let hours = props.timeOff.hoursTakenPTO.find(hours => hours.date == props.startDate)?.hours;
			while(startDate.getDay() == 0 || startDate.getDay() == 6 || hours != null){
				startDate = addDays(startDate,-1);
				hours = props.timeOff.hoursTakenPTO.find(hours => hours.date == shortDate(startDate))?.hours;
			}
			if(hours == null && startDate < new Date(props.startDate)){
				startDate = addDays(startDate,1);
			}
			while(endDate.getDay() == 0 || endDate.getDay() == 6 || hours != null){
				endDate = addDays(endDate,1);
				hours = props.timeOff.hoursTakenPTO.find(hours => hours.date == shortDate(endDate))?.hours;
			}
			if(hours == null && endDate > new Date(props.startDate)){
				endDate = addDays(endDate,-1);
			}

			setStartDate(shortDate(startDate));
			setEndDate(shortDate(endDate));


		}
	},[props.timeOff, props.startDate])


	useEffect(() =>{

			// build a list of PTO hours for each day
			let tempPTOHours:{[date:string]:number} = {};
			let date = new Date(startDate);
			while(date <= new Date(endDate)){
				let hours = props.timeOff.hoursTakenPTO.find(hours => hours.date == shortDate(date))?.hours;
				tempPTOHours[shortDate(date)] = hours??(dayOfWeek[date.getDay()]=="S"?0:8);
				date = addDays(date,1);
			}
			setPTOHours(tempPTOHours);
	},[startDate,endDate]);
	
	const dayOfWeek = ["S","M","T","W","T","F","S"]

	const [clearingForm, setClearingForm] = useState(false);

	const deletePTOBlock = async () =>{
		return new Promise<void>((resolve,reject) =>{
			let newHoursTakenPTO =[... props.timeOff.hoursTakenPTO];

			//go from startDate to endDate and remove any PTO days
			let date = new Date(startDate);
			while(date <= new Date(endDate)){
				newHoursTakenPTO = newHoursTakenPTO.filter(hours => hours.date != shortDate(date));
				date = addDays(date,1);
			}

			let calculated = {...props.timeOff.calculated};
			calculated.totalHoursTakenPTO = newHoursTakenPTO.reduce((total:number, hours) => total + hours.hours,0);

			saveTimeOffAttributeChange(props.timeOff,["hoursTakenPTO","calculated"],[newHoursTakenPTO,calculated],(entry) =>{
				AC.submitUndoStackEntry(entry);
			}).then(() =>{
				resolve();
			});

		})
	}

	const savePTOBlock = async () =>{
		return new Promise<void>((resolve,reject) =>{

			let newHoursTakenPTO =[... props.timeOff.hoursTakenPTO];

			//go from startDate to endDate and remove any 0 hour PTO days, and add any needed pto days;
			let date = new Date(startDate);
			while(date <= new Date(endDate)){
				let hours = PTOHours[shortDate(date)];
				if(hours == 0){
					// remove this day from the hours taken PTO list
					newHoursTakenPTO = newHoursTakenPTO.filter(hours => hours.date != shortDate(date));
				}else{
					// add or update this day in the hours taken PTO list
					let hoursTakenPTO = newHoursTakenPTO.find(hours => hours.date == shortDate(date));
					if(hoursTakenPTO){
						hoursTakenPTO.hours = hours;
					}else{
						newHoursTakenPTO.push({
							date:shortDate(date),
							hours:hours
						})
					}
				}
				date = addDays(date,1);
			}

			let calculated = {...props.timeOff.calculated};
			calculated.totalHoursTakenPTO = newHoursTakenPTO.reduce((total:number, hours) => total + hours.hours,0);

			saveTimeOffAttributeChange(props.timeOff,["hoursTakenPTO","calculated"],[newHoursTakenPTO,calculated],(entry) =>{
				AC.submitUndoStackEntry(entry);
			}).then(() =>{
				resolve();
			});
		})
	}

  return (
    <div className={`popup PopupSchedulePTO`}>
			<h1>Schedule {person?.name} PTO</h1>
			{clearingForm == false && (
				<div className="content">

						<button className="deleteButton" onClick={() => {
							deletePTOBlock().then(() =>{
								props.onSave();
							})
						}}>Remove From Schedule</button>

					<div className="topArea">

						<div className="row">

							<div className="col">
								<div className="label">Start Date</div>
				        <DatePicker 
				          selected={(new Date(startDate))}
				          onChange={(date:Date) =>{
				            if(date){
											let dateString = shortDate(date);
											setStartDate(dateString);
				            }
				          }}></DatePicker>
							</div>

							<div className="col">
								<div className="label">End Date</div>
				        <DatePicker 
				          selected={(new Date(endDate))}
				          onChange={(date:Date) =>{
				            if(date){
											let dateString = shortDate(date);
											setEndDate(dateString);
				            }
				          }}></DatePicker>
							</div>
						</div>
					</div>

					<h1>Daily Breakdown</h1>

					<div className="bottomArea">
						<table>
							<thead>
								<tr>
									<th>Date</th>
									<th>Hours</th>
								</tr>
							</thead>
							<tbody>
								{((startDate:string, endDate:string) =>{
									// add a row for each PTO day
									let rows:React.ReactNode[] = [];
									let date = new Date(startDate);
									while(date <= new Date(endDate)){
										rows.push(
											<tr key={shortDate(date)} className={`${dayOfWeek[date.getDay()]=="S"?"weekend":""}`}>
												<td>{shortDate(date)} - {dayOfWeek[(new Date(date)).getDay()]}</td>
												<td>
													<input type="number" 
														defaultValue={PTOHours[shortDate(date)]} 
														max={8}
														min={0}
														onChange={(event) =>{
															let tempPTOHours = {...PTOHours};
															tempPTOHours[shortDate(date)] = parseFloat(event.target.value);
															setPTOHours(tempPTOHours);
													}}></input>
												</td>
											</tr>
										)
										date = addDays(date,1);
									}
									return rows;
								})(startDate,endDate)}
							</tbody>
						</table>
					</div>
				</div>
			)}

			<div className="floatingButtons">
				<div className="buttonCancel"
					onClick={() =>{
						props.onCancel();
					}}>Cancel</div>
				<div className="buttonSave"
					onClick={() =>{
						savePTOBlock().then(() =>{
							props.onSave();
						});
					}}>Save &amp; Close</div>
			</div>
		</div>
  )
}