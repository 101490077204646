

import React, { ReactNode, useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./TeamScreenPeopleTable.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { Role } from "../../../FirebaseModel/Role";
import { Person, personCalculatedRoleWeights, personCalculatedRoleWeightsFiltered, personRolesDescription } from "../../../FirebaseModel/Person";
import OrganizationScreenRolesInput from "../../OrganizationScreen/OrganizationScreenRolesInput/OrganizationScreenRolesInput";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import OrganizationScreenPeopleInput from "../TeamScreenPeopleInput/TeamScreenPeopleInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { deletePerson, savePersonAttributeChange } from "../../../Utils/SavePersonFunctions";
import TeamScreenPeopleInput from "../TeamScreenPeopleInput/TeamScreenPeopleInput";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { Tooltip } from "@mui/material";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import TeamScreenPeopleRow from "../TeamScreenPeopleRow/TeamScreenPeopleRow";
interface TeamScreenPeopleTableProps{
	filterSearch:string,
	filterActive:boolean,
	filterInactive:boolean,

	filterDepartment:string,

	filterStartMonth:string,
	filterEndMonth:string,
	filterStartYear:string,
	filterEndYear:string,

	onEditPersonPressed:(person:Person)=>void,
	onPersonTimesheetPressed:(person:Person)=>void
}

export default function TeamScreenPeopleTable(props:TeamScreenPeopleTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const allPersons = useSelector((state:{persons:Person[]}) => state.persons)
	const roles = useSelector((state:{roles:Role[]}) => state.roles)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)


	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);
		
	const [persons, setPersons] = useState<Person[]>(allPersons);

	useEffect(() =>{
		let tempPersons:Person[] = [];
		allPersons.forEach((person:Person) =>{
			if(props.filterDepartment != ""){
				if(person.departmentID != props.filterDepartment){
					return;
				}
			}
			if(props.filterActive && person.active){
				if(props.filterSearch != ""){
					if(person.name.toLowerCase().indexOf(props.filterSearch.toLowerCase()) != -1 ||
						person.email.toLowerCase().indexOf(props.filterSearch.toLowerCase()) != -1){
						tempPersons.push(person);
					}
					return;
				}
				tempPersons.push(person);
			}
			else if(props.filterInactive && !person.active){
				if(props.filterSearch != ""){
					if(person.name.toLowerCase().indexOf(props.filterSearch.toLowerCase()) != -1 ||
						person.email.toLowerCase().indexOf(props.filterSearch.toLowerCase()) != -1){
						tempPersons.push(person);
					}
					return;
				}
				tempPersons.push(person);
			}
		});
		setPersons(tempPersons);
	},[allPersons, props.filterActive, props.filterInactive, props.filterSearch, props.filterDepartment, props.filterStartMonth, props.filterEndMonth, props.filterStartYear, props.filterEndYear, roles, actuals, organizationID, organization]);
	
  return (
    <table className={`TeamScreenPeopleTable`}>
			<thead>
				<tr>
					<th></th>
					<th>Active</th>
					<th>Name</th>
					<th>Email</th>
					<th>Phone</th>
					<th>Roles - Weights</th>
					<th>Calculated Weights</th>
					<th>Birthday</th>
					<th>Start Date</th>
					<th>End Date</th>
					<th>Employment Length</th>
				</tr>
			</thead>
			<tbody>
				{persons.map((person:Person) =>{
					return <TeamScreenPeopleRow 
						key={`person_${person.id}`} 
						person={person} 
						filterStartMonth={props.filterStartMonth} 
						filterEndMonth={props.filterEndMonth} 
						filterStartYear={props.filterStartYear} 
						filterEndYear={props.filterEndYear} 
						onEditPersonPressed={props.onEditPersonPressed} 
						onPersonTimesheetPressed={props.onPersonTimesheetPressed}></TeamScreenPeopleRow>
					return <tr key={`person_${person.id}`} className={`${person.active?"active":"inactive"}`}>
						<td>
							<Tooltip title={`Edit`} placement="bottom">
								<div className="buttonEdit"
									onClick={() =>{
										props.onEditPersonPressed(person);
									}}><FontAwesomeIcon icon={faPencil}></FontAwesomeIcon></div>
							</Tooltip>

							{/* <Tooltip title={`Timesheet for ${person.name}`} placement="bottom">
								<div className="buttonTimesheet"
									onClick={() =>{
										props.onPersonTimesheetPressed(person);
									}}><FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon></div>
							</Tooltip> */}
						</td>
						<td>
							<input type='checkbox' checked={person.active} onChange={(event) =>{
								person.active = event.target.checked;
								savePersonAttributeChange(person,"active",person.active,(entry) =>{
									AC.submitUndoStackEntry(entry);
								});
							}}></input>
						</td>
						<td><TeamScreenPeopleInput attributeKey="name" person={person}></TeamScreenPeopleInput></td>
						<td><TeamScreenPeopleInput attributeKey="email" person={person}></TeamScreenPeopleInput></td>
						<td className="personRoles">
							{personRolesDescription(person,roles)}
						</td>
						<td className="personCalculatedRoles">
							{personCalculatedRoleWeightsFiltered(person,roles,actuals,props.filterStartMonth,props.filterEndMonth,props.filterStartYear,props.filterEndYear)}
						</td>
						<td><TeamScreenPeopleInput attributeKey="birthday" className="date" isDate={true} person={person}></TeamScreenPeopleInput></td>
						<td><TeamScreenPeopleInput attributeKey="startDate" className="date" isDate={true} person={person}></TeamScreenPeopleInput></td>
						<td><TeamScreenPeopleInput attributeKey="endDate" className="date" disabled={person.active} isDate={true} person={person}></TeamScreenPeopleInput></td>
						<td>{person.startDate!="" &&(
							<>
							{person.endDate!=""?(
								Math.round((new Date(person.endDate).getTime() - new Date(person.startDate).getTime()) / (1000*60*60*24*365) *10)/10 + " yrs"
							):(

								Math.round((new Date().getTime() - new Date(person.startDate).getTime()) / (1000*60*60*24*365) *10)/10 + " yrs"
							)}
							</>
						)}</td>
						<Tooltip title={`Delete`} placement="bottom">
							<div className="buttonDelete"
								onClick={() =>{
									if(window.confirm("Are you sure you'd like to delete this person?")){
										deletePerson(person,(entry) =>{
											AC.submitUndoStackEntry(entry);
										});
									}
								}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
						</Tooltip>
					</tr>
				})}
			</tbody>
    </table>
  )
}