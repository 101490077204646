import { addDoc, collection, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { Client } from "../FirebaseModel/Client";
import { BlankBSPColumn, MonthTotal } from "../FirebaseModel/MonthTotal";
import { Organization } from "../FirebaseModel/Organization";
import { Role } from "../FirebaseModel/Role";
import { sharedFirestore } from "./SharedFirebase";
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from "./UndoStackEntry";


export function getOrCreateMonthTotal(month:number,year:number, allMonthTotals:MonthTotal[]|null, organization:Organization,undoEntryHandler:(entry:UndoStackEntry)=>void):Promise<MonthTotal>{
	return new Promise<MonthTotal>((resolve,reject) =>{
		if(allMonthTotals){
			for(var i in allMonthTotals){
				if(allMonthTotals[i].month == month && allMonthTotals[i].year == year){
					resolve(allMonthTotals[i]);
					return;
				}
			}
		}
		let newMonthTotal = {
			month: month,
			year: year,
			id: "",
			organizationID: organization.id,
			projectOrder: [],
			calculated: {
				actualHoursByRole: [],
				estimatedHoursByRole: [],
				totalHoursBillable: 0,
				totalHoursNonBillable: 0,
				totalPTOHours: 0,
				totalProductionBudget: 0,
				totalSFClosedWon: 0,
				totalSTOHours: 0,
				bsp :{
					... BlankBSPColumn
				}
			},
			bspProjects : []
		};


		addDoc(collection(sharedFirestore, "MonthTotal"), newMonthTotal).then((docRef) => {
			newMonthTotal.id = docRef.id;

			let undoEntry:UndoStackEntry = {
				type: UndoStackEntryType.create,
				objectType: UndoStackEntryObjectType.monthTotal,
				objectID: newMonthTotal.id,
				objectBeforeState: undefined,
				objectAfterState: {...newMonthTotal},
				description: "Create Month Total"
			}
			undoEntryHandler(undoEntry);
			
			resolve(newMonthTotal as MonthTotal);
		}).catch((error) => {
			reject(error);
		});
	});
}

export function saveMonthTotalAttributeChange(monthTotal:MonthTotal,  attributeKey:string|string[], value:any|any[],undoEntryHandler:(entry:UndoStackEntry)=>void){


	const projectRef = doc(sharedFirestore, "MonthTotal", monthTotal.id);

	let updatedMonthTotal:{ [x: string]: any; } = {};
	for(var key in monthTotal){
		if(attributeKey instanceof Array){
			for(var key2 in attributeKey){
				if(key == attributeKey[key2]){
					updatedMonthTotal[key] = value[key2];
					break;
				}else{
					updatedMonthTotal[key] = (monthTotal as any)[key];
				}
			}
		}else{
			if(attributeKey){
				if(key == attributeKey){
					updatedMonthTotal[key] = value;
				}else{
					updatedMonthTotal[key] = (monthTotal as any)[key];
				}
			}else{
				updatedMonthTotal[key] = (monthTotal as any)[key];
			}
		}
	}

	let undoEntry:UndoStackEntry = {
		type: UndoStackEntryType.update,
		objectType: UndoStackEntryObjectType.monthTotal,
		objectID: monthTotal.id,
		objectBeforeState: {...monthTotal},
		objectAfterState: {...updatedMonthTotal},
		description: "Update Month Total"
	}
	undoEntryHandler(undoEntry);

	for(var key in monthTotal){
		(monthTotal as any)[key] = updatedMonthTotal[key];
	}

	return updateDoc(projectRef,updatedMonthTotal)
}




export function deleteMonthTotal(monthTotal:MonthTotal,undoEntryHandler:(entry:UndoStackEntry)=>void){
	let undoEntry:UndoStackEntry = {
		type: UndoStackEntryType.delete,
		objectType: UndoStackEntryObjectType.monthTotal,
		objectID: monthTotal.id,
		objectBeforeState: {...monthTotal},
		objectAfterState: undefined,
		description: "Delete Month Total"
	}
	undoEntryHandler(undoEntry);
	return deleteDoc(doc(sharedFirestore, "MonthTotal", monthTotal.id));
}