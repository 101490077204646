import React, { useEffect, useState } from "react"
import "./ProjectTaskTaskPerson.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { Person } from "../../../FirebaseModel/Person";

interface ProjectTaskTaskPersonProps{
	personID:string;
	isReadOnly?:boolean;
	onDelete:(personID:string) => void;
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function ProjectTaskTaskPerson(props:ProjectTaskTaskPersonProps){	
  const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const person = persons.find((person) => person.id===props.personID);
  return (
    <div className={`ProjectTaskTaskPerson`}>
			
			<div className="name">{person?.name}</div>

			{!props.isReadOnly && (
				<Tooltip title="Remove team member from this task" placement="bottom">
					<div className="buttonDelete" onClick={() => props.onDelete(props.personID)}>
						<FontAwesomeIcon icon={faTrash} />
					</div>
				</Tooltip>
			)}
    </div>
  )
}