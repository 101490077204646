export function prettyNum(input:number|string|null){
	let num = 0;
  if(input == null){
    return "0";
  }else if(typeof(input) == "number"){
		num = input as number;
	}else{
		num = parseFloat(input.replace("$","").replace(/,/g,""));
	}
	num = Math.round(num*100)/100;
  return num.toLocaleString("en-US");
}

export function prettyCurrency(input:number|string){
	let num = 0;
  if(input == null){
    return "$0"
  }else if(typeof(input) == "number"){
		num = input as number;
	}else{
		num = parseFloat(input.replace("$","").replace(/,/g,""));
	}
  if(num == null){
    return "$0"
  }
	num = Math.round(num*100)/100;
  if(num == Math.round(num)){
    return "$"+prettyNum(num);
  }
  return "$"+(Math.round(num*100)/100).toLocaleString("en-US",{ minimumFractionDigits: 2 });
}

export function padDecimal(num:string, places:number){
	let decimalIndex = num.indexOf(".");
	if(decimalIndex == -1){
		num += ".";
		decimalIndex = num.length-1;
	}
	let decimalPlaces = num.length - decimalIndex - 1;
	for(let i = decimalPlaces; i < places; i++){
		num += "0";
	}
	return num;
}

export function padZeros(num:number, length:number){
	let output = num+""; 
	while(output.length < length){
		output = "0"+output;
	}
	return output;
}

export function illegalChars(input:string){
	// don't allow characters that wont show up properly in csv.
	let foundIllegalChars:string[] = [];
	let illegalChars = ["\u2013"]
	for(let i = 0; i < illegalChars.length; i++){
		if(input.indexOf(illegalChars[i]) != -1 && foundIllegalChars.indexOf(illegalChars[i]) == -1){
			foundIllegalChars.push(illegalChars[i]);
		}
	}
	return foundIllegalChars;
	
}


export function currencyToNum(input:string){
  return parseFloat(input.replace("$","").replace(/,/g,""))
}

export function shortDate(date:Date){
  return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
}

export function forceNumeric(event: React.KeyboardEvent<HTMLInputElement>) {
	if(event.charCode == 13){
		(event.target as HTMLInputElement).blur();
    event.preventDefault();
		return true;
	}
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
		return true;
  }
	return false;
}


export function forceMaxValue(event: React.KeyboardEvent<HTMLInputElement>, maxValue:number) {
	let input = event.target as HTMLInputElement;
	// if the new key stroke makes the value greater than the max value, then prevent the key stroke. take into account selection ranges
	if(input.selectionStart == input.selectionEnd){
		if(parseFloat(event.currentTarget.value + event.key) > maxValue){
			event.preventDefault();
			return true;
		}
	}else{
		let newValue = input.value.substring(0,input.selectionStart??0) + event.key + event.currentTarget.value.substring(input.selectionEnd??0);
		if(parseFloat(newValue) > maxValue){
			event.preventDefault();
			return true;
		}
	}
}
export function forceDecimal(event: React.KeyboardEvent<HTMLInputElement>) {
	if(event.charCode == 13){
		(event.target as HTMLInputElement).blur();
    event.preventDefault();
		return true;
	}
	if(!/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+|\.)$/.test(event.key)){
		event.preventDefault();
		return true;
	}
	return false;
}
export function forcePercentage(event: React.KeyboardEvent<HTMLInputElement>) {
	if(event.charCode == 13){
		(event.target as HTMLInputElement).blur();
    event.preventDefault();
		return true;
	}
	if(!/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+|\.)$|%/.test(event.key)){
		event.preventDefault();
		return true;
	}
	return false;
}


export default {prettyNum, prettyCurrency, shortDate, forceNumeric, forceDecimal, currencyToNum}