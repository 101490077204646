
import React, { ReactNode, useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./OrganizationScreenRolesRow.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { Role, rolePersonsDescription } from "../../../FirebaseModel/Role";
import { Person } from "../../../FirebaseModel/Person";
import OrganizationScreenRolesInput from "../OrganizationScreenRolesInput/OrganizationScreenRolesInput";
import { deleteRole, saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { faAnchor, faBars, faCheck, faCircleNodes, faHamburger, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Tooltip } from "@mui/material";
export interface OrganizationScreenRolesRowProps{
	role:Role;
}

export default function OrganizationScreenRolesRow(props:OrganizationScreenRolesRowProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const allRoles = useSelector((state:{roles:Role[]}) => state.roles)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)


	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);
		
	const [roles, setRoles] = useState<Role[]>(allRoles);

	useEffect(() =>{
		setRoles([... allRoles]);
	},[allRoles]);
	
	return (
		<>
		{permissionsWrite.includes("Organization") ?(
			<>
				<td><FontAwesomeIcon icon={faBars} style={{pointerEvents:"none", fontSize:"50%"}}></FontAwesomeIcon></td>
				<td><OrganizationScreenRolesInput attributeKey="name" role={props.role}></OrganizationScreenRolesInput></td>
				<td><OrganizationScreenRolesInput attributeKey="abbreviation" role={props.role}></OrganizationScreenRolesInput></td>
				<td><OrganizationScreenRolesInput attributeKey="isAM" role={props.role} isCheckbox={true}></OrganizationScreenRolesInput></td>
				<td><OrganizationScreenRolesInput attributeKey="isPM" role={props.role} isCheckbox={true}></OrganizationScreenRolesInput></td>
				<td><OrganizationScreenRolesInput attributeKey="isRSM" role={props.role} isCheckbox={true}></OrganizationScreenRolesInput></td>
				<td><OrganizationScreenRolesInput attributeKey="isAdmin" role={props.role} isCheckbox={true}></OrganizationScreenRolesInput></td>
				<td><OrganizationScreenRolesInput attributeKey="isBillable" role={props.role} isCheckbox={true}></OrganizationScreenRolesInput></td>
				<td><OrganizationScreenRolesInput attributeKey="billableWeight" role={props.role} isPercentage={true}></OrganizationScreenRolesInput></td>
				{/* <td>
					<input type='checkbox' checked={role.useOverrideHourlyRate} onChange={(event) =>{
						saveRoleAttributeChange(role,"useOverrideHourlyRate",event.target.checked)
					}}></input>
				</td>
				<td><OrganizationScreenRolesInput attributeKey="overrideHourlyRate" className={`${role.useOverrideHourlyRate?"":"disabled"}`} forceNumeric={true} isCurrency={true} role={role}></OrganizationScreenRolesInput></td> */}
				<td className="rolePeople">
						<Tooltip title={rolePersonsDescription(props.role,persons)} placement="bottom">
							<div>{rolePersonsDescription(props.role,persons)}</div>
						</Tooltip>
				</td>
				<div className="buttonDelete"
					onClick={() =>{
						if(window.confirm("Are you sure you'd like to delete this role?")){
							deleteRole(props.role, organization,(entry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}
					}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
			</>
		):(
			<>
				<td></td>
				<td>{props.role.name}</td>
				<td>{props.role.abbreviation}</td>
				<td>{props.role.isAM&&<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}</td>
				<td>{props.role.isPM&&<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}</td>
				<td>{props.role.isRSM&&<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}</td>
				<td>{props.role.isBillable&&<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}</td>
				<td>{Math.round(props.role.billableWeight*100)+"%"}</td><td className="rolePeople">
						<Tooltip title={rolePersonsDescription(props.role,persons)} placement="bottom">
							<div>{rolePersonsDescription(props.role,persons)}</div>
						</Tooltip>
				</td>
				<div className="buttonDelete"
					onClick={() =>{
						if(window.confirm("Are you sure you'd like to delete this role?")){
							deleteRole(props.role, organization,(entry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}
					}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
			</>
		)}
		</>
  )
}