import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { Organization } from "../FirebaseModel/Organization";
import { Person } from "../FirebaseModel/Person";
import { sharedFirestore } from "./SharedFirebase";

export function listUserOrganizations(email:string){
	return new Promise((resolve:(result:{persons:Person[], organizations:Organization[]})=>void,reject) => {
		const personsCollectionRef = collection(sharedFirestore, "Person");

		const q = query(personsCollectionRef, where("email", "==", email));
		getDocs(q).then(async (querySnapshot) => {
			const orgnaizationIDs:string[] = [];

			let persons:Person[] = [];

			querySnapshot.forEach((doc) => {
				console.log(doc.id, " => ", doc.data());
				orgnaizationIDs.push(doc.data().organizationID);
				persons.push({id:doc.id, ...doc.data()} as Person);
			});

			try{
				let organizations:Organization[] = [];
				for(let i = 0 ; i < orgnaizationIDs.length ; i++){
					let orgDocRef = doc(sharedFirestore, "Organization", orgnaizationIDs[i]);
					let orgDoc = await getDoc(orgDocRef);
					if(orgDoc.exists()){
						organizations.push({id:orgDocRef.id, ...orgDoc.data()} as Organization);
					}
				}
				resolve({persons, organizations});
			}catch(error){
				console.log("Error getting organization documents: ", error);
				reject(error);
			}
		}).catch((error) => {
			console.log("Error getting person documents: ", error);
			reject(error);
		})
	})
}