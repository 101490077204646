
import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./TeamScreenPeopleInput.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { forceNumeric, prettyCurrency, prettyNum, shortDate } from "../../../Utils/formatting";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { saveProjectAttributeChange, saveProjectPlannedHoursChange } from "../../../Utils/SaveProjectFunctions";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { Person } from "../../../FirebaseModel/Person";
import { savePersonAttributeChange } from "../../../Utils/SavePersonFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
interface TeamScreenPeopleInputProps{
	className?:string,
	person:Person,
	attributeKey:string,
	forceNumeric?:boolean,
	isDate?:boolean,
	disabled?:boolean,
	isCurrency?:boolean
}

export default function TeamScreenPeopleInput(props:TeamScreenPeopleInputProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)

	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	const [defaultValue, setDefaultValue] = useState("");

	useEffect(() =>{
		if(props.attributeKey){
			if(props.isCurrency){
				setDefaultValue(prettyCurrency((props.person as any)[props.attributeKey] as number))
			}else{
				setDefaultValue((props.person as any)[props.attributeKey])
			}
		}
	},[props.person,props.attributeKey])

	function forceDecimal(event: React.KeyboardEvent<HTMLInputElement>) {
		throw new Error("Function not implemented.");
	}

  return (
		<>
			{props.isDate ? (
        <DatePicker 
					className={props.className??""}
					disabled={props.disabled??undefined}
          selected={defaultValue!=""?(new Date(defaultValue)):null}
          onChange={(date:Date) =>{
            if(date){
							let dateString = shortDate(date);//`${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
							setDefaultValue(dateString);
							savePersonAttributeChange(props.person,props.attributeKey,dateString,(entry) =>{
								AC.submitUndoStackEntry(entry);
							});
            }else{
							setDefaultValue("");
							savePersonAttributeChange(props.person,props.attributeKey,"",(entry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}
          }}></DatePicker>
			):(
				<input 
					className={`TeamScreenPeopleInput ${props.className??""} ${props.attributeKey??""}`}
					disabled={props.disabled??(props.className=="disabled")}
					onBlur={(event) =>{
		

						if(props.forceNumeric && event.target.value ==""){
							event.target.value = "0";
						}

						if(props.isCurrency){
							event.target.value = prettyCurrency(event.target.value);
						}
		
						if(props.forceNumeric){
							savePersonAttributeChange(props.person,props.attributeKey,parseFloat(event.target.value.replace("$","").replace(/,/g,"")),(entry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}else{
							savePersonAttributeChange(props.person,props.attributeKey,event.target.value,(entry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}
					}}
					onFocus={(event)=>{
						if(props.forceNumeric){
							let input = event.target as HTMLInputElement
							input.setSelectionRange(0,input.value.length)
						}
					}}
					onKeyPress={(event) =>{
						if(props.forceNumeric){
							forceDecimal(event);
						}
					}}
					defaultValue={defaultValue}>
				</input>
			)}

		</>
  )
}