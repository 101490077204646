import React, { useEffect, useState } from "react"
import "./ProductionScheduleTaskScheduleBlockDay.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { faCheck, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProjectTaskScheduleBlock } from "../../../FirebaseModel/Project";
import { shortDate } from "../../../Utils/formatting";

interface ProductionScheduleTaskScheduleBlockDayProps{
	taskScheduleBlock:ProjectTaskScheduleBlock;
	day:Date;
	onEditTaskScheduleBlock:(projectTaskScheduleBlock:ProjectTaskScheduleBlock) => void;
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function ProductionScheduleTaskScheduleBlockDay(props:ProductionScheduleTaskScheduleBlockDayProps){	
	// starting from the start date use the props.date to get the index for hours per day to show;
	let tempDay = new Date(props.taskScheduleBlock.startDate);
	let dayIndex = 0;
	while(tempDay < props.day){
		dayIndex++;
		tempDay.setDate(tempDay.getDate() + 1);
	}
	let hours = props.taskScheduleBlock.dailyPlannedHours[dayIndex];
  return (
    <div className={`ProductionScheduleTaskScheduleBlockDay hours${hours} ${(shortDate(tempDay)==props.taskScheduleBlock.startDate)?"firstDay":""} ${(shortDate(tempDay)==props.taskScheduleBlock.endDate)?"lastDay":""}`}
			onClick={()=>{
				props.onEditTaskScheduleBlock(props.taskScheduleBlock);
			}}>
    </div>
  )
}