import React, { FC, useState, useRef, useLayoutEffect } from 'react';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import './DropdownButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DropdownButtonProps {
  items: string[]
  selectedIndex: number;
  searchPlaceholder?: string
  className?:string
  executeFunction:(functionIndex: number) => void
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const DropdownButton: FC<DropdownButtonProps> = ({items, selectedIndex = 0,  className = '', executeFunction}: DropdownButtonProps) => {
  const dropdownButton = useRef<HTMLDivElement>(null)
  const dropdownContainer = useRef<HTMLDivElement>(null)
  const itemsContainer = useRef<HTMLDivElement>(null)
  const textField = useRef<HTMLInputElement>(null)
  const [selected, setSelected] = useState(selectedIndex);
  const [show, setShow] = useState(false)
  const [query, setQuery] = useState("")
  const [width, height] = useWindowSize()
  const [popOnTop, setPopOnTop] = useState(false)
  
  useLayoutEffect(() => {
    if (dropdownButton.current !== null && dropdownContainer.current !== null && itemsContainer.current !== null) {
      const padding = 20
      var newTop = dropdownButton.current.offsetTop + dropdownButton.current.clientHeight + padding
      const newLeft = dropdownButton.current.offsetLeft + (dropdownButton.current.clientWidth / 2.0) - (dropdownContainer.current.clientWidth / 2.0)
      dropdownContainer.current.style.left = `${newLeft}px`
      if (newTop > (height / 2.0)) {
        // put the drop down above
        setPopOnTop(true)
        const heightOfPopover = dropdownButton.current.offsetTop - (padding * 4)
        newTop = 20
        //dropdownContainer.current.style.top = `${newTop}px`
        dropdownContainer.current.style.maxHeight = `${heightOfPopover}px`
        itemsContainer.current.style.maxHeight = `${heightOfPopover - 60}px`
      } else {
        setPopOnTop(false)
        //dropdownContainer.current.style.top = `${newTop}px`
        dropdownContainer.current.style.maxHeight = `${height - newTop - 80}px`
        itemsContainer.current.style.maxHeight = `${height - newTop - 140}px`
      }
    }
    
  })


  function updateIndex(index: number) {
    var _items = [... items]
    if (query !== '') {
      _items = _items.filter((listItem) => {
        if (listItem.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          return listItem
        }
        return null
      })
    }
    //setItem(_items[index])
    // let value = values[items.indexOf(_items[index])]
    // let tempSelected = [...selected];
    // if(tempSelected.indexOf(value) == -1){
    //   tempSelected.push(value);
    // }else{
    //   tempSelected.splice(tempSelected.indexOf(value),1)
    // }
    // setSelected(tempSelected)
    setSelected(items.indexOf(_items[index]))
    setShow(false)
  }

  const dropDownListNodes = [... items].filter((listItem) => {
    if (query === '') {
      return listItem
    } else if (listItem.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
      return listItem
    }
    return null
  }).map((listItem, index) => {
      return (
        <div 
          key={index}
          className={`list-item ${selected == items.indexOf(listItem)?"selected":""}`}
          onClick={(event) => {
            updateIndex(index)
            //setShow(false)
          }}
        >
          {`${listItem}`}
        </div>
      )
  })

  const getTitle = () =>{
    return items[selected];
  }

  return (
		<>
	    <div className={`DropdownButton ${className}`}>
	      <div 
	        ref={dropdownButton}
	        className="button" >
	          <div className='leftSide'
	            onClick={() => {
	              executeFunction(selected)
	            }}>
	            {getTitle()}
	          </div>
	          <div className='indicator'
	            onClick={() => {
	              setShow(!show)
	            }}>
	              <FontAwesomeIcon icon={faCaretDown} />
	          </div>
	      </div>
	    </div>
      <div className={`DropdownButtonDropdown ${show ? 'displayed' : 'hidden'}`}>
        <div className={`backdrop${show ? '' : ' hidden'}`} onClick={() => {
            setShow(false)
          }
        }></div>
        
        <div ref={dropdownContainer} className="items-container" style={{ minHeight: `55px`}}>
          {/* <div className={`indicator${popOnTop ? ' onTop' : ''}`}></div> */}
          <div ref={itemsContainer} className="actual-items">
            {dropDownListNodes}
          </div>
        </div>
      </div>
		</>
  )
};

export default DropdownButton;
