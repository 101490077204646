import { Timestamp } from "firebase/firestore";

// An object that represenats all the hours that a person has worked on a project
export interface ProjectRoleActualHours{
	id:string;
  organizationID:string;
  projectID:string;
  personID:string;
	roleID:string;
	year:number;
	billable:boolean;
	order:number;
  hours:{
		date:string; // m/d/yyyy
		hours:number;
	}[];
	calculated:{
		totalHours:number;
	};
}

export function hoursForDay(actual:ProjectRoleActualHours, weekKey:string){
	for(var i in actual.hours){
		let entry = actual.hours[i];
		if(entry.date === weekKey){
			return entry.hours;
		}
	}
	return 0;
}

export function actualHasDailyData(actual:ProjectRoleActualHours, weekKey:string){
	let weekTime = new Date(weekKey).getTime();
	let nextWeekTime = weekTime + 7*24*60*60*1000;
	for(var i in actual.hours){
		let entry = actual.hours[i];
		let entryTime = new Date(entry.date).getTime();
		if(entryTime > weekTime && entryTime < nextWeekTime){
			return true;
		}
		if( entryTime >= nextWeekTime){ // We can stop looking because the array is sorted
			return false;
		}
	}
	return false;
}

export function actualCalculateWeekHours(actual:ProjectRoleActualHours, weekKey:string){
	let weekTime = new Date(weekKey).getTime();
	let nextWeekTime = weekTime + 7*24*60*60*1000;
	let hours = 0;
	for(var i in actual.hours){
		let entry = actual.hours[i];
		let entryTime = new Date(entry.date).getTime();
		if(entryTime >= weekTime && entryTime < nextWeekTime){
			hours += entry.hours;
		}
		if( entryTime >= nextWeekTime){ // We can stop looking because the array is sorted
			return hours;
		}
	}
	return hours;
}