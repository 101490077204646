

import React, { useEffect, useState } from "react"
import "./ProductionSchedulePersonRow.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Client } from "../../../FirebaseModel/Client";
import { ClientContact } from "../../../FirebaseModel/ClientContact";
import { Organization, holiday } from "../../../FirebaseModel/Organization";
import { Department } from "../../../FirebaseModel/Department";
import { Person, findNonProjectScheduleBlocksForDate } from "../../../FirebaseModel/Person";
import { Project, findScheduleBlockForDate } from "../../../FirebaseModel/Project";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shortDate } from "../../../Utils/formatting";
import { addDoc, collection } from "firebase/firestore";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { UndoStackEntryType, UndoStackEntryObjectType } from "../../../Utils/UndoStackEntry";
interface ProductionSchedulePersonRowProps {
	filterClientID: string;
	filterRoleID: string;
	filterSearch: string;
	personID: string;
	collapsed: boolean;
	departmentCollapsed: boolean;
	numMonths: number;
	onCollapsedChanged: (personID: string, collapsed: boolean) => void;
}


export default function ProductionSchedulePersonRow(props: ProductionSchedulePersonRowProps) {

	const organizationID = useSelector((state: { organizationID: string }) => state.organizationID)
	const organization = useSelector((state: { organization: Organization }) => state.organization)
	const persons = useSelector((state: { persons: Person[] }) => state.persons)
	const projects = useSelector((state: { projects: Project[] }) => state.projects)
	const actuals = useSelector((state: { actuals: ProjectRoleActualHours[] }) => state.actuals)
	const clientContacts = useSelector((state: { clientContacts: ClientContact[] }) => state.clientContacts)
	const departments = useSelector((state: { departments: Department[] }) => state.departments)
	const timeOffList = useSelector((state:{timeOffList:TimeOff[]}) => state.timeOffList)

	const dispatch = useDispatch();
	const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles,
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	const [months, setMonths] = useState<Date[]>([]);
	const [days, setDays] = useState<Date[]>([]);

	const today = new Date();

	const [person, setPerson] = useState<Person>(persons.find(person => person.id === props.personID)!);

	useEffect(() => {
		setPerson(persons.find(person => person.id === props.personID)!);
	}, [props.personID])

	useEffect(() => {
		let months: Date[] = [];
		let date = new Date();
		for (let i = 0; i < props.numMonths; i++) {
			months.push(new Date(date.getFullYear(), date.getMonth() + i, 1));
		}
		setMonths(months);

		// add every day from months into the days array
		let days: Date[] = [];
		months.forEach(month => {
			let date = new Date(month);
			while (date.getMonth() === month.getMonth()) {
				days.push(new Date(date));
				date.setDate(date.getDate() + 1);
			}
		})
		setDays(days);

		const buildPTOLookup = async () =>{
			let newPTOLookup:{[year:string]:TimeOff} = {};
			for(var i  in days){
				let year = days[i].getFullYear().toString();
				if(!newPTOLookup[year]){
					newPTOLookup[year] = await createOrGetTimeOff(year);
				}
			}
			setPTOLookup(newPTOLookup);
		}
		buildPTOLookup();
	}, [props.numMonths])


	let completedStatuses: string[] = (organization.projectStatusCategories as any)["completed"] as string[];
	let personActuals = actuals.filter(actual => {
		let project = projects.find(project => project.id === actual.projectID && actual.roleID != "");
		if (project == undefined || completedStatuses.includes(project.status)) {
			return false;
		}
		return actual.personID === person.id
	});

	const [PTOLookup, setPTOLookup] = useState<{[year:string]:TimeOff}>({});

	const createOrGetTimeOff = async (year:string) =>{
		let timeOff = timeOffList.find(timeOff => timeOff.personID == person.id && timeOff.year == parseInt(year));
		if(timeOff == null){
			let allottedPTO = person.PTOPerYear;
			let allottedSTO = person.STOPerYear;
			if(organization.managesPTOAtOrganizationLevel){
				allottedPTO = organization.PTOPerYear;
				allottedSTO = organization.STOPerYear;
			}

			let timeOffDocData = {
				organizationID:organizationID,
				personID:person.id,
				year:parseInt(year),
				hoursTakenSTO:[],
				hoursTakenPTO:[],
				allottedPTO:allottedPTO,
				allottedSTO:allottedSTO,
				calculated:{
					totalHoursTakenPTO:0,
					totalHoursTakenSTO:0,
				}
			};
			let docref = await addDoc(collection(sharedFirestore,"TimeOff"),timeOffDocData);
			timeOff = {... timeOffDocData, id:docref.id} as TimeOff;

			AC.submitUndoStackEntry({
				type: UndoStackEntryType.create,
				objectType: UndoStackEntryObjectType.timeOff,
				objectID: timeOff.id,
				objectBeforeState: undefined,
				objectAfterState: {...timeOff},
				description: 'Create Time Off'
			})
		}
		return timeOff
	}

	const scheduledHoursOnGivenDay = (day: Date) => {
		let totalHours = 0;
		for (let a in personActuals) {
			let actual = personActuals[a];
			let project = projects.find(project => project.id === actual.projectID);
			if (project) {
				for (let i = 0; i < project.projectRoleTasks.length; i++) {
					let roleTask = project.projectRoleTasks[i];
					if(roleTask.roleID != actual.roleID){
						continue;
					}
					for (let r in roleTask.tasks) {
						let task = roleTask.tasks[r];
						if (task.assignedPersonIDs.includes(person.id)) {
							let taskScheduleBlock = findScheduleBlockForDate(project!, person.id, roleTask.roleID, day)
							if (taskScheduleBlock && taskScheduleBlock.taskID == task.id) {
								//generate an index from the difference between the block start day and day
								let index = 0;
								let tempDay = new Date(taskScheduleBlock.startDate);
								while (tempDay < day) {
									index++;
									tempDay.setDate(tempDay.getDate() + 1);
								}

								totalHours += taskScheduleBlock.dailyPlannedHours[index];
							}
						}
					}
				}
			}
		}

		// add PTO hours
		let year = day.getFullYear().toString();
		if(PTOLookup[year]){
			let timeOff = PTOLookup[year];
			let hoursTaken = timeOff.hoursTakenPTO.find(hoursTaken => hoursTaken.date == shortDate(day));
			if(hoursTaken){
				totalHours += hoursTaken.hours;
			}
		}

		// add non-project task hours
		let taskScheduleBlocks = findNonProjectScheduleBlocksForDate(person, day);
		if (taskScheduleBlocks && taskScheduleBlocks.length > 0) {
			//generate an index from the difference between the block start day and day
			taskScheduleBlocks.forEach(taskScheduleBlock =>{
				let index = 0;
				let tempDay = new Date(taskScheduleBlock.startDate);
				while (tempDay < day) {
					index++;
					tempDay.setDate(tempDay.getDate() + 1);
				}

				totalHours += taskScheduleBlock.dailyPlannedHours[index];
			})
		}

		return totalHours > 0 ? totalHours : "";
	}

	return (
		<>
			<tr className={`ProductionSchedulePersonRow person ${props.departmentCollapsed ? "collapsed" : ""}`}>
				<td
					onClick={() => {
						props.onCollapsedChanged(person.id, !props.collapsed);
					}}>
					<span className="indicator"><FontAwesomeIcon icon={!props.collapsed ? faChevronDown : faChevronRight}></FontAwesomeIcon></span>
					{person.name}
				</td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
				{days.map((day, index) => {
					// add a header with the single letter day of the week
					let h = holiday(shortDate(day),organization)
					let isToday = day.getDate() == today.getDate() && day.getMonth() == today.getMonth() && day.getFullYear() == today.getFullYear();
					let isWeekend = day.getDay() === 0 || day.getDay() === 6;
					return <th key={index} className={`day ${isToday ? "today" : ""} ${isWeekend ? "weekend" : ""} ${h?"holiday":""}`}>{scheduledHoursOnGivenDay(day)}</th>
				})}
			</tr>
		</>
	)
}