
import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./ProjectDropdown.css"
import { useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { forceNumeric, prettyCurrency, prettyNum } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import { Client } from "../../FirebaseModel/Client";
interface ProjectDropdownProps{
	className?:string,
	defaultValue?:string,
	clientID?:string,
	prompt?:string,
	onChange?:(value:string) => void
	onCreateNew?:()=>void
}

export default function ProjectDropdown(props:ProjectDropdownProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
  const permissions = useSelector((state:{permissions:string[]}) => state.permissions)

	const [sortedFilteredProjects, setSortedFilteredProjects] = useState<Project[]>([]);
	const [selectedValue, setSelectedValue] = useState(props.defaultValue);

	useEffect(() =>{
		if(organization){

			let tempSortedClients:Project[] = []
			if(props.clientID){
				tempSortedClients = [... projects.filter((project:Project) => project.clientID == props.clientID)];
			}else{
				tempSortedClients = [... projects];
			}
			tempSortedClients.sort((a,b) =>{
				if(a.projectName.toLowerCase() < b.projectName.toLowerCase()){
					return -1;
				}
				if(a.projectName.toLowerCase() > b.projectName.toLowerCase()){
					return 1;
				}
				return 0;
			});
			setSortedFilteredProjects(tempSortedClients);
		}
	},[organization,props.clientID,projects])

	useEffect(() =>{
		setSelectedValue(props.defaultValue);
	},[props.defaultValue])

  return (
    <select 
				className={`ProjectDropdown ${props.className??""}`} 
				value={selectedValue}
				onChange={(event) =>{
					setSelectedValue(event.target.value);
					if(event.target.value == "create new"){
						if(props.onCreateNew){
							props.onCreateNew();
						}
					}else{
						if(props.onChange){
							props.onChange(event.target.value);
						}
					}
				}}>
			<option value={""}>{props.prompt??"Select Project"}</option>
			{sortedFilteredProjects.map((project:Project, index:number) =>{
				return <option key={"client_option_"+index} value={project.id}>{project.projectName}</option>
			})}
			{permissions.indexOf("Projects") != -1 && (
				<option value="create new">Create a new Project</option>
			)}
    </select>
  )
}