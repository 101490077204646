

import React, { useEffect, useState } from "react"
import "./ProjectContactsTable.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../FirebaseModel/Organization";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shortDate } from "../../Utils/formatting";
import { Client } from "../../FirebaseModel/Client";
import ClientContactDropdown from "../ClientContactDropdown/ClientContactDropdown";
import ProjectContactsTableCard from "../ProjectContactsTableCard/ProjectContactsTableCard";
import { ClientContact } from "../../FirebaseModel/ClientContact";

interface ProjectContactsTableProps{
	clientID:string;
	isReadOnly?:boolean;
	projectContacts:{clientContactID:string, primaryContact:boolean}[];
	onCreateNewClient:() => void;
	onProjectContactsChange:(projectContacts:{clientContactID:string, primaryContact:boolean}[]) => void;
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function ProjectContactsTable(props:ProjectContactsTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const clientContacts = useSelector((state:{clientContacts:ClientContact[]}) => state.clientContacts)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles},dispatch);

	const [projectContacts, setProjectContacts] = useState<{clientContactID:string, primaryContact:boolean}[]>([]);
	const [refreshingContacts, setRefreshingContacts] = useState(false);
	const [savingContacts, setSavingContacts] = useState(false);
	
	useEffect(() =>{
		setSavingContacts(false);
		let sortedContacts = [... props.projectContacts];
		sortedContacts.sort((a,b) => {
			let contactA = clientContacts.find((contact) => contact.id===a.clientContactID);
			let contactB = clientContacts.find((contact) => contact.id===b.clientContactID);
			if(!contactA || !contactB){
				return 0;
			}
			if(a.primaryContact && !b.primaryContact){
				return -1;
			}else if (!a.primaryContact && b.primaryContact){
				return 1;
			}
			let namePartsA = contactA!.name.split(" ");
			let lastNameA = namePartsA[namePartsA.length-1];
			let namePartsB = contactB!.name.split(" ");
			let lastNameB = namePartsB[namePartsB.length-1];
			if(lastNameA < lastNameB){
				return -1;
			}else if(lastNameA > lastNameB){
				return 1;
			}
			return 0;
		});
		setProjectContacts(sortedContacts);
	},[props.projectContacts]);
	

	useEffect(() =>{
		if(refreshingContacts){
			if(optionsTableTimeout){
				clearTimeout(optionsTableTimeout);
			}
			optionsTableTimeout = setTimeout(() =>{
				setRefreshingContacts(false);
			},100);
		}
	},[refreshingContacts]);
	
  return (
    <div className={`ProjectContactsTable`}>
			<h1>Client Contacts on this project</h1>
			<div className="contactsList">
				{projectContacts.map((projectClientContact:{clientContactID:string, primaryContact:boolean}, index:number) =>{
					let contact = clientContacts.find((contact) => contact.id===projectClientContact.clientContactID);
					if(!contact){
						return <></>
					}
					return (
						<ProjectContactsTableCard
							key={contact.name}
							isReadOnly={props.isReadOnly}
							contact={projectClientContact}
							onUpdate={(contact:{clientContactID:string, primaryContact:boolean}) =>{
								let newProjectContacts = [... projectContacts];
								newProjectContacts[index] = contact;
								setProjectContacts(newProjectContacts);
								props.onProjectContactsChange(newProjectContacts);
								setRefreshingContacts(true);
							}}
							onDelete={(contact:{clientContactID:string, primaryContact:boolean}) =>{
								let newProjectContacts = [... projectContacts];
								newProjectContacts.splice(index,1);
								setProjectContacts(newProjectContacts);
								props.onProjectContactsChange(newProjectContacts);
								setRefreshingContacts(true);
							}}></ProjectContactsTableCard>
					)
				})}
			</div>		
			{props.isReadOnly ? <></> : (
				<>
					Add new: &nbsp;
				<ClientContactDropdown
					clientID={props.clientID}
					defaultValue={""}
					onChange={(clientContact) =>{
						if(projectContacts.find((contact) => contact.clientContactID===clientContact.id)){
							return;
						}
						let newProjectContacts = [... projectContacts];
						newProjectContacts.push({clientContactID:clientContact.id, primaryContact:false});
						setProjectContacts(newProjectContacts);
						props.onProjectContactsChange(newProjectContacts);
						setRefreshingContacts(true);
					}}
					onCreateNew={()=>{
						props.onCreateNewClient();
					}}></ClientContactDropdown>
				</>
			)}
			
    </div>
  )
}