export const stringToBase64 = (input: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  let base64 = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  let padding = 0;
  for (let i = 0; i < data.length; i += 3) {
    const a = data[i];
    const b = data[i + 1];
    const c = data[i + 2];

    const index1 = a >> 2;
    const index2 = ((a & 3) << 4) | (b >> 4);
    const index3 = ((b & 15) << 2) | (c >> 6);
    const index4 = c & 63;

    if (isNaN(b)) {
      padding = 2;
    } else if (isNaN(c)) {
      padding = 1;
    }

    base64 += characters[index1] + characters[index2] + characters[index3] + characters[index4];
  }

  return base64.slice(0, base64.length - padding) + (padding === 2 ? '==' : padding === 1 ? '=' : '');
}