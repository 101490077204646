
import React, { useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./WeeklyTimesheetHeader.css"
import { useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { prettyCurrency, prettyNum, shortDate } from "../../../Utils/formatting";

export enum WeeklyTimesheetHeaderType{
	label,
	label2,
	billableTotal,
	internalTotal,
	grandTotal,
	timeOffTotal
}

interface WeeklyTimesheetHeaderProps{
	type:WeeklyTimesheetHeaderType
	year:number,
	className?:string,
	weekKey?:string,
	weekKeys:string[],
	total?:number,
  isReadOnly?:boolean,
	weeklyAverage?:number,
	totalByWeek?:{[key:string]:number},
	allSelected?:boolean,

	goToDailyInput?:(weekKey:string) => void;
	onSelectAll?:(allSelected:boolean) => void;
}

export default function WeeklyTimesheetHeader(props:WeeklyTimesheetHeaderProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)

	const weekDays = ["S","M","T","W","T","F","S"];

	const today = new Date();
	const todayWeekKey = shortDate(today);//`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`;


  return (
		<>
		{props.type==WeeklyTimesheetHeaderType.label && (
			<tr className={`WeeklyTimesheetHeader label ${props.className??""}`}>
				<th colSpan={5} className='sticky1_5'></th>
				<th className="sticky6">{props.className=="PTO"?"Total Used":"Totals"}</th>
				{props.weekKeys && props.weekKeys.map((key:string, index:number) =>{
					return <th key={`week_label_${index}`} className={`date ${key == todayWeekKey?'today':''}`}
						onClick={() => {props.goToDailyInput!(key)}}>{key}</th>
				})}
				<th>{props.weekKey?"Daily":"Weekly"} Average</th>
				<th>Total</th>
			</tr>
		)}
		{props.type==WeeklyTimesheetHeaderType.label2 && (
			<tr className={`WeeklyTimesheetHeader label ${props.className??""}`}>
				<th className='sticky1'>Billable</th>
				<th className="sticky2 left">Client</th>
				<th className="sticky3 center">Project #</th>
				<th className="sticky4 left">Project Name</th>
				<th className="sticky5 left">Role</th>
				<th className="sticky6">Totals</th>
				{props.weekKeys && props.weekKeys.map((key:string, index:number) =>{
					return <th key={`week_label_${index}`} className={`date ${key == todayWeekKey?'today':''}`}
						onClick={() => {props.goToDailyInput!(key)}}>{props.weekKey?(weekDays[(new Date(key)).getDay()]):key}</th>
				})}
				<th colSpan={2}></th>
			</tr>
		)}
		{props.type==WeeklyTimesheetHeaderType.billableTotal && (
			<tr className={`WeeklyTimesheetHeader billableTotal ${props.className??""}`}>
				<th colSpan={5}  className='sticky1_5 right'>Billable Total</th>
				<th className="sticky6">{prettyNum(props.total!)}</th>
				{props.weekKeys && props.weekKeys.map((key:string, index:number) =>{
					return <th key={`week_billable_total_${index}`}>{prettyNum(props.totalByWeek![key])}</th>
				})}
				<th>{prettyNum(props.weeklyAverage!)}</th>
				<th>{prettyNum(props.total!)}</th>
			</tr>
		)}
		{props.type==WeeklyTimesheetHeaderType.internalTotal && (
			<tr className={`WeeklyTimesheetHeader internalTotal ${props.className??""}`}>
				<th colSpan={5} className='sticky1_5 right'>Internal Total</th>
				<th className="sticky6">{prettyNum(props.total!)}</th>
				{props.weekKeys && props.weekKeys.map((key:string, index:number) =>{
					return <th key={`week_internal_total_${index}`}>{prettyNum(props.totalByWeek![key])}</th>
				})}
				<th>{prettyNum(props.weeklyAverage!)}</th>
				<th>{prettyNum(props.total!)}</th>
			</tr>
		)}
		{props.type==WeeklyTimesheetHeaderType.grandTotal && (
			<tr className={`WeeklyTimesheetHeader grandTotal ${props.className??""}`}>
				<th colSpan={5} className='sticky1_5 right'>
          {!props.isReadOnly && (
            <div className='selectAll hide-from-report'>
              <input type='checkbox' className="hide-from-report" checked={props.allSelected} onChange={(e) => {props.onSelectAll!(e.target.checked)}}></input>
              Select All
            </div>
          )}Grand Total</th>
				<th className="sticky6">{prettyNum(props.total!)}</th>
				{props.weekKeys && props.weekKeys.map((key:string, index:number) =>{
					return <th key={`week_grand_total_${index}`}>{prettyNum(props.totalByWeek![key])}</th>
				})}
				<th>{prettyNum(props.weeklyAverage!)}</th>
				<th>{prettyNum(props.total!)}</th>
			</tr>
		)}
		{props.type==WeeklyTimesheetHeaderType.timeOffTotal && (
			<tr className={`WeeklyTimesheetHeader timeOffTotal ${props.className??""}`}>
				<th className='sticky1'>Time Off</th>
				<th className='sticky2'></th>
				<th className='sticky3'>Allotted</th>
				<th className='sticky4'>Rollover</th>
				<th className='sticky5'>Remaining</th>
				<th className="sticky6">{prettyNum(props.total!)}</th>
				{props.weekKeys && props.weekKeys.map((key:string, index:number) =>{
					return <th key={`time_off_total_${index}`}>{prettyNum(props.totalByWeek![key])}</th>
				})}
				<th>{prettyNum(props.weeklyAverage!)}</th>
				<th>{prettyNum(props.total!)}</th>
			</tr>
		)}
		</>
  )
}