import { Dispatch } from "react";
import { AnyAction } from "redux";
import { Client } from "../FirebaseModel/Client";
import { Person } from "../FirebaseModel/Person";
import { Project } from "../FirebaseModel/Project";
import { Role } from "../FirebaseModel/Role";
import { TimeOff } from "../FirebaseModel/TimeOff";
import { Organization } from "../FirebaseModel/Organization";
import { Screens } from "./Navigation";
import { MonthTotal } from "../FirebaseModel/MonthTotal";
import { ProjectRoleActualHours } from "../FirebaseModel/ProjectRoleActualHours";
import { UndoStackEntry } from "../Utils/UndoStackEntry";
import { Department } from "../FirebaseModel/Department";
import { ClientContact } from "../FirebaseModel/ClientContact";
import { View } from "../FirebaseModel/View";
import ProjectMessageBoard from "../FirebaseModel/ProjectMessageBoard";
import { Backup } from "../FirebaseModel/Backup";

export type NumberAction = {
  type:string,
  payload:number
} 
export type ScreenAction = {
  type:string,
  payload:Screens|undefined
} 
export type StringAction = {
  type:string,
  payload:string
} 
export type SimpleAction = {
  type:string
} 
export type BooleanAction = {
  type:string,
  payload:boolean
}


export function setScreen(screen:Screens){
  return (dispatch: Dispatch<ScreenAction>) =>{ 
    dispatch({
      type:"setScreen",
      payload:screen
    })
    dispatch({
      type:"pushScreen",
      payload:screen
    })
  }
}
export function popScreen(screenStack:Screens[]){
  return (dispatch: Dispatch<ScreenAction>) =>{ 
    dispatch({
      type:"setScreen",
      payload:screenStack[screenStack.length-2]
    })
    dispatch({
      type:"popScreen",
      payload:undefined
    })
  }
}
export function setOrganizationID(organizationID:string){
  return (dispatch: Dispatch<StringAction>) =>{ 
    dispatch({
      type:"setOrganizationID",
      payload:organizationID
    })
  }
}


export function setOrganization(organization:Organization){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setOrganization",
      payload:organization
    })
  }
}

export function setProjects(projects:Project[]){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setProjects",
      payload:projects
    })
  }
}
export function setProjectMessageBoards(projectMessageBoards:ProjectMessageBoard[]){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setProjectMessageBoards",
      payload:projectMessageBoards
    })
  }
}

export function setPersons(persons:Person[]){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setPersons",
      payload:persons
    })
  }
}

export function setClients(clients:Client[]){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setClients",
      payload:clients
    })
  }
}

export function setClientContacts(clientContacts:ClientContact[]){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setClientContacts",
      payload:clientContacts
    })
  }
}

export function setDepartments(departments:Department[]){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setDepartments",
      payload:departments
    })
  }
}

export function setRoles(roles:Role[]){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setRoles",
      payload:roles
    })
  }
}

export function setBackups(backups:Backup[]){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setBackups",
      payload:backups
    })
  }
}


export function setTimeOffList(timeOffList:TimeOff[]){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setTimeOffList",
      payload:timeOffList
    })
  }
}


export function setMonthTotals(monthTotals:MonthTotal[]){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setMonthTotals",
      payload:monthTotals
    })
  }
}
export function setActuals(actuals:ProjectRoleActualHours[]){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setActuals",
      payload:actuals
    })
  }
}
export function setPermissions(permissions:string[]){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setPermissions",
      payload:permissions
    })
  }
}
export function setPermissionsWrite(permissions:string[]){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setPermissionsWrite",
      payload:permissions
    })
  }
}

export function setViews(view:View[]){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"setViews",
      payload:view
    })
  }
}


export function undo(){
  return (dispatch: Dispatch<SimpleAction>) =>{ 
    dispatch({
      type:"undo"
    })
  }
}

export function redo(){
  return (dispatch: Dispatch<SimpleAction>) =>{ 
    dispatch({
      type:"redo"
    })
  }
}
export function submitUndoStackEntry(entry:UndoStackEntry){
  return (dispatch: Dispatch<AnyAction>) =>{ 
    dispatch({
      type:"submitUndoStackEntry",
      payload:entry
    })
  }
}





export default {
  setScreen,
  popScreen,
	setOrganizationID,
	setOrganization,
  setProjects,
  setProjectMessageBoards: setProjectMessageBoards,
	setPersons,
	setClients,
  setClientContacts,
	setDepartments,
	setRoles,
  setTimeOffList,
  setMonthTotals,
  setActuals,
  setPermissions,
  setPermissionsWrite,
  setViews,
  submitUndoStackEntry,
  setBackups,
  undo,
  redo
};

