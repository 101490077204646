


import React, { ReactNode, useEffect, useState } from "react"
import "./ProjectPortalTable.css"
import { useDispatch, useSelector } from "react-redux";
import { Project, projectHourlyRate } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import ProjectPortalMonthTotalRow from "../ProjectPortalMonthTotalRow/ProjectPortalMonthTotalRow";
import ProjectPortalProjectRow from "../ProjectPortalProjectRow/ProjectPortalProjectRow";
import ProjectPortalHeader, { ProjectPortalHeaderType } from "../ProjectPortalHeader/ProjectPortalHeader";
import { Role } from "../../../FirebaseModel/Role";
import { Client } from "../../../FirebaseModel/Client";
import { getOrCreateMonthTotal, saveMonthTotalAttributeChange } from "../../../Utils/SaveMonthTotalFunctions";
import { MonthTotal } from "../../../FirebaseModel/MonthTotal";
import DraggableTableBody from "../../DraggableTableBody/DraggableTableBody";
import { Person } from "../../../FirebaseModel/Person";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { View } from "../../../FirebaseModel/View";
import { faPencil, faCheck, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { padDecimal, prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { cleanCSVString } from "../../../Utils/CleanCSVString";
import { stringToBase64 } from "../../../Utils/StringToBase64";

interface ProjectPortalTableProps {
	columns: string[],
	filterFlag: string,
	filterStatus: string,
	filterStartMonth: string,
	filterEndMonth: string,
	filterStartYear: string,
	filterEndYear: string,
	filterSearch: string,
	filterBillable: string,
	filterProjectType: string,
	maxMonths: number,

	onCreateNewClientPressed: (project: Project) => void,
	onCreateNewPersonPressed: (project: Project) => void,
	onEditProjectPressed: (project: Project) => void

	onSelectedProjectsChanged: (projects: Project[]) => void

}

var projectLoadTimeout: NodeJS.Timeout | null = null;
var projectLoadFullTimeout: NodeJS.Timeout | null = null;


export default function ProjectPortalTable(props: ProjectPortalTableProps) {

	const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
	const organization = useSelector((state: { organization: Organization }) => state.organization)
	const allMonthTotals = useSelector((state: { monthTotals: MonthTotal[] }) => state.monthTotals)
	const clients = useSelector((state: { clients: Client[] }) => state.clients)
	const roles = useSelector((state: { roles: Role[] }) => state.roles)
	const persons = useSelector((state: { persons: Person[] }) => state.persons)
	const projects = useSelector((state: { projects: Project[] }) => state.projects)


	const [sortMonthAscending, setSortMonthAscending] = useState<boolean>(true);
	const [sortYearAscending, setSortYearAscending] = useState<boolean>(true);

	const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);

	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry: ActionCreators.submitUndoStackEntry
	}, dispatch);

	const [maxMonths, setMaxMonths] = useState<number>(12);


	const downloadTableAsCSV = () => {
		let csvContent = "";
		//build the header from the selectedcolumns in the view
		let header = "";
		props.columns.forEach((column, index) => {
			if(header != ""){
				header += ",";
			}
			header += cleanCSVString(column);
		})
		csvContent += header + "\n";

		//build the rows
		filteredProjects.forEach((project, index) => {
			let row = "";
			props.columns.forEach((column, index) => {
				if(row != ""){
					row += ",";
				}
				switch(column){
					case "Month Closed Won":
						row += project.monthClosedWon;
						return;
					case "Year Closed Won":
						row += project.yearClosedWon;
						return;
					case "Client":
						let client = clients.find(client => client.id === project.clientID);
						if(client == null){
							row += "";
						}else{
							row += cleanCSVString(client.name);
						}
						return;
					case "Project #":
						row += cleanCSVString(project.projectNumber);
						return;
					case "Project Name":
						row += project.projectName;
						return;
					case "Account Manager":
						let am = persons.find(person => person.id === project.accountManagerID);
						if(am == null){
							row += "";
						}else{
							row += cleanCSVString(am.name);
						}
						return;
					case "Regional Sales Manager":
						let rsm = persons.find(person => person.id === project.regionalSalesManagerID);
						if(rsm == null){
							row += "";
						}else{
							row += cleanCSVString(rsm.name);
						}
						return;
					case "Project Manager":
						let pm = persons.find(person => person.id === project.projectManagerID);
						if(pm == null){
							row += "";
						}else{
							row += cleanCSVString(pm.name);
						}
						return;
					case "SF Closed Won":
						row += project.sfClosedWon;
						return;
					case `${organization && (organization.shortName??"")} Production Budget`:{
						let hourlyRate = organization.hourlyRate;
						let client = clients.find((client:Client) => client.id == project.clientID);
						if(client !=null && client.useOverrideHourlyRate){
							hourlyRate = client.overrideHourlyRate;
						}
						if(project.useOverrideHourlyRate){
							hourlyRate = project.overrideHourlyRate;
						}
						let budget = project.calculated.plannedHours * hourlyRate;
						row += budget
						return;
					}
					case "Total Hours":
						row += project.calculated.plannedHours;
						return;
					case "Fixed Costs":
						row += project.fixedCosts??0;
						return;
					case "Extra Margin":
						row += project.extraMargin??0;
						return;
					case "Comments":
						row += cleanCSVString(project.comments);
						return;
					case "Project Type":
						row += cleanCSVString(project.projectType);
						return;
					case "Signed Contract?":
						row += project.isContractSigned?"Yes":"No";
						return;
					case "% of Budget Used":{
						let hourlyRate = organization.hourlyRate;
						let client = clients.find((client:Client) => client.id == project.clientID);
						if(client !=null && client.useOverrideHourlyRate){
							hourlyRate = client.overrideHourlyRate;
						}
						if(project.useOverrideHourlyRate){
							hourlyRate = project.overrideHourlyRate;
						}
						let used = project.calculated.actualHours * hourlyRate;
						let percent = 0;
						if(project.sfClosedWon > 0){
							percent = used / project.sfClosedWon;
						}
						row += padDecimal((Math.round(percent*10000)/100).toString(),2)+"%";
						return;
					}
					case "PM Estimated % Complete":
						row += padDecimal(prettyNum(project.pmEstimatedPercentComplete*100),2)+"%";
						return;
					case "AM % to Invoice":
						row += padDecimal(prettyNum(project.amPercentToInvoice*100),2)+"%";
						return;
					case "Latest Activity":
						row += project.calculated.latestActivity;
						return;
					case "Current +/- based on % complete":
						row += project.calculated.currentPlusMinus
						return;
					case "AM Notes":
						row += cleanCSVString(project.amNotes);
						return;
					case "PM Notes":
						row += cleanCSVString(project.pmNotes);
						return;
					case "Admin Notes":
						row += cleanCSVString(project.adminNotes);
						return;
					case "Difference Actual to Billed":
						row += project.calculated.differenceActualToBilled;
						return;
					case "Total Invoiced":
						row += project.calculated.totalInvoiced;
						return;
					case "Invoices Collected":
						row += project.calculated.invoicesCollected;
						return;
					case "Invoices Outstanding":
						row += project.calculated.invoicesOutstanding;
						return;
				}

				for(var i in roles){
					let role = roles[i];
					if(role.name === column){

						let hours = project.plannedHours.reduce((accumulator,hours) =>(accumulator + (hours.roleID==role.id?hours.hours:0)),0)
						row += padDecimal(prettyNum(hours),2)
						return;
					}
				}

			})
			
			csvContent += row + "\n";
		})

		let base64 = stringToBase64(csvContent);

		let dataUri = "data:text/csv;base64,"+base64;
		let link = document.createElement("a");
		link.setAttribute("href", dataUri);
		link.setAttribute("download", "project_portal.csv");
		
		document.body.appendChild(link); // Required for FF

		link.click(); // This will download the data file named "my_data.csv".

		document.body.removeChild(link);
	}

	useEffect(() => {
		let tempProjects: Project[] = [];
		
		projects.forEach(proj => {

			if (props.filterFlag !== "") {
				let statuses: string[] = (organization.projectStatusCategories as any)[props.filterFlag] as string[];
				if (statuses.filter(status => status === proj.status).length === 0) {
					return;
				}
			}
			if(props.filterStatus !== ""){
				if(proj.status !== props.filterStatus){
					return;
				}
			}
			// if the a filter is not "" then filter the projects based on filters and month and year closed won
			if ((props.filterStartYear !== "" && proj.yearClosedWon < parseInt(props.filterStartYear))) {
				return;
			}
			if (props.filterEndYear !== "" && proj.yearClosedWon > parseInt(props.filterEndYear)) {
				return;
			}
			if (props.filterStartMonth !== "" && proj.monthClosedWon < parseInt(props.filterStartMonth) &&
				(props.filterStartYear === "" || proj.yearClosedWon <= parseInt(props.filterStartYear))) {
				return;
			}
			if (props.filterEndMonth !== "" && proj.monthClosedWon > parseInt(props.filterEndMonth) &&
				(props.filterEndYear === "" || proj.yearClosedWon >= parseInt(props.filterEndYear))) {
				return;
			}

			if(props.filterProjectType !== "" && proj.projectType !== props.filterProjectType){
				return;
			}

			if (props.filterBillable !== "") {
				let hourlyRate = projectHourlyRate(proj, organization, clients);
				if (props.filterBillable == "billable" && hourlyRate == 0) {
					return;
				}
				if (props.filterBillable == "non-billable" && hourlyRate != 0) {
					return;
				}
			}

			if (props.filterSearch !== "" && clients && persons) {
				let lowerSearch = props.filterSearch.toLowerCase();
				let projNum = proj.projectNumber.toLowerCase();
				let projName = proj.projectName.toLowerCase();
				let projComments = proj.comments.toLowerCase();
				let projStatus = proj.status.toLowerCase();
				let client: Client | undefined = clients.find(c => c.id === proj.clientID);
				let AM: Person | undefined = persons.find(p => p.id === proj.accountManagerID);
				if (projNum.indexOf(lowerSearch) !== -1 ||
					projName.indexOf(lowerSearch) !== -1 ||
					projComments.indexOf(lowerSearch) !== -1 ||
					projStatus.indexOf(lowerSearch) !== -1 ||
					(client && client.name.toLowerCase().indexOf(lowerSearch) !== -1) ||
					(AM && AM.name.toLowerCase().indexOf(lowerSearch) !== -1)) {
					tempProjects.push(proj);
				}
				return;
			}

			tempProjects.push(proj);
		})

		tempProjects.sort((a, b) => {
			if (sortYearAscending) {
				if (a.yearClosedWon - b.yearClosedWon != 0) {
					return a.yearClosedWon - b.yearClosedWon > 0 ? 1 : -1;
				}
			} else {
				if (b.yearClosedWon - a.yearClosedWon != 0) {
					return b.yearClosedWon - a.yearClosedWon > 0 ? 1 : -1;
				}
			}
			if (sortMonthAscending) {
				if (a.monthClosedWon - b.monthClosedWon != 0) {
					return a.monthClosedWon - b.monthClosedWon > 0 ? 1 : -1;
				}
			} else {
				if (b.monthClosedWon - a.monthClosedWon != 0) {
					return b.monthClosedWon - a.monthClosedWon > 0 ? 1 : -1;
				}
			}

			let clientA = clients.find(c => c.id === a.clientID);
			let clientB = clients.find(c => c.id === b.clientID);
			if (clientA != null && clientB != null) {
				if (clientA.name.toLowerCase() < clientB.name.toLowerCase()) {
					return -1;
				}
				if (clientA.name.toLowerCase() > clientB.name.toLowerCase()) {
					return 1;
				}
			} else if (clientA != null) {
				return -1;
			} else if (clientB != null) {
				return 1;
			}

			if (a.projectName.toLowerCase() < b.projectName.toLowerCase()) {
				return -1;
			} else if (a.projectName.toLowerCase() > b.projectName.toLowerCase()) {
				return 1;
			}

			return 0;
		})
		if(tempProjects.length > 20){
			//first 100 projects are displayed
			setFilteredProjects(tempProjects.slice(0, 20));
			if(projectLoadFullTimeout){
				clearTimeout(projectLoadFullTimeout);
				projectLoadFullTimeout = null;
			}
			projectLoadFullTimeout =setTimeout(() => {
				setFilteredProjects(tempProjects)
			}, 500);
		}else{
			setFilteredProjects(tempProjects)
		}
		props.onSelectedProjectsChanged(selectedProjects);
	}, [projects, props.filterFlag, props.filterStartMonth, props.filterEndMonth, props.filterStartYear, props.filterEndYear, props.filterSearch, props.filterBillable, clients, persons, sortMonthAscending, sortYearAscending, selectedProjects, props.columns]);

	const buildDraggableSection = ((lastMonthTotal: MonthTotal, lastMonthsProjects: Project[], tbodys: ReactNode[], tbody: ReactNode[]) => {
		tbodys.push(
			<DraggableTableBody

				childClassName="ProjectPortalProjectRow"
				key={`month_body_${lastMonthTotal.year}_${lastMonthTotal.month}`}
				orderedItems={lastMonthsProjects.map((project: Project) => {return project.id})}
				onOrderUpdated={(newOrder: string[]) => {

				}}
				onOrderUpdateEnded={(newOrder: string[]) => {
					saveMonthTotalAttributeChange(lastMonthTotal, "projectOrder", newOrder, (entry) => {
						AC.submitUndoStackEntry(entry);
					});
				}}
				keyForIndex={(index: number) => {
					return `${lastMonthsProjects[index].id}`;
				}}>
				{tbody.map((child: ReactNode, index: number) => {
					return child;
				})}
			</DraggableTableBody>
		);
	})

	useEffect(() => {
		if(filteredProjects && filteredProjects.length > 0 && organization && allMonthTotals && allMonthTotals.length > 0 && clients && clients.length > 0 && roles && roles.length > 0 && persons && persons.length > 0){
			if(projectLoadTimeout){
				clearTimeout(projectLoadTimeout);
				projectLoadTimeout = null;
			}
			projectLoadTimeout = setTimeout(() => {
				buildTable();
			}, 200);
		}else{
			setBodyItems([]);
		}
	}, [filteredProjects, organization, allMonthTotals, clients, roles, persons, props.maxMonths])



	const [headItems, setHeadItems] = useState<ReactNode[]>([]);
	const [bodyItems, setBodyItems] = useState<ReactNode[]>([]);

	const buildTable = async () => {
		if (!props.columns) {
			return;
		}
		const thead: ReactNode[] = [];
		thead.push(<ProjectPortalHeader columns={props.columns} key={"header"} type={ProjectPortalHeaderType.type1}></ProjectPortalHeader>)

		const tbodys: ReactNode[] = [];

		let lastProject: Project | null = null;

		let totalMonthSFClosedWon = 0;
		let totalMonthBudget = 0;
		let totalMonthHours = 0;
		let totalMonthFixedCosts = 0;
		let totalMonthExtraMargin = 0;
		let totalMonthHoursByRole: any = {};

		let totalSFClosedWon = 0;
		let totalBudget = 0;
		let totalHours = 0;
		let totalFixedCosts = 0;
		let totalExtraMargin = 0;
		let totalHoursByRole: any = {};


		let tbody: ReactNode[] = [];
		let lastMonthsProjects: Project[] = [];

		if (organization != null) {

			for (let r in roles) {
				let role = roles[r];
				totalMonthHoursByRole[role.id] = 0;
				totalHoursByRole[role.id] = 0;
			}


			let addFinalTotal = false;

			for (let i in filteredProjects) {
				let project = filteredProjects[i];

				if (lastProject != null && (project.yearClosedWon != lastProject.yearClosedWon || project.monthClosedWon != lastProject.monthClosedWon)) {


					const lastMonthTotal = await getOrCreateMonthTotal(lastProject.monthClosedWon, lastProject.yearClosedWon, allMonthTotals, organization, (entry) => {
						AC.submitUndoStackEntry(entry);
					});

					tbody.sort((a, b) => {
						let anyA = a as any;
						let anyB = b as any;
						if (lastMonthTotal.projectOrder.indexOf(anyA.key) < lastMonthTotal.projectOrder.indexOf(anyB.key)) {
							return -1;
						}
						return 0;
					})

					buildDraggableSection(lastMonthTotal, lastMonthsProjects, tbodys, tbody);


					tbody = [];


					//add total line.
					tbodys.push(
						<tbody key={`total_row_${lastProject.monthClosedWon}_${lastProject.yearClosedWon}`}>
							<ProjectPortalMonthTotalRow
								columns={props.columns}
								month={lastProject.monthClosedWon}
								year={lastProject.yearClosedWon}
								sfClosedWon={totalMonthSFClosedWon}
								budget={totalMonthBudget}
								hours={totalMonthHours}
								fixedCosts={totalMonthFixedCosts}
								extraMargin={totalMonthExtraMargin}
								hoursByRole={{ ...totalMonthHoursByRole }}></ProjectPortalMonthTotalRow>
						</tbody>
					)
					//reset totals
					totalMonthSFClosedWon = 0;
					totalMonthBudget = 0;
					totalMonthHours = 0;
					totalMonthFixedCosts = 0;
					totalMonthExtraMargin = 0;
					for (let r in roles) {
						let role = roles[r];
						totalMonthHoursByRole[role.id] = 0;
					}
					lastMonthsProjects = [];
				}

				lastMonthsProjects.push(project);

				//add row for project
				tbody.push(
					<ProjectPortalProjectRow
						key={`project_row_${project.id}`}
						columns={props.columns}
						project={project}
						projectSelected={selectedProjects.includes(project)}
						setProjectSelected={(project, projectSelected) => {
							if (projectSelected) {
								setSelectedProjects([...selectedProjects, project]);
							} else {
								setSelectedProjects(selectedProjects.filter(p => p.id !== project.id));
							}
						}}
						onCreateNewClientPressed={(project) => {
							props.onCreateNewClientPressed(project);
						}}
						onCreateNewPersonPressed={(project) => {
							props.onCreateNewPersonPressed(project);
						}}
						onEditProjectPressed={(project) => {
							props.onEditProjectPressed(project);
						}}></ProjectPortalProjectRow>
				)


				//increment month totals
				totalMonthSFClosedWon += project.sfClosedWon;
				let hourlyRate = project.useOverrideHourlyRate ? project.overrideHourlyRate : organization.hourlyRate;
				let projectBudget = hourlyRate * project.calculated.plannedHours;
				totalMonthBudget += projectBudget;
				totalMonthHours += project.calculated.plannedHours;
				totalMonthFixedCosts += project.fixedCosts ?? 0;
				totalMonthExtraMargin += project.extraMargin ?? 0;
				// totalMonthBudget += project.fixedCosts??0;
				// totalMonthBudget += project.extraMargin??0;
				for (let r in roles) {
					let role = roles[r];
					for (let p in project.plannedHours) {
						let plannedHours = project.plannedHours[p];
						if (plannedHours.roleID == role.id) {
							totalMonthHoursByRole[role.id] += plannedHours.hours;
						}
					}
				}

				//increment grand totals
				totalSFClosedWon += project.sfClosedWon;
				totalBudget += projectBudget;
				totalHours += project.calculated.plannedHours;
				totalFixedCosts += project.fixedCosts ?? 0;
				totalExtraMargin += project.extraMargin ?? 0;



				// totalBudget += project.fixedCosts??0;
				// totalBudget += project.extraMargin??0;
				for (let r in roles) {
					let role = roles[r];
					for (let p in project.plannedHours) {
						let plannedHours = project.plannedHours[p];
						if (plannedHours.roleID == role.id) {
							totalHoursByRole[role.id] += plannedHours.hours;
						}
					}
				}



				lastProject = project;
				addFinalTotal = true;
			}

			if (addFinalTotal && lastProject != null) {

				const lastMonthTotal = await getOrCreateMonthTotal(lastProject.monthClosedWon, lastProject.yearClosedWon, allMonthTotals, organization, (entry) => {
					AC.submitUndoStackEntry(entry);
				});

				tbody.sort((a, b) => {
					let anyA = a as any;
					let anyB = b as any;
					if (lastMonthTotal.projectOrder.indexOf(anyA.key) < lastMonthTotal.projectOrder.indexOf(anyB.key)) {
						return -1;
					}
					return 0;
				})

				buildDraggableSection(lastMonthTotal, lastMonthsProjects, tbodys, tbody);

				tbodys.push(
					<tbody key={`total_row_${lastProject.monthClosedWon}_${lastProject.yearClosedWon}`}>
						<ProjectPortalMonthTotalRow
							columns={props.columns}
							month={lastProject.monthClosedWon}
							year={lastProject.yearClosedWon}
							sfClosedWon={totalMonthSFClosedWon}
							budget={totalMonthBudget}
							hours={totalMonthHours}
							fixedCosts={totalMonthFixedCosts}
							extraMargin={totalMonthExtraMargin}
							hoursByRole={{ ...totalMonthHoursByRole }}></ProjectPortalMonthTotalRow>
					</tbody>
				)
			}

			thead.push((
				<ProjectPortalHeader
					key={"grandTotal"}
					columns={props.columns}
					type={ProjectPortalHeaderType.grandTotal}
					hours={totalHours}
					budget={totalBudget}
					sfClosedWon={totalSFClosedWon}
					fixedCosts={totalFixedCosts}
					extraMargin={totalExtraMargin}
					hoursByRole={{ ...totalHoursByRole }}
					onSelectAllProjects={(selected) => {
						if (selected) {
							setSelectedProjects([...filteredProjects]);
						} else {
							setSelectedProjects([]);
						}
					}}></ProjectPortalHeader>
			))

			thead.push(
				<ProjectPortalHeader
					columns={props.columns}
					key={"header2"}
					type={ProjectPortalHeaderType.type2}
					onSortMonthAscending={(ascending) => setSortMonthAscending(ascending)}
					onSortYearAscending={(ascending) => setSortYearAscending(ascending)} />
			)

		}

		setHeadItems(thead);
		setBodyItems(tbodys);
	}



	return (
		<>
			<div className='buttonExportCSV' onClick={downloadTableAsCSV}><FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon>  Download CSV</div>
			<table
				className={`ProjectPortalTable`} >
				{props.columns && (
					<>
						<thead>
							{headItems.map((child: ReactNode, index: number) => {
								return child;
							})}
						</thead>
						{bodyItems.map((child: ReactNode, index: number) => {
							if (index / 2 >= props.maxMonths) {
								return <></>
							}
							return child;
						})}
					</>
				)}
			</table>
		</>
	)
}