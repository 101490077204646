
import React, { useEffect, useState } from "react"
import "./ProductionScheduleNonProjectTaskRow.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Client } from "../../../FirebaseModel/Client";
import { ClientContact } from "../../../FirebaseModel/ClientContact";
import { Organization, holiday } from "../../../FirebaseModel/Organization";
import { Person, PersonNonProjectTask, PersonNonProjectTaskScheduleBlock, findNonProjectScheduleBlocksForDate, findNonProjectTasksFromDueDate } from "../../../FirebaseModel/Person";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { addDoc, collection } from "firebase/firestore";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { UndoStackEntryType, UndoStackEntryObjectType } from "../../../Utils/UndoStackEntry";
import { Tooltip } from "@mui/material";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shortDate } from "../../../Utils/formatting";
import ProductionScheduleNonProjectTaskScheduleBlockDay from "../ProductionScheduleNonProjectTaskScheduleBlockDay/ProductionScheduleNonProjectTaskScheduleBlockDay";
import ProductionScheduleTaskDueDate from "../ProductionScheduleTaskDueDate/ProductionScheduleTaskDueDate";
interface ProductionScheduleNonProjectTaskRowProps{
	person:Person;
	numMonths:number;
	collapsed:boolean;
	departmentCollapsed:boolean;
	onAddScheduleBlock:(personID:string, startDate?:string) => void;
  onEditTaskScheduleBlock:(personNonProjectTaskScheduleBlock:PersonNonProjectTaskScheduleBlock) => void;
}


export default function ProductionScheduleNonProjectTaskRow(props:ProductionScheduleNonProjectTaskRowProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const clientContacts = useSelector((state:{clientContacts:ClientContact[]}) => state.clientContacts)
	const timeOffList = useSelector((state:{timeOffList:TimeOff[]}) => state.timeOffList)

	const today = new Date();

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles,
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	const [months, setMonths] = useState<Date[]>([]);
	const [days, setDays] = useState<Date[]>([]);


	
	useEffect(() =>{
		let months:Date[] = [];
		let date = new Date();
		for(let i = 0; i < props.numMonths; i++){
			months.push(new Date(date.getFullYear(), date.getMonth() + i, 1));
		}
		setMonths(months);

		// add every day from months into the days array
		let days:Date[] = [];
		months.forEach(month =>{
			let date = new Date(month);
			while(date.getMonth() === month.getMonth()){
				days.push(new Date(date));
				date.setDate(date.getDate() + 1);
			}
		})
		setDays(days);

	},[props.numMonths])
  return (
		<tr className={`ProductionScheduleNonProjectTaskRow ${(props.departmentCollapsed||props.collapsed)?"collapsed":""}`}>
			<td>Non Project Tasks</td>
			<td></td>
			<td></td>
			<td></td>
			<td></td>
			<td></td>
			{days.map((day, index) =>{
				// add a header with the single letter day of the week
				let h = holiday(shortDate(day),organization)
				let isToday = day.getDate() == today.getDate() && day.getMonth() == today.getMonth() && day.getFullYear() == today.getFullYear();
				let isWeekend = day.getDay() === 0 || day.getDay() === 6;
				let taskScheduleBlocks = findNonProjectScheduleBlocksForDate(props.person, day);
				let task:PersonNonProjectTask|undefined = undefined;
				if(taskScheduleBlocks && taskScheduleBlocks.length > 0){
					task = props.person.nonProjectTasks.find(task => task.id == taskScheduleBlocks[0]?.taskID);
				}
				let dueDates = findNonProjectTasksFromDueDate(props.person, day);
				return <td key={index} className={`day ${isToday?"today":""} ${isWeekend?"weekend":""} ${h?"holiday":""}`}>
					{taskScheduleBlocks.length>0 && (
						<Tooltip title={task!.name} placement="bottom">
							<div>
								<ProductionScheduleNonProjectTaskScheduleBlockDay
									nonProjectTaskScheduleBlock={taskScheduleBlocks[0]}
									day={day} 
									onEditNonProjectTaskScheduleBlock={(taskScheduleBlock) =>{
										props.onEditTaskScheduleBlock(taskScheduleBlock);
									} }></ProductionScheduleNonProjectTaskScheduleBlockDay>
							</div>
						</Tooltip>
					)}
					{dueDates.map((task) =>{
						return <ProductionScheduleTaskDueDate projectTask={task}></ProductionScheduleTaskDueDate>
					})}
					{taskScheduleBlocks.length==0 && dueDates.length == 0 && (
						<Tooltip title="Schedule Non Project Task" placement="bottom" enterDelay={500}>
							<div className='buttonAdd'
								onClick={() =>{
									props.onAddScheduleBlock(props.person.id,shortDate(day));
								}}>
									<FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
								</div>
						</Tooltip>
					)}
				</td>
			})}
		</tr>
  )
}