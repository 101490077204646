
import React, { useEffect, useState } from "react"
import { addDoc, collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./PopupProjectProfitabilityDetails.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import { Person } from "../../FirebaseModel/Person";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import { forceDecimal, forceMaxValue, forcePercentage, prettyCurrency, prettyNum } from "../../Utils/formatting";
import { saveOrganizationAttributeChange } from "../../Utils/SaveOrganizationFunctions";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { UndoStackEntry, UndoStackEntryType, UndoStackEntryObjectType } from "../../Utils/UndoStackEntry";
import { ProjectRoleActualHours } from "../../FirebaseModel/ProjectRoleActualHours";
interface PopupProjectProfitabilityDetailsProps{
	group:Project[],
	onDismiss:()=>void;
}

interface ProductionPerson{
	person:Person;
	hoursLogged:number;
}

export default function PopupProjectProfitabilityDetails(props:PopupProjectProfitabilityDetailsProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

		const organization = useSelector((state:{organization:Organization}) => state.organization)
		const clients = useSelector((state:{clients:Client[]}) => state.clients)
		const persons = useSelector((state:{persons:Person[]}) => state.persons)
		const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
	
	const [clientName, setClientName] = useState("");
	const [projectManagerName, setProjectManagerName] = useState("");
	const [dateCompleted, setDateCompleted] = useState("");
	const [totalResourceUsage, setTotalResourceUsage] = useState(0);
	const [profit, setProfit] = useState(0);
	const [profitMarginPercent, setProfitMarginPercent] = useState(0);
	const [markupPercent, setMarkupPercent] = useState(0);
	const [averageResourceHourlyCost, setAverageResourceHourlyCost] = useState(0);
	const [sfClosedWon, setSfClosedWon] = useState(0);
	const [actualHours, setActualHours] = useState(0);
	const [fixedCosts, setFixedCosts] = useState(0);
	const [productionPeople, setProductionPeople] = useState<ProductionPerson[]>([]);

	useEffect(()=>{
		let client = clients.find((client:Client) => client.id === props.group[0].clientID);
		if(client){
			setClientName(client.name);
		}
		let projectManager = persons.find((person:Person) => person.id === props.group[0].projectManagerID);
		if(projectManager){
			setProjectManagerName(projectManager.name);
		}

		let tempProductionPeople:ProductionPerson[] = [];

		actuals.forEach((actual:ProjectRoleActualHours) => {
			let groupIncludedProject = props.group.find((project:Project) => project.id === actual.projectID);
			if(groupIncludedProject){
				let person = persons.find((person:Person) => person.id === actual.personID);
				if(person){
					let productionPerson = tempProductionPeople.find((productionPerson:ProductionPerson) => productionPerson.person.id === person!.id);
					if(productionPerson){
						productionPerson.hoursLogged += actual.calculated.totalHours;
					}
					else{
						tempProductionPeople.push({
							person:person,
							hoursLogged:actual.calculated.totalHours
						});
					}
				}
			}
		});

		setProductionPeople(tempProductionPeople);

		
		let averageResourceHourlyCost = organization.averageResourceCost / organization.averageWorkWeeksPerYear / organization.targetAverageHoursPerWeek;
		setAverageResourceHourlyCost(averageResourceHourlyCost);	

		let sfClosedWon = props.group.reduce((total:number, project:Project) => total + project.sfClosedWon, 0);
		let fixedCosts = props.group.reduce((total:number, project:Project) => total + project.fixedCosts, 0);
		let actualHours = props.group.reduce((total:number, project:Project) => total + project.calculated.actualHours, 0);
		let totalResourceUsage = actualHours * averageResourceHourlyCost;
		let profit = sfClosedWon - totalResourceUsage - fixedCosts;
		let profitMarginPercent = profit / props.group.reduce((total:number, project:Project) => total + project.sfClosedWon, 0);
		let markupPercent = (sfClosedWon - totalResourceUsage) / totalResourceUsage;

		if(props.group[0].statusHistory.length>0){
			setDateCompleted(props.group[0].statusHistory[props.group[0].statusHistory.length-1].date);
		}

		setFixedCosts(fixedCosts)
		setSfClosedWon(sfClosedWon);
		setActualHours(actualHours);
		setTotalResourceUsage(totalResourceUsage);
		setProfit(profit);
		setProfitMarginPercent(profitMarginPercent);
		setMarkupPercent(markupPercent);
		
	},[organization, props.group,clients,persons])

	return (
		<div className={`popup PopupProjectProfitabilityDetails`}>
			<h1>Project Profitability Details</h1>

			<table className='projectInfoTable'>
				<tbody>
					<tr>
						<td>Client Name</td>
						<td>{clientName}</td>
					</tr>
					<tr>
						<td>Project #</td>
						<td>{props.group[0].projectNumber}</td>
					</tr>
					<tr>
						<td>Project Name</td>
						<td>{props.group[0].projectName}</td>
					</tr>
					<tr>
						<td>Project Manager</td>
						<td>{projectManagerName}</td>
					</tr>
					<tr>
						<td>Date Completed</td>
						<td>{dateCompleted}</td>
					</tr>
					<tr>
						<td>SF Closed Won</td>
						<td>{prettyCurrency(sfClosedWon)}</td>
					</tr>
				</tbody>
			</table>

			<table className='profitInfo'>
				<tbody>
					<tr>
						<td>Profit $</td>
						<td>{prettyCurrency(profit)}</td>
					</tr>
					<tr>
						<td>Profit Margin %</td>
						<td>{Math.round(profitMarginPercent*100)}%</td>
					</tr>
					<tr>
						<td>Markup %</td>
						<td>{Math.round(markupPercent*100)}%</td>
					</tr>
				</tbody>
			</table>
			
			<table className='productionInfo'>
				<thead>
					<tr>
						<th>Production Resource</th>
						<th>Hours Logged</th>
						<th>Resource Usage</th>
					</tr>
				</thead>
				<tbody>
					{productionPeople.map((productionPerson, index) => {
						return (
							<tr key={productionPerson.person.id}>
								<td>{productionPerson.person.name}</td>
								<td>{productionPerson.hoursLogged}</td>
								<td>{prettyCurrency(productionPerson.hoursLogged * averageResourceHourlyCost)}</td>
							</tr>
						)
					})}
					<tr className="total">
						<td>Total</td>
						<td>{prettyNum(actualHours)}</td>
						<td>{prettyCurrency(totalResourceUsage)}</td>
					</tr>
				</tbody>
			</table>

			<table className="fixedCosts">
				<thead>
					<tr>
						<th>Project Expenses</th>
						<th>Expense $</th>
					</tr>
				</thead>
				<tbody>
					{props.group.map((project,index) => {
						return <>
							{project.bidWorksheet.fixedCosts.map((fixedCost,index) => {
								if(fixedCost.cost == 0){
									return <></>
								}
								return (
									<tr key={`fixed_cost_${index}`}>
										<td>{fixedCost.fixedCostItem}</td>
										<td>{prettyCurrency(fixedCost.cost)}</td>
									</tr>
								)
							})}
						</>
					})}
					<tr className="total">
						<td>Total</td>
						<td>{prettyCurrency(fixedCosts)}</td>
					</tr>
				</tbody>
			</table>

			<div className="floatingButtons">
				<div className="buttonSave"
					onClick={() =>{
						props.onDismiss();
					}}>Done</div>
		</div>
	</div>
)
};