
import React, { useEffect, useState } from "react"
import { sharedFirestore } from "../Utils/SharedFirebase"
import { collection, doc, DocumentData, DocumentSnapshot, getDoc, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./OrganizationMonitor.css"
import { Project } from "../FirebaseModel/Project";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../FirebaseModel/Organization";
import { bindActionCreators } from "redux";
import ActionCreators from "../Redux/ActionCreators";
import { Client } from "../FirebaseModel/Client";
import { Person } from "../FirebaseModel/Person";
import { Role } from "../FirebaseModel/Role";
import OrganizationChildrenMonitor from "./OrganizationChildrenMonitor";
interface OrganizationMonitorProps{
}

export default function OrganizationMonitor(props:OrganizationMonitorProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setOrganization:ActionCreators.setOrganization},dispatch);  

	let organization = useSelector((state:{organization:Organization|null}) => state.organization)

	const [organizationReceived, setOrganizationReceived] = useState(false);

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)


	const organizationUpdated = (snapshot:DocumentSnapshot<DocumentData>) =>{
		let data = snapshot.data() as any;

		if(organization == null){
			organization = {
				...data,
				id:snapshot.id,
			} as Organization
		}else{
			for(var key in data){
				(organization as any)[key] = (data as any)[key];
			}
			organization.id = snapshot.id;
			organization.name = data.name
			organization.hourlyRate = data.hourlyRate
			organization.roleIDOrder = data.roleIDOrder
		}
		organization.offersProfitSharing = data.offersProfitSharing??false;
		organization.profitSharingPercentage = data.profitSharingPercentage??0.01;
		organization.organizationDescription = data.organizationDescription??"";
		organization.projectTypeList = data.projectTypeList??[];
		organization.fixedCostItemsList = data.fixedCostItemsList??[];
		organization.shortName = data.shortName??"";
		organization.averageResourceCost = data.averageResourceCost??0;
		organization.averageWorkWeeksPerYear = data.averageWorkWeeksPerYear??48;
		organization.targetAverageHoursPerWeek = data.targetAverageHoursPerWeek??38;
		organization.holidaysByYear = data.holidaysByYear??{};
		organization.organizationAdministratorPersonIDs = data.organizationAdministratorPersonIDs??[];
		organization.managesPTOAtOrganizationLevel = data.managesPTOAtOrganizationLevel??false;
		organization.PTOPerYear = data.PTOPerYear??0;
		organization.STOPerYear = data.STOPerYear??0;
		organization.PTOSTORenewal = data.PTOSTORenewal??"";
		organization.PTOExpiration = data.PTOExpiration??"";
		organization.STOExpiration = data.STOExpiration??"";
		
		AC.setOrganization(organization);

		setTimeout(() =>{
			setOrganizationReceived(true);
		},100)

		console.log("organization updated");
	}


	useEffect(() =>{				
		if(organizationID == ""){
			return;
		}
		const unsubscribeOrg = onSnapshot(doc(sharedFirestore, "Organization", organizationID), organizationUpdated);

		return () =>{
			unsubscribeOrg();
		}

	},[organizationID])



	const [organizationExists, setOrganizationExists] = useState(false);
	useEffect(() =>{
		if(organization != null){
			setOrganizationExists(true);
		}
	},[organization])


  return (
    <div className={`screen OrganizationMonitor ${organizationExists?"hidden":""}`}>
			{organizationReceived && (
				<OrganizationChildrenMonitor></OrganizationChildrenMonitor>
			)}
			Loading...
    </div>
  )
}