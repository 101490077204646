

import React, { ReactNode, useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ClientContactTableRow.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { Role, rolePersonsDescription } from "../../../FirebaseModel/Role";
import { Person, personCalculatedRoleWeightsFiltered, personRolesDescription } from "../../../FirebaseModel/Person";
import { deleteRole, saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { faAnchor, faBars, faCalendar, faCheck, faCircleNodes, faHamburger, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Tooltip } from "@mui/material";
import { savePersonAttributeChange, deletePerson } from "../../../Utils/SavePersonFunctions";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { Client } from "../../../FirebaseModel/Client";
import ClientsScreenClientsInput from "../ClientsScreenClientsInput/ClientsScreenClientsInput";
import { saveClientAttributeChange, deleteClient } from "../../../Utils/SaveClientFunctions";
import { UndoStackEntry } from "../../../Utils/UndoStackEntry";
import { prettyCurrency } from "../../../Utils/formatting";
import { ClientContact } from "../../../FirebaseModel/ClientContact";
import { ClientScreenClientsRowProps } from "../ClientScreenClientsRow/ClientScreenClientsRow";
import { deleteClientContact, saveClientContactAttributeChange } from "../../../Utils/SaveClientContactFunctions";
export interface ClientContactTableRowProps{
	clientContact:ClientContact;
	onClientContactChange:(clientContact:ClientContact) => void;
	onClientContactDelete:(clientContact:ClientContact) => void;
}

export default function ClientContactTableRow(props:ClientContactTableRowProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const allRoles = useSelector((state:{roles:Role[]}) => state.roles)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const projects = useSelector((state:{projects:Project[]}) => state.projects)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
	const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)


	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);
		
	const [roles, setRoles] = useState<Role[]>(allRoles);

	const [contact, setContact] = useState<ClientContact>({...props.clientContact});

	useEffect(() =>{
		setRoles([... allRoles]);
	},[allRoles]);
	
	return (
	<>
		{permissionsWrite.indexOf("Clients")>=0 && (
			<tr key={`contact_${props.clientContact.id}`}>
			<td>
				<input className={`clientName`}
					defaultValue={props.clientContact.name}
					onBlur={(event) =>{
						contact.name = event.target.value;
						props.onClientContactChange(contact);
						// saveClientContactAttributeChange(props.clientContact,"name",event.target.value,(entry) =>{
						// 	AC.submitUndoStackEntry(entry);
						// 	props.clientContact.name = event.target.value;
						// })
					}}></input>
			</td>
			<td>
				<input className={`clientTitle`}
					defaultValue={props.clientContact.title}
					onBlur={(event) =>{
						contact.title = event.target.value;
						props.onClientContactChange(contact);
						// saveClientContactAttributeChange(props.clientContact,"title",event.target.value,(entry) =>{
						// 	AC.submitUndoStackEntry(entry);
						// 	props.clientContact.title = event.target.value;
						// })
					}}></input>
			</td>
			<td>
				<input className={`clientEmail`}
					defaultValue={props.clientContact.email}
					onBlur={(event) =>{
						contact.email = event.target.value;
						props.onClientContactChange(contact);
						// saveClientContactAttributeChange(props.clientContact,"email",event.target.value,(entry) =>{
						// 	AC.submitUndoStackEntry(entry);
						// 	props.clientContact.email = event.target.value;
						// })
					}}></input>
			</td>
			<td>
				<input className={`clientPhone`}
					defaultValue={props.clientContact.phone}
					onBlur={(event) =>{
						contact.phone = event.target.value;
						props.onClientContactChange(contact);
						// saveClientContactAttributeChange(props.clientContact,"phone",event.target.value,(entry) =>{
						// 	AC.submitUndoStackEntry(entry);
						// 	props.clientContact.phone = event.target.value;
						// })
					}}></input>
			</td>
			<div className="buttonDelete"
				onClick={() =>{
					props.onClientContactDelete(props.clientContact);
					// deleteClientContact(props.clientContact,(entry) =>{
					// 	AC.submitUndoStackEntry(entry);
					// })
				}}><FontAwesomeIcon icon={faTrash} /></div>
		</tr>
		)}
	</>
  )
}