import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { Person } from "../FirebaseModel/Person";
import { ProjectRoleActualHours } from "../FirebaseModel/ProjectRoleActualHours";
import { Role } from "../FirebaseModel/Role";
import { sharedFirestore } from "./SharedFirebase";
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from "./UndoStackEntry";

export function saveProjectRoleActualHours(actual:ProjectRoleActualHours,  attributeKey:string|string[], value:any|any[],undoEntryHandler:(entry:UndoStackEntry)=>void){


	const projectRef = doc(sharedFirestore, "ProjectRoleActualHours", actual.id);

	let updatedActual:{ [x: string]: any; } = {};
	for(var key in actual){
		if(attributeKey instanceof Array){
			for(var key2 in attributeKey){
				if(key == attributeKey[key2]){
					updatedActual[key] = value[key2];
					break;
				}else{
					updatedActual[key] = (actual as any)[key];
				}
			}
		}else{
			if(attributeKey){
				if(key == attributeKey){
					updatedActual[key] = value;
				}else{
					updatedActual[key] = (actual as any)[key];
				}
			}else{
				updatedActual[key] = (actual as any)[key];
			}
		}
	}

	let beforeState = {...actual};
	let afterState = {...updatedActual};
	let event:UndoStackEntry = {
		type: UndoStackEntryType.update,
		objectType: UndoStackEntryObjectType.project,
		objectID: actual.id,
		objectBeforeState: beforeState,
		objectAfterState: afterState,
		description: "Update Project Role Actual Hours"
	}
	undoEntryHandler(event);
	return updateDoc(projectRef,updatedActual)
}


export function deleteProjectRoleActualHours(actual:ProjectRoleActualHours,undoEntryHandler:(entry:UndoStackEntry)=>void){

	let beforeState = {...actual};
	let event:UndoStackEntry = {
		type: UndoStackEntryType.delete,
		objectType: UndoStackEntryObjectType.project,
		objectID: actual.id,
		objectBeforeState: beforeState,
		objectAfterState: undefined,
		description: "Delete Project Role Actual Hours"
	}
	undoEntryHandler(event);
	return deleteDoc(doc(sharedFirestore, "ProjectRoleActualHours", actual.id));
}