import { Person } from "./Person";

export interface Role{
	id:string;
  organizationID:string;
  name:string;
  abbreviation:string;
  overrideHourlyRate:number;
  useOverrideHourlyRate:boolean;
	isPM:boolean;
	isAM:boolean;
	isRSM:boolean;
	isAdmin:boolean;
	isBillable:boolean;
	billableWeight:number;
	persons:{
		personID:string,
		weight:number
	}[]; // list of person IDs
}

export function rolesExistAM(roles:Role[]):boolean{
	for(var role of roles){
		if(role.isAM){
			return true;
		}
	}
	return false;
}
export function rolesExistPM(roles:Role[]):boolean{
	for(var role of roles){
		if(role.isPM){
			return true;
		}
	}
	return false;
}
export function rolesExistRSM(roles:Role[]):boolean{
	for(var role of roles){
		if(role.isRSM){
			return true;
		}
	}
	return false;
}


export function rolePersonsDescription(role:Role, persons:Person[]):string{
	let description = "";
	if(role.persons){
		for(var i in role.persons){
			let person = role.persons[i];
			for(var j in persons){
				let person2 = persons[j];
				if(person.personID === person2.id && person2.active){
					if(description != ""){
						description += ", ";
					}
					description += person2.name;
				}
			}
		}
	}
	return description;
}

export function rolePersonsDescription2(role:Role, persons:Person[], startDate:Date, endDate:Date):string{
	let description = "";
	if(role.persons){
		for(var i in role.persons){
			let person = role.persons[i];
			for(var j in persons){
				let person2 = persons[j];
				if(person.personID === person2.id){
					let personStartDate = new Date(person2.startDate??undefined);
					let personEndDate = new Date(person2.endDate??undefined);
					if(personStartDate > endDate || personEndDate < startDate){
						continue;
					}
					if(description != ""){
						description += ", ";
					}
					description += person2.name;
				}
			}
		}
	}
	return description;
}
export function rolePersonsWeightDescription(role:Role, persons:Person[]):string{
	let description = "";
	if(role.persons){
		for(var i in role.persons){
			let person = role.persons[i];
			for(var j in persons){
				let person2 = persons[j];
				if(person.personID === person2.id && person2.active){
					if(description != ""){
						description += ", ";
					}
					description += person2.name;
					if(person.weight != 1){
						description += " (" + person.weight + ")";
					}
				}
			}
		}
	}
	return description;
}

export function rolePersonsWeightDescription2(role:Role, persons:Person[], startDate:Date, endDate:Date):string{
	let description = "";
	if(role.persons){
		for(var i in role.persons){
			let person = role.persons[i];
			for(var j in persons){
				let person2 = persons[j];
				if(person.personID === person2.id){
					let personStartDate = new Date(person2.startDate??undefined);
					let personEndDate = new Date(person2.endDate??undefined);
					if(personStartDate > endDate || personEndDate < startDate){
						continue;
					}
					if(description != ""){
						description += ", ";
					}
					description += person2.name;
					if(person.weight != 1){
						description += " (" + person.weight + ")";
					}
					
				}
			}
		}
	}
	return description;
}