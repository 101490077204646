
import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./ClientDropdown.css"
import { useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { forceNumeric, prettyCurrency, prettyNum } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import { Client } from "../../FirebaseModel/Client";
interface ClientDropdownProps{
	className?:string,
	defaultValue?:string,
	prompt?:string,
	noCreation?:boolean,
	onChange?:(value:string) => void
	onCreateNew?:()=>void
}

export default function ClientDropdown(props:ClientDropdownProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)

	const [sortedClients, setSortedClients] = useState<Client[]>([]);
	const [selectedValue, setSelectedValue] = useState(props.defaultValue);

	useEffect(() =>{
		if(organization){
			let tempSortedClients = [... clients];
			tempSortedClients.sort((a,b) =>{
				if(a.name.toLowerCase() < b.name.toLowerCase()){
					return -1;
				}
				if(a.name.toLowerCase() > b.name.toLowerCase()){
					return 1;
				}
				return 0;
			});
			setSortedClients(tempSortedClients);
		}
	},[organization,clients])

	useEffect(() =>{
		setSelectedValue(props.defaultValue);
	},[props.defaultValue])

  return (
    <select 
				className={`ClientDropdown ${props.className??""}`} 
				value={selectedValue}
				onChange={(event) =>{
					setSelectedValue(event.target.value);
					if(event.target.value == "create new"){
						if(props.onCreateNew){
							props.onCreateNew();
						}
					}else{
						if(props.onChange){
							props.onChange(event.target.value);
						}
					}
				}}>
			<option value={""}>{props.prompt??"Select Client"}</option>
			{sortedClients.map((client:Client, index:number) =>{
				return <option key={"client_option_"+index} value={client.id}>{client.name}</option>
			})}
			{(permissionsWrite.indexOf("Clients") != -1 && !props.noCreation )&& (
				<option value="create new">Create a new Client</option>
			)}
    </select>
  )
}