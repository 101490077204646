

import "./MonthlyBSPPMTable.css"
import { useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { Person } from "../../../FirebaseModel/Person";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { useEffect, useState } from "react";
import { Project } from "../../../FirebaseModel/Project";
import { Client } from "../../../FirebaseModel/Client";
import { MonthTotal } from "../../../FirebaseModel/MonthTotal";
import MonthlyBSPHeader from "../MonthlyBSPHeader/MonthlyBSPHeader";
import MonthlyBSPRow from "../MonthlyBSPRow/MonthlyBSPRow";
import { prettyNum } from "../../../Utils/formatting";
import MonthlyBSPPMTableRow from "../MonthlyBSPPMTableRow/MonthlyBSPPMTableRow";
import DownloadableTable from "../../DownloadableTable/DownloadableTable";
interface MonthlyBSPPMTableProps{
	month:number,
	year:number,
	monthTotal:MonthTotal,
}



export default function MonthlyBSPPMTable(props:MonthlyBSPPMTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const roles = useSelector((state:{roles:Role[]}) => state.roles)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const projects = useSelector((state:{projects:Project[]}) => state.projects)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)

	const [pmIDs, setPMIDs] = useState<string[]>([])

	useEffect(() => {
		let pmIDs:string[] = []
		props.monthTotal.bspProjects.forEach((bspProject) => {
			let project = projects.find((project) => project.id == bspProject.projectID)
			if(project){
				let pmID = project.projectManagerID
				if(!pmIDs.includes(pmID)){
					pmIDs.push(pmID)
				}
			}
		})

		pmIDs.sort((a,b) => {
			let personA = persons.find((person) => person.id == a)
			let personB = persons.find((person) => person.id == b)
			if(personA && personB){
				return personA.name.localeCompare(personB.name)
			}
			return 0
		})

		setPMIDs(pmIDs)
	}, [props.monthTotal.bspProjects])


  return (
		<div className={`MonthlyBSPPMTableContainer`}>
			<DownloadableTable className={`MonthlyBSPPMTable`} fileName="Monthly BSP PM Table">
				<thead>
					<tr>
						<th>PM</th>
						<th>Quantity</th>
						<th># Of Projects</th>
						<th>$ Scope Start</th>
						<th>$ Scope End</th>
						<th>BSP</th>
					</tr>
				</thead>
				<tbody>
					{pmIDs.map((pmID, index) => {
						return <MonthlyBSPPMTableRow key={pmID} pmID={pmID} monthTotal={props.monthTotal} month={props.month} year={props.year}></MonthlyBSPPMTableRow>
					})}
				</tbody>
			</DownloadableTable>
		</div>
  )
}