

import React, { useEffect, useState } from "react"
import "./ProductionScheduleTable.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Client } from "../../../FirebaseModel/Client";
import { ClientContact } from "../../../FirebaseModel/ClientContact";
import { Organization } from "../../../FirebaseModel/Organization";
import ProductionScheduleTableHeader, { ProductionScheduleTableHeaderType } from "../ProductionScheduleTableHeader/ProductionScheduleTableHeader";
import ProductionScheduleDepartmentRow from "../ProductionScheduleDepartmentRow/ProductionScheduleDepartmentRow";
import { Department } from "../../../FirebaseModel/Department";
import { Person, PersonNonProjectTaskScheduleBlock } from "../../../FirebaseModel/Person";
import ProductionSchedulePersonRow from "../ProductionSchedulePersonRow/ProductionSchedulePersonRow";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { Project, ProjectTaskScheduleBlock } from "../../../FirebaseModel/Project";
import ProductionSchedulePersonProjectRoleRow from "../ProductionSchedulePersonProjectRoleRow/ProductionSchedulePersonProjectRoleRow";
import ProductionSchedulePTORow from "../ProductionSchedulePTORow/ProductionSchedulePTORow";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import ProductionScheduleNonProjectTaskRow from "../ProductionScheduleNonProjectTaskRow/ProductionScheduleNonProjectTaskRow";

interface ProductionScheduleTableProps{
  refreshCount:number;
  filterDepartmentID:string;
  filterPersonID:string;
	filterClientID:string;
	filterRoleID:string;
	filterSearch:string;
	numMonths:number;
	onAddScheduleBlock:(personID:string, roleID:string, projectID:string,startDate?:string) => void;
  onEditTaskScheduleBlock:(projectTaskScheduleBlock:ProjectTaskScheduleBlock) => void;
  onScheduleTimeOff:(timeOff:TimeOff, date:string) => void;
	onEditProject(project:Project):void;
  onAddNonProjectTaskScheduleBlock:(personID:string, startDate?:string) => void;
  onEditNonProjectTaskScheduleBlock:(personNonProjectTaskScheduleBlock:PersonNonProjectTaskScheduleBlock) => void;
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function ProductionScheduleTable(props:ProductionScheduleTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const departments = useSelector((state:{departments:Department[]}) => state.departments)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)
  const clientContacts = useSelector((state:{clientContacts:ClientContact[]}) => state.clientContacts)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles},dispatch);

  const [collapsedDepartments, setCollapsedDepartments] = useState<string[]>(departments.map(department => department.id));
  const [collapsedPeople, setCollapsedPeople] = useState<string[]>([]);//persons.map(person => person.id));

  let completedStatuses:string[] = (organization.projectStatusCategories as any)["completed"] as string[];

  const [refreshCount, setRefreshCount] = useState<number>(0);
  useEffect(() => {
    setRefreshCount(props.refreshCount);
  }, [props.refreshCount]);


  return (
    <table className={`ProductionScheduleTable`}>
			<thead>
        <ProductionScheduleTableHeader
          type={ProductionScheduleTableHeaderType.month}
          numMonths={props.numMonths}></ProductionScheduleTableHeader>
        <ProductionScheduleTableHeader
          type={ProductionScheduleTableHeaderType.day}
          numMonths={props.numMonths}></ProductionScheduleTableHeader>
        <ProductionScheduleTableHeader
          type={ProductionScheduleTableHeaderType.dayOfWeek}
          numMonths={props.numMonths}></ProductionScheduleTableHeader>
      </thead>
      <tbody>
        {refreshCount%2==0 && (
          <>
            {organization.departmentIDOrder.map(departmentID =>{
              if(props.filterDepartmentID !== "" && props.filterDepartmentID !== departmentID){
                return <></>
              }
              let departmentPersons = persons.filter(person => person.departmentID === departmentID && person.active);
              return (
                <>
                  <ProductionScheduleDepartmentRow
                    key={departmentID}
                    departmentID={departmentID}
                    collapsed={collapsedDepartments.includes(departmentID)}
                    onCollapsedChanged={(departmentID, collapsed) => {
                      if (collapsed) {
                        setCollapsedDepartments([...collapsedDepartments, departmentID]);
                      } else {
                        setCollapsedDepartments(collapsedDepartments.filter(id => id !== departmentID));
                      }
                    } } 
                    numMonths={props.numMonths}></ProductionScheduleDepartmentRow>
                    {collapsedDepartments.includes(departmentID) == false && departmentPersons.map(person =>{
                      if(props.filterPersonID !== "" && props.filterPersonID !== person.id){
                        return <></>
                      }
                      let personActuals = actuals.filter(actual => {
                        let project = projects.find(project => project.id === actual.projectID && actual.roleID != "");
                        if(project == undefined || completedStatuses.includes(project.status)){
                          return false;
                        }
                        return actual.personID === person.id
                      });
                      if(props.filterSearch != ""){
                        if(person.name.toLowerCase().indexOf(props.filterSearch.toLowerCase()) == -1 &&
                          personActuals.reduce((accumulator, actual) =>{
                            let project = projects.find(project => project.id === actual.projectID && actual.roleID != "");
                            if(project == undefined || completedStatuses.includes(project.status)){
                              return accumulator;
                            }
                            return accumulator || project.projectName.toLowerCase().indexOf(props.filterSearch.toLowerCase()) != -1
                          },false) == false){
                          return <></>;
                        }
                      }

                      return (
                        <>
                          <ProductionSchedulePersonRow 
                            key={person.id}
                            filterClientID={props.filterClientID}
                            filterRoleID={props.filterRoleID}
                            filterSearch={props.filterSearch}
                            personID={person.id} 
                            collapsed={collapsedPeople.includes(person.id)} 
                            departmentCollapsed={collapsedDepartments.includes(departmentID)}
                            numMonths={props.numMonths} 
                            onCollapsedChanged={(personID, collapsed) =>{
                              if(collapsed){
                                setCollapsedPeople([...collapsedPeople, personID]);
                              }else{
                                setCollapsedPeople(collapsedPeople.filter(id => id !== personID));
                              }
                            } }></ProductionSchedulePersonRow>
                            {collapsedPeople.includes(person.id) == false && personActuals.map((actual) =>{
                              return <ProductionSchedulePersonProjectRoleRow 
                                key={actual.id}
                                numMonths={props.numMonths} 
                                collapsed={collapsedPeople.includes(person.id)} 
                                departmentCollapsed={collapsedDepartments.includes(departmentID)}
                                actual={actual} 
                                project={projects.find(project => project.id === actual.projectID)!} 
                                person={person}
                                onEditProject={(project) =>{
                                  props.onEditProject(project);
                                } }
                                onEditTaskScheduleBlock={(projectTaskScheduleBlock) =>{
                                  props.onEditTaskScheduleBlock(projectTaskScheduleBlock);
                                } }
                                onAddScheduleBlock={(personID,roleID,projectID,startDate) =>{
                                  props.onAddScheduleBlock(personID,roleID,projectID,startDate);
                                } }></ProductionSchedulePersonProjectRoleRow>
                            })}
                            {collapsedPeople.includes(person.id) == false && person.nonProjectTasks.length > 0 && (
                              <ProductionScheduleNonProjectTaskRow 
                                person={person}
                                numMonths={props.numMonths}
                                collapsed={collapsedPeople.includes(person.id)}
                                departmentCollapsed={collapsedDepartments.includes(departmentID)} 
                                onAddScheduleBlock={(personID: string, startDate?: string | undefined) => {
                                  props.onAddNonProjectTaskScheduleBlock(personID, startDate);
                                } } 
                                onEditTaskScheduleBlock={(personNonProjectTaskScheduleBlock: PersonNonProjectTaskScheduleBlock) => {
                                  props.onEditNonProjectTaskScheduleBlock(personNonProjectTaskScheduleBlock);
                                } }></ProductionScheduleNonProjectTaskRow>
                            )}
                            {collapsedPeople.includes(person.id) == false && (
                              <ProductionSchedulePTORow 
                                person={person}
                                numMonths={props.numMonths}
                                collapsed={collapsedPeople.includes(person.id)} 
                                departmentCollapsed={collapsedDepartments.includes(departmentID)}
                                onScheduleTimeOff={(timeOff, date) =>{
                                  props.onScheduleTimeOff(timeOff, date);
                                }}></ProductionSchedulePTORow>
                            )}
                        </>
                      )
                    })}
                </>
              )
            })}
          </>
        )}
      </tbody>
    </table>
  )
}