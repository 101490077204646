

import "./AnnualBSPTable.css"
import { useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { Person } from "../../../FirebaseModel/Person";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { useEffect, useState } from "react";
import { Project } from "../../../FirebaseModel/Project";
import { Client } from "../../../FirebaseModel/Client";
import AnnualBSPHeader from "../AnnualBSPHeader/AnnualBSPHeader";
import AnnualBSPRow from "../AnnualBSPRow/AnnualBSPRow";
import { YearTotal } from "../../../FirebaseModel/YearTotal";
import DownloadableTable from "../../DownloadableTable/DownloadableTable";
interface AnnualBSPTableProps{
	year:number,
	yearTotal:YearTotal,
}



export default function AnnualBSPTable(props:AnnualBSPTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const roles = useSelector((state:{roles:Role[]}) => state.roles)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const projects = useSelector((state:{projects:Project[]}) => state.projects)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
	// export interface BSPColumn {
	// 	projectsCount:number;
	// 	projectsStalled:number;
	// 	projects1Percent:number;
	// 	projects2to9percent:number;
	// 	projects10to100percent:number;
	// 	projectsCompleted:number,
	// 	totalContractDollars:number;
	// 	averageProjectValue:number,
	// 	beginningScope:number;
	// 	endingScope:number;
	// 	resourcesHave:number;
	// 	averageBSPPerResourcePerWeek:number;
	// 	billableScopeProduced:number;
	// 	productionBudgetPreviousMonth:number;
	// 	bspToSalesRatio:number;
	// }

	let rows = [
		{
			label:"Current Projects ",
			key:"projectsCount",
			className:"topTop",
		},
		{
			label:"(Stalled) 0% Completed ",
			key:"projectsStalled",
			className:"topMid",
		},
		{
			label:"1% Completed ",
			key:"projects1Percent",
			className:"topMid",
		},
		{
			label:"2-9% Completed ",
			key:"projects2to9percent",
			className:"topMid",
		},
		{
			label:"(Active) 10-100% Completed ",
			key:"projects10to100percent",
			className:"topMid",
		},
		{
			label:"100% Completed Projects ",
			key:"projectsCompleted",
			className:"topMid",
		},
		{
			label:"Total Contract Dollars ",
			key:"totalContractDollars",
			isCurrency:true,
			className:"topMid",
		},
		{
			label:"Average Project Value ",
			key:"averageProjectValue",
			isCurrency:true,
			className:"topMid",
		},
		{
			label:"Beginning Scope $ ",
			key:"beginningScope",
			isCurrency:true,
			className:"topMid",
		},
		{
			label:"Ending Scope $ ",
			key:"endingScope",
			isCurrency:true,
			className:"topMid",
		},
		{
			label:"Number of Billable Employees ",
			key:"resourcesHave",
			className:"topMid",
		},
		{
			label:"Average BSP Per Employee Per Week ",
			key:"averageBSPPerResourcePerWeek",
			className:"topMid",
		},
		{
			label:"Number of Billable Employees (Weighted)",
			key:"resourcesHaveWeighted",
			className:"topBottom",
		},

		{
			label:"Average BSP Per Employee Per Week (Weighted) ",
			key:"averageBSPPerResourcePerWeekWeighted",
			className:"bottomTop",
		},
		{
			label:"Billable Scope Produced ($) ",
			key:"billableScopeProduced",
			isCurrency:true,
			showTotal:true,
			className:"bottomMid",
		},
		{
			label:"Production Budget Previous Month ",
			key:"productionBudgetPreviousMonth",
			isCurrency:true,
			showTotal:true,
			className:"bottomMid",
		},
		{
			label:"BSP / Sales Ratio",
			key:"productionBudgetPreviousMonth",
			isPercentage:true,
			calculateBSPSalesRatio:true,
			className:"bottomBottom",
		},
	]

	///

  return (
		<div className={`AnnualBSPTableContainer`}>
			<DownloadableTable className={`AnnualBSPTable`} fileName="Annual BSP">
				<thead>
					<AnnualBSPHeader year={props.year}></AnnualBSPHeader>
				</thead>
				<tbody>
					{
						rows.map((row,index) => {
							return <AnnualBSPRow 
								key={index} 
								row={row} 
								year={props.year}
								yearTotal={props.yearTotal}></AnnualBSPRow>
						})
					}
				</tbody>
			</DownloadableTable>
		</div>
  )
}