import { Timestamp } from "firebase/firestore";
import { Organization } from "./Organization";
import { Person } from "./Person";

export interface TimeOff{
	id:string;
  organizationID:string;
	personID:string;
	year:number;
	hoursTakenPTO:{
		date:string; // m/d/yyyy
		hours:number
	}[];
	hoursTakenSTO:{
		date:string; // m/d/yyyy
		hours:number
	}[];
	allottedPTO:number;
	allottedSTO:number;
	calculated:{
		totalHoursTakenPTO:number;
		totalHoursTakenSTO:number;
	};
}



export function timeOffHasDailySTOData(timeOff:TimeOff, weekKey:string){
	let weekTime = new Date(weekKey).getTime();
	let nextWeekTime = weekTime + 7*24*60*60*1000;
	for(var i in timeOff.hoursTakenSTO){
		let entry = timeOff.hoursTakenSTO[i];
		let entryTime = new Date(entry.date).getTime();
		if(entryTime > weekTime && entryTime < nextWeekTime){
			return true;
		}
		if( entryTime >= nextWeekTime){ // We can stop looking because the array is sorted
			return false;
		}
	}
	return false;
}

export function timeOffCalculateWeekSTOHours(timeOff:TimeOff, weekKey:string){
	let weekTime = new Date(weekKey).getTime();
	let nextWeekTime = weekTime + 7*24*60*60*1000;
	let hours = 0;
	for(var i in timeOff.hoursTakenSTO){
		let entry = timeOff.hoursTakenSTO[i];
		let entryTime = new Date(entry.date).getTime();
		if(entryTime >= weekTime && entryTime < nextWeekTime){
			hours += entry.hours;
		}
		if( entryTime >= nextWeekTime){ // We can stop looking because the array is sorted
			return hours;
		}
	}
	return hours;
}



export function timeOffHasDailyPTOData(timeOff:TimeOff, weekKey:string){
	let weekTime = new Date(weekKey).getTime();
	let nextWeekTime = weekTime + 7*24*60*60*1000;
	for(var i in timeOff.hoursTakenPTO){
		let entry = timeOff.hoursTakenPTO[i];
		let entryTime = new Date(entry.date).getTime();
		if(entryTime > weekTime && entryTime < nextWeekTime){
			return true;
		}
		if( entryTime >= nextWeekTime){ // We can stop looking because the array is sorted
			return false;
		}
	}
	return false;
}

export function timeOffCalculateWeekPTOHours(timeOff:TimeOff, weekKey:string){
	let weekTime = new Date(weekKey).getTime();
	let nextWeekTime = weekTime + 7*24*60*60*1000;
	let hours = 0;
	for(var i in timeOff.hoursTakenPTO){
		let entry = timeOff.hoursTakenPTO[i];
		let entryTime = new Date(entry.date).getTime();
		if(entryTime >= weekTime && entryTime < nextWeekTime){
			hours += entry.hours;
		}
		if( entryTime >= nextWeekTime){ // We can stop looking because the array is sorted
			return hours;
		}
	}
	return hours;
}

export interface PTOSTORemaining{
	annualPTO:number;
	rolloverPTO:number;
	annualSTO:number;
	rolloverSTO:number;
	usedPTO:number;
	usedSTO:number;
	remainingPTO:number;
	remainingSTO:number;
}

export function personRemainingPTOSTOForDate(person:Person|undefined, timeOffList:TimeOff[], date:Date){
	let result:PTOSTORemaining = {
		annualPTO:0,
		rolloverPTO:0,
		annualSTO:0,
		rolloverSTO:0,
		usedPTO:0,
		usedSTO:0,
		remainingPTO:0,
		remainingSTO:0
	};

	if(!person){
		return result;
	}

	let filteredTimeOffList = timeOffList.filter((timeOff)=>{
		return timeOff.year != null && timeOff.personID === person.id;
	});

	let sortedTimeOffList = filteredTimeOffList.sort((a,b)=>{
		return a.year - b.year;
	});


	let lastYearRollOverPTO = 0;
	let lastYearRollOverSTO = 0;
	for(let i in sortedTimeOffList){
		let timeOff = sortedTimeOffList[i];
		let preExpirationPTO = 0;
		let preExpirationSTO = 0;
		let postExpirationPTO = 0;
		let postExpirationSTO = 0;

		let PTOSTORenewal = new Date(person.PTOSTORenewal+"/"+timeOff.year);
		let PTOExpiration = new Date(person.PTOExpiration+"/"+timeOff.year);
		let STOExpiration = new Date(person.STOExpiration+"/"+timeOff.year);

		timeOff.hoursTakenPTO.forEach((entry)=>{
			let entryDate = new Date(entry.date);
			if(entryDate > date){
				return;
			}
			if(entryDate.getTime() < PTOExpiration.getTime()){
				preExpirationPTO += entry.hours;
			}else{
				postExpirationPTO += entry.hours;
			}
		})

		timeOff.hoursTakenSTO.forEach((entry)=>{
			let entryDate = new Date(entry.date);
			if(entryDate > date){
				return;
			}
			if(entryDate.getTime() < STOExpiration.getTime()){
				preExpirationSTO += entry.hours;
			}else{
				postExpirationSTO += entry.hours;
			}
		})

		if(timeOff.year == date.getFullYear()){
			if(date > PTOSTORenewal){
				result.annualPTO = timeOff.allottedPTO + lastYearRollOverPTO;
				result.annualSTO = timeOff.allottedSTO + lastYearRollOverSTO;
			}else{
				result.annualPTO = lastYearRollOverPTO;
				result.annualSTO = lastYearRollOverSTO;
			}
			result.rolloverPTO = lastYearRollOverPTO;
			result.rolloverSTO = lastYearRollOverSTO;
			result.usedPTO = preExpirationPTO + postExpirationPTO;
			result.usedSTO = preExpirationSTO + postExpirationSTO;
			result.remainingPTO = result.annualPTO - result.usedPTO;
			result.remainingSTO = result.annualSTO - result.usedSTO;
			break;
		}

		lastYearRollOverPTO = timeOff.allottedPTO + lastYearRollOverPTO - preExpirationPTO - postExpirationPTO;
		lastYearRollOverSTO = timeOff.allottedSTO + lastYearRollOverSTO - preExpirationSTO - postExpirationSTO;

	}


	return result;
}