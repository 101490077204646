
import React, { useEffect, useState } from "react"
import { addDoc, collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./PopupAddClientContact.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import { prettyNum, forceNumeric, prettyCurrency, forceDecimal } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { saveClientAttributeChange } from "../../Utils/SaveClientFunctions";
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from "../../Utils/UndoStackEntry";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { ClientContact } from "../../FirebaseModel/ClientContact";
interface PopupAddClientContactProps{
	clientID:string;
	onCancel:()=>void;
	onSave:(client:ClientContact) =>void;
	allowMultiple?:boolean;
}

export default function PopupAddClientContact(props:PopupAddClientContactProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)

	const [clientContactName, setClientContactName] = useState("");
	const [clientContactEmail, setClientContactEmail] = useState("");
	const [clientContactPhone, setClientContactPhone] = useState("");
	const [clientContactTitle, setClientContactTitle] = useState("");

	const client = clients.find((client) => client.id == props.clientID);

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);


// export interface ClientContact{
//   name:string;
//   title:string;
//   email:string;
//   phone:string;
// }

	const saveNewClientContact = () =>{
		return new Promise<ClientContact>((resolve,reject) =>{

			let contactDocData = {
				organizationID:organization.id,
				clientID:client!.id,
				name:clientContactName,
				title:clientContactTitle,
				email:clientContactEmail,
				phone:clientContactPhone,
			}


			addDoc(collection(sharedFirestore, "ClientContact"), contactDocData).then((docref) => {
				let contact = { ...contactDocData, id: docref.id } as ClientContact;
				let undoEntry:UndoStackEntry = {
					type: UndoStackEntryType.create,
					objectType: UndoStackEntryObjectType.clientContact,
					objectID: contact.id,
					objectBeforeState: undefined,
					objectAfterState: {...contact},
					description: "Create Client Contact"
				}
				AC.submitUndoStackEntry(undoEntry);
				resolve(contact);
			})

			
		})
	}



	const clearForm = () =>{
		setClientContactName("");
		setClientContactEmail("");
		setClientContactPhone("");
		setClientContactTitle("");
		setClearingForm(true);
		setTimeout(() =>{setClearingForm(false)}, 100);
	}

	const [clearingForm, setClearingForm] = useState(false);

  return (
    <div className={`popup PopupAddClientContact`}>
			<h1>New Client Contact (Client: {client!.name})</h1>
			{clearingForm == false && (
				<div className="content">

					<div className="topArea">

						<div className="row">
							<div className="col">
								<div className="label">Contact Name</div>
								<input className={`clientContactName`}
									defaultValue={clientContactName}
									onBlur={(event) =>{
										setClientContactName(event.target.value)
									}}></input>
							</div>

							<div className="col">
								<div className="label">Contact Email</div>
								<input className={`clientContactEmail`}
									defaultValue={clientContactEmail}
									onBlur={(event) =>{
										setClientContactEmail(event.target.value)
									}}></input>
							</div>

							<div className="col">
								<div className="label">Contact Phone</div>
								<input className={`clientContactPhone`}
									defaultValue={clientContactPhone}
									onBlur={(event) =>{
										setClientContactPhone(event.target.value)
									}}></input>
							</div>

							<div className="col">
								<div className="label">Contact Title</div>
								<input className={`clientContactTitle`}
									defaultValue={clientContactTitle}
									onBlur={(event) =>{
										setClientContactTitle(event.target.value)
									}}></input>
							</div>
						</div>
					</div>
				</div>
			)}

			<div className="floatingButtons">
				<div className="buttonCancel"
					onClick={() =>{
						props.onCancel();
					}}>Cancel</div>
				{props.allowMultiple ? (

					<DropdownButton 
					items={["Save & Close","Save & Add New"]} 
					selectedIndex={0} 
					executeFunction={(functionIndex: number) =>{
						switch(functionIndex){
							case 0:
								saveNewClientContact().then((contact) =>{
									props.onSave(contact);
								});
								return;
							case 1:
								saveNewClientContact().then(() =>{
									clearForm();
								});
								return;
						}
				} } ></DropdownButton>
				):(
					<div className="buttonSave"
						onClick={() =>{
							saveNewClientContact().then((contact) =>{
								props.onSave(contact);
							});
						}}>Save &amp; Close</div>
				)}
			</div>
		</div>
  )
}