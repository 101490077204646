


import React, { useEffect, useState } from "react"
import { sharedFirebaseAuth, sharedFirestore } from "../../Utils/SharedFirebase"
import { collection, deleteDoc, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where } from "firebase/firestore";
import "./HomeScreen.css"
import { Project } from "../../FirebaseModel/Project";
import { useSelector } from "react-redux";
import NavigationButtons from "../../Components/NavigationButtons/NavigationButtons";
import HomeTaskTable from "../../Components/Home/HomeTaskTable/HomeTaskTable";
import { View } from "../../FirebaseModel/View";
import { faPencil, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import ViewDropdown from "../../Components/ViewDropdown/ViewDropdown";
import { Person } from "../../FirebaseModel/Person";
import PopupAddProject from "../../Popups/PopupAddProject/PopupAddProject";
import PopupAddView from "../../Popups/PopupAddView/PopupAddView";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import DepartmentDropdown from "../../Components/DepartmentDropdown/DepartmentDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import RoleDropdown from "../../Components/RoleDropdown/RoleDropdown";
import ProjectTypeDropdown from "../../Components/ProjectTypeDropdown/ProjectTypeDropdown";
interface HomeScreenProps {
}

let slowSearchTimeout: NodeJS.Timeout | null = null;

export default function HomeScreen(props: HomeScreenProps) {

	const persons = useSelector((state: { persons: Person[] }) => state.persons)
	const person = persons.find((person: Person) => person.email.toLowerCase() == sharedFirebaseAuth.currentUser!.email!.toLowerCase())!;

	const [showingProjectPopup, setShowingProjectPopup] = useState(false)
	const [showingViewPopup, setShowingViewPopup] = useState(false)
	const views = useSelector((state: { views: View[] }) => state.views)

	const inputSearchRef = React.createRef<HTMLInputElement>();
	const [filterRole, setFilterRole] = useState<string>("")

	const [selectedProject, setSelectedProject] = useState<Project | null>(null);
	const [maxMonths, setMaxMonths] = useState<number>(12);

	const homeColumns = [
		"Project",
		"Role",
		"Task",
		"Planned Roles Hours",
		"Actual Role Hours",
		"Remaining",
		"Estimated % Complete"
	]


	const [filterClientID, setFilterClientID] = useState("");
	const [filterRoleID, setFilterRoleID] = useState("");
	const [filterProjectType, setFilterProjectType] = useState("");
	const [filterSearch, setFilterSearch] = useState("");

	const [selectedView, setSelectedView] = useState<View | null>(null);
	useEffect(() => {
		setSelectedView(views.find((view: View) => view.id == localStorage.getItem("homeSelectedView")) || null);
	}, [views]);

	const [tableOffsetTop, setTableOffsetTop] = useState<number>(0);
	const tableRef = React.createRef<HTMLDivElement>();
	const resize = () => {
		if (tableRef.current) {
			setTableOffsetTop(tableRef.current!.offsetTop);
		}
	}
	useEffect(() => {
		window.addEventListener("resize", resize);
		resize();
		return () => {
			window.removeEventListener("resize", resize);
		}
	}, [])

	useEffect(() => {
		let table = tableRef.current!;
		if (table) {
			const overrideWheel = (e: WheelEvent) => {
				if (table.scrollLeft === 0 && e.deltaX < 0) {
					e.preventDefault();
				}
			};
			table.addEventListener("wheel", overrideWheel);
			return () => {
				table.removeEventListener("wheel", overrideWheel);
			};
		}
	}, [tableRef]);

	return (
		<>
			<div className="screen HomeScreen">
				<NavigationButtons />
				<h1>Home</h1>

				<h2>These are all of the active tasks currently assigned to you</h2>

				<div className="viewSelector">
					<h3>View</h3>
					<div className="row">
						<Tooltip title="Control which columns show up below">
							<span>
								<ViewDropdown
									defaultValue={selectedView ? selectedView.id : ""}
									prompt="All Columns"
									viewType="home"
									columns={homeColumns}
									onChange={(viewID: string) => {
										let selectedView = views.find((view: View) => view.id == viewID) || null;
										setSelectedView(selectedView);

										localStorage.setItem("homeSelectedView", selectedView?.id || "");
										tableRef.current!.scrollTop = 0;
										setMaxMonths(12);
									}}
									onCreateNew={() => {
										setSelectedView(null);
										setShowingViewPopup(true);
									}}></ViewDropdown>
							</span>
						</Tooltip>
						{selectedView && selectedView.creatorPersonID == person.id && (
							<>
								<Tooltip title="Edit View">
									<div className="buttonEditView" onClick={() => {
										setShowingViewPopup(true);
									}}><FontAwesomeIcon icon={faPencil}></FontAwesomeIcon></div>
								</Tooltip>
								<Tooltip title="Delete View">
									<div className="buttonDeleteView" onClick={() => {
										if (window.confirm(`Are you sure you want to delete this view?`)) {
											deleteDoc(doc(sharedFirestore, "View", selectedView.id)).then(() => {
												setSelectedView(null);
											});
										}
									}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
								</Tooltip>
							</>
						)}
					</div>
				</div>

				<div className="filters">
					<h3>Filter</h3>
					<div className="row">
						<div className="col">
							<div className="label">Client</div>
							<ClientDropdown
								prompt="any"
								defaultValue={filterClientID}
								noCreation={true}
								onChange={(clientID) => {
									setFilterClientID(clientID)
									tableRef.current!.scrollTop = 0;
									tableRef.current!.scrollLeft = 0;
									setMaxMonths(12);
								}}></ClientDropdown>
						</div>
						<div className="col">
							<div className="label">Role</div>
							<RoleDropdown
								prompt="any"
								defaultValue={filterRoleID}
								onChange={(roleID) => {
									setFilterRoleID(roleID)
									tableRef.current!.scrollTop = 0;
									tableRef.current!.scrollLeft = 0;
									setMaxMonths(12);
								}}></RoleDropdown>
						</div>
						<div className="col">
							<div className="label">Project Type</div>
							<ProjectTypeDropdown
								prompt="All"
								defaultValue={filterProjectType}
								onChange={value => {
									setFilterProjectType(value);
									tableRef.current!.scrollTop = 0;
									tableRef.current!.scrollLeft = 0;
									setMaxMonths(12);
								}}></ProjectTypeDropdown>
						</div>
						<div className="col">
							<div className="label">search</div>
							<div className="row">
								<input type='text'
									defaultValue={filterSearch}
									ref={inputSearchRef}
									className="search"
									onChange={(event) => {
										if (slowSearchTimeout != null) {
											clearTimeout(slowSearchTimeout);
										}
										slowSearchTimeout = setTimeout(() => {
											setFilterSearch(event.target.value)
										}, 500);
									}}>
								</input>

								<div className='searchIcon'>
									<FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="label">Clear Filters</div>
							<div className='buttonClearFilters'
								onClick={() => {
									setFilterClientID("");
									setFilterProjectType("");
									setFilterRoleID("");
									setFilterSearch("");
									inputSearchRef.current!.value = "";
									tableRef.current!.scrollTop = 0;
									tableRef.current!.scrollLeft = 0;
									setMaxMonths(12);
								}}>
								Clear
							</div>
						</div>
					</div>
				</div>
				<div className="content"
					ref={tableRef}
					style={{ maxHeight: `calc(100vh - ${tableOffsetTop}px)` }}

					onScroll={(event) => {
						// add 3 months if they scroll all the way to the right
						let scrollLeft = event.currentTarget.scrollLeft;
						let scrollWidth = event.currentTarget.scrollWidth;
						let clientWidth = event.currentTarget.clientWidth;
						if (scrollLeft + clientWidth >= scrollWidth - 10) {
							setMaxMonths(maxMonths + 3);
						}
					}}>
					<HomeTaskTable
						columns={selectedView ? selectedView.selectedColumns : homeColumns}
						filterRole={filterRoleID}
						filterClient={filterClientID}
						filterSearch={filterSearch}
						filterProjectType={filterProjectType}
						numMonths={maxMonths}></HomeTaskTable>
				</div>
			</div>
			{showingViewPopup && (
				<PopupAddView
					type="home"
					columns={homeColumns}
					view={selectedView ?? undefined}
					onCancel={() => {
						setShowingViewPopup(false);
					}} onSave={(view: View) => {
						setShowingViewPopup(false);
						let tempView = { ...view }
						setSelectedView(tempView);
						setTimeout(() => {
							setSelectedView(views.find((view: View) => view.id == tempView.id) || null);
							//setSelectedView(tempView);
						}, 100);
					}} />
			)}
			{showingProjectPopup && (
				<PopupAddProject
					project={selectedProject ?? undefined}
					onCancel={() => {
						setSelectedProject(null);
						setShowingProjectPopup(false);
					}} onSave={(project) => {
						setSelectedProject(null);
						setShowingProjectPopup(false);
					}}></PopupAddProject>
			)}
		</>
	)
}