

import React, { useEffect, useState } from "react"
import "./ProjectStatusHistoryTable.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import DraggableTableBody from "../DraggableTableBody/DraggableTableBody";
import { saveRoleAttributeChange } from "../../Utils/SaveRoleFunctions";
import { saveOrganizationAttributeChange } from "../../Utils/SaveOrganizationFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { faBars, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shortDate } from "../../Utils/formatting";

interface ProjectStatusHistoryTableProps{
	statusHistory:{date:string,status:string}[];
	isReadOnly?:boolean;
	onStatusHistoryChange:(statusHistory:{date:string,status:string}[]) => void;
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function ProjectStatusHistoryTable(props:ProjectStatusHistoryTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles},dispatch);

	const [statusHistory, setStatusHistory] = useState<{date:string,status:string}[]>([]);
	
	const [savingOptionsReorder, setSavingOptionsReorder] = useState(false);
	
	useEffect(() =>{
		setSavingOptionsReorder(false);
		let sortedHistory = [... props.statusHistory];
		sortedHistory.sort((a,b) => {
			return new Date(a.date).getTime() - new Date(b.date).getTime();
		});
		setStatusHistory([... props.statusHistory]);
	},[props.statusHistory]);
	
	
  return (
    <table 
			className={`ProjectStatusHistoryTable`}>
			{savingOptionsReorder == false && (
				<>
					<thead>
						<tr>
							<th>Status</th>
							<th>Status Date</th>
						</tr>
					</thead>
					<tbody>
					{statusHistory.map((option:{date:string, status:string}, index) =>{
						return <tr key={`${option.status}_${index}`}>
								<td>
									{props.isReadOnly ?(
										<>{statusHistory[index].status}</>
										
									):(
										<select className={`status`}
												defaultValue={statusHistory[index].status}
												onChange={(event) => {
													const newOptions = [...props.statusHistory];
													newOptions[index].status = event.target.value;
													setStatusHistory(newOptions);
													props.onStatusHistoryChange(newOptions);
												}}>
													<option value="">Select Status</option>
													{organization.projectStatusList.map((option) => {
														return <option value={option}>{option}</option>
													})}
											</select>

									)}
								</td>
								<td>
									{props.isReadOnly ?(
										<>{statusHistory[index].date}</>
									):(
										<DatePicker className={`date`}
											selected={new Date(statusHistory[index].date)}
											onChange={(date:Date) => {
												const newOptions = [...props.statusHistory];
												newOptions[index].date = shortDate(date);
												newOptions.sort((a,b) => {
													return new Date(a.date).getTime() - new Date(b.date).getTime();
												});
												setStatusHistory(newOptions);
												props.onStatusHistoryChange(newOptions);
											}} />
									)}
								</td>
									{!props.isReadOnly && (
										<div className="buttonDelete"
											onClick={() =>{
												if(window.confirm("Are you sure you'd like to delete this history entry?")){
													const newOptions = [...statusHistory];
													newOptions.splice(index,1)
													if(optionsTableTimeout != null){
														clearTimeout(optionsTableTimeout);
														optionsTableTimeout = null;
													}
													setSavingOptionsReorder(true);
													optionsTableTimeout = setTimeout(() =>{
														setStatusHistory(newOptions);
														props.onStatusHistoryChange(newOptions);
													},500);
												}
											}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
									)}
							</tr>
					})}
					{!props.isReadOnly && (
						<tr>
							<td>
								<div className="buttonAdd"
									onClick={() =>{
										const newOptions = [...statusHistory];
										newOptions.push({date:shortDate(new Date()), status:""});
										setStatusHistory(newOptions);
										props.onStatusHistoryChange(newOptions);
									}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Entry</div>
							</td>
						</tr>
						)}
					</tbody>
				</>
			)}
    </table>
  )
}