
import React, { ReactNode, useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./AnnualBSPHeader.css"
import { useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role, rolesExistAM } from "../../../FirebaseModel/Role";
import { prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowDown19, faArrowUp, faArrowUp19, faChevronDown, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

interface AnnualBSPHeaderProps{

	year:number;

	onSortYearAscending?:(ascending:boolean)=>void;
	onSortMonthAscending?:(ascending:boolean)=>void;
}

export default function AnnualBSPHeader(props:AnnualBSPHeaderProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)

	const [sortYearAscending, setSortYearAscending] = useState<boolean>(true);
	const [sortMonthAscending, setSortMonthAscending] = useState<boolean>(true);

	const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]


  return (
		<tr className={`AnnualBSPHeader`}>
			<th></th>
			{(() =>{
				let nodes:ReactNode[] = [];
				let today = new Date();
				for(var i = 0; i < 12; i++){
					if(i < today.getMonth() || props.year < today.getFullYear()){
						nodes.push(<th key={`column_${i}`}>{months[i]}</th>);
					}
				}	
				return nodes;
			})()}
			<th>Total</th>
			<th>3-Mo Avg</th>
			<th>6-Mo Avg</th>
			<th>Average</th>
		</tr>
	)
}