

import React, { useEffect, useState } from "react"
import "./ProductionScheduleTableHeader.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Client } from "../../../FirebaseModel/Client";
import { ClientContact } from "../../../FirebaseModel/ClientContact";
import { Organization, holiday } from "../../../FirebaseModel/Organization";
import { shortDate } from "../../../Utils/formatting";
import { Tooltip } from "@mui/material";
export enum ProductionScheduleTableHeaderType{
	month,
	day,
	dayOfWeek,
}

interface ProductionScheduleTableHeaderProps{
	type:ProductionScheduleTableHeaderType;
	numMonths:number;
}


let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function ProductionScheduleTableHeader(props:ProductionScheduleTableHeaderProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const clientContacts = useSelector((state:{clientContacts:ClientContact[]}) => state.clientContacts)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles},dispatch);

	const [months, setMonths] = useState<Date[]>([]);
	const [days, setDays] = useState<Date[]>([]);

	const today = new Date();
	
	useEffect(() =>{
		let months:Date[] = [];
		let date = new Date();
		for(let i = 0; i < props.numMonths; i++){
			months.push(new Date(date.getFullYear(), date.getMonth() + i, 1));
		}
		setMonths(months);

		// add every day from months into the days array
		let days:Date[] = [];
		months.forEach(month =>{
			let date = new Date(month);
			while(date.getMonth() === month.getMonth()){
				days.push(new Date(date));
				date.setDate(date.getDate() + 1);
			}
		})
		setDays(days);
	},[props.numMonths])

  return (
		<>
			{props.type === ProductionScheduleTableHeaderType.month && (
				<tr className={`ProductionScheduleTableHeader month`}>
					<th>Department / <br/>Team Member / Project</th>
					<th>Role</th>
					<th>Planned Hours</th>
					<th>Actual Hours</th>
					<th>Remaining</th>
					<th>Estimated % Complete</th>
					{months.map((month, index) =>{
						
						//a header with the short month name and year. colspan is the number of days in the month
						return <th key={index} colSpan={new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate()} className="topper">{month.toLocaleString('default', { month: 'short' })} {month.getFullYear()}</th>
					})}
				</tr>
			)}
			{props.type === ProductionScheduleTableHeaderType.day && (
				<tr className={`ProductionScheduleTableHeader day`}>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
					{days.map((day, index) =>{
						// add a header with the day of the month number
						let h = holiday(shortDate(day),organization)
						let isToday = day.getDate() == today.getDate() && day.getMonth() == today.getMonth() && day.getFullYear() == today.getFullYear();
						let isWeekend = day.getDay() === 0 || day.getDay() === 6;
						return <th key={index} className={`day ${isToday?"today":""} ${isWeekend?"weekend":""} ${h?"holiday":""}`}>
							{h ? (
								<Tooltip title={h.description}>
									<span>{day.getDate()}</span>
								</Tooltip>
							):(
								<>{day.getDate()}</>
							)}
						</th>
					})}
				</tr>
			)}
			{props.type === ProductionScheduleTableHeaderType.dayOfWeek && (
				<tr className={`ProductionScheduleTableHeader dayOfWeek`}>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					{days.map((day, index) =>{
						// add a header with the single letter day of the week
						let h = holiday(shortDate(day),organization)
						let isToday = day.getDate() == today.getDate() && day.getMonth() == today.getMonth() && day.getFullYear() == today.getFullYear();
						let isWeekend = day.getDay() === 0 || day.getDay() === 6;
						return <th key={index} className={`day ${isToday?"today":""} ${isWeekend?"weekend":""} ${h?"holiday":""}`}>
							{h ? (
								<Tooltip title={h.description}>
									<span>{day.toLocaleString('default', { weekday: 'short' }).charAt(0)}</span>
								</Tooltip>
							):(
								<>{day.toLocaleString('default', { weekday: 'short' }).charAt(0)}</>
							)}
						</th>
					})}
				</tr>
			)}
		</>
  )
}