
import React, { useEffect, useState } from "react"
import { sharedFirestore } from "../../Utils/SharedFirebase"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where } from "firebase/firestore";
import "./TeamScreen.css"
import { Project } from "../../FirebaseModel/Project";
import { useDispatch, useSelector } from "react-redux";
import { faAddressCard, faGear, faHouse, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators } from "redux";
import { Organization } from "../../FirebaseModel/Organization";
import ActionCreators from "../../Redux/ActionCreators";
import { Screens } from "../../Redux/Navigation";
import TeamScreenPeopleTable from "../../Components/TeamScreen/TeamScreenPeopleTable/TeamScreenPeopleTable";
import { Person } from "../../FirebaseModel/Person";
import PopupAddPerson from "../../Popups/PopupAddPerson/PopupAddPerson";
import PopupWeeklyTimesheet from "../../Popups/PopupWeeklyTimesheet/PopupWeeklyTimesheet";
import NavigationButtons from "../../Components/NavigationButtons/NavigationButtons";
import DepartmentDropdown from "../../Components/DepartmentDropdown/DepartmentDropdown";
interface TeamScreenProps {
}

let slowSearchTimeout: NodeJS.Timeout | null = null;

export default function TeamScreen(props: TeamScreenProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen
	}, dispatch);


	let organization = useSelector((state: { organization: Organization | null }) => state.organization);
	let permissionsWrite = useSelector((state: { permissionsWrite: string[] }) => state.permissionsWrite);

	const inputSearchRef = React.createRef<HTMLInputElement>();
	const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);
	const [showingPersonPopup, setShowingPersonPopup] = useState(false)
	const [showingPersonTimesheetPopup, setShowingPersonTimesheetPopup] = useState(false)

	const [filterSearch, setFilterSearch] = useState(localStorage.getItem("teamScreenFilterSearch") ?? "");
	useEffect(() => {
		localStorage.setItem("teamScreenFilterSearch", filterSearch);
	}, [filterSearch]);

	const [filterDepartment, setFilterDepartment] = useState<string>(localStorage.getItem("teamScreenFilterDepartment") ?? "");
	useEffect(() => {
		localStorage.setItem("teamScreenFilterDepartment", filterDepartment);
	}, [filterDepartment]);

	const [filterActive, setFilterActive] = useState((localStorage.getItem("teamScreenFilterActive") ?? "true") === "true");
	useEffect(() => {
		localStorage.setItem("teamScreenFilterActive", filterActive ? "true" : "false");
	}, [filterActive]);

	const [filterInactive, setFilterInactive] = useState((localStorage.getItem("teamScreenFilterInactive") ?? "true") === "true");
	useEffect(() => {
		localStorage.setItem("teamScreenFilterInactive", filterInactive ? "true" : "false");
	}, [filterInactive]);

	const [filterEndYear, setFilterEndYear] = useState<string>(localStorage.getItem("teamScreenFilterEndYear") ?? "");
	useEffect(() => {
		localStorage.setItem("teamScreenFilterEndYear", filterEndYear);
	}, [filterEndYear]);

	const [filterStartYear, setFilterStartYear] = useState<string>(localStorage.getItem("teamScreenFilterStartYear") ?? "");
	useEffect(() => {
		localStorage.setItem("teamScreenFilterStartYear", filterStartYear);
	}, [filterStartYear]);

	const [filterStartMonth, setFilterStartMonth] = useState<string>(localStorage.getItem("teamScreenFilterStartMonth") ?? "");
	useEffect(() => {
		localStorage.setItem("teamScreenFilterStartMonth", filterStartMonth);
	}, [filterStartMonth]);

	const [filterEndMonth, setFilterEndMonth] = useState<string>(localStorage.getItem("teamScreenFilterEndMonth") ?? "");
	useEffect(() => {
		localStorage.setItem("teamScreenFilterEndMonth", filterEndMonth);
	}, [filterEndMonth]);

	return (
		<>
			<div className="screen TeamScreen">
				<h1>Team Portal</h1>

				<NavigationButtons />

				{permissionsWrite.includes("Team") && (
					<div className="buttonAdd" onClick={() => {
						setSelectedPerson(null);
						setShowingPersonPopup(true);
					}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Person</div>
				)}




				<div className="filters">
					<h3>Filter</h3>
					<div className="row">
						<div className="col">
							<div className="label">Active</div>
							<input type='checkbox'
								checked={filterActive}
								onChange={(event) => {
									setFilterActive(event.target.checked);
								}}></input>
						</div>
						<div className="col">
							<div className="label">Inactive</div>
							<input type='checkbox'
								checked={filterInactive}
								onChange={(event) => {
									setFilterInactive(event.target.checked);
								}}></input>
						</div>
						<div className="col">
							<div className="label">Department</div>
							<DepartmentDropdown onChange={(departmentID) => {
								setFilterDepartment(departmentID);
							}}></DepartmentDropdown>
						</div>
						<div className="col">
							<div className="label">search</div>
							<div className="row">
								<input type='text'
									defaultValue={filterSearch}
									ref={inputSearchRef}
									className="search"
									onChange={(event) => {
										if (slowSearchTimeout != null) {
											clearTimeout(slowSearchTimeout);
										}
										slowSearchTimeout = setTimeout(() => {
											setFilterSearch(event.target.value)
										}, 500);
									}}>
								</input>
								<div className='searchIcon'>
									<FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="label">Clear Filters</div>
							<div className='buttonClearFilters'
								onClick={() => {
									setFilterDepartment("");
									setFilterActive(true);
									setFilterInactive(false);
									setFilterStartMonth("");
									setFilterStartYear("");
									setFilterEndMonth("");
									setFilterEndYear("");
									setFilterSearch("");
									inputSearchRef.current!.value = "";
								}}>
								Clear
							</div>
						</div>
					</div>


					<div className="filters calculatedWeights">
						<h3>Calculated Weights Filter</h3>
						<div className="row">
							<div className="col">
								<div className="label">Start Month</div>
								<select value={filterStartMonth}
									onChange={(event) => {
										setFilterStartMonth(event.target.value)
										if (filterStartYear == "") {
											setFilterStartYear(new Date().getFullYear().toString());
										}
										if (filterEndMonth != "" && parseInt(filterEndMonth) < parseInt(event.target.value) &&
											(filterEndYear == "" || parseInt(filterEndYear) <= parseInt(filterStartYear))) {
											setFilterEndMonth("")
										}
									}}>
									<option value="">--</option>
									<option value="1">January</option>
									<option value="2">February</option>
									<option value="3">March</option>
									<option value="4">April</option>
									<option value="5">May</option>
									<option value="6">June</option>
									<option value="7">July</option>
									<option value="8">August</option>
									<option value="9">September</option>
									<option value="10">October</option>
									<option value="11">November</option>
									<option value="12">December</option>
								</select>
							</div>
							<div className="col">
								<div className="label">Start Year</div>
								<select value={filterStartYear}
									onChange={(event) => {
										setFilterStartYear(event.target.value)
									}}>
									<option value="">----</option>
									{(() => {
										let years = [];
										let currentYear = new Date().getFullYear();
										for (let i = currentYear; i > currentYear - 30; i--) {
											years.push(<option key={`year_${i}`} value={i}>{i}</option>)
										}
										return years;
									})()}
								</select>
							</div>
							<div className="col">
								<div className="label">End Month</div>
								<select value={filterEndMonth}
									onChange={(event) => {
										setFilterEndMonth(event.target.value)
										if (filterEndYear == "") {
											setFilterEndYear(new Date().getFullYear().toString());
										}
										if (filterStartMonth != "" && parseInt(filterStartMonth) > parseInt(event.target.value) &&
											(filterEndYear == "" || parseInt(filterEndYear) >= parseInt(filterStartYear))) {
											setFilterEndMonth("")
										}
									}}>
									<option value="">--</option>
									<option value="1">January</option>
									<option value="2">February</option>
									<option value="3">March</option>
									<option value="4">April</option>
									<option value="5">May</option>
									<option value="6">June</option>
									<option value="7">July</option>
									<option value="8">August</option>
									<option value="9">September</option>
									<option value="10">October</option>
									<option value="11">November</option>
									<option value="12">December</option>
								</select>
							</div>
							<div className="col">
								<div className="label">End Year</div>
								<select value={filterEndYear}
									onChange={(event) => {
										setFilterEndYear(event.target.value)

									}}>
									<option value="">----</option>
									{(() => {
										let years = [];
										let currentYear = new Date().getFullYear();
										for (let i = currentYear; i > currentYear - 30; i--) {
											years.push(<option key={`year_${i}`} value={i}>{i}</option>)
										}
										return years;
									})()}
								</select>
							</div>
						</div>
					</div>
				</div>

				<TeamScreenPeopleTable
					filterSearch={filterSearch}
					filterDepartment={filterDepartment}
					filterActive={filterActive}
					filterInactive={filterInactive}
					filterStartMonth={filterStartMonth}
					filterStartYear={filterStartYear}
					filterEndMonth={filterEndMonth}
					filterEndYear={filterEndYear}
					onPersonTimesheetPressed={(person: Person) => {
						setSelectedPerson(person);
						setShowingPersonTimesheetPopup(true);
					}}
					onEditPersonPressed={(person: Person) => {
						setSelectedPerson(person);
						setShowingPersonPopup(true);
					}}></TeamScreenPeopleTable>

			</div>
			{showingPersonPopup && (
				<PopupAddPerson
					allowMultiple={true}
					person={selectedPerson ?? undefined}
					onCancel={() => {
						setShowingPersonPopup(false);
					}} onSave={(person) => {
						setShowingPersonPopup(false);
					}}></PopupAddPerson>
			)}

			{showingPersonTimesheetPopup && selectedPerson && (
				<PopupWeeklyTimesheet
					person={selectedPerson}
					onDismiss={() => {
						setShowingPersonTimesheetPopup(false);
					}}></PopupWeeklyTimesheet>
			)}

		</>
	)
}