


import React, { useEffect, useState } from "react"
import { sharedFirestore } from "../../Utils/SharedFirebase"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ProductionScheduleScreen.css"
import { Project, ProjectTaskScheduleBlock } from "../../FirebaseModel/Project";
import { useSelector } from "react-redux";
import NavigationButtons from "../../Components/NavigationButtons/NavigationButtons";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import RoleDropdown from "../../Components/RoleDropdown/RoleDropdown";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductionScheduleTable from "../../Components/ProductionSchedule/ProductionScheduleTable/ProductionScheduleTable";
import DepartmentDropdown from "../../Components/DepartmentDropdown/DepartmentDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import PopupAddTaskScheduleBlock from "../../Popups/PopupAddTaskScheduleBlock/PopupAddTaskScheduleBlock";
import PopupSchedulePTO from "../../Popups/PopupSchedulePTO/PopupSchedulePTO";
import { TimeOff } from "../../FirebaseModel/TimeOff";
import PopupAddProject from "../../Popups/PopupAddProject/PopupAddProject";
import PopupAddNonProjectTaskSheduleBlock from "../../Popups/PopupAddNonProjectTaskSheduleBlock/PopupAddNonProjectTaskSheduleBlock";
import { PersonNonProjectTaskScheduleBlock } from "../../FirebaseModel/Person";
import { setRef } from "@mui/material";
interface ProductionScheduleScreenProps{
}
let slowSearchTimeout:NodeJS.Timeout|null = null;

export default function ProductionScheduleScreen(props:ProductionScheduleScreenProps){

	const [filterDepartmentID, setFilterDepartmentID] = useState("");
	const [filterPersonID, setFilterPersonID] = useState("");
	const [filterClientID, setFilterClientID] = useState("");
	const [filterRoleID, setFilterRoleID] = useState("");
	const [filterSearch, setFilterSearch] = useState("");

	const [tableOffsetTop, setTableOffsetTop] = useState<number>(0);
	const tableRef = React.createRef<HTMLDivElement>();
	const [numMonths, setNumMonths] = useState<number>(3);
	const inputSearchRef = React.useRef<HTMLInputElement>(null);
	const [showingTaskScheduleBlockPopup, setShowingTaskScheduleBlockPopup] = useState<boolean>(false);
	const [showingNonProjectTaskScheduleBlockPopup, setShowingNonProjectTaskScheduleBlockPopup] = useState<boolean>(false);
	const [showingPTOScheduleBlockPopup, setShowingPTOScheduleBlockPopup] = useState<boolean>(false);
	const [showingProjectPopup, setShowingProjectPopup] = useState<boolean>(false);

	const [refreshCount, setRefreshCount] = useState<number>(0);

	interface NewTaskScheduleBlockPopupProps{
		projectID:string,
		personID:string,
		roleID:string,
		startDate?:string
	}
	const [newTaskScheduleBlockPopupProps, setNewTaskScheduleBlockPopupProps] = useState<NewTaskScheduleBlockPopupProps|null>(null);
	interface NewNonProjectTaskScheduleBlockPopupProps{
		personID:string,
		startDate?:string
	}
	const [newNonProjectTaskScheduleBlockPopupProps, setNewNonProjectTaskScheduleBlockPopupProps] = useState<NewNonProjectTaskScheduleBlockPopupProps|null>(null);
	const [selectedTaskScheduleBlock, setSelectedTaskScheduleBlock] = useState<ProjectTaskScheduleBlock|null>(null);
	const [selectedNonProjectTaskScheduleBlock, setSelectedNonProjectTaskScheduleBlock] = useState<PersonNonProjectTaskScheduleBlock|null>(null);
	const [selectedTimeOff, setSelectedTimeOff] = useState<TimeOff|null>(null);
	const [selectedDate, setSelectedDate] = useState<string|null>(null);
	const [selectedProject, setSelectedProject] = useState<Project|null>(null);
	const resize = () =>{
		setTableOffsetTop(tableRef.current!.offsetTop);
	}
	useEffect(() =>{
		window.addEventListener("resize", resize);
		resize();
		return () =>{
			window.removeEventListener("resize", resize);
		}
	},[])

	useEffect(() =>{
		if(refreshCount%2==1){
			setTimeout(() =>{
				setRefreshCount(refreshCount+1);
			}, 100);
		}
	},[refreshCount])

	useEffect(() => {
		let table = tableRef.current!;
		if(table){
			const overrideWheel = (e: WheelEvent) => {
				if(table.scrollLeft === 0 && e.deltaX < 0){
					e.preventDefault();
				}
			};
			table.addEventListener("wheel", overrideWheel);
			return () => {
				table.removeEventListener("wheel", overrideWheel);
			};
		}
	}, [tableRef]);

  return (
		<>
	    <div className="screen ProductionScheduleScreen">
				<h1>Production Schedule</h1>
				<NavigationButtons />

				<div className="filters">
					<h3>Filter</h3>
					<div className="row">
						<div className="col">
							<div className="label">Department</div>
							<DepartmentDropdown
								prompt="any"
								defaultValue={filterDepartmentID}
								onChange={(departmentID) =>{
									setFilterDepartmentID(departmentID)
								}}></DepartmentDropdown>
						</div>
						<div className="col">
							<div className="label">Person</div>
							<PersonDropdown
								prompt="any"
								defaultValue={filterPersonID}
								noCreation={true}
								onChange={(personID) =>{
									setFilterPersonID(personID)
								}}></PersonDropdown>
						</div>
						<div className="col">
							<div className="label">Client</div>
							<ClientDropdown
								prompt="any"
								defaultValue={filterClientID}
								noCreation={true}
								onChange={(clientID) =>{
									setFilterClientID(clientID)
								}}></ClientDropdown>
						</div>
						<div className="col">
							<div className="label">Role</div>
							<RoleDropdown
								prompt="any"
								defaultValue={filterRoleID}
								onChange={(roleID) =>{
									setFilterRoleID(roleID)
								}}></RoleDropdown>
						</div>
						<div className="col">
							<div className="label">search</div>
							<div className="row">
								<input type='text' 
									defaultValue={filterSearch}
									ref={inputSearchRef}
									className="search"
									onChange={(event) =>{
										if(slowSearchTimeout != null){
											clearTimeout(slowSearchTimeout);
										}
										slowSearchTimeout = setTimeout(() =>{
											setFilterSearch(event.target.value)
										}, 500);
									}}>
								</input>

								<div className='searchIcon'>
									<FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="label">Clear Filters</div>
							<div className='buttonClearFilters'
								onClick={() =>{
									setFilterDepartmentID("");
									setFilterPersonID("");
									setFilterClientID("");
									setFilterRoleID("");
									setFilterSearch("");
									inputSearchRef.current!.value = "";
								}}>
								Clear
							</div>
						</div>
					</div>
				</div>


				<div className="content"
						ref={tableRef}
						style={{maxHeight:`calc(100vh - ${tableOffsetTop}px)`}}
						
						onScroll={(event) =>{
							// add 3 months if they scroll all the way to the right
							let scrollLeft = event.currentTarget.scrollLeft;
							let scrollWidth = event.currentTarget.scrollWidth;
							let clientWidth = event.currentTarget.clientWidth;
							if(scrollLeft + clientWidth >= scrollWidth - 10){
								setNumMonths(numMonths + 3);
							}
						}}>
							<ProductionScheduleTable 
								refreshCount={refreshCount}
								filterDepartmentID={filterDepartmentID}
								filterPersonID={filterPersonID}
								filterClientID={filterClientID}
								filterRoleID={filterRoleID}
								filterSearch={filterSearch}
								numMonths={numMonths} 
								onEditProject={(project:Project) =>{
									setSelectedProject(project);
									setShowingProjectPopup(true);
								} }
								onEditTaskScheduleBlock={(taskScheduleBlock:ProjectTaskScheduleBlock) =>{
									setSelectedTaskScheduleBlock(taskScheduleBlock);
									setShowingTaskScheduleBlockPopup(true);
								} }
								onAddScheduleBlock={(personID:string, roleID:string, projectID:string, startDate?:string) =>{
									setNewTaskScheduleBlockPopupProps({
										personID:personID,
										roleID:roleID,
										projectID:projectID,
										startDate:startDate
									})
									setShowingTaskScheduleBlockPopup(true);
								} }
								onScheduleTimeOff={(timeOff:TimeOff, date:string) =>{
									setSelectedDate(date);
									setSelectedTimeOff(timeOff);
									setShowingPTOScheduleBlockPopup(true);
								}	}
								onAddNonProjectTaskScheduleBlock={(personID:string, startDate:string|undefined) =>{
									setNewNonProjectTaskScheduleBlockPopupProps({
										personID:personID,
										startDate:startDate
									})
									setSelectedNonProjectTaskScheduleBlock(null);
									setShowingNonProjectTaskScheduleBlockPopup(true);
								}}
								onEditNonProjectTaskScheduleBlock={(personNonProjectTaskScheduleBlock) =>{
									setSelectedNonProjectTaskScheduleBlock(personNonProjectTaskScheduleBlock);
									setNewNonProjectTaskScheduleBlockPopupProps(null);
									setShowingNonProjectTaskScheduleBlockPopup(true);
								}}></ProductionScheduleTable>
					</div>

	    </div>
			{showingTaskScheduleBlockPopup && (
				<PopupAddTaskScheduleBlock 
					projectID={newTaskScheduleBlockPopupProps?.projectID??undefined}
					personID={newTaskScheduleBlockPopupProps?.personID??undefined}
					roleID={newTaskScheduleBlockPopupProps?.roleID??undefined}
					startDate={newTaskScheduleBlockPopupProps?.startDate??undefined}
					projectTaskScheduleBlock={selectedTaskScheduleBlock??undefined}
					onCancel={()=>{
						setShowingTaskScheduleBlockPopup(false);
						setSelectedTaskScheduleBlock(null);
						setNewTaskScheduleBlockPopupProps(null);
					} } 
					onSave={(taskBlock: ProjectTaskScheduleBlock|null) => {
						setShowingTaskScheduleBlockPopup(false);
						setSelectedTaskScheduleBlock(null);
						setNewTaskScheduleBlockPopupProps(null);
						setRefreshCount(refreshCount + 1);
					} }></PopupAddTaskScheduleBlock>
			)}
			{showingNonProjectTaskScheduleBlockPopup && (
				<PopupAddNonProjectTaskSheduleBlock 
					personID={newNonProjectTaskScheduleBlockPopupProps?.personID??undefined}
					startDate={newNonProjectTaskScheduleBlockPopupProps?.startDate??undefined}
					personNonProjectTaskScheduleBlock={selectedNonProjectTaskScheduleBlock??undefined}
					onCancel={()=>{
						setShowingNonProjectTaskScheduleBlockPopup(false);
						setSelectedNonProjectTaskScheduleBlock(null);
						setNewNonProjectTaskScheduleBlockPopupProps(null);
					} } 
					onSave={(taskBlock: PersonNonProjectTaskScheduleBlock|null) => {
						setShowingNonProjectTaskScheduleBlockPopup(false);
						setSelectedNonProjectTaskScheduleBlock(null);
						setNewNonProjectTaskScheduleBlockPopupProps(null);
						setRefreshCount(refreshCount + 1);
					} }></PopupAddNonProjectTaskSheduleBlock>
			)}
			
			{showingPTOScheduleBlockPopup && (
				<PopupSchedulePTO 
					timeOff={selectedTimeOff!}
					startDate={selectedDate!}
					onCancel={()=>{
						setShowingPTOScheduleBlockPopup(false);
						setSelectedTimeOff(null);
						setSelectedDate(null);
					} } onSave={()=>{
						setShowingPTOScheduleBlockPopup(false);
						setSelectedTimeOff(null);
						setSelectedDate(null);
						setRefreshCount(refreshCount + 1);
					} }></PopupSchedulePTO>
			)}

			{showingProjectPopup && (
				<PopupAddProject 
					project={selectedProject??undefined}
					onCancel={() =>{
						setSelectedProject(null);
						setShowingProjectPopup(false);			
					}} onSave={(project) =>{
						setSelectedProject(null);
						setShowingProjectPopup(false);	
						setRefreshCount(refreshCount + 1);		
					}}></PopupAddProject>
			)}
		</>
  )
}