
import React, { useEffect, useState } from "react"
import { addDoc, collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./IndividualProfitSharingScreen.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../FirebaseModel/Organization";
import { Person } from "../../FirebaseModel/Person";
import { Client } from "../../FirebaseModel/Client";
import { forceDecimal, forceMaxValue, forcePercentage, prettyCurrency, shortDate } from "../../Utils/formatting";
import { sharedFirebaseAuth, sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { Role } from "../../FirebaseModel/Role";
import { saveRoleAttributeChange } from "../../Utils/SaveRoleFunctions";
import { savePersonAttributeChange } from "../../Utils/SavePersonFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { UndoStackEntry, UndoStackEntryType, UndoStackEntryObjectType } from "../../Utils/UndoStackEntry";
import DepartmentDropdown from "../../Components/DepartmentDropdown/DepartmentDropdown";
import IndividualProfitSharingTable from "../../Components/IndividualProfitSharing/IndividualProfitSharingTable/IndividualProfitSharingTable";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import NavigationButtons from "../../Components/NavigationButtons/NavigationButtons";
interface IndividualProfitSharingScreenProps{
}

export default function IndividualProfitSharingScreen(props:IndividualProfitSharingScreenProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)

	const person = persons.find(person => person.email.toLowerCase() === sharedFirebaseAuth.currentUser!.email!)!;

	const [year, setYear] = useState<number>(new Date().getFullYear());
	const [personID, setPersonID] = useState<string>(person.id || "");
	

	const [tableOffsetTop, setTableOffsetTop] = useState<number>(0);
	const tableRef = React.createRef<HTMLDivElement>();
	const resize = () =>{
		setTableOffsetTop(tableRef.current!.offsetTop);
	}


	useEffect(() =>{
		window.addEventListener("resize", resize);
		resize();
		return () =>{
			window.removeEventListener("resize", resize);
		}
	},[])

	useEffect(() => {
		let table = tableRef.current!;
		if(table){
			const overrideWheel = (e: WheelEvent) => {
				if(table.scrollLeft === 0 && e.deltaX < 0){
					e.preventDefault();
				}
			};
			table.addEventListener("wheel", overrideWheel);
			return () => {
				table.removeEventListener("wheel", overrideWheel);
			};
		}
	}, [tableRef]);

  return (
    <div className={`screen IndividualProfitSharingScreen`}>
		<h1>Individual Profit Sharing for <br/>{person.name} ({year})</h1>

		<div className="filters">
			<h3>Parameters</h3>
			<div className="row">
				<div className="col">
					<div className="label">Year</div>
					<select value={year}
						onChange={(event) =>{
							setYear(parseInt(event.target.value))
						}}>
						{(() =>{
							let years = [];
							let currentYear = new Date().getFullYear();
							for(let i = currentYear; i > currentYear - 30; i--){
								years.push(<option key={`year_${i}`} value={i}>{i}</option>)
							}
							return years;
						})()}
					</select>
				</div>
			</div>
		</div>
			<NavigationButtons />

			<div className="content"
				ref={tableRef}
				style={{maxHeight:`calc(100vh - ${tableOffsetTop}px)`}}>
				{personID != "" && (
					<IndividualProfitSharingTable 
						year={year} 
						personID={personID}></IndividualProfitSharingTable>
				)}
			</div>
			
		</div>
	)
}