
import React, { useEffect, useState } from "react"
import { sharedFirestore } from "../../Utils/SharedFirebase"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ReportPortalScreen.css"
import { Project } from "../../FirebaseModel/Project";
import { useDispatch, useSelector } from "react-redux";
import { faAddressCard, faGear, faHouse, faPieChart, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators } from "redux";
import { Organization } from "../../FirebaseModel/Organization";
import ActionCreators from "../../Redux/ActionCreators";
import { Screens } from "../../Redux/Navigation";
import TeamScreenPeopleTable from "../../Components/TeamScreen/TeamScreenPeopleTable/TeamScreenPeopleTable";
import { Person } from "../../FirebaseModel/Person";
import PopupAddPerson from "../../Popups/PopupAddPerson/PopupAddPerson";
import PopupWeeklyTimesheet from "../../Popups/PopupWeeklyTimesheet/PopupWeeklyTimesheet";
import NavigationButtons from "../../Components/NavigationButtons/NavigationButtons";
import ReportSoldVSBilled from "../../Reports/ReportSoldVSBilled/ReportSoldVSBilled";
import ReportMonthlyBSP from "../../Reports/ReportMonthlyBSP/ReportMonthlyBSP";
import ReportAnnualBSP from "../../Reports/ReportAnnualBSP/ReportAnnualBSP";
import ReportProfitSharing from "../../Reports/ReportProfitSharing/ReportProfitSharing";
interface ReportPortalScreenProps{
}

let slowSearchTimeout:NodeJS.Timeout|null = null;

export default function ReportPortalScreen(props:ReportPortalScreenProps){
  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setScreen:ActionCreators.setScreen},dispatch);


	let organization = useSelector((state:{organization:Organization|null}) => state.organization);

  const permissions = useSelector((state:{permissions:string[]}) => state.permissions)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)

	const [selectedPerson, setSelectedPerson] = useState<Person|null>(null);
	const [showingPersonPopup, setShowingPersonPopup] = useState(false)
	const [showingPersonTimesheetPopup, setShowingPersonTimesheetPopup] = useState(false)

	const [filterEndYear, setFilterEndYear] = useState<string>(localStorage.getItem("reportPortalFilterEndYear")||""+(new Date().getFullYear()));
	useEffect(() =>{
		localStorage.setItem("reportPortalFilterEndYear", filterEndYear);
	},[filterEndYear]);

	const [filterStartYear, setFilterStartYear] = useState<string>(localStorage.getItem("reportPortalFilterStartYear")||""+(new Date().getFullYear()));
	useEffect(() =>{
		localStorage.setItem("reportPortalFilterStartYear", filterStartYear);
	},[filterStartYear]);

	const [filterStartMonth, setFilterStartMonth] = useState<string>(localStorage.getItem("reportPortalFilterStartMonth")||"1");
	useEffect(() =>{
		localStorage.setItem("reportPortalFilterStartMonth", filterStartMonth);
	},[filterStartMonth]);

	const [filterEndMonth, setFilterEndMonth] = useState<string>(localStorage.getItem("reportPortalFilterEndMonth")||"12");
	useEffect(() =>{
		localStorage.setItem("reportPortalFilterEndMonth", filterEndMonth);
	},[filterEndMonth]);

	const [weeksPerYear, setWeeksPerYear] = useState(localStorage.getItem("reportPortalWeeksPerYear")||"50");
	useEffect(() =>{
		localStorage.setItem("reportPortalWeeksPerYear", weeksPerYear);
	},[weeksPerYear]);
	

	const [showingSoldVsBilledReport, setShowingSoldVsBilledReport] = useState<boolean>(false);
	const [showingMonthlyBSPReport, setShowingMonthlyBSPReport] = useState<boolean>(false);
	const [showingAnnualBSPReport, setShowingAnnualBSPReport] = useState<boolean>(false);
	const [showingIndividualProfitSharingReport, setShowingIndividualProfitSharingReport] = useState<boolean>(false);
  return (
    <>
    <div className="screen ReportPortalScreen">
      <h1>Report Portal</h1>

			<div className="filters">
				<h3>Parameters</h3>
				<div className="row">
					<div className="col">
						<div className="label">Start Month</div>
						<select value={filterStartMonth}
							onChange={(event) =>{
								setFilterStartMonth(event.target.value)
								if(filterStartYear == ""){
									setFilterStartYear(new Date().getFullYear().toString());
								}
							}}>
							<option value="1">January</option>
							<option value="2">February</option>
							<option value="3">March</option>
							<option value="4">April</option>
							<option value="5">May</option>
							<option value="6">June</option>
							<option value="7">July</option>
							<option value="8">August</option>
							<option value="9">September</option>
							<option value="10">October</option>
							<option value="11">November</option>
							<option value="12">December</option>
						</select>
					</div>
					<div className="col">
						<div className="label">Start Year</div>
						<select value={filterStartYear}
							onChange={(event) =>{
								setFilterStartYear(event.target.value)
							}}>
							{(() =>{
								let years = [];
								let currentYear = new Date().getFullYear();
								for(let i = currentYear; i > currentYear - 30; i--){
									years.push(<option key={`year_${i}`} value={i}>{i}</option>)
								}
								return years;
							})()}
						</select>
					</div>
					<div className="col">
						<div className="label">End Month</div>
						<select value={filterEndMonth}
							onChange={(event) =>{
								setFilterEndMonth(event.target.value)
								if(filterEndYear == ""){
									setFilterEndYear(new Date().getFullYear().toString());
								}
							}}>
							<option value="1">January</option>
							<option value="2">February</option>
							<option value="3">March</option>
							<option value="4">April</option>
							<option value="5">May</option>
							<option value="6">June</option>
							<option value="7">July</option>
							<option value="8">August</option>
							<option value="9">September</option>
							<option value="10">October</option>
							<option value="11">November</option>
							<option value="12">December</option>
						</select>
					</div>
					<div className="col">
						<div className="label">End Year</div>
						<select value={filterEndYear}
							onChange={(event) =>{
								setFilterEndYear(event.target.value)

							}}>
							{(() =>{
								let years = [];
								let currentYear = new Date().getFullYear();
								for(let i = currentYear; i > currentYear - 30; i--){
									years.push(<option key={`year_${i}`} value={i}>{i}</option>)
								}
								return years;
							})()}
						</select>
					</div>
					<div className="col">
						<div className="label">Work Weeks</div>
						<select value={weeksPerYear}
							onChange={(event) =>{
								setWeeksPerYear(event.target.value)
							}}>
							<option value={52}>{52}</option>
							<option value={50}>{50}</option>
							<option value={48}>{48}</option>
							<option value={46}>{46}</option>
						</select>
					</div>
				</div>
			</div>

			<NavigationButtons />

			<div className="reports">


				{permissions.indexOf("Sold VS Billed Report") !=-1 && (
					<div 
						className="option" 
						onClick={() =>{
							setShowingSoldVsBilledReport(true);
						}}>
						<div className="icon"><FontAwesomeIcon icon={faPieChart}></FontAwesomeIcon></div> 
						Sold Vs Billed ({filterStartMonth}/{filterStartYear} - {filterEndMonth}/{filterEndYear})
					</div>
				)}



				{permissions.indexOf("Monthly BSP Report") !=-1 && (
					<div 
						className="option" 
						onClick={() =>{
							setShowingMonthlyBSPReport(true);
						}}>
						<div className="icon"><FontAwesomeIcon icon={faPieChart}></FontAwesomeIcon></div> 
						Monthly BSP Report ({filterStartMonth}/{filterStartYear})
					</div>
				)}


				{permissions.indexOf("Annual BSP Report") !=-1 && (
					<div 
						className="option" 
						onClick={() =>{
							setShowingAnnualBSPReport(true);
						}}>
						<div className="icon"><FontAwesomeIcon icon={faPieChart}></FontAwesomeIcon></div> 
						Annual BSP Report ({filterStartYear})
					</div>
				)}


				{(permissions.indexOf("Profit Sharing Report") !=-1 && organization && organization.offersProfitSharing) && (
					<div 
						className="option" 
						onClick={() =>{
							setShowingIndividualProfitSharingReport(true);
						}}>
						<div className="icon"><FontAwesomeIcon icon={faPieChart}></FontAwesomeIcon></div> 
						Individual Profit Sharing ({filterStartYear})
					</div>
				)}
			</div>
    </div>

		{showingSoldVsBilledReport && (
			<ReportSoldVSBilled  
				filterStartMonth={filterStartMonth} 
				filterEndMonth={filterEndMonth} 
				filterStartYear={filterStartYear} 
				filterEndYear={filterEndYear}
				weeksPerYear={weeksPerYear}
				onDismiss={() => {
					setShowingSoldVsBilledReport(false);
				} }></ReportSoldVSBilled>
		)}

		{showingMonthlyBSPReport && (
			<ReportMonthlyBSP
				filterMonth={filterStartMonth}
				filterYear={filterStartYear}
				onDismiss={() =>{
					setShowingMonthlyBSPReport(false);
				}}></ReportMonthlyBSP>
		)}

		{showingAnnualBSPReport && (
			<ReportAnnualBSP
				filterYear={filterStartYear}
				onDismiss={() =>{
					setShowingAnnualBSPReport(false);
				}}></ReportAnnualBSP>
		)}

		{showingIndividualProfitSharingReport && (
			<ReportProfitSharing
				filterStartYear={filterStartYear}
				onDismiss={() =>{
					setShowingIndividualProfitSharingReport(false);
				}}></ReportProfitSharing>
		)}

    </>
  )
}