

import React, { ReactNode, useEffect, useState } from "react"
import { sharedFirestore } from "../../Utils/SharedFirebase"
import { addDoc, collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ProjectProfitabilityScreen.css"
import PopupAddProject from "../../Popups/PopupAddProject/PopupAddProject";
import ProjectPortalTable from "../../Components/ProjectPortal/ProjectPortalTable/ProjectPortalTable";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../FirebaseModel/Organization";
import addFakeData from "../../Utils/FakeData";
import { Project } from "../../FirebaseModel/Project";
import PopupAddPerson from "../../Popups/PopupAddPerson/PopupAddPerson";
import PopupAddClient from "../../Popups/PopupAddClient/PopupAddClient";
import { saveProjectAttributeChange } from "../../Utils/SaveProjectFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faGear, faGears, faPeopleGroup, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { Screens } from "../../Redux/Navigation";
import NavigationButtons from "../../Components/NavigationButtons/NavigationButtons";
import { UndoStackEntry } from "../../Utils/UndoStackEntry";
import ProjectProfitabilityTable from "../../Components/ProjectProfitability/ProjectProfitabilityTable/ProjectProfitabilityTable";
import PopupProjectProfitabilityDetails from "../../Popups/PopupProjectProfitabilityDetails/PopupProjectProfitabilityDetails";
import ProjectTypeDropdown from "../../Components/ProjectTypeDropdown/ProjectTypeDropdown";
interface ProjectProfitabilityScreenProps{
}


let slowSearchTimeout:NodeJS.Timeout|null = null;

export default function ProjectProfitabilityScreen(props:ProjectProfitabilityScreenProps){


  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setScreen:ActionCreators.setScreen,
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);
	
	const inputSearchRef = React.createRef<HTMLInputElement>();
	const [showingProjectPopup, setShowingProjectPopup] = useState(false)
	const [showingClientPopup, setShowingClientPopup] = useState(false)
	const [showingPersonPopup, setShowingPersonPopup] = useState(false)
	let organization = useSelector((state:{organization:Organization|null}) => state.organization)
	let permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)
  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)

	const [selectedProjectGroup, setSelectedProjectGroup] = useState<Project[]|null>(null);
	const [maxMonths, setMaxMonths] = useState<number>(1200); // go back to 12 once there's more data

	const [filterEndYear, setFilterEndYear] = useState<string>(localStorage.getItem("projectPortalFilterEndYear") || "");
	useEffect(() =>{
		localStorage.setItem("projectPortalFilterEndYear", filterEndYear);
	},[filterEndYear]);

	const [filterStartYear, setFilterStartYear] = useState<string>(localStorage.getItem("projectPortalFilterStartYear") || "");
	useEffect(() =>{
		localStorage.setItem("projectPortalFilterStartYear", filterStartYear);
	},[filterStartYear]);
	const [filterStartMonth, setFilterStartMonth] = useState<string>(localStorage.getItem("projectPortalFilterStartMonth") ||"");
	useEffect(() =>{
		localStorage.setItem("projectPortalFilterStartMonth", filterStartMonth);
	},[filterStartMonth]);

	const [filterEndMonth, setFilterEndMonth] = useState<string>(localStorage.getItem("projectPortalFilterEndMonth") ||"");
	useEffect(() =>{
		localStorage.setItem("projectPortalFilterEndMonth", filterEndMonth);
	},[filterEndMonth]);

	const [filterSearch, setFilterSearch] = useState<string>(localStorage.getItem("projectPortalFilterSearch") || "");
	useEffect(() =>{
		localStorage.setItem("projectPortalFilterSearch", filterSearch);
	},[filterSearch]);

	const [filterMergeCOs, setFilterMergeCOs] = useState<boolean>(localStorage.getItem("projectPortalFilterMergeCOs") == "true");
	useEffect(() =>{
		localStorage.setItem("projectPortalFilterMergeCOs", filterMergeCOs.toString());
	},[filterMergeCOs]);

	const [filterProjectType, setFilterProjectType] = useState<string>(localStorage.getItem("projectPortalFilterProjectType") || "");
	useEffect(() =>{
		localStorage.setItem("projectPortalFilterProjectType", filterProjectType);
	},[filterProjectType]);

	const [tableOffsetTop, setTableOffsetTop] = useState<number>(0);
	const tableRef = React.createRef<HTMLDivElement>();
	const resize = () =>{
		setTableOffsetTop(tableRef.current!.offsetTop);
	}
	useEffect(() =>{
		window.addEventListener("resize", resize);
		resize();
		return () =>{
			window.removeEventListener("resize", resize);
		}
	},[])

	useEffect(() => {
		let table = tableRef.current!;
		if(table){
			const overrideWheel = (e: WheelEvent) => {
				if(table.scrollLeft === 0 && e.deltaX < 0){
					e.preventDefault();
				}
			};
			table.addEventListener("wheel", overrideWheel);
			return () => {
				table.removeEventListener("wheel", overrideWheel);
			};
		}
	}, [tableRef]);

  return (
		<>
	    <div className="screen ProjectProfitabilityScreen">

				<h1>Project Profitability</h1>

				<NavigationButtons />

				<div className="filters">
					<h3>Filter</h3>
					<div className="row">
						<div className="col">
							<div className="label">Start Month</div>
							<select value={filterStartMonth}
								onChange={(event) =>{
									setFilterStartMonth(event.target.value)
									tableRef.current!.scrollTop = 0;
									setMaxMonths(12);
									// if(filterStartYear == ""){
									// 	setFilterStartYear(new Date().getFullYear().toString());
									// }
									// if(filterEndMonth != "" && parseInt(filterEndMonth) < parseInt(event.target.value) &&
									// 	(filterEndYear == "" || parseInt(filterEndYear) <= parseInt(filterStartYear))){
									// 	setFilterEndMonth("")
									// }
								}}>
								<option value="">--</option>
								<option value="1">January</option>
								<option value="2">February</option>
								<option value="3">March</option>
								<option value="4">April</option>
								<option value="5">May</option>
								<option value="6">June</option>
								<option value="7">July</option>
								<option value="8">August</option>
								<option value="9">September</option>
								<option value="10">October</option>
								<option value="11">November</option>
								<option value="12">December</option>
							</select>
						</div>
						<div className="col">
							<div className="label">Start Year</div>
							<select value={filterStartYear}
								onChange={(event) =>{
									setFilterStartYear(event.target.value)
									tableRef.current!.scrollTop = 0;
									setMaxMonths(12);
								}}>
								<option value="">----</option>
								{(() =>{
									let years = [];
									let currentYear = new Date().getFullYear();
									for(let i = currentYear; i > currentYear - 30; i--){
										years.push(<option key={`year_${i}`} value={i}>{i}</option>)
									}
									return years;
								})()}
							</select>
						</div>
						<div className="col">
							<div className="label">End Month</div>
							<select value={filterEndMonth}
								onChange={(event) =>{
									setFilterEndMonth(event.target.value)
									tableRef.current!.scrollTop = 0;
									setMaxMonths(12);
									// if(filterEndYear == ""){
									// 	setFilterEndYear(new Date().getFullYear().toString());
									// }
									// if(filterStartMonth != "" && parseInt(filterStartMonth) > parseInt(event.target.value) &&
									// 	(filterEndYear == "" || parseInt(filterEndYear) >= parseInt(filterStartYear))){
									// 	setFilterEndMonth("")
									// }
								}}>
								<option value="">--</option>
								<option value="1">January</option>
								<option value="2">February</option>
								<option value="3">March</option>
								<option value="4">April</option>
								<option value="5">May</option>
								<option value="6">June</option>
								<option value="7">July</option>
								<option value="8">August</option>
								<option value="9">September</option>
								<option value="10">October</option>
								<option value="11">November</option>
								<option value="12">December</option>
							</select>
						</div>
						<div className="col">
							<div className="label">End Year</div>
							<select value={filterEndYear}
								onChange={(event) =>{
									setFilterEndYear(event.target.value)
									tableRef.current!.scrollTop = 0;
									setMaxMonths(12);

								}}>
								<option value="">----</option>
								{(() =>{
									let years = [];
									let currentYear = new Date().getFullYear();
									for(let i = currentYear; i > currentYear - 30; i--){
										years.push(<option key={`year_${i}`} value={i}>{i}</option>)
									}
									return years;
								})()}
							</select>
						</div>
						<div className="col">
							<div className="label">Merge CO</div>
							<input type='checkbox'
								checked={filterMergeCOs}
								onChange={event =>{
									setFilterMergeCOs(event.target.checked);
									tableRef.current!.scrollTop = 0;
									setMaxMonths(12);
								}}></input>
						</div>
						<div className="col">
							<div className="label">Project Type</div>
							<ProjectTypeDropdown
								prompt="All"
								defaultValue={filterProjectType}
								onChange={value =>{
									setFilterProjectType(value);
									tableRef.current!.scrollTop = 0;
									setMaxMonths(12);
								}}></ProjectTypeDropdown>
						</div>
						
						<div className="col">
							<div className="label">search</div>
							<div className="row">
								<input type='text' 
									defaultValue={filterSearch}
									ref={inputSearchRef}
									className="search"
									onChange={(event) =>{
										if(slowSearchTimeout != null){
											clearTimeout(slowSearchTimeout);
										}
										slowSearchTimeout = setTimeout(() =>{
											setFilterSearch(event.target.value)
											tableRef.current!.scrollTop = 0;
											setMaxMonths(12);
										}, 500);
									}}>
								</input>

								<div className='searchIcon'>
									<FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="label">Clear Filters</div>
							<div className='buttonClearFilters'
								onClick={() =>{
									setFilterStartMonth("");
									setFilterStartYear("");
									setFilterEndMonth("");
									setFilterEndYear("");
									setFilterMergeCOs(false);
									setFilterSearch("");
									setFilterProjectType("");
									inputSearchRef.current!.value = "";
									tableRef.current!.scrollTop = 0;
									setMaxMonths(12);
								}}>
								Clear
							</div>
						</div>
					</div>
				</div>


				<div className="content"
					ref={tableRef}
					style={{maxHeight:`calc(100vh - ${tableOffsetTop}px)`, display:(showingProjectPopup)?"none":"block"}}
					onScroll={(event) =>{						
						let table = event.target as HTMLTableElement;
						let scrollBottom = table.scrollHeight - table.scrollTop - table.clientHeight;
						if(scrollBottom < 10){
							setMaxMonths(maxMonths + 12);
						}
					}}>
					<ProjectProfitabilityTable 
						filterFlag={"completed"}
						mergeChangeOrders={filterMergeCOs}
						filterStartMonth={filterStartMonth}
						filterEndMonth={filterEndMonth}
						filterStartYear={filterStartYear}
						filterEndYear={filterEndYear}
						filterSearch={filterSearch}
						filterProjectType={filterProjectType}
						maxMonths={maxMonths}
						onViewProjectDetailsPressed={(group:Project[]) =>{
							setSelectedProjectGroup(group);
							setShowingProjectPopup(true);
						}}></ProjectProfitabilityTable>
				</div>

	    </div>
			{showingProjectPopup && (
				<PopupProjectProfitabilityDetails 
					group={selectedProjectGroup!}
					onDismiss={() =>{
						setSelectedProjectGroup(null);
						setShowingProjectPopup(false);			
					}}></PopupProjectProfitabilityDetails>
			)}
		</>
  )
}