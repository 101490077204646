

import React, { useEffect, useState } from "react"
import "./ProjectTasksTable.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import DraggableTableBody from "../../DraggableTableBody/DraggableTableBody";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { saveOrganizationAttributeChange } from "../../../Utils/SaveOrganizationFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { faBars, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shortDate } from "../../../Utils/formatting";
import { ProjectTask, ProjectTaskScheduleBlock } from "../../../FirebaseModel/Project";
import ProjectTasksRoleSection from "../ProjectTasksRoleSection/ProjectTasksRoleSection";

// import * as uuid from "uuid";
const uuid = require('uuid');

interface ProjectTasksTableProps{
	projectRoleTasks:{
		roleID:string,
		tasks:ProjectTask[]
	}[];
	projectID:string;
	plannedHours: { [roleID: string]: number; };
	isReadOnly?:boolean;
	onProjectTasksChanged:(projectRoleTasks:{roleID:string,tasks:ProjectTask[]}[]) => void;
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function ProjectTasksTable(props:ProjectTasksTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles},dispatch);

	const [projectRoleTasks, setProjectRoleTasks] = useState<{roleID:string,tasks:ProjectTask[]}[]>([]);
	
	const [saving, setSaving] = useState(false);

	useEffect(() =>{
		setProjectRoleTasks([... props.projectRoleTasks]);
	},[props.projectRoleTasks]);
	
  return (
    <table 
			className={`ProjectTasksTable`}>
			{saving == false && (
				<>
					<thead>
						<tr>
							<th>Role</th>
							<th>Task</th>
							<th>Assigned<br/>Hours</th>
							<th>Estimated<br/>Percent Complete</th>
							<th>Assigned<br/> Team Members</th>
							<th>Due Date</th>
						</tr>
					</thead>
					{roles.map((role:Role) => {
						return (
							<ProjectTasksRoleSection 
								projectRoleTasks={props.projectRoleTasks} 
								roleID={role.id} 
								projectID={props.projectID}
								isReadOnly={props.isReadOnly}
								plannedHours={props.plannedHours}
								onAddTask={(roleID: string) =>{
									let newTask:ProjectTask = {
										id: uuid.v4(),
										name: "",
										projectID: props.projectID,
										roleID: roleID,
										percentComplete: 0,
										assignedPersonIDs: [] as string[],
										plannedHours: 0,
										scheduleBlocks: [] as ProjectTaskScheduleBlock[],
										dueDate: ""
									}
									let newProjectRoleTasks = [...projectRoleTasks];
									let roleIndex = newProjectRoleTasks.findIndex((projectRoleTask) => projectRoleTask.roleID == roleID);
									if(roleIndex != -1){
										newProjectRoleTasks[roleIndex].tasks.push(newTask);
										setProjectRoleTasks(newProjectRoleTasks);
										props.onProjectTasksChanged(newProjectRoleTasks);
									}else{
										newProjectRoleTasks.push({
											roleID: roleID,
											tasks: [newTask]
										});
										setProjectRoleTasks(newProjectRoleTasks);
										props.onProjectTasksChanged(newProjectRoleTasks);
									}
								}} 
								onDeleteTask={(taskID: string)=>{ 
									let newProjectRoleTasks = [...projectRoleTasks];
									let roleIndex = newProjectRoleTasks.findIndex((projectRoleTask) => projectRoleTask.roleID == role.id);
									if(roleIndex != -1){
										let taskIndex = newProjectRoleTasks[roleIndex].tasks.findIndex((task) => task.id == taskID);
										if(taskIndex != -1){
											newProjectRoleTasks[roleIndex].tasks.splice(taskIndex,1);
											setProjectRoleTasks(newProjectRoleTasks);
											props.onProjectTasksChanged(newProjectRoleTasks);
										}
									}
								}} 
								
								onUpdateTask={(taskID: string, taskName: string, plannedHours: number, percentComplete: number, persons: string[],dueDate:string) =>{
									let newProjectRoleTasks = [...projectRoleTasks];
									let roleIndex = newProjectRoleTasks.findIndex((projectRoleTask) => projectRoleTask.roleID == role.id);
									if(roleIndex != -1){
										let taskIndex = newProjectRoleTasks[roleIndex].tasks.findIndex((task) => task.id == taskID);
										if(taskIndex != -1){
											newProjectRoleTasks[roleIndex].tasks[taskIndex].name = taskName;
											newProjectRoleTasks[roleIndex].tasks[taskIndex].plannedHours = plannedHours;
											newProjectRoleTasks[roleIndex].tasks[taskIndex].percentComplete = percentComplete;
											newProjectRoleTasks[roleIndex].tasks[taskIndex].assignedPersonIDs = persons;
											newProjectRoleTasks[roleIndex].tasks[taskIndex].dueDate = dueDate;
											setProjectRoleTasks(newProjectRoleTasks);
											props.onProjectTasksChanged(newProjectRoleTasks);
										}
									}
								} }></ProjectTasksRoleSection>
						)
					})}
				</>
			)}
    </table>
  )
}