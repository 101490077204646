
import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./ViewDropdown.css"
import { useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { forceNumeric, prettyCurrency, prettyNum } from "../../Utils/formatting";
import { sharedFirebaseAuth, sharedFirestore } from "../../Utils/SharedFirebase";
import { Client } from "../../FirebaseModel/Client";
import { View } from "../../FirebaseModel/View";
import { Person } from "../../FirebaseModel/Person";
interface ViewDropdownProps{
	className?:string,
	defaultValue?:string,
	viewType:string,
	columns:string[],
	prompt?:string,
	noCreation?:boolean,
	onChange?:(viewID:string) => void
	onCreateNew?:()=>void
}

export default function ViewDropdown(props:ViewDropdownProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const person = persons.find((person:Person) => person.email.toLowerCase() == sharedFirebaseAuth.currentUser!.email!.toLowerCase())!;
  const allViews = useSelector((state:{views:View[]}) => state.views);
	

	const [sortedViews, setSortedViews] = useState<View[]>([]);
	const [selectedValue, setSelectedValue] = useState(props.defaultValue);

	useEffect(() =>{
		if(organization){
			let tempSortedViews:View[] = [];
			allViews.forEach((view:View) =>{
				if(view.viewType == props.viewType && (view.public || view.creatorPersonID == person.id)){
					tempSortedViews.push(view);
				}
			});
			tempSortedViews.sort((a,b) =>{
				if(a.name.toLowerCase() < b.name.toLowerCase()){
					return -1;
				}
				if(a.name.toLowerCase() > b.name.toLowerCase()){
					return 1;
				}
				return 0;
			});
			setSortedViews(tempSortedViews);
		}
	},[organization,clients,allViews])

	useEffect(() =>{
		setSelectedValue(props.defaultValue);
	},[props.defaultValue])

  return (
    <select 
				className={`ViewDropdown ${props.className??""}`} 
				value={selectedValue}
				onChange={(event) =>{
					setSelectedValue(event.target.value);
					if(event.target.value == "create new"){
						if(props.onCreateNew){
							props.onCreateNew();
						}
					}else{
						if(props.onChange){
							props.onChange(event.target.value);
						}
					}
				}}>
			<option value={""}>{props.prompt??"Select View"}</option>
			{sortedViews.map((view:View, index:number) =>{
				return <option key={"view_option_"+index} value={view.id}>{view.name}</option>
			})}
			{!props.noCreation && (
				<option value="create new">Create a new View</option>
			)}
    </select>
  )
}