import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { Client } from "../FirebaseModel/Client";
import { Project } from "../FirebaseModel/Project";
import { Role } from "../FirebaseModel/Role";
import { saveProjectAttributeChange } from "./SaveProjectFunctions";
import { sharedFirestore } from "./SharedFirebase";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../Redux/ActionCreators";
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from "./UndoStackEntry";
import { ClientContact } from "../FirebaseModel/ClientContact";

export function saveClientContactAttributeChange(client:ClientContact,  attributeKey:string|string[], value:any|any[],undoEntryHandler:(entry:UndoStackEntry)=>void){


	const projectRef = doc(sharedFirestore, "ClientContact", client.id);

	let beforeEntry = {...client};

	let updatedClientContact:{ [x: string]: any; } = {};
	for(var key in client){
		if(attributeKey instanceof Array){
			for(var key2 in attributeKey){
				if(key == attributeKey[key2]){
					updatedClientContact[key] = value[key2];
					break;
				}else{
					updatedClientContact[key] = (client as any)[key];
				}
			}
		}else{
			if(attributeKey){
				if(key == attributeKey){
					updatedClientContact[key] = value;
				}else{
					updatedClientContact[key] = (client as any)[key];
				}
			}else{
				updatedClientContact[key] = (client as any)[key];
			}
		}
	}

	let undoEntry:UndoStackEntry = {
		type: UndoStackEntryType.update,
		objectType: UndoStackEntryObjectType.clientContact,
		objectID: client.id,
		objectBeforeState: beforeEntry,
		objectAfterState: {...updatedClientContact},
		description: "Update Client Contact"
	}
	undoEntryHandler(undoEntry);
	return updateDoc(projectRef,updatedClientContact)
}



export async function deleteClientContact(clientContact:ClientContact,undoEntryHandler:(entry:UndoStackEntry)=>void){
	
	undoEntryHandler({
		type: UndoStackEntryType.delete,
		objectType: UndoStackEntryObjectType.clientContact,
		objectID: clientContact.id,
		objectBeforeState: {...clientContact},
		objectAfterState: undefined,
		description: "Delete Client Contact"
	})
	return deleteDoc(doc(sharedFirestore, "ClientContact", clientContact.id));
}