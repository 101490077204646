

import React, { useEffect, useState } from "react"
import { addDoc, collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where } from "firebase/firestore";
import "./PopupSendSurvey.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import { prettyNum, forceNumeric, prettyCurrency, forceDecimal } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import ClientsContactTable from "../../Components/ClientsScreen/ClientsContactTable/ClientsContactTable";
import ClientProjectsTable from "../../Components/ClientsScreen/ClientProjectsTable/ClientProjectsTable";
import PopupAddProject from "../PopupAddProject/PopupAddProject";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { UndoStackEntry, UndoStackEntryType, UndoStackEntryObjectType } from "../../Utils/UndoStackEntry";
import { ClientContact } from "../../FirebaseModel/ClientContact";
import { deleteClientContact } from "../../Utils/SaveClientContactFunctions";
import axios from "axios";

import { CredentialResponse, OverridableTokenClientConfig, hasGrantedAllScopesGoogle, useGoogleLogin } from 'react-oauth-google';
import { Person } from "../../FirebaseModel/Person";
import { CircularProgress } from "@mui/material";

interface PopupSendSurveyProps {
	project: Project;
	contacts: { clientContactID: string, primaryContact: boolean }[];
	onCancel: () => void;
	onSend: (formID: string) => void;
}

declare const google: any;

export default function PopupSendSurvey(props: PopupSendSurveyProps) {

	const GoogleLogin = useGoogleLogin({
		flow: "implicit",
		scope: "https://www.googleapis.com/auth/drive",
		onSuccess: handleSuccess,
		onError: handleFailure,
	})
	useEffect(() => {
		let config: OverridableTokenClientConfig = {
		}
		GoogleLogin(config)

	}, [])

	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry: ActionCreators.submitUndoStackEntry
	}, dispatch);

	const organization = useSelector((state: { organization: Organization }) => state.organization)
	const clientContacts = useSelector((state: { clientContacts: ClientContact[] }) => state.clientContacts)
	const persons = useSelector((state: { persons: Person[] }) => state.persons)

	const [sendingSurvey, setSendingSurvey] = useState(false);

	function handleSuccess(response: any) {
		// check if response is of type GoogleLoginResponse
		console.log("reached handleSuccess", response);
		if (response && 'access_token' in response) {
			const accessToken = response.access_token;
			setAccessToken(accessToken);
			// Use accessToken as bearer token for your API requests
		}
	}

	function handleFailure(error: any) {
		console.log(error);
	}

	const createClientForm = async () => {
		return new Promise<{ formID: string, responderURL: string }>((resolve, reject) => {
			if(selectedContactIDs.length === 0) {
				resolve({formID: "", responderURL: ""});
				return;
			}

			axios({
				method: "post",
				url: "https://forms.googleapis.com/v1/forms",
				headers: {
					Authorization: `Bearer ${accessToken}`
				},
				data: {
					info: {
						title: surveyTitle,
						documentTitle: "client survey - " + props.project.projectName
					}
				}
			})
				.then((res) => {
					let data = res.data;
					console.log("form data.", data);
					let formID = data.formId;
					setFormID(formID);
					resolve({ formID, responderURL: data.responderUri });
				})
				.catch((err) => {
					console.log(err);
				});

		})



	}

	const clientSurvey = () => {
		const updateFormInfo = {
			updateFormInfo: {
				info: {
					description: surveyDescriptionClient,
				},
				updateMask: "description"
			}
		};
		const label1 = {
			createItem: {
				item: {
					title: "How did the final product turn out?",
					textItem: {
					},
				},
				location: { index: 0 },
			},
		};
		const question1 = {
			createItem: {
				item: {
					title: "1. How well did we produce a highly-creative, awesome deliverable that looks amazing?",
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 1 },
			},
		};
		const question2 = {
			createItem: {
				item: {
					title: "2. How well did we deliver an error-free final product that works right?",
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 2 },
			},
		};
		const question3 = {
			createItem: {
				item: {
					title: "3. How well does the final product fulfill the objectives of the project?",
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 3 },
			},
		};
		const comments1 = {
			createItem: {
				item: {
					title: "Comments:",
					questionItem: {
						question: { textQuestion: { paragraph: false } },
						// image: { altText: "sample", sourceUri: imageUrl2 },
					},
				},
				location: { index: 4 },
			},
		};
		const label2 = {
			createItem: {
				item: {
					title: `How was your experience with the ${organization.name} team related to:`,
					textItem: {
					},
				},
				location: { index: 5 },
			},
		};
		const question4 = {
			createItem: {
				item: {
					title: "4. Overall project communication?",
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 6 },
			},
		};
		const question5 = {
			createItem: {
				item: {
					title: "5. Accurately recording and incorporating your direction and feedback?",
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 7 },
			},
		};
		const question6 = {
			createItem: {
				item: {
					title: "6. Overall project organization and attention to detail?",
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 8 },
			},
		};
		const question7 = {
			createItem: {
				item: {
					title: "7. Hitting the timeline or deadline?",
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 9 },
			},
		};
		const question8 = {
			createItem: {
				item: {
					title: "8. Addressing any problems, challenges, or concerns you may have had throughout the project?",
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 10 },
			},
		};
		const question9 = {
			createItem: {
				item: {
					title: `9. How was your overall experience with ${organization.name}?`,
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 11 },
			},
		};
		const comments2 = {
			createItem: {
				item: {
					title: "Comments:",
					questionItem: {
						question: { textQuestion: { paragraph: false } },
						// image: { altText: "sample", sourceUri: imageUrl2 },
					},
				},
				location: { index: 12 },
			},
		};
		const label3 = {
			createItem: {
				item: {
					title: "Last Question...",
					textItem: {
					},
				},
				location: { index: 13 },
			},
		};
		const question10 = {
			createItem: {
				item: {
					title: `10. How likely would you be to recommend ${organization.name} to an associate or colleague?`,
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 14 },
			},
		};
		const label4 = {
			createItem: {
				item: {
					title: "Thank you for taking the time to provide your candid feedback and responses!  It will help us know how well we are doing and where we can improve.",
					textItem: {
					},
				},
				location: { index: 15 },
			},
		};
		const final = {
			createItem: {
				item: {
					title: `Is there is anything else you would like to communicate directly to the owners and management team of ${organization.name}?`,
					questionItem: {
						question: { textQuestion: { paragraph: false } },
						// image: { altText: "sample", sourceUri: imageUrl2 },
					},
				},
				location: { index: 12 },
			},
		};

		return {
			requests: [
				updateFormInfo,
				label1,
				question1,
				question2,
				question3,
				comments1,
				label2,
				question4,
				question5,
				question6,
				question7,
				question8,
				question9,
				comments2,
				label3,
				question10,
				label4,
				final
			]
		}
	}

	const addFieldsToClientForm = async (formID: string) => {
		return new Promise<void>((resolve, reject) => {
			if(selectedContactIDs.length === 0) {
				resolve();
				return;
			}

			axios({
				method: "post",
				url: `https://forms.googleapis.com/v1/forms/${formID}:batchUpdate`,
				headers: {
					Authorization: `Bearer ${accessToken}`
				},
				data: clientSurvey()
			})
				.then((res) => {
					console.log("updated form", res.data);
					resolve();
				})
				.catch((err) => {
					console.log(err);
				});
		})
	}

	const emailClientForm = async (responderURL: string) => {
		return new Promise<void>(async (resolve, reject) => {
			for (var i in selectedContactIDs) {
				let contact = clientContacts.find((e) => e.id === selectedContactIDs[i]);
				if (contact) {
					const docData = {
						to: contact.email,
						message:{
							subject: surveyTitle,
							text: surveyEmailBodyClient + " " + responderURL,
							html: surveyEmailBodyClient + " <a href='" + responderURL +"'>here</a>"
						}
					}
					console.log("sending client survey to " + contact.email)
					const docref = await addDoc(collection(sharedFirestore, "messages"), docData);
				}
			}
			resolve();
		})
	}


	const createInternalForm = async () => {
		return new Promise<{ formID: string, responderURL: string }>((resolve, reject) => {
			if(selectedPersonIDs.length === 0) {
				resolve({ formID: "", responderURL: "" });
				return;
			}

			axios({
				method: "post",
				url: "https://forms.googleapis.com/v1/forms",
				headers: {
					Authorization: `Bearer ${accessToken}`
				},
				data: {
					info: {
						title: surveyTitle,
						documentTitle: "internal survey - " + props.project.projectName
					}
				}
			})
				.then((res) => {
					let data = res.data;
					console.log("form data.", data);
					let formID = data.formId;
					setFormIDInternal(formID);
					resolve({ formID, responderURL: data.responderUri });
				})
				.catch((err) => {
					console.log(err);
				});
		})
	}

	const internalSurvey = () => {

		const updateFormInfo = {
			updateFormInfo: {
				info: {
					description: surveyDescriptionInternal,
				},
				updateMask: "description"
			}
		};
		const label1 = {
			createItem: {
				item: {
					title: "Solution, Scope, and Quality",
					textItem: {
					},
				},
				location: { index: 0 },
			},
		};
		const question1 = {
			createItem: {
				item: {
					title: `1. How well did ${organization.name} identify and understand the client's needs and objectives and how well did our solution meet and fulfill those objectives?`,
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 1 },
			},
		};
		const comments1 = {
			createItem: {
				item: {
					title: "1. Any comments:",
					questionItem: {
						question: { textQuestion: { paragraph: false } },
						// image: { altText: "sample", sourceUri: imageUrl2 },
					},
				},
				location: { index: 2 },
			},
		};
		const question2 = {
			createItem: {
				item: {
					title: `2. How well did ${organization.name} deliver error-free deliverables, accurate to the scope described in the contract, while taking into account appropriate client direction and feedback, and any change orders?`,
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 3 },
			},
		};
		const comments2 = {
			createItem: {
				item: {
					title: "2. Any comments:",
					questionItem: {
						question: { textQuestion: { paragraph: false } },
						// image: { altText: "sample", sourceUri: imageUrl2 },
					},
				},
				location: { index: 4 },
			},
		};
		const question3 = {
			createItem: {
				item: {
					title: `3. How well did ${organization.name} produce a highly-creative, technically-strong, awesome deliverable that the client and ${organization.name} will be proud of?`,
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 5 },
			},
		};
		const comments3 = {
			createItem: {
				item: {
					title: "3. Any comments:",
					questionItem: {
						question: { textQuestion: { paragraph: false } },
						// image: { altText: "sample", sourceUri: imageUrl2 },
					},
				},
				location: { index: 6 },
			},
		};
		const label2 = {
			createItem: {
				item: {
					title: "Managing Time, Resources, and Accountability",
					textItem: {
					},
				},
				location: { index: 7 },
			},
		};
		const question4 = {
			createItem: {
				item: {
					title: `4. How well did ${organization.name} create, maintain, and update an appropriate production timeline that was visible and communicated to the client and the production team?`,
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 8 },
			},
		};
		const comments4 = {
			createItem: {
				item: {
					title: "4. Any comments:",
					questionItem: {
						question: { textQuestion: { paragraph: false } },
						// image: { altText: "sample", sourceUri: imageUrl2 },
					},
				},
				location: { index: 9 },
			},
		};
		const question5 = {
			createItem: {
				item: {
					title: `5. How well did ${organization.name} hold the client and each team member accountable to their commitments throughout the project?`,
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 10 },
			},
		};
		const comments5 = {
			createItem: {
				item: {
					title: "5. Any comments:",
					questionItem: {
						question: { textQuestion: { paragraph: false } },
						// image: { altText: "sample", sourceUri: imageUrl2 },
					},
				},
				location: { index: 11 },
			},
		};
		const label3 = {
			createItem: {
				item: {
					title: "Communication and Organization",
					textItem: {
					},
				},
				location: { index: 12 },
			},
		};
		const question6 = {
			createItem: {
				item: {
					title: `6. How was the overall project communication?  Did ${organization.name} respond in a timely manner to the client’s questions and concerns?  Were important contract terms reviewed with the client and the ${organization.name} team so that everyone understood the contract's scope?  Was communication, direction, and feedback accurately recorded, organized, and communicated properly to all parties?`,
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 13 },
			},
		};
		const comments6 = {
			createItem: {
				item: {
					title: "6. Any comments:",
					questionItem: {
						question: { textQuestion: { paragraph: false } },
						// image: { altText: "sample", sourceUri: imageUrl2 },
					},
				},
				location: { index: 14 },
			},
		};
		const question7 = {
			createItem: {
				item: {
					title: "7. How organized was the project?  How well were the details of the project kept organized such as file management, documentation, scheduling, reporting, etc.?",
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 15 },
			},
		};
		const comments7 = {
			createItem: {
				item: {
					title: "7. Any comments:",
					questionItem: {
						question: { textQuestion: { paragraph: false } },
						// image: { altText: "sample", sourceUri: imageUrl2 },
					},
				},
				location: { index: 16 },
			},
		};
		const label4 = {
			createItem: {
				item: {
					title: "Client Relationship, Project Ownership, and Leadership",
					textItem: {
					},
				},
				location: { index: 17 },
			},
		};
		const question8 = {
			createItem: {
				item: {
					title: `8. How well was the account managed by the AM and the relationship with the client fostered throughout the course of the project?  How was the client's experience with the ${organization.name} team?  How much would they look forward to working with this team again?`,
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 18 },
			},
		};
		const comments8 = {
			createItem: {
				item: {
					title: "8. Any comments:",
					questionItem: {
						question: { textQuestion: { paragraph: false } },
						// image: { altText: "sample", sourceUri: imageUrl2 },
					},
				},
				location: { index: 19 },
			},
		};
		const question9 = {
			createItem: {
				item: {
					title: "9. How well did the PM fill the primary leadership role on the project by proactively driving the project to completion, and by addressing problems, challenges, and concerns throughout the project?",
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 20 },
			},
		};
		const comments9 = {
			createItem: {
				item: {
					title: "9. Any comments:",
					questionItem: {
						question: { textQuestion: { paragraph: false } },
						// image: { altText: "sample", sourceUri: imageUrl2 },
					},
				},
				location: { index: 21 },
			},
		};
		const label5 = {
			createItem: {
				item: {
					title: "Overall Satisfaction",
					textItem: {
					},
				},
				location: { index: 22 },
			},
		};
		const question10 = {
			createItem: {
				item: {
					title: "10. Overall, how well did this project go?",
					questionItem: {
						question: {
							scaleQuestion: {
								lowLabel: "Worst",
								highLabel: "Best",
								low: 1,
								high: 5,
							},
						},
					},
				},
				location: { index: 23 },
			},
		};
		const comments10 = {
			createItem: {
				item: {
					title: "10. Any comments:",
					questionItem: {
						question: { textQuestion: { paragraph: false } },
						// image: { altText: "sample", sourceUri: imageUrl2 },
					},
				},
				location: { index: 24 },
			},
		};
		const label6 = {
			createItem: {
				item: {
					title: "Thank you for taking the time to provide your candid feedback and responses!  It will help us know how well we are doing and where we can improve.",
					textItem: {
					},
				},
				location: { index: 15 },
			},
		};

		return {
			requests: [
				updateFormInfo,
				label1,
				question1,
				comments1,
				question2,
				comments2,
				question3,
				comments3,
				label2,
				question4,
				comments4,
				question5,
				comments5,
				label3,
				question6,
				comments6,
				question7,
				comments7,
				label4,
				question8,
				comments8,
				question9,
				comments9,
				label5,
				question10,
				comments10,
				label6
			]
		}
	}

	const addFieldsToInternalForm = async (formID: string) => {
		return new Promise<void>((resolve, reject) => {
			if(selectedContactIDs.length === 0) {
				resolve();
				return;
			}
			
			axios({
				method: "post",
				url: `https://forms.googleapis.com/v1/forms/${formID}:batchUpdate`,
				headers: {
					Authorization: `Bearer ${accessToken}`
				},
				data: internalSurvey()
			})
				.then((res) => {
					resolve();
				})
				.catch((err) => {
					console.log(err);
				});

		})
	}

	const emailInternalForm = async (responderURL: string) => {
		console.log("calling email internal form")
		return new Promise<void>(async (resolve, reject) => {
			for (var i in selectedPersonIDs) {
				let person = persons.find((e) => e.id === selectedPersonIDs[i]);
				if (person) {
					const docData = {
						to: person.email,
						message:{
							subject: surveyTitle,
							text: surveyEmailBodyInternal +" "+ responderURL,
							html: surveyEmailBodyInternal +" <a href='" + responderURL +"'>here</a>"
						}
					}
					console.log("sending internal survey to " + person.email)
					const docref = await addDoc(collection(sharedFirestore, "messages"), docData);
				}
			}
			resolve();
		})
	}

	const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
	const [surveyTitle, setSurveyTitle] = useState<string>("End of Project Survey: " + props.project.projectName);
	const [surveyDescriptionClient, setSurveyDescriptionClient] = useState<string>(`At ${organization.name}, we want you to have an amazing experience working with us and provide you with the most incredible creative solution possible... and we are always trying to improve!

Your feedback and comments in this survey are very much appreciated and will be sent directly to the owners and management team of ${organization.name} who want to make sure we are doing a great job for you. Thanks for taking a second to let us know what you think.`);
	const [surveyEmailBodyClient, setSurveyEmailBodyClient] = useState<string>("Please fill out the survey");
	const [surveyEmailBodyInternal, setSurveyEmailBodyInternal] = useState<string>("Please fill out the survey");

	const [surveyDescriptionInternal, setSurveyDescriptionInternal] = useState<string>(`Thanks for taking the time to provide your input and perspectives on how we did as a team on this project.  This will help us know how well we are doing and where we can improve.`);

	const [formID, setFormID] = useState<string | undefined>(undefined);
	const [formIDInternal, setFormIDInternal] = useState<string | undefined>(undefined);
	const [selectedContactIDs, setSelectedContactIDs] = useState<string[]>([]);
	const [selectedPersonIDs, setSelectedPersonIDs] = useState<string[]>([]);

	useEffect(() => {
		if (props.project) {
			let tempSelectedPersonIDs: string[] = [];
			if (props.project.accountManagerID != "") {
				tempSelectedPersonIDs.push(props.project.accountManagerID);
			}
			if (props.project.projectManagerID != "") {
				tempSelectedPersonIDs.push(props.project.projectManagerID);
			}
			if (props.project.regionalSalesManagerID != "") {
				tempSelectedPersonIDs.push(props.project.regionalSalesManagerID);
			}
			props.project.projectRoleTasks.forEach((projectRoleTask) => {
				projectRoleTask.tasks.forEach((task) => {
					task.assignedPersonIDs.forEach((assignedPersonID) => {
						if (!selectedPersonIDs.includes(assignedPersonID)) {
							let person = persons.find((person) => person.id === assignedPersonID);
							if (person && person.active) {
								tempSelectedPersonIDs.push(assignedPersonID);
							}
						}
					})
				})
			})
			setSelectedPersonIDs(tempSelectedPersonIDs);
		}
	}, [props.project])


	return (
		<>
			<div className={`popup PopupSendSurvey`}>
				<h1>New Survey</h1>

				<div className="content">

					{accessToken ? (
						<>
							<h2>Authentication Complete.</h2>

							<h3>Survey Title</h3>
							<input type="text" className="surveyTitle" defaultValue={surveyTitle} onChange={(e) => {
								setSurveyTitle(e.target.value);
							}} />

							<h3>Client Survey Description</h3>
							<textarea className="surveyDescriptionClient" defaultValue={surveyDescriptionClient} onChange={(e) => {
								setSurveyDescriptionClient(e.target.value);
							}} />

							<h3>Client Survey Email Body</h3>
							<textarea className="surveyEmailBody" defaultValue={surveyEmailBodyClient} onChange={(e) => {
								setSurveyEmailBodyClient(e.target.value);
							}} />

							<h3>Which client contacts to send it to?</h3>
							<div className="contactsHolder">
								{props.contacts.map((contactInfo) => {
									let clientContact = clientContacts.find((clientContact) => clientContact.id === contactInfo.clientContactID);
									if (clientContact && clientContact.email === "") {
										return <></>;
									}
									return (
										<div className="contact">
											<input type='checkbox'
												checked={selectedContactIDs.includes(contactInfo.clientContactID)}
												onChange={(e) => {
													if (e.target.checked) {
														setSelectedContactIDs([...selectedContactIDs, contactInfo.clientContactID]);
													} else {
														setSelectedContactIDs(selectedContactIDs.filter((id) => id !== contactInfo.clientContactID));
													}
												}} />
											<div className="name">{clientContact?.name} ({clientContact?.email})</div>
										</div>
									)
								})}
							</div>

							<h3>Internal Survey Description</h3>
							<textarea className="surveyDescriptionInternal" defaultValue={surveyDescriptionInternal} onChange={(e) => {
								setSurveyDescriptionInternal(e.target.value);
							}} />
							<h3>Internal Survery Email Body</h3>
							<textarea className="surveyEmailBodyInternal" defaultValue={surveyEmailBodyInternal} onChange={(e) => {
								setSurveyEmailBodyInternal(e.target.value);
							}} />

							<h3>Which internal people to send to?</h3>
							<div className="internalPeopleHolder">
								{persons.map((person) => {
									if (!person.active || person.email === "") {
										return <></>;
									}
									return (
										<div className="person" key={person.id}>
											<input type='checkbox'
												checked={selectedPersonIDs.includes(person.id)}
												onChange={(e) => {
													if (e.target.checked) {
														setSelectedPersonIDs([...selectedPersonIDs, person.id]);
													} else {
														setSelectedPersonIDs(selectedPersonIDs.filter((id) => id !== person.id));
													}
												}} />
											<div className="name">{person.name} ({person.email})</div>
										</div>
									)
								})}
							</div>
						</>
					) : (
						<div className="googleLoginHolder">
							<h2>Log into google to create a google survey for this project</h2>
						</div>
					)}


				</div>


				<div className="floatingButtons">
					{!sendingSurvey ? (
						<>
							<div className="buttonCancel"
								onClick={() => {
									props.onCancel();
								}}>Cancel</div>
							<div className="buttonSave"
								onClick={() => {
									setSendingSurvey(true);
									createClientForm().then((clientForm) => {
										addFieldsToClientForm(clientForm.formID).then(() => {
											emailClientForm(clientForm.responderURL).then(() => {
												createInternalForm().then((internalForm) => {
													addFieldsToInternalForm(internalForm.formID).then(() => {
														emailInternalForm(internalForm.responderURL).then(() => {
															props.onSend(clientForm.formID);
														})
													})
												})
											})
										})
									});
								}}>Save &amp; Close</div>
						</>
					) : (
						<CircularProgress />
					)}
				</div>
			</div>
		</>
	)
}