
import React, { ReactNode, useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./AnnualBSPRow.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role, rolesExistAM } from "../../../FirebaseModel/Role";
import { prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowDown19, faArrowUp, faArrowUp19, faChevronDown, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { MonthTotal } from "../../../FirebaseModel/MonthTotal";
import { getOrCreateMonthTotal } from "../../../Utils/SaveMonthTotalFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { YearTotal } from "../../../FirebaseModel/YearTotal";
import { getOrCreateYearTotal } from "../../../Utils/SaveYearTotalFunctions";

interface AnnualBSPRowProps{
	row: {
		label:string,
		key:string,
		isCurrency?:boolean,
		isPercentage?:boolean,
		showTotal?:boolean,
		calculateBSPSalesRatio?:boolean,
		className?:string,
	},
	yearTotal:YearTotal,
	year:number;

}

export default function AnnualBSPHeader(props:AnnualBSPRowProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const allMonthTotals = useSelector((state:{monthTotals:MonthTotal[]}) => state.monthTotals)
  const allYearTotals = useSelector((state:{yearTotals:YearTotal[]}) => state.yearTotals)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

 

	const [sortYearAscending, setSortYearAscending] = useState<boolean>(true);
	const [sortMonthAscending, setSortMonthAscending] = useState<boolean>(true);

	const [monthTotals, setMonthTotals] = useState<MonthTotal[]>([]);
	const [yearTotal, setYearTotal] = useState<YearTotal|null>(props.yearTotal);

	const loadMonthTotals = async () => {
		let tempMotTotals:MonthTotal[] = [];
		let today = new Date();
		for(var i = 0; i < 12; i++){
			if(i < today.getMonth() || props.year < today.getFullYear()){
				tempMotTotals.push(await getOrCreateMonthTotal(i+1, props.year,allMonthTotals, organization, (entry) =>{
					AC.submitUndoStackEntry(entry);
				}));
			}
		}
		setMonthTotals(tempMotTotals);
	};

	useEffect(() => {
		loadMonthTotals();
	},[allMonthTotals]);


  return (
		<tr className={`AnnualBSPRow ${props.row.className??""}`}>
			<td>{props.row.label}</td>
			{monthTotals.length > 0 && (
				<>
					{(() =>{
						let nodes:ReactNode[] = [];
						let today = new Date();
						for(var i = 0; i < 12; i++){
							if(i < today.getMonth() || props.year < today.getFullYear() && i < monthTotals.length){
								if(props.row.isCurrency){
									nodes.push(<td key={`${props.row.label}_${i}`}>{prettyCurrency(((monthTotals[i].calculated.bsp as any)[props.row.key]))}</td>);
								}else if(props.row.isPercentage){
									nodes.push(<td key={`${props.row.label}_${i}`}>{Math.round(((monthTotals[i].calculated.bsp as any)[props.row.key]) * 100)}%</td>);
								}else{
									nodes.push(<td key={`${props.row.label}_${i}`}>{prettyNum(((monthTotals[i].calculated.bsp as any)[props.row.key]))}</td>);
								}
							}
						}	
						return nodes;
					})()}
				</>
			)}
			{(monthTotals.length > 0 && yearTotal) && (
				<>
				{(() =>{
					if(props.row.showTotal){
						return (
							<>
								<td>{prettyCurrency(monthTotals.reduce((sum,mt) => sum+=(mt.calculated.bsp as any)[props.row.key],0))}</td>
								<td>{prettyCurrency(((yearTotal.calculated.averageBSP_3Months as any)[props.row.key]))}</td>
								<td>{prettyCurrency(((yearTotal.calculated.averageBSP_6Months as any)[props.row.key]))}</td>
								<td>{prettyCurrency(((yearTotal.calculated.averageBSP as any)[props.row.key]))}</td>
							</>
						)
					}
					if(props.row.calculateBSPSalesRatio){
						//nodes.push(<th key={`${props.row.label}_${i}`}>{Math.round(((monthTotals[i].calculated.bsp as any)[props.row.key]) * 100)}%</td>);
						let bspSum = monthTotals.reduce((sum,mt) => sum+=mt.calculated.bsp.billableScopeProduced,0);
						let prodBudjetSum = monthTotals.reduce((sum,mt) => sum+=mt.calculated.bsp.productionBudgetPreviousMonth,0);
						let totalBSPToSales = prodBudjetSum==0?0:bspSum/prodBudjetSum;
						return (
							<>
								<td>{Math.round((totalBSPToSales) * 100)}%</td>
								<td>{Math.round(((yearTotal.calculated.averageBSP_3Months as any)[props.row.key]) * 100)}%</td>
								<td>{Math.round(((yearTotal.calculated.averageBSP_6Months as any)[props.row.key]) * 100)}%</td>
								<td>{Math.round(((yearTotal.calculated.averageBSP as any)[props.row.key]) * 100)}%</td>
							</>
						)
					}
					if(props.row.isCurrency){
						//nodes.push(<th key={`${props.row.label}_${i}`}>{prettyCurrency(((monthTotals[i].calculated.bsp as any)[props.row.key]))}</td>);
						return (
							<>
								<td></td>
								<td>{prettyCurrency(((yearTotal.calculated.averageBSP_3Months as any)[props.row.key]))}</td>
								<td>{prettyCurrency(((yearTotal.calculated.averageBSP_6Months as any)[props.row.key]))}</td>
								<td>{prettyCurrency(((yearTotal.calculated.averageBSP as any)[props.row.key]))}</td>
							</>
						)
					}else if(props.row.isPercentage){
						//nodes.push(<th key={`${props.row.label}_${i}`}>{Math.round(((monthTotals[i].calculated.bsp as any)[props.row.key]) * 100)}%</td>);
						return (
							<>
								<td></td>
								<td>{Math.round(((yearTotal.calculated.averageBSP_3Months as any)[props.row.key]) * 100)}%</td>
								<td>{Math.round(((yearTotal.calculated.averageBSP_6Months as any)[props.row.key]) * 100)}%</td>
								<td>{Math.round(((yearTotal.calculated.averageBSP as any)[props.row.key]) * 100)}%</td>
							</>
						)
					}else{
						//nodes.push(<th key={`${props.row.label}_${i}`}>{prettyNum(((monthTotals[i].calculated.bsp as any)[props.row.key]))}</td>);
						return (
							<>
								<td></td>
								<td>{prettyNum(((yearTotal.calculated.averageBSP_3Months as any)[props.row.key]))}</td>
								<td>{prettyNum(((yearTotal.calculated.averageBSP_6Months as any)[props.row.key]))}</td>
								<td>{prettyNum(((yearTotal.calculated.averageBSP as any)[props.row.key]))}</td>
							</>
						)
					}
				})()
				}
				</>
			)}
		</tr>
	)
}