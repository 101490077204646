
import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./ClientsScreenClientsInput.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { forceNumeric, prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { saveProjectAttributeChange, saveProjectPlannedHoursChange } from "../../../Utils/SaveProjectFunctions";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { Client } from "../../../FirebaseModel/Client";
import { saveClientAttributeChange } from "../../../Utils/SaveClientFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { UndoStackEntry } from "../../../Utils/UndoStackEntry";
interface ClientsScreenClientsInputProps{
	className?:string,
	client:Client,
	attributeKey:string,
	forceNumeric?:boolean,
	isCurrency?:boolean
}

export default function ClientsScreenClientsInput(props:ClientsScreenClientsInputProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

  const organization = useSelector((state:{organization:Organization}) => state.organization)

	const [defaultValue, setDefaultValue] = useState("");

	useEffect(() =>{
		if(props.attributeKey){
			if(props.isCurrency){
				if(props.client.useOverrideHourlyRate){
					setDefaultValue(prettyCurrency((props.client as any)[props.attributeKey] as number))
				}else{
					setDefaultValue(prettyCurrency(organization.hourlyRate))
				}
			}else{
				setDefaultValue((props.client as any)[props.attributeKey])
			}
		}
	},[props.client,props.attributeKey,organization])

	function forceDecimal(event: React.KeyboardEvent<HTMLInputElement>) {
		throw new Error("Function not implemented.");
	}

  return (
    <input 
			className={`ClientsScreenClientsInput ${props.className??""} ${props.attributeKey??""}`}
			disabled={props.className=="disabled"}
			onBlur={(event) =>{

				if(props.forceNumeric && event.target.value == ""){
					event.target.value = "0";
				}

				if(props.isCurrency){
					event.target.value = prettyCurrency(event.target.value);
				}

				if(props.forceNumeric){
					saveClientAttributeChange(props.client,props.attributeKey,parseFloat(event.target.value.replace("$","").replace(/,/g,"")),(entry:UndoStackEntry) =>{
						AC.submitUndoStackEntry(entry);
					});
				}else{
					saveClientAttributeChange(props.client,props.attributeKey,event.target.value,(entry:UndoStackEntry) =>{
						AC.submitUndoStackEntry(entry);
					});
				}
			}}
			onFocus={(event)=>{
				if(props.forceNumeric){
					let input = event.target as HTMLInputElement
					input.setSelectionRange(0,input.value.length)
				}
			}}
			onKeyPress={(event) =>{
				if(props.forceNumeric){
					forceDecimal(event);
				}
			}}
			defaultValue={defaultValue}>
    </input>
  )
}