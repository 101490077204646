
import React, { ReactNode, useEffect, useState } from "react"
import "./HomeTaskRow.css"
import { Project, ProjectTask, findProjectTasksFromDueDate, findScheduleBlockForDate, findScheduleBlockForTaskDate } from "../../../FirebaseModel/Project";
import { useSelector } from "react-redux";
import { Role } from "../../../FirebaseModel/Role";
import { faBars, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { Organization, holiday } from "../../../FirebaseModel/Organization";
import { prettyNum, shortDate } from "../../../Utils/formatting";
import ProductionScheduleTaskDueDate from "../../ProductionSchedule/ProductionScheduleTaskDueDate/ProductionScheduleTaskDueDate";
import ProductionScheduleTaskScheduleBlockDay from "../../ProductionSchedule/ProductionScheduleTaskScheduleBlockDay/ProductionScheduleTaskScheduleBlockDay";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";

interface HomeTaskRowProps{
  columns:string[],
	projectTask:ProjectTask,
	numMonths:number,
	personID:string,
	hideCalendar?: boolean,	
}


export default function HomeTaskRow(props:HomeTaskRowProps){
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)

	const [plannedRoleHours, setPlannedRoleHours] = useState<number>(0);
	const [actualRoleHours, setActualRoleHours] = useState<number>(0);


	const [months, setMonths] = useState<Date[]>([]);
	const [days, setDays] = useState<Date[]>([]);

	const [project, setProject] = useState<Project | undefined>(undefined);
	const [role, setRole] = useState<Role | undefined>(undefined);
	useEffect(() => {
		setProject(projects.find((project: Project) => project.id === props.projectTask.projectID));
		setRole(roles.find((role: Role) => role.id === props.projectTask.roleID));
	}, [projects, roles, props.projectTask])
	const today = new Date();

	useEffect(() => {
		if(project){
			setPlannedRoleHours(project.plannedHours.find((plannedHours) => plannedHours.roleID === props.projectTask.roleID)?.hours || 0);
		}
	}, [project, props.projectTask.roleID]);

	useEffect(() => {
		if(project && actuals){
			let actualHours = 0;
			actuals.forEach((actual) => {
				if(actual.projectID === project.id && actual.roleID === props.projectTask.roleID){
					actualHours += actual.calculated.totalHours;
				}
			})
			setActualRoleHours(actualHours);
		}
	}, [project, actuals, props.projectTask.roleID]);


	useEffect(() =>{
		let months:Date[] = [];
		let date = new Date();
		for(let i = 0; i < props.numMonths; i++){
			months.push(new Date(date.getFullYear(), date.getMonth() + i, 1));
		}
		setMonths(months);

		// add every day from months into the days array
		let days:Date[] = [];
		months.forEach(month =>{
			let date = new Date(month);
			while(date.getMonth() === month.getMonth()){
				days.push(new Date(date));
				date.setDate(date.getDate() + 1);
			}
		})
		setDays(days);
	},[props.numMonths])

	const rowForColumn = (column:string, index:number) => {
		let firstColumnStuff = index ==0?<>
			<FontAwesomeIcon icon={faBars} style={{pointerEvents:"none", fontSize:"50%"}}></FontAwesomeIcon>
				</>:<></>
		switch(column){
			case "Project":
				return <td className="project">{firstColumnStuff}{project?.projectName}</td>
			case "Role":
				return <td className="role">{firstColumnStuff}{role?.abbreviation}</td>
			case "Task":
				return <td className="task">{firstColumnStuff}{props.projectTask.name}</td>
			case "Planned Roles Hours":
				return <td className="plannedRoleHours">{firstColumnStuff}{prettyNum(plannedRoleHours)}</td>
			case "Actual Role Hours":
				return <td className="actualRoleHours">{firstColumnStuff}{prettyNum(actualRoleHours)}</td>
			case "Remaining":
				return <td className="remaining">{firstColumnStuff}{prettyNum(plannedRoleHours - actualRoleHours)}</td>
			case "Estimated % Complete":
				return <td className='estimatedPercentComplete'>{firstColumnStuff}{Math.round(props.projectTask.percentComplete*100)}%</td>
		}
		return <></>
	}

  return (
    <>
			{props.columns && (
				<>
				{project && role && (
					<>
						{props.columns.map((column:string, index:number) => {
							return rowForColumn(column,index)
						})}
						{!props.hideCalendar && days.map((day, index) =>{
							if(!organization) return (<></>);
							// add a header with the single letter day of the week
							let h = holiday(shortDate(day),organization)
							let isToday = day.getDate() == today.getDate() && day.getMonth() == today.getMonth() && day.getFullYear() == today.getFullYear();
							let isWeekend = day.getDay() === 0 || day.getDay() === 6;
							let taskScheduleBlock = findScheduleBlockForTaskDate(project!, props.personID, role!.id, props.projectTask, day);
							
							let dueDates = findProjectTasksFromDueDate(project!, role!.id, day);
							return <td key={index} className={`day ${isToday?"today":""} ${isWeekend?"weekend":""} ${h?"holiday":""}`}>
								{taskScheduleBlock && (
									<Tooltip title={`${project.projectName} - ${props.projectTask!.name}`} placement="bottom">
										<div>
											<ProductionScheduleTaskScheduleBlockDay
												taskScheduleBlock={taskScheduleBlock}
												day={day} 
												onEditTaskScheduleBlock={(taskScheduleBlock) =>{
													//props.onEditTaskScheduleBlock(taskScheduleBlock);
												} }></ProductionScheduleTaskScheduleBlockDay>
										</div>
								</Tooltip>
								)}
								{dueDates.map((task) =>{
									return <ProductionScheduleTaskDueDate projectTask={task}></ProductionScheduleTaskDueDate>
								})}
							</td>
						})}
					</>
			)}
				</>
			)}
		</>
  )
}