import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { Client } from "../FirebaseModel/Client";
import { Role } from "../FirebaseModel/Role";
import { TimeOff } from "../FirebaseModel/TimeOff";
import { sharedFirestore } from "./SharedFirebase";
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from "./UndoStackEntry";
import { Organization } from "../FirebaseModel/Organization";
import { Person } from "../FirebaseModel/Person";

export function saveTimeOffAttributeChange(timeOff:TimeOff,  attributeKey:string|string[], value:any|any[],undoEntryHandler:(entry:UndoStackEntry)=>void){


	const projectRef = doc(sharedFirestore, "TimeOff", timeOff.id);

	let updatedTimeOff:{ [x: string]: any; } = {};
	for(var key in timeOff){
		if(attributeKey instanceof Array){
			for(var key2 in attributeKey){
				if(key == attributeKey[key2]){
					updatedTimeOff[key] = value[key2];
					break;
				}else{
					updatedTimeOff[key] = (timeOff as any)[key];
				}
			}
		}else{
			if(attributeKey){
				if(key == attributeKey){
					updatedTimeOff[key] = value;
				}else{
					updatedTimeOff[key] = (timeOff as any)[key];
				}
			}else{
				updatedTimeOff[key] = (timeOff as any)[key];
			}
		}
	}
	let undoEntry:UndoStackEntry = {
		type: UndoStackEntryType.update,
		objectType: UndoStackEntryObjectType.timeOff,
		objectID: timeOff.id,
		objectBeforeState: {...timeOff},
		objectAfterState: {...updatedTimeOff},
		description: "Update Time Off"
	}
	undoEntryHandler(undoEntry);

	return updateDoc(projectRef,updatedTimeOff)
}



export function deleteTimeOff(timeOff:TimeOff,undoEntryHandler:(entry:UndoStackEntry)=>void){

	let undoEntry:UndoStackEntry = {
		type: UndoStackEntryType.delete,
		objectType: UndoStackEntryObjectType.role,
		objectID: timeOff.id,
		objectBeforeState: {...timeOff},
		objectAfterState: undefined,
		description: "Delete Time Off"
	}
	undoEntryHandler(undoEntry);
	return deleteDoc(doc(sharedFirestore, "TimeOff", timeOff.id));
}


export async function updatePersonTimeOffAllottedPTOAndSTO(person:Person, organization:Organization, timeOffList:TimeOff[],undoEntryHandler:(entry:UndoStackEntry)=>void){
	let thisYear = new Date().getFullYear();
	for(let i in timeOffList){
		let timeOff = timeOffList[i];
		if(timeOff.personID == person.id && timeOff.year == thisYear){
			let allottedPTO = person.PTOPerYear;
			let allottedSTO = person.STOPerYear;
			if(organization.managesPTOAtOrganizationLevel){
				allottedPTO = organization.PTOPerYear;
				allottedSTO = organization.STOPerYear;
			}
			if(timeOff.allottedPTO != allottedPTO || timeOff.allottedSTO != allottedSTO){
				await saveTimeOffAttributeChange(timeOff,["allottedPTO","allottedSTO"],[allottedPTO,allottedSTO],undoEntryHandler);
				timeOff.allottedPTO = allottedPTO;
				timeOff.allottedSTO = allottedSTO;
			}
			return;
		}
	}
}