
import React, { useEffect, useState } from "react"
import "./OrganizationAdministratorsList.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Client } from "../../../FirebaseModel/Client";
import { ClientContact } from "../../../FirebaseModel/ClientContact";
import OrganizationAdminCard from "../OrganizationAdminCard/OrganizationAdminCard";
import { saveOrganizationAttributeChange } from "../../../Utils/SaveOrganizationFunctions";
import PersonDropdown from "../../PersonDropdown/PersonDropdown";
import { Person } from "../../../FirebaseModel/Person";

interface OrganizationAdministratorsListProps{
	isReadOnly?:boolean;
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function OrganizationAdministratorsList(props:OrganizationAdministratorsListProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles,
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	const [adminPersonIDs, setAdminPersonIDs] = useState<string[]>(organization.organizationAdministratorPersonIDs);
	
	
  return (
    <div className={`OrganizationAdministratorsList`}>
			{adminPersonIDs.map((personID:string, index:number) =>{
				let person = persons.find((person) => person.id===personID);
				if(!person){
					return <></>
				}
				return (
					<OrganizationAdminCard
						key={person.id}
						isReadOnly={props.isReadOnly}
						personID={person.id}
						onDelete={(personID:string) =>{
							let newAdminPersonIDs = [... adminPersonIDs];
							newAdminPersonIDs.splice(index,1);
							setAdminPersonIDs(newAdminPersonIDs);
							saveOrganizationAttributeChange(organization, "organizationAdministratorPersonIDs", newAdminPersonIDs,entry =>{
								AC.submitUndoStackEntry(entry);
							});
						}}></OrganizationAdminCard>
				)
			})}		
			{!props.isReadOnly && (
				<div className="row">
					Add new: &nbsp;
					<PersonDropdown
						noCreation={true}
						prompt="Select a person to add as an administrator"
						defaultValue={""}
						onChange={(personID) =>{
							if(!personID){
								return;
							}
							let newAdminPersonIDs = [... adminPersonIDs];
							newAdminPersonIDs.push(personID);
							setAdminPersonIDs(newAdminPersonIDs);
							saveOrganizationAttributeChange(organization, "organizationAdministratorPersonIDs", newAdminPersonIDs,entry =>{
								AC.submitUndoStackEntry(entry);
							});
						}}
						onCreateNew={()=>{
						}}></PersonDropdown>
				</div>
			)}
				
    </div>
  )
}