

import React, { useEffect, useState } from "react"
import { addDoc, collection, deleteDoc, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./PopupWeeklyTimesheet.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import { prettyNum, forceNumeric, prettyCurrency, forceDecimal, shortDate } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { Person } from "../../FirebaseModel/Person";
import WeeklyTimesheetTable from "../../Components/WeeklyTimesheet/WeeklyTimesheetTable/WeeklyTimesheetTable";
import { ProjectRoleActualHours } from "../../FirebaseModel/ProjectRoleActualHours";
import PopupAddClient from "../PopupAddClient/PopupAddClient";
import PopupAddProject from "../PopupAddProject/PopupAddProject";
import { saveProjectRoleActualHours } from "../../Utils/SaveProjectRoleActualFunctions";
import PopupAddRole from "../PopupAddRole/PopupAddRole";
import { saveProjectAttributeChange } from "../../Utils/SaveProjectFunctions";
import PopupDailyTimesheet from "../PopupDailyTimesheet/PopupDailyTimesheet";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { UndoStackEntry } from "../../Utils/UndoStackEntry";
import { faRocket, faExplosion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveYearTotalAttributeChange } from "../../Utils/SaveYearTotalFunctions";
import { saveMonthTotalAttributeChange } from "../../Utils/SaveMonthTotalFunctions";
import { MonthTotal } from "../../FirebaseModel/MonthTotal";
import { YearTotal } from "../../FirebaseModel/YearTotal";
import { TimeOff } from "../../FirebaseModel/TimeOff";
import { saveTimeOffAttributeChange } from "../../Utils/SaveTimeOffFunctions";
interface PopupWeeklyTimesheetProps{
	onDismiss:()=>void;
	person:Person;
}

export default function PopupWeeklyTimesheet(props:PopupWeeklyTimesheetProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const allActuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
	const permissionsWrite = useSelector((state: { permissionsWrite: string[] }) => state.permissionsWrite)
	const monthTotals = useSelector((state: { monthTotals: MonthTotal[] }) => state.monthTotals)
	const yearTotals = useSelector((state: { yearTotals: YearTotal[] }) => state.yearTotals)
	const timeOffList = useSelector((state: { timeOffList: TimeOff[] }) => state.timeOffList)

	const [showingClientPopup, setShowingClientPopup] = useState(false);
	const [selectedActual, setSelectedActual] = useState<ProjectRoleActualHours>();
	const [showingProjectPopup, setShowingProjectPopup] = useState(false);
	const [showingRolePopup, setShowingRolePopup] = useState(false);
	const [showingDailyInput, setShowingDailyInput] = useState(false);
	const [selectedweekKey, setSelectedWeekKey] = useState<string>("");

	const [year , setYear] = useState<number>(new Date().getFullYear());

	const [selectedActuals, setSelectedActuals] = useState<ProjectRoleActualHours[]>([]);
	const [selectedPTO, setSelectedPTO] = useState<TimeOff|null>(null);
	const [selectedSTO, setSelectedSTO] = useState<TimeOff|null>(null);
	const [actuals, setActuals] = useState<ProjectRoleActualHours[]>([]);	



	const recalculateProjectMonthAndYearTotals = async () =>{
		await projects.forEach(async project => {
			let actualsForProject = actuals.filter(actual => actual.projectID === project.id);
			let totalHours = actualsForProject.reduce((total, actual) => total + actual.calculated.totalHours, 0);
			if (project.calculated.actualHours != totalHours) {
				let newCalcualted = { ...project.calculated }
				newCalcualted.actualHours = totalHours;

				let latestActivity = newCalcualted.latestActivity
				actuals.forEach(actual => {
					if(actual.projectID == project.id){
						actual.hours.forEach(hour => {
							if(latestActivity == ""){
								latestActivity = hour.date;
							}else{
								let latestDate = new Date(latestActivity);
								let date = new Date(hour.date);
								if(date > latestDate){
									latestActivity = hour.date;
								}
							}
						});
					}
				});
				newCalcualted.latestActivity = latestActivity;
				
				await saveProjectAttributeChange(project, organization,clients, "calculated", newCalcualted, (entry: UndoStackEntry) => {
					AC.submitUndoStackEntry(entry);
				})
				project.calculated = newCalcualted;
			}
		})

		await monthTotals.forEach(async monthTotal => {
			let actualsForMonth = actuals.filter(actual => actual.year === monthTotal.year);
			let totalBillableHoursByRole: { [roleID: string]: number } = {};
			let totalNonBillableHoursByRole: { [roleID: string]: number } = {};
			actualsForMonth.forEach(actual => {
				let totalHoursByRole = totalNonBillableHoursByRole;
				if(actual.billable){
					totalHoursByRole = totalBillableHoursByRole;
				}
				if (!totalHoursByRole[actual.roleID]) {
					totalHoursByRole[actual.roleID] = 0;
				}
				totalHoursByRole[actual.roleID] += actual.hours.reduce((total, hourEntry) => total + (new Date(hourEntry.date).getMonth()+1 == monthTotal.month?hourEntry.hours:0), 0);
			})
			let newCalculated = { ...monthTotal.calculated };
			newCalculated.actualHoursByRole = [];
			newCalculated.totalHoursBillable = 0;
			newCalculated.totalHoursNonBillable = 0;
			for (var roleID in totalBillableHoursByRole) {
				newCalculated.actualHoursByRole.push({ roleID: roleID, hours: totalBillableHoursByRole[roleID]??0 + totalNonBillableHoursByRole[roleID]??0 });
				newCalculated.totalHoursBillable += totalBillableHoursByRole[roleID]??0;
				newCalculated.totalHoursNonBillable += totalNonBillableHoursByRole[roleID]??0;
			}
			await saveMonthTotalAttributeChange(monthTotal, "calculated", newCalculated, (entry: UndoStackEntry) => {
				AC.submitUndoStackEntry(entry);
			})
			monthTotal.calculated = newCalculated;

		})

		if(yearTotals == null){
			return;
		}
		await yearTotals.forEach(async yearTotal => {
			let monthTotalsForYear = monthTotals.filter(monthTotal => monthTotal.year === yearTotal.year);
			let totalBillableHoursByRole: { [roleID: string]: number } = {};
			let totalNonBillableHoursByRole: { [roleID: string]: number } = {};
			monthTotalsForYear.forEach(monthTotal => {
				let totalHoursByRole = totalNonBillableHoursByRole;
				if(monthTotal.calculated.totalHoursBillable > 0){
					totalHoursByRole = totalBillableHoursByRole;
				}
				monthTotal.calculated.actualHoursByRole.forEach(actualHoursByRole => {
					if (!totalHoursByRole[actualHoursByRole.roleID]) {
						totalHoursByRole[actualHoursByRole.roleID] = 0;
					}
					totalHoursByRole[actualHoursByRole.roleID] += actualHoursByRole.hours;
				})
			}	)
			let newCalculated = { ...yearTotal.calculated };
			newCalculated.actualHoursByRole = [];
			newCalculated.totalHoursBillable = 0;
			newCalculated.totalHoursNonBillable = 0;
			for (var roleID in totalBillableHoursByRole) {
				newCalculated.actualHoursByRole.push({ roleID: roleID, hours: totalBillableHoursByRole[roleID]??0 + totalNonBillableHoursByRole[roleID]??0 });
				newCalculated.totalHoursBillable += totalBillableHoursByRole[roleID]??0;
				newCalculated.totalHoursNonBillable += totalNonBillableHoursByRole[roleID]??0;
			}
			await saveYearTotalAttributeChange(yearTotal, "calculated", newCalculated, (entry: UndoStackEntry) => {
				AC.submitUndoStackEntry(entry);
			});
			yearTotal.calculated = newCalculated;

		});

	}
	
	useEffect(() =>{
		let tempProjectRoleActualHours:ProjectRoleActualHours[] = [];
		allActuals.forEach(actual =>{
			if(actual.personID === props.person.id ){
				tempProjectRoleActualHours.push(actual);
			}
		});
		setActuals(tempProjectRoleActualHours);
	},[allActuals,year])
	

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	const [tableOffsetTop, setTableOffsetTop] = useState<number>(0);
	const tableRef = React.createRef<HTMLDivElement>();
	const resize = () =>{
		if(tableRef.current == null){
			return;
		}
		setTableOffsetTop(tableRef.current!.offsetTop);
	}
	useEffect(() =>{
		window.addEventListener("resize", resize);
		resize();
		return () =>{
			window.removeEventListener("resize", resize);
		}
	},[])

	useEffect(() => {
		let table = tableRef.current!;
		if(table){
			const overrideWheel = (e: WheelEvent) => {
				if(table.scrollLeft === 0 && e.deltaX < 0){
					e.preventDefault();
				}
			};
			table.addEventListener("wheel", overrideWheel);
			return () => {
				table.removeEventListener("wheel", overrideWheel);
			};
		}
	}, [tableRef]);
	
  return (
		<>
	    <div className={`popup PopupWeeklyTimesheet`}>
				<div className="topRow">
					<h1>
						Timesheet for {props.person.name} 
						<div className="break"></div>
						<select value={year}
							className="yearSelect"
							onChange={(event) =>{
								setYear(parseInt(event.target.value))
							}}>
							{(() =>{
								let years = [];
								let currentYear = new Date().getFullYear();
								for(let i = currentYear; i > currentYear - 30; i--){
									years.push(<option value={i}>{i}</option>)
								}
								return years;
							})()}
						</select>

						<span onClick={() =>{
							let today = new Date();
							while(today.getDay() != 0){
								today.setDate(today.getDate() - 1);
							}
							let weekKey = shortDate(today);
							setSelectedWeekKey(weekKey);
							setShowingDailyInput(true);
						}} className="weekButton">Go To This Week</span>
					</h1>
				</div>

				{permissionsWrite.includes("Organization Timesheets") && permissionsWrite.includes("Organization") && (selectedActuals.length> 0 || selectedPTO) && (
					<div className="buttonNuke" onClick={() =>{
						if(window.confirm(`You are about to delete ${selectedActuals.length} project role actuals, are you sure?`)){
							if(window.confirm(`This is irreversible, are you super duper sure?`)){
								let nukeEmAll = async () =>{
									for(var i in selectedActuals){
										deleteDoc(doc(sharedFirestore, "ProjectRoleActualHours", selectedActuals[i].id));
									}
									if(selectedPTO){
										let newHoursTakenPTO: {
											date: string;
											hours: number;
										}[] = [];
										let newCalculated = { ...selectedPTO.calculated };
										newCalculated.totalHoursTakenPTO = 0;
										await saveTimeOffAttributeChange(selectedPTO, ["hoursTakenPTO", "calculated"], [newHoursTakenPTO, newCalculated], (entry: UndoStackEntry) => {
											AC.submitUndoStackEntry(entry);
										});
									}
									if(selectedSTO){
										let newHoursTakenSTO: {
											date: string;
											hours: number;
										}[] = [];
										let newCalculated = { ...selectedSTO.calculated };
										newCalculated.totalHoursTakenSTO = 0;
										await saveTimeOffAttributeChange(selectedSTO, ["hoursTakenSTO", "calculated"], [newHoursTakenSTO, newCalculated], (entry: UndoStackEntry) => {
											AC.submitUndoStackEntry(entry);
										});
									}
									setSelectedActuals([]);
									setSelectedPTO(null);
								}
								nukeEmAll();
							}
						}
					}}><FontAwesomeIcon icon={faRocket}></FontAwesomeIcon> Delete {selectedActuals.length} Project Role Actuals!{selectedPTO?" and PTO":""}{selectedSTO?" and STO":""} <FontAwesomeIcon icon={faExplosion}></FontAwesomeIcon></div>
				)}
				{!(showingDailyInput||showingClientPopup||showingProjectPopup||showingRolePopup) && (
					<div className='contentClip'
						style={{maxHeight:`calc(100vh - ${tableOffsetTop}px - 90px)`}}>
						<div className="content"
							ref={tableRef}
							style={{maxHeight:`calc(100vh - ${tableOffsetTop}px - 90px)`}}>
							<WeeklyTimesheetTable
								year={year}
								showCompleted={true}
                isReadOnly={permissionsWrite.indexOf("Organization Timesheets")==-1}
								goToDailyInput={(weekKey:string) =>{
									setSelectedWeekKey(weekKey);
									setShowingDailyInput(true);
								}}
								person={props.person} onCreateNewClient={(actual: ProjectRoleActualHours) => {
									setSelectedActual(actual);
									setShowingClientPopup(true);
								} } onCreateNewProject={(actual: ProjectRoleActualHours) => {
									setSelectedActual(actual);
									setShowingProjectPopup(true);
								} } onCreateNewRole={(actual: ProjectRoleActualHours) => {
									setSelectedActual(actual);
									setShowingRolePopup(true);
								} }
								
								onSelectedActualsChanged={(actuals: ProjectRoleActualHours[]) => {
									setSelectedActuals(actuals);
								}}						
								onSelectedPTOChanged={(timeOff:TimeOff|null) => {
									setSelectedPTO(timeOff);
								}}
								onSelectedSTOChanged={(timeOff:TimeOff|null) => {
									setSelectedSTO(timeOff);
								}}></WeeklyTimesheetTable>
						</div>
					</div>
				)}

				

				{/* <div className="tableCover"></div> */}
				<div className="floatingButtons">
					<div className="buttonSave"
						onClick={() =>{

							recalculateProjectMonthAndYearTotals();
							props.onDismiss();
							// saveNewClient().then((newClient:Client) =>{
							// 	props.onSave(newClient);
							// });
						}}>Done</div>
					</div>
			</div>
			{showingClientPopup && (
				<PopupAddClient onCancel={() =>{
					setShowingClientPopup(false);			
				}} onSave={(client) =>{
					setShowingClientPopup(false);			
				}}></PopupAddClient>
			)}
			{showingProjectPopup && (
				<PopupAddProject 
					disableMultiple={true}
					onCancel={() =>{
						setShowingProjectPopup(false);			
					}} onSave={(project) =>{
						saveProjectRoleActualHours(selectedActual!, "projectID", project.id,(entry:UndoStackEntry) =>{
							AC.submitUndoStackEntry(entry);
						})
						setShowingProjectPopup(false);			
					}}></PopupAddProject>
			)}
			{showingRolePopup && (
				<PopupAddRole onCancel={() =>{
					setShowingRolePopup(false);			
				}} onSave={(role) =>{
					saveProjectRoleActualHours(selectedActual!, "roleID", role.id,(entry) =>{
						AC.submitUndoStackEntry(entry);
					})
					setShowingRolePopup(false);			
				}}></PopupAddRole>
			)}
			{showingDailyInput && (
				<PopupDailyTimesheet 
          isReadOnly={permissionsWrite.indexOf("Organization Timesheets")==-1}
					showCompleted={true}
					onDismiss={function (): void {
						setShowingDailyInput(false);
					} } 
					weekKey={selectedweekKey} 
					person={props.person}></PopupDailyTimesheet>
			)}
		</>
  )
}