
import React, { useEffect, useState } from "react"
import "./OrganizationScreenRolesTable.css"
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import OrganizationScreenRolesRow, { OrganizationScreenRolesRowProps } from "../OrganizationScreenRolesRow/OrganizationScreenRolesRow";
import DraggableTableBody from "../../DraggableTableBody/DraggableTableBody";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { saveOrganizationAttributeChange } from "../../../Utils/SaveOrganizationFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";

interface OrganizationScreenRolesTableProps{
}




export default function OrganizationScreenRolesTable(props:OrganizationScreenRolesTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)

	const allRoles = useSelector((state:{roles:Role[]}) => state.roles)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles,
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);


	const [roles, setRoles] = useState<Role[]>([... allRoles]);

	useEffect(() =>{
		setRoles([... allRoles]);
		console.log("roles updated");
		setSavingRolesReorder(false);
	},[allRoles,organization.roleIDOrder]);

	const[savingRolesReorder, setSavingRolesReorder] = useState(false);
	
  return (
    <table 
			className={`OrganizationScreenRolesTable`}>
			<thead>
				<tr>
					<th></th>
					<th>Name</th>
					<th>Abbrev.</th>
					<th>AM</th>
					<th>PM</th>
					<th>RSM</th>
					<th>Admin</th>
					<th>Billable</th>
					<th>Billable<br/>Weight (%)</th>
					{/* <th>Use<br></br>Custom<br></br>Rate?</th>
					<th>Hourly Rate</th> */}
					<th>People</th>

				</tr>
			</thead>
			{savingRolesReorder == false && (
				<DraggableTableBody
					childClassName="OrganizationScreenRolesRow"
					orderedItems={organization.roleIDOrder}
					onOrderUpdated={(newOrder:string[]) =>{

					}}
					onOrderUpdateEnded={(newOrder:string[]) =>{
						const newRoles = [...roles];
						newRoles.sort((a,b) =>{
							return newOrder.indexOf(a.id) - newOrder.indexOf(b.id);
						});
						saveOrganizationAttributeChange(organization, "roleIDOrder", newOrder,(entry) =>{
							AC.submitUndoStackEntry(entry);
						});
						AC.setRoles(newRoles);
					}}
					keyForIndex={(index:number) =>{
						if(roles[index]){
							return `${roles[index].id}`;
						}
						return `role_${index}`;
					}}>
					{organization.roleIDOrder.map((roleID:string, index) =>{
						for(var i in roles){
							let role = roles[i];
							if(role.id === roleID){
								return <OrganizationScreenRolesRow key={`role_${role.id}`} role={role}></OrganizationScreenRolesRow>
							}
						}
						return <></>
					})}
				</DraggableTableBody>
				
			)}
    </table>
  )
}