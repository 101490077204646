
import React, { useEffect, useState } from "react"
import "./ReportSoldVSBilled.css"
import { useSelector } from "react-redux";
import { Organization } from "../../FirebaseModel/Organization";
import SoldVSBilledTable from "../../Components/SoldVSBilled/SoldVSBilledTable/SoldVSBilledTable";
interface ReportSoldVSBilledProps{

	filterStartMonth:string,
	filterEndMonth:string,
	filterStartYear:string,
	filterEndYear:string,
	weeksPerYear:string,
	onDismiss:()=>void;
}

export default function ReportSoldVSBilled(props:ReportSoldVSBilledProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
 

	const [tableOffsetTop, setTableOffsetTop] = useState<number>(0);
	const tableRef = React.createRef<HTMLDivElement>();
	const resize = () =>{
		setTableOffsetTop(tableRef.current!.offsetTop);
	}

	useEffect(() => {
		let table = tableRef.current!;
		if(table){
			const overrideWheel = (e: WheelEvent) => {
				if(table.scrollLeft === 0 && e.deltaX < 0){
					e.preventDefault();
				}
			};
			table.addEventListener("wheel", overrideWheel);
			return () => {
				table.removeEventListener("wheel", overrideWheel);
			};
		}
	}, [tableRef]);

	const [filterEndYear, setFilterEndYear] = useState<string>(props.filterEndYear);
	const [filterStartYear, setFilterStartYear] = useState<string>(props.filterStartYear);
	const [filterStartMonth, setFilterStartMonth] = useState<string>(props.filterStartMonth);
	const [filterEndMonth, setFilterEndMonth] = useState<string>(props.filterEndMonth);
	const [weeksPerYear, setWeeksPerYear] = useState<string>(props.weeksPerYear);


	useEffect(() =>{
		window.addEventListener("resize", resize);
		resize();
		return () =>{
			window.removeEventListener("resize", resize);
		}
	},[])

  return (
		<>
	    <div className={`report ReportSoldVSBilled`}>
				<h1>Sold vs Billed Report ({filterStartMonth}/{filterStartYear} - {filterEndMonth}/{filterEndYear})</h1>

			<div className="filters">
				<h3>Parameters</h3>
				<div className="row">
					<div className="col">
						<div className="label">Start Month</div>
						<select value={filterStartMonth}
							onChange={(event) =>{
								setFilterStartMonth(event.target.value)
								if(filterStartYear == ""){
									setFilterStartYear(new Date().getFullYear().toString());
								}
							}}>
							<option value="1">January</option>
							<option value="2">February</option>
							<option value="3">March</option>
							<option value="4">April</option>
							<option value="5">May</option>
							<option value="6">June</option>
							<option value="7">July</option>
							<option value="8">August</option>
							<option value="9">September</option>
							<option value="10">October</option>
							<option value="11">November</option>
							<option value="12">December</option>
						</select>
					</div>
					<div className="col">
						<div className="label">Start Year</div>
						<select value={filterStartYear}
							onChange={(event) =>{
								setFilterStartYear(event.target.value)
							}}>
							{(() =>{
								let years = [];
								let currentYear = new Date().getFullYear();
								for(let i = currentYear; i > currentYear - 30; i--){
									years.push(<option key={`year_${i}`} value={i}>{i}</option>)
								}
								return years;
							})()}
						</select>
					</div>
					<div className="col">
						<div className="label">End Month</div>
						<select value={filterEndMonth}
							onChange={(event) =>{
								setFilterEndMonth(event.target.value)
								if(filterEndYear == ""){
									setFilterEndYear(new Date().getFullYear().toString());
								}
							}}>
							<option value="1">January</option>
							<option value="2">February</option>
							<option value="3">March</option>
							<option value="4">April</option>
							<option value="5">May</option>
							<option value="6">June</option>
							<option value="7">July</option>
							<option value="8">August</option>
							<option value="9">September</option>
							<option value="10">October</option>
							<option value="11">November</option>
							<option value="12">December</option>
						</select>
					</div>
					<div className="col">
						<div className="label">End Year</div>
						<select value={filterEndYear}
							onChange={(event) =>{
								setFilterEndYear(event.target.value)

							}}>
							{(() =>{
								let years = [];
								let currentYear = new Date().getFullYear();
								for(let i = currentYear; i > currentYear - 30; i--){
									years.push(<option key={`year_${i}`} value={i}>{i}</option>)
								}
								return years;
							})()}
						</select>
					</div>
					<div className="col">
						<div className="label">Work Weeks</div>
						<select value={weeksPerYear}
							onChange={(event) =>{
								setWeeksPerYear(event.target.value)
							}}>
							<option value={52}>{52}</option>
							<option value={50}>{50}</option>
							<option value={48}>{48}</option>
							<option value={46}>{46}</option>
						</select>
					</div>
				</div>
			</div>

				<div className="content"
					ref={tableRef}
					style={{maxHeight:`calc(100vh - ${tableOffsetTop}px)`}}>

					<SoldVSBilledTable 
						weeksPerYear={parseInt(weeksPerYear)} 
						startYear={parseInt(filterStartYear)} 
						startMonth={parseInt(filterStartMonth)} 
						endYear={parseInt(filterEndYear)} 
						endMonth={parseInt(filterEndMonth)}
						listProjects={false}></SoldVSBilledTable>

				</div>
				<div className="floatingButtons">
					<div className="buttonSave"
						onClick={() =>{
							props.onDismiss();
						}}>Done</div>
					</div>
			</div>
		</>
  )
}