
import React, { useEffect, useState } from "react"
import { addDoc, collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./PopupAddDepartment.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import { Person } from "../../FirebaseModel/Person";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import { forceDecimal, prettyCurrency } from "../../Utils/formatting";
import { saveOrganizationAttributeChange } from "../../Utils/SaveOrganizationFunctions";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { UndoStackEntry, UndoStackEntryType, UndoStackEntryObjectType } from "../../Utils/UndoStackEntry";
import { Department } from "../../FirebaseModel/Department";
interface PopupAddDepartmentProps{
	onCancel:()=>void;
	onSave:(department:Department) =>void;
	allowMultiple?:boolean;
}

export default function PopupAddDepartment(props:PopupAddDepartmentProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const departments = useSelector((state:{departments:Department[]}) => state.departments)

	const saveNewDepartment = () =>{
		return new Promise((resolve:(department:Department)=>void,reject) =>{
			let doc = {
				organizationID: organization.id,
				name: name,
				abbreviation: abbreviation,
				overrideHourlyRate: overrideHourlyRate,
				useOverrideHourlyRate: useOverrideHourlyRate,
				persons: []
			}
			addDoc(collection(sharedFirestore, "Department"),doc).then((docRef) =>{
				let newDepartment:Department = {id:docRef.id, ...doc} as Department;
				let undoEntry:UndoStackEntry = {
					type: UndoStackEntryType.batchStart,
					objectType: UndoStackEntryObjectType.department,
					objectID: newDepartment.id,
					objectBeforeState: undefined,
					objectAfterState: undefined,
					description: "Create Department"
				}
				AC.submitUndoStackEntry(undoEntry);

				undoEntry = {
					type: UndoStackEntryType.create,
					objectType: UndoStackEntryObjectType.department,
					objectID: newDepartment.id,
					objectBeforeState: undefined,
					objectAfterState: {...newDepartment},
					description: "Create Department"
				}
				AC.submitUndoStackEntry(undoEntry);
				
				let newDepartmentIDOrder = [...organization.departmentIDOrder];
				newDepartmentIDOrder.push(docRef.id);
				saveOrganizationAttributeChange(organization,"departmentIDOrder",newDepartmentIDOrder ,(entry) =>{
					AC.submitUndoStackEntry(entry);
				})
				.then(() =>{
					let undoEntry:UndoStackEntry = {
						type: UndoStackEntryType.batchEnd,
						objectType: UndoStackEntryObjectType.department,
						objectID: newDepartment.id,
						objectBeforeState: undefined,
						objectAfterState: {...newDepartment},
						description: "Create Department"
					}
					AC.submitUndoStackEntry(undoEntry);
					resolve(newDepartment);
				});
			})
		})
	}

	const clearForm = () =>{
		setName("");
		setAbbreviation("");
		setUseOverrideHourlyRate(false);
		setOverrideHourlyRate(organization.hourlyRate);
		setClearingForm(true);
		setTimeout(() =>{setClearingForm(false)}, 100);
	}


	const [name, setName] = useState("");
	const [abbreviation, setAbbreviation] = useState("");
	const [useOverrideHourlyRate, setUseOverrideHourlyRate] = useState(false);
	const [overrideHourlyRate, setOverrideHourlyRate] = useState(organization.hourlyRate);

	const [clearingForm, setClearingForm] = useState(false);
	return (
		<div className={`popup PopupAddDepartment`}>
			<h1>New Department</h1>
			{clearingForm == false && (
				<div className="content">

					<div className="topArea">

					<div className="row">
						<div className="col">
							<div className="label">Department Name</div>
							<input className={`name`} 
								defaultValue={name}
								onBlur={(event) =>{
									setName(event.target.value)
								}}></input>
						</div>
						<div className="col">
							<div className="label">Abbreviation</div>
							<input className={`abbreviation`} 
								defaultValue={abbreviation}
								onBlur={(event) =>{
									setAbbreviation(event.target.value)
								}}></input>
						</div>
						</div>
					</div>
				</div>
			)}

			<div className="floatingButtons">
				<div className="buttonCancel"
					onClick={() =>{
						props.onCancel();
					}}>Cancel</div>

				<DropdownButton 
						items={["Save & Close","Save & Add New"]} 
						selectedIndex={0} 
						executeFunction={(functionIndex: number) =>{
							switch(functionIndex){
								case 0:
									saveNewDepartment().then((newDepartment:Department) =>{
										props.onSave(newDepartment);
									});
									return;
								case 1:
									saveNewDepartment().then((newDepartment:Department) =>{
										clearForm();
									});
									return;
							}
					} } ></DropdownButton>
		</div>
	</div>
)
}