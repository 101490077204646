

import React, { ReactNode, useEffect, useRef, useState } from "react"
import "./WeeklyTimesheetTable.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { Role } from "../../../FirebaseModel/Role";
import { Person, personRolesDescription } from "../../../FirebaseModel/Person";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { deletePerson, savePersonAttributeChange } from "../../../Utils/SavePersonFunctions";
import WeeklyTimesheetRow, { WeeklyTimesheetRowType } from "../WeeklyTimesheetRow/WeeklyTimesheetRow";
import { QuerySnapshot, DocumentData, query, where, onSnapshot, collection, addDoc } from "firebase/firestore";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import WeeklyTimesheetHeader, { WeeklyTimesheetHeaderType } from "../WeeklyTimesheetHeader/WeeklyTimesheetHeader";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { resolve } from "dns";
import { saveOrganizationAttributeChange } from "../../../Utils/SaveOrganizationFunctions";
import DraggableTableBody from "../../DraggableTableBody/DraggableTableBody";
import OrganizationScreenRolesRow from "../../OrganizationScreen/OrganizationScreenRolesRow/OrganizationScreenRolesRow";
import { shortDate } from "../../../Utils/formatting";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { UndoStackEntryType, UndoStackEntryObjectType } from "../../../Utils/UndoStackEntry";
import DownloadableTable from "../../DownloadableTable/DownloadableTable";
interface WeeklyTimesheetTableProps {
  person: Person,
  year: number,
  weekKey?: string,
  isReadOnly?: boolean,
  showCompleted?: boolean,
  onCreateNewClient: (actual: ProjectRoleActualHours) => void;
  onCreateNewProject: (actual: ProjectRoleActualHours) => void;
  onCreateNewRole: (actual: ProjectRoleActualHours) => void;
  onSelectedActualsChanged: (actuals: ProjectRoleActualHours[]) => void;
  onSelectedPTOChanged: (timeOff: TimeOff | null) => void;
  onSelectedSTOChanged: (timeOff: TimeOff | null) => void;

  goToDailyInput: (weekKey: string) => void;
}

let creatingTimeOff = false;

export default function WeeklyTimesheetTable(props: WeeklyTimesheetTableProps) {

  const organizationID = useSelector((state: { organizationID: string }) => state.organizationID)
  const organization = useSelector((state: { organization: Organization }) => state.organization)
  const allActuals = useSelector((state: { actuals: ProjectRoleActualHours[] }) => state.actuals)
  const allPersons = useSelector((state: { persons: Person[] }) => state.persons)
  const roles = useSelector((state: { roles: Role[] }) => state.roles)
  const timeOffList = useSelector((state: { timeOffList: TimeOff[] }) => state.timeOffList)
  const permissionsWrite = useSelector((state: { permissionsWrite: string[] }) => state.permissionsWrite)


  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry: ActionCreators.submitUndoStackEntry
  }, dispatch);

  const [actuals, setActuals] = useState<ProjectRoleActualHours[]>([]);
  const [weekKeys, setWeekKeys] = useState<string[]>([]);

  const [timeOff, setTimeOff] = useState<TimeOff>();

  const [selectedActuals, setSelectedActuals] = useState<ProjectRoleActualHours[]>([]);
  const [selectedPTO, setSelectedPTO] = useState<TimeOff | null>(null);
  const [selectedSTO, setSelectedSTO] = useState<TimeOff | null>(null);

  useEffect(() => {

    let tempProjectRoleActualHours: ProjectRoleActualHours[] = [];
    allActuals.forEach((actual) => {
      if (actual.personID != props.person.id || actual.year != props.year) {
        return;
      }
      tempProjectRoleActualHours.push(actual);
    })
    tempProjectRoleActualHours.sort((a, b) => {
      if (props.person.actualsOrder![props.year] == null) {
        props.person.actualsOrder![props.year] = [];
      }
      if (props.person.actualsOrder![props.year].indexOf(a.id) != -1 && props.person.actualsOrder![props.year].indexOf(b.id) != -1) {
        return props.person.actualsOrder![props.year].indexOf(a.id) - props.person.actualsOrder![props.year].indexOf(b.id) || 0;
      }

      if (a.order < b.order) {
        return -1;
      } else if (a.order > b.order) {
        return 1;
      } else {
        return 0;
      }
    })
    setActuals(tempProjectRoleActualHours);

  }, [allActuals, props.year]);

  useEffect(() => {

    let weekKeys = []
    if (props.weekKey != null) {
      let startDate = new Date(props.weekKey);
      for (let i = 0; i < 7; i++) {
        weekKeys.push(shortDate(startDate));//`${startDate.getMonth()+1}/${startDate.getDate()}/${startDate.getFullYear()}`);
        startDate.setDate(startDate.getDate() + 1);
      }
    } else {
      let startDate = new Date(`01/01/${props.year}`);
      while (startDate.getDay() != 0) {
        startDate.setDate(startDate.getDate() - 1);
      }
      let endDate = new Date(startDate.getTime());
      endDate.setDate(startDate.getDate() + 7);
      let today = new Date();
      while (startDate < today && (startDate.getFullYear() == props.year || endDate.getFullYear() == props.year)) {
        weekKeys.push(shortDate(startDate));//`${startDate.getMonth()+1}/${startDate.getDate()}/${startDate.getFullYear()}`)
        startDate = new Date(endDate.getTime());
        endDate.setDate(endDate.getDate() + 7);
      }
    }

    setWeekKeys(weekKeys);


  }, [props.year, props.weekKey])

  useEffect(() => {
    let timeOff = timeOffList.find(timeOff => timeOff.personID == props.person.id && timeOff.year == props.year);
    if (timeOff == null) {
      if (creatingTimeOff) {
        return
      }
      creatingTimeOff = true;

      let allottedPTO = props.person.PTOPerYear;
      let allottedSTO = props.person.STOPerYear;
      if (organization.managesPTOAtOrganizationLevel) {
        allottedPTO = organization.PTOPerYear;
        allottedSTO = organization.STOPerYear;
      }


      let timeOffDocData = {
        organizationID: organizationID,
        personID: props.person.id,
        year: props.year,
        hoursTakenSTO: [],
        hoursTakenPTO: [],
        allottedPTO: allottedPTO,
        allottedSTO: allottedSTO,
        calculated: {
          totalHoursTakenPTO: 0,
          totalHoursTakenSTO: 0,
        }
      };
      addDoc(collection(sharedFirestore, "TimeOff"), timeOffDocData).then((docref) => {
        let timeOff = { ...timeOffDocData, id: docref.id } as TimeOff;
        setTimeOff(timeOff);

        AC.submitUndoStackEntry({
          type: UndoStackEntryType.create,
          objectType: UndoStackEntryObjectType.timeOff,
          objectID: timeOff.id,
          objectBeforeState: undefined,
          objectAfterState: { ...timeOff },
          description: 'Create Time Off'
        })
        creatingTimeOff = false;
      })
    } else {
      setTimeOff(timeOff);
    }
  }, [timeOffList, props.person, props.year, selectedPTO, selectedSTO])



  return (
    <div className={`WeeklyTimesheetTableContainer`}>
      {(() => {
        let billableTotal = 0;
        let billableTotalByWeek: { [key: string]: number } = {};
        let timeOffTotal = 0;
        let timeOffTotalByWeek: { [key: string]: number } = {};
        let internalTotal = 0;
        let internalTotalByWeek: { [key: string]: number } = {};
        let grandTotal = 0;
        let grandTotalByWeek: { [key: string]: number } = {};

        let billableWeeklyAverage = 0;
        let internalWeeklyAverage = 0;
        let grandWeeklyAverage = 0;
        let timeOffWeeklyAverage = 0;

        if (actuals.length > 0) {
          for (var key in weekKeys) {
            billableTotalByWeek[weekKeys[key]] = 0;
            internalTotalByWeek[weekKeys[key]] = 0;
            grandTotalByWeek[weekKeys[key]] = 0;
            timeOffTotalByWeek[weekKeys[key]] = 0;
          }

          let startDate = new Date(weekKeys[0]);
          let endDate = new Date(weekKeys[weekKeys.length - 1]);
          endDate.setDate(endDate.getDate() + 7);

          if (props.weekKey) {
            endDate = new Date(weekKeys[weekKeys.length - 1]);
          }

          let startTime = startDate.getTime();
          let endTime = endDate.getTime();



          for (var a in actuals) {
            let actual = actuals[a];
            for (var i in actual.hours) {
              let entry = actual.hours[i];
              if (entry.hours == null || isNaN(entry.hours) || isFinite(entry.hours) == false) {
                continue;
              }
              let entryTime = new Date(entry.date).getTime();
              if (entryTime < startTime || entryTime > endTime) {
                continue;
              }
              let sunday = new Date(entry.date);
              sunday.setDate(sunday.getDate() - sunday.getDay());
              let key = entry.date;
              if (props.weekKey == null) {
                key = shortDate(sunday);
              }
              grandTotal += entry.hours;
              grandTotalByWeek[key] += entry.hours;
              if (actual.billable == false) {
                internalTotal += entry.hours;
                internalTotalByWeek[key] += entry.hours;
              } else {
                billableTotal += entry.hours;
                billableTotalByWeek[key] += entry.hours;
              }
            }
          }

          if (timeOff) {
            for (var i in timeOff.hoursTakenPTO) {
              let entry = timeOff.hoursTakenPTO[i];
              let entryTime = new Date(entry.date).getTime();
              if (entryTime < startTime || entryTime > endTime) {
                continue;
              }

              let sunday = new Date(entry.date);
              sunday.setDate(sunday.getDate() - sunday.getDay());
              let key = entry.date;
              if (props.weekKey == null) {
                key = shortDate(sunday);
              }

              timeOffTotal += entry.hours;
              timeOffTotalByWeek[key] += entry.hours;
              internalTotal += entry.hours;
              internalTotalByWeek[key] += entry.hours;
              grandTotal += entry.hours;
              grandTotalByWeek[key] += entry.hours;
            }
            for (var i in timeOff.hoursTakenSTO) {
              let entry = timeOff.hoursTakenSTO[i];
              let entryTime = new Date(entry.date).getTime();
              if (entryTime < startTime || entryTime > endTime) {
                continue;
              }

              let sunday = new Date(entry.date);
              sunday.setDate(sunday.getDate() - sunday.getDay());
              let key = entry.date;
              if (props.weekKey == null) {
                key = shortDate(sunday);
              }
              timeOffTotal += entry.hours;
              timeOffTotalByWeek[key] += entry.hours;
              internalTotal += entry.hours;
              internalTotalByWeek[key] += entry.hours;
              grandTotal += entry.hours;
              grandTotalByWeek[key] += entry.hours;
            }
          }


          if (props.weekKey) {
            billableWeeklyAverage = billableTotal / 7;
            internalWeeklyAverage = internalTotal / 7;
            grandWeeklyAverage = grandTotal / 7;
            timeOffWeeklyAverage = timeOffTotal / 7;
          } else {
            billableWeeklyAverage = billableTotal / weekKeys.length;
            internalWeeklyAverage = internalTotal / weekKeys.length;
            grandWeeklyAverage = grandTotal / weekKeys.length;
            timeOffWeeklyAverage = timeOffTotal / weekKeys.length;
          }
        }


        return (
          <>
            <table className={`WeeklyTimesheetTable`}>
              <thead>
                <WeeklyTimesheetHeader
                  type={WeeklyTimesheetHeaderType.label}
                  year={props.year}
                  weekKey={props.weekKey ?? undefined}
                  goToDailyInput={(weekKey: string) => {
                    props.goToDailyInput(weekKey);
                  }}
                  weekKeys={weekKeys}></WeeklyTimesheetHeader>

                <WeeklyTimesheetHeader
                  type={WeeklyTimesheetHeaderType.billableTotal}
                  year={props.year}
                  weeklyAverage={billableWeeklyAverage}
                  total={billableTotal}
                  totalByWeek={billableTotalByWeek}
                  weekKeys={weekKeys}></WeeklyTimesheetHeader>

                <WeeklyTimesheetHeader
                  type={WeeklyTimesheetHeaderType.internalTotal}
                  year={props.year}
                  weeklyAverage={internalWeeklyAverage}
                  total={internalTotal}
                  totalByWeek={internalTotalByWeek}
                  weekKeys={weekKeys}></WeeklyTimesheetHeader>

                <WeeklyTimesheetHeader
                  allSelected={selectedActuals.length == actuals.length}
                  type={WeeklyTimesheetHeaderType.grandTotal}
                  year={props.year}
                  isReadOnly={props.isReadOnly}
                  weeklyAverage={grandWeeklyAverage}
                  total={grandTotal}
                  totalByWeek={grandTotalByWeek}
                  weekKeys={weekKeys}
                  onSelectAll={(allSelected) => {
                    let selectedActuals = [...actuals]
                    if (!allSelected) {
                      selectedActuals = [];
                    }
                    setSelectedActuals(selectedActuals);
                    props.onSelectedActualsChanged(selectedActuals);
                    if (allSelected) {
                      setSelectedPTO(timeOff!);
                      setSelectedSTO(timeOff!);
                      props.onSelectedPTOChanged(timeOff!);
                      props.onSelectedSTOChanged(timeOff!);
                    } else {
                      setSelectedPTO(null);
                      props.onSelectedPTOChanged(null);
                      props.onSelectedSTOChanged(null);
                    }
                  }}></WeeklyTimesheetHeader>

                <WeeklyTimesheetHeader
                  type={WeeklyTimesheetHeaderType.label2}
                  year={props.year}
                  weekKey={props.weekKey ?? undefined}
                  goToDailyInput={(weekKey: string) => {
                    props.goToDailyInput(weekKey);
                  }}
                  weekKeys={weekKeys}></WeeklyTimesheetHeader>

              </thead>
              <DraggableTableBody
                childClassName="WeeklyTimesheetRow"
                orderedItems={props.person.actualsOrder![props.year]}
                onOrderUpdated={(newOrder: string[]) => {

                }}
                onOrderUpdateEnded={(newOrder: string[]) => {
                  let map = { ...props.person.actualsOrder };
                  map[props.year] = newOrder;
                  savePersonAttributeChange(props.person, "actualsOrder", map, (entry) => {
                    AC.submitUndoStackEntry(entry);
                  });
                }}
                keyForIndex={(index: number) => {
                  return `${actuals[index].id}`;
                }}>
                {actuals.map((actual: ProjectRoleActualHours) => {
                  return <WeeklyTimesheetRow
                    key={`actuals_${actual.id}`}
                    isReadOnly={props.isReadOnly}
                    selected={selectedActuals.indexOf(actual) != -1}
                    year={props.year}
                    projectRoleActualHours={actual}
                    weekKeys={weekKeys}
                    person={props.person}
                    weekKey={props.weekKey ?? undefined}
                    showCompleted={props.showCompleted}
                    type={WeeklyTimesheetRowType.Project}
                    goToDailyInput={(weekKey: string) => {
                      props.goToDailyInput(weekKey);
                    }}
                    onCreateNewClient={(actual: ProjectRoleActualHours) => {
                      props.onCreateNewClient(actual);
                    }}
                    onCreateNewProject={(actual: ProjectRoleActualHours) => {
                      props.onCreateNewProject(actual);
                    }}
                    onCreateNewRole={(actual: ProjectRoleActualHours) => {
                      props.onCreateNewRole(actual);
                    }}
                    onSelectedActualChange={(actual: ProjectRoleActualHours, selected: boolean) => {
                      let newSelectedActuals = [...selectedActuals];
                      if (selected) {
                        newSelectedActuals.push(actual);
                      } else {
                        newSelectedActuals.splice(newSelectedActuals.indexOf(actual), 1);
                      }
                      setSelectedActuals(newSelectedActuals);
                      props.onSelectedActualsChanged(newSelectedActuals);
                    }}></WeeklyTimesheetRow>
                })}
              </DraggableTableBody>
            </table>
            <table className={`WeeklyTimesheetTable PTO`}>
              <tbody>
                <tr className="WeeklyTimesheetRow add">
                  <WeeklyTimesheetRow
                    selected={false}
                    isReadOnly={props.isReadOnly}
                    year={props.year}
                    person={props.person}
                    weekKeys={weekKeys}
                    weekKey={props.weekKey ?? undefined}
                    nextOrder={actuals.length}
                    goToDailyInput={(weekKey: string) => { }}
                    type={WeeklyTimesheetRowType.AddRow}></WeeklyTimesheetRow>
                </tr>
                <WeeklyTimesheetHeader
                  className="PTO"
                  type={WeeklyTimesheetHeaderType.label}
                  year={props.year}
                  weekKey={props.weekKey ?? undefined}
                  goToDailyInput={(weekKey: string) => {
                    props.goToDailyInput(weekKey);
                  }}
                  weekKeys={weekKeys}></WeeklyTimesheetHeader>

                <WeeklyTimesheetHeader
                  type={WeeklyTimesheetHeaderType.timeOffTotal}
                  year={props.year}
                  weeklyAverage={timeOffWeeklyAverage}
                  total={timeOffTotal}
                  totalByWeek={timeOffTotalByWeek}
                  weekKey={props.weekKey ?? undefined}
                  weekKeys={weekKeys}></WeeklyTimesheetHeader>

                <tr className="WeeklyTimesheetRow PTO">
                  <WeeklyTimesheetRow
                    selected={selectedPTO == timeOff}
                    isReadOnly={props.isReadOnly}
                    year={props.year}
                    timeOff={timeOff}
                    weekKeys={weekKeys}
                    weekKey={props.weekKey ?? undefined}
                    goToDailyInput={(weekKey: string) => {
                      props.goToDailyInput(weekKey);
                    }}
                    person={props.person}
                    type={WeeklyTimesheetRowType.PTO}
                    onSelectedPTOChange={(timeOff: TimeOff, selected: boolean) => {
                      if (selected) {
                        setSelectedPTO(timeOff);
                      } else {
                        setSelectedPTO(null);
                      }
                      props.onSelectedPTOChanged(timeOff);
                    }}></WeeklyTimesheetRow>
                </tr>
                <tr className="WeeklyTimesheetRow STO">
                  <WeeklyTimesheetRow
                    selected={selectedSTO == timeOff}
                    isReadOnly={props.isReadOnly}
                    year={props.year}
                    timeOff={timeOff}
                    weekKeys={weekKeys}
                    person={props.person}
                    weekKey={props.weekKey ?? undefined}
                    goToDailyInput={(weekKey: string) => {
                      props.goToDailyInput(weekKey);
                    }}
                    type={WeeklyTimesheetRowType.STO}
                    onSelectedSTOChange={(timeOff: TimeOff, selected: boolean) => {
                      if (selected) {
                        setSelectedSTO(timeOff);
                      } else {
                        setSelectedSTO(null);
                      }
                      props.onSelectedSTOChanged(timeOff);
                    }}></WeeklyTimesheetRow>
                </tr>
              </tbody>
            </table>
          </>
        )
      })()}
    </div>
  )
}