
import React, { useEffect, useState } from "react"
import { addDoc, collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./PopupAddClient.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import { prettyNum, forceNumeric, prettyCurrency, forceDecimal } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import ClientsContactTable from "../../Components/ClientsScreen/ClientsContactTable/ClientsContactTable";
import ClientProjectsTable from "../../Components/ClientsScreen/ClientProjectsTable/ClientProjectsTable";
import PopupAddProject from "../PopupAddProject/PopupAddProject";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { UndoStackEntry, UndoStackEntryType, UndoStackEntryObjectType } from "../../Utils/UndoStackEntry";
import { ClientContact } from "../../FirebaseModel/ClientContact";
import { deleteClientContact } from "../../Utils/SaveClientContactFunctions";
interface PopupAddClientProps{
	client?:Client;
	onCancel:()=>void;
	onSave:(client:Client) =>void;
	allowMultiple?:boolean;
}


export default function PopupAddClient(props:PopupAddClientProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

  const organization = useSelector((state:{organization:Organization}) => state.organization)

	const [clientName, setClientName] = useState(props.client?props.client!.name??"":"");
	const [useOverrideHourlyRate, setUseOverrideHourlyRate] = useState(props.client?props.client!.useOverrideHourlyRate:false);
	const [overrideHourlyRate, setOverrideHourlyRate] = useState(props.client?props.client!.overrideHourlyRate:organization.hourlyRate);
	const allClientContacts = useSelector((state:{clientContacts:ClientContact[]}) => state.clientContacts)

	const [selectedProject, setSelectedProject] = useState<Project|undefined>(undefined);
	const [showingProjectPopup, setShowingProjectPopup] = useState(false);

	const saveNewClient = () =>{
		return new Promise((resolve:(client:Client)=>void,reject) =>{

			let docData = {
				organizationID:organization.id,
				name:clientName,
				overrideHourlyRate:overrideHourlyRate,
				useOverrideHourlyRate:useOverrideHourlyRate,
			}
			addDoc(collection(sharedFirestore,"Client"),docData).then((docref) =>{
				let undoEntry:UndoStackEntry = {
					type: UndoStackEntryType.batchStart,
					objectType: UndoStackEntryObjectType.client,
					objectID: docref.id,
					objectBeforeState: undefined,
					objectAfterState: undefined,
					description: "Create Client"
				}
				AC.submitUndoStackEntry(undoEntry);


				let client = {... docData, id:docref.id} as Client
				undoEntry = {
					type: UndoStackEntryType.create,
					objectType: UndoStackEntryObjectType.client,
					objectID: docref.id,
					objectBeforeState: undefined,
					objectAfterState: {...client},
					description: "Create Client"
				}
				AC.submitUndoStackEntry(undoEntry);

				for(let i in clientContacts){
					let clientContact = clientContacts[i];
					clientContact.clientID = client.id;
					addDoc(collection(sharedFirestore,"ClientContact"),clientContact).then((docref) =>{
						let contact = {... clientContact, id:docref.id} as ClientContact
						clientContact.id = docref.id;
						let undoEntry:UndoStackEntry = {
							type: UndoStackEntryType.create,
							objectType: UndoStackEntryObjectType.clientContact,
							objectID: docref.id,
							objectBeforeState: undefined,
							objectAfterState: {...contact},
							description: "Create Client Contact"
						}
						AC.submitUndoStackEntry(undoEntry);
					})
				}

				undoEntry = {
					type: UndoStackEntryType.batchEnd,
					objectType: UndoStackEntryObjectType.client,
					objectID: docref.id,
					objectBeforeState: undefined,
					objectAfterState: {...client},
					description: "Create Client"
				}
				AC.submitUndoStackEntry(undoEntry);
				resolve(client);
			})
		})
	}

	const updateExistingClient = () =>{
		return new Promise((resolve:(client:Client)=>void,reject) =>{
			const docRef = doc(sharedFirestore, "Client", props.client!.id);

			let undoEntry:UndoStackEntry = {
				type: UndoStackEntryType.batchStart,
				objectType: UndoStackEntryObjectType.client,
				objectID: props.client!.id,
				objectBeforeState: {...props.client!},
				objectAfterState: undefined,
				description: "Update Client"
			}
			AC.submitUndoStackEntry(undoEntry);

			let docData = {
				organizationID:organization.id,
				name:clientName,
				overrideHourlyRate:overrideHourlyRate,
				useOverrideHourlyRate:useOverrideHourlyRate,
			}

			for(var i in docData){
				(props.client! as any)[i] = (docData as any)[i];
			}

			let currentClientContacts = allClientContacts.filter((clientContact:ClientContact) =>{
				return clientContact.clientID == props.client!.id;
			});

			for(let i in currentClientContacts){
				let currentClientContact = currentClientContacts[i];
				let found = false;
				for(let j in clientContacts){
					let clientContact = clientContacts[j];
					if(clientContact.id == currentClientContact.id){
						found = true;
						break;
					}
				}
				if(found == false){
					deleteClientContact(currentClientContact,(entry) =>{
						AC.submitUndoStackEntry(entry);
					});
				}
			}

			for(let i in clientContacts){
				let clientContact = clientContacts[i];
				if(clientContact.id == ""){
					let clientContactDocRef = {
						organizationID:organization.id,
						clientID:props.client!.id,
						name:clientContact.name,
						title:clientContact.title,
						email:clientContact.email,
						phone:clientContact.phone,
					}
					addDoc(collection(sharedFirestore,"ClientContact"),clientContactDocRef).then((docref) =>{
						let contact = {... clientContact, id:docref.id} as ClientContact
						clientContact.id = docref.id;
						let undoEntry:UndoStackEntry = {
							type: UndoStackEntryType.create,
							objectType: UndoStackEntryObjectType.clientContact,
							objectID: docref.id,
							objectBeforeState: undefined,
							objectAfterState: {...contact},
							description: "Create Client Contact"
						}
						AC.submitUndoStackEntry(undoEntry);
					})
				}else{
					let currentClientContact = currentClientContacts.find((currentClientContact:ClientContact) =>{
						return currentClientContact.id == clientContact.id;
					})!;
					let docRef = {
						id:clientContact.id,
						organizationID:clientContact.organizationID,
						clientID:clientContact.clientID,
						name:clientContact.name,
						title:clientContact.title,
						email:clientContact.email,
						phone:clientContact.phone,
					}
					updateDoc(doc(sharedFirestore, "ClientContact", clientContact.id), docRef).then(() =>{
						let undoEntry:UndoStackEntry = {
							type: UndoStackEntryType.update,
							objectType: UndoStackEntryObjectType.clientContact,
							objectID: clientContact.id,
							objectBeforeState: {...currentClientContact},
							objectAfterState: {...docRef},
							description: "Update Client Contact"
						}
						AC.submitUndoStackEntry(undoEntry);
					})
				}
			}



			updateDoc(docRef, docData).then(() =>{

				let client = {... docData, id:props.client!.id} as Client
				let undoEntry:UndoStackEntry = {
					type: UndoStackEntryType.update,
					objectType: UndoStackEntryObjectType.client,
					objectID: props.client!.id,
					objectBeforeState: {...props.client!},
					objectAfterState: {...client},
					description: "Update Client"
				}
				AC.submitUndoStackEntry(undoEntry);
				
				undoEntry = {
					type: UndoStackEntryType.batchEnd,
					objectType: UndoStackEntryObjectType.client,
					objectID: props.client!.id,
					objectBeforeState: {...props.client!},
					objectAfterState: {...client},
					description: "Update Client"
				}
				AC.submitUndoStackEntry(undoEntry);
				resolve(client);
			})
		})
	}


	const clearForm = () =>{
		setClientName("");
		setUseOverrideHourlyRate(false);
		setOverrideHourlyRate(organization.hourlyRate);

		setClearingForm(true);
		setTimeout(() =>{setClearingForm(false)}, 100);
	}

	const [clearingForm, setClearingForm] = useState(false);

	const [clientContacts, setClientContacts] = useState<ClientContact[]>([]);
	useEffect(() =>{
		if(props.client){
			let currentClientContacts = allClientContacts.filter((clientContact:ClientContact) =>{
				return clientContact.clientID == props.client!.id;
			});
			setClientContacts(currentClientContacts);
		}
	},[props.client,allClientContacts]);


  return (
		<>
	    <div className={`popup PopupAddClient`}>
				<h1>New Client</h1>
				{clearingForm == false && (
					<div className="content">

						<div className="topArea">

							<div className="row">
								<div className="col">
									<div className="label">Client Name</div>
									<input className={`clientName`} 
										defaultValue={clientName}
										onBlur={(event) =>{
											setClientName(event.target.value)
										}}></input>
								</div>
								<div className="col">
									<div className="row">
										<div className="label">Custom Hourly Rate?</div>
										<input type='checkbox' checked={useOverrideHourlyRate} onChange={(event) =>{
											setUseOverrideHourlyRate(event.target.checked);
										}}></input>
									</div>
									
									<input type='text' disabled={useOverrideHourlyRate==false}
										onKeyPress={(event)=>{forceDecimal(event)}} 
										defaultValue={prettyCurrency(overrideHourlyRate)}
										onFocus={(event)=>{
											let input = event.target as HTMLInputElement
											input.setSelectionRange(0,input.value.length)
										}}
										onBlur={(event) =>{

											if(event.target.value ==""){
												event.target.value = "0";
											}
											
											event.target.value = prettyCurrency(event.target.value)
											setOverrideHourlyRate(parseFloat(event.target.value.replace("$","").replace(/,/g,"")));
										}}></input>
								</div>
							</div>
						</div>
						{props.client && (
							<div className="bottomArea">
								<h1>Client Contacts</h1>
								<div className="butonAdd"
									onClick={() =>{
										let newContact = {
											organizationID:organization.id,
											clientID:props.client!.id,
											id:"",
											name:"",
											email:"",
											phone:"",
											title:""
										}
										setClientContacts([...clientContacts,newContact]);
									}}><FontAwesomeIcon icon={faPlus} /> Add Contact</div>
								<ClientsContactTable
									clientContacts={clientContacts} 
									onClientContactsChange={(clientContacts: ClientContact[]) =>{
										setClientContacts(clientContacts);
									} }></ClientsContactTable>
							</div>
						)}
						{props.client && (
							<div className='bottomArea'>
								<h1>Client Projects</h1>
								<ClientProjectsTable 
									client={props.client}
									onEditProjectPressed={project =>{
										setSelectedProject(project);
										setShowingProjectPopup(true);
									}}></ClientProjectsTable>
							</div>
						)}
					</div>
				)}


				<div className="floatingButtons">
					<div className="buttonCancel"
						onClick={() =>{
							props.onCancel();
						}}>Cancel</div>
					{(props.allowMultiple && props.client==null)? (

						<DropdownButton 
						items={["Save & Close","Save & Add New"]} 
						selectedIndex={0} 
						executeFunction={(functionIndex: number) =>{
							switch(functionIndex){
								case 0:
									saveNewClient().then((newClient:Client) =>{
										props.onSave(newClient);
									});
									return;
								case 1:
									saveNewClient().then((newClient:Client) =>{
										clearForm();
									});
									return;
							}
					} } ></DropdownButton>
					):(
						<div className="buttonSave"
							onClick={() =>{
								if(props.client){
									updateExistingClient().then((newClient:Client) =>{
										props.onSave(newClient);
									});
								}else{
									saveNewClient().then((newClient:Client) =>{
										props.onSave(newClient);
									});
								}
							}}>Save &amp; Close</div>
					)}
				</div>
			</div>
			{showingProjectPopup && (
				<PopupAddProject
					project={selectedProject}
					onCancel={() =>{
						setShowingProjectPopup(false);
					}}
					onSave={(project:Project) =>{
						setShowingProjectPopup(false);
					}}></PopupAddProject>
			)}
		</>
  )
}