import React, { useEffect, useState } from "react"
import "./ProjectContactsTableCard.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../FirebaseModel/Organization";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { faCheck, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shortDate } from "../../Utils/formatting";
import { Client } from "../../FirebaseModel/Client";
import ClientContactDropdown from "../ClientContactDropdown/ClientContactDropdown";
import { Tooltip } from "@mui/material";
import { ClientContact } from "../../FirebaseModel/ClientContact";

interface ProjectContactsTableCardProps{
	contact:{clientContactID:string, primaryContact:boolean};
	isReadOnly?:boolean;
	onUpdate:(contact:{clientContactID:string, primaryContact:boolean}) => void;
	onDelete:(contact:{clientContactID:string, primaryContact:boolean}) => void;
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function ProjectContactsTableCard(props:ProjectContactsTableCardProps){	
	let clientContacts = useSelector((state:{clientContacts:ClientContact[]}) => state.clientContacts);
	let clientContact = clientContacts.find((contact) => contact.id===props.contact.clientContactID);
  return (
    <div className={`ProjectContactsTableCard`}>
			<div className="primaryContact">
				<label style={props.contact.primaryContact?{}:{opacity:props.isReadOnly?0:0.5}}>
					{props.isReadOnly ?(
						<>{props.contact.primaryContact??<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}</>
					):(
						<input type="checkbox" 
							checked={props.contact.primaryContact} 
							onChange={(event) =>{
								props.onUpdate({...props.contact, primaryContact:event.target.checked});						
							}}/>
					)}
					Primary Contact
				</label>
			</div>
			<div className="name">{clientContact?.name}</div>
			<div className="title">{clientContact?.title}</div>
			<div className="phone">{clientContact?.phone}</div>
			<div className="email">{clientContact?.email}</div>

			{!props.isReadOnly && (
				<Tooltip title="Remove contact from this project" placement="bottom">
					<div className="buttonDelete" onClick={() => props.onDelete(props.contact)}>
						<FontAwesomeIcon icon={faTrash} />
					</div>
				</Tooltip>
			)}
    </div>
  )
}