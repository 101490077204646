


import React, { useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ProjectPortalMonthTotalRow.css"
import { useSelector } from "react-redux";
import { Role, rolesExistAM } from "../../../FirebaseModel/Role";
import { padDecimal, prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { Organization } from "../../../FirebaseModel/Organization";
import { View } from "../../../FirebaseModel/View";
interface ProjectPortalMonthTotalRowProps{
	columns:string[],
	month:number;
	year:number;
	sfClosedWon:number;
	budget:number;
	hours:number;
	fixedCosts:number;
	extraMargin:number;
	hoursByRole:any;
}

export default function ProjectPortalMonthTotalRow(props:ProjectPortalMonthTotalRowProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const roles = useSelector((state:{roles:Role[]}) => state.roles)

	const buildColumnForColumn = (column:string, index:number) =>{
		switch(column){
			case "Month Closed Won":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}>{props.month}</td>
			case "Year Closed Won":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}>{props.year}</td>
			case "Project Status":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Client":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Project #":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Project Name":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Account Manager":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Regional Sales Manager":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Project Manager":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "SF Closed Won":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`} className="money">{padDecimal(prettyCurrency(props.sfClosedWon??0),2)}</td>
			case `${organization && (organization.shortName??"")} Production Budget`:
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`} className="money">{padDecimal(prettyCurrency(props.budget??0),2)}</td>
			case "Total Hours":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}>{prettyNum(props.hours??0)}</td>
			case "Fixed Costs":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`} className="money">{padDecimal(prettyCurrency(props.fixedCosts??0),2)}</td>
			case "Extra Margin":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`} className="money">{padDecimal(prettyCurrency(props.extraMargin??0),2)}</td>
			case "Comments":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Project Type":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Signed Contract?":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "% of Budget Used":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "PM Estimated % Complete":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "AM % to Invoice":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Latest Activity":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Current +/- based on % complete":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "AM Notes":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "PM Notes":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Admin Notes":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Difference Actual to Billed":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Total Invoiced":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Invoices Collected":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>
			case "Invoices Outstanding":
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}></td>

		}

		for(var i in roles){
			let role = roles[i];
			if(role.name === column){
				return <td key={`month_${props.month}_${props.year}_total_column_${index}`}>{padDecimal(prettyNum(props.hoursByRole[role.id]),2)}</td>
			}
		}
		console.log("column not found 4 ",column);
		return <></>
	}


  return (
    <tr className={`ProjectPortalMonthTotalRow`}>
			{props.columns.map((column:string,index:number) => buildColumnForColumn(column,index))}
    </tr>
  )
}