import React, { useEffect, useState } from "react"
import { addDoc, collection, deleteDoc, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where } from "firebase/firestore";
import "./TimesheetScreen.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import { prettyNum, forceNumeric, prettyCurrency, forceDecimal, shortDate } from "../../Utils/formatting";
import { sharedFirebaseAuth, sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { Person } from "../../FirebaseModel/Person";
import WeeklyTimesheetTable from "../../Components/WeeklyTimesheet/WeeklyTimesheetTable/WeeklyTimesheetTable";
import { ProjectRoleActualHours } from "../../FirebaseModel/ProjectRoleActualHours";
import { saveProjectRoleActualHours } from "../../Utils/SaveProjectRoleActualFunctions";
import { saveProjectAttributeChange, saveProjectPlannedHoursChange } from "../../Utils/SaveProjectFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faExplosion, faRocket } from "@fortawesome/free-solid-svg-icons";
import PopupAddClient from "../../Popups/PopupAddClient/PopupAddClient";
import PopupAddProject from "../../Popups/PopupAddProject/PopupAddProject";
import PopupAddRole from "../../Popups/PopupAddRole/PopupAddRole";
import NavigationButtons from "../../Components/NavigationButtons/NavigationButtons";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { UndoStackEntry } from "../../Utils/UndoStackEntry";
import { MonthTotal } from "../../FirebaseModel/MonthTotal";
import { YearTotal } from "../../FirebaseModel/YearTotal";
import { act } from "react-dom/test-utils";
import { saveMonthTotalAttributeChange } from "../../Utils/SaveMonthTotalFunctions";
import { saveYearTotalAttributeChange } from "../../Utils/SaveYearTotalFunctions";
import { TimeOff } from "../../FirebaseModel/TimeOff";
import { saveTimeOffAttributeChange } from "../../Utils/SaveTimeOffFunctions";
interface TimesheetScreenProps {
}

export default function TimesheetScreen(props: TimesheetScreenProps) {


	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		submitUndoStackEntry: ActionCreators.submitUndoStackEntry
	}, dispatch);

  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const allActuals = useSelector((state: { actuals: ProjectRoleActualHours[] }) => state.actuals)
	const projects = useSelector((state: { projects: Project[] }) => state.projects)
	const monthTotals = useSelector((state: { monthTotals: MonthTotal[] }) => state.monthTotals)
	const yearTotals = useSelector((state: { yearTotals: YearTotal[] }) => state.yearTotals)
	const persons = useSelector((state: { persons: Person[] }) => state.persons)
	const timeOffList = useSelector((state: { timeOffList: TimeOff[] }) => state.timeOffList)
	const permissionsWrite = useSelector((state: { permissionsWrite: string[] }) => state.permissionsWrite)

	const [showingClientPopup, setShowingClientPopup] = useState(false);
	const [selectedActual, setSelectedActual] = useState<ProjectRoleActualHours>();
	const [showingProjectPopup, setShowingProjectPopup] = useState(false);
	const [showingRolePopup, setShowingRolePopup] = useState(false);

	let sunday = new Date();
	sunday.setDate(sunday.getDate() - sunday.getDay());
	const [weekKey, setWeekKey] = useState(shortDate(sunday));

	const person = persons.find(person => person.email.toLowerCase() === sharedFirebaseAuth.currentUser!.email!)!;

	const [actuals, setActuals] = useState<ProjectRoleActualHours[]>([]);


	const recalculateProjectMonthAndYearTotals = async () =>{
		await projects.forEach(async project => {
			let actualsForProject = actuals.filter(actual => actual.projectID === project.id);
			let totalHours = actualsForProject.reduce((total, actual) => total + actual.calculated.totalHours, 0);
			if (project.calculated.actualHours != totalHours) {
				let newCalcualted = { ...project.calculated }
				newCalcualted.actualHours = totalHours;

				let latestActivity = newCalcualted.latestActivity
				actuals.forEach(actual => {
					if(actual.projectID == project.id){
						actual.hours.forEach(hour => {
							if(latestActivity == ""){
								latestActivity = hour.date;
							}else{
								let latestDate = new Date(latestActivity);
								let date = new Date(hour.date);
								if(date > latestDate){
									latestActivity = hour.date;
								}
							}
						});
					}
				});
				newCalcualted.latestActivity = latestActivity;
				
				await saveProjectAttributeChange(project,organization,clients, "calculated", newCalcualted, (entry: UndoStackEntry) => {
					AC.submitUndoStackEntry(entry);
				})
				project.calculated = newCalcualted;
			}
		})

		await monthTotals.forEach(async monthTotal => {
			let actualsForMonth = actuals.filter(actual => actual.year === monthTotal.year);
			let totalBillableHoursByRole: { [roleID: string]: number } = {};
			let totalNonBillableHoursByRole: { [roleID: string]: number } = {};
			actualsForMonth.forEach(actual => {
				let totalHoursByRole = totalNonBillableHoursByRole;
				if(actual.billable){
					totalHoursByRole = totalBillableHoursByRole;
				}
				if (!totalHoursByRole[actual.roleID]) {
					totalHoursByRole[actual.roleID] = 0;
				}
				totalHoursByRole[actual.roleID] += actual.hours.reduce((total, hourEntry) => total + (new Date(hourEntry.date).getMonth()+1 == monthTotal.month?hourEntry.hours:0), 0);
			})
			let newCalculated = { ...monthTotal.calculated };
			newCalculated.actualHoursByRole = [];
			newCalculated.totalHoursBillable = 0;
			newCalculated.totalHoursNonBillable = 0;
			for (var roleID in totalBillableHoursByRole) {
				newCalculated.actualHoursByRole.push({ roleID: roleID, hours: totalBillableHoursByRole[roleID]??0 + totalNonBillableHoursByRole[roleID]??0 });
				newCalculated.totalHoursBillable += totalBillableHoursByRole[roleID]??0;
				newCalculated.totalHoursNonBillable += totalNonBillableHoursByRole[roleID]??0;
			}
			await saveMonthTotalAttributeChange(monthTotal, "calculated", newCalculated, (entry: UndoStackEntry) => {
				AC.submitUndoStackEntry(entry);
			})
			monthTotal.calculated = newCalculated;

		})

		await yearTotals.forEach(async yearTotal => {
			let monthTotalsForYear = monthTotals.filter(monthTotal => monthTotal.year === yearTotal.year);
			let totalBillableHoursByRole: { [roleID: string]: number } = {};
			let totalNonBillableHoursByRole: { [roleID: string]: number } = {};
			monthTotalsForYear.forEach(monthTotal => {
				let totalHoursByRole = totalNonBillableHoursByRole;
				if(monthTotal.calculated.totalHoursBillable > 0){
					totalHoursByRole = totalBillableHoursByRole;
				}
				monthTotal.calculated.actualHoursByRole.forEach(actualHoursByRole => {
					if (!totalHoursByRole[actualHoursByRole.roleID]) {
						totalHoursByRole[actualHoursByRole.roleID] = 0;
					}
					totalHoursByRole[actualHoursByRole.roleID] += actualHoursByRole.hours;
				})
			}	)
			let newCalculated = { ...yearTotal.calculated };
			newCalculated.actualHoursByRole = [];
			newCalculated.totalHoursBillable = 0;
			newCalculated.totalHoursNonBillable = 0;
			for (var roleID in totalBillableHoursByRole) {
				newCalculated.actualHoursByRole.push({ roleID: roleID, hours: totalBillableHoursByRole[roleID]??0 + totalNonBillableHoursByRole[roleID]??0 });
				newCalculated.totalHoursBillable += totalBillableHoursByRole[roleID]??0;
				newCalculated.totalHoursNonBillable += totalNonBillableHoursByRole[roleID]??0;
			}
			await saveYearTotalAttributeChange(yearTotal, "calculated", newCalculated, (entry: UndoStackEntry) => {
				AC.submitUndoStackEntry(entry);
			});
			yearTotal.calculated = newCalculated;

		});

	}

	useEffect(() => {
		let tempProjectRoleActualHours: ProjectRoleActualHours[] = [];
		allActuals.forEach(actual => {
			if (actual.personID === person.id) {
				tempProjectRoleActualHours.push(actual);
			}
		});
		setActuals(tempProjectRoleActualHours);
	}, [allActuals])


	const [tableOffsetTop, setTableOffsetTop] = useState<number>(0);
	const tableRef = React.createRef<HTMLDivElement>();
	const resize = () => {
		setTableOffsetTop(tableRef.current!.offsetTop);
	}
	useEffect(() => {
		window.addEventListener("resize", resize);
		resize();
		return () => {
			window.removeEventListener("resize", resize);
		}
	}, [])

	useEffect(() => {
		let table = tableRef.current!;
		if(table){
			const overrideWheel = (e: WheelEvent) => {
				if(table.scrollLeft === 0 && e.deltaX < 0){
					e.preventDefault();
				}
			};
			table.addEventListener("wheel", overrideWheel);
			return () => {
				table.removeEventListener("wheel", overrideWheel);
			};
		}
	}, [tableRef]);

	let nextWeekDate = (new Date(weekKey));
	nextWeekDate.setDate(nextWeekDate.getDate() + 7);

	const [selectedActuals, setSelectedActuals] = useState<ProjectRoleActualHours[]>([]);
	const [selectedPTO, setSelectedPTO] = useState<TimeOff|null>(null);
	const [selectedSTO, setSelectedSTO] = useState<TimeOff|null>(null);

	let nextWeekKey = shortDate(nextWeekDate);//`${nextWeekDate.getMonth()+1}/${nextWeekDate.getDate()}/${nextWeekDate.getFullYear()}`;
	return (
		<>
			<div className={`screen TimesheetScreen`}>
				<div className="topRow">
					<h1>Timesheet for {person.name}
						<div className="break"></div>
						<span onClick={() => {
							let today = new Date(weekKey!);
							today.setDate(today.getDate() - 7);
							setWeekKey(shortDate(today));
						}} className="weekButton"><FontAwesomeIcon icon={faChevronLeft} /></span>

						({weekKey} - {nextWeekKey})
						<span onClick={() => {
							let today = new Date(weekKey!);
							today.setDate(today.getDate() + 7);
							setWeekKey(shortDate(today));
						}} className="weekButton"><FontAwesomeIcon icon={faChevronRight} /></span>
					</h1>
				</div>
				<NavigationButtons />

				{permissionsWrite.includes("Personal Timesheet") && permissionsWrite.includes("Organization") && (selectedActuals.length> 0 || selectedPTO) && (
					<div className="buttonNuke" onClick={() =>{
						if(window.confirm(`You are about to delete ${selectedActuals.length} project role actuals, are you sure?`)){
							if(window.confirm(`This is irreversible, are you super duper sure?`)){
								let nukeEmAll = async () =>{
									for(var i in selectedActuals){
										deleteDoc(doc(sharedFirestore, "ProjectRoleActualHours", selectedActuals[i].id));
									}
									if(selectedPTO){
										let newHoursTakenPTO: {
											date: string;
											hours: number;
										}[] = [];
										let newCalculated = { ...selectedPTO.calculated };
										newCalculated.totalHoursTakenPTO = 0;
										await saveTimeOffAttributeChange(selectedPTO, ["hoursTakenPTO", "calculated"], [newHoursTakenPTO, newCalculated], (entry: UndoStackEntry) => {
											AC.submitUndoStackEntry(entry);
										});
									}
									if(selectedSTO){
										let newHoursTakenSTO: {
											date: string;
											hours: number;
										}[] = [];
										let newCalculated = { ...selectedSTO.calculated };
										newCalculated.totalHoursTakenSTO = 0;
										await saveTimeOffAttributeChange(selectedSTO, ["hoursTakenSTO", "calculated"], [newHoursTakenSTO, newCalculated], (entry: UndoStackEntry) => {
											AC.submitUndoStackEntry(entry);
										});
									}
									setSelectedActuals([]);
									setSelectedPTO(null);
								}
								nukeEmAll();
							}
						}
					}}><FontAwesomeIcon icon={faRocket}></FontAwesomeIcon> Delete {selectedActuals.length} Project Role Actuals!{selectedPTO?" and PTO":""}{selectedSTO?" and STO":""} <FontAwesomeIcon icon={faExplosion}></FontAwesomeIcon></div>
				)}
				
				<div className='contentClip'
						style={{maxHeight:`calc(100vh - ${tableOffsetTop}px - 90px)`}}>

					<div className="content"
						ref={tableRef}
						style={{ maxHeight: `calc(100vh - ${tableOffsetTop}px - 90px)` }}>
						<WeeklyTimesheetTable
							year={(new Date()).getFullYear()}
							goToDailyInput={() => { }}
							weekKey={weekKey}
							person={person} onCreateNewClient={(actual: ProjectRoleActualHours) => {
								setSelectedActual(actual);
								setShowingClientPopup(true);
							}} onCreateNewProject={(actual: ProjectRoleActualHours) => {
								setSelectedActual(actual);
								setShowingProjectPopup(true);
							}} onCreateNewRole={(actual: ProjectRoleActualHours) => {
								setSelectedActual(actual);
								setShowingRolePopup(true);
							}}

							onSelectedActualsChanged={(actuals: ProjectRoleActualHours[]) => {
								setSelectedActuals(actuals);
							}}										
							onSelectedPTOChanged={(timeOff:TimeOff|null) => {
								setSelectedPTO(timeOff);
							}}
							onSelectedSTOChanged={(timeOff:TimeOff|null) => {
								setSelectedSTO(timeOff);
							}}
							></WeeklyTimesheetTable>
					</div>
				</div>

				<div className="tableCover"></div>

				<div className="floatingButtons">
					<div className="buttonSave"
						onClick={() => {
							recalculateProjectMonthAndYearTotals();
						}}>Save</div>
				</div>
			</div>
			{showingClientPopup && (
				<PopupAddClient onCancel={() => {
					setShowingClientPopup(false);
				}} onSave={(client) => {
					setShowingClientPopup(false);
				}}></PopupAddClient>
			)}
			{showingProjectPopup && (
				<PopupAddProject
					disableMultiple={true}
					onCancel={() => {
						setShowingProjectPopup(false);
					}} onSave={(project) => {
						saveProjectRoleActualHours(selectedActual!, "projectID", project.id, (entry) => {
							AC.submitUndoStackEntry(entry);
						})
						setShowingProjectPopup(false);
					}}></PopupAddProject>
			)}
			{showingRolePopup && (
				<PopupAddRole onCancel={() => {
					setShowingRolePopup(false);
				}} onSave={(role) => {
					saveProjectRoleActualHours(selectedActual!, "roleID", role.id, (entry) => {
						AC.submitUndoStackEntry(entry);
					})
					setShowingRolePopup(false);
				}}></PopupAddRole>
			)}
		</>
	)
}