
import { useCSVReader } from 'react-papaparse';
import React, { useEffect, useState } from "react"
import { addDoc, collection, deleteDoc, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where } from "firebase/firestore";
import "./PopupImportTimesheet.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import { prettyNum, forceNumeric, prettyCurrency, forceDecimal, shortDate } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { saveClientAttributeChange } from "../../Utils/SaveClientFunctions";
import { useRef } from 'react';
import { ProjectRoleActualHours } from '../../FirebaseModel/ProjectRoleActualHours';
import { Person } from '../../FirebaseModel/Person';
import { saveProjectAttributeChange } from '../../Utils/SaveProjectFunctions';
import { saveProjectRoleActualHours } from '../../Utils/SaveProjectRoleActualFunctions';
import { TimeOff } from '../../FirebaseModel/TimeOff';
import { time } from 'console';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../Redux/ActionCreators';
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from '../../Utils/UndoStackEntry';
import { stringToBase64 } from '../../Utils/StringToBase64';
import { cleanCSVString } from '../../Utils/CleanCSVString';
interface PopupImportTimesheetProps {
  onDismiss: () => void;
}

export default function PopupImportTimesheet(props: PopupImportTimesheetProps) {

  const progressRef = useRef<HTMLProgressElement>(null);
  const organization = useSelector((state: { organization: Organization }) => state.organization)
  const clients = useSelector((state: { clients: Client[] }) => state.clients)
  const projects = useSelector((state: { projects: Project[] }) => state.projects)
  const roles = useSelector((state: { roles: Role[] }) => state.roles)
  const actuals = useSelector((state: { actuals: ProjectRoleActualHours[] }) => state.actuals)
  const persons = useSelector((state: { persons: Person[] }) => state.persons)

  const timeOffList = useSelector((state: { timeOffList: TimeOff[] }) => state.timeOffList)
  const { CSVReader } = useCSVReader();

  const [allRows, setAllRows] = useState<any[]>([]);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);




  const collapseRows = (rows: any[]) => {
    let collapsedRows: any[] = [];

    // <li>Project Number</li>
    // <li>Person Name</li>
    // <li>Role</li>
    // <li>Type (Billable, Not Billable, PTO, STO)</li>
    // <li>[Date 1] (yyyy-mm-dd)</li>
    // <li>[Date 2]</li>
    // <li>...</li>
    // <li>[Date N]</li>

    collapsedRows.push(rows[0]);

    // add a funciton replace to the number prototype that does nothing

    for(let r = 1; r < rows.length; r++) {
      let row = rows[r];
      let projectNumber = row[0];
      let personName = row[1];
      let role = row[2];
      let type = row[3];
      let otherRow = collapsedRows.find((r: any) => r[0] == projectNumber && r[1] == personName && r[2] == role && r[3] == type);
      if (otherRow == null) {
        collapsedRows.push([...row]);
      } else {
        for (let c = 4; c < row.length; c++) {
          if (row[c] != "") {
            otherRow[c] = parseFloat(otherRow[c]==""?"0":otherRow[c]) + parseFloat(row[c]==""?"0":row[c]);
          }
        }
      }
    }

    return collapsedRows;
  } 

	const downloadTemplateCSV = () =>{
		// generate a csv file with the appropriate headers and download it.

		let csvContent = "data:text/csv;charset=utf-8,";
		
    csvContent += "Project Number,Person Name,Role,\"Type (Billable, Not Billable, PTO, STO)\","+shortDate(new Date())+"\n";

		let encodedUri = encodeURI(csvContent);
		let link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "timesheet_import_template.csv");
		document.body.appendChild(link); // Required for FF

		link.click(); // This will download the data file named "my_data.csv".

		document.body.removeChild(link);
	}
  
	const downloadCurrentTimesheetCSV = () =>{
		// generate a csv file with the appropriate headers and download it.

    let csvContent = "";

    csvContent += "Project Number,Person Name,Role,\"Type (Billable, Not Billable, PTO, STO)\",";
    let firstDate = new Date();
    let lastDate = new Date();
    actuals.forEach(actual =>{
      actual.hours.forEach(hour =>{
        let hoursDate = new Date(hour.date);
        if(hoursDate < firstDate){
          firstDate = hoursDate;
        }
        if(hoursDate > lastDate){
          lastDate = hoursDate;
        }
      })
    })
    let currentDate = new Date(firstDate);
    let first = true;
    while(currentDate <= lastDate){
      if(!first){
        csvContent += ",";
      }
      first = false;
      csvContent += shortDate(currentDate);
      currentDate.setDate(currentDate.getDate()+1);
    }
    csvContent += "\n";

    let rows:any[][] = [];

    projects.forEach(project =>{
      roles.forEach(role =>{
        persons.forEach(person =>{
          let projectRoleActuals = actuals.filter(actual => actual.projectID == project.id && actual.personID == person.id && actual.roleID == role.id);
          projectRoleActuals.forEach(actual =>{
            let row:any[] = [];
            row.push(cleanCSVString(project.projectNumber));
            row.push(cleanCSVString(person.name));
            row.push(cleanCSVString(role.abbreviation));
            row.push(actual.billable?"Billable":"Not Billable");
            let currentDate = new Date(firstDate);
            let first = true;
            while(currentDate <= lastDate){
              let hours = actual.hours.find(hour => hour.date == shortDate(currentDate));
              if(hours){
                row.push(hours.hours);
              }else{
                row.push("0");
              }
              currentDate.setDate(currentDate.getDate()+1);
            }
            rows.push(row);

            // csvContent += cleanCSVString(project.projectNumber)+","+cleanCSVString(person.name)+","+role.abbreviation+(actual.billable?",Billable,":",Not Billable,");
            // let currentDate = new Date(firstDate);
            // let first = true;
            // while(currentDate <= lastDate){
            //   if(!first){
            //     csvContent += ",";
            //   }
            //   first = false;
            //   let hours = actual.hours.find(hour => hour.date == shortDate(currentDate));
            //   if(hours){
            //     csvContent += hours.hours;
            //   }else{
            //     csvContent += "0";
            //   }
            //   currentDate.setDate(currentDate.getDate()+1);
            // }
            // csvContent += "\n";
          })
        })
      })
    })


    timeOffList.forEach(timeOff =>{
      persons.forEach(person =>{
        if(timeOff.personID == person.id){
          let person = persons.find(p => p.id == timeOff.personID)!;
          let row:any[] = [];
          row.push("");
          row.push(cleanCSVString(person.name));
          row.push("");
          row.push("PTO");
          let currentDate = new Date(firstDate);
          let first = true;
          while(currentDate <= lastDate){
            let hours = timeOff.hoursTakenPTO.find(hour => hour.date == shortDate(currentDate));
            if(hours){
              row.push(hours.hours);
            }else{
              row.push("0");
            }
            currentDate.setDate(currentDate.getDate()+1);
          }
          rows.push(row);

          row = [];
          row.push("");
          row.push(cleanCSVString(person.name));
          row.push("");
          row.push("STO");
          currentDate = new Date(firstDate);
          first = true;
          while(currentDate <= lastDate){
            let hours = timeOff.hoursTakenSTO.find(hour => hour.date == shortDate(currentDate));
            if(hours){
              row.push(hours.hours);
            }else{
              row.push("0");
            }
            currentDate.setDate(currentDate.getDate()+1);
          }
          rows.push(row);
          // csvContent += ","+cleanCSVString(person.name)+",,PTO,";
          // let currentDate = new Date(firstDate);
          // let first = true;
          // while(currentDate <= lastDate){
          //   if(!first){
          //     csvContent += ",";
          //   }
          //   first = false;
          //   let hours = timeOff.hoursTakenPTO.find(hour => hour.date == shortDate(currentDate));
          //   if(hours){
          //     csvContent += hours.hours;
          //   }else{
          //     csvContent += "0";
          //   }
          //   currentDate.setDate(currentDate.getDate()+1);
          // }
          // csvContent += "\n";
          // csvContent += ","+cleanCSVString(person.name)+",,STO,";
          // currentDate = new Date(firstDate);
          // first = true;
          // while(currentDate <= lastDate){
          //   if(!first){
          //     csvContent += ",";
          //   }
          //   first = false;
          //   let hours = timeOff.hoursTakenSTO.find(hour => hour.date == shortDate(currentDate));
          //   if(hours){
          //     csvContent += hours.hours;
          //   }else{
          //     csvContent += "0";
          //   }
          //   currentDate.setDate(currentDate.getDate()+1);
          // }
          // csvContent += "\n";
        }
      })
    })

    let collapsedRows = collapseRows(rows);

    collapsedRows.forEach(row =>{
      csvContent += row.join(",")+"\n";
    })
 

		// convert content to base64 string and download as data uri
		let base64 = stringToBase64(csvContent);

		let dataUri = "data:text/csv;base64,"+base64;
		let link = document.createElement("a");
		link.setAttribute("href", dataUri);
		link.setAttribute("download", "current_timesheet.csv");
		
		document.body.appendChild(link); // Required for FF

		link.click(); // This will download the data file named "my_data.csv".

		document.body.removeChild(link);
  }


  const validateFormat = (rows: any) => {


    // <li>Project Number</li>
    // <li>Person Name</li>
    // <li>Role</li>
    // <li>Type (Billable, Not Billable, PTO, STO)</li>
    // <li>[Date 1] (yyyy-mm-dd)</li>
    // <li>[Date 2]</li>
    // <li>...</li>
    // <li>[Date N]</li>

    if (rows.length < 2) {
      setErrorMessage("File must have at least 2 rows");
      return false;
    }


    let headerRow = rows[0];

    if (headerRow[0].toLowerCase() != "project number") {
      setErrorMessage("First column must be 'Project Number'");
      return false;
    }

    if (headerRow[1].toLowerCase() != "person name") {
      setErrorMessage("Second column must be 'Person Name'");
      return false;
    }
    if (headerRow[2].toLowerCase() != "role") {
      setErrorMessage("Second column must be 'Role'");
      return false;
    }
    if (headerRow[3].toLowerCase() != "type (billable, not billable, pto, sto)") {
      setErrorMessage("Second column must be 'Type (Billable, Not Billable, PTO, STO)'");
      return false;
    }
    for (let c = 4; c < headerRow.length; c++) {
      if (headerRow[c] == "") {
        break;
      }
      if (headerRow[c].indexOf("-") != -1) {
        let parts = headerRow[c].split("-");
        if (parts.length != 3) {
          setErrorMessage("Date columns must be in the format yyyy-mm-dd");
          return false;
        }
        let year = parseInt(parts[0]);
        if(year < 100){
          year += 2000;
        }
        let month = parseInt(parts[1]);
        let day = parseInt(parts[2]);
        if (year < 1980 || year > 2100 || month < 1 || month > 12 || day < 1 || day > 31) {
          setErrorMessage("Date columns must be in the format yyyy-mm-dd");
          return false;
        }
      } else if (headerRow[c].indexOf("/") != -1) {
        let parts = headerRow[c].split("/");
        if (parts.length != 3) {
          setErrorMessage("Date columns must be in the format yyyy-mm-dd");
          return false;
        }
        let year = parseInt(parts[2]);
        if(year < 100){
          year += 2000;
        }
        let month = parseInt(parts[0]);
        let day = parseInt(parts[1]);
        if (year < 1980 || year > 2100 || month < 1 || month > 12 || day < 1 || day > 31) {
          setErrorMessage("Date columns must be in the format yyyy-mm-dd");
          return false;
        }
      }
    }

    return true;
  }

  let updateProjectsCalculatedActuals = async (modifiedProjects: Project[]) => {
  }

  useEffect(() => {
    updateProjectsCalculatedActuals = async (modifiedProjects: Project[]) => {
      for (let p in modifiedProjects) {
        let project = modifiedProjects[p];

        let actualsForProject = actuals.filter(actual => actual.projectID === project.id);
        let totalHours = actualsForProject.reduce((total, actual) => total + actual.calculated.totalHours, 0);
        if (project.calculated.actualHours != totalHours) {
          let newCalcualted = { ...project.calculated }
          if (newCalcualted.actualHours != totalHours) {
            newCalcualted.actualHours = totalHours;
            saveProjectAttributeChange(project, organization,clients, "calculated", newCalcualted,(entry:UndoStackEntry) =>{
              AC.submitUndoStackEntry(entry);
            })
          }
        }
      }
    }
  }, [actuals])



  const findActual = async (project: Project, personID: string, year: number, role: Role | null) => {
    return new Promise((resolve: (actual: ProjectRoleActualHours) => void, reject) => {
      for (let a in actuals) {
        if (actuals[a].projectID == project.id && actuals[a].personID == personID && actuals[a].year == year && (role == null || actuals[a].roleID == role.id)) {
          resolve(actuals[a]);
          return;
        }
      }
      let person = persons.find(p => p.id == personID)!;
      let newActual = {
        organizationID: organization.id,
        projectID: project.id,
        personID: personID,
        roleID: role ? role.id : (person.roles ? (person.roles!.length > 0 ? person.roles![0].roleID : "") : ""),
        year: year,
        billable: true,
        order: 0,
        hours: [] as { date: string, hours: number }[],
        calculated: {
          totalHours: 0
        }
      }
      addDoc(collection(sharedFirestore, "ProjectRoleActualHours"), newActual)
        .then((doc) => {
          let actual = { id: doc.id, ...newActual } as ProjectRoleActualHours;
          actuals.push(actual);

          AC.submitUndoStackEntry({
            type: UndoStackEntryType.create,
            objectType: UndoStackEntryObjectType.actual,
            objectID: actual.id,
            objectBeforeState: undefined,
            objectAfterState: {...actual},
            description: 'Create Project Role Actual'
          })
          resolve(actual);
        });
    })
  }

  const findTimeOff = async (personID: string, year: number) => {
    return new Promise((resolve: (actual: TimeOff) => void, reject) => {

      let timeOff = timeOffList.find(timeOff => timeOff.personID == personID && timeOff.year == year);
      if (timeOff == null) {
        let person = persons.find(p => p.id == personID)!;
        let allottedPTO = person.PTOPerYear;
  			let allottedSTO = person.STOPerYear;
  			if(organization.managesPTOAtOrganizationLevel){
  				allottedPTO = organization.PTOPerYear;
  				allottedSTO = organization.STOPerYear;
  			}

        let timeOffDocData = {
          organizationID: organization.id,
          personID: personID,
          year: year,
          hoursTakenSTO: [],
          hoursTakenPTO: [],
          allottedPTO: allottedPTO,
          allottedSTO: allottedSTO,
          calculated: {
            totalHoursTakenPTO: 0,
            totalHoursTakenSTO: 0,
          }
        };
        addDoc(collection(sharedFirestore, "TimeOff"), timeOffDocData).then((docref) => {
          let timeOff = { ...timeOffDocData, id: docref.id } as TimeOff;

          AC.submitUndoStackEntry({
            type: UndoStackEntryType.create,
            objectType: UndoStackEntryObjectType.timeOff,
            objectID: timeOff.id,
            objectBeforeState: undefined,
            objectAfterState: {...timeOff},
            description: 'Create Time Off'
          })
          resolve(timeOff);
        })
      } else {
        resolve(timeOff);
      }
    })
  }
  const getRemainingHours = (actual: ProjectRoleActualHours, startDate: Date, endDate: Date) => {
    let results: { date: string, hours: number }[] = [];
    for (let i in actual.hours) {
      let date = new Date(actual.hours[i].date);
      if (date < startDate || date > endDate) {
        results.push(actual.hours[i]);
      }
    }
    return results;
  }

  const getRemainingSTOHours = (timeOff: TimeOff, startDate: Date, endDate: Date) => {
    let results: { date: string, hours: number }[] = [];
    for (let i in timeOff.hoursTakenSTO) {
      let date = new Date(timeOff.hoursTakenSTO[i].date);
      if (date < startDate || date > endDate) {
        results.push(timeOff.hoursTakenSTO[i]);
      }
    }
    return results;
  }

  const getRemainingPTOHours = (timeOff: TimeOff, startDate: Date, endDate: Date) => {
    let results: { date: string, hours: number }[] = [];
    for (let i in timeOff.hoursTakenPTO) {
      let date = new Date(timeOff.hoursTakenPTO[i].date);
      if (date < startDate || date > endDate) {
        results.push(timeOff.hoursTakenPTO[i]);
      }
    }
    return results;
  }


  const saveNewHours = async (actual: ProjectRoleActualHours, hours: { date: string, hours: number }[]) => {
    // let newHours = actual.hours;
    // for (let h in hours) {
    //   newHours.push(hours[h]);
    // }
    let totalHours = 0;
    for (let h in hours) {
      totalHours += hours[h].hours;
    }
    if (hours.length > 0) {
      let newActual = {
        ...actual,
        hours: hours,
        calculated: {
          ...actual.calculated,
          totalHours: totalHours
        }
      }

      AC.submitUndoStackEntry({
        type: UndoStackEntryType.update,
        objectType: UndoStackEntryObjectType.actual,
        objectID: newActual.id,
        objectBeforeState: {...actual},
        objectAfterState: {...newActual},
        description: 'Update Project Role Actual'
      })

      return updateDoc(doc(sharedFirestore, "ProjectRoleActualHours", actual.id), newActual)
    } else {

      AC.submitUndoStackEntry({
        type: UndoStackEntryType.delete,
        objectType: UndoStackEntryObjectType.actual,
        objectID: actual.id,
        objectBeforeState: {...actual},
        objectAfterState: undefined,
        description: 'Delete Project Role Actual'
      })
      
      return deleteDoc(doc(sharedFirestore, "ProjectRoleActualHours", actual.id));
    }
  }

  const saveNewSTOHours = async (timeOff: TimeOff, hours: { date: string, hours: number }[]) => {
    // let newHours = timeOff.hoursTakenSTO;
    // for (let h in hours) {
    //   newHours.push(hours[h]);
    // }
    let totalHours = 0;
    for (let h in hours) {
      totalHours += hours[h].hours??0;
    }
    let newTimeOff = {
      ...timeOff,
      hoursTakenSTO: hours,
      calculated: {
        ...timeOff.calculated,
        totalHoursTakenSTO: totalHours,
      }
    }

    AC.submitUndoStackEntry({
      type: UndoStackEntryType.update,
      objectType: UndoStackEntryObjectType.timeOff,
      objectID: newTimeOff.id,
      objectBeforeState: {...timeOff},
      objectAfterState: {...newTimeOff},
      description: 'Update Time Off'
    })
    timeOff.hoursTakenSTO = newTimeOff.hoursTakenSTO;
    timeOff.calculated = newTimeOff.calculated;
    return updateDoc(doc(sharedFirestore, "TimeOff", timeOff.id), newTimeOff)
  }
  const saveNewPTOHours = async (timeOff: TimeOff, hours: { date: string, hours: number }[]) => {
    // let newHours = timeOff.hoursTakenPTO;
    // for (let h in hours) {
    //   newHours.push(hours[h]);
    // }
    let totalHours = 0;
    for (let h in hours) {
      totalHours += hours[h].hours??0;
    }
    let newTimeOff = {
      ...timeOff,
      hoursTakenPTO: hours,
      calculated: {
        ...timeOff.calculated,
        totalHoursTakenPTO: totalHours,
      }
    }
    AC.submitUndoStackEntry({
      type: UndoStackEntryType.update,
      objectType: UndoStackEntryObjectType.timeOff,
      objectID: newTimeOff.id,
      objectBeforeState: {...timeOff},
      objectAfterState: {...newTimeOff},
      description: 'Update Time Off'
    })
    timeOff.hoursTakenPTO = newTimeOff.hoursTakenPTO;
    timeOff.calculated = newTimeOff.calculated;
    return updateDoc(doc(sharedFirestore, "TimeOff", timeOff.id), newTimeOff)
  }


  const processFile = async (rows: any) => {
    setAllRows(rows);
  }
  const startImport = async (rows: any) => {

    AC.submitUndoStackEntry({
      type: UndoStackEntryType.batchStart,
      objectType: UndoStackEntryObjectType.nonApplicable,
      objectID: "",
      objectBeforeState: undefined,
      objectAfterState: undefined,
      description: 'Import Timesheet'
    })

    setErrorMessage("");

    let collapsedRows = collapseRows(rows);

    let dates: Date[] = [];
    let lastColumn = rows[0].length - 1;


    let firstDate: Date | null = null;
    let lastDate: Date | null = null;

    let modifiedProjects: Project[] = [];

    const DATE_START_COL = 4;


    progressRef.current!.max = collapsedRows.length;
    progressRef.current!.style.display = "block";

    function startNextRow(i: number) {
      setTimeout(() => {
        processNextRow(i);
      }, 100);
    }


    function parseTeamGanttDate(input: string) {
      if(input.indexOf('/') > -1){
        let parts = input.split('/');
        let year = parseInt(parts[2]);
        if(year < 100){
          year += 2000;
        }
        let month = parseInt(parts[0]);
        let day = parseInt(parts[1]);
        return new Date(year,month-1,day); // Note: months are 0-based
      }else{
  
        let parts = input.split('-');
  
        // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
        return new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2])); // Note: months are 0-based
      }
  
    }

    //for(let i = 0; i < rows.length; i++){
    async function processNextRow(i: number) {
      if (i >= collapsedRows.length) {
        console.log("Done processing file");
        progressRef.current!.style.display = "none";
        
        setAllRows([]);
        setTimeout(async () => {
          await updateProjectsCalculatedActuals(modifiedProjects);

          AC.submitUndoStackEntry({
            type: UndoStackEntryType.batchEnd,
            objectType: UndoStackEntryObjectType.nonApplicable,
            objectID: "",
            objectBeforeState: undefined,
            objectAfterState: undefined,
            description: 'Import Timesheet'
          })

          alert("done");
        }, 100);
        return;
      }
      let row = collapsedRows[i];
      let headerRow = collapsedRows[0];

      progressRef.current!.value = i;


      dates = [];
      for (var c = DATE_START_COL; c < headerRow.length; c++) {
        if (headerRow[c] == "") {
          lastColumn = c - 1;
          break;
        }
        dates[c] = parseTeamGanttDate(headerRow[c]);
      }
      firstDate = dates[DATE_START_COL];
      lastDate = dates[lastColumn];

      console.log(`Processing row ${i} of ${collapsedRows.length}...`);

      if (i == 0) {
        // Header row
      } else {

        let projectNumber = row[0];
        let personName = row[1];
        let roleAbbreviation = row[2];
        let type = row[3];
        let person = persons.find(person => person.name.toLowerCase() === personName.toLowerCase());
        if (type.toLowerCase() == "billable" || type.toLowerCase() == "not billable") {
          let project = projects.find(project => project.projectNumber.toLowerCase() === projectNumber.toLowerCase());
          let role = roles.find(role => role.abbreviation.toLowerCase() === roleAbbreviation.toLowerCase()) as Role | null;
          if (person != null && project != null) {
            let actual = await findActual(project, person.id, firstDate!.getFullYear(), role);
            if (type.toLowerCase() == "billable" && !actual.billable) {
              actual.billable = true;
            } else if (type.toLowerCase() == "not billable" && actual.billable) {
              actual.billable = false;
            }

            let newHours = getRemainingHours(actual, firstDate!, lastDate!);
            let cutoffDate = new Date(firstDate!);
            for (let c = DATE_START_COL; c <= lastColumn; c++) {
              let hours = (row[c]&&row[c]!="")?parseFloat(row[c]):0;
              if (hours > 0) {
                if (dates[c].getFullYear() != cutoffDate.getFullYear()) {
                  //console.log("Saving hours for " + currentName + " " + currentTask, newHours)
                  await saveNewHours(actual, newHours);
                  cutoffDate = new Date(dates[c]);
                  actual = await findActual(project!, person.id, dates[c].getFullYear(), role);
                  newHours = getRemainingHours(actual, dates[c], lastDate!);
                }
                newHours.push({
                  date: shortDate(dates[c]),
                  hours: hours
                })
              }
            }
            await saveNewHours(actual, newHours);
          }
        } else if (type.toLowerCase() == "sto") {
          let timeOff = await findTimeOff(person!.id, firstDate!.getFullYear());
          let newHours = getRemainingSTOHours(timeOff, firstDate!, lastDate!);
          let cutoffDate = new Date(firstDate!);
          for (let c = DATE_START_COL; c <= lastColumn; c++) {
            let hours = (row[c]&&row[c]!="")?parseFloat(row[c]):0;
            if (hours > 0) {
              if (dates[c].getFullYear() != cutoffDate.getFullYear()) {
                //console.log("Saving hours for " + currentName + " " + currentTask, newHours)
                await saveNewSTOHours(timeOff, newHours);
                cutoffDate = new Date(dates[c]);
                timeOff = await findTimeOff(person!.id, dates[c]!.getFullYear());
                newHours = getRemainingSTOHours(timeOff, dates[c], lastDate!);
              }
              newHours.push({
                date: shortDate(dates[c]),
                hours: hours
              })
            }
          }
          await saveNewSTOHours(timeOff, newHours);
        } else if (type.toLowerCase() == "pto") {
          let timeOff = await findTimeOff(person!.id, firstDate!.getFullYear());
          let newHours = getRemainingPTOHours(timeOff, firstDate!, lastDate!);
          let cutoffDate = new Date(firstDate!);
          for (let c = DATE_START_COL; c <= lastColumn; c++) {
            let hours = (row[c]&&row[c]!="")?parseFloat(row[c]):0;
            if (hours > 0) {
              if (dates[c].getFullYear() != cutoffDate.getFullYear()) {
                //console.log("Saving hours for " + currentName + " " + currentTask, newHours)
                await saveNewPTOHours(timeOff, newHours);
                cutoffDate = new Date(dates[c]);
                timeOff = await findTimeOff(person!.id, dates[c]!.getFullYear());
                newHours = getRemainingPTOHours(timeOff, dates[c], lastDate!);
              }
              newHours.push({
                date: shortDate(dates[c]),
                hours: hours
              })
            }
          }
          await saveNewPTOHours(timeOff, newHours);
        }

      }

      startNextRow(i + 1);
    }
    if (validateFormat(collapsedRows)) {
      startNextRow(0);
    }
  }


  return (
    <div className={`popup PopupImportTimesheet`}>
      <h1>Import Timesheet Hours from CSV</h1>

      {errorMessage != "" && <div className="errorMessage">{errorMessage}</div>}

      <div className='instructions'>
        The CSV file must be formatted with these headers in this order:
        <ul>
          <li>Project Number</li>
          <li>Person Name</li>
          <li>Role</li>
          <li>Type (Billable, Not Billable, PTO, STO)</li>
          <li>[Date 1] (yyyy-mm-dd)</li>
          <li>[Date 2]</li>
          <li>...</li>
          <li>[Date N]</li>
        </ul>
      </div>

      <CSVReader
        onUploadAccepted={async (results: any) => {
          console.log('---------------------------');
          console.log(results);
          console.log('---------------------------');
          processFile(results.data);
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }: any) => (
          <>
            <div className='csvReader'>
              <button type='button' {...getRootProps()} className={"browseFile"}>
                Browse file
              </button>
              <div className={"acceptedFile"}>
                {acceptedFile && acceptedFile.name}
              </div>
              <button {...getRemoveFileProps()} className={"remove"}>
                Remove
              </button>
            </div>
            {/* <ProgressBar className={"progressBarBackgroundColor"} /> */}
          </>
        )}
      </CSVReader>
      {allRows.length > 0 && (
        <div className='buttonImport'
          onClick={() =>{
            startImport(allRows)
            setAllRows([]);
          }}><FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon> Start Import</div>
      )}
      <progress ref={progressRef} style={{ display: 'none' }} className={"progressBarBackgroundColor"} />

			<div className='buttonTemplate'
				onClick={downloadTemplateCSV}>
				<FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon> Download Template
				</div>

      <div className='buttonTemplate'
        onClick={downloadCurrentTimesheetCSV}>
        <FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon> Download Current Timesheet CSV
        </div>

      <div className="floatingButtons">
        <div className="buttonSave"
          onClick={() => {
            projects.forEach(project => {
              let actualsForProject = actuals.filter(actual => actual.projectID === project.id);
              let totalHours = actualsForProject.reduce((total, actual) => total + actual.calculated.totalHours, 0);
              if (project.calculated.actualHours != totalHours) {
                let newCalcualted = { ...project.calculated }
                if (newCalcualted.actualHours != totalHours) {
                  newCalcualted.actualHours = totalHours;
                  saveProjectAttributeChange(project, organization,clients, "calculated", newCalcualted,(entry:UndoStackEntry) =>{
                    AC.submitUndoStackEntry(entry);
                  })
                }
              }
            })
            props.onDismiss();
          }}>Done</div>
      </div>
    </div>
  )
}