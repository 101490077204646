

import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./PersonDropdown.css"
import { useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { forceNumeric, prettyCurrency, prettyNum } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import { Person } from "../../FirebaseModel/Person";
interface PersonDropdownProps{
	className?:string,
	isAM?:boolean,
	isPM?:boolean,
	isRSM?:boolean,
	roleID?:string,
	isBillable?:boolean,
	clearOnChange?:boolean,
	prompt?:string,
	defaultValue?:string,
	noCreation?:boolean,
	onChange?:(value:string) => void
	onCreateNew?:()=>void
}

export default function PersonDropdown(props:PersonDropdownProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)

	const [sortedPersons, setSortedPersons] = useState<Person[]>([]);
	const [selectedValue, setSelectedValue] = useState(props.defaultValue);

	useEffect(() =>{
		if(organization && persons && roles){
			let filteredPersons = persons.filter((person:Person) =>{
				if(props.roleID){
					if(person.roles==undefined){
						return false;
					}
					for(var role of person.roles!){
						if(role.roleID == props.roleID){
							return person.active;
						}
					}
					return false;
				}else if(props.isAM){
					if(person.roles==undefined){
						return false;
					}
					for(var role of person.roles!){
						let otherRoles = roles.filter((role:Role) => role.isAM == true);
						for(var otherRole of otherRoles){
							if(role.roleID == otherRole.id){
								return person.active;
							}
						}
					}
					return false;
				}else if(props.isPM){
					if(person.roles==undefined){
						return false;
					}
					for(var role of person.roles!){
						let otherRoles = roles.filter((role:Role) => role.isPM == true);
						for(var otherRole of otherRoles){
							if(role.roleID == otherRole.id){
								return person.active;
							}
						}
					}
					return false;
				}else if(props.isRSM){
					if(person.roles==undefined){
						return false;
					}
					for(var role of person.roles!){
						let otherRoles = roles.filter((role:Role) => role.isRSM == true);
						for(var otherRole of otherRoles){
							if(role.roleID == otherRole.id){
								return person.active;
							}
						}
					}
					return false;
				}else if(props.isBillable){
					if(person.roles==undefined){
						return false;
					}
					for(var role of person.roles!){
						let otherRoles = roles.filter((role:Role) => role.isBillable == true);
						for(var otherRole of otherRoles){
							if(role.roleID == otherRole.id){
								return person.active;
							}
						}
					}
					return false;
				}
				return person.active;
			});
			let tempsortedPersons = [... filteredPersons];
			tempsortedPersons.sort((a,b) =>{
				let aFirstName = a.name.split(" ")[0]
				let bFirstName = b.name.split(" ")[0]
				if(aFirstName.toLowerCase() < bFirstName.toLowerCase()){
					return -1;
				}
				if(aFirstName.toLowerCase() > bFirstName.toLowerCase()){
					return 1;
				}
				let aLastName = a.name.split(" ")[a.name.split(" ").length-1]
				let bLastName = b.name.split(" ")[b.name.split(" ").length-1]
				if(aLastName.toLowerCase() < bLastName.toLowerCase()){
					return -1;
				}
				if(aLastName.toLowerCase() > bLastName.toLowerCase()){
					return 1;
				}
				return 0;
			});
			setSortedPersons(tempsortedPersons);
		}
	},[organization,persons,roles])

	useEffect(() =>{
		setSelectedValue(props.defaultValue);
	},[props.defaultValue])

  return (
    <select 
				className={`PersonDropdown ${props.className??""}`} 
				value={selectedValue}
				onChange={(event) =>{
					setSelectedValue(event.target.value);
					if(event.target.value == "create new"){
						if(props.onCreateNew){
							props.onCreateNew();
						}
					}else{
						if(props.onChange){
							props.onChange(event.target.value);
						}
						if(props.clearOnChange){
							setSelectedValue("");
						}
					}
				}}>
			<option value={""}>{props.prompt??"Select Person"}</option>
			{sortedPersons.map((person:Person, index:number) =>{
				return <option key={"person_option_"+index} value={person.id}>{person.name}</option>
			})}
			{(permissionsWrite.indexOf("Team") != -1 && !props.noCreation )&& (
				<option value="create new">Create a new Person</option>
			)}
    </select>
  )
}