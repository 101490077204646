
import React, { useEffect, useState } from "react"
import { addDoc, collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./PopupAddPerson.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../FirebaseModel/Organization";
import { Person, PersonNonProjectTask } from "../../FirebaseModel/Person";
import { Client } from "../../FirebaseModel/Client";
import { forceDecimal, forceMaxValue, forcePercentage, prettyCurrency, prettyNum, shortDate } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { Role } from "../../FirebaseModel/Role";
import { saveRoleAttributeChange } from "../../Utils/SaveRoleFunctions";
import { savePersonAttributeChange } from "../../Utils/SavePersonFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { UndoStackEntry, UndoStackEntryType, UndoStackEntryObjectType } from "../../Utils/UndoStackEntry";
import DepartmentDropdown from "../../Components/DepartmentDropdown/DepartmentDropdown";
import PersonActiveTasksTable from "../../Components/PersonActiveTasks/PersonActiveTasksTable/PersonActiveTasksTable";
import { Project } from "../../FirebaseModel/Project";
import PopupAddProject from "../PopupAddProject/PopupAddProject";
import NonProjectTasksTable from "../../Components/NonProjectTasks/NonProjectTasksTable/NonProjectTasksTable";
import { saveOrganizationAttributeChange } from "../../Utils/SaveOrganizationFunctions";
import { updatePersonTimeOffAllottedPTOAndSTO } from "../../Utils/SaveTimeOffFunctions";
import { TimeOff } from "../../FirebaseModel/TimeOff";
interface PopupAddPersonProps{
	person?:Person;
	roleFilter?:string;
	onCancel:()=>void;
	onSave:(person:Person) =>void;
	allowMultiple?:boolean;
}

export default function PopupAddPerson(props:PopupAddPersonProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)
  const timeOffList = useSelector((state:{timeOffList:TimeOff[]}) => state.timeOffList)

	const [clearingForm, setClearingForm] = useState(false);

	const [selectedProject, setSelectedProject] = useState<Project|undefined>(undefined);
	const [showingProjectPopup, setShowingProjectPopup] = useState(false);

	const [personName, setPersonName] = useState(props.person? props.person!.name :"");
	const [personEmail, setPersonEmail] = useState(props.person? props.person!.email :"");
	const [personPhone, setPersonPhone] = useState(props.person? props.person!.phone :"");
	const [personActive, setPersonActive] = useState(props.person? props.person!.active :true);
	const [birthday, setBirthday] = useState(props.person? props.person!.birthday :"");
	const [startDate, setStartDate] = useState(props.person? props.person!.startDate :"");
	const [endDate, setEndDate] = useState(props.person? props.person!.endDate :"");
	const [departmentID, setDepartmentID] = useState(props.person? props.person!.departmentID??"" :"");

	const [PTOPerYear, setPTOPerYear] = useState(props.person? props.person!.PTOPerYear:0);
	const [STOPerYear, setSTOPerYear] = useState(props.person? props.person!.STOPerYear:0);
	const [PTOSTORenewal, setPTOSTORenewal] = useState(props.person? props.person!.PTOSTORenewal:"");
	const [PTOExpiration, setPTOExpiration] = useState(props.person? props.person!.PTOExpiration:"");
	const [STOExpiration, setSTOExpiration] = useState(props.person? props.person!.STOExpiration:"");

	const [personEligibleForProfitSharing, setPersonEligibleForProfitSharing] = useState(props.person? props.person!.eligibleForProfitSharing :false);
	const [personNonProjectTasks, setPersonNonProjectTasks] = useState(props.person? props.person!.nonProjectTasks :[] as PersonNonProjectTask[]);

	const [permissionModifyOrganization, setPermissionModifyOrganization] = useState(props.person?(props.person.permissions?(props.person.permissions.indexOf("Organization")!=-1):false):false);
	const [permissionModifyClients, setPermissionModifyClients] = useState(props.person?(props.person.permissions?(props.person.permissions.indexOf("Clients")!=-1):false):false);
	const [permissionModifyTeam, setPermissionModifyTeam] = useState(props.person?(props.person.permissions?(props.person.permissions.indexOf("Team")!=-1):false):false);
	const [permissionModifyProjects, setPermissionModifyProjects] = useState(props.person?(props.person.permissions?(props.person.permissions.indexOf("Projects")!=-1):false):false);
	const [permissionModifyTimesheet, setPermissionModifyTimesheet] = useState(props.person?(props.person.permissions?(props.person.permissions.indexOf("Personal Timesheet")!=-1):false):false);
	const [permissionModifyOrganizationTimesheets, setPermissionModifyOrganizationTimesheets] = useState(props.person?(props.person.permissions?(props.person.permissions.indexOf("Organization Timesheets")!=-1):false):false);
	const [permissionModifyProfitSharing, setPermissionModifyProfitSharing] = useState(props.person?(props.person.permissions?(props.person.permissions.indexOf("Profit Sharing")!=-1):false):false);
	const [permissionReports, setPermissionReports] = useState(props.person?(props.person.permissions?(props.person.permissions.indexOf("Reports")!=-1):false):false);
	const [permissionReportSoldVsBilled, setPermissionReportSoldVsBilled] = useState(props.person?(props.person.permissions?(props.person.permissions.indexOf("Sold VS Billed Report")!=-1):false):false);
	const [permissionReportMonthlyBSP, setPermissionReportMonthlyBSP] = useState(props.person?(props.person.permissions?(props.person.permissions.indexOf("Monthly BSP Report")!=-1):false):false);
	const [permissionReportAnnualBSP, setPermissionReportAnnualBSP] = useState(props.person?(props.person.permissions?(props.person.permissions.indexOf("Annual BSP Report")!=-1):false):false);
	const [permissionReportProfitSharing, setPermissionReportProfitSharing] = useState(props.person?(props.person.permissions?(props.person.permissions.indexOf("Profit Sharing Report")!=-1):false):false);
	const [permissionProductionSchedule, setPermissionProductionSchedule] = useState(props.person?(props.person.permissions?(props.person.permissions.indexOf("Production Schedule")!=-1):false):false);


	const [permissionModifyOrganizationWrite, setPermissionModifyOrganizationWrite] = useState(props.person?(props.person.permissionsWrite?(props.person.permissionsWrite.indexOf("Organization")!=-1):false):false);
	const [permissionModifyClientsWrite, setPermissionModifyClientsWrite] = useState(props.person?(props.person.permissionsWrite?(props.person.permissionsWrite.indexOf("Clients")!=-1):false):false);
	const [permissionModifyTeamWrite, setPermissionModifyTeamWrite] = useState(props.person?(props.person.permissionsWrite?(props.person.permissionsWrite.indexOf("Team")!=-1):false):false);
	const [permissionModifyProjectsWrite, setPermissionModifyProjectsWrite] = useState(props.person?(props.person.permissionsWrite?(props.person.permissionsWrite.indexOf("Projects")!=-1):false):false);
	const [permissionModifyTimesheetWrite, setPermissionModifyTimesheetWrite] = useState(props.person?(props.person.permissionsWrite?(props.person.permissionsWrite.indexOf("Personal Timesheet")!=-1):false):false);
	const [permissionModifyOrganizationTimesheetsWrite, setPermissionModifyOrganizationTimesheetsWrite] = useState(props.person?(props.person.permissionsWrite?(props.person.permissionsWrite.indexOf("Organization Timesheets")!=-1):false):false);
	const [permissionModifyProfitSharingWrite, setPermissionModifyProfitSharingWrite] = useState(props.person?(props.person.permissionsWrite?(props.person.permissionsWrite.indexOf("Profit Sharing")!=-1):false):false);
	const [permissionReportsWrite, setPermissionReportsWrite] = useState(props.person?(props.person.permissionsWrite?(props.person.permissionsWrite.indexOf("Reports")!=-1):false):false);
	const [permissionReportSoldVsBilledWrite, setPermissionReportSoldVsBilledWrite] = useState(props.person?(props.person.permissionsWrite?(props.person.permissionsWrite.indexOf("Sold VS Billed Report")!=-1):false):false);
	const [permissionReportMonthlyBSPWrite, setPermissionReportMonthlyBSPWrite] = useState(props.person?(props.person.permissionsWrite?(props.person.permissionsWrite.indexOf("Monthly BSP Report")!=-1):false):false);
	const [permissionReportAnnualBSPWrite, setPermissionReportAnnualBSPWrite] = useState(props.person?(props.person.permissionsWrite?(props.person.permissionsWrite.indexOf("Annual BSP Report")!=-1):false):false);
	const [permissionReportProfitSharingWrite, setPermissionReportProfitSharingWrite] = useState(props.person?(props.person.permissionsWrite?(props.person.permissionsWrite.indexOf("Profit Sharing Report")!=-1):false):false);
	const [permissionProductionScheduleWrite, setPermissionProductionScheduleWrite] = useState(props.person?(props.person.permissionsWrite?(props.person.permissionsWrite.indexOf("Production Schedule")!=-1):false):false);

	let tempPersonRoles:{[roleID:string]:{checked:boolean, weight:number}} = {};
	for(let role of roles){
		let checked = props.roleFilter?role.abbreviation == props.roleFilter:false;
		tempPersonRoles[role.id] = {checked:checked, weight:1};
		if(props.person && props.person!.roles){
			for(let personRole of props.person!.roles!){
				if(personRole.roleID == role.id){
					tempPersonRoles[role.id] = {checked:true, weight:personRole.weight};
				}
			}
		}
	}
	let [personRoles, setPersonRoles] = useState(tempPersonRoles);

	const saveNewPerson = () =>{
		return new Promise((resolve:(person:Person)=>void,reject) =>{
				for(let person of persons){
					if(person.email.toLowerCase() == personEmail.toLowerCase()){
						reject("A person with that email already exists");
						return;
					}
				}
				let docData = {
					organizationID:organization.id,
					name:personName,
					active:personActive,
					phone:personPhone,
					email:personEmail.toLowerCase(),
					permissions:[] as string[],
					permissionsWrite:[] as string[],
					birthday:birthday,
					startDate:startDate,
					endDate:endDate,
					departmentID:departmentID,
					nonProjectTasks:personNonProjectTasks,
					eligibleForProfitSharing:personEligibleForProfitSharing,
					roles:[]as {roleID:string, weight:number}[],
					PTOPerYear:PTOPerYear,
					STOPerYear:STOPerYear,
					PTOSTORenewal:PTOSTORenewal,
					PTOExpiration:PTOExpiration,
					STOExpiration:STOExpiration,
					assignedTaskOrder:[] as string[]
				}
				for(let role of roles){
					if(personRoles[role.id].checked){
						docData.roles.push({roleID:role.id, weight:personRoles[role.id].weight});
					}
				}
				if(permissionModifyOrganization){
					docData.permissions.push("Organization");
				}
				if(permissionModifyClients){
					docData.permissions.push("Clients");
				}
				if(permissionModifyTeam){
					docData.permissions.push("Team");
				}
				if(permissionModifyProjects){
					docData.permissions.push("Projects");
				}
				if(permissionModifyTimesheet){
					docData.permissions.push("Personal Timesheet");
				}
				if(permissionModifyProfitSharing){
					docData.permissions.push("Profit Sharing");
				}
				if(permissionReports){
					docData.permissions.push("Reports");
				}
				if(permissionReportSoldVsBilled){
					docData.permissions.push("Sold VS Billed Report");
				}
				if(permissionReportMonthlyBSP){
					docData.permissions.push("Monthly BSP Report");
				}
				if(permissionReportAnnualBSP){
					docData.permissions.push("Annual BSP Report");
				}
				if(permissionReportProfitSharing){
					docData.permissions.push("Profit Sharing Report");
				}
				if(permissionProductionSchedule){
					docData.permissions.push("Production Schedule");
				}
				if(permissionModifyOrganizationTimesheets){
					docData.permissions.push("Organization Timesheets");
				}


				if(permissionModifyOrganizationWrite){
					docData.permissionsWrite.push("Organization");
				}
				if(permissionModifyClientsWrite){
					docData.permissionsWrite.push("Clients");
				}
				if(permissionModifyTeamWrite){
					docData.permissionsWrite.push("Team");
				}
				if(permissionModifyProjectsWrite){
					docData.permissionsWrite.push("Projects");
				}
				if(permissionModifyTimesheetWrite){
					docData.permissionsWrite.push("Personal Timesheet");
				}
				if(permissionModifyProfitSharingWrite){
					docData.permissionsWrite.push("Profit Sharing");
				}
				if(permissionReportsWrite){
					docData.permissionsWrite.push("Reports");
				}
				if(permissionReportSoldVsBilledWrite){
					docData.permissionsWrite.push("Sold VS Billed Report");
				}
				if(permissionReportMonthlyBSPWrite){
					docData.permissionsWrite.push("Monthly BSP Report");
				}
				if(permissionReportAnnualBSPWrite){
					docData.permissionsWrite.push("Annual BSP Report");
				}
				if(permissionReportProfitSharingWrite){
					docData.permissionsWrite.push("Profit Sharing Report");
				}
				if(permissionProductionScheduleWrite){
					docData.permissionsWrite.push("Production Schedule");
				}
				if(permissionModifyOrganizationTimesheetsWrite){
					docData.permissionsWrite.push("Organization Timesheets");
				}

				addDoc(collection(sharedFirestore,"Person"),docData).then((docref) =>{
					let person = {... docData, id:docref.id} as Person;

					let undoEntry:UndoStackEntry = {
						type: UndoStackEntryType.create,
						objectType: UndoStackEntryObjectType.person,
						objectID: person.id,
						objectBeforeState: undefined,
						objectAfterState: {...person},
						description: "Create Person"
					}
					AC.submitUndoStackEntry(undoEntry);

		 			updateRolePersons(person).then(() =>{
						resolve(person);
					});
				})
		})
	}

	const saveExistingPerson = async () =>{
		const projectRef = doc(sharedFirestore, "Person", props.person!.id);

		let updatedPerson:{ [x: string]: any; } = {};
		for(var key in props.person!){
			updatedPerson[key] = (props.person! as any)[key];
		}
		updatedPerson.name = personName
		updatedPerson.active = personActive
		updatedPerson.email = personEmail.toLowerCase()
		updatedPerson.birthday = birthday
		updatedPerson.startDate = startDate
		updatedPerson.endDate = endDate
		updatedPerson.phone = personPhone
		updatedPerson.departmentID = departmentID
		updatedPerson.roles = [] as {roleID:string, weight:number}[];
		updatedPerson.nonProjectTasks = personNonProjectTasks;
		updatedPerson.eligibleForProfitSharing = personEligibleForProfitSharing;
		updatedPerson.PTOPerYear = PTOPerYear;
		updatedPerson.STOPerYear = STOPerYear;
		updatedPerson.PTOSTORenewal = PTOSTORenewal;
		updatedPerson.PTOExpiration = PTOExpiration;
		updatedPerson.STOExpiration = STOExpiration;
		for(let role of roles){
			if(personRoles[role.id].checked){
				updatedPerson.roles.push({roleID:role.id, weight:personRoles[role.id].weight});
			}
		}

		updatedPerson.permissions = [] as string[];

		if(permissionModifyOrganization){
			updatedPerson.permissions.push("Organization");
		}
		if(permissionModifyClients){
			updatedPerson.permissions.push("Clients");
		}
		if(permissionModifyTeam){
			updatedPerson.permissions.push("Team");
		}
		if(permissionModifyProjects){
			updatedPerson.permissions.push("Projects");
		}
		if(permissionModifyTimesheet){
			updatedPerson.permissions.push("Personal Timesheet");
		}
		if(permissionModifyProfitSharing){
			updatedPerson.permissions.push("Profit Sharing");
		}
		if(permissionReports){
			updatedPerson.permissions.push("Reports");
		}
		if(permissionReportSoldVsBilled){
			updatedPerson.permissions.push("Sold VS Billed Report");
		}
		if(permissionReportMonthlyBSP){
			updatedPerson.permissions.push("Monthly BSP Report");
		}
		if(permissionReportAnnualBSP){
			updatedPerson.permissions.push("Annual BSP Report");
		}
		if(permissionReportProfitSharing){
			updatedPerson.permissions.push("Profit Sharing Report");
		}
		if(permissionProductionSchedule){
			updatedPerson.permissions.push("Production Schedule");
		}
		if(permissionModifyOrganizationTimesheets){
			updatedPerson.permissions.push("Organization Timesheets");
		}

		updatedPerson.permissionsWrite = [] as string[];

		if(permissionModifyOrganizationWrite){
			updatedPerson.permissionsWrite.push("Organization");
		}
		if(permissionModifyClientsWrite){
			updatedPerson.permissionsWrite.push("Clients");
		}
		if(permissionModifyTeamWrite){
			updatedPerson.permissionsWrite.push("Team");
		}
		if(permissionModifyProjectsWrite){
			updatedPerson.permissionsWrite.push("Projects");
		}
		if(permissionModifyTimesheetWrite){
			updatedPerson.permissionsWrite.push("Personal Timesheet");
		}
		if(permissionModifyProfitSharingWrite){
			updatedPerson.permissionsWrite.push("Profit Sharing");
		}
		if(permissionReportsWrite){
			updatedPerson.permissionsWrite.push("Reports");
		}
		if(permissionReportSoldVsBilledWrite){
			updatedPerson.permissionsWrite.push("Sold VS Billed Report");
		}
		if(permissionReportMonthlyBSPWrite){
			updatedPerson.permissionsWrite.push("Monthly BSP Report");
		}
		if(permissionReportAnnualBSPWrite){
			updatedPerson.permissionsWrite.push("Annual BSP Report");
		}
		if(permissionReportProfitSharingWrite){
			updatedPerson.permissionsWrite.push("Profit Sharing Report");
		}
		if(permissionProductionScheduleWrite){
			updatedPerson.permissionsWrite.push("Production Schedule");
		}
		if(permissionModifyOrganizationTimesheetsWrite){
			updatedPerson.permissionsWrite.push("Organization Timesheets");
		}

		let undoEntry:UndoStackEntry = {
			type: UndoStackEntryType.batchStart,
			objectType: UndoStackEntryObjectType.person,
			objectID: props.person!.id,
			objectBeforeState: {...props.person!},
			objectAfterState: undefined,
			description: "Update Person"
		}
		AC.submitUndoStackEntry(undoEntry);

		await updatePersonTimeOffAllottedPTOAndSTO(props.person!,organization,timeOffList,entry =>{
			AC.submitUndoStackEntry(entry);
		})
		
		await updateRolePersons(props.person!);

		undoEntry = {
			type: UndoStackEntryType.update,
			objectType: UndoStackEntryObjectType.person,
			objectID: props.person!.id,
			objectBeforeState: {...props.person!},
			objectAfterState: {...updatedPerson},
			description: "Update Client"
		}
		AC.submitUndoStackEntry(undoEntry);

		undoEntry = {
			type: UndoStackEntryType.batchEnd,
			objectType: UndoStackEntryObjectType.person,
			objectID: props.person!.id,
			objectBeforeState: {...props.person!},
			objectAfterState: {...updatedPerson},
			description: "Update Person"
		}
		AC.submitUndoStackEntry(undoEntry);

		return updateDoc(projectRef,updatedPerson)
	}

	const updateRolePersons = async (person:Person) =>{

		for(let role of roles){
			if(personRoles[role.id].checked){
				if(role.persons == undefined){
					await saveRoleAttributeChange(role, "persons", [{personID:person.id, weight:personRoles[role.id].weight}],(entry) =>{
						AC.submitUndoStackEntry(entry);
					});
				}else{
					let rolePersonRow = role.persons.find(({personID}) => personID == person.id);
					if(rolePersonRow == undefined){
						await saveRoleAttributeChange(role, "persons", [...role.persons, {personID:person.id, weight:personRoles[role.id].weight}],(entry) =>{
							AC.submitUndoStackEntry(entry);
						});
					}else{
						if(rolePersonRow.weight != personRoles[role.id].weight){
							rolePersonRow.weight = personRoles[role.id].weight;
							await saveRoleAttributeChange(role, "persons", role.persons,(entry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}
					}
				}
			}else{
				if(role.persons){
					let newPersons = role.persons.filter(({personID}) => personID != person.id);
					if(newPersons.length != role.persons.length){
						await saveRoleAttributeChange(role, "persons", newPersons,(entry) =>{
							AC.submitUndoStackEntry(entry);
						});
					}
				}
			}
		}
	}


	const clearForm = () =>{
		setPersonName("");
		setPersonEmail("");
		setClearingForm(true);
		setTimeout(() =>{setClearingForm(false)}, 100);
	}


  return (
		<>
    <div className={`popup PopupAddPerson`}>
			<h1>{props.person?"Edit Person":"New Person"}</h1>
			{clearingForm == false && (
				<div className="content">

					<div className="topArea">

						<div className="row">
							<div className="col">
								<div className="label">Name</div>
								<input className={`clientName`} 
									defaultValue={personName}
									type="text"
									onBlur={(event) =>{
										setPersonName(event.target.value)
									}}></input>
							</div>
							<div className="col">
								<div className="label">Email</div>
								<input className={`personEmail`} 
									type="email"
									defaultValue={personEmail}
									onBlur={(event) =>{
										setPersonEmail(event.target.value)
									}}></input>
							</div>
							<div className="col">
								<div className="label">Phone</div>
								<input className={`personPhone`} 
									type="text"
									defaultValue={personPhone}
									onBlur={(event) =>{
										setPersonPhone(event.target.value)
									}}></input>
							</div>
							<div className="col">
								<div className="label">Active</div>
								<input className={`personActive`} 
									type="checkbox"
									checked={personActive}
									onChange={(event) =>{
										setPersonActive(event.target.checked)
									}}></input>
							</div>
							{organization && organization.offersProfitSharing && (
								<div className="col">
									<div className="label">Eligible for<br/>Profit Sharing</div>
									<input className={`personEligibleForProfitSharing`} 
										type="checkbox"
										checked={personEligibleForProfitSharing}
										onChange={(event) =>{
											setPersonEligibleForProfitSharing(event.target.checked)
										}}></input>
								</div>
							)}
							<div className="col">
								<div className="label">Department</div>
								<DepartmentDropdown
									defaultValue={departmentID}
									onChange={(departmentID) =>{
										setDepartmentID(departmentID);
									}}></DepartmentDropdown>
							</div>
							<div className="col">
								<div className="label">Birthday</div>
				        <DatePicker 
									className={"date"}
									disabled={false}
				          selected={birthday!=""?(new Date(birthday)):null}
				          onChange={(date:Date) =>{
				            if(date){
											let dateString = shortDate(date);//`${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
											setBirthday(dateString);
				            }else{
											setBirthday("");
										}
				          }}></DatePicker>
							</div>
							<div className="col">
								<div className="label">Start Date</div>
				        <DatePicker 
									className={"date"}
									disabled={false}
				          selected={startDate!=""?(new Date(startDate)):null}
				          onChange={(date:Date) =>{
				            if(date){
											let dateString = shortDate(date);//`${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
											setStartDate(dateString);
				            }else{
											setStartDate("");
										}
				          }}></DatePicker>
							</div>
							<div className="col">
								<div className="label">End Date</div>
				        <DatePicker 
									className={"date"}
									disabled={personActive}
				          selected={endDate!=""?(new Date(endDate)):null}
				          onChange={(date:Date) =>{
				            if(date){
											let dateString = shortDate(date);//`${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
											setEndDate(dateString);
				            }else{
											setEndDate("");
										}
				          }}></DatePicker>
							</div>
							{startDate!="" && (
								<div className="col">
									<div className="label">Employment Length</div>
									<div className="value">
										{endDate!=""?(
											Math.round((new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000*60*60*24*365) *10)/10 + " yrs"
										):(
											Math.round((new Date().getTime() - new Date(startDate).getTime()) / (1000*60*60*24*365) *10)/10 + " yrs"
										)}
									</div>
								</div>
							)}

							{organization.managesPTOAtOrganizationLevel == false && (
								<>
									<div className="col">
										<div className="label">PTO Hours<br/>Per Year</div>
										<input type='text'
											className="ptoPerYear"
											onKeyPress={(event)=>{forceDecimal(event)}} 
											defaultValue={prettyNum(PTOPerYear)}
											onFocus={(event)=>{
												let input = event.target as HTMLInputElement
												input.setSelectionRange(0,input.value.length)
											}}
											onBlur={(event) =>{
												if(event.target.value ==""){
													event.target.value = "0";
												}
												event.target.value = prettyNum(event.target.value)
												setPTOPerYear(parseFloat(event.target.value.replace("$","").replace(/,/g,"")))
											}}></input>
									</div>
									<div className="col">
										<div className="label">STO Hours<br/>Per Year</div>
										<input type='text'
											className="stoPerYear"
											onKeyPress={(event)=>{forceDecimal(event)}} 
											defaultValue={prettyNum(STOPerYear)}
											onFocus={(event)=>{
												let input = event.target as HTMLInputElement
												input.setSelectionRange(0,input.value.length)
											}}
											onBlur={(event) =>{
												if(event.target.value ==""){
													event.target.value = "0";
												}
												event.target.value = prettyNum(event.target.value)
												setSTOPerYear(parseFloat(event.target.value.replace("$","").replace(/,/g,"")))
											}}></input>
									</div>
									<div className='col'>
										<div className="label">PTO/STO<br/>Renewal Day</div>
										<DatePicker
											className="date"
											value={PTOSTORenewal?PTOSTORenewal+"/"+(new Date().getFullYear()):""}
											dateFormat={"MM/dd"}
											onChange={(date) => {
												if(date){
													let dateString = shortDate(date);
													// strip off slash year
													dateString = dateString.substring(0,dateString.length-5);
													setPTOSTORenewal(dateString);
												}else{
													setPTOSTORenewal("");
												}
											}}>
										</DatePicker>
									</div>
									<div className='col'>
										<div className="label">PTO<br/>Expiration Day</div>
										<DatePicker
											className="date"
											value={PTOExpiration?PTOExpiration+"/"+(new Date().getFullYear()):""}
											dateFormat={"MM/dd"}
											onChange={(date) => {
												if(date){
													let dateString = shortDate(date);
													// strip off slash year
													dateString = dateString.substring(0,dateString.length-5);
													setPTOExpiration(dateString);
												}else{
													setPTOExpiration("");
												}
											}}></DatePicker>
									</div>
									<div className='col'>
										<div className="label">STO<br/>Expiration Day</div>
										<DatePicker
											className="date"
											value={STOExpiration?STOExpiration+"/"+(new Date().getFullYear()):""}
											dateFormat={"MM/dd"}
											onChange={(date) => {
												if(date){
													let dateString = shortDate(date);
													// strip off slash year
													dateString = dateString.substring(0,dateString.length-5);
													setSTOExpiration(dateString);
												}else{
													setSTOExpiration("");
												}
											}}></DatePicker>
									</div>
								</>
							)}
						</div>
					</div>
					<div className="bottomArea">
						<h1>This Person's Role(s)</h1>
						<div className="roles">
							<div className="roleHeader role">
								<input type="checkbox"></input>
								<div className="roleName">Role Name</div>
								<div className="roleWeight">Weight (%)</div>
							</div>
							{organization.roleIDOrder.map((roleID) =>{
								let role = roles.find((role) => role.id == roleID);
								if(role){
									return (
										<div className="role">
											<input type="checkbox"
												checked={personRoles[role.id].checked}
												onChange={(event) =>{
													let newPersonRoles = {...personRoles};
													newPersonRoles[role!.id].checked = event.target.checked;
													setPersonRoles(newPersonRoles);
												}}></input>
											<div className={`roleName ${personRoles[role.id].checked?"":"disabled"}`}>{role.name}</div>
											<input type="text"
												className="roleWeight"
												defaultValue={Math.round(personRoles[role!.id].weight *100 ).toString() + "%"}
												disabled={!personRoles[role.id].checked}
												onFocus={(event) =>{
													event.target.select();
												}}
												onBlur={(event) =>{

													if(event.target.value ==""){
														event.target.value = "0";
													}

													event.target.value = Math.round(personRoles[role!.id].weight *100 ).toString() + "%"
												}}
												onKeyPress={(event) =>{
													forcePercentage(event);
													forceMaxValue(event,100)
												}}
												onChange={(event) =>{
													let newPersonRoles = {...personRoles};
													newPersonRoles[role!.id].weight = Math.round(parseFloat(event.target.value.replace(/,/g, '').replace("%","")))/100
													setPersonRoles(newPersonRoles);
												}}></input>
										</div>	
									)
								}else{
									return <></>
								}
							})}
						</div>

						<h1>This Person's Permission(s)</h1>
						<table className="permissions">
							<thead>
								<tr>
									<th>Read</th>
									<th>Write</th>
									<th>Permission</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<input type="checkbox"
											checked={permissionModifyOrganization}
											onChange={(event) =>{
												setPermissionModifyOrganization(event.target.checked)
											}}></input>
									</td>
									<td>
										<input type="checkbox"
											checked={permissionModifyOrganizationWrite}
											onChange={(event) =>{
												setPermissionModifyOrganizationWrite(event.target.checked)
											}}></input>
									</td>
									<td>Organization</td>
								</tr>

								<tr>
									<td>
										<input type="checkbox"
											checked={permissionModifyProjects}
											onChange={(event) =>{
												setPermissionModifyProjects(event.target.checked)
											}}></input>
									</td>
									<td>
										<input type="checkbox"
											checked={permissionModifyProjectsWrite}
											onChange={(event) =>{
												setPermissionModifyProjectsWrite(event.target.checked)
											}}></input>
									</td>
									<td>Projects</td>
								</tr>

								<tr>
									<td>
										<input type="checkbox"
											checked={permissionModifyTeam}
											onChange={(event) =>{
												setPermissionModifyTeam(event.target.checked)
											}}></input>
									</td>
									<td>
										<input type="checkbox"
											checked={permissionModifyTeamWrite}
											onChange={(event) =>{
												setPermissionModifyTeamWrite(event.target.checked)
											}}></input>
									</td>
									<td>Team</td>
								</tr>

								<tr>
									<td>
										<input type="checkbox"
											checked={permissionModifyClients}
											onChange={(event) =>{
												setPermissionModifyClients(event.target.checked)
											}}></input>
									</td>
									<td>
										<input type="checkbox"
											checked={permissionModifyClientsWrite}
											onChange={(event) =>{
												setPermissionModifyClientsWrite(event.target.checked)
											}}></input>
									</td>
									<td>Clients</td>
								</tr>

								<tr>
									<td>
										<input type="checkbox"
											checked={permissionModifyTimesheet}
											onChange={(event) =>{
												setPermissionModifyTimesheet(event.target.checked)
											}}></input>
									</td>
									<td>
										<input type="checkbox"
											checked={permissionModifyTimesheetWrite}
											onChange={(event) =>{
												setPermissionModifyTimesheetWrite(event.target.checked)
											}}></input>
									</td>
									<td>Personal Timesheet</td>
								</tr>

								<tr>
									<td>
										<input type="checkbox"
											checked={permissionModifyOrganizationTimesheets}
											onChange={(event) =>{
												setPermissionModifyOrganizationTimesheets(event.target.checked)
											}}></input>
									</td>
									<td>
										<input type="checkbox"
											checked={permissionModifyOrganizationTimesheetsWrite}
											onChange={(event) =>{
												setPermissionModifyOrganizationTimesheetsWrite(event.target.checked)
											}}></input>
									</td>
									<td>Organization Timesheets</td>
								</tr>

								{organization && organization.offersProfitSharing && (
									<tr>
										<td>
											<input type="checkbox"
												checked={permissionModifyProfitSharing}
												onChange={(event) =>{
													setPermissionModifyProfitSharing(event.target.checked)
												}}></input>
										</td>
										<td>
											<input type="checkbox"
												checked={permissionModifyProfitSharingWrite}
												onChange={(event) =>{
													setPermissionModifyProfitSharingWrite(event.target.checked)
												}}></input>
										</td>
										<td>Profit Sharing</td>
									</tr>
								)}

								<tr>
									<td>
										<input type="checkbox"
											checked={permissionProductionSchedule}
											onChange={(event) =>{
												setPermissionProductionSchedule(event.target.checked)
											}}></input>
									</td>
									<td>
										<input type="checkbox"
											checked={permissionProductionScheduleWrite}
											onChange={(event) =>{
												setPermissionProductionScheduleWrite(event.target.checked)
											}}></input>
									</td>
									<td>Production Schedule</td>
								</tr>

								<tr>
									<td>
										<input type="checkbox"
											checked={permissionReports}
											onChange={(event) =>{
												setPermissionReports(event.target.checked)
											}}></input>
									</td>
									<td>
										<input type="checkbox"
											checked={permissionReportsWrite}
											onChange={(event) =>{
												setPermissionReportsWrite(event.target.checked)
											}}></input>
									</td>
									<td>Reports</td>
								</tr>




								<tr>
									<td colSpan={2}></td>
									<td><b>Individual reports:</b></td>
								</tr>

								<tr>
									<td>
										<input type="checkbox"
											checked={permissionReportSoldVsBilled}
											onChange={(event) =>{
												setPermissionReportSoldVsBilled(event.target.checked)
											}}></input>
									</td>
									<td>
										<input type="checkbox"
											checked={permissionReportSoldVsBilledWrite}
											onChange={(event) =>{
												setPermissionReportSoldVsBilledWrite(event.target.checked)
											}}></input>
									</td>
									<td>Sold VS Billed Report</td>
								</tr>
								<tr>
									<td>
										<input type="checkbox"
											checked={permissionReportMonthlyBSP}
											onChange={(event) =>{
												setPermissionReportMonthlyBSP(event.target.checked)
											}}></input>
									</td>
									<td>
										<input type="checkbox"
											checked={permissionReportMonthlyBSPWrite}
											onChange={(event) =>{
												setPermissionReportMonthlyBSPWrite(event.target.checked)
											}}></input>
									</td>
									<td>Monthly BSP Report</td>
								</tr>
								<tr>
									<td>
										<input type="checkbox"
											checked={permissionReportAnnualBSP}
											onChange={(event) =>{
												setPermissionReportAnnualBSP(event.target.checked)
											}}></input>
									</td>
									<td>
										<input type="checkbox"
											checked={permissionReportAnnualBSPWrite}
											onChange={(event) =>{
												setPermissionReportAnnualBSPWrite(event.target.checked)
											}}></input>
									</td>
									<td>Annual BSP Report</td>
								</tr>
								{(organization && organization.offersProfitSharing) && (
									<tr>
										<td>
											<input type="checkbox"
												checked={permissionReportProfitSharing}
												onChange={(event) =>{
													setPermissionReportProfitSharing(event.target.checked)
												}}></input>
										</td>
										<td>
											<input type="checkbox"
												checked={permissionReportProfitSharingWrite}
												onChange={(event) =>{
													setPermissionReportProfitSharingWrite(event.target.checked)
												}}></input>
										</td>
										<td>Individual Profit Sharing Report</td>
									</tr>
								)}


							</tbody>
						</table>
					</div>
					{props.person && (
						<>
							<h1>Active Project Tasks</h1>
							<div className="bottomArea">
								<PersonActiveTasksTable 
									person={props.person} 
									onEditProject={(project: Project) =>{
										setSelectedProject(project);
										setShowingProjectPopup(true);
									} }></PersonActiveTasksTable>
							</div>

							<h1>Non Project Tasks</h1>
							<div className="bottomArea">
								<NonProjectTasksTable 
									nonProjectTasks={personNonProjectTasks}
									personID={props.person.id} 
									isReadOnly={permissionsWrite.includes("Team") == false}
									onNonProjectTasksChange={(nonProjectTasks) =>{
										setPersonNonProjectTasks(nonProjectTasks);
									}}></NonProjectTasksTable>
							</div>
						</>
						)}

				</div>
			)}

			<div className="floatingButtons">
				<div className="buttonCancel"
					onClick={() =>{
						props.onCancel();
					}}>Cancel</div>
				{permissionsWrite.includes("Team") && (
					<>
						{(props.allowMultiple && props.person==null)? (
		
							<DropdownButton 
							items={["Save & Close","Save & Add New"]} 
							selectedIndex={0} 
							executeFunction={(functionIndex: number) =>{
								switch(functionIndex){
									case 0:
										saveNewPerson().then((person) =>{
											props.onSave(person);
										}).catch((error) =>{
											alert(error);
										})	
										return;
									case 1:
										saveNewPerson().then((person) =>{
											clearForm();
										}).catch((error) =>{
											alert(error);
										});
										return;
								}
							} } ></DropdownButton>
						):(
							<div className="buttonSave"
								onClick={() =>{
									if(props.person){
										saveExistingPerson().then(() =>{
											props.onSave(props.person!);
										}).catch((error) =>{
											alert(error);
										})
									}else{
										saveNewPerson().then((person) =>{
											props.onSave(person);
										}).catch((error) =>{
											alert(error);
										})
									}
								}}>Save &amp; Close</div>
						)}
					</>
				)}
			</div>
		</div>

		{showingProjectPopup && (
				<PopupAddProject
					project={selectedProject}
					onCancel={() =>{
						setShowingProjectPopup(false);
					}}
					onSave={(project:Project) =>{
						setShowingProjectPopup(false);
					}}></PopupAddProject>
			)}
		</>
	)
}