
import React, { useEffect, useState } from "react"
import "./OrganizationScreenProjectStatusOptionsTable.css"
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import OrganizationScreenRolesRow, { OrganizationScreenRolesRowProps } from "../OrganizationScreenRolesRow/OrganizationScreenRolesRow";
import DraggableTableBody from "../../DraggableTableBody/DraggableTableBody";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { saveOrganizationAttributeChange } from "../../../Utils/SaveOrganizationFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { faBars, faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface OrganizationScreenProjectStatusOptionsTableProps{
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function OrganizationScreenProjectStatusOptionsTable(props:OrganizationScreenProjectStatusOptionsTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	let permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles,
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	const [projectStatusOptions, setProjectStatusOptions] = useState<string[]>([... organization.projectStatusList]);
	
	const [savingOptionsReorder, setSavingOptionsReorder] = useState(false);
	
	useEffect(() =>{
		setSavingOptionsReorder(false);
		setProjectStatusOptions([... organization.projectStatusList]);
	},[organization,organization.projectStatusList]);
	
	
  return (
    <table 
			className={`OrganizationScreenProjectStatusOptionsTable`}>
			{savingOptionsReorder == false && (
				<>
					<thead>
						<tr>
							<th></th>
							<th></th>
							<th colSpan={4}>Option Flags</th>
						</tr>
						<tr>
							<th></th>
							<th>Option Name</th>
							<th>bidding</th>
							<th>sold</th>
							<th>active</th>
							<th>completed</th>
						</tr>
					</thead>
					<DraggableTableBody
					childClassName=""
						keyForIndex={(index:number) =>{
							return `${projectStatusOptions[index]}`;
						}}
						orderedItems={organization.projectStatusList}
						onOrderUpdated={(newOrder:string[]) =>{

						}}
						onOrderUpdateEnded={(newOrder:string[]) =>{
							setProjectStatusOptions(newOrder);
							saveOrganizationAttributeChange(organization,"projectStatusList",newOrder,(entry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}}>
						{organization.projectStatusList.map((option:string, index) =>{
							return <>
								{permissionsWrite.includes("Organization") ?(
									<>
										<td><FontAwesomeIcon icon={faBars} style={{pointerEvents:"none", fontSize:"50%"}}></FontAwesomeIcon></td>
										<td>
											<input type='text' onChange={(e) =>{
												const newOptions = [...organization.projectStatusList];
												newOptions[index] = e.target.value;
												if(optionsTableTimeout != null){
													clearTimeout(optionsTableTimeout);
													optionsTableTimeout = null;
												}
												optionsTableTimeout = setTimeout(() =>{
													setProjectStatusOptions(newOptions);
													saveOrganizationAttributeChange(organization,"projectStatusList",newOptions,(entry) =>{
														AC.submitUndoStackEntry(entry);
													});
												},1000);
											}} defaultValue={projectStatusOptions[index]}></input>
										</td>
										<td>
											<input type='checkbox' onChange={(e) =>{
												const newOptions = {...organization.projectStatusCategories};
												if(e.target.checked){
													newOptions.bidding.push(option);
												}else{
													newOptions.bidding = newOptions.bidding.filter((item) => item != option);
												}
												if(optionsTableTimeout != null){
													clearTimeout(optionsTableTimeout);
													optionsTableTimeout = null;
												}
												optionsTableTimeout = setTimeout(() =>{
													saveOrganizationAttributeChange(organization,"projectStatusCategories",newOptions,(entry) =>{
														AC.submitUndoStackEntry(entry);
													});
												},1000);
											}} defaultChecked={organization.projectStatusCategories.bidding.includes(option)}></input>
										</td>
										<td>
											<input type='checkbox' onChange={(e) =>{
												const newOptions = {...organization.projectStatusCategories};
												if(e.target.checked){
													newOptions.sold.push(option);
												}else{
													newOptions.sold = newOptions.sold.filter((item) => item != option);
												}
												if(optionsTableTimeout != null){
													clearTimeout(optionsTableTimeout);
													optionsTableTimeout = null;
												}
												optionsTableTimeout = setTimeout(() =>{
													saveOrganizationAttributeChange(organization,"projectStatusCategories",newOptions,(entry) =>{
														AC.submitUndoStackEntry(entry);
													});
												},1000);
											}} defaultChecked={organization.projectStatusCategories.sold.includes(option)}></input>
										</td>
										<td>
											<input type='checkbox' onChange={(e) =>{
												const newOptions = {...organization.projectStatusCategories};
												if(e.target.checked){
													newOptions.active.push(option);
												}else{
													newOptions.active = newOptions.active.filter((item) => item != option);
												}
												if(optionsTableTimeout != null){
													clearTimeout(optionsTableTimeout);
													optionsTableTimeout = null;
												}
												optionsTableTimeout = setTimeout(() =>{
													saveOrganizationAttributeChange(organization,"projectStatusCategories",newOptions,(entry) =>{
														AC.submitUndoStackEntry(entry);
													});
												},1000);
											}} defaultChecked={organization.projectStatusCategories.active.includes(option)}></input>
										</td>
										<td>
											<input type='checkbox' onChange={(e) =>{
												const newOptions = {...organization.projectStatusCategories};
												if(e.target.checked){
													newOptions.completed.push(option);
												}else{
													newOptions.completed = newOptions.completed.filter((item) => item != option);
												}
												if(optionsTableTimeout != null){
													clearTimeout(optionsTableTimeout);
													optionsTableTimeout = null;
												}
												optionsTableTimeout = setTimeout(() =>{
													saveOrganizationAttributeChange(organization,"projectStatusCategories",newOptions,(entry) =>{
														AC.submitUndoStackEntry(entry);
													});
												},1000);
											}} defaultChecked={organization.projectStatusCategories.completed.includes(option)}></input>
										</td>
										<div className="buttonDelete"
											onClick={() =>{
												if(window.confirm("Are you sure you'd like to delete this option?")){
													const newOptions = [...organization.projectStatusList];
													newOptions.splice(index,1)
													if(optionsTableTimeout != null){
														clearTimeout(optionsTableTimeout);
														optionsTableTimeout = null;
													}
													setSavingOptionsReorder(true);
													optionsTableTimeout = setTimeout(() =>{
														setProjectStatusOptions(newOptions);
														saveOrganizationAttributeChange(organization,"projectStatusList",newOptions,(entry) =>{
															AC.submitUndoStackEntry(entry);
														});
													},1000);
												}
											}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
									</>
								):(
									<>
										<td></td>
										<td>{projectStatusOptions[index]}</td>
										<td>
											{organization.projectStatusCategories.bidding.includes(option)&&<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}
										</td>
										<td>
											{organization.projectStatusCategories.sold.includes(option)&&<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}
										</td>
										<td>
											{organization.projectStatusCategories.active.includes(option)&&<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}
										</td>
										<td>
											{organization.projectStatusCategories.completed.includes(option)&&<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}
										</td>
									</>
								)}
							</>
						})}
					</DraggableTableBody>
				</>
			)}
    </table>
  )
}