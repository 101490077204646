import { collection, deleteDoc, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where } from "firebase/firestore";
import { Organization } from "../FirebaseModel/Organization";
import { Project } from "../FirebaseModel/Project";
import { Role } from "../FirebaseModel/Role";
import { saveOrganizationAttributeChange } from "./SaveOrganizationFunctions";
import { sharedFirestore } from "./SharedFirebase";
import { UndoStackEntry, UndoStackEntryType, UndoStackEntryObjectType } from "./UndoStackEntry";

export function saveRoleAttributeChange(role:Role,  attributeKey:string|string[], value:any|any[],undoEntryHandler:(entry:UndoStackEntry)=>void){


	const projectRef = doc(sharedFirestore, "Role", role.id);

	let updatedRole:{ [x: string]: any; } = {};
	for(var key in role){
		if(attributeKey instanceof Array){
			for(var key2 in attributeKey){
				if(key == attributeKey[key2]){
					updatedRole[key] = value[key2];
					break;
				}else{
					updatedRole[key] = (role as any)[key];
				}
			}
		}else{
			if(attributeKey){
				if(key == attributeKey){
					updatedRole[key] = value;
				}else{
					updatedRole[key] = (role as any)[key];
				}
			}else{
				updatedRole[key] = (role as any)[key];
			}
		}
	}
	let undoEntry:UndoStackEntry = {
		type: UndoStackEntryType.update,
		objectType: UndoStackEntryObjectType.role,
		objectID: role.id,
		objectBeforeState: {...role},
		objectAfterState: {...updatedRole},
		description: "Update Role"
	}
	undoEntryHandler(undoEntry);

	return updateDoc(projectRef,updatedRole)
}

function deleteRoleFromAllProjects(role: Role,undoEntryHandler:(entry:UndoStackEntry)=>void) {
	return new Promise((resolve:(result:string)=>void) =>{
		let q = query(collection(sharedFirestore, "Project"),where("roleIDs", "array-contains", role.id));
		getDocs(q).then((querySnapshot: QuerySnapshot<DocumentData>) => {
			querySnapshot.forEach(async (doc) => {
				let project = doc.data();
				let newProject = {...project, id:doc.id} as any;
				newProject.roleIDs = project.roleIDs.filter((roleID:string) => roleID != role.id);
				let totalPlannedHours = 0;
				for(var i = 0; i < newProject.plannedHours.length; i++){
					if(newProject.plannedHours[i].roleID == role.id){
						newProject.plannedHours.splice(i,1);
						i--;
					}else{
						totalPlannedHours += newProject.plannedHours[i].hours;
					}
				}
				newProject.calculated.plannedHours = totalPlannedHours;

				let undoEntry:UndoStackEntry = {
					type: UndoStackEntryType.update,
					objectType: UndoStackEntryObjectType.project,
					objectID: newProject.id,
					objectBeforeState: {...project},
					objectAfterState: {...newProject},
					description: "Update Project"
				}
				undoEntryHandler(undoEntry);
				await updateDoc(doc.ref,newProject);
			})
			resolve("success");
		})
	})
}

export function deleteRole(role:Role, organization:Organization,undoEntryHandler:(entry:UndoStackEntry)=>void){
	return new Promise((resolve,reject) =>{

		deleteDoc(doc(sharedFirestore, "Role", role.id)).then(() =>{
			let undoEntry:UndoStackEntry = {
				type: UndoStackEntryType.batchEnd,
				objectType: UndoStackEntryObjectType.role,
				objectID: role.id,
				objectBeforeState: {...role},
				objectAfterState: undefined,
				description: "Delete Role"
			}
			undoEntryHandler(undoEntry);
			resolve("success");


		// let undoEntry:UndoStackEntry = {
		// 	type: UndoStackEntryType.batchStart,
		// 	objectType: UndoStackEntryObjectType.role,
		// 	objectID: role.id,
		// 	objectBeforeState: {...role},
		// 	objectAfterState: undefined,
		// 	description: "Delete Role"
		// }
		// undoEntryHandler(undoEntry);
		// deleteRoleFromAllProjects(role,undoEntryHandler).then(() =>{
		// 	let newRoleIDOrder = [...organization.roleIDOrder]
		// 	if(newRoleIDOrder.indexOf(role.id) > -1){
		// 		newRoleIDOrder.splice(newRoleIDOrder.indexOf(role.id),1);
		// 	}
		// 	saveOrganizationAttributeChange(organization,"roleIDOrder",newRoleIDOrder,undoEntryHandler)
		// 		.then(() =>{
		// 			let undoEntry:UndoStackEntry = {
		// 				type: UndoStackEntryType.delete,
		// 				objectType: UndoStackEntryObjectType.role,
		// 				objectID: role.id,
		// 				objectBeforeState: {...role},
		// 				objectAfterState: undefined,
		// 				description: "Delete Role"
		// 			}
		// 			undoEntryHandler(undoEntry);
		// 			deleteDoc(doc(sharedFirestore, "Role", role.id)).then(() =>{
		// 				let undoEntry:UndoStackEntry = {
		// 					type: UndoStackEntryType.batchEnd,
		// 					objectType: UndoStackEntryObjectType.role,
		// 					objectID: role.id,
		// 					objectBeforeState: {...role},
		// 					objectAfterState: undefined,
		// 					description: "Delete Role"
		// 				}
		// 				undoEntryHandler(undoEntry);
		// 				resolve("success");
		// 			});		
		// 		});	
		}).catch((error: any) =>{
			reject(error)
		});
	});
}
