import { FC, useEffect, useState } from "react";
import { User } from "firebase/auth";
import { sharedFirebaseAuth } from "../Utils/SharedFirebase";
import { AuthContext } from "./AuthContext";
import { GoogleOAuthProvider } from 'react-oauth-google';


export interface AuthProviderProps {
    children: JSX.Element;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);

	// Your clientId retrieved from Google Developer Console
	const clientId = '355666971172-ppoe7vd9ki5hc4n18n3rbm25v57m8i7j.apps.googleusercontent.com'; 
  
  useEffect(() => {
    const unsubscribe = sharedFirebaseAuth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });
    return unsubscribe
  }, []);

  return (
    <AuthContext.Provider value={user}>
      <GoogleOAuthProvider
        
        clientId={clientId}
        onScriptLoadSuccess={() =>{         
          console.log("script loaded") 
        }}
        onScriptLoadError={() =>{
          console.log("script load error")
        }}>{children}</GoogleOAuthProvider>
    </AuthContext.Provider>
  );
};