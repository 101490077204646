
import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where } from "firebase/firestore";
import "./ProjectTypeDropdown.css"
import { useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { forceNumeric, prettyCurrency, prettyNum } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import { Client } from "../../FirebaseModel/Client";
import { Department } from "../../FirebaseModel/Department";
interface ProjectTypeDropdownProps {
	className?: string,
	defaultValue?: string,
	prompt?: string,
	onChange?: (projectType: string) => void
}

export default function ProjectTypeDropdown(props: ProjectTypeDropdownProps) {

	const organization = useSelector((state: { organization: Organization }) => state.organization)

	const [projectTypeList, setProjectTypeList] = useState<string[]>([]);
	const [selectedValue, setSelectedValue] = useState(props.defaultValue);

	useEffect(() => {
		if (organization) {
			setProjectTypeList([...organization.projectTypeList]);
		}
	}, [organization])

	useEffect(() => {
		setSelectedValue(props.defaultValue);
	}, [props.defaultValue])

	return (
		<select
			className={`ProjectTypeDropdown ${props.className ?? ""}`}
			value={selectedValue}
			onChange={(event) => {
				setSelectedValue(event.target.value);
				if (props.onChange) {
					props.onChange(event.target.value);
				}
			}}>
			<option value={""}>{props.prompt ?? "Select Project Type"}</option>
			{projectTypeList.map((option: string, index: number) => {
				return <option key={"project_type_option_" + index} value={option}>{option}</option>
			})}
		</select>
	)
}