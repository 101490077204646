
import React, { useEffect, useState } from "react"
import "./ProductionScheduleNonProjectTaskScheduleBlockDay.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { faCheck, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProjectTaskScheduleBlock } from "../../../FirebaseModel/Project";
import { shortDate } from "../../../Utils/formatting";
import { PersonNonProjectTaskScheduleBlock } from "../../../FirebaseModel/Person";

interface ProductionScheduleNonProjectTaskScheduleBlockDayProps{
	nonProjectTaskScheduleBlock:PersonNonProjectTaskScheduleBlock;
	day:Date;
	onEditNonProjectTaskScheduleBlock:(nonProjectTaskScheduleBlock:PersonNonProjectTaskScheduleBlock) => void;
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function ProductionScheduleNonProjectTaskScheduleBlockDay(props:ProductionScheduleNonProjectTaskScheduleBlockDayProps){	
	// starting from the start date use the props.date to get the index for hours per day to show;
	let tempDay = new Date(props.nonProjectTaskScheduleBlock.startDate);
	let dayIndex = 0;
	while(tempDay < props.day){
		dayIndex++;
		tempDay.setDate(tempDay.getDate() + 1);
	}
	let hours = props.nonProjectTaskScheduleBlock.dailyPlannedHours[dayIndex];
  return (
    <div className={`ProductionScheduleNonProjectTaskScheduleBlockDay hours${hours} ${(shortDate(tempDay)==props.nonProjectTaskScheduleBlock.startDate)?"firstDay":""} ${(shortDate(tempDay)==props.nonProjectTaskScheduleBlock.endDate)?"lastDay":""}`}
			onClick={()=>{
				props.onEditNonProjectTaskScheduleBlock(props.nonProjectTaskScheduleBlock);
			}}>
    </div>
  )
}