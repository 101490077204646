

import React, { useEffect, useState } from "react"
import "./ProductionSchedulePTORow.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Client } from "../../../FirebaseModel/Client";
import { ClientContact } from "../../../FirebaseModel/ClientContact";
import { Organization, holiday } from "../../../FirebaseModel/Organization";
import { Person } from "../../../FirebaseModel/Person";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { addDoc, collection } from "firebase/firestore";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { UndoStackEntryType, UndoStackEntryObjectType } from "../../../Utils/UndoStackEntry";
import { Tooltip } from "@mui/material";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shortDate } from "../../../Utils/formatting";
interface ProductionSchedulePTORowProps{
	person:Person;
	numMonths:number;
	collapsed:boolean;
	departmentCollapsed:boolean;
	onScheduleTimeOff:(timeOff:TimeOff, date:string) => void;
}


export default function ProductionSchedulePTORow(props:ProductionSchedulePTORowProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const clientContacts = useSelector((state:{clientContacts:ClientContact[]}) => state.clientContacts)
	const timeOffList = useSelector((state:{timeOffList:TimeOff[]}) => state.timeOffList)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles,
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	const [months, setMonths] = useState<Date[]>([]);
	const [days, setDays] = useState<Date[]>([]);

	const [PTOLookup, setPTOLookup] = useState<{[year:string]:TimeOff}>({});

	const today = new Date();

	const createOrGetTimeOff = async (year:string) =>{
		let timeOff = timeOffList.find(timeOff => timeOff.personID == props.person.id && timeOff.year == parseInt(year));
		if(timeOff == null){
			let allottedPTO = props.person.PTOPerYear;
			let allottedSTO = props.person.STOPerYear;
			if(organization.managesPTOAtOrganizationLevel){
				allottedPTO = organization.PTOPerYear;
				allottedSTO = organization.STOPerYear;
			}


			let timeOffDocData = {
				organizationID:organizationID,
				personID:props.person.id,
				year:parseInt(year),
				hoursTakenSTO:[],
				hoursTakenPTO:[],
				allottedPTO:allottedPTO,
				allottedSTO:allottedSTO,
				calculated:{
					totalHoursTakenPTO:0,
					totalHoursTakenSTO:0,
				}
			};
			let docref = await addDoc(collection(sharedFirestore,"TimeOff"),timeOffDocData);
			timeOff = {... timeOffDocData, id:docref.id} as TimeOff;

			AC.submitUndoStackEntry({
				type: UndoStackEntryType.create,
				objectType: UndoStackEntryObjectType.timeOff,
				objectID: timeOff.id,
				objectBeforeState: undefined,
				objectAfterState: {...timeOff},
				description: 'Create Time Off'
			})
		}
		return timeOff
	}
	
	useEffect(() =>{
		let months:Date[] = [];
		let date = new Date();
		for(let i = 0; i < props.numMonths; i++){
			months.push(new Date(date.getFullYear(), date.getMonth() + i, 1));
		}
		setMonths(months);

		// add every day from months into the days array
		let days:Date[] = [];
		months.forEach(month =>{
			let date = new Date(month);
			while(date.getMonth() === month.getMonth()){
				days.push(new Date(date));
				date.setDate(date.getDate() + 1);
			}
		})
		setDays(days);

		const buildPTOLookup = async () =>{
			let newPTOLookup:{[year:string]:TimeOff} = {};
			for(var i  in days){
				let year = days[i].getFullYear().toString();
				if(!newPTOLookup[year]){
					newPTOLookup[year] = await createOrGetTimeOff(year);
				}
			}
			setPTOLookup(newPTOLookup);
		}
		buildPTOLookup();
	},[props.numMonths])

  return (
		<tr className={`ProductionSchedulePTORow ${(props.departmentCollapsed||props.collapsed)?"collapsed":""}`}>
			<td>PTO</td>
			<td></td>
			<td></td>
			<td></td>
			<td></td>
			<td></td>
			{days.map((day, index) =>{
				// add a header with the single letter day of the week
				let h = holiday(shortDate(day),organization)
				let isToday = day.getDate() == today.getDate() && day.getMonth() == today.getMonth() && day.getFullYear() == today.getFullYear();
				let isWeekend = day.getDay() === 0 || day.getDay() === 6;
				let timeOffHours = 0;
				let timeOff:TimeOff|undefined = undefined;
				if(PTOLookup[day.getFullYear().toString()]){
					timeOff = PTOLookup[day.getFullYear().toString()];
					if(timeOff){
						timeOffHours = timeOff.hoursTakenPTO.find(hoursTakenPTO => hoursTakenPTO.date == day.toLocaleDateString())?.hours??0;
					}
				}
				return <td key={index} className={`day ${isToday?"today":""} ${isWeekend?"weekend":""} hours${timeOffHours} ${h?"holiday":""}`}>
					{timeOff && (
						<Tooltip title="Schedule Time Off" placement="bottom" enterDelay={500}>
							<div className='buttonAdd'
								onClick={() =>{
									props.onScheduleTimeOff(timeOff!, day.toLocaleDateString());
								}}>
									<FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
								</div>
						</Tooltip>
					)}
				</td>
			})}
		</tr>
  )
}