

import React, { ReactNode, useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./OrganizationScreenDepartmentsRow.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { Role, rolePersonsDescription } from "../../../FirebaseModel/Role";
import { Person } from "../../../FirebaseModel/Person";
import OrganizationScreenRolesInput from "../OrganizationScreenRolesInput/OrganizationScreenRolesInput";
import { deleteRole, saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { faAnchor, faBars, faCircleNodes, faHamburger, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Department, departmentPersonsDescription } from "../../../FirebaseModel/Department";
import { deleteDepartment } from "../../../Utils/SaveDepartmentFunctions";
import OrganizationScreenDepartmentsInput from "../OrganizationScreenDepartmentsInput/OrganizationScreenDepartmentsInput";
import { Tooltip } from "@mui/material";
export interface OrganizationScreenDepartmentsRowProps{
	department:Department;
}

export default function OrganizationScreenDepartmentsRow(props:OrganizationScreenDepartmentsRowProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const allDepartments = useSelector((state:{departments:Department[]}) => state.departments)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)


	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);
		
	const [roles, setRoles] = useState<Department[]>(allDepartments);

	useEffect(() =>{
		setRoles([... allDepartments]);
	},[allDepartments]);
	
	return (
		<>
			{permissionsWrite.includes("Organization") ?(
				<>
					<td><FontAwesomeIcon icon={faBars} style={{pointerEvents:"none", fontSize:"50%"}}></FontAwesomeIcon></td>
					<td><OrganizationScreenDepartmentsInput attributeKey="name" department={props.department}></OrganizationScreenDepartmentsInput></td>
					<td><OrganizationScreenDepartmentsInput attributeKey="abbreviation" department={props.department}></OrganizationScreenDepartmentsInput></td>
					{/* <td>
						<input type='checkbox' checked={role.useOverrideHourlyRate} onChange={(event) =>{
							saveRoleAttributeChange(role,"useOverrideHourlyRate",event.target.checked)
						}}></input>
					</td>
					<td><OrganizationScreenRolesInput attributeKey="overrideHourlyRate" className={`${role.useOverrideHourlyRate?"":"disabled"}`} forceNumeric={true} isCurrency={true} role={role}></OrganizationScreenRolesInput></td> */}
					<td className="departmentPeople">
							<Tooltip title={departmentPersonsDescription(props.department,persons)} placement="bottom">
								<div>{departmentPersonsDescription(props.department,persons)}</div>
							</Tooltip>
						</td>
					<div className="buttonDelete"
						onClick={() =>{
							if(window.confirm("Are you sure you'd like to delete this department?")){
								deleteDepartment(props.department, organization,(entry) =>{
									AC.submitUndoStackEntry(entry);
								});
							}
						}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
				</>
			):(
				<>
					<td></td>
					<td>{props.department.name}</td>
					<td>{props.department.abbreviation}</td>
					{/* <td>
						<input type='checkbox' checked={role.useOverrideHourlyRate} onChange={(event) =>{
							saveRoleAttributeChange(role,"useOverrideHourlyRate",event.target.checked)
						}}></input>
					</td>
					<td><OrganizationScreenRolesInput attributeKey="overrideHourlyRate" className={`${role.useOverrideHourlyRate?"":"disabled"}`} forceNumeric={true} isCurrency={true} role={role}></OrganizationScreenRolesInput></td> */}
					<td className="departmentPeople">
							<Tooltip title={departmentPersonsDescription(props.department,persons)} placement="bottom">
								<div>{departmentPersonsDescription(props.department,persons)}</div>
							</Tooltip>
						</td>
				</>
			)}
		</>
  )
}