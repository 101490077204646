import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { Client } from "../FirebaseModel/Client";
import { Project } from "../FirebaseModel/Project";
import { Role } from "../FirebaseModel/Role";
import { saveProjectAttributeChange } from "./SaveProjectFunctions";
import { sharedFirestore } from "./SharedFirebase";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../Redux/ActionCreators";
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from "./UndoStackEntry";
import { Organization } from "../FirebaseModel/Organization";

export function saveClientAttributeChange(client:Client,  attributeKey:string|string[], value:any|any[],undoEntryHandler:(entry:UndoStackEntry)=>void){


	const projectRef = doc(sharedFirestore, "Client", client.id);

	let beforeEntry = {...client};

	let updatedClient:{ [x: string]: any; } = {};
	for(var key in client){
		if(attributeKey instanceof Array){
			for(var key2 in attributeKey){
				if(key == attributeKey[key2]){
					updatedClient[key] = value[key2];
					break;
				}else{
					updatedClient[key] = (client as any)[key];
				}
			}
		}else{
			if(attributeKey){
				if(key == attributeKey){
					updatedClient[key] = value;
				}else{
					updatedClient[key] = (client as any)[key];
				}
			}else{
				updatedClient[key] = (client as any)[key];
			}
		}
	}

	let undoEntry:UndoStackEntry = {
		type: UndoStackEntryType.update,
		objectType: UndoStackEntryObjectType.client,
		objectID: client.id,
		objectBeforeState: beforeEntry,
		objectAfterState: {...updatedClient},
		description: "Update Client"
	}
	undoEntryHandler(undoEntry);
	return updateDoc(projectRef,updatedClient)
}



export async function deleteClient(client:Client,projects:Project[],organization:Organization,clients:Client[],undoEntryHandler:(entry:UndoStackEntry)=>void){
	undoEntryHandler({
		type: UndoStackEntryType.batchStart,
		objectType: UndoStackEntryObjectType.nonApplicable,
		objectID: client.id,
		objectBeforeState: {...client},
		objectAfterState: undefined,
		description: "Delete Client"
	})
	for(var i in projects){
		let project = projects[i];
		if(project.clientID == client.id){
			
			await saveProjectAttributeChange(project,organization,clients,"clientID","",undoEntryHandler);
		}
	}
	undoEntryHandler({
		type: UndoStackEntryType.delete,
		objectType: UndoStackEntryObjectType.client,
		objectID: client.id,
		objectBeforeState: {...client},
		objectAfterState: undefined,
		description: "Delete Client"
	})
	undoEntryHandler({
		type: UndoStackEntryType.batchEnd,
		objectType: UndoStackEntryObjectType.nonApplicable,
		objectID: client.id,
		objectBeforeState: {...client},
		objectAfterState: undefined,
		description: "Delete Client"
	})
	return deleteDoc(doc(sharedFirestore, "Client", client.id));
}