import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { sharedFirestore } from "./SharedFirebase";

export enum UndoStackEntryType{
	create,
	delete,
	update,
	batchStart,
	batchEnd
}

export enum UndoStackEntryObjectType{
	client,
	clientContact,
	monthTotal,
	organization,
	person,
	project,
	actual,
	role,
	view,
  department,
	timeOff,
	yearTotal,
	nonApplicable
}

export interface UndoStackEntry{
	type:UndoStackEntryType;
	objectType:UndoStackEntryObjectType;
	objectID:string;
	objectBeforeState:any;
	objectAfterState:any;
	description:string;
}


export async function PerformUndo(stack:UndoStackEntry[], stackIndex:number){
	let i  = stackIndex-1;
	if(i < 0) return stackIndex;
	let entry = stack[i];
	switch(entry.type){
		case UndoStackEntryType.create:
			await UndoCreate(entry);
			break;
		case UndoStackEntryType.delete:
			await UndoDelete(entry);
			break;
		case UndoStackEntryType.update:
			await UndoUpdate(entry);
			break;
		case UndoStackEntryType.batchStart:
			break;
		case UndoStackEntryType.batchEnd:
			let batchEntry = stack[i];
			while(batchEntry.type != UndoStackEntryType.batchStart){
				await PerformUndo(stack, i);
				i--;
				batchEntry = stack[i];
			}
			break;
	}
	return i;
}

export async function PerformRedo(stack:UndoStackEntry[], stackIndex:number){
	let i  = stackIndex;
	if(i >= stack.length) return stackIndex;
	let entry = stack[i];
	switch(entry.type){
		case UndoStackEntryType.create:
			await RedoCreate(entry);
			break;
		case UndoStackEntryType.delete:
			await RedoDelete(entry);
			break;
		case UndoStackEntryType.update:
			await RedoUpdate(entry);
			break;
		case UndoStackEntryType.batchStart:
			i++;
			let batchEntry = stack[i];
			while(batchEntry.type != UndoStackEntryType.batchEnd){
				await PerformRedo(stack, i);
				i++;
				batchEntry = stack[i];
			}
			break;
		case UndoStackEntryType.batchEnd:
			break;
	}
	i++;
	return i;
}


async function UndoCreate(entry:UndoStackEntry){
	await RedoDelete(entry);
}
async function UndoDelete(entry:UndoStackEntry){
	switch(entry.objectType){
		case UndoStackEntryObjectType.client:
			const clientRef = doc(sharedFirestore, "Client", entry.objectID);
			await setDoc(clientRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.clientContact:
			const clientContactRef = doc(sharedFirestore, "ClientContact", entry.objectID);
			await setDoc(clientContactRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.monthTotal:
			const monthTotalRef = doc(sharedFirestore, "MonthTotal", entry.objectID);
			await setDoc(monthTotalRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.organization:
			break;
		case UndoStackEntryObjectType.person:
			const personRef = doc(sharedFirestore, "Person", entry.objectID);
			await setDoc(personRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.project:
			const projectRef = doc(sharedFirestore, "Project", entry.objectID);
			await setDoc(projectRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.view:
			const viewRef = doc(sharedFirestore, "View", entry.objectID);
			await setDoc(viewRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.actual:
			const projectRoleActualHoursRef = doc(sharedFirestore, "ProjectRoleActualHours", entry.objectID);
			await setDoc(projectRoleActualHoursRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.role:
			const roleRef = doc(sharedFirestore, "Role", entry.objectID);
			await setDoc(roleRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.role:
			const departmentRef = doc(sharedFirestore, "Department", entry.objectID);
			await setDoc(departmentRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.timeOff:
			const timeOffRef = doc(sharedFirestore, "TimeOff", entry.objectID);
			await setDoc(timeOffRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.yearTotal:
			break;
		case UndoStackEntryObjectType.nonApplicable:
			break;
	}
}
async function UndoUpdate(entry:UndoStackEntry){
	switch(entry.objectType){
		case UndoStackEntryObjectType.client:
			const clientRef = doc(sharedFirestore, "Client", entry.objectID);
			await updateDoc(clientRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.clientContact:
			const clientContactRef = doc(sharedFirestore, "ClientContact", entry.objectID);
			await updateDoc(clientContactRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.monthTotal:
			const monthTotalRef = doc(sharedFirestore, "MonthTotal", entry.objectID);
			await updateDoc(monthTotalRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.organization:
			break;
		case UndoStackEntryObjectType.person:
			const personRef = doc(sharedFirestore, "Person", entry.objectID);
			await updateDoc(personRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.project:
			const projectRef = doc(sharedFirestore, "Project", entry.objectID);
			await updateDoc(projectRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.view:
			const viewRef = doc(sharedFirestore, "View", entry.objectID);
			await updateDoc(viewRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.actual:
			const projectRoleActualHoursRef = doc(sharedFirestore, "ProjectRoleActualHours", entry.objectID);
			await updateDoc(projectRoleActualHoursRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.role:
			const roleRef = doc(sharedFirestore, "Role", entry.objectID);
			await updateDoc(roleRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.department:
			const departmentRef = doc(sharedFirestore, "Department", entry.objectID);
			await updateDoc(departmentRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.timeOff:
			const timeOffRef = doc(sharedFirestore, "TimeOff", entry.objectID);
			await updateDoc(timeOffRef, entry.objectBeforeState);
			break;
		case UndoStackEntryObjectType.yearTotal:
			break;
		case UndoStackEntryObjectType.nonApplicable:
			break;
	}

}

async function RedoCreate(entry:UndoStackEntry){
	switch(entry.objectType){
		case UndoStackEntryObjectType.client:
			const clientRef = doc(sharedFirestore, "Client", entry.objectID);
			await setDoc(clientRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.clientContact:
			const clientContactRef = doc(sharedFirestore, "ClientContact", entry.objectID);
			await setDoc(clientContactRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.monthTotal:
			const monthTotalRef = doc(sharedFirestore, "MonthTotal", entry.objectID);
			await setDoc(monthTotalRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.organization:
			break;
		case UndoStackEntryObjectType.person:
			const personRef = doc(sharedFirestore, "Person", entry.objectID);
			await setDoc(personRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.project:
			const projectRef = doc(sharedFirestore, "Project", entry.objectID);
			await setDoc(projectRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.view:
			const viewRef = doc(sharedFirestore, "View", entry.objectID);
			await setDoc(viewRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.actual:
			const projectRoleActualHoursRef = doc(sharedFirestore, "ProjectRoleActualHours", entry.objectID);
			await setDoc(projectRoleActualHoursRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.role:
			const roleRef = doc(sharedFirestore, "Role", entry.objectID);
			await setDoc(roleRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.department:
			const departmentRef = doc(sharedFirestore, "Department", entry.objectID);
			await setDoc(departmentRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.timeOff:
			const timeOffRef = doc(sharedFirestore, "TimeOff", entry.objectID);
			await setDoc(timeOffRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.yearTotal:
			break;
		case UndoStackEntryObjectType.nonApplicable:
			break;
	}
	
}
async function RedoDelete(entry:UndoStackEntry){
	switch(entry.objectType){
		case UndoStackEntryObjectType.client:
			const clientRef = doc(sharedFirestore, "Client", entry.objectID);
			await deleteDoc(clientRef);
			break;
		case UndoStackEntryObjectType.clientContact:
			const clientContactRef = doc(sharedFirestore, "ClientContact", entry.objectID);
			await deleteDoc(clientContactRef);
			break;
		case UndoStackEntryObjectType.monthTotal:
			const monthTotalRef = doc(sharedFirestore, "MonthTotal", entry.objectID);
			await deleteDoc(monthTotalRef);
			break;
		case UndoStackEntryObjectType.organization:
			break;
		case UndoStackEntryObjectType.person:
			const personRef = doc(sharedFirestore, "Person", entry.objectID);
			await deleteDoc(personRef);
			break;
		case UndoStackEntryObjectType.project:
			const projectRef = doc(sharedFirestore, "Project", entry.objectID);
			await deleteDoc(projectRef);
			break;
			case UndoStackEntryObjectType.view:
				const viewRef = doc(sharedFirestore, "View", entry.objectID);
				await deleteDoc(viewRef);
				break;
		case UndoStackEntryObjectType.actual:
			const projectRoleActualHoursRef = doc(sharedFirestore, "ProjectRoleActualHours", entry.objectID);
			await deleteDoc(projectRoleActualHoursRef);
			break;
		case UndoStackEntryObjectType.role:
			const roleRef = doc(sharedFirestore, "Role", entry.objectID);
			await deleteDoc(roleRef);
			break;
		case UndoStackEntryObjectType.department:
			const departmentRef = doc(sharedFirestore, "Department", entry.objectID);
			await deleteDoc(departmentRef);
			break;
		case UndoStackEntryObjectType.timeOff:
			const timeOffRef = doc(sharedFirestore, "TimeOff", entry.objectID);
			await deleteDoc(timeOffRef);
			break;
		case UndoStackEntryObjectType.yearTotal:
			break;
		case UndoStackEntryObjectType.nonApplicable:
			break;
	}
	
}
async function RedoUpdate(entry:UndoStackEntry){
	switch(entry.objectType){
		case UndoStackEntryObjectType.client:
			const clientRef = doc(sharedFirestore, "Client", entry.objectID);
			await updateDoc(clientRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.clientContact:
			const clientContactRef = doc(sharedFirestore, "ClientContact", entry.objectID);
			await updateDoc(clientContactRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.monthTotal:
			const monthTotalRef = doc(sharedFirestore, "MonthTotal", entry.objectID);
			await updateDoc(monthTotalRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.organization:
			break;
		case UndoStackEntryObjectType.person:
			const personRef = doc(sharedFirestore, "Person", entry.objectID);
			await updateDoc(personRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.project:
			const projectRef = doc(sharedFirestore, "Project", entry.objectID);
			await updateDoc(projectRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.view:
			const viewRef = doc(sharedFirestore, "View", entry.objectID);
			await updateDoc(viewRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.actual:
			const projectRoleActualHoursRef = doc(sharedFirestore, "ProjectRoleActualHours", entry.objectID);
			await updateDoc(projectRoleActualHoursRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.role:
			const roleRef = doc(sharedFirestore, "Role", entry.objectID);
			await updateDoc(roleRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.department:
			const departmentRef = doc(sharedFirestore, "Department", entry.objectID);
			await updateDoc(departmentRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.timeOff:
			const timeOffRef = doc(sharedFirestore, "TimeOff", entry.objectID);
			await updateDoc(timeOffRef, entry.objectAfterState);
			break;
		case UndoStackEntryObjectType.yearTotal:
			break;
		case UndoStackEntryObjectType.nonApplicable:
			break;
	}
	
}

