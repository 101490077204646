
import React, { useEffect, useState } from "react"
import "./OrganizationScreenDepartmentsTable.css"
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import OrganizationScreenRolesRow, { OrganizationScreenRolesRowProps } from "../OrganizationScreenRolesRow/OrganizationScreenRolesRow";
import DraggableTableBody from "../../DraggableTableBody/DraggableTableBody";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { saveOrganizationAttributeChange } from "../../../Utils/SaveOrganizationFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Department } from "../../../FirebaseModel/Department";
import OrganizationScreenDepartmentsRow from "../OrganizationScreenDepartmentsRow/OrganizationScreenDepartmentsRow";

interface OrganizationScreenDepartmentsTableProps{
}




export default function OrganizationScreenDepartmentsTable(props:OrganizationScreenDepartmentsTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)

	const allDepartments = useSelector((state:{departments:Department[]}) => state.departments)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setDepartments:ActionCreators.setDepartments,
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);


	const [departments, setDepartments] = useState<Department[]>([... allDepartments]);

	useEffect(() =>{
		setDepartments([... allDepartments]);
		console.log("departments updated");
		setSavingDepartmentsReorder(false);
	},[allDepartments,organization.departmentIDOrder]);

	const[savingDepartmentsReorder, setSavingDepartmentsReorder] = useState(false);
	
  return (
    <table 
			className={`OrganizationScreenDepartmentsTable`}>
			<thead>
				<tr>
					<th></th>
					<th>Name</th>
					<th>Abbrev.</th>
					{/* <th>Use<br></br>Custom<br></br>Rate?</th>
					<th>Hourly Rate</th> */}
					<th>People</th>

				</tr>
			</thead>
			{savingDepartmentsReorder == false && (
				<DraggableTableBody
					childClassName="OrganizationScreenDepartmentsRow"
					keyForIndex={(index:number) =>{
						if(departments[index]){
							return `department_${departments[index].id}`;
						}
						return `department_${index}`;
					}}
					orderedItems={organization.departmentIDOrder}
					onOrderUpdated={(newOrder:string[]) =>{

					}}
					onOrderUpdateEnded={(newOrder:string[]) =>{
						const newDepartments = [...departments];
						newDepartments.sort((a,b) =>{
							return newOrder.indexOf(a.id) - newOrder.indexOf(b.id);
						});
						saveOrganizationAttributeChange(organization, "departmentIDOrder", newOrder,(entry) =>{
							AC.submitUndoStackEntry(entry);
						});
						AC.setDepartments(newDepartments)
					}}>
					{organization.departmentIDOrder.map((departmentID:string, index) =>{
						for(var i in departments){
							let department = departments[i];
							if(department.id === departmentID){
								return <OrganizationScreenDepartmentsRow key={`department_${department.id}`} department={department}></OrganizationScreenDepartmentsRow>
							}
						}
						return <></>
					})}
				</DraggableTableBody>
				
			)}
    </table>
  )
}