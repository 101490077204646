
import "./UndoStackOverlay.css"
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { UndoStackEntry } from "../../Utils/UndoStackEntry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faUndo } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

interface UndoStackOverlayProps{
}

export default function UndoStackOverlay(props:UndoStackOverlayProps){
	
  const undoStackPromise = useSelector((state:{undoStack:{stack:UndoStackEntry[],top:number}}) => state.undoStack)
	
  const dispatch = useDispatch();
  const AC = bindActionCreators({
    undo:ActionCreators.undo,
    redo:ActionCreators.redo},dispatch);

	const [myUndoStack, setMyUndoStack] = useState<{stack:UndoStackEntry[],top:number}>(undoStackPromise);

	useEffect(()=>{
		let processIt = async () =>{
			let undoStack = await undoStackPromise;
			setMyUndoStack(undoStack);
		}
		processIt();
	},[undoStackPromise]);

  return (
		<>
			{myUndoStack.stack && myUndoStack.stack.length > 0 && (
				<div className={`UndoStackOverlay ${myUndoStack.stack.length==0?"hidden":""}`}>
					<div className="controls">
						{(myUndoStack.stack.length > 0 && myUndoStack.top > 0) && (
							<div 
								className={`buttonUndo`} 
								onClick={()=>{
									AC.undo();
								}}>
								<FontAwesomeIcon icon={faUndo}></FontAwesomeIcon>&nbsp; Undo {myUndoStack.stack[myUndoStack.top-1].description}
							</div>
						)}
						
						{( (myUndoStack.stack.length > 0 && myUndoStack.top > 0) && (myUndoStack.stack.length > 0 && myUndoStack.top < myUndoStack.stack.length)) && (
							<div className='divider'>|</div>
						)}

						{(myUndoStack.stack.length > 0 && myUndoStack.top < myUndoStack.stack.length) && (
							<div
								className={`buttonRedo`}
								onClick={()=>{
									AC.redo();
								}
							}>
								<FontAwesomeIcon icon={faRedo}></FontAwesomeIcon> &nbsp; Redo {myUndoStack.stack[myUndoStack.top].description}
							</div>
						)}
					</div>
					<div className="undoStack">
						{myUndoStack.stack.map((entry,index)=>{
							return <div className={`undoStackEntry ${index == myUndoStack.top?"selected":""}`} key={index}>{entry.description}</div>
						})}
					</div>
				</div>
			)}
		</>
  )
}


