

import React, { useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./SoldVSBilledTableHeader.css"
import { useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { ReactNode } from "react";

export enum SoldVSBilledTableHeaderType{
	label1,
	total1,
	label2,
	total2,
}

interface SoldVSBilledTableHeaderProps{
	type:SoldVSBilledTableHeaderType
	
	
	bshSold?:number,
	bshSoldPercent?:number,
	actualHrs?:number,
	actualHrsPercent?:number,
	estVsAct?:number,
	budgetBsh?:number,
	bshMo?:number,
	capacityMo?:number,
	dollarMo?:number,
	annualized?:number,
	neededResources?:number,
	resourcesHave?:number,
	difference?:number,
	bspWeek?:number,
	avgAct?:number,
	bspCapacityYr?:number,
	resources?:string|ReactNode,
	roleWeight?:number,
	weightedNum?:number,
}

export default function SoldVSBilledTableHeader(props:SoldVSBilledTableHeaderProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)


  return (
		<>
		{props.type==SoldVSBilledTableHeaderType.label1 && (
			<tr className={`SoldVSBilledTableHeader label1`}>
				<th></th>
				<th>BSH Sold (SOWs)</th>
				<th>%</th>
				<th>Acutal Hrs</th>
				<th>%</th>
				<th>Est. vs Act.</th>
				<th>Budget (BSH)</th>
				<th>BSH / MO</th>
				<th>Capacity / MO</th>
				<th>$ Sold / MO</th>
				<th>Annualized</th>
				<th>Needed Resources</th>
				<th>Resources Have</th>
				<th>Difference</th>
				<th>BSP / Week</th>
				<th>AVG(Act.)</th>
				<th>BSP Capacity (Yr.)</th>
				<th>Resource</th>
				{/* <th>Role Weight</th>
				<th>Weighted #</th> */}
			</tr>
		)}
		{props.type==SoldVSBilledTableHeaderType.label2 && (
			<tr className={`SoldVSBilledTableHeader label2`}>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th>SF YTD</th>
				<th>SF Annualized</th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th>BSP Capacity (Mo.)</th>
				<th></th>
				{/* <th></th>
				<th></th> */}
			</tr>
		)}
		{props.type==SoldVSBilledTableHeaderType.total1 && (
			<tr className={`SoldVSBilledTableHeader total1`}>
				<th>Totals</th>
				<th>{prettyNum(props.bshSold!)}</th>
				<th>{Math.round(props.bshSoldPercent!*1000)/10}%</th>
				<th>{prettyNum(props.actualHrs!)}</th>
				<th>{Math.round(props.actualHrsPercent!*1000)/10}%</th>
				<th>{Math.round(props.estVsAct!*1000)/10}%</th>
				<th>{prettyCurrency(props.budgetBsh!)}</th>
				<th>{prettyNum(props.bshMo!)}</th>
				<th>{prettyCurrency(props.capacityMo!)}</th>
				<th>{prettyCurrency(props.dollarMo!)}</th>
				<th>{prettyCurrency(props.annualized!)}</th>
				<th>{Math.round(props.neededResources!*100)/100}</th>
				<th>{Math.round(props.resourcesHave!*100)/100}</th>
				<th>{Math.round(props.difference!*100)/100}</th>
				<th>{Math.round(props.bspWeek!*100)/100}</th>
				<th>{Math.round(props.avgAct!*100)/100}</th>
				<th>{prettyCurrency(props.bspCapacityYr!)}</th>
				<th>{props.resources}</th>
				{/* <th>{Math.round(props.roleWeight!*100)/100}</th>
				<th>{Math.round(props.weightedNum!*100)/100}</th> */}
			</tr>
		)}
		{props.type==SoldVSBilledTableHeaderType.total2 && (
			<tr className={`SoldVSBilledTableHeader total2`}>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th>{prettyCurrency(props.budgetBsh!)}</th>
				<th>{prettyCurrency(props.annualized!)}</th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th>{prettyCurrency(props.bspCapacityYr!)}</th>
				<th></th>
				{/* <th></th>
				<th></th> */}
			</tr>
		)}
		</>
  )
}