

import React, { ReactNode, useEffect, useState } from "react"
import "./HomeTaskHeaderRow.css"
import { ProjectTask } from "../../../FirebaseModel/Project";
import { Tooltip } from "@mui/material";
import { Organization, holiday } from "../../../FirebaseModel/Organization";
import { shortDate } from "../../../Utils/formatting";
import { ProductionScheduleTableHeaderType } from "../../ProductionSchedule/ProductionScheduleTableHeader/ProductionScheduleTableHeader";
import { useSelector } from "react-redux";

interface HomeTaskHeaderRowProps {
	type: ProductionScheduleTableHeaderType,
	columns: string[],
	numMonths: number,
	hideCalendar?: boolean,
}


export default function HomeTaskHeaderRow(props: HomeTaskHeaderRowProps) {
	const organization = useSelector((state: { organization: Organization }) => state.organization)

	const [months, setMonths] = useState<Date[]>([]);
	const [days, sethays] = useState<Date[]>([]);

	const today = new Date();

	useEffect(() => {
		let months: Date[] = [];
		let date = new Date();
		for (let i = 0; i < props.numMonths; i++) {
			months.push(new Date(date.getFullYear(), date.getMonth() + i, 1));
		}
		setMonths(months);

		let days: Date[] = [];
		months.forEach(month => {
			let date = new Date(month);
			while (date.getMonth() === month.getMonth()) {
				days.push(new Date(date));
				date.setDate(date.getDate() + 1);
			}
		})
		sethays(days);
	}, [props.numMonths])

	const rowForColumnMonth = (column: string, index: number) => {
		switch (column) {
			case "Project":
				return <th></th>
			case "Role":
				return <th></th>
			case "Task":
				return <th></th>
			case "Planned Roles Hours":
				return <th></th>
			case "Actual Role Hours":
				return <th></th>
			case "Remaining":
				return <th></th>
			case "Estimated % Complete":
				return <th></th>
		}
		return <></>
	}
	const rowForColumnDay = (column: string, index: number) => {
		switch (column) {
			case "Project":
				return <th></th>
			case "Role":
				return <th></th>
			case "Task":
				return <th></th>
			case "Planned Roles Hours":
				return <th></th>
			case "Actual Role Hours":
				return <th></th>
			case "Remaining":
				return <th></th>
			case "Estimated % Complete":
				return <th></th>
		}
		return <></>
	}
	const rowForColumnDayOfWeek = (column: string, index: number) => {
		switch (column) {
			case "Project":
				return <th className="project">Project</th>
			case "Role":
				return <th className="role">Role</th>
			case "Task":
				return <th className="task">Task</th>
			case "Planned Roles Hours":
				return <th className="plannedRoleHours">Planned Roles Hours</th>
			case "Actual Role Hours":
				return <th className="actualRoleHours">Actual Role Hours</th>
			case "Remaining":
				return <th className="remaining">Remaining</th>
			case "Estimated % Complete":
				return <th className='estimatedPercentComplete'>Estimated % Complete</th>
		}
		return <></>
	}
	return (
		<>
			{props.columns && (
				<>
					{props.type === ProductionScheduleTableHeaderType.month && (
						<tr className={`ProductionScheduleTableHeader HomeTaskHeaderRow month`}>
							{props.columns.map((column: string, index: number) => {
								return rowForColumnMonth(column, index)
							})}
							{!props.hideCalendar && months.map((month, index) => {

								//a header with the short month name and year. colspan is the number of days in the month
								return <th key={index} colSpan={new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate()} className="topper">{month.toLocaleString('default', { month: 'short' })} {month.getFullYear()}</th>
							})}
						</tr>
					)}
					{props.type === ProductionScheduleTableHeaderType.day && (
						<tr className={`ProductionScheduleTableHeader HomeTaskHeaderRow day`}>
							{props.columns.map((column: string, index: number) => {
								return rowForColumnDay(column, index)
							})}
							{!props.hideCalendar && days.map((day, index) => {
								if (!organization) return <></>
								// add a header with the day of the month number
								let h = holiday(shortDate(day), organization)
								let isToday = day.getDate() == today.getDate() && day.getMonth() == today.getMonth() && day.getFullYear() == today.getFullYear();
								let isWeekend = day.getDay() === 0 || day.getDay() === 6;
								return <th key={index} className={`day ${isToday ? "today" : ""} ${isWeekend ? "weekend" : ""} ${h ? "holiday" : ""}`}>
									{h ? (
										<Tooltip title={h.description}>
											<span>{day.getDate()}</span>
										</Tooltip>
									) : (
										<>{day.getDate()}</>
									)}
								</th>
							})}
						</tr>
					)}
					{props.type === ProductionScheduleTableHeaderType.dayOfWeek && (
						<tr className={`ProductionScheduleTableHeader HomeTaskHeaderRow dayOfWeek`}>
							{props.columns.map((column: string, index: number) => {
								return rowForColumnDayOfWeek(column, index)
							})}
							{!props.hideCalendar && days.map((day, index) => {
								if (!organization) return <></>
								// add a header with the single letter day of the week
								let h = holiday(shortDate(day), organization)
								let isToday = day.getDate() == today.getDate() && day.getMonth() == today.getMonth() && day.getFullYear() == today.getFullYear();
								let isWeekend = day.getDay() === 0 || day.getDay() === 6;
								return <th key={index} className={`day ${isToday ? "today" : ""} ${isWeekend ? "weekend" : ""} ${h ? "holiday" : ""}`}>
									{h ? (
										<Tooltip title={h.description}>
											<span>{day.toLocaleString('default', { weekday: 'short' }).charAt(0)}</span>
										</Tooltip>
									) : (
										<>{day.toLocaleString('default', { weekday: 'short' }).charAt(0)}</>
									)}
								</th>
							})}
						</tr>
					)}
				</>
			)}
		</>
	)
}