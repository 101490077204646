
import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./DepartmentDropdown.css"
import { useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { forceNumeric, prettyCurrency, prettyNum } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import { Client } from "../../FirebaseModel/Client";
import { Department } from "../../FirebaseModel/Department";
interface DepartmentDropdownProps{
	className?:string,
	defaultValue?:string,
	prompt?:string,
	onChange?:(departmentID:string) => void
	onCreateNew?:()=>void
}

export default function DepartmentDropdown(props:DepartmentDropdownProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const allDepartments = useSelector((state:{departments:Department[]}) => state.departments)

	const [departments, setDepartments] = useState<Department[]>([...allDepartments]);
	const [selectedValue, setSelectedValue] = useState(props.defaultValue);

	useEffect(() =>{
		if(organization){
			setDepartments([...allDepartments]);
		}
	},[allDepartments,allDepartments])

	useEffect(() =>{
		setSelectedValue(props.defaultValue);
	},[props.defaultValue])

  return (
    <select 
				className={`DepartmentDropdown ${props.className??""}`} 
				value={selectedValue}
				onChange={(event) =>{
					setSelectedValue(event.target.value);
					if(event.target.value == "create new"){
						if(props.onCreateNew){
							props.onCreateNew();
						}
					}else{
						if(props.onChange){
							props.onChange(event.target.value);
						}
					}
				}}>
			<option value={""}>{props.prompt??"Select Department"}</option>
			{departments.map((department:Department, index:number) =>{
				return <option key={"department_option_"+index} value={department.id}>{department.abbreviation}</option>
			})}
    </select>
  )
}