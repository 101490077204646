
const specialCharacter = (input:string) =>{
	// return whether or not the input includes an illegal CSV character.
	
	if(input.indexOf(",") != -1){
		return true;
	}
	if(input.indexOf("\"") != -1){
		return true;
	}
	if(input.indexOf("\n") != -1){
		return true;
	}
	return false;
}

export const cleanCSVString = (input:string) =>{
	// if the input includes an illegal CSV character, wrap it in quotes.
	if(specialCharacter(input)){
		return "\""+input+"\"";
	}
	return input;
}