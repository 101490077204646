

import React, { useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ProjectProfitabilityTableRow.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Role, rolesExistAM, rolesExistPM } from "../../../FirebaseModel/Role";
import { Organization } from "../../../FirebaseModel/Organization";
import { Client } from "../../../FirebaseModel/Client";
import { Person } from "../../../FirebaseModel/Person";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { Tooltip } from "@mui/material";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { UndoStackEntry } from "../../../Utils/UndoStackEntry";
import { padDecimal, prettyCurrency } from "../../../Utils/formatting";
import { faEye, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface ProjectProfitabilityTableRowProps{
	group:Project[],
	mergeChangeOrders?:boolean,
	onProjectDetailsPressed:(group:Project[])=>void
}

export default function ProjectProfitabilityTableRow(props:ProjectProfitabilityTableRowProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);


  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const roles = useSelector((state:{roles:Role[]}) => state.roles)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)

	const [clientName, setClientName] = useState("");
	const [projectManagerName, setProjectManagerName] = useState("");
	const [dateCompleted, setDateCompleted] = useState("");
	const [profit, setProfit] = useState(0);
	const [profitMarginPercent, setProfitMarginPercent] = useState(0);
	const [markupPercent, setMarkupPercent] = useState(0);

	useEffect(() =>{
		let client = clients.find((client:Client) => client.id == props.group[0].clientID);
		if(client !=null){
			setClientName(client.name);
		}
		let projectManager = persons.find((person:Person) => person.id == props.group[0].projectManagerID);
		if(projectManager !=null){
			setProjectManagerName(projectManager.name);
		}
		let dateCompleted = "";
		let soldStatuses:string[] = (organization.projectStatusCategories as any)["sold"] as string[];
		let completedStatuses:string[] = (organization.projectStatusCategories as any)["completed"] as string[];
		props.group[0].statusHistory.forEach(status => {
			if(soldStatuses.includes(status.status) && completedStatuses.includes(status.status)){
				dateCompleted = status.date;
			}
		})
		setDateCompleted(dateCompleted);

		let averageResourceHourlyCost = organization.averageResourceCost / organization.averageWorkWeeksPerYear / organization.targetAverageHoursPerWeek;

		let totalResourceUsage = props.group.reduce((a,b) => a + b.calculated.actualHours, 0) * averageResourceHourlyCost;
		let profit = props.group.reduce((a,b) => a + b.sfClosedWon, 0) - totalResourceUsage - (props.group.reduce((a,b) => a + b.fixedCosts, 0)??0);
		setProfit(profit);
		
		let profitMarginPercent = profit / props.group.reduce((a,b) => a + b.sfClosedWon, 0);
		setProfitMarginPercent(profitMarginPercent);
		
		let markupPercent = (props.group.reduce((a,b) => a + b.sfClosedWon, 0) - totalResourceUsage) / totalResourceUsage;
		setMarkupPercent(markupPercent);

	},[organization, props.group])

  return (
		<tr className={`ProjectProfitabilityTableRow`}>
			<td>

				<Tooltip title={clientName} placement="bottom">
					<span>{clientName}</span>
				</Tooltip>
				
				<Tooltip title={`View Project Details`} placement="bottom">
						<div className="buttonEdit"
							onClick={() =>{
								props.onProjectDetailsPressed(props.group);
							}}><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></div>
					</Tooltip>
				</td>
			<td>{props.mergeChangeOrders?props.group[0].projectNumber.split("-")[0]:props.group[0].projectNumber}</td>
			<td>
				<Tooltip title={props.group[0].projectName} placement="bottom">
					<span>{props.group[0].projectName}</span>
				</Tooltip>
			</td>
			<td>{padDecimal(prettyCurrency(props.group.reduce((a,b) => a + b.sfClosedWon, 0)),2)}</td>
			<td>{dateCompleted}</td>
			{rolesExistPM(roles) && <td>{projectManagerName}</td>}
			<td>{padDecimal(prettyCurrency(profit),2)}</td>
			<td>{Math.round(profitMarginPercent * 100)}%</td>
			<td>{Math.round(markupPercent*100)}%</td>
		</tr>
  )
}