import { addDoc, collection, deleteDoc, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { shortDate } from "./formatting";
import { sharedFirestore } from "./SharedFirebase";
import { Backup } from "../FirebaseModel/Backup";

export async function CreateBackup(name: string, objectTypes: string[], organizationID: string) {
	let today = new Date();
	let backupDoc = {
		organizationID: organizationID,
		name: name,
		backupDate: shortDate(today),
		backupDateMillis: today.getTime(),
		objectTypes: objectTypes
	}

	let backupCollection = collection(sharedFirestore, "Backup");
	let backupDocRef = await addDoc(backupCollection, backupDoc);
	return {
		...backupDoc,
		id: backupDocRef.id
	} as Backup;
}


export async function BackupObjects(backupID: string, organizationID: string, objects: { [type: string]: any[]|null; }, progress: (progressCurrent: number, progressTotal: number) => void) {
	let progressCurrent = 0;
	let progressTotal = 0;


	for(var type in objects){
		let list:any[]|null = objects[type];
		if(list == null){
			let q = query(collection(sharedFirestore, type), where("organizationID","==",organizationID));
			let querySnapshot = await getDocs(q);
			list = [];
			for(var i = 0; i < querySnapshot.size; i++){
				let doc = querySnapshot.docs[i];
				list.push({...doc.data(),id:doc.id});
			}
			objects[type] = list;
		}
		progressTotal += list.length;
	}

	for(var type in objects){
		let list:any[]|null = objects[type];
		if(list == null){
			continue;
		}
		for(var i = 0; i < list.length; i++){
			let object = list[i];
			await BackupObject(backupID,type,object.id,object,organizationID);
			progressCurrent++;
			progress(progressCurrent,progressTotal);
		}
	}

}

export async function BackupObject(backupID: string, objectType: string, objectID: string, object: any, organizationID: string) {
	let backupObjectDoc = {
		organizationID: organizationID,
		backupID: backupID,
		objectType: objectType,
		objectID: objectID,
		object: object
	}

	if(objectID === undefined){
		console.log("undefined objectID");
	}

	let backupObjectCollection = collection(sharedFirestore, "BackupObject");
	let backupObjectDocRef = await addDoc(backupObjectCollection, backupObjectDoc);
	return {
		...backupObjectDoc,
		id: backupObjectDocRef.id
	}
}

export async function ClearObjectsOfType(organizationID:string, objectType:string){

	console.log("deleting "+objectType)
	return new Promise<void>((resolve,reject) => {
		let q = query(collection(sharedFirestore, objectType), where("organizationID","==",organizationID));
		if(objectType == "Organization"){
			resolve();
			return;
		}
		getDocs(q).then((querySnapshot) => {
			for(var i = 0; i < querySnapshot.size; i++){
				let doc = querySnapshot.docs[i];
				deleteDoc(doc.ref);
			}
			console.log("deleted "+objectType)
			resolve();
		});
	})
}
export async function RestoreObjectsOfType(backupID:string, organizationID: string, objectType:string){

	console.log("restoring "+objectType)
	return new Promise<void>((resolve,reject) => {
		let q = query(collection(sharedFirestore, "BackupObject"), where("organizationID","==",organizationID), where("objectType","==",objectType), where("backupID","==",backupID));
		getDocs(q).then(async (querySnapshot) => {
			for(var i = 0; i < querySnapshot.size; i++){
				let backupObjectDoc = querySnapshot.docs[i];
				let data = backupObjectDoc.data();
				let object = data.object;
				let objectID = data.objectID as string;
				let objectRef = doc(collection(sharedFirestore, objectType),objectID);
				await setDoc(objectRef,object);
			}
			console.log("restored "+objectType)
			resolve();
		});		
	})
}

export async function RestoreBackup(backup:Backup, progress: (phase:string, progressCurrent: number, progressTotal: number) => void){
	let restoredTypes = 0;
	let totalTypes = backup.objectTypes.length;
	progress("Restoring",restoredTypes,totalTypes);
	for(var i = 0; i < backup.objectTypes.length; i++){
		let type = backup.objectTypes[i];
		progress("Restoring - "+type,restoredTypes,totalTypes);
		await RestoreObjectsOfType(backup.id, backup.organizationID,type);
		restoredTypes++;
	}
}

export async function DeleteBackup(backup:Backup){
	let q = query(collection(sharedFirestore, "BackupObject"), where("organizationID","==",backup.organizationID), where("backupID","==",backup.id));
	getDocs(q).then((querySnapshot) => {
		for(var i = 0; i < querySnapshot.size; i++){
			let doc = querySnapshot.docs[i];
			deleteDoc(doc.ref);
		}
	});
	let backupRef = doc(collection(sharedFirestore, "Backup"),backup.id);
	await deleteDoc(backupRef);
}


export async function ClearAndRestoreFromBackup(backup:Backup, progress: (phase:string, progressCurrent: number, progressTotal: number) => void){
	let clearedTypes = 0;
	let totalTypes = backup.objectTypes.length;
	progress("Clearing",clearedTypes,totalTypes);
	for(var i = 0; i < backup.objectTypes.length; i++){
		let type = backup.objectTypes[i];
		progress("Clearing - "+type,clearedTypes,totalTypes);
		await ClearObjectsOfType(backup.organizationID,type);
		clearedTypes++;
	}
	await RestoreBackup(backup,progress);
}