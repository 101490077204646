


import React, { useEffect, useState } from "react"
import "./NonProjectTasksTable.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { saveOrganizationAttributeChange } from "../../../Utils/SaveOrganizationFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { faBars, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forceDecimal, forcePercentage, prettyNum, shortDate } from "../../../Utils/formatting";
import { PersonNonProjectTask, PersonNonProjectTaskScheduleBlock } from "../../../FirebaseModel/Person";
import NonProjectTasksTableRow from "../NonProjectTasksTableRow/NonProjectTasksTableRow";

const uuid = require('uuid');

interface NonProjectTasksTableProps{
	nonProjectTasks:PersonNonProjectTask[];
	personID:string;
	isReadOnly?:boolean;
	onNonProjectTasksChange:(nonProjectTasks:PersonNonProjectTask[]) => void;
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function NonProjectTasksTable(props:NonProjectTasksTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles},dispatch);

	const [nonProjectTaks, setNonProjectTasks] = useState<PersonNonProjectTask[]>(props.nonProjectTasks);
	useEffect(() =>{
		setNonProjectTasks(props.nonProjectTasks);
	},[props.nonProjectTasks]);

	
  return (
    <table 
			className={`NonProjectTasksTable`}>
			<thead>
				<tr>
					<th>Task</th>
					<th>Planned<br/>Hours</th>
					<th>Estimated<br/>Percent Complete</th>
					<th>Due Date</th>
				</tr>
			</thead>
			<tbody>
			{nonProjectTaks.map((task:PersonNonProjectTask, index) =>{
				return <NonProjectTasksTableRow
					key={task.id}
					nonProjectTask={task}
					onNonProjectTaskChange={(nonProjectTask) => {
						const newOptions = [...nonProjectTaks];
						newOptions[index] = nonProjectTask;
						setNonProjectTasks(newOptions);
						props.onNonProjectTasksChange(newOptions);
					} }
					onDeleteProjectTask={(nonProjectTask) => {
						const newOptions = [...nonProjectTaks];
						newOptions.splice(index, 1);
						setNonProjectTasks(newOptions);
						props.onNonProjectTasksChange(newOptions);

					} } personID={props.personID} />
			})}
			{!props.isReadOnly && (
				<tr>
					<td>
						<div className="buttonAdd"
							onClick={() =>{
								const newOptions = [...nonProjectTaks];
								newOptions.push({
									id:uuid.v4(), 
									name:"", 
									plannedHours:0, 
									percenComplete:0, 
									dueDate:"",
									scheduleBlocks:[] as PersonNonProjectTaskScheduleBlock[]
								} as PersonNonProjectTask);
								setNonProjectTasks(newOptions);
								props.onNonProjectTasksChange(newOptions);
							}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Task</div>
					</td>
				</tr>
			)}
			</tbody>
    </table>
  )
}