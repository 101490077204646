
import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, Timestamp, updateDoc, where} from "firebase/firestore";
import "./WeeklyTimesheetInput.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { forceDecimal, forceNumeric, prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { saveProjectAttributeChange, saveProjectPlannedHoursChange } from "../../../Utils/SaveProjectFunctions";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { saveProjectRoleActualHours } from "../../../Utils/SaveProjectRoleActualFunctions";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { saveTimeOffAttributeChange } from "../../../Utils/SaveTimeOffFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { UndoStackEntry } from "../../../Utils/UndoStackEntry";

export enum WeeklyTimesheetInputType{
	ActualHours,
	PTO,
	STO
}

interface WeeklyTimesheetInputProps{
	className?:string,
	type:WeeklyTimesheetInputType,
	actual?:ProjectRoleActualHours,
	timeOff?:TimeOff,
	weekKey:string,
}

export default function WeeklyTimesheetInput(props:WeeklyTimesheetInputProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);
		
  const organization = useSelector((state:{organization:Organization}) => state.organization)

	const [defaultValue, setDefaultValue] = useState("");

	useEffect(() =>{

		let found = false;

		if(props.type == WeeklyTimesheetInputType.ActualHours){

			for(var i in props.actual!.hours){
				let entry = props.actual!.hours[i];
				if(entry.date == props.weekKey){
					found = true;
					setDefaultValue(prettyNum(entry.hours));
					break;
				}
			}
		}else if(props.type == WeeklyTimesheetInputType.PTO){
			for(var i in props.timeOff!.hoursTakenPTO){
				let entry = props.timeOff!.hoursTakenPTO[i];
				if(entry.date == props.weekKey){
					found = true;
					setDefaultValue(prettyNum(entry.hours));
					break;
				}
			}
		}else if(props.type == WeeklyTimesheetInputType.STO){
			for(var i in props.timeOff!.hoursTakenSTO){
				let entry = props.timeOff!.hoursTakenSTO[i];
				if(entry.date == props.weekKey){
					found = true;
					setDefaultValue(prettyNum(entry.hours));
					break;
				}
			}
		}
			

		if(!found){
			setDefaultValue("0");
		}
	},[props.actual])

  return (
    <input className={`WeeklyTimesheetInput ${props.className??""}`}
			onBlur={(event) =>{

				if(event.target.value ==""){
					event.target.value = "0";
				}

				let numHours = parseFloat(event.target.value.replace("$","").replace(/,/g,""));
				let tempHours:{date:string, hours:number}[] = [];

				let calculated:any = {};


				if(props.type == WeeklyTimesheetInputType.ActualHours){
					tempHours = [... props.actual!.hours];
					calculated = {...props.actual!.calculated};
					calculated.totalHours = 0;
				}else if(props.type == WeeklyTimesheetInputType.PTO){
					tempHours = [... props.timeOff!.hoursTakenPTO];
					calculated = {...props.timeOff!.calculated};
					calculated.totalHoursTakenPTO = 0;
				}else if(props.type == WeeklyTimesheetInputType.STO){
					tempHours = [... props.timeOff!.hoursTakenSTO];
					calculated = {...props.timeOff!.calculated};
					calculated.totalHoursTakenSTO = 0;
				}


				let found = false;
				for(var i in tempHours){
					let entry = tempHours[i];
					if(entry.date == props.weekKey){
						found = true;
						tempHours[i].hours = numHours;
						if(numHours == 0){
							tempHours.splice(parseInt(i),1);
						}
						break;
					}
				}
				if(!found && numHours > 0){
					tempHours.push({date:props.weekKey,hours:numHours})
				}

				tempHours.sort((a,b) => {
					let timeA = new Date(a.date).getTime();
					let timeB = new Date(b.date).getTime();
					if(timeA < timeB){
						return -1;
					}else if(timeA > timeB){
						return 1;
					}
					return 0;
				})

				let totalHours = 0;
				for(var i in tempHours){
					totalHours += tempHours[i].hours;
				};

				if(props.type == WeeklyTimesheetInputType.ActualHours){
					calculated.totalHours = totalHours;
					saveProjectRoleActualHours(props.actual!,["hours","calculated"],[tempHours,calculated],(entry:UndoStackEntry) =>{
						AC.submitUndoStackEntry(entry);
					}).then(() =>{
						props.actual!.hours = tempHours;
						props.actual!.calculated = calculated;
					});
				}else if(props.type == WeeklyTimesheetInputType.PTO){
					calculated.totalHoursTakenPTO = totalHours;
					saveTimeOffAttributeChange(props.timeOff!,["hoursTakenPTO","calculated"],[tempHours,calculated],(entry) =>{
						AC.submitUndoStackEntry(entry);
					}).then(() =>{
						props.timeOff!.hoursTakenPTO = tempHours;
						props.timeOff!.calculated = calculated;
					});
				}else if(props.type == WeeklyTimesheetInputType.STO){
					calculated.totalHoursTakenSTO = totalHours;
					saveTimeOffAttributeChange(props.timeOff!,["hoursTakenSTO","calculated"],[tempHours,calculated],(entry) =>{
						AC.submitUndoStackEntry(entry);
					}).then(() =>{
						props.timeOff!.hoursTakenSTO = tempHours;
						props.timeOff!.calculated = calculated;
					});
				}
			}}
			onFocus={(event)=>{
				let input = event.target as HTMLInputElement
				input.setSelectionRange(0,input.value.length)
			}}
			onKeyPress={(event) =>{
				forceDecimal(event);
			}}
			defaultValue={defaultValue}>
    </input>
  )
}