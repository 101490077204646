

import React, { useEffect, useState } from "react"
import { addDoc, collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./PopupAddTaskScheduleBlock.css"
import { useDispatch, useSelector } from "react-redux";
import { Project, ProjectTask, ProjectTaskScheduleBlock } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import { prettyNum, forceNumeric, prettyCurrency, forceDecimal, shortDate } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { saveClientAttributeChange } from "../../Utils/SaveClientFunctions";
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from "../../Utils/UndoStackEntry";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { ClientContact } from "../../FirebaseModel/ClientContact";
import { Person } from "../../FirebaseModel/Person";
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { saveProjectAttributeChange } from "../../Utils/SaveProjectFunctions";


const uuid = require('uuid');

interface PopupAddTaskScheduleBlockProps{
	projectTaskScheduleBlock?:ProjectTaskScheduleBlock
	projectID?:string;
	roleID?:string;
	personID?:string;
	startDate?:string;

	onCancel:()=>void;
	onSave:(taskBlock:ProjectTaskScheduleBlock|null) =>void;
}

export default function PopupAddTaskScheduleBlock(props:PopupAddTaskScheduleBlockProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)

	const [project, setProject] = useState<Project|null>(null);
	const [role, setRole] = useState<Role|null>(null);
	const [person, setPerson] = useState<Person|null>(null);

	const [tasks, setTasks] = useState<ProjectTask[]>([]);
	const [selectedTask, setSelectedTask] = useState<ProjectTask|null>(null);

	const [plannedHours, setPlannedHours] = useState<number>(0);
	const [scheduledHours, setScheduledHours] = useState<number>(0);


	const [startDate, setStartDate] = useState<string>(props.startDate??shortDate(new Date()));
	const [endDate, setEndDate] = useState<string>(props.startDate??shortDate(new Date()));
	const [dailyPlannedHours, setDailyPlannedHours] = useState<number[]|undefined>(props.projectTaskScheduleBlock?.dailyPlannedHours??undefined);

	useEffect(() =>{
		let project = projects.find((project) => project.id == (props.projectID??props.projectTaskScheduleBlock!.projectID));
		setProject(project!);
		let role = roles.find((role) => role.id == (props.roleID??props.projectTaskScheduleBlock!.roleID));
		setRole(role!);
		let person = persons.find((person) => person.id == (props.personID??props.projectTaskScheduleBlock!.personID));
		setPerson(person!);

		setDailyPlannedHours(props.projectTaskScheduleBlock?.dailyPlannedHours??undefined);
	
		setStartDate(props.projectTaskScheduleBlock?.startDate??props.startDate??shortDate(new Date()));
		setEndDate(props.projectTaskScheduleBlock?.endDate??props.startDate??shortDate(new Date()));
	},[props.personID, props.projectID, props.roleID, props.projectTaskScheduleBlock])

	useEffect(() =>{
		if(project){
			let projectRoleTasks = project.projectRoleTasks.find((projectRoleTask) => projectRoleTask.roleID == role!.id);
			let tasks = projectRoleTasks?.tasks??[];
			setTasks(tasks);

		}else{
			setTasks([]);
		}
	},[project, props.projectTaskScheduleBlock])

	useEffect(() =>{
		if(project){
			let selectedTask = tasks.find((task) => task.id == props.projectTaskScheduleBlock?.taskID);
			if(selectedTask){
				setSelectedTask(selectedTask);
			}

			if(!selectedTask && tasks.length > 0){
				setSelectedTask(tasks[0]);
			}
		}
	},[project, props.projectTaskScheduleBlock,tasks])

	useEffect(() =>{
		if(selectedTask){
			setPlannedHours(selectedTask.plannedHours);
		}else{
			setPlannedHours(0);
		}
	},[selectedTask]);

	useEffect(() =>{
		let scheduledHours = 0;
		if(dailyPlannedHours){
			dailyPlannedHours.forEach((plannedHour) =>{
				scheduledHours += plannedHour;
			})
		}
		setScheduledHours(scheduledHours);
	},[dailyPlannedHours]);

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);
		

		useEffect(() =>{
			// building the planned daily hours. if its unset then it is 8 hours for weekday and 0 for weekend
			let newDailyPlannedHours = [];
			let currentDate = new Date(startDate);
			let endDateDate = new Date(endDate);
			while(currentDate <= endDateDate){
				if(currentDate.getDay() == 0 || currentDate.getDay() == 6){
					newDailyPlannedHours.push(0);
				}else{
					newDailyPlannedHours.push(8);
				}
				currentDate = addDays(currentDate,1);
			}

			let oldDaiplyPlannedHours = [...dailyPlannedHours??[]];
			// remove any left over entries so that there are only as many as newDailyPannedHours
			oldDaiplyPlannedHours.splice(newDailyPlannedHours.length,oldDaiplyPlannedHours.length - newDailyPlannedHours.length);

			//now only overwrite if the dailyPlannedHours[i] is unset
			for(let i = 0; i < newDailyPlannedHours.length; i++){
				if(oldDaiplyPlannedHours[i] == undefined){
					oldDaiplyPlannedHours[i] = newDailyPlannedHours[i];
				}
			}

			//add any rows if the newDailyPlannedHours is longer than the dailyPlannedHours
			for(let i = oldDaiplyPlannedHours.length; i < newDailyPlannedHours.length; i++){
				oldDaiplyPlannedHours.push(newDailyPlannedHours[i]);
			}

			setDailyPlannedHours(oldDaiplyPlannedHours);
		},[startDate,endDate]);


		useEffect(() =>{
			if(props.projectTaskScheduleBlock){
				setDailyPlannedHours(props.projectTaskScheduleBlock.dailyPlannedHours);
				setStartDate(props.projectTaskScheduleBlock.startDate);
				setEndDate(props.projectTaskScheduleBlock.endDate);
			}
		},[props.projectTaskScheduleBlock])

		// a function that returns a date offset by a certain number of days
		const addDays = function(date:Date, days:number) {
			var date = new Date(date.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		}

	const saveNewProjectTaskScheduleBlock = () =>{
		return new Promise<ProjectTaskScheduleBlock>((resolve,reject) =>{

			let taskBlockDocData:ProjectTaskScheduleBlock = {
				projectID: project!.id,
				roleID: role!.id,
				taskID: selectedTask!.id,
				personID: person!.id,
				startDate: startDate,
				endDate: endDate,
				dailyPlannedHours: dailyPlannedHours!,
				id: uuid.v4()
			}

			let projectTasks = [...project!.projectRoleTasks];
			let task = projectTasks.find((projectRoleTask) => projectRoleTask.roleID == role!.id)!.tasks.find((task) => task.id == selectedTask!.id)!;

			task.scheduleBlocks.push(taskBlockDocData);

			saveProjectAttributeChange(project!, organization,clients, "projectRoleTasks", projectTasks,(entry) =>{
				AC.submitUndoStackEntry(entry);
			}).then(() =>{
				resolve(taskBlockDocData);
			});			
		})
	}

	const updateProjectTaskScheduleBlock = () =>{
		return new Promise<ProjectTaskScheduleBlock>((resolve,reject) =>{
			let taskBlockDocData:ProjectTaskScheduleBlock = {
				projectID: project!.id,
				roleID: role!.id,
				taskID: selectedTask!.id,
				personID: person!.id,
				startDate: startDate,
				endDate: endDate,
				dailyPlannedHours: dailyPlannedHours!,
				id: props.projectTaskScheduleBlock!.id
			}

			let projectTasks = [...project!.projectRoleTasks];
			let prevTask = projectTasks.find((projectRoleTask) => projectRoleTask.roleID == role!.id)!.tasks.find((task) => task.id == props.projectTaskScheduleBlock!.taskID)!;
			let task = projectTasks.find((projectRoleTask) => projectRoleTask.roleID == role!.id)!.tasks.find((task) => task.id == selectedTask!.id)!;

			//if prevTask id is different than selected task id then remove the taskBlock from the prevTask
			if(prevTask.id != task.id){
				let taskBlockIndex = prevTask.scheduleBlocks.indexOf(props.projectTaskScheduleBlock!);
				prevTask.scheduleBlocks.splice(taskBlockIndex,1);
			}

			let taskBlock = task.scheduleBlocks.find((taskBlock) => taskBlock.id == props.projectTaskScheduleBlock!.id)!;
			let taskBlockIndex = task.scheduleBlocks.indexOf(taskBlock);
			if(taskBlockIndex == -1){
				task.scheduleBlocks.push(taskBlockDocData);
			}else{
				task.scheduleBlocks[taskBlockIndex] = taskBlockDocData;
			}

			saveProjectAttributeChange(project!,organization,clients, "projectRoleTasks", projectTasks,(entry) =>{
				AC.submitUndoStackEntry(entry);
			}).then(() =>{
				resolve(taskBlockDocData);
			});
		})
	}

	const deleteProjectTaskScheduleBlock = () =>{
		return new Promise<void>((resolve,reject) =>{
			let projectTasks = [...project!.projectRoleTasks];
			let task = projectTasks.find((projectRoleTask) => projectRoleTask.roleID == role!.id)!.tasks.find((task) => task.id == props.projectTaskScheduleBlock!.taskID)!;
			let taskBlockIndex = task.scheduleBlocks.indexOf(props.projectTaskScheduleBlock!);
			task.scheduleBlocks.splice(taskBlockIndex,1);

			saveProjectAttributeChange(project!,organization,clients, "projectRoleTasks", projectTasks,(entry) =>{
				AC.submitUndoStackEntry(entry);
			}).then(() =>{
				resolve();
			});
		})
	}


	const dayOfWeek = ["S","M","T","W","T","F","S"]

	const [clearingForm, setClearingForm] = useState(false);

  return (
    <div className={`popup PopupAddTaskScheduleBlock`}>
			<h1>Schedule {person?.name} in project "{project?.projectName}"</h1>
			{clearingForm == false && (
				<div className="content">

					{props.projectTaskScheduleBlock && (
						<button className="deleteButton" onClick={() => {
							deleteProjectTaskScheduleBlock().then(() =>{
								props.onSave(null);
							})						
						}}>Remove From Schedule</button>
					)}

					<div className="topArea">

						<div className="row">
							<div className="col">
								<div className="label">Task</div>
								<select className={`task`}
									value={selectedTask?selectedTask.id:""}
									onChange={(event) =>{
										let task = tasks.find((task) => task.id == event.target.value)!;
										setSelectedTask(task);
									}}>
									{tasks.map((task) =>(
										<option value={task.id}>{task.name}</option>
									))}
								</select>
							</div>

							<div className="col">
								<div className="label">Start Date</div>
				        <DatePicker 
				          selected={(new Date(startDate))}
				          onChange={(date:Date) =>{
				            if(date){
											let dateString = shortDate(date);
											setStartDate(dateString);
				            }
				          }}></DatePicker>
							</div>

							<div className="col">
								<div className="label">End Date</div>
								<DatePicker 
									selected={(new Date(endDate))}
									onChange={(date:Date) =>{
										if(date){
											let dateString = shortDate(date);
											setEndDate(dateString);
										}
									}}></DatePicker>
							</div>

							<div className="col">
								<div className="label">Planned hours<br/>for this task:</div>
								{plannedHours}
							</div>

							<div className="col">
								<div className="label">Sheduled hours<br/>for this task:</div>
								{scheduledHours}
							</div>
						</div>
					</div>

					<h1>Daily Breakdown</h1>

					<div className="bottomArea">
						<table>
							<thead>
								<tr>
									<th>Date</th>
									<th>Hours</th>
								</tr>
							</thead>
							<tbody>
								{dailyPlannedHours && dailyPlannedHours.map((hours,index) =>(
									<tr className={`${(addDays(new Date(startDate),index).getDay()==0||addDays(new Date(startDate),index).getDay()==6)?"weekend":""}`}>
										<td>{shortDate(addDays(new Date(startDate),index))} - {dayOfWeek[addDays(new Date(startDate),index).getDay()]}</td>
										<td>
											<input type="number" 
												value={hours} 
												max={10}
												min={0}
												onFocus={(event) =>{
													event.target.select();
													event.target.setSelectionRange(0,event.target.value.length);
												}}
												onChange={(event) =>{
												let newDailyPlannedHours = [...dailyPlannedHours];
												newDailyPlannedHours[index] = parseFloat(event.target.value);
												setDailyPlannedHours(newDailyPlannedHours);
											}}
											onKeyPress={(event) =>{
												forceDecimal(event);
											}}></input>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)}

			<div className="floatingButtons">
				<div className="buttonCancel"
					onClick={() =>{
						props.onCancel();
					}}>Cancel</div>
				<div className="buttonSave"
					onClick={() =>{
						if(props.projectTaskScheduleBlock){
							updateProjectTaskScheduleBlock().then((taskBlock) =>{
								props.onSave(taskBlock);
							});
						}else{
							saveNewProjectTaskScheduleBlock().then((taskBlock) =>{
								props.onSave(taskBlock);
							});
						}
					}}>Save &amp; Close</div>
			</div>
		</div>
  )
}