import { AnyAction } from 'redux'
import { Client } from '../FirebaseModel/Client';
import { Person } from '../FirebaseModel/Person';
import { Project } from '../FirebaseModel/Project';
import { Role } from '../FirebaseModel/Role';
import { TimeOff } from '../FirebaseModel/TimeOff';
import { Organization } from '../FirebaseModel/Organization';
import { MonthTotal } from '../FirebaseModel/MonthTotal';
import { ProjectRoleActualHours } from '../FirebaseModel/ProjectRoleActualHours';
import { PerformRedo, PerformUndo, UndoStackEntry } from '../Utils/UndoStackEntry';
import { Department } from '../FirebaseModel/Department';
import { ClientContact } from '../FirebaseModel/ClientContact';
import { View } from '../FirebaseModel/View';
import ProjectMessageBoard from '../FirebaseModel/ProjectMessageBoard';
import { Backup } from '../FirebaseModel/Backup';
import { YearTotal } from '../FirebaseModel/YearTotal';


export enum Screens{
  Home,
  ProjectPortal,
  ProjectProfitability,
	Organization,
  Team,
  Clients,
  Reports,
  Timesheet,
  ProductionSchedule,
  IndividualProfitSharing,
  Import
}

export function ScreenReducer(state = Screens.Home, action:AnyAction){
  switch(action.type){
    case "setScreen":
      return  action.payload;
  }
  return state;
}

export function ScreenStackReducer(state:Screens[] = [Screens.Home], action:AnyAction){
  switch(action.type){  
    case "pushScreen":
      let newStack = [...state];
      newStack.push(action.payload);
      return  newStack;
    case "popScreen":
      let popStack = [...state];
      popStack.pop();
      return  popStack;
  }
  return state;
}

// infuse organization id = rJVOlT1dz6O1d5PARqqt
export function OrganizationIDReducer(state = "", action:AnyAction){
  switch(action.type){
    case "setOrganizationID":
      return  action.payload;
  }
  return state;
}

export function OrganizationReducer(state:Organization|null = null, action:AnyAction){
  switch(action.type){
    case "setOrganization":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}
export function ProjectsReducer(state:Project[] = [], action:AnyAction){
  switch(action.type){
    case "setProjects":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}
export function ProjectMessageBoardsReducer(state:ProjectMessageBoard[] = [], action:AnyAction){
  switch(action.type){
    case "setProjectMessageBoards":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}
export function DepartmentsReducer(state:Department[] = [], action:AnyAction){
  switch(action.type){
    case "setDepartments":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}
export function RolesReducer(state:Role[] = [], action:AnyAction){
  switch(action.type){
    case "setRoles":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}
export function PersonsReducer(state:Person[] = [], action:AnyAction){
  switch(action.type){
    case "setPersons":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}
export function ClientsReducer(state:Client[] = [], action:AnyAction){
  switch(action.type){
    case "setClients":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}
export function ClientContactsReducer(state:ClientContact[] = [], action:AnyAction){
  switch(action.type){
    case "setClientContacts":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}


export function TimeOffListReducer(state:TimeOff[] = [], action:AnyAction){
  switch(action.type){
    case "setTimeOffList":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}
export function MonthTotalsReducer(state:MonthTotal[] = [], action:AnyAction){
  switch(action.type){
    case "setMonthTotals":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}
export function YearTotalsReducer(state:YearTotal[] = [], action:AnyAction){
  switch(action.type){
    case "setYearTotals":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}
export function ActualsReducer(state:ProjectRoleActualHours[] = [], action:AnyAction){
  switch(action.type){
    case "setActuals":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}
export function PermissionsReducer(state:string[] = [], action:AnyAction){
  switch(action.type){
    case "setPermissions":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}
export function PermissionsWriteReducer(state:string[] = [], action:AnyAction){
  switch(action.type){
    case "setPermissionsWrite":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}
export function ViewsReducer(state:View[] = [], action:AnyAction){
  switch(action.type){
    case "setViews":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}
export function BackupsReducer(state:Backup[] = [], action:AnyAction){
  switch(action.type){
    case "setBackups":
      return  JSON.parse(JSON.stringify(action.payload));
  }
  return state;
}


export async function UndoStackReducer(state:{stack:UndoStackEntry[],top:number} = {stack:[],top:0}, action:AnyAction){
  let oldState = await state;
  switch(action.type){
    case "submitUndoStackEntry":{ //
      let entry:UndoStackEntry = action.payload;
      oldState.stack.splice(oldState.top,oldState.stack.length-oldState.top);
      let newStack = [...oldState.stack];
      newStack.push(entry);
      let newTop = newStack.length;
      return  {stack:newStack,top:newTop};
    }
    case "undo":{ //
      let newTop = await PerformUndo(oldState.stack,oldState.top);
      return  {stack:oldState.stack,top:newTop};
    }
    case "redo":{ //
      let newTop = await PerformRedo(oldState.stack,oldState.top);
      return  {stack:oldState.stack,top:newTop};
    }

  }
  return state;
}







export default {
  ScreenReducer,
	OrganizationIDReducer,
	OrganizationReducer,
	ProjectsReducer,
  ProjectMessageBoardsReducer,
  DepartmentsReducer,
	RolesReducer,
	PersonsReducer,
	ClientsReducer,
  ClientContactsReducer,
  TimeOffListReducer,
  MonthTotalsReducer,
  YearTotalsReducer,
  ActualsReducer,
  PermissionsReducer,
  PermissionsWriteReducer,
  ViewsReducer,
  UndoStackReducer,
  BackupsReducer
}