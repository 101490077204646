
import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./RoleDropdown.css"
import { useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { forceNumeric, prettyCurrency, prettyNum } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import { Client } from "../../FirebaseModel/Client";
interface RoleDropdownProps{
	className?:string,
	defaultValue?:string,
	prompt?:string,
	onChange?:(value:string) => void
	onCreateNew?:()=>void
}

export default function RoleDropdown(props:RoleDropdownProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const allRoles = useSelector((state:{roles:Role[]}) => state.roles)

	const [roles, setRoles] = useState<Role[]>([...allRoles]);
	const [selectedValue, setSelectedValue] = useState(props.defaultValue);

	useEffect(() =>{
		if(organization){
			setRoles([...allRoles]);
		}
	},[allRoles,allRoles])

	useEffect(() =>{
		setSelectedValue(props.defaultValue);
	},[props.defaultValue])

  return (
    <select 
				className={`RoleDropdown ${props.className??""}`} 
				value={selectedValue}
				onChange={(event) =>{
					setSelectedValue(event.target.value);
					if(event.target.value == "create new"){
						if(props.onCreateNew){
							props.onCreateNew();
						}
					}else{
						if(props.onChange){
							props.onChange(event.target.value);
						}
					}
				}}>
			<option value={""}>{props.prompt??"Select Role"}</option>
			{roles.map((role:Role, index:number) =>{
				return <option key={"role_option_"+index} value={role.id}>{role.abbreviation}</option>
			})}
    </select>
  )
}