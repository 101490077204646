
import React, { ReactNode, useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ClientScreenClientsRow.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { Role, rolePersonsDescription } from "../../../FirebaseModel/Role";
import { Person, personCalculatedRoleWeightsFiltered, personRolesDescription } from "../../../FirebaseModel/Person";
import { deleteRole, saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { faAnchor, faBars, faCalendar, faCheck, faCircleNodes, faHamburger, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Tooltip } from "@mui/material";
import { savePersonAttributeChange, deletePerson } from "../../../Utils/SavePersonFunctions";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { Client } from "../../../FirebaseModel/Client";
import ClientsScreenClientsInput from "../ClientsScreenClientsInput/ClientsScreenClientsInput";
import { saveClientAttributeChange, deleteClient } from "../../../Utils/SaveClientFunctions";
import { UndoStackEntry } from "../../../Utils/UndoStackEntry";
import { prettyCurrency } from "../../../Utils/formatting";
export interface ClientScreenClientsRowProps{
	client:Client;
	onEditClientPressed:(client:Client) => void;
}

export default function ClientScreenClientsRow(props:ClientScreenClientsRowProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const allRoles = useSelector((state:{roles:Role[]}) => state.roles)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const projects = useSelector((state:{projects:Project[]}) => state.projects)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
	const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)


	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);
		
	const [roles, setRoles] = useState<Role[]>(allRoles);

	useEffect(() =>{
		setRoles([... allRoles]);
	},[allRoles]);
	
	return (
	<>
		{permissionsWrite.indexOf("Clients")>=0 ? (
			<tr key={`client_${props.client.id}`}>
			<td><ClientsScreenClientsInput attributeKey="name" client={props.client}></ClientsScreenClientsInput></td>
			<td>
				<input type='checkbox' checked={props.client.useOverrideHourlyRate} onChange={(event) =>{
					saveClientAttributeChange(props.client,"useOverrideHourlyRate",event.target.checked,(entry:UndoStackEntry) =>{
						AC.submitUndoStackEntry(entry);
					})
				}}></input>
			</td>
			<td><ClientsScreenClientsInput attributeKey="overrideHourlyRate" className={`${props.client.useOverrideHourlyRate?"":"disabled"}`} forceNumeric={true} isCurrency={true} client={props.client}></ClientsScreenClientsInput></td>
			<div className="buttonDelete"
				onClick={() =>{
					if(window.confirm("Are you sure you'd like to delete this client?")){
						deleteClient(props.client,projects,organization,clients,(entry:UndoStackEntry) =>{
							AC.submitUndoStackEntry(entry);
						});
					}
				}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>

			<div className="buttonEdit"
				onClick={() =>{
					props.onEditClientPressed(props.client);
				}}><FontAwesomeIcon icon={faPencil}></FontAwesomeIcon></div>
		</tr>
		):(
			<tr key={`client_${props.client.id}`}>
				<td>{props.client.name}</td>
				<td>
					{props.client.useOverrideHourlyRate && <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}
				</td>
				<td>{props.client.useOverrideHourlyRate && <>{prettyCurrency(props.client.overrideHourlyRate)}</>}</td>
			</tr>
		)}
	</>
  )
}