
import { useCSVReader } from 'react-papaparse';
import React, { useEffect, useState } from "react"
import { addDoc, collection, deleteDoc, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./PopupImportFromTeamGantt.css"
import { useDispatch, useSelector } from "react-redux";
import { Project, ProjectInvoice } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import { prettyNum, forceNumeric, prettyCurrency, forceDecimal, shortDate } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { saveClientAttributeChange } from "../../Utils/SaveClientFunctions";
import { useRef } from 'react';
import { ProjectRoleActualHours } from '../../FirebaseModel/ProjectRoleActualHours';
import { Person } from '../../FirebaseModel/Person';
import { saveProjectAttributeChange } from '../../Utils/SaveProjectFunctions';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimeOff } from '../../FirebaseModel/TimeOff';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../Redux/ActionCreators';
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from '../../Utils/UndoStackEntry';
interface PopupImportFromTeamGanttProps{
	onDismiss:()=>void;
}

export default function PopupImportFromTeamGantt(props:PopupImportFromTeamGanttProps){

  const progressRef = useRef<HTMLProgressElement>(null);
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
  const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)
  const timeOffList = useSelector((state: { timeOffList: TimeOff[] }) => state.timeOffList)

  const { CSVReader } = useCSVReader();

  const [allRows, setAllRows] = useState<any[]>([]);

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);


  const DATE_START_COL = 4;

  let updateProjectsCalculatedActuals = async (modifiedProjects:Project[]) =>{
  }

  useEffect(() =>{
    updateProjectsCalculatedActuals = async (modifiedProjects:Project[]) =>{
      for(let p in modifiedProjects){
        let project = modifiedProjects[p];

				let actualsForProject = actuals.filter(actual => actual.projectID === project.id);
				let totalHours = actualsForProject.reduce((total, actual) => total + actual.calculated.totalHours, 0);
				if(project.calculated.actualHours != totalHours){
					let newCalcualted = {...project.calculated}
					if(newCalcualted.actualHours != totalHours){
						newCalcualted.actualHours = totalHours;
						await saveProjectAttributeChange(project, organization,clients, "calculated", newCalcualted,(entry:UndoStackEntry) =>{
              AC.submitUndoStackEntry(entry);
            })
					}
				}
      }
    }
  },[actuals])



  const collapseRows = (rows: any[]) => {
    let collapsedRows: any[] = [];

    collapsedRows.push(rows[0]);

    let currentProjectName = "";
    let currentGroupName = "";


    for(let r = 1; r < rows.length; r++) {
      let row = rows[r];
      let projectNumber = row[1];
      let type = row[3];
      let otherRow:any[] =[];
      switch(type){
        case "project":
          currentProjectName = row[0];
          currentGroupName = "";
          break;
        case "group":
          currentGroupName = row[0];
          if(currentGroupName.toLowerCase().indexOf("sto") != -1){
            currentGroupName = "STO";
          }else{
            currentGroupName = "PTO";
          }
          break;
      }
      if(currentProjectName.toLowerCase().indexOf("vacation") != -1 && type == "task"){
        row[0] = currentGroupName;
        otherRow = collapsedRows.find((r: any) => r[0] == currentGroupName &&r[1] == projectNumber && r[3] == type);
      }else{
        otherRow = collapsedRows.find((r: any) => r[1] == projectNumber && r[3] == type);
      }
      if (otherRow == null) {
        collapsedRows.push([...row]);
      } else {
        for (let c = 4; c < row.length; c++) {
          if (row[c] != "") {
            otherRow[c] = parseFloat(otherRow[c]==""?"0":otherRow[c]) + parseFloat(row[c]==""?"0":row[c]);
          }
        }
      }
    }

    return collapsedRows;
  } 

  function parseTeamGanttDate(input: string) {
    if(input.indexOf('/') > -1){
      let parts = input.split('/');
      let year = parseInt(parts[2]);
      if(year < 100){
        year += 2000;
      }
      let month = parseInt(parts[0]);
      let day = parseInt(parts[1]);
      return new Date(year,month-1,day); // Note: months are 0-based
    }else{

      let parts = input.split('-');

      // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
      return new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2])); // Note: months are 0-based
    }

  }

  const getClientAndProjectName = (input: string) => {
    if (input.indexOf("-") != -1) {
      const parts = input.split("-");
      return {
        clientName: parts[0],
        projectName: input,
      };
    }
    if (input.indexOf(":") != -1) {
      const parts = input.split(":");
      return {
        clientName: parts[0],
        projectName: input,
      };
    }
    return {
      clientName: "",
      projectName: input,
    };
  }

  const createProjectIfNotExists = async (projectNumber:string, projectName:string) =>{
    let project = projects.find(p => p.projectNumber == projectNumber);
    if(project){
      return project;
    }
    let newProject = {
      organizationID: organization.id,
      projectNumber: projectNumber,
      projectName: projectName,
      clientID: "",
      monthClosedWon: new Date().getMonth()+1,
      yearClosedWon: new Date().getFullYear(),
      sfClosedWon: 0,
      accountManagerID: '',
      projectManagerID: '',
      regionalSalesManagerID: '',
      overrideHourlyRate: 0,
      useOverrideHourlyRate: false,
      isContractSigned:false,
      status: '',
      statusHistory: [] as {date:string, status:string}[],
      projectClientContacts: [] as {clientContactID:string, primaryContact:boolean}[],
      calculated: {
        actualHours: 0,
        plannedHours: 0,
        differenceActualToBilled:0,
		    latestActivity:"",
        currentPlusMinus:0,
        totalInvoiced:0,
        invoicesCollected:0,
        invoicesOutstanding:0,
      },
      plannedHours: [] as {roleID:string, hours:number}[],
      roleIDs: [] as string[],
      departmentIDs: [] as string[],
      comments: '',
      bidWorksheet: {
        roles:[] as {roleID:string, hours:number, sowPrice:number}[],
        fixedCosts:[] as {fixedCostItem:string, cost:number, sowPrice:number}[],
        discount:0,
        calculated:{
          totalHours:0,
          totalSOWPrice:0,
          totalFixedCosts:0,
          totalExtraMargin:0,
          totalProductionBudget:0,
        }
      },
      retrospective:{
        date:"",
        attendingClientContactIDs:[] as string[],
        whatWentWell:"",
        whatCouldHaveGoneBetter:"",
        whatWillMakeTheNextProjectBetter:"",
        clientWhatChallengesAreYouLookingToSolve:"",
        clientWhatUpcomingEventsAreYouPreparingFor:"",
        clientWhoElseInYourOrganizationCouldWeHelp:"",
        notes:"",
      },
      pmEstimatedPercentComplete:0,
      amPercentToInvoice:0,
      pmNotes:"",
      amNotes:"",
      adminNotes:"",
      invoices:[] as ProjectInvoice[],
    }

    roles.forEach(role =>newProject.bidWorksheet.roles.push({ roleID: role.id, hours: 0, sowPrice: 0 }));
    organization!.fixedCostItemsList.forEach(fixedCost => {newProject.bidWorksheet.fixedCosts.push({fixedCostItem:fixedCost, cost:0, sowPrice:0})})

    let n
    let newProjectDoc = await addDoc(collection(sharedFirestore, "Project"), newProject);
    let proj = {id: newProjectDoc.id, ...newProject} as Project;

    AC.submitUndoStackEntry({
      type: UndoStackEntryType.create,
      objectType: UndoStackEntryObjectType.project,
      objectID: newProjectDoc.id,
      objectBeforeState: undefined,
      objectAfterState: proj,
      description: 'Create Project'
    })

    projects.push(proj);
    return proj
  }

  const saveNewHours = async (actual:ProjectRoleActualHours, hours:{date:string, hours:number}[]) =>{
    let newHours = actual.hours;
    for(let h in hours){
      newHours.push(hours[h]);
    }
    let totalHours = 0;
    for(let h in newHours){
      totalHours += newHours[h].hours??0;
    }
    if(newHours.length > 0){
      let updatedActual = {
        ...actual,
        hours: newHours,
        calculated: {
          totalHours: totalHours
        }
      }
      AC.submitUndoStackEntry({
        type: UndoStackEntryType.update,
        objectType: UndoStackEntryObjectType.actual,
        objectID: actual.id,
        objectBeforeState: {...actual},
        objectAfterState: {...updatedActual},
        description: 'Update Project Role Actaul Hours'
      })
      actual.hours = newHours;
      actual.calculated = updatedActual.calculated;
      return updateDoc(doc(sharedFirestore, "ProjectRoleActualHours", actual.id), updatedActual)
    }else{

      AC.submitUndoStackEntry({
        type: UndoStackEntryType.delete,
        objectType: UndoStackEntryObjectType.actual,
        objectID: actual.id,
        objectBeforeState: {...actual},
        objectAfterState: undefined,
        description: 'Delete Project Role Actaul Hours'
      })
      return deleteDoc(doc(sharedFirestore, "ProjectRoleActualHours", actual.id));
    }
  }

  const saveNewSTOHours = async (timeOff: TimeOff, hours: { date: string, hours: number }[]) => {
    // let newHours = timeOff.hoursTakenSTO;
    // for (let h in hours) {
    //   newHours.push(hours[h]);
    // }
    let totalHours = 0;
    for (let h in hours) {
      totalHours += hours[h].hours??0;
    }
    let updatedTimeOff = {
      ...timeOff,
      hoursTakenSTO: hours,
      calculated: {
        ...timeOff.calculated,
        totalHoursTakenSTO: totalHours,
      }
    }

    AC.submitUndoStackEntry({
      type: UndoStackEntryType.update,
      objectType: UndoStackEntryObjectType.timeOff,
      objectID: timeOff.id,
      objectBeforeState: {...timeOff},
      objectAfterState: {...updatedTimeOff},
      description: 'Update Person Time Off'
    })
    timeOff.hoursTakenPTO = updatedTimeOff.hoursTakenPTO;
    timeOff.calculated = updatedTimeOff.calculated;
    return updateDoc(doc(sharedFirestore, "TimeOff", timeOff.id), updatedTimeOff)
  }
  const saveNewPTOHours = async (timeOff: TimeOff, hours: { date: string, hours: number }[]) => {
    // let newHours = timeOff.hoursTakenPTO;
    // for (let h in hours) {
    //   newHours.push(hours[h]);
    // }
    let totalHours = 0;
    for (let h in hours) {
      totalHours += hours[h].hours??0;
    }
    let updatedTimeOff ={
      ...timeOff,
      hoursTakenPTO: hours,
      calculated: {
        ...timeOff.calculated,
        totalHoursTakenPTO: totalHours,
      }
    }

    AC.submitUndoStackEntry({
      type: UndoStackEntryType.update,
      objectType: UndoStackEntryObjectType.timeOff,
      objectID: timeOff.id,
      objectBeforeState: {...timeOff},
      objectAfterState: {...updatedTimeOff},
      description: 'Update Person Time Off'
    })
    timeOff.hoursTakenPTO = updatedTimeOff.hoursTakenPTO;
    timeOff.calculated = updatedTimeOff.calculated;
    return updateDoc(doc(sharedFirestore, "TimeOff", timeOff.id),updatedTimeOff )
  }



  const getPersonByName = (name:string) =>{

    if(name== "EVERYONE"){
      return null;
    }
    let category = "";
    let firstName = "";
    let lastName = "";
    if( name.indexOf("-")!= -1){
      let parts = name.split("-");
      category = parts[0];
      parts = parts[1].split(" ");
      firstName = parts[0];
      lastName = parts[1];
    }else{
      let parts = name.split(" ");
      firstName = parts[0];
      lastName = parts[1];
    }

    let trimmedName = firstName + " " + lastName;
  
    for(let p in persons){
      if(trimmedName.toLowerCase() == persons[p].name.toLowerCase()){
        return persons[p];
      }
    }
    return null;
  }

  const findActual = async (project:Project, personID:string, year:number) =>{
    return new Promise((resolve:(actual:ProjectRoleActualHours)=>void, reject) => {
      for(let a in actuals){
        if(actuals[a].projectID == project.id && actuals[a].personID == personID && actuals[a].year == year){
          resolve( actuals[a]);
          return;
        }
      }
      let person = persons.find(p => p.id == personID)!;
      let newActual = {
        organizationID: organization.id,
        projectID: project.id,
        personID: personID,
        roleID: person.roles?(person.roles!.length>0?person.roles![0].roleID:""):"",
        year: year,
        billable: true,
        order: 0,
        hours: [] as {date:string, hours:number}[],
        calculated: {
          totalHours: 0
        }
      }
      addDoc(collection(sharedFirestore, "ProjectRoleActualHours"), newActual)
        .then((doc) => {
          let actual = {id: doc.id, ...newActual} as ProjectRoleActualHours;
          actuals.push(actual);

          AC.submitUndoStackEntry({
            type: UndoStackEntryType.create,
            objectType: UndoStackEntryObjectType.actual,
            objectID: doc.id,
            objectBeforeState: undefined,
            objectAfterState: {...actual},
            description: 'Created Project Role Actual Hours'
          })
          resolve(actual);
      });
    })
  }


  const findTimeOff = async (personID: string, year: number) => {
    return new Promise((resolve: (actual: TimeOff) => void, reject) => {

      let timeOff = timeOffList.find(timeOff => timeOff.personID == personID && timeOff.year == year);
      if (timeOff == null) {
        let timeOffDocData = {
          organizationID: organization.id,
          personID: personID,
          year: year,
          hoursTakenSTO: [],
          hoursTakenPTO: [],
          allottedPTO: 0,
          allottedSTO: 0,
          calculated: {
            totalHoursTakenPTO: 0,
            totalHoursTakenSTO: 0,
          }
        };
        addDoc(collection(sharedFirestore, "TimeOff"), timeOffDocData).then((docref) => {
          let timeOff = { ...timeOffDocData, id: docref.id } as TimeOff;


          AC.submitUndoStackEntry({
            type: UndoStackEntryType.create,
            objectType: UndoStackEntryObjectType.timeOff,
            objectID: docref.id,
            objectBeforeState: undefined,
            objectAfterState: {...timeOff},
            description: 'Created Person Time Off'
          })
          resolve(timeOff);
        })
      } else {
        resolve(timeOff);
      }
    })
  }
  const getRemainingHours = (actual:ProjectRoleActualHours, startDate:Date, endDate:Date) =>{
    let results:{date:string, hours:number}[] = [];
    for(let i in actual.hours){
      let date = new Date(actual.hours[i].date);
      if(date < startDate || date > endDate){
        results.push(actual.hours[i]);
      }
    }
    return results;
  }

  const getRemainingSTOHours = (timeOff: TimeOff, startDate: Date, endDate: Date) => {
    let results: { date: string, hours: number }[] = [];
    for (let i in timeOff.hoursTakenSTO) {
      let date = new Date(timeOff.hoursTakenSTO[i].date);
      if (date < startDate || date > endDate) {
        results.push(timeOff.hoursTakenSTO[i]);
      }
    }
    return results;
  }

  const getRemainingPTOHours = (timeOff: TimeOff, startDate: Date, endDate: Date) => {
    let results: { date: string, hours: number }[] = [];
    for (let i in timeOff.hoursTakenPTO) {
      let date = new Date(timeOff.hoursTakenPTO[i].date);
      if (date < startDate || date > endDate) {
        results.push(timeOff.hoursTakenPTO[i]);
      }
    }
    return results;
  }



  const processFile = async (rows: any) =>{
    setAllRows(rows);
  }
  const startImport = async (rows: any) =>{

    AC.submitUndoStackEntry({
      type: UndoStackEntryType.batchStart,
      objectType: UndoStackEntryObjectType.nonApplicable,
      objectID: '',
      objectBeforeState: undefined,
      objectAfterState: undefined,
      description: 'Import from team gantt'
    })

    let collapsedRows = collapseRows(rows);

    let dates:Date[] = [];
    let currentName = "";
    let currentProjectName = "";
    let currentGroupName = "";
    let currentPerson:any|null = null;
    let currentProjectNumber = "";
    let currentTask = "";
    let currentTaskID = "";

    let currentProject:Project|null = null;

    let firstDate:Date|null = null;
    let lastDate:Date|null = null;

    let modifiedProjects:Project[] = [];


    progressRef.current!.max = rows.length;
    progressRef.current!.style.display = "block";

    function startNextRow(i:number){
      setTimeout(() => {
        processNextRow(i);
      }, 100);
    }

    //for(let i = 0; i < rows.length; i++){
    async function processNextRow(i:number){
      if(i >= collapsedRows.length){
        console.log("Done processing file");
        progressRef.current!.style.display = "none";
        setTimeout(async () => {
          await updateProjectsCalculatedActuals(modifiedProjects);

          AC.submitUndoStackEntry({
            type: UndoStackEntryType.batchEnd,
            objectType: UndoStackEntryObjectType.nonApplicable,
            objectID: '',
            objectBeforeState: undefined,
            objectAfterState: undefined,
            description: 'Import from team gantt'
          })

          alert("Done");
        }, 100);
        return;
      }
      let row = collapsedRows[i];
      console.log(`Processing row ${i} of ${collapsedRows.length}...`);
      //console.log(row);
      progressRef.current!.value = i;
      if(row[1] == "ProjectID"){
        currentName = row[0];
        currentPerson = getPersonByName(currentName);
        dates = [];
        for(var c = 4; c < row.length - 1; c++ ){
          dates[c] = parseTeamGanttDate(row[c]);
        }
        firstDate = dates[4];
        lastDate = dates[row.length-2];
      }else if(currentPerson != null){
        switch(row[3]){
          case "project":
            currentProjectName = row[0];
            currentGroupName = "";
            currentProjectNumber = row[1];
            currentProject = null;
            if(currentProjectName.toLowerCase().indexOf("vacation") ==-1){
              currentProject = await createProjectIfNotExists(currentProjectNumber, currentProjectName);
              modifiedProjects.push(currentProject);
            }
            break;
          case "group":
            currentGroupName = row[0];
            break;
          case "task":
            currentTask = row[0].trim();
            currentTaskID = row[2];
            // if(currentProject == null){
            //   console.log("No project for " + currentName + " " + currentTask);
            //   startNextRow(i+1);
            //   return;
            //   //continue;
            // }
            if(currentPerson == null){
              console.log("No person for " + currentName);
              startNextRow(i+1);
              return;
              //continue;
            }
            // if( currentProjectName.toLowerCase().indexOf("vacation") !=-1){
            //   console.log("Vacation project skipped");
            //   startNextRow(i+1);
            //   return;
            //   //continue;
            // }
            let hasHours = false;

            for(let c = 4; c < row.length - 1; c++ ){
              let hours = (row[c]&&row[c]!="")?parseFloat(row[c]):0;
              if(hours > 0){
                hasHours = true;
                break;
              }
            }
            if(hasHours == false){
              console.log("No hours for " + currentName + " " + currentTask);
              startNextRow(i+1);
              return;
              //continue;
            }

            let headerRow = collapsedRows[0];
            let lastColumn = headerRow.length - 1;

            dates = [];
            for (var c = DATE_START_COL; c < headerRow.length; c++) {
              if (headerRow[c] == "") {
                lastColumn = c - 1;
                break;
              }
              dates[c] = parseTeamGanttDate(headerRow[c]);
            }
            firstDate = dates[DATE_START_COL];
            lastDate = dates[lastColumn];

            if(currentProjectName.toLowerCase().indexOf("vacation") ==-1){

              let actual = await findActual(currentProject!, currentPerson.id, firstDate!.getFullYear());
              let newHours = getRemainingHours(actual, firstDate!, lastDate!);
              let cutoffDate = new Date(firstDate!);
              for(let c = DATE_START_COL; c < lastColumn; c++ ){
                let hours = (row[c]&&row[c]!="")?parseFloat(row[c]):0;
                if(hours > 0){
                  if(dates[c].getFullYear() != cutoffDate.getFullYear()){
                    //console.log("Saving hours for " + currentName + " " + currentTask, newHours)
                    await saveNewHours(actual, newHours);
                    cutoffDate = new Date(dates[c]);
                    actual = await findActual(currentProject!, currentPerson.id, dates[c].getFullYear());
                    newHours = getRemainingHours(actual, dates[c], lastDate!);
                  }
                  newHours.push({
                    date:shortDate(dates[c]),
                    hours:hours
                  })
                }
              }
              //console.log("Saving hours for " + currentName + " " + currentTask, newHours)
              await saveNewHours(actual, newHours);
            }else if(currentTask.toLowerCase().indexOf("sto")!= -1){//sto

              let timeOff = await findTimeOff(currentPerson!.id, firstDate!.getFullYear());
              let newHours = getRemainingSTOHours(timeOff, firstDate!, lastDate!);
              let cutoffDate = new Date(firstDate!);
              for (let c = DATE_START_COL; c <= lastColumn; c++) {
                let hours = (row[c]&&row[c]!="")?parseFloat(row[c]):0;
                if (hours > 0) {
                  if (dates[c].getFullYear() != cutoffDate.getFullYear()) {
                    //console.log("Saving hours for " + currentName + " " + currentTask, newHours)
                    await saveNewSTOHours(timeOff, newHours);
                    cutoffDate = new Date(dates[c]);
                    timeOff = await findTimeOff(currentPerson!.id, dates[c]!.getFullYear());
                    newHours = getRemainingSTOHours(timeOff, dates[c], lastDate!);
                  }
                  newHours.push({
                    date: shortDate(dates[c]),
                    hours: hours
                  })
                }
              }
              await saveNewSTOHours(timeOff, newHours);
            }else{//pto
              let timeOff = await findTimeOff(currentPerson!.id, firstDate!.getFullYear());
              let newHours = getRemainingPTOHours(timeOff, firstDate!, lastDate!);
              let cutoffDate = new Date(firstDate!);
              for (let c = DATE_START_COL; c <= lastColumn; c++) {
                let hours = (row[c]&&row[c]!="")?parseFloat(row[c]):0;
                if (hours > 0) {
                  if (dates[c].getFullYear() != cutoffDate.getFullYear()) {
                    //console.log("Saving hours for " + currentName + " " + currentTask, newHours)
                    await saveNewPTOHours(timeOff, newHours);
                    cutoffDate = new Date(dates[c]);
                    timeOff = await findTimeOff(currentPerson!.id, dates[c]!.getFullYear());
                    newHours = getRemainingPTOHours(timeOff, dates[c], lastDate!);
                  }
                  newHours.push({
                    date: shortDate(dates[c]),
                    hours: hours
                  })
                }
              }
              await saveNewPTOHours(timeOff, newHours);
            }
        }
      }
      startNextRow(i+1);
    }

    startNextRow(0);
  }


  return (
    <div className={`popup PopupImportFromTeamGantt`}>
			<h1>Import From Team Gantt Time Tracking Report CSV</h1>

      <div className='instructions'>
        This is a CSV file that you can export from Team Gantt. 
        <br/>
        It is found under Reports &gt; Time Tracking
      </div>

      <CSVReader
        onUploadAccepted={async (results: any) => {
          console.log('---------------------------');
          console.log(results);
          console.log('---------------------------');
          processFile(results.data);
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }: any) => (
          <>
            <div className='csvReader'>
              <button type='button' {...getRootProps()} className={"browseFile"}>
                Browse file
              </button>
              <div className={"acceptedFile"}>
                {acceptedFile && acceptedFile.name}
              </div>
              <button {...getRemoveFileProps()} className={"remove"}>
                Remove
              </button>
            </div>
            {/* <ProgressBar  className={"progressBarBackgroundColor"} /> */}
          </>
        )}
      </CSVReader>
      {allRows.length > 0 && (
        <div className='buttonImport'
          onClick={() =>{
            startImport(allRows)
            setAllRows([]);
          }}><FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon> Start Import</div>
      )}
      <progress ref={progressRef} style={{display:'none'}} className={"progressBarBackgroundColor"} />

				<div className="floatingButtons">
					<div className="buttonSave"
						onClick={() =>{
							projects.forEach( project =>{
								let actualsForProject = actuals.filter(actual => actual.projectID === project.id);
								let totalHours = actualsForProject.reduce((total, actual) => total + actual.calculated.totalHours, 0);
								if(project.calculated.actualHours != totalHours){
									let newCalcualted = {...project.calculated}
									if(newCalcualted.actualHours != totalHours){
										newCalcualted.actualHours = totalHours;
										saveProjectAttributeChange(project, organization,clients, "calculated", newCalcualted,(entry:UndoStackEntry) =>{
                      AC.submitUndoStackEntry(entry);
                    })
									}
								}
							})
							props.onDismiss();
						}}>Done</div>
					</div>
		</div>
  )
}