import { doc, updateDoc } from "firebase/firestore";
import { Organization } from "../FirebaseModel/Organization";
import { sharedFirestore } from "./SharedFirebase";
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from "./UndoStackEntry";

export function saveOrganizationAttributeChange(organization:Organization, attributeKey:string|string[], value:any|any[],undoEntryHandler:(entry:UndoStackEntry)=>void){


	const projectRef = doc(sharedFirestore, "Organization", organization.id);

	let updatedOrganization:{ [x: string]: any; } = {};
	for(var key in organization){
		if(attributeKey instanceof Array){
			for(var key2 in attributeKey){
				if(key == attributeKey[key2]){
					updatedOrganization[key] = value[key2];
					break;
				}else{
					updatedOrganization[key] = (organization as any)[key];
				}
			}
		}else{
			if(attributeKey){
				if(key == attributeKey){
					updatedOrganization[key] = value;
				}else{
					updatedOrganization[key] = (organization as any)[key];
				}
			}else{
				updatedOrganization[key] = (organization as any)[key];
			}
		}
	}

	let undoEntry:UndoStackEntry = {
		type: UndoStackEntryType.update,
		objectType: UndoStackEntryObjectType.organization,
		objectID: organization.id,
		objectBeforeState: {...organization},
		objectAfterState: {...updatedOrganization},
		description: "Update Organization"
	}
	undoEntryHandler(undoEntry);

	return updateDoc(projectRef,updatedOrganization)
}
