


import React, { useEffect, useState } from "react"
import { sharedFirestore } from "../../Utils/SharedFirebase"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ImportScreen.css"
import { Project } from "../../FirebaseModel/Project";
import { useSelector } from "react-redux";
import PopupImportFromTeamGantt from "../../Popups/PopupImportFromTeamGantt/PopupImportFromTeamGantt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import NavigationButtons from "../../Components/NavigationButtons/NavigationButtons";
import PopupImportProjects from "../../Popups/PopupImportProjects/PopupImportProjects";
import PopupImportPeople from "../../Popups/PopupImportPeople/PopupImportPeople";
import PopupImportClients from "../../Popups/PopupImportClients/PopupImportClients";
import PopupImportTimesheet from "../../Popups/PopupImportTimesheet/PopupImportTimesheet";
import PopupImportInvoices from "../../Popups/PopupImportInvoices/PopupImportInvoices";
interface ImportScreenProps{
}

export default function ImportScreen(props:ImportScreenProps){

	const [showingImportFromTeamGantt, setShowingImportFromTeamGantt] = useState(false)
	const [showingImportProjectsFromCSV, setShowingImportProjectsFromCSV] = useState(false)
	const [showingImportInvoicesFromCSV, setShowingImportInvoicesFromCSV] = useState(false)
	const [showingImportPeopleFromCSV, setShowingImportPeopleFromCSV] = useState(false)
	const [showingImportActualsFromCSV, setShowingImportActualsFromCSV] = useState(false)
	const [showingImportClientFromCSV, setShowingImportClientFromCSV] = useState(false)
  return (
		<>
	    <div className="screen ImportScreen">
				<h1>Import Portal</h1>
				
				<NavigationButtons />

				<div className="filters">
					<h3>options</h3>
				</div>

				<div className="imports">

				<div className="option"
						onClick={() =>{
							setShowingImportProjectsFromCSV(true);
						}}>
						<div className="icon"><FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon></div>						
						Import Projects From CSV
					</div>

					<div className="option"
						onClick={() =>{
							setShowingImportInvoicesFromCSV(true);
						}}>
						<div className="icon"><FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon></div>						
						Import Invoices From CSV
					</div>

					<div className="option"
						onClick={() =>{
							setShowingImportClientFromCSV(true);
						}}>
						<div className="icon"><FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon></div>						
						Import Clients From CSV
					</div>

					<div className="option"
						onClick={() =>{
							setShowingImportPeopleFromCSV(true);
						}}>
						<div className="icon"><FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon></div>						
						Import Team Members From CSV
					</div>


					<div className="option"
						onClick={() =>{
							setShowingImportActualsFromCSV(true);
						}}>
						<div className="icon"><FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon></div>						
						Import Timesheet Hours From CSV
					</div>

					<div className="option"
						onClick={() =>{
							setShowingImportFromTeamGantt(true);
						}}>
						<div className="icon"><FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon></div>						
						Import from Team Gantt
					</div>
				</div>
				
	    </div>
			{showingImportFromTeamGantt && (
				<PopupImportFromTeamGantt onDismiss={() => {
					setShowingImportFromTeamGantt(false);
				} }></PopupImportFromTeamGantt>
			)}
			{showingImportProjectsFromCSV && (
				<PopupImportProjects onDismiss={() => {
					setShowingImportProjectsFromCSV(false);
				} }></PopupImportProjects>
			)}
			{showingImportPeopleFromCSV && (
				<PopupImportPeople onDismiss={() => {
					setShowingImportPeopleFromCSV(false);
				} }></PopupImportPeople>
			)}
			{showingImportClientFromCSV && (
				<PopupImportClients onDismiss={() => {
					setShowingImportClientFromCSV(false);
				} }></PopupImportClients>
			)}
			{showingImportActualsFromCSV && (
				<PopupImportTimesheet onDismiss={() => {
					setShowingImportActualsFromCSV(false);
				} }></PopupImportTimesheet>
			)}
			{showingImportInvoicesFromCSV && (
				<PopupImportInvoices onDismiss={() => {
					setShowingImportInvoicesFromCSV(false);
				} }></PopupImportInvoices>
			)}
			
		</>
  )
}