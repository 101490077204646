import { BSPColumn, BlankBSPColumn, MonthTotal } from "../FirebaseModel/MonthTotal";
import { Organization } from "../FirebaseModel/Organization";
import { Person } from "../FirebaseModel/Person";
import { Project } from "../FirebaseModel/Project";
import { Role } from "../FirebaseModel/Role";
import { YearTotal } from "../FirebaseModel/YearTotal";
import { getOrCreateMonthTotal, saveMonthTotalAttributeChange } from "./SaveMonthTotalFunctions";
import { saveYearTotalAttributeChange } from "./SaveYearTotalFunctions";
import { UndoStackEntry } from "./UndoStackEntry";

export async function CalculateMonthlyBSP(monthTotal:MonthTotal, projects:Project[],roles:Role[], people:Person[],organization:Organization,undoEntryHandler:(entry:UndoStackEntry)=>void){
	
	let activeProjects = ProjectsActiveInMonth(monthTotal.month, monthTotal.year, projects, organization);
	
	let newBSPProjects = [...monthTotal.bspProjects];

	for(var i in activeProjects){
		let project = activeProjects[i];
		let foundProject = false;
		for(var j in monthTotal.bspProjects){
			let bspProject = monthTotal.bspProjects[j];
			if(bspProject.projectID == project.id){
				foundProject = true;
				break;
			}
		}
		if(!foundProject){
			newBSPProjects.push({
				projectID: project.id,
				firstDayPercentComplete: 0,
				lastDayPercentComplete: 0
			});
		}
	}


	let bsp:BSPColumn = {
		... BlankBSPColumn
	}

	for(var i in people){
		let person = people[i];
		if(person.active && person.roles){
			person.roles.forEach((personRole)=>{
				let role = roles.find((r)=>r.id == personRole.roleID);
				if(role){
					bsp.resourcesHave += personRole.weight;
					bsp.resourcesHaveWeighted += personRole.weight * role!.billableWeight
				}
			})
		}
	}


	bsp.projectsCount = newBSPProjects.length;

	for(var i in newBSPProjects){
		let bspProject = newBSPProjects[i];
		let project = projects.find((p)=>p.id == bspProject.projectID);
		if(project){
			if(bspProject.lastDayPercentComplete == 0){
				bsp.projectsStalled++;
			}else if(bspProject.lastDayPercentComplete == 1){
				bsp.projectsCompleted++;
			}else if(bspProject.lastDayPercentComplete < .01){
				bsp.projects1Percent++;
			}else if(bspProject.lastDayPercentComplete < .1){
				bsp.projects2to9percent++;
			}else{
				bsp.projects10to100percent++;
			}

			bsp.totalContractDollars += project.sfClosedWon;
			bsp.averageProjectValue += project.sfClosedWon;
			bsp.beginningScope += (1-bspProject.firstDayPercentComplete) * project.sfClosedWon;
			bsp.endingScope += (1-bspProject.lastDayPercentComplete) * project.sfClosedWon;
			bsp.billableScopeProduced = bsp.beginningScope - bsp.endingScope;
		}
	}

	bsp.averageProjectValue = bsp.averageProjectValue / bsp.projectsCount;
	bsp.averageBSPPerResourcePerWeek = bsp.billableScopeProduced * 12 / 52 / organization.hourlyRate / bsp.resourcesHave;
	bsp.averageBSPPerResourcePerWeekWeighted = bsp.billableScopeProduced * 12 / 52 / organization.hourlyRate / bsp.resourcesHaveWeighted;

	let calculated = {...monthTotal.calculated};
	calculated.bsp = bsp;

	await saveMonthTotalAttributeChange(monthTotal, ["bspProjects", "calculated"], [newBSPProjects, calculated],undoEntryHandler);
}

export async function CalculateAnnualBSP(yearTotal:YearTotal, monthTotals:MonthTotal[], projects:Project[],roles:Role[], people:Person[],organization:Organization,undoEntryHandler:(entry:UndoStackEntry)=>void){
		let yearMonthTotals = [
			(await getOrCreateMonthTotal(12, yearTotal.year-1, monthTotals, organization,undoEntryHandler)),
		];
		let today = new Date();
		for(var i = 1; i <= 12; i++){
			if(i < today.getMonth()+1 || yearTotal.year < today.getFullYear()){
				let monthTotal = await getOrCreateMonthTotal(i, yearTotal.year, monthTotals, organization,undoEntryHandler);
				yearMonthTotals.push(monthTotal);
			}
		}

		for(var i =0; i < yearMonthTotals.length; i++){
			let monthTotal = yearMonthTotals[i];
			await CalculateMonthlyBSP(monthTotal,projects,roles,people,organization,undoEntryHandler);			
		}

		let averageBSP:BSPColumn = {
			... BlankBSPColumn
		}
		let averageBSP_3Months:BSPColumn = {
			... BlankBSPColumn
		}
		let averageBSP_6Months:BSPColumn = {
			... BlankBSPColumn
		}

		for(var i = 1; i < yearMonthTotals.length - 1; i++){
			let prevCalculated = {...yearMonthTotals[i-1].calculated};
			let newCalculated = {...yearMonthTotals[i].calculated};

			newCalculated.bsp.productionBudgetPreviousMonth = prevCalculated.bsp.billableScopeProduced;
			newCalculated.bsp.bspToSalesRatio = newCalculated.bsp.billableScopeProduced / newCalculated.bsp.productionBudgetPreviousMonth;
		}

		yearMonthTotals.splice(0,1); //remove the first month, which is the previous year

		let avg = (totals:MonthTotal[], key:string) =>{
			let sum = totals.reduce((sum, total)=>sum + (total.calculated.bsp as any)[key], 0);
			if(totals.length == 0){
				return 0;
			}
			return sum / totals.length;
		}

		for(var key in averageBSP){
			(averageBSP as any)[key] = avg(yearMonthTotals, key);
		}
		if(yearMonthTotals.length > 3){
			for(var key in averageBSP_3Months){
				(averageBSP_3Months as any)[key] = avg(yearMonthTotals.slice(-3), key);
			}
		}
		if(yearMonthTotals.length > 6){
			for(var key in averageBSP_6Months){
				(averageBSP_6Months as any)[key] = avg(yearMonthTotals.slice(-6), key);
			}
		}

		let newCalculated = {
			...yearTotal.calculated,
		}

		newCalculated.averageBSP = averageBSP;

		newCalculated.averageBSP_3Months = averageBSP_3Months;
		newCalculated.averageBSP_6Months = averageBSP_6Months;

		await saveYearTotalAttributeChange(yearTotal, ["calculated"], [newCalculated],undoEntryHandler);
		

}


export function ProjectsActiveInMonth(month:number, year:number, projects:Project[],organization:Organization){
	let results:Project[] = [];

	for(var i in projects){
		let project = projects[i];
		let prevDate = new Date(`${project.monthClosedWon}/1/${project.yearClosedWon}`);
		for(var j in project.statusHistory){
			let status = project.statusHistory[j];
			let statuses:string[] = (organization.projectStatusCategories as any)["active"] as string[];
			let statusDate = new Date(status.date);
			if(statuses.indexOf(status.status) > -1){
				let firstOfMonthPref = new Date(prevDate);
				firstOfMonthPref.setDate(1);
				let lastOfMonthStatusDate = new Date(statusDate);
				lastOfMonthStatusDate.setMonth(lastOfMonthStatusDate.getMonth() + 1);
				lastOfMonthStatusDate.setDate(0);
				if(statusDate >= firstOfMonthPref && statusDate <= lastOfMonthStatusDate){
					results.push(project);
					break;
				}
				prevDate = statusDate;
			}
		}
	}

	return results;
}

