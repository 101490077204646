import React, { useEffect, useState } from "react"
import "./OrganizationAdminCard.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { ClientContact } from "../../../FirebaseModel/ClientContact";
import { Person } from "../../../FirebaseModel/Person";

interface OrganizationAdminCardProps{
	personID:string;
	isReadOnly?:boolean;
	onDelete:(personID:string) => void;
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function OrganizationAdminCard(props:OrganizationAdminCardProps){	
	let clientContacts = useSelector((state:{clientContacts:ClientContact[]}) => state.clientContacts);
	let persons = useSelector((state:{persons:Person[]}) => state.persons);
	let person = persons.find((person) => person.id===props.personID);
  return (
    <div className={`OrganizationAdminCard`}>
			<div className="name">{person?.name}</div>
			<div className="email">{person?.email}</div>

			{!props.isReadOnly && (
				<Tooltip title="Remove admin from this organization" placement="bottom">
					<div className="buttonDelete" onClick={() => props.onDelete(props.personID)}>
						<FontAwesomeIcon icon={faTrash} />
					</div>
				</Tooltip>
			)}
    </div>
  )
}