import React, { ReactNode, useEffect, useState } from "react"
import "./HomeTaskTable.css"
import { useDispatch, useSelector } from "react-redux";
import { Project, ProjectTask } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Client } from "../../../FirebaseModel/Client";
import { getOrCreateMonthTotal, saveMonthTotalAttributeChange } from "../../../Utils/SaveMonthTotalFunctions";
import { MonthTotal } from "../../../FirebaseModel/MonthTotal";
import DraggableTableBody from "../../DraggableTableBody/DraggableTableBody";
import { Person } from "../../../FirebaseModel/Person";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Role } from "../../../FirebaseModel/Role";
import { sharedFirebaseAuth } from "../../../Utils/SharedFirebase";
import HomeTaskRow from "../HomeTaskRow/HomeTaskRow";
import { savePersonAttributeChange } from "../../../Utils/SavePersonFunctions";
import HomeTaskHeaderRow from "../HomeTaskHeaderRow/HomeTaskHeaderRow";
import { ProductionScheduleTableHeaderType } from "../../ProductionSchedule/ProductionScheduleTableHeader/ProductionScheduleTableHeader";

interface HomeTaskTableProps {
	columns: string[],
	mergeChangeOrders?: boolean,
	filterClient: string,
	filterRole: string,
	filterSearch: string,
	filterProjectType: string,
	numMonths: number,
}


let hideCalendarTimout: NodeJS.Timeout | null = null;

export default function HomeTaskTable(props: HomeTaskTableProps) {

	const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
	const organization = useSelector((state: { organization: Organization }) => state.organization)
	const allMonthTotals = useSelector((state: { monthTotals: MonthTotal[] }) => state.monthTotals)
	const clients = useSelector((state: { clients: Client[] }) => state.clients)
	const roles = useSelector((state: { roles: Role[] }) => state.roles)
	const persons = useSelector((state: { persons: Person[] }) => state.persons)
	const projects = useSelector((state: { projects: Project[] }) => state.projects)

	const [person, setPerson] = useState<Person | undefined>(undefined)
	useEffect(() => {
		if (persons && sharedFirebaseAuth.currentUser) {
			setPerson(persons.find((person: Person) => person.email === sharedFirebaseAuth.currentUser!.email))
		} else {
			setPerson(undefined)
		}
	}, [persons, sharedFirebaseAuth.currentUser])

	const [projectsWithActiveAssignedTasks, setProjectsWithActiveAssignedTasks] = useState<Project[]>([])
	useEffect(() => {
		if (projects && person) {
			let projectsWithActiveAssignedTasks: Project[] = []
			let activeStatuses = organization.projectStatusCategories["active"]
			let activeProjects = projects.filter((project: Project) => activeStatuses.includes(project.status));
			activeProjects.forEach((project: Project) => {
				let assignedTasks = project.projectRoleTasks.filter((roleTask: any) => {
					return roleTask.tasks.filter((task: ProjectTask) => task.assignedPersonIDs.includes(person.id)).length > 0
				})
				if (assignedTasks.length > 0) {
					if(props.filterClient != "" && project.clientID != props.filterClient) {
						return;
					}
					if(props.filterProjectType != "" && project.projectType != props.filterProjectType) {
						return;
					}
					projectsWithActiveAssignedTasks.push(project)
				}
			})
			setProjectsWithActiveAssignedTasks(projectsWithActiveAssignedTasks)
		}
	}, [projects, person, props.filterRole, props.filterSearch, props.filterClient])


	const [sortedTasks, setSortedTasks] = useState<ProjectTask[]>([]);
	useEffect(() => {
		if (projectsWithActiveAssignedTasks && person) {
			let sortedTasks: ProjectTask[] = []
			let sortedTasksWhereProjectMatchesSearch: ProjectTask[] = []
			projectsWithActiveAssignedTasks.forEach((project: Project) => {
				project.projectRoleTasks.forEach((roleTask: any) => {
					roleTask.tasks.forEach((task: ProjectTask) => {
						if (task.assignedPersonIDs.includes(person!.id)) {
							sortedTasks.push(task)
							if(project.projectName.toLowerCase().includes(props.filterSearch.toLowerCase())){
								sortedTasksWhereProjectMatchesSearch.push(task)
							}
						}
					})
				})
			})
			sortedTasks.sort((a: ProjectTask, b: ProjectTask) => {
				let indexA = person!.assignedTaskOrder.indexOf(a.id)
				let indexB = person!.assignedTaskOrder.indexOf(b.id)
				if (indexA == -1 && indexB != -1) {
					return 1
				}
				if (indexA != -1 && indexB == -1) {
					return -1
				}
				return indexA - indexB
			});

			if(props.filterRole !=""){
				sortedTasks = sortedTasks.filter((roleTask: any) => {
					return roleTask.roleID == props.filterRole
				})
			}
			if(props.filterSearch != ""){
				let newSortedTasks = sortedTasks.filter((task: ProjectTask) => {
					return task.name.toLowerCase().includes(props.filterSearch.toLowerCase())
				})
				//sorted tasks is set to merged newSortedTasks and projects where the project name matches the search
				sortedTasks = [...newSortedTasks, ...sortedTasksWhereProjectMatchesSearch]
			}
			let newOrder = sortedTasks.map((task: ProjectTask) => task.id)
			savePersonAttributeChange(person!, "assignedTaskOrder", newOrder, (event: any) => AC.submitUndoStackEntry(event))
			setSortedTasks(sortedTasks)
		} else {
			setSortedTasks([])
		}
	}, [person, projectsWithActiveAssignedTasks, props.filterRole, props.filterSearch])

	const [hideCalendar, setHideCalendar] = useState<boolean>(false);
	useEffect(() => {
		if(hideCalendar){
			if(hideCalendarTimout){
				clearTimeout(hideCalendarTimout);
			}
			hideCalendarTimout = setTimeout(() => {
				setHideCalendar(false)
			}, 1000);
		}
	}, [hideCalendar,sortedTasks]);

	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry: ActionCreators.submitUndoStackEntry
	}, dispatch);


	return (
		<table
			className={`HomeTaskTable`} >
			<thead>
				<HomeTaskHeaderRow columns={props.columns} numMonths={props.numMonths} type={ProductionScheduleTableHeaderType.month} hideCalendar={hideCalendar} />
				<HomeTaskHeaderRow columns={props.columns} numMonths={props.numMonths} type={ProductionScheduleTableHeaderType.day} hideCalendar={hideCalendar} />
				<HomeTaskHeaderRow columns={props.columns} numMonths={props.numMonths} type={ProductionScheduleTableHeaderType.dayOfWeek} hideCalendar={hideCalendar} />
			</thead>
			{person && organization && (
				<DraggableTableBody
					childClassName={`HomeTaskRow`}
					orderedItems={person.assignedTaskOrder}
					onOrderUpdated={(newOrder: string[]) => {
						//setTemporarySortOrder(newOrder);
					}}
					keyForIndex={(index: number) => {
						return sortedTasks[index].id;
					}}
					onOrderUpdateEnded={(newOrder) => {
						savePersonAttributeChange(person!, "assignedTaskOrder", newOrder, (event: any) => AC.submitUndoStackEntry(event))


						let newSortedTasks = [...sortedTasks];
						newSortedTasks.sort((a: ProjectTask, b: ProjectTask) => {
							let indexA = newOrder.indexOf(a.id)
							let indexB = newOrder.indexOf(b.id)
							if (indexA == -1 && indexB != -1) {
								return 1
							}
							if (indexA != -1 && indexB == -1) {
								return -1
							}
							return indexA - indexB
						});
						setHideCalendar(true);
						setSortedTasks(newSortedTasks);

					}}>
					{sortedTasks.map((task: ProjectTask, index: number) => {
						return <HomeTaskRow
							columns={props.columns}
							numMonths={props.numMonths}
							projectTask={task}
							hideCalendar={hideCalendar}
							personID={person!.id} />
					})}
				</DraggableTableBody>
			)}
		</table>
	)
}