import React, { useState, useCallback, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import './DraggableTableBody.css';

interface DraggableTableBodyProps {
  children: React.ReactNode;
  className?: string;
  childClassName: string;
  orderedItems?: any[];
  keyForIndex: (index: number) => string;
  onOrderUpdated?: (newOrder: any[]) => void;
  onOrderUpdateEnded?: (newOrder: any[]) => void;
}

let toIndex = 0;

const DraggableTableBody: React.FC<DraggableTableBodyProps> = ({ children, className, childClassName, orderedItems, keyForIndex, onOrderUpdateEnded: onDragEnd, onOrderUpdated }) => {
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [draggingItem, setDraggingItem] = useState<any>(null);

  const [rearranging, setRearranging] = useState(false);

  const [finishedDragMethod, setFinishedDragMethod] = useState<(() => void) | null>(null);



  const DragRow: React.FC<{ children?: React.ReactNode; index: number; onOrderedItemsChanged:(items:any[])=>void; onOrderedItemsChangeFinished?:()=>void; }> = (props) => {
    let dragTimeout: NodeJS.Timeout | null = null;
    const { children, index, onOrderedItemsChanged, onOrderedItemsChangeFinished } = props;
    const ref = React.useRef<HTMLTableRowElement>(null);

    const [, drop] = useDrop({
      accept: 'ROW',
      hover: (item: { index: number; type: string }) => {

        if(dragTimeout){
          clearTimeout(dragTimeout);
        }
        dragTimeout = setTimeout(() => {
          if (ref.current) {
            setDraggingIndex(null);
            ref.current.classList.remove('dragging');
            setRearranging(false);
          }
        }, 2000);

        if (draggingIndex === null || index === draggingIndex) {
          return
        }
        toIndex = index;
        if (draggingItem && orderedItems && onOrderUpdated) {
          let newOrder = [...orderedItems];
          newOrder.splice(draggingIndex, 1);
          newOrder.splice(index, 0, draggingItem);
          onOrderedItemsChanged(newOrder);
        }
        setDraggingIndex(index)
      }
    });

    const [, drag] = useDrag({
      type: 'ROW',
      item: { index },
      collect: (monitor) => {
        if (monitor.isDragging()) {
          if (orderedItems) {
            setDraggingItem(orderedItems[index])
          }
          setDraggingIndex(index);
          setRearranging(true);
          
          
        }else if(monitor.didDrop()){
          onOrderedItemsChangeFinished && onOrderedItemsChangeFinished();
          setDraggingIndex(null);
          setRearranging(false);
        }
      },
      end: () => {
        setDraggingIndex(null);
        setRearranging(false);
      }
    });

    drag(drop(ref));
    const rowClassName = index === draggingIndex ? `${childClassName} dragging` : childClassName;
    if(dragTimeout){
      clearTimeout(dragTimeout);
    }
    dragTimeout = setTimeout(() => {
      if (ref.current) {
        setDraggingIndex(null);
        ref.current.classList.remove('dragging');
        setRearranging(false);
      }
    }, 1000);
    return <tr ref={ref} key={keyForIndex(index)} className={rowClassName}>{children}</tr>;
  };

  return (
    <tbody className={`DraggableTableBody ${className} ${rearranging?"rearranging":""}`}>
      {React.Children.map(children, (child, index) => (
        <DragRow 
          key={index} 
          index={index}
          onOrderedItemsChanged={(items) =>{
            onOrderUpdated && onOrderUpdated(items);
            setFinishedDragMethod(() =>{
              onDragEnd && onDragEnd(items);
            });
          }}
          onOrderedItemsChangeFinished={finishedDragMethod??undefined}>
          {child}
        </DragRow>
      ))}
    </tbody>
  );
};

export default DraggableTableBody;