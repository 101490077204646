import React, { useEffect, useState } from "react"
import "./ProductionScheduleTaskDueDate.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { faCheck, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProjectTask, ProjectTaskScheduleBlock } from "../../../FirebaseModel/Project";
import { shortDate } from "../../../Utils/formatting";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "@mui/material";
import { PersonNonProjectTask } from "../../../FirebaseModel/Person";

interface ProductionScheduleTaskDueDateProps{
	projectTask:ProjectTask|PersonNonProjectTask;
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function ProductionScheduleTaskDueDate(props:ProductionScheduleTaskDueDateProps){	
	// starting from the start date use the props.date to get the index for hours per day to show;
	let tempDay = new Date(props.projectTask.dueDate);
  return (
    <div className={`ProductionScheduleTaskDueDate`}>
			<Tooltip title={props.projectTask.name +" - due: "+shortDate(tempDay)}>
				<FontAwesomeIcon icon={faCalendar} />
			</Tooltip>
    </div>
  )
}