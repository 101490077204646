
import React, { useEffect, useState } from "react"
import "./ReportMonthlyBSP.css"
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../FirebaseModel/Organization";
import SoldVSBilledTable from "../../Components/SoldVSBilled/SoldVSBilledTable/SoldVSBilledTable";
import MonthlyBSPTable from "../../Components/MonthlyBSP/MonthlyBSPTable/MonthlyBSPTable";
import { BSPProject, MonthTotal } from "../../FirebaseModel/MonthTotal";
import { getOrCreateMonthTotal, saveMonthTotalAttributeChange } from "../../Utils/SaveMonthTotalFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import MonthlyBSPScopeCompletedTable from "../../Components/MonthlyBSP/MonthlyBSPScopeCompletedTable/MonthlyBSPScopeCompletedTable";
import MonthlyBSPPMTable from "../../Components/MonthlyBSP/MonthlyBSPPMTable/MonthlyBSPPMTable";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shortDate } from "../../Utils/formatting";
import { CalculateMonthlyBSP } from "../../Utils/CalculateBSPFunctions";
import { Person } from "../../FirebaseModel/Person";
import { Project } from "../../FirebaseModel/Project";
import { Role } from "../../FirebaseModel/Role";
import { YearTotal } from "../../FirebaseModel/YearTotal";
interface ReportMonthlyBSPProps{
	filterMonth:string,
	filterYear:string,
	onDismiss:()=>void;
}

export default function ReportSoldVSBilled(props:ReportMonthlyBSPProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const monthTotals = useSelector((state:{monthTotals:MonthTotal[]}) => state.monthTotals)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	const [month , setMonth] = useState<number>(parseInt(props.filterMonth));
	const [year , setYear] = useState<number>(parseInt(props.filterYear));
 

	const [tableOffsetTop, setTableOffsetTop] = useState<number>(0);
	const tableRef = React.createRef<HTMLDivElement>();
	const resize = () =>{
		if(tableRef.current){
			setTableOffsetTop(tableRef.current!.offsetTop);
		}
	}

	useEffect(() => {
		let table = tableRef.current!;
		if(table){
			const overrideWheel = (e: WheelEvent) => {
				if(table.scrollLeft === 0 && e.deltaX < 0){
					e.preventDefault();
				}
			};
			table.addEventListener("wheel", overrideWheel);
			return () => {
				table.removeEventListener("wheel", overrideWheel);
			};
		}
	}, [tableRef]);

	const beginCalculation = (monthTotal:MonthTotal) =>{
		setCalculationComplete(false);
		CalculateMonthlyBSP(monthTotal,projects,roles,persons,organization,entry =>{
			AC.submitUndoStackEntry(entry);
		}).then(() =>{
			setCalculationComplete(true);
		})
	}

	const [monthTotal, setMonthTotal] = useState<MonthTotal|null>(null);
	const [calculationComplete, setCalculationComplete] = useState<boolean>(false);

	useEffect(() =>{
		window.addEventListener("resize", resize);
		resize();
		

		getOrCreateMonthTotal(month, year, monthTotals, organization, (entry) =>{
			AC.submitUndoStackEntry(entry);
		}).then(monthTotal =>{
			setMonthTotal(monthTotal);
			beginCalculation(monthTotal);
			
		})
		return () =>{
			window.removeEventListener("resize", resize);
		}
		
	},[month,year])



  return (
		<>
	    <div className={`report ReportMonthlyBSP`}>
				<div className="topRow">
					<h1>Monthly BSP Report 
						<span onClick={() =>{
							setCalculationComplete(false);
								let newMonth = month-1;
								if(newMonth < 1){
									newMonth = 12;
									setYear(year-1);
								}
								setMonth(newMonth);
							}} className="monthButton"><FontAwesomeIcon icon={faChevronLeft} /></span>

					 ({month}/{year})
						<span onClick={() =>{
								setCalculationComplete(false);
								let newMonth = month+1;
								if(newMonth > 12){
									newMonth = 1;
									setYear(year+1);
								}
								setMonth(newMonth);
							}} className="monthButton"><FontAwesomeIcon icon={faChevronRight} /></span>
					</h1>
				</div>

				{(calculationComplete && monthTotal) && (
				<div className="content"
					ref={tableRef}
					style={{maxHeight:`calc(100vh - ${tableOffsetTop}px)`}}>
					{monthTotal && (
						<>
							<MonthlyBSPTable 
								onPullFromLastMonth={() =>{

									let lastMonthMonth = month-1;
									let lastMonthYear = year;
									if(lastMonthMonth < 1){
										lastMonthMonth = 12;
										lastMonthYear = year-1;
									}

									getOrCreateMonthTotal(lastMonthMonth, lastMonthYear, monthTotals, organization, (entry) =>{
										AC.submitUndoStackEntry(entry);
									}).then(lastMonthTotal =>{
										//go through the bspProjects in last month and set this months percent start to the same las last months percent complete
										let newBSPProjects:BSPProject[] = (JSON.parse(JSON.stringify(monthTotal.bspProjects))) as BSPProject[];
										monthTotal.bspProjects.forEach(bspProject =>{
											let lastMonthBSPProject = lastMonthTotal.bspProjects.find(lastMonthBSPProject => lastMonthBSPProject.projectID == bspProject.projectID);
											if(lastMonthBSPProject){
												bspProject.firstDayPercentComplete = lastMonthBSPProject.lastDayPercentComplete;
											}
										})
										saveMonthTotalAttributeChange(monthTotal, "bspProjects", newBSPProjects, entry =>{
											AC.submitUndoStackEntry(entry);
										}).then(() =>{
											beginCalculation(monthTotal);
										})
									})
								}}
								onPushToNextMonth={() =>{
									let nextMonthMonth = month+1;
									let nextMonthYear = year;
									if(nextMonthMonth > 12){
										nextMonthMonth = 1;
										nextMonthYear = year+1;
									}

									getOrCreateMonthTotal(nextMonthMonth, nextMonthYear, monthTotals, organization, (entry) =>{
										AC.submitUndoStackEntry(entry);
									}).then(nextMonthTotal =>{
										let newBSPProjects:BSPProject[] = (JSON.parse(JSON.stringify(nextMonthTotal.bspProjects))) as BSPProject[];
										monthTotal.bspProjects.forEach(bspProject =>{
											let nextMonthBSPProject = newBSPProjects.find(nextMonthBSPProject => nextMonthBSPProject.projectID == bspProject.projectID);
											if(nextMonthBSPProject){
												nextMonthBSPProject.firstDayPercentComplete = bspProject.lastDayPercentComplete;
											}
										})
										saveMonthTotalAttributeChange(nextMonthTotal, "bspProjects", newBSPProjects, entry =>{
											AC.submitUndoStackEntry(entry);
										}).then(() =>{
											beginCalculation(monthTotal);
										})
									})
								}}
								month={month} year={year} monthTotal={monthTotal!}></MonthlyBSPTable>
							<MonthlyBSPScopeCompletedTable
								month={month} year={year} monthTotal={monthTotal!}	></MonthlyBSPScopeCompletedTable>
							<MonthlyBSPPMTable
								month={month} year={year} monthTotal={monthTotal!}></MonthlyBSPPMTable>
						</>
					)}
				</div>
				)}
				{calculationComplete == false && (
					<div className="loading">
						<div className="loadingText">Calculating...</div>
					</div>
				)}
				<div className="floatingButtons">
					<div className="buttonSave"
						onClick={() =>{
							props.onDismiss();
						}}>Done</div>
					</div>
			</div>
		</>
  )
}