

import React, { ReactNode, useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ClientsScreenClientsTable.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { Role } from "../../../FirebaseModel/Role";
import { Person } from "../../../FirebaseModel/Person";
import OrganizationScreenRolesInput from "../../OrganizationScreen/OrganizationScreenRolesInput/OrganizationScreenRolesInput";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { Client } from "../../../FirebaseModel/Client";
import ClientsScreenClientsInput from "../ClientsScreenClientsInput/ClientsScreenClientsInput";
import { deleteClient, saveClientAttributeChange } from "../../../Utils/SaveClientFunctions";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { UndoStackEntry } from "../../../Utils/UndoStackEntry";
import ClientScreenClientsRow from "../ClientScreenClientsRow/ClientScreenClientsRow";
interface ClientsScreenClientsTableProps{
	filterSearch:string;
	onEditClientPressed:(client:Client)=>void;
}

export default function ClientsScreenClientsTable(props:ClientsScreenClientsTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const allClients = useSelector((state:{clients:Client[]}) => state.clients)
	const projects = useSelector((state:{projects:Project[]}) => state.projects)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	const [clients, setClients] = useState<Client[]>([]);

	useEffect(() =>{
		let tempClients:Client[] = [];
		allClients.forEach((client:Client) =>{
			if(props.filterSearch != ""){
				if(client.name.toLowerCase().indexOf(props.filterSearch.toLowerCase()) != -1){
					tempClients.push(client);
				}
				return;
			}
			tempClients.push(client);
		});
		setClients(tempClients);
	},[allClients,props.filterSearch]);
	
  return (
    <table className={`ClientsScreenClientsTable`}>
			<thead>
				<tr>
					<th>Name</th>
					<th>Use<br></br>Override<br></br>Rate?</th>
					<th>Hourly Rate</th>

				</tr>
			</thead>
			<tbody>
				{clients.map((client:Client) =>{
					return <ClientScreenClientsRow 
						key={`client_${client.id}`}
						client={client} 
						onEditClientPressed={props.onEditClientPressed}></ClientScreenClientsRow>
				})}
			</tbody>
    </table>
  )
}