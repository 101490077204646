
import React, { ReactNode, useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./MonthlyBSPPMTableRow.css"
import { useDispatch, useSelector } from "react-redux";
import { Project, projectHourlyRate } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role, rolesExistAM } from "../../../FirebaseModel/Role";
import { prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowDown19, faArrowUp, faArrowUp19, faChevronDown, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { BSPProject, MonthTotal } from "../../../FirebaseModel/MonthTotal";
import { getOrCreateMonthTotal } from "../../../Utils/SaveMonthTotalFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { YearTotal } from "../../../FirebaseModel/YearTotal";
import { getOrCreateYearTotal } from "../../../Utils/SaveYearTotalFunctions";
import { Client } from "../../../FirebaseModel/Client";
import { Person } from "../../../FirebaseModel/Person";
import MonthlyBSPInput from "../MonthlyBSPInput/MonthlyBSPInput";

interface MonthlyBSPPMTableRowProps{
	pmID:string,
	monthTotal:MonthTotal,
	month:number,
	year:number;
	className?:string,
}

export default function MonthlyBSPPMTableRow(props:MonthlyBSPPMTableRowProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)
  const allMonthTotals = useSelector((state:{monthTotals:MonthTotal[]}) => state.monthTotals)
  const allYearTotals = useSelector((state:{yearTotals:YearTotal[]}) => state.yearTotals)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	const [pmName, setPMName] = useState<string>("");
	const [quantity, setQuantity] = useState<number>(0);
	const [sfClosedWon, setSFClosedWon] = useState<number>(0);
	const [scopeStart, setScopeStart] = useState<number>(0);
	const [scopeEnd, setScopeEnd] = useState<number>(0);

	useEffect(() =>{
		let pm = persons.find(p => p.id == props.pmID);
		if(pm){
			setPMName(pm.name);
		}
		let quantity = 0;
		let sfClosedWon = 0;
		let scopeStart = 0;
		let scopeEnd = 0;
		
		props.monthTotal.bspProjects.forEach(bspProject => {
			let project = projects.find(p => p.id == bspProject.projectID);
			if(project && project.projectManagerID == props.pmID){
				quantity++;
				sfClosedWon += project.sfClosedWon;
				scopeStart += project.sfClosedWon * (1- bspProject.firstDayPercentComplete);
				scopeEnd += project.sfClosedWon * (1- bspProject.lastDayPercentComplete);
			}
		});

		setQuantity(quantity);
		setSFClosedWon(sfClosedWon);
		setScopeStart(scopeStart);
		setScopeEnd(scopeEnd);
		
	},[props.pmID, persons, props.monthTotal.bspProjects])


  return (
		<tr className={`MonthlyBSPPMTableRow ${props.className??""}`}>
			<td>{pmName}</td>
			<td>{quantity}</td>
			<td>{prettyCurrency(sfClosedWon)}</td>
			<td>{prettyCurrency(scopeStart)}</td>
			<td>{prettyCurrency(scopeEnd)}</td>
			<td>{prettyCurrency(scopeStart - scopeEnd)}</td>
		</tr>
	)
}