
import React, { ReactNode, useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./MonthlyBSPRow.css"
import { useDispatch, useSelector } from "react-redux";
import { Project, projectHourlyRate } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role, rolesExistAM } from "../../../FirebaseModel/Role";
import { prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowDown19, faArrowUp, faArrowUp19, faChevronDown, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { BSPProject, MonthTotal } from "../../../FirebaseModel/MonthTotal";
import { getOrCreateMonthTotal } from "../../../Utils/SaveMonthTotalFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { YearTotal } from "../../../FirebaseModel/YearTotal";
import { getOrCreateYearTotal } from "../../../Utils/SaveYearTotalFunctions";
import { Client } from "../../../FirebaseModel/Client";
import { Person } from "../../../FirebaseModel/Person";
import MonthlyBSPInput from "../MonthlyBSPInput/MonthlyBSPInput";

interface MonthlyBSPRowProps{
	bspProject:BSPProject,
	monthTotal:MonthTotal,
	month:number,
	year:number;
	className?:string,
}

export default function MonthlyBSPRow(props:MonthlyBSPRowProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)
  const allMonthTotals = useSelector((state:{monthTotals:MonthTotal[]}) => state.monthTotals)
  const allYearTotals = useSelector((state:{yearTotals:YearTotal[]}) => state.yearTotals)


  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

 
		// monthTotal.bspProjects:{
		// 	projectID:string;
		// 	firstDayPercentComplete:number;
		// 	lastDayPercentComplete:number;
		// }[]

	const [project, setProject] = useState<Project>(projects.find(p => p.id == props.bspProject.projectID)!);
	const [client, setClient] = useState<Client|null>(clients.find(c => c.id == project.clientID)??null);

	const [pm, setPM] = useState<Person|null>(persons.find(p => p.id == project.projectManagerID)??null);

	const [percentCompleted, setPercentCompleted] = useState<number>(0);
	

	const [scopeRemainingDay1, setScopeRemainingDay1] = useState<number>(0);
	const [scopeRemainingDay30, setScopeRemainingDay30] = useState<number>(0);


	useEffect(() =>{

		let percentCompleted = props.bspProject.lastDayPercentComplete - props.bspProject.firstDayPercentComplete;
		setPercentCompleted(percentCompleted);

		let scopeRemainingDay1 = project.sfClosedWon * (1 - props.bspProject.firstDayPercentComplete);
		setScopeRemainingDay1(scopeRemainingDay1);

		let scopeRemainingDay30 = project.sfClosedWon * (1 - props.bspProject.lastDayPercentComplete);
		setScopeRemainingDay30(scopeRemainingDay30);

	},[props.bspProject,props.bspProject.firstDayPercentComplete,props.bspProject.lastDayPercentComplete])


  return (
		<tr className={`MonthlyBSPRow ${props.className??""}`}>
			<td>{client?client.name:""}</td>
			<td>{project.projectNumber}</td>
			<td>{project.projectName}</td>
			<td>{pm?pm.name:""}</td>
			<td>{prettyCurrency(project.sfClosedWon)}</td>
			<td>
				{permissionsWrite.includes("Monthly BSP Report") ? (
					<MonthlyBSPInput
						monthTotal={props.monthTotal}
						projectID={props.bspProject.projectID}
						attributeKey="firstDayPercentComplete"
						></MonthlyBSPInput>
				):(
					<>{Math.round(props.bspProject.firstDayPercentComplete*100)}%</>
				)}
			</td>
			<td>
				{permissionsWrite.includes("Monthly BSP Report") ? (
				<MonthlyBSPInput
					monthTotal={props.monthTotal}
					projectID={props.bspProject.projectID}
					attributeKey="lastDayPercentComplete"
					></MonthlyBSPInput>
				):(
					<>{Math.round(props.bspProject.lastDayPercentComplete*100)}%</>
				)}
			</td>
			<td>{Math.round(percentCompleted*100)}%</td>
			<td>{prettyCurrency(scopeRemainingDay1)}</td>
			<td>{prettyCurrency(scopeRemainingDay30)}</td>
			<td>{prettyCurrency(scopeRemainingDay1 - scopeRemainingDay30)}</td>
		</tr>
	)
}