
import React, { ReactNode, useEffect, useState } from "react"
import { addDoc, collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./WeeklyTimesheetRow.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { Role, rolePersonsDescription } from "../../../FirebaseModel/Role";
import { Person } from "../../../FirebaseModel/Person";
import { deleteRole, saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { faAnchor, faBars, faCheck, faCircleNodes, faHamburger, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { actualCalculateWeekHours, actualHasDailyData, hoursForDay, ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import ActionCreators, { setRoles } from "../../../Redux/ActionCreators";
import { Client } from "../../../FirebaseModel/Client";
import { deleteProjectRoleActualHours, saveProjectRoleActualHours } from "../../../Utils/SaveProjectRoleActualFunctions";
import WeeklyTimesheetInput, { WeeklyTimesheetInputType } from "../WeeklyTimesheetInput/WeeklyTimesheetInput";
import ClientDropdown from "../../ClientDropdown/ClientDropdown";
import ProjectDropdown from "../../ProjectDropdown/ProjectDropdown";
import { PTOSTORemaining, TimeOff, personRemainingPTOSTOForDate, timeOffCalculateWeekPTOHours, timeOffCalculateWeekSTOHours } from "../../../FirebaseModel/TimeOff";
import { forceDecimal, prettyNum, shortDate } from "../../../Utils/formatting";
import { saveTimeOffAttributeChange } from "../../../Utils/SaveTimeOffFunctions";
import RoleDropdown from "../../RoleDropdown/RoleDropdown";
import { bindActionCreators } from "redux";
import { UndoStackEntryObjectType, UndoStackEntryType } from "../../../Utils/UndoStackEntry";

export enum WeeklyTimesheetRowType{
	Project,
	PTO,
	STO,
	AddRow
}


export interface WeeklyTimesheetRowProps{
	projectRoleActualHours?:ProjectRoleActualHours;
	timeOff?:TimeOff;
	person?:Person;
	nextOrder?:number;
	type:WeeklyTimesheetRowType;
  isReadOnly?:boolean;
	weekKeys:string[],
	weekKey?:string,
	year:number;
	showCompleted?:boolean,
  selected:boolean,
	onCreateNewClient?:(actual:ProjectRoleActualHours) => void;
	onCreateNewProject?:(actual:ProjectRoleActualHours) => void;
	onCreateNewRole?:(actual:ProjectRoleActualHours) => void;
	onSelectedActualChange?:(actual:ProjectRoleActualHours, selected:boolean) => void;
	onSelectedPTOChange?:(timeOff:TimeOff, selected:boolean) => void;
	onSelectedSTOChange?:(timeOff:TimeOff, selected:boolean) => void;

	goToDailyInput:(weekKey:string) => void;

}

export default function WeeklyTimesheetRow(props:WeeklyTimesheetRowProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);
		
  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const projects = useSelector((state:{projects:Project[]}) => state.projects)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
	const roles = useSelector((state:{roles:Role[]}) => state.roles)
	const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)
	const timeOffList = useSelector((state:{timeOffList:TimeOff[]}) => state.timeOffList)


	const [person, setPerson] = useState<Person|null>(null);
	const [client, setClient] = useState<Client|null>(null);
	const [project, setProject] = useState<Project|null>(null);
	const [role, setRole] = useState<Role|null>(null);
	const [totalHours, setTotalHours] = useState<number>(0);

	const [clientID, setClientID] = useState<string|null>(null);
	const [projectID, setProjectID] = useState<string>("");
	const [roleID, setRoleID] = useState<string>("");

	const today = new Date();
	const todayWeekKey = shortDate(today);//`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`;

	const [selected, setSelected] = useState<boolean>(props.selected);
	useEffect(() =>{
		setSelected(props.selected);
	},[props.selected]);


	useEffect(() =>{
		if(project){
			setProjectID(project.id);
			setClient(clients.find((client:Client) => client.id == project.clientID)??null);
			setClientID(project.clientID);
		}
	},[project,clients]);

	useEffect(() =>{
		if(props.projectRoleActualHours != null){
			setProject(projects.find((project:Project) => project.id == props.projectRoleActualHours!.projectID)??null);
		}
	},[projects]);

	useEffect(() =>{
		if(props.projectRoleActualHours != null){
			setRole(roles.find((role:Role) => role.id == props.projectRoleActualHours!.roleID)??null);
			setRoleID(props.projectRoleActualHours!.roleID);
		}
	},[roles]);

	useEffect(() =>{
		if(props.projectRoleActualHours != null){
			setPerson(persons.find((person:Person) => person.id == props.projectRoleActualHours!.personID)??null);
		}
	},[persons]);

	const isActive = () =>{
		// if the projectstatus is not in the list of completed statuses then it is active
		if(project && organization && organization.projectStatusCategories){
			let completedStatuses:string[] = (organization.projectStatusCategories as any)["completed"] as string[];
			if(projectID != ""){
				let project = projects.find((project:Project) => project.id == projectID);
				if(project && completedStatuses.includes(project.status)){
					return false;
				}
			}
		}
		return true;
	}

	const [localActual, setLocalActual] = useState<ProjectRoleActualHours|null>(null);

	useEffect(() =>{

		let startDate = new Date(props.weekKeys[0]);
		let endDate = new Date(props.weekKeys[props.weekKeys.length-1]);
		endDate.setDate(endDate.getDate() + 7);

		if(props.weekKey){
			endDate = new Date(props.weekKeys[props.weekKeys.length-1]);
		}

		let startTime = startDate.getTime();	
		let endTime = endDate.getTime();
		let tempTotalHours = 0;

		if(props.projectRoleActualHours != null){
			setPerson(persons.find((person:Person) => person.id == props.projectRoleActualHours!.personID)??null)
			setProject(projects.find((project:Project) => project.id == props.projectRoleActualHours!.projectID)??null);
			setRole(roles.find((role:Role) => role.id == props.projectRoleActualHours!.roleID)??null);
			let localProjectRoleActualHours = {...props.projectRoleActualHours};
			setLocalActual(localProjectRoleActualHours);

			let actual = props.projectRoleActualHours!;
			for(var i in actual.hours){
				let entry = actual.hours[i];
				let entryTime = new Date(entry.date).getTime();
				if(entryTime < startTime || entryTime > endTime){
					continue;
				}
				tempTotalHours += entry.hours;
			}
		}else if(props.type == WeeklyTimesheetRowType.PTO && props.timeOff != null){
			for(var i in props.timeOff!.hoursTakenPTO){
				let entry = props.timeOff!.hoursTakenPTO[i];
				let entryTime = new Date(entry.date).getTime();
				if(entryTime < startTime || entryTime > endTime){
					continue;
				}
				tempTotalHours += entry.hours;
			}
		}else if(props.type == WeeklyTimesheetRowType.STO && props.timeOff != null){
			for(var i in props.timeOff!.hoursTakenSTO){
				let entry = props.timeOff!.hoursTakenSTO[i];
				let entryTime = new Date(entry.date).getTime();
				if(entryTime < startTime || entryTime > endTime){
					continue;
				}
				tempTotalHours += entry.hours;
			}
		}

		setTotalHours(tempTotalHours);

	},[props.projectRoleActualHours, props.projectRoleActualHours?.hours, props.projectRoleActualHours?.projectID, props.projectRoleActualHours?.roleID,props.timeOff,props.weekKey]);

	const [remainingTimeOff, setRemainingTimeOff] = useState<PTOSTORemaining>({
		annualPTO:12,
		rolloverPTO:12,
		annualSTO:12,
		rolloverSTO:12,
		usedPTO:12,
		usedSTO:12,
		remainingPTO:12,
		remainingSTO:12
	});
	useEffect(() =>{
		if(props.person){
			// let date = props.year < (new Date()).getFullYear() ? new Date("12/31/"+props.year) : new Date();
			let date = new Date("12/31/"+props.year);
			let remainingTimeOff = personRemainingPTOSTOForDate(props.person, timeOffList, date);
			setRemainingTimeOff(remainingTimeOff);
		}
	},[props.person,props.timeOff,props.timeOff?.hoursTakenPTO, props.timeOff?.hoursTakenSTO	,timeOffList]);
	
	return (
		<>
			{((props.showCompleted || isActive()) && props.type == WeeklyTimesheetRowType.Project && props.projectRoleActualHours && localActual) &&(
				<>
					<td className='sticky1 center'>

						{permissionsWrite.includes("Personal Timesheet") && !props.isReadOnly && (
							<input type='checkbox' className="selectCheckbox hide-from-report"
								checked = {selected}
								onChange={(event) =>{
									if(props.onSelectedActualChange){
										props.onSelectedActualChange(props.projectRoleActualHours!, event.target.checked);
									}
								}}></input>
						)}

						{(permissionsWrite.includes("Personal Timesheet") && isActive()) && !props.isReadOnly ?(
							<>
							<FontAwesomeIcon icon={faBars} style={{pointerEvents:"none", fontSize:"50%"}}></FontAwesomeIcon>
								<input type='checkbox'
									checked={localActual.billable}
									onChange={(event) =>{
										saveProjectRoleActualHours(props.projectRoleActualHours!, "billable", event.target.checked,(entry) =>{
											AC.submitUndoStackEntry(entry);
										});
									}}></input>
							</>
						):(
							<>
								{localActual.billable && <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}
							</>
						)}
					</td>
					<td className='sticky2'>
						{(permissionsWrite.includes("Personal Timesheet") && isActive()) && !props.isReadOnly ?(
							<ClientDropdown 
								defaultValue={project?project.clientID:""} 
								onCreateNew={() =>{
									if(props.onCreateNewClient){
										props.onCreateNewClient(props.projectRoleActualHours!);
									}
								}}
								onChange={(id:string) =>{
									setClientID(id);
									if(project && project.clientID != id){
										setProjectID("");
									}
								}}></ClientDropdown>
						):(
							<>{project && clients.find(client=>client.id==project.clientID)?.name}</>
						)}
					</td>
					<td className='sticky3'>{projectID!=""?(projects.find(project=>project.id==projectID))!.projectNumber:""}</td>
					<td className='sticky4'>
						{(permissionsWrite.includes("Personal Timesheet") && isActive()) && !props.isReadOnly ?(
							<ProjectDropdown 
								defaultValue={projectID} 
								clientID={clientID??undefined} 
								onCreateNew={() =>{
									if(props.onCreateNewProject){
										props.onCreateNewProject(props.projectRoleActualHours!);
									}
								}}
								onChange={(id:string) =>{

									if(id != ""){
										let personActuals = actuals.filter(actual => actual.personID == props.person!.id);
										let projectActuals = personActuals.filter(actual => actual.projectID == id);
										let roleActuals = projectActuals.filter(actual => actual.roleID == roleID);
										if(roleActuals.length > 0){
											alert("There is already a row for this project and role.");
											return;
										}

									}
									setProjectID(id);
									//setProject(projects.find((project:Project) => project.id == id)??null);
									let project = projects.find((project:Project) => project.id == id);
									saveProjectRoleActualHours(props.projectRoleActualHours!, "projectID", project!.id,(entry) =>{
										AC.submitUndoStackEntry(entry);
									}).then(() =>{
										props.projectRoleActualHours!.projectID = id;
									});
								}}></ProjectDropdown>
						):(
							<>{project?.projectName}</>
						)}
					</td>
					<td className='sticky5'>

						{(permissionsWrite.includes("Personal Timesheet") && isActive()) && !props.isReadOnly ?(
							<RoleDropdown 
								defaultValue={roleID} 
								onCreateNew={() =>{
									if(props.onCreateNewRole){
										props.onCreateNewRole(props.projectRoleActualHours!);
									}
								}}
								onChange={(id:string) =>{
									if(id != ""){
										let personActuals = actuals.filter(actual => actual.personID == props.person!.id);
										let projectActuals = personActuals.filter(actual => actual.projectID == projectID);
										let roleActuals = projectActuals.filter(actual => actual.roleID == id);
										if(roleActuals.length > 0){
											alert("There is already a row for this project and role.");
											return;
										}

									}
									setRoleID(id);
									saveProjectRoleActualHours(props.projectRoleActualHours!, "roleID", id,(entry) =>{
										AC.submitUndoStackEntry(entry);
									}).then(() =>{
										props.projectRoleActualHours!.roleID = id;
									});
								}}></RoleDropdown>
						):(
							<>{roles.find(role=>role.id==localActual.roleID)?.abbreviation}</>
						)}
					</td>
					<td className="sticky6">{prettyNum(totalHours)}</td>
					{props.weekKeys && props.weekKeys.map((key:string, index:number) =>{
						if(props.weekKey == null && actualHasDailyData(props.projectRoleActualHours!, key)){
							return(
								<td key={`week_label_${index}_${key??""}`} className={`calculated ${key==todayWeekKey?"today":""}`} onClick={() => {props.goToDailyInput(key)}}>
									{actualCalculateWeekHours(props.projectRoleActualHours!, key)}
								</td>
							)
						}
						return (
							<td key={`week_label_${index}_${key??""}}`} className={key==todayWeekKey?"today":""}>
								{(permissionsWrite.includes("Personal Timesheet") && isActive()) && !props.isReadOnly ?(
									<WeeklyTimesheetInput 
										type={WeeklyTimesheetInputType.ActualHours}
										actual={props.projectRoleActualHours!} 
										weekKey={key}></WeeklyTimesheetInput>
								):(
									<>{props.weekKey ? hoursForDay(props.projectRoleActualHours!, key):actualCalculateWeekHours(props.projectRoleActualHours!, key)}</>
								)}
							</td>
						)
					})}
					<td colSpan={2}></td>

					{(permissionsWrite.includes("Personal Timesheet") && isActive()) && !props.isReadOnly && (
						<div className="buttonDelete"
							onClick={() =>{
								if(window.confirm("Are you sure you'd like to delete this row?")){
									deleteProjectRoleActualHours(props.projectRoleActualHours!,(entry) =>{
										AC.submitUndoStackEntry(entry);
									})
								}
							}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
					)}
				</>
			)}
			{((props.type == WeeklyTimesheetRowType.PTO || props.type == WeeklyTimesheetRowType.STO) && props.timeOff) && (remainingTimeOff) &&(
				<>
				<td className="sticky1 right">						
					{permissionsWrite.includes("Personal Timesheet") && !props.isReadOnly && (
						<input type='checkbox' className="selectCheckbox hide-from-report"
							checked = {props.selected}
							onChange={(event) =>{
								if(props.type == WeeklyTimesheetRowType.PTO){
									if(props.onSelectedPTOChange){
										props.onSelectedPTOChange(props.timeOff!, event.target.checked);
									}
								}else{
									if(props.onSelectedSTOChange){
										props.onSelectedSTOChange(props.timeOff!, event.target.checked);
									}
								}
							}}></input>
					)}

					{props.type == WeeklyTimesheetRowType.PTO?"PTO":"STO"}
				</td>
				<td className="sticky2"></td>
				<td className="sticky3">{prettyNum((props.type == WeeklyTimesheetRowType.PTO)?remainingTimeOff.annualPTO:remainingTimeOff.annualSTO)}</td>
				<td className="sticky4">{prettyNum((props.type == WeeklyTimesheetRowType.PTO)?remainingTimeOff.rolloverPTO:remainingTimeOff.rolloverSTO)}</td>
				<td className="sticky5">{prettyNum((props.type == WeeklyTimesheetRowType.PTO)?remainingTimeOff.remainingPTO:remainingTimeOff.remainingSTO)}</td>
				<td className="sticky6">{prettyNum(totalHours)}</td>
				{props.weekKeys && props.weekKeys.map((key:string, index:number) =>{
					
					if(props.type == WeeklyTimesheetRowType.PTO && props.weekKey == null && timeOffCalculateWeekPTOHours(props.timeOff!, key)){
						return(
							<td key={`week_label_${index}_${key??""}}`} className={`calculated ${key==todayWeekKey?"today":""}`} onClick={() => {props.goToDailyInput(key)}}>
								{timeOffCalculateWeekPTOHours(props.timeOff!, key)}
							</td>
						)
					}else if(props.type == WeeklyTimesheetRowType.STO && props.weekKey == null && timeOffCalculateWeekSTOHours(props.timeOff!, key)){
						return(
							<td key={`week_label_${index}_${key??""}}`} className={`calculated ${key==todayWeekKey?"today":""}`} onClick={() => {props.goToDailyInput(key)}}>
								{timeOffCalculateWeekSTOHours(props.timeOff!, key)}
							</td>
						)
					}
					return (
						<td key={`week_label_${index}_${key??""}}`} className={key==todayWeekKey?"today":""}>
							{permissionsWrite.includes("Personal Timesheet") && !props.isReadOnly ?(
									<WeeklyTimesheetInput 
										type={ props.type == WeeklyTimesheetRowType.PTO?WeeklyTimesheetInputType.PTO:WeeklyTimesheetInputType.STO}
										timeOff={props.timeOff!} 
										weekKey={key}></WeeklyTimesheetInput>
							):(
								<>{props.type == WeeklyTimesheetRowType.PTO?timeOffCalculateWeekPTOHours(props.timeOff!, key):timeOffCalculateWeekSTOHours(props.timeOff!, key)}</>
							)}
						</td>
					)
				})}
				<td colSpan={2}></td>
			</>
			)}

			{(permissionsWrite.includes("Personal Timesheet") && !props.isReadOnly && props.type == WeeklyTimesheetRowType.AddRow && props.person) &&(
				<>
					<td colSpan={5} className="sticky1_5 left">

						<div className="buttonAdd" onClick={() =>{
							let newProjectRoleActualHours = {
								id: "",
								organizationID: organizationID,
								projectID: "",
								personID: props.person!.id,
								year:props.year,
								roleID: props.person!.roles?(props.person!.roles!.length>0?props.person!.roles![0].roleID:""):"",
								billable: true,
								order:props.nextOrder??0,
								hours: [],
								calculated: {
									totalHours: 0
								}
							}
							addDoc(collection(sharedFirestore, "ProjectRoleActualHours"), newProjectRoleActualHours).then((docRef) => {
								newProjectRoleActualHours.id = docRef.id;
								AC.submitUndoStackEntry({
									type: UndoStackEntryType.create,
									objectType: UndoStackEntryObjectType.actual,
									objectID: docRef.id,
									objectBeforeState: undefined,
									objectAfterState: {...newProjectRoleActualHours},
									description: "Add Project Role Actual Hours"
								})
							})
						}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Row</div>
					</td>
					<td className="sticky6"></td>
					<td colSpan={props.weekKeys.length+2}></td>
				</>
			)}
		</>
  )
}