
import "./IndividualProfitSharingHeader.css"
import { useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { Person } from "../../../FirebaseModel/Person";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { useEffect, useState } from "react";
import { Project } from "../../../FirebaseModel/Project";
import { Client } from "../../../FirebaseModel/Client";
import { MonthTotal } from "../../../FirebaseModel/MonthTotal";
interface IndividualProfitSharingHeaderProps{
}

export default function IndividualProfitSharingHeader(props:IndividualProfitSharingHeaderProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const roles = useSelector((state:{roles:Role[]}) => state.roles)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const projects = useSelector((state:{projects:Project[]}) => state.projects)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)


  return (
			<tr className={`IndividualProfitSharingHeader`}>
				<th>Client Name</th>
				<th>Project #</th>
				<th>Project Name</th>
				<th>Role</th>
				<th>Billable Hours Available</th>
				<th>Billable Hours Credited</th>
				<th>Quarterly Bonus</th>
				<th>Hours Recorded</th>
				<th>Date Completed</th>
				<th>Notes</th>
			</tr>
  )
}