
import { useCSVReader } from 'react-papaparse';
import React, { useEffect, useState } from "react"
import { addDoc, collection, deleteDoc, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./PopupImportPeople.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import { prettyNum, forceNumeric, prettyCurrency, forceDecimal, shortDate } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { saveClientAttributeChange } from "../../Utils/SaveClientFunctions";
import { useRef } from 'react';
import { ProjectRoleActualHours } from '../../FirebaseModel/ProjectRoleActualHours';
import { Person, PersonNonProjectTask } from '../../FirebaseModel/Person';
import { saveProjectAttributeChange } from '../../Utils/SaveProjectFunctions';
import { faFileDownload, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from '../../Utils/UndoStackEntry';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../Redux/ActionCreators';
import { stringToBase64 } from '../../Utils/StringToBase64';
import { cleanCSVString } from '../../Utils/CleanCSVString';
interface PopupImportPeopleProps{
	onDismiss:()=>void;
}

export default function PopupImportPeople(props:PopupImportPeopleProps){

  const progressRef = useRef<HTMLProgressElement>(null);
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
  const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)


  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

  const { CSVReader } = useCSVReader();

	const [errorMessage, setErrorMessage] = useState<string>("");
	const [allRows, setAllRows] = useState<any[]>([]);

	const downloadTemplateCSV = () =>{
		// generate a csv file with the appropriate headers and download it.

		let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Name,Email,Phone,Birthday,Start Date,End Date\n";

		let encodedUri = encodeURI(csvContent);
		let link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "people_import_template.csv");
		document.body.appendChild(link); // Required for FF

		link.click(); // This will download the data file named "my_data.csv".

		document.body.removeChild(link);
	}

	const downloadCurrentPersonsCSV = () =>{
		// generate a csv file with the appropriate headers and download it.

    let csvContent = "";
    csvContent += "Name,Email,Phone,Birthday,Start Date,End Date\n";

    persons.forEach(person =>{
      csvContent += `${cleanCSVString(person.name)},${cleanCSVString(person.email)},${cleanCSVString(person.phone)},${cleanCSVString(person.birthday)},${cleanCSVString(person.startDate)},${cleanCSVString(person.endDate)}\n`;
    })

		// convert content to base64 string and download as data uri
		let base64 = stringToBase64(csvContent);

		let dataUri = "data:text/csv;base64,"+base64;
    let link = document.createElement("a");
    link.setAttribute("href", dataUri);
    link.setAttribute("download", "people_import_template.csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".

    document.body.removeChild(link);
  }

	const validateFormat = (rows:any) => {

		if(rows.length < 2){
			setErrorMessage("File must have at least 2 rows");
			return false;
		}

		let headerRow = rows[0];
		if(headerRow.length != 5){
			setErrorMessage("File must have 5 columns");
			return false;
		}

		if(headerRow[0].toLowerCase() != "name"){
			setErrorMessage("First column must be 'Name'");
			return false;
		}

		if(headerRow[1].toLowerCase() != "email"){
			setErrorMessage("Second column must be 'Email'");
			return false;
		}

		if(headerRow[2].toLowerCase() != "phone"){
			setErrorMessage("Second column must be 'Phone'");
			return false;
		}

		if(headerRow[3].toLowerCase() != "birthday"){
			setErrorMessage("Third column must be 'Birthday'");
			return false;
		}

		if(headerRow[4].toLowerCase() != "start date"){
			setErrorMessage("Fourth column must be 'Start Date'");
			return false;
		}
		if(headerRow[5].toLowerCase() != "end date"){
			setErrorMessage("Fourth column must be 'End Date'");
			return false;
		}

		return true;
	}


  const processFile = async (rows: any) => {
    setAllRows(rows);
  }
  const startImport = async (rows: any) => {

    AC.submitUndoStackEntry({
      type: UndoStackEntryType.batchStart,
      objectType: UndoStackEntryObjectType.nonApplicable,
      objectID: "",
      objectBeforeState: undefined,
      objectAfterState: undefined,
      description: 'Import People'
    })

    setErrorMessage("");
    let dates:Date[] = [];
    let currentName = "";
    let currentProjectName = "";
    let currentPerson:any|null = null;
    let currentProjectNumber = "";
    let currentTask = "";
    let currentTaskID = "";

    let currentProject:Project|null = null;

    let firstDate:Date|null = null;
    let lastDate:Date|null = null;

    let modifiedProjects:Project[] = [];


    progressRef.current!.max = rows.length;
    progressRef.current!.style.display = "block";

    function startNextRow(i:number){
      setTimeout(() => {
        processNextRow(i);
      }, 100);
    }

    //for(let i = 0; i < rows.length; i++){
    async function processNextRow(i:number){
      if(i >= rows.length){
        console.log("Done processing file");
        progressRef.current!.style.display = "none";
        // setTimeout(() => {
        //   updateProjectsCalculatedActuals(modifiedProjects);
        // }, 100);

        AC.submitUndoStackEntry({
          type: UndoStackEntryType.batchEnd,
          objectType: UndoStackEntryObjectType.nonApplicable,
          objectID: "",
          objectBeforeState: undefined,
          objectAfterState: undefined,
          description: 'Import Timesheet'
        })
        return;
      }
      let row = rows[i];
			progressRef.current!.value = i;
      console.log(`Processing row ${i} of ${rows.length}...`);
      startNextRow(i+1);

			let name = row[0];
			let email = row[1];
			let phone = row[2];
			let birthday = row[3];
			let startDate = row[4];
			let endDate = row[5];


			let person = persons.find(person => person.name.toLowerCase() == name.toLowerCase())
			if(person == null){

				let docData = {
					organizationID:organization.id,
					name:name,
					active:endDate != "",
					email:email.toLowerCase(),
          phone:phone,
					startDate:startDate,
					endDate:endDate,
					birthday:birthday,
					permissions:[] as string[],
					roles:[]as {roleID:string, weight:number}[],
          nonProjectTasks:[] as PersonNonProjectTask[],
          assignedTaskOrder:[] as string[],
				}
				let docref =  await addDoc(collection(sharedFirestore,"Person"),docData)
				let newPerson = {... docData, id:docref.id} as Person;

        AC.submitUndoStackEntry({
          type: UndoStackEntryType.create,
          objectType: UndoStackEntryObjectType.person,
          objectID: newPerson.id,
          objectBeforeState: undefined,
          objectAfterState: {...newPerson},
          description: 'Create Person'
        })
				persons.push(newPerson);
			}
    }
		if(validateFormat(rows)){
    	startNextRow(1);
		}
  }


  return (
    <div className={`popup PopupImportPeople`}>
			<h1>Import Team Members from CSV</h1>

			{errorMessage!="" && <div className="errorMessage">{errorMessage}</div>}

      <div className='instructions'>
        The CSV file must be formatted with these headers in this order:
				<ul>
					<li>Person Name</li>
					<li>Person Email</li>
					<li>Person Phone</li>
					<li>Birthday </li>
					<li>Start Date </li>
					<li>End Date</li>
				</ul>
      </div>

      <CSVReader
        onUploadAccepted={async (results: any) => {
          console.log('---------------------------');
          console.log(results);
          console.log('---------------------------');
          processFile(results.data);
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }: any) => (
          <>
            <div className='csvReader'>
              <button type='button' {...getRootProps()} className={"browseFile"}>
                Browse file
              </button>
              <div className={"acceptedFile"}>
                {acceptedFile && acceptedFile.name}
              </div>
              <button {...getRemoveFileProps()} className={"remove"}>
                Remove
              </button>
            </div>
            {/* <ProgressBar  className={"progressBarBackgroundColor"} /> */}
          </>
        )}
      </CSVReader>
      {allRows.length > 0 && (
        <div className='buttonImport'
          onClick={() =>{
            startImport(allRows)
            setAllRows([]);
          }}><FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon> Start Import</div>
      )}
      <progress ref={progressRef} style={{display:'none'}} className={"progressBarBackgroundColor"} />


			<div className='buttonTemplate'
				onClick={downloadTemplateCSV}>
				<FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon> Download Template
				</div>
			<div className='buttonTemplate'
				onClick={downloadCurrentPersonsCSV}>
				<FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon> Download Current Team CSV
				</div>

				<div className="floatingButtons">
					<div className="buttonSave"
						onClick={() =>{
							projects.forEach( project =>{
								let actualsForProject = actuals.filter(actual => actual.projectID === project.id);
								let totalHours = actualsForProject.reduce((total, actual) => total + actual.calculated.totalHours, 0);
								if(project.calculated.actualHours != totalHours){
									let newCalcualted = {...project.calculated}
									if(newCalcualted.actualHours != totalHours){
										newCalcualted.actualHours = totalHours;
										saveProjectAttributeChange(project,organization,clients, "calculated", newCalcualted,(entry:UndoStackEntry) =>{
                      AC.submitUndoStackEntry(entry);
                    })
									}
								}
							})
							props.onDismiss();
						}}>Done</div>
					</div>
		</div>
  )
}