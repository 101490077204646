

import "./MonthlyBSPTable.css"
import { useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { Person } from "../../../FirebaseModel/Person";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { useEffect, useState } from "react";
import { Project } from "../../../FirebaseModel/Project";
import { Client } from "../../../FirebaseModel/Client";
import { MonthTotal } from "../../../FirebaseModel/MonthTotal";
import MonthlyBSPHeader from "../MonthlyBSPHeader/MonthlyBSPHeader";
import MonthlyBSPRow from "../MonthlyBSPRow/MonthlyBSPRow";
import DownloadableTable from "../../DownloadableTable/DownloadableTable";
interface MonthlyBSPTableProps{
	month:number,
	year:number,
	monthTotal:MonthTotal,
	onPullFromLastMonth:() => void
	onPushToNextMonth:() => void
}



export default function MonthlyBSPTable(props:MonthlyBSPTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const roles = useSelector((state:{roles:Role[]}) => state.roles)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const projects = useSelector((state:{projects:Project[]}) => state.projects)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)


  return (
		<div className={`MonthlyBSPTableContainer`}>
			<DownloadableTable className={`MonthlyBSPTable`} fileName="Monthly BSP">
				<thead>
					<MonthlyBSPHeader 
						month={props.month} 
						year={props.year} 
						monthTotal={props.monthTotal}
						onPullFromLastMonth={props.onPullFromLastMonth}
						onPushToNextMonth={props.onPushToNextMonth}></MonthlyBSPHeader>
				</thead>
				<tbody>
					{props.monthTotal.bspProjects.map((bspProject, index) => {
						let project = projects.find(project => project.id === bspProject.projectID)
						if(project == null){
							return <></>
						}
						return <MonthlyBSPRow
							key={bspProject.projectID} 
							bspProject={bspProject} 
							monthTotal={props.monthTotal} 
							month={props.month} 
							year={props.year}></MonthlyBSPRow>
					})}
				</tbody>
			</DownloadableTable>
		</div>
  )
}