import { shortDate } from "../Utils/formatting";
import { Department } from "./Department";
import { ProjectRoleActualHours } from "./ProjectRoleActualHours";
import { Role } from "./Role";

export interface Person{
	id:string;
  organizationID:string;
  name:string;
  email:string;
	phone:string;
	active:boolean;
	roles?:{
		roleID:string,
		weight:number
	}[];
	departmentID:string;
	permissions?:string[],
	permissionsWrite?:string[],
	eligibleForProfitSharing:boolean,
	actualsOrder?:{[year:string]:string[]};
	birthday:string;
	startDate:string;
	endDate:string;
	nonProjectTasks:PersonNonProjectTask[];
	PTOPerYear:number;
	STOPerYear:number;
	PTOSTORenewal:string;
	PTOExpiration:string;
	STOExpiration:string;
	calculated:{
		PTOHoursRemaining:number;
		STOHoursRemaining:number;
	}
	assignedTaskOrder:string[];
}

export interface PersonNonProjectTask{
	id:string;
	name:string;
	plannedHours:number;
	dueDate:string;
	percenComplete:number;
	active:boolean;
	scheduleBlocks:PersonNonProjectTaskScheduleBlock[];	
}

export interface PersonNonProjectTaskScheduleBlock{
	id:string;
	taskID:string;
	personID:string;
	startDate:string;
	endDate:string;
	dailyPlannedHours:number[];
}

export function personRolesDescription(person:Person, roles:Role[]):string{
	let description = "";
	if(person.roles){
		for(var i in person.roles){
			let role = person.roles[i];
			for(var j in roles){
				let role2 = roles[j];
				if(role.roleID === role2.id){
					if(description != ""){
						description += ", ";
					}
					description += role2.abbreviation+" "+ (Math.round(role.weight*100)) + "%";
				}
			}
		}
	}
	return description;
}

export function personCalculatedRoleWeights(person:Person, roles:Role[],allActuals:ProjectRoleActualHours[]){
	let totalHours = 0;
	let hoursByRole:{[roleID:string]:number} = {};
	for(let actual of allActuals){
		if(actual.personID == person.id){
			totalHours += actual.calculated.totalHours;
			if(!hoursByRole[actual.roleID]){
				hoursByRole[actual.roleID] = 0;
			}
			hoursByRole[actual.roleID] += actual.calculated.totalHours;
		}
	}
	let result = "";
	for(var roleID in hoursByRole){
		let hours = hoursByRole[roleID];
		let percentage = hours/totalHours;
		if(result != ""){
			result += ", ";
		}
		for(let role of roles){
			if(role.id == roleID){
				result += role.abbreviation + " " + Math.round(percentage*100) + "%";
			}
		}
	}
	return result;
}

export function personCalculatedRoleWeightsFiltered(person:Person, roles:Role[],allActuals:ProjectRoleActualHours[], filterStartMonth:string, filterStartYear:string, filterEndMonth:string, filterEndYear:string){
	let totalHours = 0;
	let hoursByRole:{[roleID:string]:number} = {};
	for(let actual of allActuals){
		if(actual.personID == person.id){
			for(let hours of actual.hours){
				if(filterStartYear != "" && (new Date(hours.date)).getFullYear() < parseInt(filterStartYear)){
					continue;
				}
				if(filterEndYear != "" && (new Date(hours.date)).getFullYear() > parseInt(filterEndYear)){
					continue;
				}
				if(filterStartMonth!= "" && (new Date(hours.date)).getMonth() < parseInt(filterStartMonth)){
					continue;
				}
				if(filterEndMonth != "" && (new Date(hours.date)).getMonth() > parseInt(filterEndMonth)){
					continue;
				}
				totalHours += hours.hours;
				if(!hoursByRole[actual.roleID]){
					hoursByRole[actual.roleID] = 0;
				}
				hoursByRole[actual.roleID] += hours.hours;
			}
		}
	}
	let result = "";
	for(var roleID in hoursByRole){
		let hours = hoursByRole[roleID];
		let percentage = hours/totalHours;
		if(result != ""){
			result += ", ";
		}
		for(let role of roles){
			if(role.id == roleID){
				result += role.abbreviation + " " + Math.round(percentage*100) + "%";
			}
		}
	}
	return result;
}


export function findNonProjectScheduleBlocksForDate(person:Person, date:Date){
	let scheduleBlocks:PersonNonProjectTaskScheduleBlock[] = [];
	let personTasks = person.nonProjectTasks;
	if(personTasks){
		let tasks = personTasks.filter(t => t.scheduleBlocks.find(sb => new Date(sb.startDate) <= date && new Date(sb.endDate) >= date));
		if(tasks && tasks.length > 0){
			tasks.forEach(task =>{
				let scheduleBlock = task.scheduleBlocks.find(sb => new Date(sb.startDate) <= date && new Date(sb.endDate) >= date);
				if(scheduleBlock){
					scheduleBlocks.push(scheduleBlock);
				}
			})
		}
	}
	return scheduleBlocks;
}

export function findNonProjectTasksFromDueDate(person:Person, date:Date){
	let tasks:PersonNonProjectTask[] = [];
	let roleTasks = person.nonProjectTasks;
	if(roleTasks){
		tasks = roleTasks.filter(t => t.dueDate == shortDate(date));
	}
	return tasks;
}