
import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./ClientContactDropdown.css"
import { useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Client } from "../../FirebaseModel/Client";
import { ClientContact } from "../../FirebaseModel/ClientContact";
interface ClientContactDropdownProps{
	className?:string,
	clientID:string,
	defaultValue?:string,
	prompt?:string,
	onChange?:(value:ClientContact) => void
	onCreateNew?:()=>void
}

export default function ClientContactDropdown(props:ClientContactDropdownProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const clientContacts = useSelector((state:{clientContacts:ClientContact[]}) => state.clientContacts)
  const permissions = useSelector((state:{permissions:string[]}) => state.permissions)

	const [sortedClientContacts, setSortedClientContacts] = useState<ClientContact[]>([]);
	const [selectedValue, setSelectedValue] = useState(props.defaultValue);

	useEffect(() =>{
		if(organization){
			let contacts = clientContacts.filter((client:ClientContact) => client.clientID == props.clientID);
			if(contacts.length>0){
				let tempSortedClientContacts = [... contacts];
				tempSortedClientContacts.sort((a,b) =>{
					if(a.name.toLowerCase() < b.name.toLowerCase()){
						return -1;
					}
					if(a.name.toLowerCase() > b.name.toLowerCase()){
						return 1;
					}
					return 0;
				});
				setSortedClientContacts(tempSortedClientContacts);
			}
		}
	},[organization,clients])

	useEffect(() =>{
		setSelectedValue(props.defaultValue);
	},[props.defaultValue])

  return (
    <select 
				className={`ClientContactDropdown ${props.className??""}`} 
				value={selectedValue}
				onChange={(event) =>{
					setSelectedValue(event.target.value);
					if(event.target.value == "create new"){
						if(props.onCreateNew){
							props.onCreateNew();
						}
					}else{
						if(props.onChange){
							let index =  parseInt(event.target.value);
							props.onChange(sortedClientContacts[index]);
						}
					}
				}}>
			<option value={""}>{props.prompt??"Select Client Contact"}</option>
			{sortedClientContacts.map((clientContact:ClientContact, index:number) =>{
				return <option key={"client_contact_option_"+index} value={index}>{clientContact.name}</option>
			})}
			{permissions.indexOf("Clients") != -1 && (
				<option value="create new">Create a new Client Contact</option>
			)}
    </select>
  )
}