

import React, { useEffect, useState } from "react"
import "./OrganizationBackups.css"
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import OrganizationScreenRolesRow, { OrganizationScreenRolesRowProps } from "../OrganizationScreenRolesRow/OrganizationScreenRolesRow";
import DraggableTableBody from "../../DraggableTableBody/DraggableTableBody";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { saveOrganizationAttributeChange } from "../../../Utils/SaveOrganizationFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Department } from "../../../FirebaseModel/Department";
import OrganizationScreenDepartmentsRow from "../OrganizationScreenDepartmentsRow/OrganizationScreenDepartmentsRow";
import { Backup } from "../../../FirebaseModel/Backup";
import { BackupObject, BackupObjects, ClearAndRestoreFromBackup, CreateBackup, DeleteBackup } from "../../../Utils/BackupFunctions";
import { Person } from "../../../FirebaseModel/Person";
import { Project } from "../../../FirebaseModel/Project";
import { View } from "../../../FirebaseModel/View";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Client } from "../../../FirebaseModel/Client";
import { ClientContact } from "../../../FirebaseModel/ClientContact";
import { MonthTotal } from "../../../FirebaseModel/MonthTotal";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { YearTotal } from "../../../FirebaseModel/YearTotal";
import { Tooltip } from "@mui/material";

interface OrganizationBackupsProps {
}




export default function OrganizationBackups(props: OrganizationBackupsProps) {

	const organizationID = useSelector((state: { organizationID: string }) => state.organizationID)
	const organization = useSelector((state: { organization: Organization }) => state.organization)

	const backups = useSelector((state: { backups: Backup[] }) => state.backups)
	const persons = useSelector((state: { persons: Person[] }) => state.persons)
	const projects = useSelector((state: { projects: Project[] }) => state.projects)
	const views = useSelector((state: { views: View[] }) => state.views)
	const clients = useSelector((state: { clients: Client[] }) => state.clients)
	const clientContacts = useSelector((state: { clientContacts: ClientContact[] }) => state.clientContacts)
	const departments = useSelector((state: { departments: Department[] }) => state.departments)
	const monthTotals = useSelector((state: { monthTotals: MonthTotal[] }) => state.monthTotals)
	const yearTotals = useSelector((state: { yearTotals: YearTotal[] }) => state.yearTotals)
	const actuals = useSelector((state: { actuals: ProjectRoleActualHours[] }) => state.actuals)
	const roles = useSelector((state: { roles: Role[] }) => state.roles)
	const timeOffList = useSelector((state: { timeOffList: TimeOff[] }) => state.timeOffList)

	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setDepartments: ActionCreators.setDepartments,
		submitUndoStackEntry: ActionCreators.submitUndoStackEntry
	}, dispatch);

	const [sortedBackups, setSortedBackups] = useState<Backup[]>([]);
	useEffect(() => {
		let sortedBackups = [...backups];
		sortedBackups.sort((a, b) => b.backupDateMillis - a.backupDateMillis);
		setSortedBackups(sortedBackups);
	}, [backups]);

	const createTheBackup = async () => {
		let backupParams = {
			"Client": clients,
			"ClientContact": clientContacts,
			"Department": departments,
			"MonthTotal": monthTotals,
			"Organization": [organization],
			"Person": persons,
			"Project": projects,
			"ProjectRoleActualHours": actuals,
			"Role": roles,
			"TimeOff": timeOffList,
			"View": views,
			"YearTotal": yearTotals,
			"CommunicationProject": null,
			"ProjectFile": null,
			"ProjectMessageBoard": null,
			"Thread": null,
			"ThreadPage": null,
		}

		let backupKeys = Object.keys(backupParams);

		let backup = await CreateBackup(backupDescription, backupKeys, organizationID);
		setBackingUpID(backup.id);
		setProgressCurrent(0);
		let progressCurrent = 0;
		setProgressTotal(1);

		await BackupObjects(backup.id, organizationID, backupParams, (progressCurrent: number, progressTotal: number) => {
			setProgressCurrent(progressCurrent);
			setProgressTotal(progressTotal);
		});

		setBackingUpID("");
		console.log("backed up");
	}


	const [backupDescription, setBackupDescription] = useState("");

	const [backingUpID, setBackingUpID] = useState("");
	const [progressCurrent, setProgressCurrent] = useState(0);
	const [progressTotal, setProgressTotal] = useState(0);

	return (
		<table
			className={`OrganizationBackups`}>
			<thead>
				<tr>
					<td colSpan={2}>
						<input type="text" placeholder="Backup Description" value={backupDescription} onChange={(e) => {
							setBackupDescription(e.target.value);
						}}></input>
					</td>
					<td>
						<div className='buttonCreate' onClick={async () => {
							createTheBackup();
						}}>Create New Backup</div>
					</td>
				</tr>
				<tr>
					<th>Backup Description</th>
					<th>Backup Date</th>
					<th>Functions</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{sortedBackups.map((backup: Backup) => {
					return <tr key={backup.id}>
						<td>{backup.name}</td>
						<Tooltip title={new Date(backup.backupDateMillis).toLocaleString()}>
							<td>{backup.backupDate}</td>
						</Tooltip>
						<td>
							{backingUpID != backup.id && (
								<>
									<div className='buttonRestore' onClick={() => {
										if(window.confirm("Are you sure you want to restore this backup?")){
											ClearAndRestoreFromBackup(backup, (phase:string, progressCurrent: number, progressTotal: number) => {
												setProgressCurrent(progressCurrent);
												setProgressTotal(progressTotal);
											}).then(() =>{
												console.log("restored");
											})
										}
									}}>Clear &amp; Restore</div>
									<div className="buttonDelete" onClick={() => {
										if(window.confirm("Are you sure you want to DELETE this backup?")){
											DeleteBackup(backup);
										}
									}}><FontAwesomeIcon icon={faTrash}/></div>
								</>
							)}
						</td>
						<td>
							{backingUpID === backup.id ? <div>{progressCurrent}/{progressTotal}({Math.round(progressCurrent / progressTotal * 100)}%)</div> : null}
						</td>
					</tr>
				})}
			</tbody>
		</table>
	)
}
