export interface Organization{
	id:string;
  name:string;
	identifier:string;
	shortName:string;
	organizationDescription:string;
  hourlyRate:number;
	roleIDOrder:string[];
	departmentIDOrder:string[];
	projectTypeList:string[];
	projectStatusList:string[];
	projectStatusCategories:{
		bidding:string[];
		active:string[];
		sold:string[];
		completed:string[];
	},
	fixedCostItemsList:string[];
	offersProfitSharing:boolean;
	profitSharingPercentage:number;
	averageResourceCost:number;
	averageWorkWeeksPerYear:number;
	targetAverageHoursPerWeek:number;
	organizationAdministratorPersonIDs:string[];	
	holidaysByYear:{
		[year:number]:OrganizationHoliday[]
	};
  managesPTOAtOrganizationLevel:boolean;
	PTOPerYear:number;
	STOPerYear:number;
	PTOSTORenewal:string;
	PTOExpiration:string;
	STOExpiration:string;
}

export interface OrganizationHoliday{
	date:string;
	description:string;
}



export function holiday(date:string, organization:Organization){
	let holidaysThisYear = organization.holidaysByYear[new Date(date).getFullYear()]??[];
	let holiday = holidaysThisYear.find(holiday => holiday.date === date);
	return holiday;
}