

import React, { useEffect, useState } from "react"
import "./ProductionScheduleDepartmentRow.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Client } from "../../../FirebaseModel/Client";
import { ClientContact } from "../../../FirebaseModel/ClientContact";
import { Organization, holiday } from "../../../FirebaseModel/Organization";
import { Department } from "../../../FirebaseModel/Department";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { shortDate } from "../../../Utils/formatting";
interface ProductionScheduleDepartmentRowProps{
	departmentID:string;
	collapsed:boolean;
	numMonths:number;
	onCollapsedChanged:(departmentID:string, collapsed:boolean) => void;
}


export default function ProductionScheduleDepartmentRow(props:ProductionScheduleDepartmentRowProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const clientContacts = useSelector((state:{clientContacts:ClientContact[]}) => state.clientContacts)
  const departments = useSelector((state:{departments:Department[]}) => state.departments)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles},dispatch);

	const [months, setMonths] = useState<Date[]>([]);
	const [days, setDays] = useState<Date[]>([]);

	const today = new Date();

	const [department, setDepartment] = useState<Department>(departments.find(department => department.id === props.departmentID)!);
	useEffect(() =>{
		setDepartment(departments.find(department => department.id === props.departmentID)!);
	},[props.departmentID])
	useEffect(() =>{
		let months:Date[] = [];
		let date = new Date();
		for(let i = 0; i < props.numMonths; i++){
			months.push(new Date(date.getFullYear(), date.getMonth() + i, 1));
		}
		setMonths(months);

		// add every day from months into the days array
		let days:Date[] = [];
		months.forEach(month =>{
			let date = new Date(month);
			while(date.getMonth() === month.getMonth()){
				days.push(new Date(date));
				date.setDate(date.getDate() + 1);
			}
		})
		setDays(days);
	},[props.numMonths])

  return (
		<tr className={`ProductionScheduleDepartmentRow department`}>
			<td
				onClick={() =>{
					props.onCollapsedChanged(department.id, !props.collapsed);				
				}}>
				<span className="indicator"><FontAwesomeIcon icon={!props.collapsed?faChevronDown:faChevronRight}></FontAwesomeIcon></span>
				{department.name}
			</td>
			<td></td>
			<td></td>
			<td></td>
			<td></td>
			<td></td>
			{days.map((day, index) =>{
				// add a header with the single letter day of the week
				let h = holiday(shortDate(day),organization)
				let isToday = day.getDate() == today.getDate() && day.getMonth() == today.getMonth() && day.getFullYear() == today.getFullYear();
				let isWeekend = day.getDay() === 0 || day.getDay() === 6;
				return <th key={index} className={`day ${isToday?"today":""} ${isWeekend?"weekend":""} ${h?"holiday":""}`}></th>
			})}
		</tr>
  )
}