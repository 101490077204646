import React, { useEffect, useState } from "react"
import { sharedFirebaseAuth, sharedFirestore } from "../../Utils/SharedFirebase"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./OrganizationScreen.css"
import { Project } from "../../FirebaseModel/Project";
import { useDispatch, useSelector } from "react-redux";
import { faAddressCard, faCheck, faHouse, faPeopleGroup, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { Screens } from "../../Redux/Navigation";
import { Organization } from "../../FirebaseModel/Organization";
import { forceDecimal, forcePercentage, prettyCurrency, prettyNum, shortDate } from "../../Utils/formatting";
import { saveOrganizationAttributeChange } from "../../Utils/SaveOrganizationFunctions";
import PopupAddPerson from "../../Popups/PopupAddPerson/PopupAddPerson";
import { saveProjectAttributeChange } from "../../Utils/SaveProjectFunctions";
import PopupAddRole from "../../Popups/PopupAddRole/PopupAddRole";
import OrganizationScreenRolesTable from "../../Components/OrganizationScreen/OrganizationScreenRolesTable/OrganizationScreenRolesTable";
import TeamScreenPeopleTable from "../../Components/TeamScreen/TeamScreenPeopleTable/TeamScreenPeopleTable";
import PopupAddClient from "../../Popups/PopupAddClient/PopupAddClient";
import ClientsScreenClientsInput from "../../Components/ClientsScreen/ClientsScreenClientsInput/ClientsScreenClientsInput";
import ClientsScreenClientsTable from "../../Components/ClientsScreen/ClientsScreenClientsTable/ClientsScreenClientsTable";
import { Person } from "../../FirebaseModel/Person";
import NavigationButtons from "../../Components/NavigationButtons/NavigationButtons";
import PopupAddProject from "../../Popups/PopupAddProject/PopupAddProject";
import OrganizationScreenProjectStatusOptionsTable from "../../Components/OrganizationScreen/OrganizationScreenProjectStatusOptionsTable/OrganizationScreenProjectStatusOptionsTable";
import OrganizationScreenFixedCostItemsOptionsTable from "../../Components/OrganizationScreen/OrganizationScreenFixedCostItemsOptionsTable/OrganizationScreenFixedCostItemsOptionsTable";
import { UndoStackEntry } from "../../Utils/UndoStackEntry";
import OrganizationScreenDepartmentsTable from "../../Components/OrganizationScreen/OrganizationScreenDepartmentsTable/OrganizationScreenDepartmentsTable";
import PopupAddDepartment from "../../Popups/PopupAddDepartment/PopupAddDepartment";
import OrganizationYearHolidaysTable from "../../Components/OrganizationScreen/OrganizationYearHolidaysTable/OrganizationYearHolidaysTable";
import { Client } from "../../FirebaseModel/Client";
import OrganizationScreenProjectTypesTable from "../../Components/OrganizationScreen/OrganizationScreenProjectTypesTable/OrganizationScreenProjectTypesTable";
import OrganizationAdministratorsList from "../../Components/OrganizationScreen/OrganizationAdministratorsList/OrganizationAdministratorsList";

import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OrganizationBackups from "../../Components/OrganizationScreen/OrganizationBackups/OrganizationBackups";

interface OrganizationScreenProps{
}

let slowSearchTimeout:NodeJS.Timeout|null = null;

export default function OrganizationScreen(props:OrganizationScreenProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setScreen:ActionCreators.setScreen,
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);


	const organization = useSelector((state:{organization:Organization}) => state.organization)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const person = persons.find(person => person.email.toLowerCase() === sharedFirebaseAuth.currentUser!.email!.toLowerCase());
	let permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite);

	const [organizationName, setOrganizationName] = useState(organization!.name);
	const [shortName, setShortName] = useState(organization!.shortName);
	const [organizationDescription, setOrganizationDescription] = useState(organization!.organizationDescription);
	const [organizationHourlyRate, setOrganizationHourlyRate] = useState(organization!.hourlyRate);
	const [averageResourceCost, setAverageResourceCost] = useState(organization!.averageResourceCost);
	const [averageWorkWeeksPerYear, setAverageWorkWeeksPerYear] = useState(organization!.averageWorkWeeksPerYear);
	const [targetAverageHoursPerWeek, setTargetAverageHoursPerWeek] = useState(organization!.targetAverageHoursPerWeek);
	const [organizationOffersProfitSharing, setOrganizationOffersProfitSharing] = useState(organization!.offersProfitSharing);
	const [organizationProfitSharingPercent, setOrganizationProfitSharingPercent] = useState(organization!.profitSharingPercentage);

	const [managesPTOAtOrganizationLevel, setManagesPTOAtOrganizationLevel] = useState(organization!.managesPTOAtOrganizationLevel);
  const [PTOPerYear, setPTOPerYear] = useState(organization!.PTOPerYear);
	const [STOPerYear, setSTOPerYear] = useState(organization!.STOPerYear);
	const [PTOSTORenewal, setPTOSTORenewal] = useState(organization!.PTOSTORenewal);
	const [PTOExpiration, setPTOExpiration] = useState(organization!.PTOExpiration);
	const [STOExpiration, setSTOExpiration] = useState(organization!.STOExpiration);

	const [showingDepartmentPopup, setShowingDepartmentPopup] = useState(false)
	const [showingRolePopup, setShowingRolePopup] = useState(false)
	const [showingClientPopup, setShowingClientPopup] = useState(false)
	const [showingProjectPopup, setShowingProjectPopup] = useState(false)
	const [selectedProject, setSelectedProject] = useState<Project|null>(null);

	const canWrite = permissionsWrite.includes("Organization");


  return (
		<>
	    <div className="screen OrganizationScreen">
				<h1>Organization</h1>

				<NavigationButtons />


				{organization && (
					<>
						<div className="row">
							<div className="col">
								<div className="label">Name</div>
								{canWrite ?(
									<input className={`organizationName`} 
									type='text'
										defaultValue={organizationName}
										onBlur={(event) =>{
											saveOrganizationAttributeChange(organization!,"name",event.target.value,(entry) =>{
												AC.submitUndoStackEntry(entry);
											})
										}}></input>
								):(
									<>{organizationName}</>
								)}
							</div>
							<div className="col">
								<div className="label">Short Name</div>
								{canWrite ?(
									<input className={`organizationShortName`} 
									type='text'
										defaultValue={shortName}
										onBlur={(event) =>{
											saveOrganizationAttributeChange(organization!,"shortName",event.target.value,(entry) =>{
												AC.submitUndoStackEntry(entry);
											})
										}}></input>
								):(
									<>{shortName}</>
								)}
							</div>
							<div className="col">
								<div className="label">Hourly Rate</div>
								{canWrite ?(
									<input type='text'
										className="hourlyRate"
										onKeyPress={(event)=>{forceDecimal(event)}} 
										defaultValue={prettyCurrency(organizationHourlyRate)}
										onFocus={(event)=>{
											let input = event.target as HTMLInputElement
											input.setSelectionRange(0,input.value.length)
										}}
										onBlur={(event) =>{
											if(event.target.value ==""){
												event.target.value = "0";
											}
											event.target.value = prettyCurrency(event.target.value)
											saveOrganizationAttributeChange(organization!,"hourlyRate",parseFloat(event.target.value.replace("$","").replace(/,/g,"")),(entry) =>{
												AC.submitUndoStackEntry(entry);
											})
										}}></input>
								):(
									<>{prettyCurrency(organizationHourlyRate)}</>
								)}
							</div>
							<div className="col">
								<div className="label">Average Resource Cost</div>
								{canWrite ?(
									<input type='text'
										className="averageResourceCost"
										onKeyPress={(event)=>{forceDecimal(event)}} 
										defaultValue={prettyCurrency(averageResourceCost)}
										onFocus={(event)=>{
											let input = event.target as HTMLInputElement
											input.setSelectionRange(0,input.value.length)
										}}
										onBlur={(event) =>{
											if(event.target.value ==""){
												event.target.value = "0";
											}
											event.target.value = prettyCurrency(event.target.value)
											saveOrganizationAttributeChange(organization!,"averageResourceCost",parseFloat(event.target.value.replace("$","").replace(/,/g,"")),(entry) =>{
												AC.submitUndoStackEntry(entry);
											})
										}}></input>
								):(
									<>{prettyCurrency(averageResourceCost)}</>
								)}
							</div>
							<div className="col">
								<div className="label">Average Work<br/>Weeks per year</div>
								{canWrite ?(
									<input type='text'
										className="averageWorkWeeksPerYear"
										onKeyPress={(event)=>{forceDecimal(event)}} 
										defaultValue={prettyNum(averageWorkWeeksPerYear)}
										onFocus={(event)=>{
											let input = event.target as HTMLInputElement
											input.setSelectionRange(0,input.value.length)
										}}
										onBlur={(event) =>{
											if(event.target.value ==""){
												event.target.value = "0";
											}
											event.target.value = prettyNum(event.target.value)
											saveOrganizationAttributeChange(organization!,"averageWorkWeeksPerYear",parseFloat(event.target.value.replace("$","").replace(/,/g,"")),(entry) =>{
												AC.submitUndoStackEntry(entry);
											})
										}}></input>
								):(
									<>{prettyNum(averageWorkWeeksPerYear)}</>
								)}
							</div>
							<div className="col">
								<div className="label">Target Average<br/> Hours per week</div>
								{canWrite ?(
									<input type='text'
										className="targetAverageHoursPerWeek"
										onKeyPress={(event)=>{forceDecimal(event)}} 
										defaultValue={prettyNum(targetAverageHoursPerWeek)}
										onFocus={(event)=>{
											let input = event.target as HTMLInputElement
											input.setSelectionRange(0,input.value.length)
										}}
										onBlur={(event) =>{
											if(event.target.value ==""){
												event.target.value = "0";
											}
											event.target.value = prettyNum(event.target.value)
											saveOrganizationAttributeChange(organization!,"targetAverageHoursPerWeek",parseFloat(event.target.value.replace("$","").replace(/,/g,"")),(entry) =>{
												AC.submitUndoStackEntry(entry);
											})
										}}></input>
								):(
									<>{prettyNum(targetAverageHoursPerWeek)}</>
								)}
							</div>

							<div className="col">
								<div className="label">Offers Profit<br/>Sharing</div>
								{canWrite ?(
								<input className={`organizationOffersProfitSharing`} 
									type="checkbox"
									checked={organizationOffersProfitSharing}
									onChange={(event) =>{
										saveOrganizationAttributeChange(organization!,"offersProfitSharing",event.target.checked,(entry) =>{
											AC.submitUndoStackEntry(entry);
										})
										setOrganizationOffersProfitSharing(event.target.checked);
									}}></input>
								):(
									<>{organizationOffersProfitSharing ?? <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}</>
								)}
							</div>

							{organizationOffersProfitSharing && (
								<div className="col">
									<div className="label">Profit<br/>Sharing %</div>
									{canWrite ?(
										<input type='text'
											className="profitSharingPercent"
											onKeyPress={(event)=>{forcePercentage(event)}} 
											defaultValue={Math.round(organizationProfitSharingPercent*1000)/10+"%"}
											onFocus={(event)=>{
												let input = event.target as HTMLInputElement
												input.setSelectionRange(0,input.value.length)
											}}
											onBlur={(event) =>{
												if(event.target.value ==""){
													event.target.value = "0";
												}
												let value = parseFloat(event.target.value)/100;
												event.target.value =  (value*100)+"%"
												saveOrganizationAttributeChange(organization!,"profitSharingPercentage",value,(entry) =>{
													AC.submitUndoStackEntry(entry);
												})
												setOrganizationProfitSharingPercent(value);
											}}></input>
									):(
										<>{Math.round(organizationProfitSharingPercent*1000)/10}%</>
									)}
								</div>
							)}
							<div className="col">
								<div className="label">Manages PTO at<br/>Organization Level</div>
								{canWrite ?(
								<input className={`managesPTOAtOrganizationLevel`} 
									type="checkbox"
									checked={managesPTOAtOrganizationLevel}
									onChange={(event) =>{
										saveOrganizationAttributeChange(organization!,"managesPTOAtOrganizationLevel",event.target.checked,(entry) =>{
											AC.submitUndoStackEntry(entry);
										})
										setManagesPTOAtOrganizationLevel(event.target.checked);
									}}></input>
								):(
									<>{managesPTOAtOrganizationLevel ?? <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}</>
								)}
							</div>

							{managesPTOAtOrganizationLevel && (
								<>
									<div className="col">
										<div className="label">PTO Hours<br/>Per Year</div>
										{canWrite ?(
											<input type='text'
												className="ptoPerYear"
												onKeyPress={(event)=>{forceDecimal(event)}} 
												defaultValue={prettyNum(PTOPerYear)}
												onFocus={(event)=>{
													let input = event.target as HTMLInputElement
													input.setSelectionRange(0,input.value.length)
												}}
												onBlur={(event) =>{
													if(event.target.value ==""){
														event.target.value = "0";
													}
													event.target.value = prettyNum(event.target.value)
													saveOrganizationAttributeChange(organization!,"PTOPerYear",parseFloat(event.target.value.replace("$","").replace(/,/g,"")),(entry) =>{
														AC.submitUndoStackEntry(entry);
													})
												}}></input>
										):(
											<>{prettyNum(PTOPerYear)}</>
										)}
									</div>
									<div className="col">
										<div className="label">STO Hours<br/>Per Year</div>
										{canWrite ?(
											<input type='text'
												className="stoPerYear"
												onKeyPress={(event)=>{forceDecimal(event)}} 
												defaultValue={prettyNum(STOPerYear)}
												onFocus={(event)=>{
													let input = event.target as HTMLInputElement
													input.setSelectionRange(0,input.value.length)
												}}
												onBlur={(event) =>{
													if(event.target.value ==""){
														event.target.value = "0";
													}
													event.target.value = prettyNum(event.target.value)
													saveOrganizationAttributeChange(organization!,"STOPerYear",parseFloat(event.target.value.replace("$","").replace(/,/g,"")),(entry) =>{
														AC.submitUndoStackEntry(entry);
													})
												}}></input>
										):(
											<>{prettyNum(STOPerYear)}</>
										)}
									</div>
									<div className='col'>
										<div className="label">PTO/STO<br/>Renewal Day</div>
										{canWrite ?(
											<DatePicker
												className="date"
												value={PTOSTORenewal?PTOSTORenewal+"/"+(new Date().getFullYear()):""}
												dateFormat={"MM/dd"}
												onChange={(date) => {
													if(date){
														let dateString = shortDate(date);
														// strip off slash year
														dateString = dateString.substring(0,dateString.length-5);
														setPTOSTORenewal(dateString);
														saveOrganizationAttributeChange(organization!,"PTOSTORenewal",dateString,(entry) =>{
															AC.submitUndoStackEntry(entry);
														})
													}else{
														setPTOSTORenewal("");
														saveOrganizationAttributeChange(organization!,"PTOSTORenewal","",(entry) =>{
															AC.submitUndoStackEntry(entry);
														})
													}
												}}>
											</DatePicker>
										):(
											<>{PTOSTORenewal}</>
										)}
									</div>
									<div className='col'>
										<div className="label">PTO<br/>Expiration Day</div>
										{canWrite ?(
											<DatePicker
												className="date"
												value={PTOExpiration?PTOExpiration+"/"+(new Date().getFullYear()):""}
												dateFormat={"MM/dd"}
												onChange={(date) => {
													if(date){
														let dateString = shortDate(date);
														// strip off slash year
														dateString = dateString.substring(0,dateString.length-5);
														setPTOExpiration(dateString);
														saveOrganizationAttributeChange(organization!,"PTOExpiration",dateString,(entry) =>{
															AC.submitUndoStackEntry(entry);
														})
														setPTOExpiration(dateString);
													}else{
														setPTOExpiration("");
														saveOrganizationAttributeChange(organization!,"PTOExpiration","",(entry) =>{
															AC.submitUndoStackEntry(entry);
														})
													}
												}}></DatePicker>
										):(
											<>{PTOExpiration}</>
										)}
									</div>
									<div className='col'>
										<div className="label">STO<br/>Expiration Day</div>
										{canWrite ?(
										<DatePicker
											className="date"
											value={STOExpiration?STOExpiration+"/"+(new Date().getFullYear()):""}
											dateFormat={"MM/dd"}
											onChange={(date) => {
												if(date){
													let dateString = shortDate(date);
													// strip off slash year
													dateString = dateString.substring(0,dateString.length-5);
													setSTOExpiration(dateString);
													saveOrganizationAttributeChange(organization!,"STOExpiration",dateString,(entry) =>{
														AC.submitUndoStackEntry(entry);
													});
												}else{
													setSTOExpiration("");
													saveOrganizationAttributeChange(organization!,"STOExpiration","",(entry) =>{
														AC.submitUndoStackEntry(entry);
													})
												}
											}}></DatePicker>
										):(
											<>{STOExpiration}</>
										)}
									</div>
								</>
							)}
						</div>


						<div className="row">
							<div className="col">
								<div className="label">Organization Description / Goals</div>
								{canWrite ?(
									<textarea className={`organizationDescription`} 
										defaultValue={organizationDescription}
										onBlur={(event) =>{
											saveOrganizationAttributeChange(organization!,"organizationDescription",event.target.value,(entry) =>{
												AC.submitUndoStackEntry(entry);
											})
										}}></textarea>
								):(
									<>{organizationDescription}</>
								)}
							</div>
						</div>

						<h1>Organization Administrators</h1>
							<OrganizationAdministratorsList
							 isReadOnly = {!((permissionsWrite.includes("Organization") && person && organization.organizationAdministratorPersonIDs.includes(person.id)) || organization.organizationAdministratorPersonIDs.length==0)}
							></OrganizationAdministratorsList>

						<h1>Departments</h1>
						{permissionsWrite.includes("Organization") && (
							<div className="buttonAdd" onClick={() =>{
								setShowingDepartmentPopup(true);
							}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Department</div>
						)}
						<OrganizationScreenDepartmentsTable></OrganizationScreenDepartmentsTable>

						<h1>Roles</h1>
						{permissionsWrite.includes("Organization") && (
							<div className="buttonAdd" onClick={() =>{
								setShowingRolePopup(true);
							}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Role</div>
						)}
						<OrganizationScreenRolesTable></OrganizationScreenRolesTable>


						<h1>Project Types</h1>
						{permissionsWrite.includes("Organization") && (
							<div className="buttonAdd" onClick={() =>{
								organization?.projectTypeList.push("");
								saveOrganizationAttributeChange(organization!,"projectTypeList",organization?.projectTypeList,(entry) =>{
									AC.submitUndoStackEntry(entry);
								});
							}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Option</div>
						)}
						<OrganizationScreenProjectTypesTable></OrganizationScreenProjectTypesTable>


						<h1>Project Status Options</h1>
						{permissionsWrite.includes("Organization") && (
							<div className="buttonAdd" onClick={() =>{
								organization?.projectStatusList.push("");
								saveOrganizationAttributeChange(organization!,"projectStatusList",organization?.projectStatusList,(entry) =>{
									AC.submitUndoStackEntry(entry);
								});
							}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Option</div>
						)}
						<OrganizationScreenProjectStatusOptionsTable></OrganizationScreenProjectStatusOptionsTable>

						<h1>Organization Holidays ({(new Date()).getFullYear()})</h1>
						{permissionsWrite.includes("Organization") && (
							<div className="buttonAdd" onClick={() =>{
								let newHolidaysByYear = {...organization?.holidaysByYear};
								if(newHolidaysByYear[(new Date()).getFullYear()] == null){
									newHolidaysByYear[(new Date()).getFullYear()] = [];
								}
								newHolidaysByYear[(new Date()).getFullYear()].push({
									date:shortDate(new Date()),
									description:""
								});
								saveOrganizationAttributeChange(organization!,"holidaysByYear",newHolidaysByYear,(entry) =>{
									AC.submitUndoStackEntry(entry);
								});
							}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Holiday</div>
						)}
						<OrganizationYearHolidaysTable></OrganizationYearHolidaysTable>

						<h1>List of Fixed Cost Options</h1>
						{permissionsWrite.includes("Organization") && (
							<div className="buttonAdd" onClick={() =>{
								organization?.fixedCostItemsList.push("");
								saveOrganizationAttributeChange(organization!,"fixedCostItemsList",organization?.fixedCostItemsList,(entry) =>{
									AC.submitUndoStackEntry(entry);
								});
							}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Option</div>
						)}
						<OrganizationScreenFixedCostItemsOptionsTable></OrganizationScreenFixedCostItemsOptionsTable>

						{permissionsWrite.includes("Organization") && (
							<>
								<h1>System Backups</h1>
								<OrganizationBackups></OrganizationBackups>
							</>
						)}
						{/* 
						<h1>Clients</h1>
						<div className="buttonAdd" onClick={() =>{
							setShowingClientPopup(true);
						}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Client</div>
						<OrganizationScreenClientsTable></OrganizationScreenClientsTable>
						 */}
					</>	
				)}

	    </div>


			{showingRolePopup && (
				<PopupAddRole 
					allowMultiple={true}
					onCancel={() =>{
						setShowingRolePopup(false);			
					}} onSave={(person) =>{
						setShowingRolePopup(false);	
					}}></PopupAddRole>
			)}
			{showingDepartmentPopup && (
				<PopupAddDepartment 
					allowMultiple={true}
					onCancel={() =>{
						setShowingDepartmentPopup(false);			
					}} onSave={(department) =>{
						setShowingDepartmentPopup(false);	
					}}></PopupAddDepartment>
			)}

			{showingClientPopup && (
				<PopupAddClient 
					allowMultiple={true}
					onCancel={() =>{
						setShowingClientPopup(false);			
					}} onSave={(client) =>{
						setShowingClientPopup(false);	
						if(selectedProject != null){
							selectedProject.clientID = client.id;
							saveProjectAttributeChange(selectedProject, organization,clients, "clientID", client.id,(entry:UndoStackEntry) =>{
								AC.submitUndoStackEntry(entry);
							})
						}
					}}></PopupAddClient>
			)}

			{showingProjectPopup && (
				<PopupAddProject 
					project={selectedProject??undefined}
					onCancel={() =>{
						setSelectedProject(null);
						setShowingProjectPopup(false);			
					}} onSave={(project) =>{
						setSelectedProject(null);
						setShowingProjectPopup(false);			
					}}></PopupAddProject>
			)}

		</>
  )
}