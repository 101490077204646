

import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where } from "firebase/firestore";
import "./DownloadableTable.css"
import { useSelector } from "react-redux";
import { Organization } from "../../FirebaseModel/Organization";
import { createRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { stringToBase64 } from "../../Utils/StringToBase64";
import jsPDF from "jspdf";
import { Tooltip } from "@mui/material";
import * as XLSX from 'xlsx';

interface DownloadableTableProps {
	className?: string,
	fileName?: string,
	children: React.ReactNode;
}

export default function DownloadableTable(props: DownloadableTableProps) {

	const tableRef = createRef<HTMLTableElement>();
	const organization = useSelector((state: { organization: Organization }) => state.organization)


	const downloadTableAsCSV = () => {

		tableRef.current?.querySelectorAll(".hide-from-report").forEach((element) => {
			element.classList.add("hidden");
		})

		let csvContent = "";

		let table: HTMLTableElement = tableRef.current!;
		let rows = table.rows;

		for (let i = 0; i < rows.length; i++) {
			let cells = rows[i].cells;
			let row: string[] = [];
			for (let j = 0; j < cells.length; j++) {
				//if cell contains an input use the value instead of the innerText
				if (cells[j].querySelector("input:not(.hide-from-report)")) {
					// if the input is a checkbox
					if (cells[j].querySelector("input")!.type == "checkbox") {
						if (cells[j].querySelector("input")!.checked) {
							row.push("true");
						} else {
							row.push("false");
						}
					} else {
						//if it contains a comma, wrap it in quotes
						if (cells[j].querySelector("input")!.value.indexOf(",") > -1) {
							row.push(`"${cells[j].querySelector("input")!.value}"`);
						} else {
							row.push(cells[j].querySelector("input")!.value);
						}
					}
				} else if (cells[j].querySelector("select:not(.hide-from-report)")) {
					let select = cells[j].querySelector("select")!
					//if it contains a comma, wrap it in quotes
					if (select.options[select.selectedIndex].text.indexOf(",") > -1) {
						row.push(`"${select.options[select.selectedIndex].text}"`);
					} else {
						row.push(select.options[select.selectedIndex].text);
					}
				} else if (cells[j].querySelector("textarea:not(.hide-from-report)")) {
					//if it contains a comma, wrap it in quotes
					if (cells[j].querySelector("textarea")!.value.indexOf(",") > -1) {
						row.push(`"${cells[j].querySelector("textarea")!.value}"`);
					} else {
						row.push(cells[j].querySelector("textarea")!.value);
					}
				} else {

					if (cells[j].innerText.indexOf(",") > -1) {
						row.push(`"${cells[j].innerText}"`);
					} else {
						row.push(cells[j].innerText);
					}
				}
				if(cells[j].colSpan > 1){
					for(let jj = 0; jj < cells[j].colSpan - 1; jj++){
						row.push("");
					}
				}
			}
			csvContent += row.join(",") + "\n";
		}

		// convert content to base64 string and download as data uri
		let base64 = stringToBase64(csvContent);

		let dataUri = "data:text/csv;base64," + base64;
		let link = document.createElement("a");
		link.setAttribute("href", dataUri);
		link.setAttribute("download", (props.fileName ?? "table") + ".csv");
		document.body.appendChild(link); // Required for FF

		link.click(); // This will download the data file named "my_data.csv".

		document.body.removeChild(link);

		tableRef.current?.querySelectorAll(".hide-from-report").forEach((element) => {
			element.classList.remove("hidden");
		})
	}


	const downloadTableAsPDF = () => {

		// Get the table element
		let table = tableRef.current!;

		// Initialize jsPDF
		let doc = new jsPDF("l");


		let scale = 0.15;
		let html = '<div style="transform: scale(' + scale + ');">' + table.outerHTML + '</div>';

		doc.html(html, {
			callback: function (doc) {
				// Save the PDF
				doc.save((props.fileName ?? "table") + ".pdf");
			}
		});
	}
	
		
	const downloadTableAsExcel = () => {

		let table = tableRef.current!;

		let tds = table.querySelectorAll('td, th');

		tds.forEach(td => {

		  // Check for form inputs
		  if(td.querySelector('input, select, textarea')){

		    // Get value from input and set as text content
		    td.textContent = (td.querySelector('input, select, textarea')! as any).value;

		  }

		});

	
		// Initialize XLSX
		let workbook = XLSX.utils.book_new();
	
		// Convert table to worksheet
		let worksheet = XLSX.utils.table_to_sheet(table);
	
		// Add worksheet to workbook
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
		
		// Export workbook as xlsx file
		XLSX.writeFile(workbook, (props.fileName ?? "table") + ".xlsx");

	}



	return (
		<table ref={tableRef} className={`DownloadableTable ${props.className ?? ""}`}>
			{props.children}
			<Tooltip title="Download as CSV">
				<div className="buttonDownload" onClick={() => {
					downloadTableAsCSV();
				}}><FontAwesomeIcon icon={faDownload} /></div>
			</Tooltip>
		</table>
	)
}