
import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./ProjectInvoiceTableRow.css"
import { useRef } from "react";
import { useSelector } from "react-redux";
import { Role } from "../../../FirebaseModel/Role";
import { ProjectInvoice, ProjectTask } from "../../../FirebaseModel/Project";
import { forceNumeric, forcePercentage, prettyCurrency, prettyNum, shortDate } from "../../../Utils/formatting";
import PersonDropdown from "../../PersonDropdown/PersonDropdown";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { savePersonAttributeChange } from "../../../Utils/SavePersonFunctions";
interface ProjectInvoiceTableRowProps{
	projectInvoice:ProjectInvoice;
	isReadOnly?:boolean,
	onDeleteInvoice:(invoiceID:string) => void
	onUpdateInvoice:(invoiceID:string, invoiceName:string, invoiceAmount:number, invoiceDate:string, invoiceDueDate:string) => void

}

export default function ProjectInvoiceTableRow(props:ProjectInvoiceTableRowProps){
  const roles = useSelector((state:{roles:Role[]}) => state.roles)

	const [invoiceNumber, setInvoiceNumber] = useState<string>(props.projectInvoice.invoiceNumber);
	const [invoiceAmount, setInvoiceAmount] = useState<number>(props.projectInvoice.amount);
	const [invoiceDate, setInvoiceDate] = useState<string>(props.projectInvoice.dateInvoiced);
	const [invoicePaid, setInvoicePaid] = useState<string>(props.projectInvoice.datePaid);
	
  return (
    <tr className={`ProjectInvoiceTableRow`}>
			{props.isReadOnly ? (
				<>
					<td>{props.projectInvoice.invoiceNumber}</td>
					<td>{props.projectInvoice.dateInvoiced}</td>
					<td>{prettyCurrency(props.projectInvoice.amount)}</td>
					<td>{props.projectInvoice.datePaid}</td>
				</>	
			):(
				<>
					<td>
						<input type='text' 
							className="invoiceNumber" 
							defaultValue={invoiceNumber} 
							onBlur={(event) =>{
								setInvoiceNumber(event.target.value);
								props.onUpdateInvoice(props.projectInvoice.id, event.target.value, invoiceAmount, invoiceDate, invoicePaid);
							}}></input>
					</td>
					<td>
						<DatePicker
							selected={invoiceDate==""?null:new Date(invoiceDate)}
							onChange={(date:Date) =>{
								let newInvoiceDate = "";
								if(date){
									newInvoiceDate = (shortDate(date));
								}
								setInvoiceDate(newInvoiceDate);
								props.onUpdateInvoice(props.projectInvoice.id, invoiceNumber, invoiceAmount, newInvoiceDate, invoicePaid);
							}}
							></DatePicker>
					</td>
					<td>
						<input type='text'
							className="invoiceAmount"
							defaultValue={prettyCurrency(invoiceAmount)}
							onFocus={(event) =>{
								event.target.setSelectionRange(0, event.target.value.length);
							}}
							onKeyPress={(event) =>{
								forceNumeric(event);
							}}
							onBlur={(event) =>{
								let newInvoiceAmount = parseFloat(event.target.value.replace(/[^0-9.]/g, ''));
								event.target.value = prettyCurrency(newInvoiceAmount);
								setInvoiceAmount(newInvoiceAmount);
								props.onUpdateInvoice(props.projectInvoice.id, invoiceNumber, newInvoiceAmount, invoiceDate, invoicePaid);
							}}></input>
					</td>
					<td>
						<DatePicker
							selected={invoicePaid==""?null:new Date(invoicePaid)}
							onChange={(date:Date) =>{
								let newInvoicePaid = "";
								if(date){
									newInvoicePaid = (shortDate(date));
								}
								setInvoicePaid(newInvoicePaid);
								props.onUpdateInvoice(props.projectInvoice.id, invoiceNumber, invoiceAmount, invoiceDate, newInvoicePaid);
							}}
							></DatePicker>
					</td>
					<div className="buttonDelete"
						onClick={(event)=>{
							if(window.confirm("Are you sure you want to delete this invoice?")){
								props.onDeleteInvoice(props.projectInvoice.id);
							}
						}}>
							<FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
					</div>
				</>
			)}

		</tr>
  )
}