


import React, { useEffect, useState } from "react"
import "./PersonActiveTasksTable.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import DraggableTableBody from "../../DraggableTableBody/DraggableTableBody";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { saveOrganizationAttributeChange } from "../../../Utils/SaveOrganizationFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { faBars, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { prettyNum, shortDate } from "../../../Utils/formatting";
import { Person } from "../../../FirebaseModel/Person";
import { Project, ProjectTask } from "../../../FirebaseModel/Project";
import { Client } from "../../../FirebaseModel/Client";

interface PersonActiveTasksTableProps{
	person:Person;
	onEditProject:(project:Project) => void;
}

export default function PersonActiveTasksTable(props:PersonActiveTasksTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)
  const permissions = useSelector((state:{permissions:string[]}) => state.permissions)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles},dispatch);

	
	
	const [personTasks, setPersonTasks] = useState<{project:Project, role:Role, task:ProjectTask}[]>([]);

	useEffect(() =>{
		let personTasks:{project:Project, role:Role, task:ProjectTask}[] = [];
		let completedStatuses = organization.projectStatusCategories["completed"]
		projects.forEach(project =>{
			if(completedStatuses.indexOf(project.status) == -1){
				project.projectRoleTasks.forEach(projectRole =>{
					let role = roles.find(role => role.id == projectRole.roleID)!;
					projectRole.tasks.forEach(task =>{
						if(task.assignedPersonIDs.indexOf(props.person.id) != -1){
							personTasks.push({project,role, task});
						}
					});
				});
			}
		});
		setPersonTasks(personTasks);
	},[projects, props.person.id]);
	
	
  return (
    <table 
			className={`PersonActiveTasksTable`}>
			<thead>
				<tr>
					<th>Client</th>
					<th>Project</th>
					<th>Role</th>
					<th>Task</th>
					<th>Planned Hours</th>
					<th>Estimated Percent Complete</th>
					<th>Due Date</th>
				</tr>
			</thead>
			<tbody>
				{personTasks.map(projectRoleTask =>{
					let project = projectRoleTask.project;
					let role = projectRoleTask.role;
					let client = clients.find(client => client.id == project.clientID);
					return (
						<tr key={projectRoleTask.task.id}>
							<td>{client?client.name:""}</td>
							<td className={permissions.includes("Projects")?"buttonProject":""}
								onClick={permissions.includes("Projects")?() =>{
									props.onEditProject(project);
								}:()=>{}}>{project.projectName}</td>
							<td>{role.abbreviation}</td>
							<td>{projectRoleTask.task.name}</td>
							<td>{prettyNum(projectRoleTask.task.plannedHours)}</td>
							<td>{Math.round(projectRoleTask.task.percentComplete *100)}%</td>
							<td>{projectRoleTask.task.dueDate}</td>
						</tr>
					)
				})}
			</tbody>
    </table>
  )
}