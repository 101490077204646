
import React, { useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ProjectProfitabilityTableHeader.css"
import { useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role, rolesExistAM, rolesExistPM } from "../../../FirebaseModel/Role";
import { padDecimal, prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowDown19, faArrowUp, faArrowUp19, faChevronDown, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

export enum ProjectProfitabilityTableHeaderType{
	type1,
	type2,
	grandTotal
}

interface ProjectProfitabilityTableHeaderProps{
	type:ProjectProfitabilityTableHeaderType

	sfClosedWon?:number;
	profit?:number;
	profitMarginPercent?:number;
	markupPercent?:number;

	onSortAscending?:(ascending:boolean)=>void;
	
}

export default function ProjectProfitabilityTableHeader(props:ProjectProfitabilityTableHeaderProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)

	const [sortYearAscending, setSortYearAscending] = useState<boolean>(true);
	const [sortMonthAscending, setSortMonthAscending] = useState<boolean>(true);


  return (
		<>
		{props.type==ProjectProfitabilityTableHeaderType.type1 && (
			<tr className={`ProjectProfitabilityTableHeader type1`}>
				<th>Client Name</th>
				<th>Project #</th>
				<th>Project Name</th>
				<th>SF Closed Won</th>
				<th>Date Completed</th>
				{rolesExistPM(roles) && <th>Project Manager</th>}
				<th>Profit $</th>
				<th>Profit Margin %</th>
				<th>Markup %</th>
			</tr>
		)}

		{props.type==ProjectProfitabilityTableHeaderType.type2 && (
			<tr className={`ProjectProfitabilityTableHeader type2`}>
				<th>Client</th>
				<th>Project #</th>
				<th>Project Name</th>
				<th>SF Closed Won</th><th className={`${sortMonthAscending?"ascending":"descending"}`}
					onClick={() =>{
						let newSortMonthAscending = !sortMonthAscending;
						if(props.onSortAscending){
							props.onSortAscending(newSortMonthAscending);
						}
						setSortMonthAscending(newSortMonthAscending);
					}}>Date <FontAwesomeIcon icon={sortMonthAscending?faArrowUp:faArrowDown}></FontAwesomeIcon></th>
				{rolesExistPM(roles) && <th>PM</th>}
				<th>Profit $</th>
				<th>Profit Margin %</th>
				<th>Markup %</th>
			</tr>
		)}

		{props.type==ProjectProfitabilityTableHeaderType.grandTotal && (
			<tr className={`ProjectProfitabilityTableHeader grandTotal`}>
				<th></th>
				<th></th>
				<th></th>
				<th>{padDecimal(prettyCurrency(props.sfClosedWon!),2)}</th>
				<th></th>
				<th></th>
				<th>{padDecimal(prettyCurrency(props.profit!),2)}</th>
				<th>{Math.round(props.profitMarginPercent!*100)}%</th>
				<th>{Math.round(props.markupPercent!*100)}%</th>
			</tr>
		)}
		</>
  )
}