

import React, { useEffect, useState } from "react"
import "./ProductionSchedulePersonProjectRoleRow.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Client } from "../../../FirebaseModel/Client";
import { ClientContact } from "../../../FirebaseModel/ClientContact";
import { Organization, holiday } from "../../../FirebaseModel/Organization";
import { Department } from "../../../FirebaseModel/Department";
import { Person } from "../../../FirebaseModel/Person";
import { Project, ProjectTask, ProjectTaskScheduleBlock, findProjectTasksFromDueDate, findScheduleBlockForDate } from "../../../FirebaseModel/Project";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { faChevronDown, faChevronRight, faCircle, faClipboard, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Role } from "../../../FirebaseModel/Role";
import { Tooltip } from "@mui/material";
import ProductionScheduleTaskScheduleBlockDay from "../ProductionScheduleTaskScheduleBlockDay/ProductionScheduleTaskScheduleBlockDay";
import ProductionScheduleTaskDueDate from "../ProductionScheduleTaskDueDate/ProductionScheduleTaskDueDate";
import { prettyNum, shortDate } from "../../../Utils/formatting";
interface ProductionSchedulePersonProjectRoleRowProps{
	numMonths:number;
	actual:ProjectRoleActualHours,
	project:Project,
	person:Person,
	collapsed:boolean;
	departmentCollapsed:boolean;
	onAddScheduleBlock:(personID:string, roleID:string, projectID:string, startDate?:string) => void;
  onEditTaskScheduleBlock:(projectTaskScheduleBlock:ProjectTaskScheduleBlock) => void;
	onEditProject(project:Project):void;
}


export default function ProductionSchedulePersonProjectRoleRow(props:ProductionSchedulePersonProjectRoleRowProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
  const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
  const clientContacts = useSelector((state:{clientContacts:ClientContact[]}) => state.clientContacts)
  const departments = useSelector((state:{departments:Department[]}) => state.departments)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const permissions = useSelector((state:{permissions:string[]}) => state.permissions)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles},dispatch);

	const [months, setMonths] = useState<Date[]>([]);
	const [days, setDays] = useState<Date[]>([]);

	const today = new Date();

	useEffect(() =>{
		let months:Date[] = [];
		let date = new Date();
		for(let i = 0; i < props.numMonths; i++){
			months.push(new Date(date.getFullYear(), date.getMonth() + i, 1));
		}
		setMonths(months);

		// add every day from months into the days array
		let days:Date[] = [];
		months.forEach(month =>{
			let date = new Date(month);
			while(date.getMonth() === month.getMonth()){
				days.push(new Date(date));
				date.setDate(date.getDate() + 1);
			}
		})
		setDays(days);
	},[props.numMonths])

	const [plannedHours, setPlannedHours] = useState<number>(0);
	const [actualHours, setActualHours] = useState<number>(0);
	const [estimatedPercentComplete, setEstimatedPercentComplete] = useState<number>(0);


	useEffect(() => {
		let filteredTasks = props.project.projectRoleTasks.find((projectRoleTask) => projectRoleTask.roleID === props.actual.roleID)?.tasks??[];
		
		let plannedHours = 0;
		let completedHours = 0;
		filteredTasks.forEach((task) => {
			plannedHours += task.plannedHours;
			completedHours += task.plannedHours * task.percentComplete;
		})

		let totalActualHours = 0;
		let projectActuals = actuals.filter(actual => actual.projectID == props.project.id && actual.roleID == props.actual.roleID);
		projectActuals.forEach(actual =>{
			totalActualHours += actual.calculated.totalHours;
		})

		setPlannedHours(plannedHours);
		setActualHours(totalActualHours);
		setEstimatedPercentComplete(plannedHours === 0 ? 0 : completedHours / plannedHours);
	}, [props.project.projectRoleTasks, props.actual.roleID, roles, actuals])

  return (
		<>
			<tr className={`ProductionSchedulePersonProjectRoleRow actual  ${(props.departmentCollapsed||props.collapsed)?"collapsed":""}`}>
				<td>
					{permissions.includes("Projects") ? (
						<Tooltip title={"View Project"} placement="bottom">
							<div className='buttonProject'
								onClick={() =>{
									props.onEditProject(props.project);
								}}>
								<span className='indicator'>
									<FontAwesomeIcon icon={faClipboard}></FontAwesomeIcon>
								</span> {props.project.projectName}
							</div>
						</Tooltip>
					):(
						<>
							<span className='indicator'>
								<FontAwesomeIcon icon={faClipboard}></FontAwesomeIcon>
							</span> {props.project.projectName}
						</>
					)}
				</td>
				<td>{roles.find(role => role.id == props.actual.roleID)?.abbreviation}</td>
				<td>{prettyNum(plannedHours)}</td>
				<td>{prettyNum(actualHours)}</td>
				<td>{prettyNum(plannedHours - actualHours)}</td>
				<td>{Math.round(estimatedPercentComplete * 100)}%</td>
				{days.map((day, index) =>{
					// add a header with the single letter day of the week
					let h = holiday(shortDate(day),organization)
					let isToday = day.getDate() == today.getDate() && day.getMonth() == today.getMonth() && day.getFullYear() == today.getFullYear();
					let isWeekend = day.getDay() === 0 || day.getDay() === 6;
					let taskScheduleBlock = findScheduleBlockForDate(props.project, props.person.id, props.actual.roleID, day);
					let project = projects.find(project => project.id == props.project.id)!;
					let task:ProjectTask|undefined = undefined;
					if(project && taskScheduleBlock){
						task = project.projectRoleTasks.find(roleTasks => roleTasks.roleID == taskScheduleBlock?.roleID)!.tasks.find(task => task.id == taskScheduleBlock?.taskID);
					}
					let dueDates = findProjectTasksFromDueDate(props.project, props.actual.roleID, day);
					return <td key={index} className={`day ${isToday?"today":""} ${isWeekend?"weekend":""} ${h?"holiday":""}`}>
						{taskScheduleBlock && (
							<Tooltip title={`${project.projectName} - ${task!.name}`} placement="bottom">
								<div>
									<ProductionScheduleTaskScheduleBlockDay
										taskScheduleBlock={taskScheduleBlock}
										day={day} 
										onEditTaskScheduleBlock={(taskScheduleBlock) =>{
											props.onEditTaskScheduleBlock(taskScheduleBlock);
										} }></ProductionScheduleTaskScheduleBlockDay>
								</div>
						</Tooltip>
						)}
						{dueDates.map((task) =>{
							return <ProductionScheduleTaskDueDate projectTask={task}></ProductionScheduleTaskDueDate>
						})}
						{!taskScheduleBlock && dueDates.length == 0 && (
							<Tooltip title="Schedule Task" placement="bottom" enterDelay={500}>
								<div className='buttonAdd'
									onClick={() =>{
										props.onAddScheduleBlock(props.person.id, props.actual.roleID, props.project.id,shortDate(day));
									}}>
										<FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
									</div>
							</Tooltip>
						)}
					</td>
				})}
			</tr>
		</>
  )
}