import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./OrganizationScreenRolesInput.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { forceNumeric, forcePercentage, prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { saveProjectAttributeChange, saveProjectPlannedHoursChange } from "../../../Utils/SaveProjectFunctions";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
interface OrganizationScreenRolesInputProps{
	className?:string,
	role:Role,
	attributeKey:string,
	forceNumeric?:boolean,
	isCurrency?:boolean,
	isPercentage?:boolean,
	isCheckbox?:boolean,
}

export default function OrganizationScreenRolesInput(props:OrganizationScreenRolesInputProps){

  const organization = useSelector((state:{organization:Organization}) => state.organization)

	const [defaultValue, setDefaultValue] = useState("");
	const [defaultChecked, setDefaultChecked] = useState(false);
	
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	useEffect(() =>{
		if(props.attributeKey){
			if(props.isCheckbox){
				setDefaultChecked((props.role as any)[props.attributeKey])
			}else if(props.isPercentage){
				setDefaultValue(Math.round((props.role as any)[props.attributeKey] as number * 100)+"%")
			}else if(props.isCurrency){
				setDefaultValue(prettyCurrency((props.role as any)[props.attributeKey] as number))
			}else{
				setDefaultValue((props.role as any)[props.attributeKey])
			}
		}
	},[props.role,props.attributeKey])

  return (
		<>
			{props.isCheckbox? (
				
				<input type='checkbox'
					className={`OrganizationScreenRolesInput ${props.className??""}`}
					checked={defaultChecked} 
					onChange={(event) =>{
						saveRoleAttributeChange(props.role,props.attributeKey,event.target.checked,(entry) =>{
							AC.submitUndoStackEntry(entry);
						});
					}}></input>

			): (
				<input 
					className={`OrganizationScreenRolesInput ${props.className??""} ${props.attributeKey??""}`}
					disabled={props.className=="disabled"}
					onBlur={(event) =>{

						if(props.forceNumeric && event.target.value ==""){
							event.target.value = "0";
						}
						if(props.isPercentage){
							event.target.value = Math.round(parseInt(event.target.value))+"%";
						}
						if(props.isCurrency){
							event.target.value = prettyCurrency(event.target.value);
						}
		
						if(props.isPercentage){
							saveRoleAttributeChange(props.role,props.attributeKey,parseFloat(event.target.value.replace("%","").replace(/,/g,""))/100,(entry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}else if(props.forceNumeric){
							saveRoleAttributeChange(props.role,props.attributeKey,parseFloat(event.target.value.replace("$","").replace(/,/g,"")),(entry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}else{
							saveRoleAttributeChange(props.role,props.attributeKey,event.target.value,(entry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}
					}}
					onFocus={(event)=>{
						if(props.forceNumeric){
							let input = event.target as HTMLInputElement
							input.setSelectionRange(0,input.value.length)
						}
					}}
					onKeyPress={(event) =>{
						if(props.isPercentage){
							forcePercentage(event);
						}
						if(props.forceNumeric){
							forceNumeric(event);
						}
					}}
					defaultValue={defaultValue}>
				</input>
			)}
		</>
  )
}