
import { useCSVReader } from 'react-papaparse';
import React, { useEffect, useState } from "react"
import { addDoc, collection, deleteDoc, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./PopupImportClients.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import { prettyNum, forceNumeric, prettyCurrency, forceDecimal, shortDate } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { saveClientAttributeChange } from "../../Utils/SaveClientFunctions";
import { useRef } from 'react';
import { ProjectRoleActualHours } from '../../FirebaseModel/ProjectRoleActualHours';
import { Person } from '../../FirebaseModel/Person';
import { saveProjectAttributeChange } from '../../Utils/SaveProjectFunctions';
import { faFileDownload, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../Redux/ActionCreators';
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from '../../Utils/UndoStackEntry';
import { stringToBase64 } from '../../Utils/StringToBase64';
import { cleanCSVString } from '../../Utils/CleanCSVString';
interface PopupImportClientsProps{
	onDismiss:()=>void;
}

export default function PopupImportClients(props:PopupImportClientsProps){

  const progressRef = useRef<HTMLProgressElement>(null);
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
  const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)

  const { CSVReader } = useCSVReader();

	const [errorMessage, setErrorMessage] = useState<string>("");
	const [allRows, setAllRows] = useState<any[]>([]);

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);


  const downloadTemplateCSV = () =>{
    // generate a csv file with the appropriate headers and download it.

    let csvContent = "data:text/csv;charset=utf-8,";
    
    csvContent += "Client Name,Hourly Rate (blank if using organization rate)\n";

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "client_import_template.csv");
    document.body.appendChild(link); // Required for FF
    

    link.click(); // This will download the data file named "my_data.csv".

    document.body.removeChild(link);
  }

	const downloadCurrentClientsCSV = () =>{
		// generate a csv file with the appropriate headers and download it.

    let csvContent = "";

    csvContent += "Client Name,Hourly Rate (blank if using organization rate)\n";

    clients.forEach(client =>{
      csvContent += `${cleanCSVString(client.name)},${client.useOverrideHourlyRate?client.overrideHourlyRate:""}\n`;
    })

		// convert content to base64 string and download as data uri
		let base64 = stringToBase64(csvContent);

		let dataUri = "data:text/csv;base64,"+base64;
    let link = document.createElement("a");
    link.setAttribute("href", dataUri);
    link.setAttribute("download", "current_clients.csv");
    document.body.appendChild(link); // Required for FF


    link.click(); // This will download the data file named "my_data.csv".

    document.body.removeChild(link);
  }
    

	const validateFormat = (rows:any) => {


		// <li>Client Name</li>
		// <li>Hourly Rate (blank if using organization rate)</li>

		if(rows.length < 2){
			setErrorMessage("File must have at least 2 rows");
			return false;
		}

		let headerRow = rows[0];
		if(headerRow.length != 2){
			setErrorMessage("File must have 2 columns");
			return false;
		}

		if(headerRow[0].toLowerCase() != "client name"){
			setErrorMessage("First column must be 'Client Name'");
			return false;
		}

		if(headerRow[1].toLowerCase() != "hourly rate (blank if using organization rate)"){
			setErrorMessage("Second column must be 'Hourly Rate (blank if using organization rate)'");
			return false;
		}

		return true;
	}


  const processFile = async (rows: any) => {
    setAllRows(rows);
  }
  const startImport = async (rows: any) => {

    AC.submitUndoStackEntry({
      type: UndoStackEntryType.batchStart,
      objectType: UndoStackEntryObjectType.nonApplicable,
      objectID: "",
      objectBeforeState: undefined,
      objectAfterState: undefined,
      description: 'Import Clients'
    })

    let dates:Date[] = [];
    let currentName = "";
    let currentProjectName = "";
    let currentPerson:any|null = null;
    let currentProjectNumber = "";
    let currentTask = "";
    let currentTaskID = "";

    let currentProject:Project|null = null;

    let firstDate:Date|null = null;
    let lastDate:Date|null = null;

    let modifiedProjects:Project[] = [];


    progressRef.current!.max = rows.length;
    progressRef.current!.style.display = "block";

    function startNextRow(i:number){
      setTimeout(() => {
        processNextRow(i);
      }, 100);
    }

    //for(let i = 0; i < rows.length; i++){
    async function processNextRow(i:number){
      if(i >= rows.length){
        console.log("Done processing file");
        progressRef.current!.style.display = "none";
        // setTimeout(() => {
        //   updateProjectsCalculatedActuals(modifiedProjects);
        // }, 100);
        AC.submitUndoStackEntry({
          type: UndoStackEntryType.batchEnd,
          objectType: UndoStackEntryObjectType.nonApplicable,
          objectID: "",
          objectBeforeState: undefined,
          objectAfterState: undefined,
          description: 'Import Clients'
        })
        return;
      }
      let row = rows[i];
      console.log(`Processing row ${i} of ${rows.length}...`);
      startNextRow(i+1);

			let clientName = row[0];
			let hourlyRate = row[1];

			let client = clients.find(client => client.name.toLowerCase() === clientName.toLowerCase());
			if(client == null){

				let docData = {
					organizationID:organization.id,
					name:clientName,
					overrideHourlyRate:(isNaN(hourlyRate)?parseFloat(hourlyRate):0),
					useOverrideHourlyRate:hourlyRate != "",
				}
				let docref = await addDoc(collection(sharedFirestore,"Client"),docData)
				let newClient = {...docData, id: docref.id} as Client;

        AC.submitUndoStackEntry({
          type: UndoStackEntryType.create,
          objectType: UndoStackEntryObjectType.client,
          objectID: newClient.id,
          objectBeforeState: undefined,
          objectAfterState: {...newClient},
          description: 'Create Client'
        })
				clients.push(newClient);
			}

    }

		if(validateFormat(rows)){
    	startNextRow(1);
		}
  }


  return (
    <div className={`popup PopupImportClients`}>
			<h1>Import Clients from CSV</h1>

			{errorMessage!="" && <div className="errorMessage">{errorMessage}</div>}

      <div className='instructions'>
        The CSV file must be formatted with these headers in this order:
				<ul>
					<li>Client Name</li>
					<li>Hourly Rate (blank if using organization rate)</li>
				</ul>
      </div>

      <CSVReader
        onUploadAccepted={async (results: any) => {
          console.log('---------------------------');
          console.log(results);
          console.log('---------------------------');
          processFile(results.data);
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }: any) => (
          <>
            <div className='csvReader'>
              <button type='button' {...getRootProps()} className={"browseFile"}>
                Browse file
              </button>
              <div className={"acceptedFile"}>
                {acceptedFile && acceptedFile.name}
              </div>
              <button {...getRemoveFileProps()} className={"remove"}>
                Remove
              </button>
            </div>
            {/* <ProgressBar  className={"progressBarBackgroundColor"} /> */}
          </>
        )}
      </CSVReader>
      {allRows.length > 0 && (
        <div className='buttonImport'
          onClick={() =>{
            startImport(allRows)
            setAllRows([]);
          }}><FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon> Start Import</div>
      )}
      <progress ref={progressRef} style={{display:'none'}} className={"progressBarBackgroundColor"} />

			<div className='buttonTemplate'
				onClick={downloadTemplateCSV}>
				<FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon> Download Template
				</div>
			<div className='buttonTemplate'
				onClick={downloadCurrentClientsCSV}>
				<FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon> Download Current Team CSV
				</div>

				<div className="floatingButtons">
					<div className="buttonSave"
						onClick={() =>{
							projects.forEach( project =>{
								let actualsForProject = actuals.filter(actual => actual.projectID === project.id);
								let totalHours = actualsForProject.reduce((total, actual) => total + actual.calculated.totalHours, 0);
								if(project.calculated.actualHours != totalHours){
									let newCalcualted = {...project.calculated}
									if(newCalcualted.actualHours != totalHours){
										newCalcualted.actualHours = totalHours;
										saveProjectAttributeChange(project,organization,clients, "calculated", newCalcualted,(entry:UndoStackEntry) =>{
                      AC.submitUndoStackEntry(entry);
                    })
									}
								}
							})
							props.onDismiss();
						}}>Done</div>
					</div>
		</div>
  )
}