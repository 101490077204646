export interface MonthTotal{
	id:string;
	organizationID:string;
	month:number;
	year:number;
	projectOrder:string[];
	calculated:{
		actualHoursByRole:{roleID:string, hours:number}[];
		estimatedHoursByRole:{roleID:string, hours:number}[];
		totalHoursBillable:number;
		totalHoursNonBillable:number;
		totalPTOHours:number;
		totalProductionBudget:number;
		totalSFClosedWon:number;
		totalSTOHours:number;
		bsp:BSPColumn
	},
	bspProjects:BSPProject[]
}

export interface BSPProject {
	projectID:string;
	firstDayPercentComplete:number;
	lastDayPercentComplete:number;
}

export interface BSPColumn {
	projectsCount:number;
	projectsStalled:number;
	projects1Percent:number;
	projects2to9percent:number;
	projects10to100percent:number;
	projectsCompleted:number,
	totalContractDollars:number;
	averageProjectValue:number,
	beginningScope:number;
	endingScope:number;
	resourcesHave:number;
	averageBSPPerResourcePerWeek:number;
	resourcesHaveWeighted: number,
	averageBSPPerResourcePerWeekWeighted: number,
	billableScopeProduced:number;
	productionBudgetPreviousMonth:number;
	bspToSalesRatio:number;
}

export const BlankBSPColumn:BSPColumn = {
	projectsCount: 0,
	projectsStalled: 0,
	projects1Percent: 0,
	projects2to9percent: 0,
	projects10to100percent: 0,
	projectsCompleted: 0,
	totalContractDollars: 0,
	averageProjectValue: 0,
	beginningScope: 0,
	endingScope: 0,
	resourcesHave: 0,
	averageBSPPerResourcePerWeek: 0,
	resourcesHaveWeighted: 0,
	averageBSPPerResourcePerWeekWeighted: 0,
	billableScopeProduced: 0,
	productionBudgetPreviousMonth: 0,
	bspToSalesRatio: 0
}