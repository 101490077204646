
import React, { useEffect, useRef, useState } from "react"
import { addDoc, collection, deleteDoc, doc, updateDoc } from "firebase/firestore";
import "./PopupAddProject.css"
import "../Popups.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Project, ProjectBidWorksheet, ProjectRetrospective, ProjectTask } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role, rolesExistAM, rolesExistPM, rolesExistRSM } from "../../FirebaseModel/Role";
import PopupAddClient from "../PopupAddClient/PopupAddClient";
import { forceDecimal, forceMaxValue, forceNumeric, forcePercentage, padZeros, prettyCurrency, prettyNum, shortDate } from "../../Utils/formatting";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import PopupAddPerson from "../PopupAddPerson/PopupAddPerson";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { Client } from "../../FirebaseModel/Client";
import { ProjectRoleActualHours } from "../../FirebaseModel/ProjectRoleActualHours";
import PopupAddClientContact from "../PopupAddClientContact/PopupAddClientContact";
import ClientContactDropdown from "../../Components/ClientContactDropdown/ClientContactDropdown";
import { deleteProject } from "../../Utils/SaveProjectFunctions";
import ProjectStatusHistoryTable from "../../Components/ProjectStatusHistoryTable/ProjectStatusHistoryTable";
import BiddingWorksheet from "../../Components/BiddingWorksheet/BiddingWorksheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faRobot } from "@fortawesome/free-solid-svg-icons";
import ProjectContactsTable from "../../Components/ProjectContactsTable/ProjectContactsTable";
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from "../../Utils/UndoStackEntry";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { Person } from "../../FirebaseModel/Person";
import ProjectTasksTable from "../../Components/ProjectTasks/ProjectTasksTable/ProjectTasksTable";
import ProjectChangeOrdersTable from "../../Components/ProjectChangeOrders/ProjectChangeOrdersTable/ProjectChangeOrdersTable";
import ProjectInvoiceTable from "../../Components/ProjectInvoices/ProjectInvoiceTable/ProjectInvoiceTable";
import ProjectTypeDropdown from "../../Components/ProjectTypeDropdown/ProjectTypeDropdown";
import ProjectRetrospectiveWorksheet from "../../Components/ProjectRetrospectiveWorksheet/ProjectRetrospectiveWorksheet";
import PopupSendSurvey from "../PopupSendSurvey/PopupSendSurvey";
import ProjectMessageBoardWorksheet from "../../Components/ProjectMessageBoardWorksheet/ProjectMessageBoardWorksheet";
import ChangeOrderHoursBreakdownByRole from "../../Components/ChangeOrderHoursBreakdownByRole/ChangeOrderHoursBreakdownByRole";
interface PopupAddProjectProps {
	project?: Project;
	defaultProjectNumber?: string;
	disableMultiple?: boolean;
	isProjectChangeOrder?: boolean;
	changeOrderParentProjectNumber?: string;
	onCancel: () => void;
	onSave: (project: Project) => void;
}

export default function PopupAddProject(props: PopupAddProjectProps) {

	const organizationID = useSelector((state: { organizationID: string }) => state.organizationID)
	const organization = useSelector((state: { organization: Organization }) => state.organization)
	const allActuals = useSelector((state: { actuals: ProjectRoleActualHours[] }) => state.actuals)
	const roles = useSelector((state: { roles: Role[] }) => state.roles)
	const clients = useSelector((state: { clients: Client[] }) => state.clients)
	const projects = useSelector((state: { projects: Project[] }) => state.projects)
	const persons = useSelector((state: { persons: Person[] }) => state.persons)
	const permissionsWrite = useSelector((state: { permissionsWrite: string[] }) => state.permissionsWrite)


	const [actuals, setActuals] = useState<ProjectRoleActualHours[]>([]);

	const [showingClientPopup, setShowingClientPopup] = useState(false);
	const [showingPersonPopup, setShowingPersonPopup] = useState(false);
	const [showingSurveyPopup, setShowingSurveyPopup] = useState(false);

	const [selectedProject, setSelectedProject] = useState<Project | undefined>(undefined);
	const [showingProjectPopup, setShowingProjectPopup] = useState(false);

	const [showingClientContactPopup, setShowingClientContactPopup] = useState(false);
	const [addingPrimaryContact, setAddingPrimaryContact] = useState(false);
	const [personIsAM, setPersonIsAM] = useState(false);
	const [personIsRSM, setPersonIsRSM] = useState(false);

	const inputSFClosdWon = useRef<HTMLInputElement>(null);

	const [month, setMonth] = useState(props.project ? props.project!.monthClosedWon : ((new Date()).getMonth() + 1));
	const [year, setYear] = useState(props.project ? props.project!.yearClosedWon : ((new Date()).getFullYear()));
	const [projectNum, setProjectNum] = useState(props.project ? props.project!.projectNumber : (props.defaultProjectNumber ?? ""));
	const [clientID, setClientID] = useState(props.project ? props.project!.clientID : "");
	const [projectName, setProjectName] = useState(props.project ? props.project!.projectName : "");
	const [sfClosedWon, setSFClosedWon] = useState(props.project ? props.project!.sfClosedWon : 0);
	const [projectType, setProjectType] = useState(props.project ? props.project!.projectType : "");
	const [accountManagerID, setAccountManagerID] = useState(props.project ? props.project!.accountManagerID : "");
	const [projectManagerID, setProjectManagerID] = useState(props.project ? props.project!.projectManagerID : "");
	const [regionalSalesManagerID, setRegionalSalesManagerID] = useState(props.project ? props.project!.regionalSalesManagerID : "");
	const [useOverrideHourlyRate, setUseOverrideHourlyRate] = useState(props.project ? props.project!.useOverrideHourlyRate : false);
	const [overrideHourlyRate, setOverrideHourlyRate] = useState(props.project ? props.project!.overrideHourlyRate : organization.hourlyRate);
	const [fixedCosts, setFixedCosts] = useState(props.project ? (props.project!.fixedCosts ?? 0) : 0);
	const [extraMargin, setExtraMargin] = useState(props.project ? (props.project!.extraMargin ?? 0) : 0);
	const [comments, setComments] = useState(props.project ? (props.project!.comments ?? "") : "");
	const [isContractSigned, setIsContractSigned] = useState(props.project ? (props.project!.isContractSigned ?? false) : false);
	const [status, setStatus] = useState(props.project ? (props.project!.status ?? organization.projectStatusCategories["active"][0]) : organization.projectStatusCategories["bidding"][0]);

	let emptyStatusHistory: { date: string, status: string }[] = [];
	const [statusHistory, setStatusHistory] = useState(props.project ? (props.project!.statusHistory ?? [...emptyStatusHistory]) : [...emptyStatusHistory]);
	let emptyProjectContacts: { clientContactID: string, primaryContact: boolean }[] = [];
	const [projectContacts, setProjectContacts] = useState(props.project ? (props.project!.projectClientContacts ?? [...emptyProjectContacts]) : [...emptyProjectContacts]);
	let emptyProjectRoleTasks = [] as { roleID: string, tasks: ProjectTask[] }[];
	const [projectRoleTasks, setProjectRoleTasks] = useState(props.project ? (props.project!.projectRoleTasks ?? [...emptyProjectRoleTasks]) : [...emptyProjectRoleTasks]);

	const [pmEstimatedPercentComplete, setPMEstimatedPercentComplete] = useState(props.project ? (props.project!.pmEstimatedPercentComplete ?? 0) : 0);
	const [amPercentToInvoice, setAMPercentToInvoice] = useState(props.project ? (props.project!.amPercentToInvoice ?? 0) : 0);
	const [pmNotes, setPMNotes] = useState(props.project ? (props.project!.pmNotes ?? "") : "");
	const [amNotes, setAMNotes] = useState(props.project ? (props.project!.amNotes ?? "") : "");
	const [adminNotes, setAdminNotes] = useState(props.project ? (props.project!.adminNotes ?? "") : "");
	const [invoices, setInvoices] = useState(props.project ? (props.project!.invoices ?? []) : []);

	const [isChangeOrder, setIsChangeOrder] = useState(props.project ? (props.project!.isChangeOrder ?? false) : (props.isProjectChangeOrder ?? false));
	const [changeOrderParentProjectNumber, setChangeOrderParentProjectNumber] = useState(isChangeOrder ? (props.project ? props.project!.changeOrderParentProjectNumber : (props.changeOrderParentProjectNumber ?? false)) : "");

	const [selectedClient, setSelectedClient] = useState<Client | undefined>(undefined);

	const [totalProjectPrice, setTotalProjectPrice] = useState(0);
	const [parentProject, setParentProject] = useState<Project | undefined>(undefined);
	useEffect(() => {
		let tempProject = props.project;
		if (props.isProjectChangeOrder && props.changeOrderParentProjectNumber) {
			const parentProject = projects.find(p => p.projectNumber === props.changeOrderParentProjectNumber);
			if (parentProject) {
				setParentProject(parentProject);
			} else {
				setParentProject(undefined);
			}
			tempProject = parentProject;
		}
		if (tempProject) {
			//calculate the total project price from sfClosedWon fields on the parent and all change order projects;
			let total = tempProject.sfClosedWon;
			projects.forEach(p => {
				if (p.isChangeOrder && p.changeOrderParentProjectNumber === tempProject!.projectNumber) {
					total += p.sfClosedWon;
				}
			});
			setTotalProjectPrice(total);
		} else {
			setTotalProjectPrice(sfClosedWon);
		}
	}, [props.isProjectChangeOrder, props.changeOrderParentProjectNumber, projects, props.project, sfClosedWon]);

	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry: ActionCreators.submitUndoStackEntry
	}, dispatch);

	let tempTotalHours = 0;
	let tempTotalBudget = 0;
	let tempTotalProductionBudget = 0;

	if (props.project) {

		let totalPlannedHours = 0;
		for (var key in props.project!.plannedHours) {
			if (organization.roleIDOrder.indexOf(key) != -1) {
				let role = roles.find(r => r.id == key);
				if (role && role.isBillable) {
					totalPlannedHours += props.project!.plannedHours[key].hours
				}
			}
		}
		tempTotalHours = (totalPlannedHours);
		let hourlyRate = organization.hourlyRate;
		let client = clients.find(c => c.id == clientID);
		if (client && client.useOverrideHourlyRate) {
			hourlyRate = client.overrideHourlyRate;
		}
		if (props.project.useOverrideHourlyRate) {
			hourlyRate = props.project.overrideHourlyRate;
		}
		let newTotalBudget = totalPlannedHours * hourlyRate;
		tempTotalBudget = (newTotalBudget)
		tempTotalProductionBudget = (newTotalBudget + fixedCosts + extraMargin)
	}

	let blankBidWorksheet: ProjectBidWorksheet = {
		roles: [],
		fixedCosts: [],
		discount: 0,
		bidNotes: "",
		calculated: {
			totalHours: 0,
			totalSOWPrice: 0,
			totalFixedCosts: 0,
			totalProductionBudget: 0,
			totalExtraMargin: 0,
		}
	}

	let blankRetrospetive: ProjectRetrospective = {
		date: "",
		attendingClientContactIDs: [],
		whatWentWell: "",
		whatCouldHaveGoneBetter: "",
		whatWillMakeTheNextProjectBetter: "",
		clientWhatChallengesAreYouLookingToSolve: "",
		clientWhatUpcomingEventsAreYouPreparingFor: "",
		clientWhoElseInYourOrganizationCouldWeHelp: "",
		notes: "",
	}

	roles.forEach(role => { blankBidWorksheet.roles.push({ roleID: role.id, hours: 0, sowPrice: 0 }) });
	organization!.fixedCostItemsList.forEach(fixedCost => { blankBidWorksheet.fixedCosts.push({ fixedCostItem: fixedCost, cost: 0, sowPrice: 0 }) })

	const [totalHours, setTotalHours] = useState(tempTotalHours);
	const [totalBudget, setTotalBudget] = useState(tempTotalBudget);
	const [totalProductionBudget, setTotalProductionBudget] = useState(tempTotalProductionBudget);
	const [bidWorksheet, setBidWorksheet] = useState(props.project ? (props.project!.bidWorksheet ?? blankBidWorksheet) : blankBidWorksheet);
	const [retrospective, setRetrospective] = useState(props.project ? (props.project!.retrospective ?? blankRetrospetive) : blankRetrospetive);
	const [clearingForm, setClearingForm] = useState(false);
	const [changeOrderProjectNumber, setChangeOrderProjectNumber] = useState<string | undefined>(undefined);

	const buildTotals = () => {

		let totalPlannedHours = 0;
		for (var key in projectHours) {
			if (organization.roleIDOrder.indexOf(key) != -1) {
				let role = roles.find(r => r.id == key);
				if (role && role.isBillable) {
					totalPlannedHours += projectHours[key]
				}
			}
		}
		setTotalHours(totalPlannedHours);
		let hourlyRate = organization.hourlyRate;
		let client = clients.find((client: Client) => client.id == clientID);
		if (client && client.useOverrideHourlyRate) {
			hourlyRate = client.overrideHourlyRate;
		}
		if (useOverrideHourlyRate) {
			hourlyRate = overrideHourlyRate;
		}
		let newTotalBudget = totalPlannedHours * hourlyRate;
		setTotalBudget(newTotalBudget)
		setTotalProductionBudget(newTotalBudget + fixedCosts + extraMargin)

		recalculateProjectHoursTotal();

	}

	useEffect(() => {
		if (props.project) {
			let actuals: ProjectRoleActualHours[] = [];
			for (var i in allActuals) {
				let actual = allActuals[i];
				if (actual.projectID === props.project!.id) {
					actuals.push(actual);
				}
			}
			setActuals(actuals);


			buildTotals();

		}
	}, [props.project, allActuals])

	let hoursBreakdown: { [x: string]: number; } = {};
	if (props.project) {
		for (var i in props.project.plannedHours) {
			let role = props.project.plannedHours[i];
			hoursBreakdown[role.roleID] = role.hours;
		}
	} else {
		for (var i in roles) {
			let role = roles[i];
			hoursBreakdown[role.id] = 0;
		}
	}
	const [projectHours, setProjectHours] = useState(hoursBreakdown);
	const [projectHoursTotal, setProjectHoursTotal] = useState(hoursBreakdown);
	const [fixedCostTotal, setFixedCostTotal] = useState(0);
	const [extraMarginTotal, setExtraMarginTotal] = useState(0);
	const [totalHoursTotal, setTotalHoursTotal] = useState(0);
	const [totalBudgetTotal, setTotalBudgetTotal] = useState(0);
	const [totalProductionBudgetTotal, setTotalProductionBudgetTotal] = useState(0);

	const recalculateProjectHoursTotal = () => {
		//start with whats in project hours and then add all hours from change orders
		let totalHours: { [x: string]: number; } = {};
		let fixedCostTotal = fixedCosts;
		let extraMarginTotal = extraMargin;
		let totalHoursTotal = 0;
		for (let i in projectHours) {
			totalHours[i] = projectHours[i];
			totalHoursTotal += projectHours[i];
		}
		if (props.project) {
			for (let i = 0; i < projects.length; i++) {
				let project = projects[i];
				if (project.isChangeOrder && project.changeOrderParentProjectNumber == props.project!.projectNumber) {
					for (let i in project.plannedHours) {
						let role = project.plannedHours[i];
						if (totalHours[role.roleID]) {
							totalHours[role.roleID] += role.hours;
						} else {
							totalHours[role.roleID] = role.hours;
						}
						totalHoursTotal += role.hours;
					}
					fixedCostTotal += project.fixedCosts;
					extraMarginTotal += project.extraMargin;
				}
			}
			setProjectHoursTotal(totalHours);
			setFixedCostTotal(fixedCostTotal);
			setExtraMarginTotal(extraMarginTotal);
			setTotalHoursTotal(totalHoursTotal);

			let hourlyRate = organization.hourlyRate;
			let client = clients.find((client: Client) => client.id == clientID);
			if (client && client.useOverrideHourlyRate) {
				hourlyRate = client.overrideHourlyRate;
			}
			if (useOverrideHourlyRate) {
				hourlyRate = overrideHourlyRate;
			}
			let totalBudget = totalHoursTotal * hourlyRate;
			setTotalBudgetTotal(totalBudget);
			setTotalProductionBudgetTotal(totalBudget + fixedCostTotal + extraMarginTotal);
		}
	}

	const clearForm = () => {
		// setMonth((new Date()).getMonth() + 1);
		// setYear((new Date()).getFullYear());
		setProjectNum("");
		setClientID("");
		setProjectName("");
		setSFClosedWon(0);
		setAccountManagerID("");
		setProjectManagerID("");
		setProjectType("");

		let hoursBreakdown: { [x: string]: number; } = {};
		for (var i in roles) {
			let role = roles[i];
			hoursBreakdown[role.id] = 0;
		}
		setProjectHours(hoursBreakdown);
		setTotalHours(0);
		setTotalBudget(0);
		setTotalProductionBudget(0);

		setUseOverrideHourlyRate(false);
		setOverrideHourlyRate(organization.hourlyRate);

		setExtraMargin(0);
		setFixedCosts(0);
		setComments("");

		setStatus("");
		setStatusHistory([...emptyStatusHistory])


		setClearingForm(true);
		setTimeout(() => { setClearingForm(false) }, 100);
	}

	useEffect(() => {
		if (clearingForm == false) {

			buildTotals();

		}
	}, [clearingForm]);

	const saveNewProject = () => {
		return new Promise((resolve: (project: Project) => void, reject) => {

			for (var p in projects) {
				let otherProejct = projects[p];
				if (otherProejct.projectNumber == projectNum) {
					reject("Project number already exists");
					return;
				}
			}

			let translatedPlannedHours: { roleID: string; hours: number; }[] = [];
			let totalPlannedHours = 0;
			let differenceActualToBilled = 0;
			let totalInvoiced = 0;
			let invoicesCollected = 0;
			let invoicesOutstanding = 0;
			let roleIDs: string[] = [];
			let departmentIDs: string[] = [];
			for (var key in projectHours) {
				translatedPlannedHours.push({
					roleID: key,
					hours: projectHours[key]
				})
				roleIDs.push(key);
				let role = roles.find((role: Role) => role.id == key);
				if (role && role.isBillable) {
					totalPlannedHours += projectHours[key]
				}
			}
			let docData = {
				accountManagerID: accountManagerID,
				calculated: {
					actualHours: 0,
					plannedHours: totalPlannedHours,
					differenceActualToBilled: differenceActualToBilled,
					latestActivity: "",
					currentPlusMinus: 0,
					totalInvoiced: totalInvoiced,
					invoicesCollected: invoicesCollected,
					invoicesOutstanding: invoicesOutstanding,
				},
				clientID: clientID,
				comments: comments,
				monthClosedWon: month,
				organizationID: organization.id,
				overrideHourlyRate: useOverrideHourlyRate ? overrideHourlyRate : organization.hourlyRate,
				plannedHours: translatedPlannedHours,
				roleIDs: roleIDs,
				departmentIDs: departmentIDs,
				projectManagerID: projectManagerID,
				regionalSalesManagerID: regionalSalesManagerID,
				projectType: projectType,
				projectName: projectName,
				projectNumber: projectNum,
				isContractSigned: isContractSigned,
				fixedCosts: 0,
				extraMargin: 0,
				sfClosedWon: sfClosedWon,
				useOverrideHourlyRate: useOverrideHourlyRate,
				yearClosedWon: year,
				status: status,
				statusHistory: statusHistory,
				projectClientContacts: projectContacts,
				bidWorksheet: bidWorksheet,
				projectRoleTasks: projectRoleTasks,
				pmEstimatedPercentComplete: pmEstimatedPercentComplete,
				amPercentToInvoice: amPercentToInvoice,
				pmNotes: pmNotes,
				amNotes: amNotes,
				adminNotes: adminNotes,
				invoices: invoices,
				isChangeOrder: isChangeOrder,
				changeOrderParentProjectNumber: changeOrderParentProjectNumber,
			}
			addDoc(collection(sharedFirestore, "Project"), docData).then((docref) => {
				let project = { ...docData, id: docref.id } as Project;
				let undoEntry: UndoStackEntry = {
					type: UndoStackEntryType.create,
					objectType: UndoStackEntryObjectType.project,
					objectID: project.id,
					objectBeforeState: undefined,
					objectAfterState: { ...project },
					description: "Create Project"
				}
				AC.submitUndoStackEntry(undoEntry);
				resolve(project);
			})
		});
	}

	const saveExistingProject = () => {
		return new Promise<void>((resolve, reject) => {

			const projectRef = doc(sharedFirestore, "Project", props.project!.id);


			for (var p in projects) {
				let otherProejct = projects[p];
				if (otherProejct.projectNumber == projectNum && otherProejct.id != props.project!.id) {
					reject(`Project number ${projectNum} already exists`);
					return;
				}
			}


			let updatedProject: { [x: string]: any; } = {};
			for (var key in props.project!) {
				updatedProject[key] = (props.project! as any)[key];
			}

			let translatedPlannedHours: { roleID: string; hours: number; }[] = [];
			let totalPlannedHours = 0;
			let roleIDs: string[] = [];
			for (var key in projectHours) {
				if (organization.roleIDOrder.indexOf(key) != -1) {
					translatedPlannedHours.push({
						roleID: key,
						hours: projectHours[key]
					})
					roleIDs.push(key);
					let role = roles.find((role: Role) => role.id == key);
					if (role && role.isBillable) {
						totalPlannedHours += projectHours[key]
					}
				}
			}

			let differenceActualToBilled = 0;
			let totalInvoiced = 0;
			let invoicesCollected = 0;
			let invoicesOutstanding = 0;

			updatedProject.calculated.plannedHours = totalPlannedHours
			updatedProject.clientID = clientID
			updatedProject.monthClosedWon = month
			updatedProject.plannedHours = translatedPlannedHours
			updatedProject.roleIDs = roleIDs
			updatedProject.projectManagerID = projectManagerID
			updatedProject.accountManagerID = accountManagerID;
			updatedProject.regionalSalesManagerID = regionalSalesManagerID;
			updatedProject.projectName = projectName
			updatedProject.projectNumber = projectNum
			updatedProject.sfClosedWon = sfClosedWon
			updatedProject.yearClosedWon = year
			updatedProject.fixedCosts = fixedCosts;
			updatedProject.projectType = projectType;
			updatedProject.extraMargin = extraMargin;
			updatedProject.comments = comments;
			updatedProject.isContractSigned = isContractSigned;
			updatedProject.status = status;
			updatedProject.statusHistory = statusHistory;
			updatedProject.projectClientContacts = projectContacts;
			updatedProject.useOverrideHourlyRate = useOverrideHourlyRate;
			updatedProject.overrideHourlyRate = useOverrideHourlyRate ? overrideHourlyRate : organization.hourlyRate
			updatedProject.bidWorksheet = bidWorksheet;
			updatedProject.retrospective = retrospective;
			updatedProject.projectRoleTasks = projectRoleTasks;

			updatedProject.pmEstimatedPercentComplete = pmEstimatedPercentComplete;
			updatedProject.amPercentToInvoice = amPercentToInvoice;
			updatedProject.pmNotes = pmNotes;
			updatedProject.amNotes = amNotes;
			updatedProject.adminNotes = adminNotes;
			updatedProject.invoices = invoices;
			updatedProject.isChangeOrder = isChangeOrder;
			updatedProject.changeOrderParentProjectNumber = changeOrderParentProjectNumber;

			invoices.forEach(invoice => {
				totalInvoiced += invoice.amount;
				if (invoice.datePaid != "") {
					invoicesCollected += invoice.amount;
				} else {
					invoicesOutstanding += invoice.amount;
				}
			});
			updatedProject.calculated.totalInvoiced = totalInvoiced;
			updatedProject.calculated.invoicesCollected = invoicesCollected;
			updatedProject.calculated.invoicesOutstanding = invoicesOutstanding;


			let hourlyRate = organization.hourlyRate;
			let client = clients.find((client: Client) => client.id == updatedProject.clientID);
			if (client != null && client.useOverrideHourlyRate) {
				hourlyRate = client.overrideHourlyRate;
			}
			if (updatedProject.useOverrideHourlyRate) {
				hourlyRate = updatedProject.overrideHourlyRate;
			}
			let budget = updatedProject.calculated.plannedHours * hourlyRate;
			updatedProject.calculated.currentPlusMinus = (updatedProject.pmEstimatedPercentComplete * budget) - (updatedProject.calculated.actualHours * hourlyRate);

			updatedProject.calculated.differenceActualToBilled = updatedProject.calculated.totalInvoiced - (updatedProject.amPercentToInvoice * updatedProject.sfClosedWon);

			let latestActivity = updatedProject.calculated.latestActivity
			actuals.forEach(actual => {
				if (actual.projectID == props.project!.id) {
					actual.hours.forEach(hour => {
						if (latestActivity == "") {
							latestActivity = hour.date;
						} else {
							let latestDate = new Date(latestActivity);
							let date = new Date(hour.date);
							if (date > latestDate) {
								latestActivity = hour.date;
							}
						}
					});
				}
			});
			updatedProject.calculated.latestActivity = latestActivity;

			let total = 0;
			for (var i in updatedProject.plannedHours) {
				let role = updatedProject.plannedHours[i];
				total += role.hours;
			}
			updatedProject.calculated.plannedHours = total;

			updatedProject.roleIDs = [] as string[];
			for (var i in updatedProject.plannedHours) {
				let role = updatedProject.plannedHours[i];
				updatedProject.roleIDs.push(role.roleID);
			}

			let undoEntry: UndoStackEntry = {
				type: UndoStackEntryType.update,
				objectType: UndoStackEntryObjectType.project,
				objectID: props.project!.id,
				objectBeforeState: { ...props.project! },
				objectAfterState: { ...updatedProject },
				description: "Create Project"
			}
			AC.submitUndoStackEntry(undoEntry);

			updateDoc(projectRef, updatedProject).then(() => {
				resolve();
			})

		})
	}

	let completedStatuses: string[] = (organization.projectStatusCategories as any)["completed"] as string[];
	let biddingStatuses: string[] = (organization.projectStatusCategories as any)["bidding"] as string[];


	return (
		<>
			<div className={`popup PopupAddProject`}>
				<h1>{props.project ? "Edit Project" : "New Project Sold"}</h1>
				{clearingForm == false && (
					<div className="content">

						{props.project && (
							<button className="deleteButton" onClick={() => {
								if (window.confirm("Are you sure you want to delete this project?")) {
									deleteProject(props.project!, actuals, (entry: UndoStackEntry) => {
										AC.submitUndoStackEntry(entry);
									}).then(() => {
										props.onCancel();
									})
								}
							}}>Delete Project</button>
						)}

						<div className="topArea">

							<div className="row">
								<div className="col">
									<div className="label">Project Closed</div>
									<div className="row">
										{completedStatuses.includes(status) == false ? (
											<input className={`month`}
												onBlur={(event) => {
													if (event.target.value == "" || event.target.value == "0") {
														event.target.value = ((new Date()).getMonth() + 1).toString();
													}
													setMonth(parseFloat(event.target.value.replace(/,/g, "")))
												}}
												onFocus={(event) => {
													let input = event.target as HTMLInputElement
													input.setSelectionRange(0, input.value.length)
												}}
												onKeyPress={(event) => {
													forceNumeric(event);
													forceMaxValue(event, 12);
												}}
												defaultValue={month}>
											</input>
										) : (
											<>{month}</>
										)}

										{completedStatuses.includes(status) == false ? (
											<input className={`year`}
												onBlur={(event) => {
													if (event.target.value == "" || parseInt(event.target.value) < 1983) {
														event.target.value = (new Date()).getFullYear().toString();
													}
													setYear(parseFloat(event.target.value.replace(/,/g, "")))
												}}
												onFocus={(event) => {
													let input = event.target as HTMLInputElement
													input.setSelectionRange(0, input.value.length)
												}}
												onKeyPress={(event) => {
													forceNumeric(event);
													forceMaxValue(event, 2200);
												}}
												onChange={(event) => {
												}}
												defaultValue={year}>
											</input>
										) : (
											<>{year}</>
										)}
									</div>
								</div>
							</div>


							<div className="row">

								<div className="col">
									<div className="label">Client</div>

									{completedStatuses.includes(status) == false ? (
										<>
											<ClientDropdown
												onChange={(value: string) => {
													setClientID(value);
												}}
												onCreateNew={() => {
													setSelectedClient(undefined);
													setShowingClientPopup(true);
												}}
												className="Client"
												defaultValue={clientID}></ClientDropdown>

											{(clientID != "" && permissionsWrite.includes("Clients")) && (
												<div className="buttonEditClient"
													onClick={() => {
														setSelectedClient(clients.find((client) => client.id == clientID))
														setShowingClientPopup(true);
													}}>
													Edit Client <FontAwesomeIcon icon={faEdit} />
												</div>
											)}
										</>
									) : (
										<>{clients.find(client => client.id == clientID)?.name}</>
									)}
								</div>

								<div className="col">
									<div className="label">Project #</div>
									<div className="row">
										{completedStatuses.includes(status) == false ? (
											<>
												<input className={`projectNum`}
													defaultValue={projectNum}
													onBlur={(event) => {
														setProjectNum(event.target.value)
													}}></input>
												{projectNum == "" && (
													<div className='buttonGenerate'
														onClick={() => {
															let rand = "";
															let found = true;
															while (found) {
																found = false;
																rand = Math.floor(Math.random() * 9999999) + "";

																//pad rand to 7 digits
																rand = rand.padStart(7, "0");

																for (var i in projects) {
																	if (projects[i].projectNumber == rand) {
																		found = true;
																		break;
																	}
																}
															}
															setProjectNum(rand);

															setClearingForm(true);
															setTimeout(() => { setClearingForm(false) }, 100);
														}}><FontAwesomeIcon icon={faRobot}></FontAwesomeIcon> Generate</div>
												)}
											</>
										) : (
											<>{projectNum}</>
										)}
									</div>
								</div>

								<div className="col">
									<div className="label">Project Name</div>
									{completedStatuses.includes(status) == false ? (
										<input className={`projectName`}
											defaultValue={projectName}
											onBlur={(event) => {
												setProjectName(event.target.value)
											}}></input>
									) : (
										<>{projectName}</>
									)}

								</div>
								{biddingStatuses.includes(status) == false && (
									<div className="col">
										<div className="label">SOW Price</div>
										{completedStatuses.includes(status) == false ? (
											<input className={`sfClosedWon`}
												ref={inputSFClosdWon}
												defaultValue={prettyCurrency(sfClosedWon)}
												onFocus={(event) => {
													let input = event.target as HTMLInputElement
													input.setSelectionRange(0, input.value.length)
												}}
												onKeyPress={(event) => {
													forceDecimal(event);
												}}
												onBlur={(event) => {
													event.target.value = prettyCurrency(event.target.value);
													setSFClosedWon(parseFloat(event.target.value.replace("$", "").replace(/,/g, "")))
												}}></input>
										) : (
											<>{prettyCurrency(sfClosedWon)}</>
										)}
									</div>
								)}
								<div className="col">
									<div className="label">Total Project Price</div>
									<div className='calculatedTotalProjectPrice'>
										{prettyCurrency(totalProjectPrice)}
									</div>
								</div>

								{rolesExistAM(roles) && (
									<div className="col">
										<div className="label">Account Manager</div>
										{completedStatuses.includes(status) == false ? (
											<PersonDropdown
												isAM={true}
												onChange={(value: string) => {
													setAccountManagerID(value);
												}}
												onCreateNew={() => {
													setPersonIsAM(true);
													setPersonIsRSM(false);
													setShowingPersonPopup(true);
												}}
												className="accountManager"
												defaultValue={accountManagerID}></PersonDropdown>
										) : (
											<>{persons.find(person => person.id == accountManagerID)?.name}</>
										)}
									</div>
								)}

								{rolesExistPM(roles) && (
									<div className="col">
										<div className="label">Project Manager</div>
										{completedStatuses.includes(status) == false ? (
											<PersonDropdown
												isPM={true}
												onChange={(value: string) => {
													setProjectManagerID(value);
												}}
												onCreateNew={() => {
													setPersonIsAM(false);
													setPersonIsRSM(false);
													setShowingPersonPopup(true);
												}}
												className="projectManager"
												defaultValue={projectManagerID}></PersonDropdown>
										) : (
											<>{persons.find(person => person.id == projectManagerID)?.name}</>
										)}
									</div>
								)}

								{rolesExistRSM(roles) && (
									<div className="col">
										<div className="label">Regional Sales Manager</div>
										{completedStatuses.includes(status) == false ? (
											<PersonDropdown
												isRSM={true}
												onChange={(value: string) => {
													setRegionalSalesManagerID(value);
												}}
												onCreateNew={() => {
													setPersonIsAM(false);
													setPersonIsRSM(true);
													setShowingPersonPopup(true);
												}}
												className="regionalSalesManagerID"
												defaultValue={regionalSalesManagerID}></PersonDropdown>
										) : (
											<>{persons.find(person => person.id == regionalSalesManagerID)?.name}</>
										)}
									</div>
								)}


								<div className="col">
									<div className="row">
										<div className="label">Custom Hourly Rate?</div>
										{completedStatuses.includes(status) == false ? (
											<input type='checkbox' checked={useOverrideHourlyRate} onChange={(event) => {
												setUseOverrideHourlyRate(event.target.checked);
											}}></input>
										) : (
											<>{useOverrideHourlyRate && <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}</>
										)}
									</div>

									{completedStatuses.includes(status) == false ? (
										<input type='text' disabled={useOverrideHourlyRate == false}
											onKeyPress={(event) => { forceDecimal(event) }}
											defaultValue={prettyCurrency(overrideHourlyRate)}
											onFocus={(event) => {
												let input = event.target as HTMLInputElement
												input.setSelectionRange(0, input.value.length)
											}}
											onBlur={(event) => {

												if (event.target.value == "") {
													event.target.value = "0";
												}
												event.target.value = prettyCurrency(event.target.value)
												setOverrideHourlyRate(parseFloat(event.target.value.replace("$", "").replace(/,/g, "")));
											}}></input>
									) : (
										<>{useOverrideHourlyRate ? prettyCurrency(overrideHourlyRate) : ""}</>
									)}
								</div>

								<div className="col">
									<div className="row">
										<div className="label">Contract Signed</div>
										{completedStatuses.includes(status) == false ? (
											<input type='checkbox' checked={isContractSigned} onChange={(event) => {
												setIsContractSigned(event.target.checked);
											}}></input>
										) : (
											<>{isContractSigned && <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}</>
										)}
									</div>
								</div>

								<div className="col">
									<div className="label">Project Type</div>
									{completedStatuses.includes(status) == false ? (
										<ProjectTypeDropdown
											defaultValue={projectType}
											onChange={(projectType: string) => {
												setProjectType(projectType);
											}}></ProjectTypeDropdown>
									) : (
										<>{projectType}</>
									)}
								</div>


								<div className="col">
									<div className="label">Project Status</div>
									<select className={`status`}
										value={status}
										onChange={(event) => {
											setStatus(event.target.value)

											let newStatus = {
												date: shortDate(new Date()),
												status: event.target.value,
											};
											let newStatusHistory = [...statusHistory];
											newStatusHistory.push(newStatus);
											setStatusHistory(newStatusHistory);
										}}>
										<option value="">Select Status</option>
										{organization.projectStatusList.map((option) => {
											return <option value={option}>{option}</option>
										})}
									</select>
								</div>

								<div className="col">
									<div className="label">PM Estimated Percent Complete</div>
									{completedStatuses.includes(status) == false ? (
										<input className={`pmEstimatedPercentComplete`}
											defaultValue={(Math.round(pmEstimatedPercentComplete * 1000) / 10) + "%"}
											onFocus={(event) => {
												let input = event.target as HTMLInputElement
												input.setSelectionRange(0, input.value.length)
											}}
											onKeyPress={(event) => {
												forcePercentage(event);
											}}
											onBlur={(event) => {
												let percent = parseFloat(event.target.value.replace("%", "").replace(/,/g, "")) / 100;
												event.target.value = (Math.round(percent * 1000) / 10) + "%";
												setPMEstimatedPercentComplete(percent)
											}}></input>
									) : (
										<>{(Math.round(pmEstimatedPercentComplete * 1000) / 10) + "%"}</>
									)}
								</div>

								<div className="col">
									<div className="label">AM % to Invoice</div>
									{completedStatuses.includes(status) == false ? (
										<input className={`amPercentToInvoice`}
											defaultValue={(Math.round(amPercentToInvoice * 1000) / 10) + "%"}
											onFocus={(event) => {
												let input = event.target as HTMLInputElement
												input.setSelectionRange(0, input.value.length)
											}}
											onKeyPress={(event) => {
												forcePercentage(event);
											}}
											onBlur={(event) => {
												let percent = parseFloat(event.target.value.replace("%", "").replace(/,/g, "")) / 100;
												event.target.value = (Math.round(percent * 1000) / 10) + "%";
												setAMPercentToInvoice(percent)
											}}></input>
									) : (
										<>{(Math.round(pmEstimatedPercentComplete * 1000) / 10) + "%"}</>
									)}
								</div>
							</div>
							<div className="row">
								<div className="col">
									<div className="label">AM Notes</div>
									{completedStatuses.includes(status) == false ? (
										<textarea className={`amNotes`}
											defaultValue={amNotes}
											onBlur={(event) => {
												setAMNotes(event.target.value);
											}}></textarea>
									) : (
										<>{amNotes}</>
									)}
								</div>
							</div>
							<div className="row">
								<div className="col">
									<div className="label">PM Notes</div>
									{completedStatuses.includes(status) == false ? (
										<textarea className={`pmNotes`}
											defaultValue={pmNotes}
											onBlur={(event) => {
												setPMNotes(event.target.value);
											}}></textarea>
									) : (
										<>{pmNotes}</>
									)}
								</div>
							</div>
							<div className="row">
								<div className="col">
									<div className="label">Admin Notes</div>
									{completedStatuses.includes(status) == false ? (
										<textarea className={`adminNotes`}
											defaultValue={adminNotes}
											onBlur={(event) => {
												setAdminNotes(event.target.value);
											}}></textarea>
									) : (
										<>{adminNotes}</>
									)}
								</div>
							</div>
						</div>

						{(statusHistory.length > 0 || parentProject != undefined) && (
							<div className="topArea">
								<h1>Project Status History</h1>
								<ProjectStatusHistoryTable
									statusHistory={parentProject ? parentProject.statusHistory : statusHistory}
									isReadOnly={completedStatuses.includes(status) || parentProject != undefined}
									onStatusHistoryChange={(newStatusHistory) => {
										setStatusHistory(newStatusHistory);
										if (newStatusHistory.length > 0) {
											let topEntry = newStatusHistory[newStatusHistory.length - 1];
											setStatus(topEntry.status);
										}
									}}></ProjectStatusHistoryTable>
							</div>
						)}

						{(parentProject || (clientID != "" && clients.find((client: Client) => client.id == clientID))) && (
							<div className="topArea">
								<ProjectContactsTable
									clientID={parentProject ? parentProject.clientID : clientID}
									isReadOnly={completedStatuses.includes(status) || parentProject != undefined}
									projectContacts={parentProject ? parentProject.projectClientContacts : projectContacts}
									onProjectContactsChange={(newProjectContacts) => {
										setProjectContacts([...newProjectContacts]);
									}}
									onCreateNewClient={function (): void {
										setAddingPrimaryContact(false);
										setShowingClientContactPopup(true);
									}}></ProjectContactsTable>
							</div>
						)}


						<div className="topArea">
							<BiddingWorksheet
								bidWorksheet={bidWorksheet}
								projectStatus={status}
								useOverrideHourlyRate={useOverrideHourlyRate}
								overrideHourlyRate={overrideHourlyRate}
								onBidWorksheetChange={newBidWorksheet => {
									setBidWorksheet(newBidWorksheet);
								}}
								onClosedWon={() => {
									setSFClosedWon(bidWorksheet.calculated.totalSOWPrice);
									if (inputSFClosdWon.current) {
										inputSFClosdWon.current.value = prettyCurrency(bidWorksheet.calculated.totalSOWPrice);
									}

									let hoursBreakdown: { [x: string]: number; } = {};
									for (var i in bidWorksheet.roles) {
										let roleRow = bidWorksheet.roles[i];
										let role = roles.find((role: Role) => role.id == roleRow.roleID);
										if (role && role.isBillable) {
											hoursBreakdown[roleRow.roleID] = roleRow.hours;
										}
									}
									setProjectHours(hoursBreakdown);
									setStatus("Closed Won");

									setExtraMargin(bidWorksheet.calculated.totalExtraMargin);
									setFixedCosts(bidWorksheet.calculated.totalFixedCosts);

									let comments = "";
									for (var i in bidWorksheet.fixedCosts) {
										let fixedCost = bidWorksheet.fixedCosts[i];
										if (fixedCost.cost > 0) {
											if (comments != "") {
												comments += ", ";
											}
											comments += fixedCost.fixedCostItem + ": " + prettyCurrency(fixedCost.cost);
										}
									}
									if (bidWorksheet.discount > 0) {
										if (comments != "") {
											comments += ", ";
										}
										comments += "Discount: " + prettyCurrency(bidWorksheet.discount);
									}
									setComments(comments);
									setClearingForm(true);
									setTimeout(() => { setClearingForm(false) }, 100);

								}}></BiddingWorksheet>
						</div>

						<div className="topArea">
							<ProjectRetrospectiveWorksheet
								retrospective={retrospective}
								projectStatus={status}
								projectContacts={projectContacts}
								onRetrospectiveChange={retrospective => {
									setRetrospective(retrospective);
								}}
								onSendSurveyClicked={() => {
									setShowingSurveyPopup(true);
								}}></ProjectRetrospectiveWorksheet>
						</div>


						{biddingStatuses.includes(status) == false && (
							<>
								<div className="bottomArea">
									<div className="leftSide">
										<h1>Hours Breakdown By Role</h1>
										<div className="roles">


											{organization.roleIDOrder.map((roleID: string, index) => {
												let role: Role | null = null;
												for (var i in roles) {
													let tempRole = roles[i];
													if (tempRole.id === roleID) {
														role = tempRole;
														break;
													}
												}
												if (role == null) {
													return <></>
												}

												if (role.isBillable == false) {
													return <></>
												}
												return (
													<div key={`role_input_${role.id}`} className='role'>
														<div className="label">{role.name}</div>
														{completedStatuses.includes(status) == false ? (
															<input
																type='text'
																onKeyPress={(event) => { forceDecimal(event) }}
																onFocus={(event) => {
																	let input = event.target as HTMLInputElement
																	input.setSelectionRange(0, input.value.length)
																}}
																onBlur={(event) => {
																	event.target.value = prettyNum(event.target.value)
																	if (event.target.value == "") {
																		event.target.value = "0";
																	}

																	let newProjectHours = { ...projectHours };
																	newProjectHours[role!.id] = parseFloat(event.target.value.replace("$", "").replace(/,/g, ""))
																	setProjectHours(newProjectHours);

																	let totalPlannedHours = 0;
																	for (var key in newProjectHours) {
																		if (organization.roleIDOrder.indexOf(key) != -1) {
																			let role = roles.find((role: Role) => role.id == key);
																			if (role && role.isBillable) {
																				totalPlannedHours += newProjectHours[key]
																			}
																		}
																	}
																	setTotalHours(totalPlannedHours);
																	let hourlyRate = organization.hourlyRate;
																	let client = clients.find((client: Client) => client.id == clientID);
																	if (client && client.useOverrideHourlyRate) {
																		hourlyRate = client.overrideHourlyRate;
																	}
																	if (useOverrideHourlyRate) {
																		hourlyRate = overrideHourlyRate;
																	}
																	let newTotalBudget = totalPlannedHours * hourlyRate;
																	setTotalBudget(newTotalBudget)
																	setTotalProductionBudget(newTotalBudget + fixedCosts + extraMargin)
																}}
																defaultValue={projectHours[role.id] ?? "0"} ></input>
														) : (
															<>{projectHours[role.id] ?? "0"}</>
														)}
														{props.project && (
															<div className="usedHours">
																{(
																	() => {
																		let usedHours = 0;
																		let roleActuals = actuals.filter(actual => actual.roleID == role!.id, []);
																		usedHours = roleActuals.reduce((total, actual) => total + actual.calculated.totalHours, 0)
																		return usedHours > 0 ? `Used: ${prettyNum(usedHours)}` : "";
																	}
																)()}
															</div>
														)}
													</div>
												)
											})}


											<div className="role">
												<div className="label">Fixed Costs</div>
												{completedStatuses.includes(status) == false ? (
													<>
														<input className={`fixedCosts`}
															defaultValue={prettyCurrency(fixedCosts)}
															onFocus={(event) => {
																let input = event.target as HTMLInputElement
																input.setSelectionRange(0, input.value.length)
															}}
															onKeyPress={(event) => {
																forceDecimal(event);
															}}
															onBlur={(event) => {
																event.target.value = prettyCurrency(event.target.value);
																let newFixedCosts = parseFloat(event.target.value.replace("$", "").replace(/,/g, ""))
																setFixedCosts(newFixedCosts)
																setTotalProductionBudget(totalBudget + newFixedCosts + extraMargin)
															}}></input>
														{props.project && <div className="usedHours"></div>}
													</>
												) : (
													<>{prettyCurrency(fixedCosts)}<div className="usedHours"></div></>
												)}
											</div>

											<div className="role">
												<div className="label">Extra Margin</div>
												{completedStatuses.includes(status) == false ? (
													<>
														<input className={`extraMargin`}
															defaultValue={prettyCurrency(extraMargin)}
															onFocus={(event) => {
																let input = event.target as HTMLInputElement
																input.setSelectionRange(0, input.value.length)
															}}
															onKeyPress={(event) => {
																forceDecimal(event);
															}}
															onBlur={(event) => {
																event.target.value = prettyCurrency(event.target.value);
																let newExtraMargin = parseFloat(event.target.value.replace("$", "").replace(/,/g, ""))
																setExtraMargin(newExtraMargin)
																setTotalProductionBudget(totalBudget + fixedCosts + newExtraMargin)
															}}></input>
														{props.project && <div className="usedHours"></div>}
													</>
												) : (
													<>{prettyCurrency(extraMargin)}<div className="usedHours"></div></>
												)}

											</div>

											<div className="role wide">
												<div className="label">Comments</div>

												{completedStatuses.includes(status) == false ? (
													<>
														<input className={`comments`}
															defaultValue={comments}
															onBlur={(event) => {
																setComments(event.target.value);
															}}></input>
														{props.project && <div className="usedHours"></div>}
													</>
												) : (
													<>{comments}<div className="usedHours"></div></>
												)}
											</div>
										</div>
									</div>
									<div className="rightSide">
										<div className="label">Total Hours</div>
										<div className="value">{prettyNum(totalHours)}</div>
										<div className="label">{organization.shortName} Production Budget</div>
										<div className="value">{prettyCurrency(totalBudget)}</div>
										<div className="label">Total Production Budget</div>
										<div className="value">{prettyCurrency(totalProductionBudget)}</div>
									</div>
								</div>

								{!parentProject && props.project && (
									<>
										{projects.filter((project2: Project) => (project2.isChangeOrder && project2.changeOrderParentProjectNumber == props.project!.projectNumber)).map((project2: Project) => {
											return <ChangeOrderHoursBreakdownByRole
												project={project2}
												onUpdated={() => {
													recalculateProjectHoursTotal();
												}}></ChangeOrderHoursBreakdownByRole>
										})}
										<div className="bottomArea">
											<div className="leftSide">
												<h1>Combined Hours Breakdown By Role</h1>
												<div className="roles combined">


													{organization.roleIDOrder.map((roleID: string, index) => {
														let role: Role | null = null;
														for (var i in roles) {
															let tempRole = roles[i];
															if (tempRole.id === roleID) {
																role = tempRole;
																break;
															}
														}
														if (role == null) {
															return <></>
														}

														if (role.isBillable == false) {
															return <></>
														}
														return (
															<div key={`role_input_${role.id}`} className='role'>
																<div className="label">{role.name}</div>
																<>{projectHoursTotal[role.id] ?? "0"}</>
																{props.project && (
																	<div className="usedHours">
																		{(
																			() => {
																				let usedHours = 0;
																				let roleActuals = actuals.filter(actual => actual.roleID == role!.id, []);
																				usedHours = roleActuals.reduce((total, actual) => total + actual.calculated.totalHours, 0)
																				return usedHours > 0 ? `Used: ${prettyNum(usedHours)}` : "";
																			}
																		)()}
																	</div>
																)}
															</div>
														)
													})}


													<div className="role">
														<div className="label">Fixed Costs</div>
														<>{prettyCurrency(fixedCostTotal)}<div className="usedHours"></div></>
													</div>

													<div className="role">
														<div className="label">Extra Margin</div>
														<>{prettyCurrency(extraMarginTotal)}<div className="usedHours"></div></>

													</div>
												</div>
											</div>
											<div className="rightSide">
												<div className="label">Combined Hours</div>
												<div className="value">{prettyNum(totalHoursTotal)}</div>
												<div className="label">{organization.shortName} Combined Production Budget</div>
												<div className="value">{prettyCurrency(totalBudgetTotal)}</div>
												<div className="label">Total Combined Production Budget</div>
												<div className="value">{prettyCurrency(totalProductionBudgetTotal)}</div>
											</div>
										</div>
									</>
								)}





								{props.project && (
									<>
										{!isChangeOrder && (
											<div className="topArea">
												<h1>Project Tasks</h1>
												<ProjectTasksTable
													projectID={props.project!.id}
													projectRoleTasks={projectRoleTasks}
													plannedHours={projectHours}
													isReadOnly={completedStatuses.includes(status)}
													onProjectTasksChanged={(projectRoleTasks) => {
														setProjectRoleTasks(projectRoleTasks);
													}}></ProjectTasksTable>
											</div>
										)}
										<div className="topArea">
											<h1>{parentProject ? "This project is a Change Order" : "Change Orders"}</h1>
											<ProjectChangeOrdersTable
												isParentProjectChangeOrder={isChangeOrder}
												changeOrderProjectNumber={changeOrderProjectNumber}
												parentProject={props.project}
												onEditProjectPressed={project => {
													setChangeOrderProjectNumber(undefined);
													setSelectedProject(project);
													setShowingProjectPopup(true);
												}}
												onCreateChangeOrder={() => {
													let number = 1;
													let tempProjectNumber = props.project!.projectNumber + "-" + padZeros(number, 2);
													let existingProject = projects.find(project => project.projectNumber == tempProjectNumber);
													while (existingProject) {
														number++;
														tempProjectNumber = props.project!.projectNumber + "-" + padZeros(number, 2);
														existingProject = projects.find(project => project.projectNumber == tempProjectNumber);
													}
													setChangeOrderProjectNumber(tempProjectNumber);
													setSelectedProject(undefined);
													setShowingProjectPopup(true);
												}}></ProjectChangeOrdersTable>
										</div>
									</>
								)}

								{props.project && !isChangeOrder && (
									<div className="topArea">
										<h1>Invoices</h1>
										<ProjectInvoiceTable
											project={props.project}
											projectInvoices={invoices}
											clientID={clientID}
											useOverrideHourlyRate={useOverrideHourlyRate}
											overrideHourlyRate={overrideHourlyRate}
											totalPlannedHoursCombined={totalHoursTotal}
											pmEstimatedPercentComplete={pmEstimatedPercentComplete}
											amPercentToInvoice={amPercentToInvoice}
											totalProjectPrice={totalProjectPrice}
											onProjectInvoicesChanged={(invoices) => {
												setInvoices(invoices);
											}} ></ProjectInvoiceTable>
									</div>
								)}

								{props.project && !isChangeOrder && (
									<div className="topArea">
										<ProjectMessageBoardWorksheet
											project={props.project}
											projectContacts={projectContacts}></ProjectMessageBoardWorksheet>
									</div>
								)}

							</>
						)}

					</div>
				)}
				<div className="floatingButtons">
					<div className="buttonCancel"
						onClick={() => {
							props.onCancel();
						}}>Cancel</div>
					{props.project ? (
						<div className="buttonSave"
							onClick={() => {
								saveExistingProject().then(() => {
									props.onSave(props.project!);
								})
									.catch(err => {
										alert(err);
									});
							}}>Save &amp; Close</div>
					) : (
						<>
							{props.disableMultiple ? (
								<div className="buttonSave"
									onClick={() => {
										saveNewProject().then((project: Project) => {
											props.onSave(project);
										})
											.catch(err => {
												alert(err);
											});
									}}>Save &amp; Close</div>
							) : (
								<DropdownButton
									items={["Save & Close", "Save & Add New"]}
									selectedIndex={0}
									executeFunction={(functionIndex: number) => {
										switch (functionIndex) {
											case 0:
												saveNewProject().then((project: Project) => {
													props.onSave(project);
												})
													.catch(err => {
														alert(err);
													});
												return;
											case 1:
												saveNewProject().then((project: Project) => {
													clearForm();
												})
													.catch(err => {
														alert(err);
													});
												return;
										}
									}} ></DropdownButton>
							)}
						</>
					)}
				</div>
			</div>

			{showingClientPopup && (
				<PopupAddClient
					client={selectedClient ?? undefined}
					onCancel={() => {
						setShowingClientPopup(false);
					}} onSave={(client) => {
						setClientID(client.id)
						setShowingClientPopup(false);
					}}></PopupAddClient>
			)}

			{showingClientContactPopup && clientID != "" && (
				<PopupAddClientContact
					clientID={clientID}
					allowMultiple={false}
					onCancel={() => {
						setShowingClientContactPopup(false);
					}} onSave={(clientContact) => {
						let found = false;
						let foundIndex = 0;
						for (let i = 0; i < projectContacts.length; i++) {

							if (projectContacts[i].clientContactID == clientContact.id) {
								found = true;
								foundIndex = i;
								break;
							}
						}
						if (!found) {
							setProjectContacts([...projectContacts, { clientContactID: clientContact.id, primaryContact: false }]);
						}
						setShowingClientContactPopup(false);
					}}></PopupAddClientContact>
			)}

			{showingPersonPopup && (
				<PopupAddPerson
					roleFilter="AMPM"
					onCancel={() => {
						setShowingPersonPopup(false);
					}} onSave={(person) => {
						if (personIsAM) {
							setAccountManagerID(person.id);
						} else if (personIsRSM) {
							setRegionalSalesManagerID(person.id);
						} else {
							setProjectManagerID(person.id)
						}
						setShowingPersonPopup(false);
					}}></PopupAddPerson>
			)}

			{showingProjectPopup && (
				<PopupAddProject
					project={selectedProject}
					isProjectChangeOrder={true}
					changeOrderParentProjectNumber={projectNum}
					defaultProjectNumber={changeOrderProjectNumber}
					onCancel={() => {
						setShowingProjectPopup(false);
					}}
					onSave={(project: Project) => {
						setShowingProjectPopup(false);
					}}></PopupAddProject>
			)}
			{showingSurveyPopup && (
				<PopupSendSurvey
					project={props.project!}
					contacts={projectContacts}
					onCancel={() => {
						setShowingSurveyPopup(false);
					}}
					onSend={(formID: string) => {
						setShowingSurveyPopup(false);
					}}></PopupSendSurvey>
			)}
		</>
	)
}