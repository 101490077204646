
import { useCSVReader } from 'react-papaparse';
import React, { useEffect, useState } from "react"
import { addDoc, collection, deleteDoc, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where } from "firebase/firestore";
import "./PopupImportInvoices.css"
import { useDispatch, useSelector } from "react-redux";
import { Project, ProjectInvoice } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import { prettyNum, forceNumeric, prettyCurrency, forceDecimal, shortDate } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { saveClientAttributeChange } from "../../Utils/SaveClientFunctions";
import { useRef } from 'react';
import { ProjectRoleActualHours } from '../../FirebaseModel/ProjectRoleActualHours';
import { Person } from '../../FirebaseModel/Person';
import { saveProjectAttributeChange } from '../../Utils/SaveProjectFunctions';
import { faFileDownload, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../Redux/ActionCreators';
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from '../../Utils/UndoStackEntry';
import { stringToBase64 } from '../../Utils/StringToBase64';
import { cleanCSVString } from '../../Utils/CleanCSVString';

const uuid = require('uuid');

interface PopupImportInvoicesProps {
	onDismiss: () => void;
}

export default function PopupImportInvoices(props: PopupImportInvoicesProps) {

	const progressRef = useRef<HTMLProgressElement>(null);
	const organization = useSelector((state: { organization: Organization }) => state.organization)
	const clients = useSelector((state: { clients: Client[] }) => state.clients)
	const projects = useSelector((state: { projects: Project[] }) => state.projects)
	const actuals = useSelector((state: { actuals: ProjectRoleActualHours[] }) => state.actuals)
	const persons = useSelector((state: { persons: Person[] }) => state.persons)

	const { CSVReader } = useCSVReader();

	const [errorMessage, setErrorMessage] = useState<string>("");
	const [allRows, setAllRows] = useState<any[]>([]);

	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry: ActionCreators.submitUndoStackEntry
	}, dispatch);


	const downloadTemplateCSV = () => {
		// generate a csv file with the appropriate headers and download it.

		let csvContent = "data:text/csv;charset=utf-8,";

		csvContent += "Project Number,Invoice Number,Date Invoiced,Amount,Date Paid\n";

		let encodedUri = encodeURI(csvContent);
		let link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "invoice_import_template.csv");
		document.body.appendChild(link); // Required for FF


		link.click(); // This will download the data file named "my_data.csv".

		document.body.removeChild(link);
	}

	const downloadCurrentInvoicesCSV = () => {
		// generate a csv file with the appropriate headers and download it.

		let csvContent = "";

		csvContent += "Project Number,Invoice Number,Date Invoiced,Amount,Date Paid\n";

		projects.forEach(project => {
			project.invoices.forEach(invoice => {
				csvContent += `${cleanCSVString(project.projectNumber)},${cleanCSVString(invoice.invoiceNumber)},${cleanCSVString(invoice.dateInvoiced)},${invoice.amount},${cleanCSVString(invoice.datePaid)}\n`;
			});
		})

		// convert content to base64 string and download as data uri
		let base64 = stringToBase64(csvContent);

		let dataUri = "data:text/csv;base64," + base64;
		let link = document.createElement("a");
		link.setAttribute("href", dataUri);
		link.setAttribute("download", "current_invoices.csv");
		document.body.appendChild(link); // Required for FF


		link.click(); // This will download the data file named "my_data.csv".

		document.body.removeChild(link);
	}


	const validateFormat = (rows: any) => {


		// <li>Client Name</li>
		// <li>Hourly Rate (blank if using organization rate)</li>

		if (rows.length < 5) {
			setErrorMessage("File must have at least 5 rows");
			return false;
		}

		let headerRow = rows[0];
		if (headerRow.length != 5) {
			setErrorMessage("File must have 5 columns");
			return false;
		}

		if (headerRow[0].toLowerCase() != "project number") {
			setErrorMessage("First column must be 'Project Number'");
			return false;
		}
		if (headerRow[1].toLowerCase() != "invoice number") {
			setErrorMessage("Second column must be 'Invoice Number'");
			return false;
		}
		if (headerRow[2].toLowerCase() != "date invoiced") {
			setErrorMessage("3rd column must be 'Date Invoiced'");
			return false;
		}
		if (headerRow[3].toLowerCase() != "amount") {
			setErrorMessage("4th column must be 'Amount'");
			return false;
		}
		if (headerRow[4].toLowerCase() != "date paid") {
			setErrorMessage("5th column must be 'Date Paid'");
			return false;
		}

		return true;
	}


	const processFile = async (rows: any) => {
		setAllRows(rows);
	}
	const startImport = async (rows: any) => {

		AC.submitUndoStackEntry({
			type: UndoStackEntryType.batchStart,
			objectType: UndoStackEntryObjectType.nonApplicable,
			objectID: "",
			objectBeforeState: undefined,
			objectAfterState: undefined,
			description: 'Import Invoices'
		})

		let dates: Date[] = [];
		let currentName = "";
		let currentProjectName = "";
		let currentPerson: any | null = null;
		let currentProjectNumber = "";
		let currentTask = "";
		let currentTaskID = "";

		let currentProject: Project | null = null;

		let firstDate: Date | null = null;
		let lastDate: Date | null = null;

		let modifiedProjects: Project[] = [];


		progressRef.current!.max = rows.length;
		progressRef.current!.style.display = "block";

		function startNextRow(i: number) {
			setTimeout(() => {
				processNextRow(i);
			}, 100);
		}

		//for(let i = 0; i < rows.length; i++){
		async function processNextRow(i: number) {
			if (i >= rows.length) {
				console.log("Done processing file");
				progressRef.current!.style.display = "none";
				// setTimeout(() => {
				//   updateProjectsCalculatedActuals(modifiedProjects);
				// }, 100);
				AC.submitUndoStackEntry({
					type: UndoStackEntryType.batchEnd,
					objectType: UndoStackEntryObjectType.nonApplicable,
					objectID: "",
					objectBeforeState: undefined,
					objectAfterState: undefined,
					description: 'Import Invoices'
				})
				return;
			}
			let row = rows[i];
			console.log(`Processing row ${i} of ${rows.length}...`);
			startNextRow(i + 1);

			let projectNumber = row[0];
			let invoiceNumber = row[1];
			let dateInvoiced = row[2];
			let amount = row[3];
			let datePaid = row[4];

			let project = projects.find(project => project.projectNumber.toLowerCase() === projectNumber.toLowerCase());
			if (project) {
				let updatedProject = {
					...project
				}
				let invoice = project.invoices.find(invoice => invoice.invoiceNumber.toLowerCase() === invoiceNumber.toLowerCase());
				let updatedInvoice: ProjectInvoice;
				if (invoice) {

					updatedInvoice = {
						...invoice,
						dateInvoiced: dateInvoiced,
						amount: parseFloat(amount),
						datePaid: datePaid,
					}
					updatedProject.invoices = updatedProject.invoices.map(invoice => invoice.id == updatedInvoice.id ? updatedInvoice : invoice);
				}else{
					updatedInvoice = {
						id: uuid.v4(),
						projectID: project.id,
						dateInvoiced: dateInvoiced,
						amount: parseFloat(amount),
						datePaid: datePaid,
						invoiceNumber: invoiceNumber
					}
					updatedProject.invoices.push(updatedInvoice);
				}

				AC.submitUndoStackEntry({
					type: UndoStackEntryType.update,
					objectType: UndoStackEntryObjectType.project,
					objectID: updatedProject.id,
					objectBeforeState: project,
					objectAfterState: updatedProject,
					description: `Update Invoice`
				});
				modifiedProjects.push(updatedProject);
				await updateDoc(doc(sharedFirestore, "Project", updatedProject.id), updatedProject);
			}

			}

			if (validateFormat(rows)) {
				startNextRow(1);
			}
		}


		return (
			<div className={`popup PopupImportInvoices`}>
				<h1>Import Invoices from CSV</h1>

				{errorMessage != "" && <div className="errorMessage">{errorMessage}</div>}

				<div className='instructions'>
					The CSV file must be formatted with these headers in this order:
					<ul>
						<li>Project Number</li>
						<li>Invoice Number</li>
						<li>Date Invoiced</li>
						<li>Amount</li>
						<li>Date Paid</li>
					</ul>
				</div>

				<CSVReader
					onUploadAccepted={async (results: any) => {
						console.log('---------------------------');
						console.log(results);
						console.log('---------------------------');
						processFile(results.data);
					}}
				>
					{({
						getRootProps,
						acceptedFile,
						ProgressBar,
						getRemoveFileProps,
					}: any) => (
						<>
							<div className='csvReader'>
								<button type='button' {...getRootProps()} className={"browseFile"}>
									Browse file
								</button>
								<div className={"acceptedFile"}>
									{acceptedFile && acceptedFile.name}
								</div>
								<button {...getRemoveFileProps()} className={"remove"}>
									Remove
								</button>
							</div>
							{/* <ProgressBar  className={"progressBarBackgroundColor"} /> */}
						</>
					)}
				</CSVReader>
				{allRows.length > 0 && (
					<div className='buttonImport'
						onClick={() => {
							startImport(allRows)
							setAllRows([]);
						}}><FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon> Start Import</div>
				)}
				<progress ref={progressRef} style={{ display: 'none' }} className={"progressBarBackgroundColor"} />

				<div className='buttonTemplate'
					onClick={downloadTemplateCSV}>
					<FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon> Download Template
				</div>
				<div className='buttonTemplate'
					onClick={downloadCurrentInvoicesCSV}>
					<FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon> Download Current Invoices CSV
				</div>

				<div className="floatingButtons">
					<div className="buttonSave"
						onClick={() => {
							projects.forEach(project => {
								let actualsForProject = actuals.filter(actual => actual.projectID === project.id);
								let totalHours = actualsForProject.reduce((total, actual) => total + actual.calculated.totalHours, 0);
								if (project.calculated.actualHours != totalHours) {
									let newCalcualted = { ...project.calculated }
									if (newCalcualted.actualHours != totalHours) {
										newCalcualted.actualHours = totalHours;
										saveProjectAttributeChange(project, organization, clients, "calculated", newCalcualted, (entry: UndoStackEntry) => {
											AC.submitUndoStackEntry(entry);
										})
									}
								}
							})
							props.onDismiss();
						}}>Done</div>
				</div>
			</div>
		)
	}