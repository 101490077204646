import { combineReducers } from "redux"
import { OrganizationIDReducer, ScreenReducer, OrganizationReducer, ClientsReducer, PersonsReducer, RolesReducer, ProjectsReducer, TimeOffListReducer, MonthTotalsReducer, ActualsReducer, PermissionsReducer, UndoStackReducer, DepartmentsReducer, PermissionsWriteReducer, ClientContactsReducer, ViewsReducer, ProjectMessageBoardsReducer, BackupsReducer, YearTotalsReducer, ScreenStackReducer } from "./Navigation"
export const Reducers = combineReducers({
  screen:ScreenReducer,
	screenStack:ScreenStackReducer,
	organizationID:OrganizationIDReducer,
	organization:OrganizationReducer,
	projects:ProjectsReducer,
	projectMessageBoards:ProjectMessageBoardsReducer,
	departments:DepartmentsReducer,
	roles:RolesReducer,
	persons:PersonsReducer,
	clients:ClientsReducer,
	clientContacts:ClientContactsReducer,
	timeOffList:TimeOffListReducer,
	monthTotals:MonthTotalsReducer,
	yearTotals:YearTotalsReducer,
	actuals:ActualsReducer,
	permissions:PermissionsReducer,
	permissionsWrite:PermissionsWriteReducer,
	views:ViewsReducer,
	undoStack:UndoStackReducer,
	backups:BackupsReducer
})


