import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./ProjectPortalInput.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { forceDecimal, forceNumeric, forcePercentage, padDecimal, prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { saveProjectAttributeChange, saveProjectPlannedHoursChange } from "../../../Utils/SaveProjectFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { UndoStackEntry } from "../../../Utils/UndoStackEntry";
import { Client } from "../../../FirebaseModel/Client";
interface ProjectPortalInputProps{
	className?:string,
	project:Project,
	attributeKey?:string,
	roleID?:string,
	forceNumeric?:boolean,
	forcePercentage?:boolean,
	isCurrency?:boolean
}

export default function ProjectPortalInput(props:ProjectPortalInputProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

		const organization = useSelector((state:{organization:Organization}) => state.organization)
		const clients = useSelector((state:{clients:Client[]}) => state.clients)
		const roles = useSelector((state:{roles:Role[]}) => state.roles)

	const [defaultValue, setDefaultValue] = useState("");

	useEffect(() =>{
		if(props.attributeKey){
			if(props.isCurrency){
				if((props.project as any)[props.attributeKey] == undefined){
					setDefaultValue(padDecimal("$0",2));
				}else{
					setDefaultValue(padDecimal(prettyCurrency((props.project as any)[props.attributeKey] as number),2))
				}
			}else if(props.forcePercentage){
				if((props.project as any)[props.attributeKey] == undefined){
					setDefaultValue("0.00%");
				}else{
					setDefaultValue(padDecimal(prettyNum((props.project as any)[props.attributeKey] as number * 100),2) + "%")
				}
			}else{
				setDefaultValue((props.project as any)[props.attributeKey])
			}
		}else if(props.roleID){

			let found = false;

			for(var i in props.project.plannedHours){
				let role = props.project.plannedHours[i];
				if(role.roleID == props.roleID!){
					found = true;
					setDefaultValue(padDecimal(prettyNum(role.hours),2));
				}
			}

			if(!found){
				setDefaultValue("0.00");
			}
		}
	},[props.project,props.attributeKey,props.roleID,props.isCurrency])

  return (
    <input className={`ProjectPortalInput ${props.className??""} ${props.attributeKey??""} ${props.roleID?"role":""}`}
			onBlur={(event) =>{

				if(props.forceNumeric && event.target.value ==""){
					event.target.value = "0";
				}

				if(props.forcePercentage){
					let percentage = parseFloat(event.target.value.replace("%","").replace(/,/g,"")) / 100
					event.target.value = Math.round(percentage * 10000) / 100 + "%";
				}

				if(props.isCurrency){
					event.target.value = padDecimal(prettyCurrency(event.target.value),2);
				}

				if(props.attributeKey){
					if(props.forceNumeric){
						saveProjectAttributeChange(props.project,organization,clients,props.attributeKey,parseFloat(event.target.value.replace("$","").replace(/,/g,"")),(entry:UndoStackEntry) =>{
							AC.submitUndoStackEntry(entry);
						});
					}else if(props.forcePercentage){
						let percentage = parseFloat(event.target.value.replace("%","").replace(/,/g,"")) / 100
						saveProjectAttributeChange(props.project,organization,clients,props.attributeKey,percentage,(entry:UndoStackEntry) =>{
							AC.submitUndoStackEntry(entry);
						});
					}else{
						saveProjectAttributeChange(props.project,organization,clients,props.attributeKey,event.target.value,(entry:UndoStackEntry) =>{
							AC.submitUndoStackEntry(entry);
						});
					}
				}else if(props.roleID){
					saveProjectPlannedHoursChange(props.project,props.roleID,roles,organization!,clients,parseFloat(event.target.value.replace("$","").replace(/,/g,"")),(entry:UndoStackEntry) =>{
						AC.submitUndoStackEntry(entry);
					});
				}

			}}
			onFocus={(event)=>{
				let input = event.target as HTMLInputElement
				input.setSelectionRange(0,input.value.length)
			}}
			onKeyPress={(event) =>{
				if(props.forceNumeric){
					forceDecimal(event);
				}
				if(props.forcePercentage){
					forcePercentage(event);
				}
			}}
			defaultValue={defaultValue}>
    </input>
  )
}