
import React, { useEffect, useState } from "react"
import "./ReportProfitSharing.css"
import { useSelector } from "react-redux";
import { Organization } from "../../FirebaseModel/Organization";
import SoldVSBilledTable from "../../Components/SoldVSBilled/SoldVSBilledTable/SoldVSBilledTable";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import IndividualProfitSharingTable from "../../Components/IndividualProfitSharing/IndividualProfitSharingTable/IndividualProfitSharingTable";
interface ReportProfitSharingProps{

	filterStartYear:string,
	onDismiss:()=>void;
}

export default function ReportProfitSharing(props:ReportProfitSharingProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
 

	const [tableOffsetTop, setTableOffsetTop] = useState<number>(0);
	const tableRef = React.createRef<HTMLDivElement>();
	const resize = () =>{
		setTableOffsetTop(tableRef.current!.offsetTop);
	}

	useEffect(() => {
		let table = tableRef.current!;
		if(table){
			const overrideWheel = (e: WheelEvent) => {
				if(table.scrollLeft === 0 && e.deltaX < 0){
					e.preventDefault();
				}
			};
			table.addEventListener("wheel", overrideWheel);
			return () => {
				table.removeEventListener("wheel", overrideWheel);
			};
		}
	}, [tableRef]);
	
	const [year, setYear] = useState<string>(props.filterStartYear);
	const [personID, setPersonID] = useState<string>("");
	


	useEffect(() =>{
		window.addEventListener("resize", resize);
		resize();
		return () =>{
			window.removeEventListener("resize", resize);
		}
	},[])

  return (
		<>
	    <div className={`report ReportProfitSharing`}>
				<h1>Individual Profit Sharing ({year})</h1>

			<div className="filters">
				<h3>Parameters</h3>
				<div className="row">
					<div className="col">
						<div className="label">Person</div>
						<PersonDropdown isBillable={true}
							onChange={(personID) =>{
								setPersonID(personID);
							}}></PersonDropdown>
					</div>
					<div className="col">
						<div className="label">Year</div>
						<select value={year}
							onChange={(event) =>{
								setYear(event.target.value)
							}}>
							{(() =>{
								let years = [];
								let currentYear = new Date().getFullYear();
								for(let i = currentYear; i > currentYear - 30; i--){
									years.push(<option key={`year_${i}`} value={i}>{i}</option>)
								}
								return years;
							})()}
						</select>
					</div>
				</div>
			</div>

				<div className="content"
					ref={tableRef}
					style={{maxHeight:`calc(100vh - ${tableOffsetTop}px)`}}>
					{personID != "" && (
						<IndividualProfitSharingTable 
							year={parseInt(year)} 
							personID={personID}></IndividualProfitSharingTable>
					)}
				</div>
				<div className="floatingButtons">
					<div className="buttonSave"
						onClick={() =>{
							props.onDismiss();
						}}>Done</div>
					</div>
			</div>
		</>
  )
}