import { addDoc, collection, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { Client } from "../FirebaseModel/Client";
import { BSPColumn, BlankBSPColumn, MonthTotal } from "../FirebaseModel/MonthTotal";
import { Organization } from "../FirebaseModel/Organization";
import { Role } from "../FirebaseModel/Role";
import { sharedFirestore } from "./SharedFirebase";
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from "./UndoStackEntry";
import { YearTotal } from "../FirebaseModel/YearTotal";


export function getOrCreateYearTotal(year:number, allYearTotals:YearTotal[]|null, organization:Organization,undoEntryHandler:(entry:UndoStackEntry)=>void):Promise<YearTotal>{
	return new Promise<YearTotal>((resolve,reject) =>{
		if(allYearTotals){
			for(var i in allYearTotals){
				if(allYearTotals[i].year == year){
					resolve(allYearTotals[i]);
					return;
				}
			}
		}

		let blankBSP:BSPColumn = {
			... BlankBSPColumn
		}

		let newYearTotal = {
			year: year,
			organizationID: organization.id,
			calculated: {
				actualHoursByRole: [],
				estimatedHoursByRole: [],
				totalHoursBillable: 0,
				totalHoursNonBillable: 0,
				totalPTOHours: 0,
				totalProductionBudget: 0,
				totalSFClosedWon: 0,
				totalSTOHours: 0,
				averageBSP:{
					...blankBSP
				},
				averageBSP_3Months:{
					...blankBSP
				},
				averageBSP_6Months:{
					...blankBSP
				}
			}
		};


		addDoc(collection(sharedFirestore, "YearTotal"), newYearTotal).then((docRef) => {
			let yearTotal = {...newYearTotal, id:docRef.id} as YearTotal
			let undoEntry:UndoStackEntry = {
				type: UndoStackEntryType.create,
				objectType: UndoStackEntryObjectType.yearTotal,
				objectID: yearTotal.id,
				objectBeforeState: undefined,
				objectAfterState: {...yearTotal},
				description: "Create Month Total"
			}
			undoEntryHandler(undoEntry);
			resolve(yearTotal);
		}).catch((error) => {
			reject(error);
		});
	});
}

export function saveYearTotalAttributeChange(yearTotal:YearTotal,  attributeKey:string|string[], value:any|any[],undoEntryHandler:(entry:UndoStackEntry)=>void){


	const projectRef = doc(sharedFirestore, "YearTotal", yearTotal.id);

	let updatedYearTotal:{ [x: string]: any; } = {};
	for(var key in yearTotal){
		if(attributeKey instanceof Array){
			for(var key2 in attributeKey){
				if(key == attributeKey[key2]){
					updatedYearTotal[key] = value[key2];
					break;
				}else{
					updatedYearTotal[key] = (yearTotal as any)[key];
				}
			}
		}else{
			if(attributeKey){
				if(key == attributeKey){
					updatedYearTotal[key] = value;
				}else{
					updatedYearTotal[key] = (yearTotal as any)[key];
				}
			}else{
				updatedYearTotal[key] = (yearTotal as any)[key];
			}
		}
	}

	let undoEntry:UndoStackEntry = {
		type: UndoStackEntryType.update,
		objectType: UndoStackEntryObjectType.yearTotal,
		objectID: yearTotal.id,
		objectBeforeState: {...yearTotal},
		objectAfterState: {...updatedYearTotal},
		description: "Update Year Total"
	}
	undoEntryHandler(undoEntry);
	for(var key in yearTotal){
		(yearTotal as any)[key] = updatedYearTotal[key];
	}

	return updateDoc(projectRef,updatedYearTotal)
}




export function deleteYearTotal(yearTotal:YearTotal,undoEntryHandler:(entry:UndoStackEntry)=>void){
	let undoEntry:UndoStackEntry = {
		type: UndoStackEntryType.delete,
		objectType: UndoStackEntryObjectType.yearTotal,
		objectID: yearTotal.id,
		objectBeforeState: {...yearTotal},
		objectAfterState: undefined,
		description: "Delete Year Total"
	}
	undoEntryHandler(undoEntry);
	return deleteDoc(doc(sharedFirestore, "YearTotal", yearTotal.id));
}