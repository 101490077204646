
import "./NavigationButtons.css"
import { useDispatch, useSelector } from "react-redux";
import { faGear, faPeopleGroup, faAddressCard, faHouse, faBarChart, faPieChart, faCalendar, faFileImport, faSignOut, faClipboardCheck, faClipboardList, faClock, faHome, faSackDollar, faFilterCircleDollar, faDollar, faBars, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Screens } from "../../Redux/Navigation";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { Tooltip } from "@mui/material";
import { sharedFirebaseApp, sharedFirebaseAuth } from "../../Utils/SharedFirebase";
import { Person } from "../../FirebaseModel/Person";
import { useState } from "react";

interface NavigationButtonsProps {
	loginButtonClass?: string;
}

export default function NavigationButtons(props: NavigationButtonsProps) {
	const currentScreen = useSelector((state: { screen: Screens }) => state.screen)
	const screenStack = useSelector((state: { screenStack: Screens[] }) => state.screenStack)

	const backDescription = () => {
		switch (screenStack[screenStack.length - 2]) {
			case Screens.Home:
				return "Home Page";
			case Screens.ProjectPortal:
				return "Project Portal";
			case Screens.ProjectProfitability:
				return "Project Profitability";
			case Screens.Organization:
				return "Organization Portal";
			case Screens.Import:
				return "Import Portal";
			case Screens.Team:
				return "Team Portal";
			case Screens.Clients:
				return "Client Portal";
			case Screens.ProductionSchedule:
				return "Production Schedule";
			case Screens.Timesheet:
				return "Timesheet";
			case Screens.IndividualProfitSharing:
				return "Profit Sharing";
			case Screens.Reports:
				return "Reports";
			default:
				return "";
		}
	}

	const organization = useSelector((state: { organization: Organization }) => state.organization)
	const roles = useSelector((state: { roles: Role[] }) => state.roles)
	const permissions = useSelector((state: { permissions: string[] }) => state.permissions)
	const permissionsWrite = useSelector((state: { permissionsWrite: string[] }) => state.permissionsWrite)
	const persons = useSelector((state: { persons: Person[] }) => state.persons)

	const [menuOpen, setMenuOpen] = useState<boolean>(false);

	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		popScreen: ActionCreators.popScreen
	}, dispatch);

	let isPersonBillable = false;
	if (sharedFirebaseAuth.currentUser) {
		const person = persons.find(person => person.email.toLowerCase() === sharedFirebaseAuth.currentUser!.email!)!;
		if (person && person.roles) {
			for (var role of person.roles!) {
				let otherRoles = roles.filter((role: Role) => role.isBillable == true);
				for (var otherRole of otherRoles) {
					if (role.roleID == otherRole.id) {
						isPersonBillable = true;
						break;
					}
				}
				if (isPersonBillable) {
					break;
				}
			}
		}
	}
	const [personBillable, setPersonBillable] = useState<boolean>(isPersonBillable);

	return (
		<>
			{/* {screenStack.length > 1 && (
				<Tooltip title={backDescription()} >
					<div className="buttonNavigateBack" onClick={() => {
						AC.popScreen(screenStack);
					}}><FontAwesomeIcon icon={faChevronLeft} /></div>
				</Tooltip>
			)} */}
			<div className={`NavigationButtons ${menuOpen ? "open" : ""}`}>
				{/* <div className="buttonMenu" onClick={() => {
					setMenuOpen(!menuOpen);
				}}><FontAwesomeIcon icon={faBars}></FontAwesomeIcon></div> */}
				<div className='lightviewLogo'>
					<div className="label">LightView</div>
				</div>

				<Tooltip title="Home/Dashboard" placement="bottom">
					<div className={`buttonHome ${currentScreen == Screens.Home?"selected":""} button`} onClick={() => {
						AC.setScreen(Screens.Home)
					}}><div className='label'>Home/Dashboard</div></div>
				</Tooltip>

				<div className="spacer"></div>

				{(permissions.indexOf("Projects") != -1) && (
					<Tooltip title="Project Portal" placement="bottom">
						<div className={`buttonProject ${currentScreen == Screens.ProjectPortal?"selected":""} button`} onClick={() => {
							AC.setScreen(Screens.ProjectPortal)
						}}><div className='label'>Projects</div></div>
					</Tooltip>
				)}
				{(permissions.indexOf("Projects") != -1) && (
					<Tooltip title="Project Profitability" placement="bottom">
						<div className={`buttonProfitability ${currentScreen == Screens.ProjectProfitability?"selected":""} button`} onClick={() => {
							AC.setScreen(Screens.ProjectProfitability)
						}}><div className="label">Project Profitability</div></div>
					</Tooltip>
				)}

				{((permissions.indexOf("Projects") != -1) ||
					(permissions.indexOf("Projects") != -1)) && (
						<div className="spacer"></div>
					)}

				{(permissions.indexOf("Production Schedule") != -1) && (
					<Tooltip title="Production Schedule" placement="bottom">
						<div className={`buttonProductionSchedule ${currentScreen == Screens.ProductionSchedule?"selected":""} button`} onClick={() => {
							AC.setScreen(Screens.ProductionSchedule)
						}}><div className='label'>Produciton Schedule</div></div>
					</Tooltip>
				)}

				{(permissions.indexOf("Personal Timesheet") != -1) && (
					<Tooltip title="Timesheet" placement="bottom">
						<div className={`buttonTimesheet ${currentScreen == Screens.Timesheet?"selected":""} button`} onClick={() => {
							AC.setScreen(Screens.Timesheet)
						}}><div className='label'>Time Sheet</div></div>
					</Tooltip>
				)}

				{((permissions.indexOf("Production Schedule") != -1) ||
					(permissions.indexOf("Personal Timesheet") != -1)) && (
						<div className="spacer"></div>
					)}

				{(permissions.indexOf("Profit Sharing") != -1 && organization && organization.offersProfitSharing && isPersonBillable) && (
					<Tooltip title="Profit Sharing" placement="bottom">
						<div className={`buttonProfitSharing ${currentScreen == Screens.IndividualProfitSharing?"selected":""} button`} onClick={() => {
							AC.setScreen(Screens.IndividualProfitSharing)
						}}><div className='label'>Profit Sharing</div></div>
					</Tooltip>
				)}
				{(permissions.indexOf("Reports") != -1) && (
					<Tooltip title="Reports" placement="bottom">
						<div className={`buttonReports ${currentScreen == Screens.Reports?"selected":""} button`} onClick={() => {
							AC.setScreen(Screens.Reports)
						}}><div className="label">Reports</div></div>
					</Tooltip>
				)}

				{((permissions.indexOf("Profit Sharing") != -1) ||
					(permissions.indexOf("Reports") != -1)) && (
						<div className="spacer"></div>
					)}


				{(permissions.indexOf("Organization") != -1) && (
					<Tooltip title="Organization Portal" placement="bottom">
						<div className={`buttonOrganization ${currentScreen == Screens.Organization?"selected":""} button`} onClick={() => {
							AC.setScreen(Screens.Organization)
						}}><div className="label">My Organization</div></div>
					</Tooltip>
				)}

				{(permissionsWrite.indexOf("Organization") != -1) && (
					<Tooltip title="Import Portal" placement="bottom">
						<div className={`buttonImport ${currentScreen == Screens.Import?"selected":""} button`} onClick={() => {
							AC.setScreen(Screens.Import)
						}}><div className="label">Import</div></div>
					</Tooltip>
				)}

				{(permissions.indexOf("Team") != -1) && (
					<Tooltip title="Team Portal" placement="bottom">
						<div className={`buttonTeam ${currentScreen == Screens.Team?"selected":""} button`} onClick={() => {
							AC.setScreen(Screens.Team)
						}}><div className="label">My Team</div></div>
					</Tooltip>
				)}

				{(permissions.indexOf("Clients") != -1) && (
					<Tooltip title="Client Portal" placement="bottom">
						<div className={`buttonClients ${currentScreen == Screens.Team?"selected":""} button`} onClick={() => {
							AC.setScreen(Screens.Clients)
						}}><div className="label">My Clients</div></div>
					</Tooltip>
				)}

			</div>

			<Tooltip title="Log out" placement="bottom">
					<div className={`buttonLogout ${props.loginButtonClass??""}`} onClick={() => {
						sharedFirebaseAuth.signOut();
					}}></div>
				</Tooltip>
		</>
	)
}


