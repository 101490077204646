
import React, { ReactNode, useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./TeamScreenPeopleRow.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { sharedFirebaseAuth, sharedFirestore } from "../../../Utils/SharedFirebase";
import { Role, rolePersonsDescription } from "../../../FirebaseModel/Role";
import { Person, personCalculatedRoleWeightsFiltered, personRolesDescription } from "../../../FirebaseModel/Person";
import { deleteRole, saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { faAnchor, faBars, faCalendar, faCheck, faCircleNodes, faHamburger, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { Tooltip } from "@mui/material";
import { savePersonAttributeChange, deletePerson } from "../../../Utils/SavePersonFunctions";
import TeamScreenPeopleInput from "../TeamScreenPeopleInput/TeamScreenPeopleInput";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
export interface TeamScreenPeopleRowProps{
	person:Person;
	filterStartMonth:string;
	filterEndMonth:string;
	filterStartYear:string;
	filterEndYear:string;
	onEditPersonPressed:(person:Person) => void;
	onPersonTimesheetPressed:(person:Person) => void;
}

export default function TeamScreenPeopleRow(props:TeamScreenPeopleRowProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const allRoles = useSelector((state:{roles:Role[]}) => state.roles)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
	const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)
	const permissions = useSelector((state:{permissions:string[]}) => state.permissions)


	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);
		
	const [roles, setRoles] = useState<Role[]>(allRoles);

	useEffect(() =>{
		setRoles([... allRoles]);
	},[allRoles]);
	
	return (
	<>
		{permissionsWrite.indexOf("Team")>=0 ? (
			<tr key={`person_${props.person.id}`} className={`TeamScreenPeopleRow ${props.person.active?"active":"inactive"}`}>
				<td>
					<Tooltip title={`Edit`} placement="bottom">
						<div className="buttonEdit"
							onClick={() =>{
								props.onEditPersonPressed(props.person);
							}}><FontAwesomeIcon icon={faPencil}></FontAwesomeIcon></div>
					</Tooltip>
					{permissions.indexOf("Organization Timesheets")!=-1 && (
						<Tooltip title={`Timesheet for ${props.person.name}`} placement="bottom">
							<div className="buttonTimesheet"
								onClick={() =>{
									props.onPersonTimesheetPressed(props.person);
								}}><FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon></div>
						</Tooltip>
					)}
				</td>
				<td>
					<input type='checkbox' checked={props.person.active} onChange={(event) =>{
						savePersonAttributeChange(props.person,"active",event.target.checked,(entry) =>{
							AC.submitUndoStackEntry(entry);
						});
					}}></input>
				</td>
				<td><TeamScreenPeopleInput attributeKey="name" person={props.person}></TeamScreenPeopleInput></td>
				<td><TeamScreenPeopleInput attributeKey="email" person={props.person}></TeamScreenPeopleInput></td>
				<td><TeamScreenPeopleInput attributeKey="phone" person={props.person}></TeamScreenPeopleInput></td>
				<td className="personRoles">
					<Tooltip title={personRolesDescription(props.person,roles)} placement="bottom">
						<span>{personRolesDescription(props.person,roles)}</span>
					</Tooltip>					
				</td>
				<td className="personCalculatedRoles">
					<Tooltip title={personCalculatedRoleWeightsFiltered(props.person,roles,actuals,props.filterStartMonth,props.filterEndMonth,props.filterStartYear,props.filterEndYear)} placement="bottom">
						<span>{personCalculatedRoleWeightsFiltered(props.person,roles,actuals,props.filterStartMonth,props.filterEndMonth,props.filterStartYear,props.filterEndYear)}</span>
					</Tooltip>
				</td>
				<td><TeamScreenPeopleInput attributeKey="birthday" className="date" isDate={true} person={props.person}></TeamScreenPeopleInput></td>
				<td><TeamScreenPeopleInput attributeKey="startDate" className="date" isDate={true} person={props.person}></TeamScreenPeopleInput></td>
				<td><TeamScreenPeopleInput attributeKey="endDate" className="date" disabled={props.person.active} isDate={true} person={props.person}></TeamScreenPeopleInput></td>
				<td>{props.person.startDate!="" &&(
					<>
					{props.person.endDate!=""?(
						Math.round((new Date(props.person.endDate).getTime() - new Date(props.person.startDate).getTime()) / (1000*60*60*24*365) *10)/10 + " yrs"
					):(

						Math.round((new Date().getTime() - new Date(props.person.startDate).getTime()) / (1000*60*60*24*365) *10)/10 + " yrs"
					)}
					</>
				)}</td>
				<Tooltip title={`Delete`} placement="bottom">
					<div className="buttonDelete"
						onClick={() =>{
							if(window.confirm("Are you sure you'd like to delete this person?")){
								deletePerson(props.person,(entry) =>{
									AC.submitUndoStackEntry(entry);
								});
							}
						}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
				</Tooltip>
			</tr>
		):(
			<tr key={`person_${props.person.id}`} className={`TeamScreenPeopleRow ${props.person.active?"active":"inactive"}`}>
				<td>	
					<Tooltip title={`Timesheet for ${props.person.name}`} placement="bottom">
						<div className="buttonTimesheet"
							onClick={() =>{
								props.onPersonTimesheetPressed(props.person);
							}}><FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon></div>
					</Tooltip>
				</td>
				<td>
					{props.person.active && <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}
				</td>
				<td>{props.person.name}</td>
				<td>{props.person.email}</td>
				<td className="personRoles">
					{personRolesDescription(props.person,roles)}
				</td>
				<td className="personCalculatedRoles">
					{personCalculatedRoleWeightsFiltered(props.person,roles,actuals,props.filterStartMonth,props.filterEndMonth,props.filterStartYear,props.filterEndYear)}
				</td>
				<td>{props.person.birthday}</td>
				<td>{props.person.startDate}</td>
				<td>{props.person.endDate}</td>
				<td>{props.person.startDate!="" &&(
					<>
					{props.person.endDate!=""?(
						Math.round((new Date(props.person.endDate).getTime() - new Date(props.person.startDate).getTime()) / (1000*60*60*24*365) *10)/10 + " yrs"
					):(
	
						Math.round((new Date().getTime() - new Date(props.person.startDate).getTime()) / (1000*60*60*24*365) *10)/10 + " yrs"
					)}
					</>
				)}</td>
			</tr>
		)}
	</>
  )
}