

import "./IndividualProfitSharingRow.css"
import { useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { Person } from "../../../FirebaseModel/Person";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { useEffect, useState } from "react";
import { Project } from "../../../FirebaseModel/Project";
import { Client } from "../../../FirebaseModel/Client";
import { MonthTotal } from "../../../FirebaseModel/MonthTotal";
import { prettyCurrency, prettyNum } from "../../../Utils/formatting";
interface IndividualProfitSharingRowProps{
	clientName:string,
	projectNumber:string,
	projectName:string,
	role:string,
	hoursAvailable:number,
	hoursCredited:number,
	quarterlyBonus:number,
	hoursActual:number,
	hoursActualDifferentPerson:number,
	dateCompleted:string,
	notes:string,
}



export default function IndividualProfitSharingRow(props:IndividualProfitSharingRowProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const roles = useSelector((state:{roles:Role[]}) => state.roles)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const projects = useSelector((state:{projects:Project[]}) => state.projects)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)


  return (
			<tr className={`IndividualProfitSharingRow`}>
				<td>{props.clientName}</td>
				<td>{props.projectNumber}</td>
				<td>{props.projectName}</td>
				<td>{props.role}</td>
				<td>{prettyNum(props.hoursAvailable)}</td>
				<td>{prettyNum(props.hoursCredited)}</td>
				<td>{prettyCurrency(props.quarterlyBonus)}</td>
				<td>{prettyNum(props.hoursActual)}</td>
				<td>{props.dateCompleted}</td>
				<td>{props.notes}</td>
			</tr>
  )
}