import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { Project } from "../FirebaseModel/Project";
import { ProjectRoleActualHours } from "../FirebaseModel/ProjectRoleActualHours";
import { deleteProjectRoleActualHours, saveProjectRoleActualHours } from "./SaveProjectRoleActualFunctions";
import { sharedFirestore } from "./SharedFirebase";
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from "./UndoStackEntry";
import { Role } from "../FirebaseModel/Role";
import { Organization } from "../FirebaseModel/Organization";
import { Client } from "../FirebaseModel/Client";

export function saveProjectAttributeChange(project:Project, organization:Organization, clients:Client[],  attributeKey:string|string[], value:any|any[],undoEntryHandler:(entry:UndoStackEntry)=>void){


	const projectRef = doc(sharedFirestore, "Project", project.id);

	let updatedProject:{ [x: string]: any; } = {};
	if(attributeKey instanceof Array){
		for(var key2 in attributeKey){
			if((updatedProject as any)[attributeKey[key2] ] === undefined){
				(updatedProject as any)[attributeKey[key2] ] = value[key2];
			}
		}
	}else{
		if((updatedProject as any)[attributeKey ] === undefined){
			(updatedProject as any)[attributeKey ] = value;
		}
	}
	for(var key in project){
		if(attributeKey instanceof Array){
			for(var key2 in attributeKey){
				if(key == attributeKey[key2]){
					updatedProject[key] = value[key2];
					break;
				}else{
					updatedProject[key] = (project as any)[key];
				}
			}
		}else{
			if(attributeKey){
				if(key == attributeKey){
					updatedProject[key] = value;
				}else{
					updatedProject[key] = (project as any)[key];
				}
			}else{
				updatedProject[key] = (project as any)[key];
			}
		}
	}


	
	let hourlyRate = organization.hourlyRate;
	let client = clients.find((client:Client) => client.id == updatedProject.clientID);
	if(client !=null && client.useOverrideHourlyRate){
		hourlyRate = client.overrideHourlyRate;
	}
	if(updatedProject.useOverrideHourlyRate){
		hourlyRate = updatedProject.overrideHourlyRate;
	}
	let budget = updatedProject.calculated.plannedHours * hourlyRate;
	updatedProject.calculated.currentPlusMinus = (updatedProject.pmEstimatedPercentComplete*budget) - (updatedProject.calculated.actualHours * hourlyRate);

	updatedProject.calculated.differenceActualToBilled = updatedProject.calculated.totalInvoiced - (updatedProject.amPercentToInvoice*updatedProject.sfClosedWon);

	updatedProject.roleIDs = [] as string[];
	for(var i in updatedProject.plannedHours){
		let role = updatedProject.plannedHours[i];
		updatedProject.roleIDs.push(role.roleID);
	}

	let beforeState = {...project};
	let afterState = {...updatedProject};
	let event:UndoStackEntry = {
		type: UndoStackEntryType.update,
		objectType: UndoStackEntryObjectType.project,
		objectID: project.id,
		objectBeforeState: beforeState,
		objectAfterState: afterState,
		description: "Update Project"
	}
	undoEntryHandler(event);
	return updateDoc(projectRef,updatedProject)
}


export function saveProjectPlannedHoursChange(project:Project, roleID:string, roles:Role[], organization:Organization, clients:Client[], value:number,undoEntryHandler:(entry:UndoStackEntry)=>void){


	const projectRef = doc(sharedFirestore, "Project", project.id);

	let updatedProject:{ [x: string]: any; } = {};
	for(var key in project){
		updatedProject[key] = (project as any)[key];
	}

	let found = false;

	for(var i in updatedProject.plannedHours){
		let role = updatedProject.plannedHours[i];
		if(role.roleID == roleID!){
			found = true;
			role.hours = value
		}
	}

	if(!found && roleID != ""){
		updatedProject.plannedHours.push({
			roleID:roleID!,
			hours:value
		})
	}

	let total = 0;
	for(var i in updatedProject.plannedHours){
		let role = updatedProject.plannedHours[i];
		let otherRole = roles.find((r)=>r.id == role.roleID);
		if(otherRole && otherRole.isBillable){
			total += role.hours;
		}
	}
	updatedProject.calculated.plannedHours = total;
	
	let hourlyRate = organization.hourlyRate;
	let client = clients.find((client:Client) => client.id == updatedProject.clientID);
	if(client !=null && client.useOverrideHourlyRate){
		hourlyRate = client.overrideHourlyRate;
	}
	if(updatedProject.useOverrideHourlyRate){
		hourlyRate = updatedProject.overrideHourlyRate;
	}
	let budget = updatedProject.calculated.plannedHours * hourlyRate;
	updatedProject.calculated.currentPlusMinus = (updatedProject.pmEstimatedPercentComplete*budget) - (updatedProject.calculated.actualHours * hourlyRate);

	updatedProject.calculated.differenceActualToBilled = updatedProject.calculated.totalInvoiced - (updatedProject.amPercentToInvoice*updatedProject.sfClosedWon);

	updatedProject.roleIDs = [] as string[];
	for(var i in updatedProject.plannedHours){
		let role = updatedProject.plannedHours[i];
		updatedProject.roleIDs.push(role.roleID);
	}

	let beforeState = {...project};
	let afterState = {...updatedProject};
	let event:UndoStackEntry = {
		type: UndoStackEntryType.update,
		objectType: UndoStackEntryObjectType.project,
		objectID: project.id,
		objectBeforeState: beforeState,
		objectAfterState: afterState,
		description: "Update Project"
	}
	undoEntryHandler(event);
	return updateDoc(projectRef,updatedProject)
}


export async function deleteProject(project:Project, actuals:ProjectRoleActualHours[],undoEntryHandler:(entry:UndoStackEntry)=>void){
	undoEntryHandler({
		type: UndoStackEntryType.batchStart,
		objectType: UndoStackEntryObjectType.project,
		objectID: project.id,
		objectBeforeState: undefined,
		objectAfterState: undefined,
		description: "Delete Project"
	})
	for(let i in actuals){
		if(actuals[i].projectID == project.id){
			saveProjectRoleActualHours(actuals[i], "projectID", "", undoEntryHandler);
			//await deleteProjectRoleActualHours(actuals[i]);
		}
	}
	undoEntryHandler({
		type: UndoStackEntryType.delete,
		objectType: UndoStackEntryObjectType.project,
		objectID: project.id,
		objectBeforeState: {...project},
		objectAfterState: undefined,
		description: "Delete Project"
	})
	undoEntryHandler({
		type: UndoStackEntryType.batchEnd,
		objectType: UndoStackEntryObjectType.project,
		objectID: project.id,
		objectBeforeState: undefined,
		objectAfterState: undefined,
		description: "Delete Project"
	})
	return deleteDoc(doc(sharedFirestore, "Project", project.id));
}