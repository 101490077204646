
import React, { useEffect, useState } from "react"
import { sharedFirestore } from "../../Utils/SharedFirebase"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ClientsScreen.css"
import { Project } from "../../FirebaseModel/Project";
import { useDispatch, useSelector } from "react-redux";
import { faGear, faHouse, faPeopleGroup, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators } from "redux";
import { Organization } from "../../FirebaseModel/Organization";
import ActionCreators from "../../Redux/ActionCreators";
import { Screens } from "../../Redux/Navigation";
import TeamScreenPeopleTable from "../../Components/TeamScreen/TeamScreenPeopleTable/TeamScreenPeopleTable";
import { Person } from "../../FirebaseModel/Person";
import PopupAddPerson from "../../Popups/PopupAddPerson/PopupAddPerson";
import PopupWeeklyTimesheet from "../../Popups/PopupWeeklyTimesheet/PopupWeeklyTimesheet";
import ClientsScreenClientsTable from "../../Components/ClientsScreen/ClientsScreenClientsTable/ClientsScreenClientsTable";
import { Client } from "../../FirebaseModel/Client";
import PopupAddClient from "../../Popups/PopupAddClient/PopupAddClient";
import NavigationButtons from "../../Components/NavigationButtons/NavigationButtons";
interface ClientsScreenProps{
}

let slowSearchTimeout:NodeJS.Timeout|null = null;

export default function ClientsScreen(props:ClientsScreenProps){
  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setScreen:ActionCreators.setScreen},dispatch);


	let organization = useSelector((state:{organization:Organization|null}) => state.organization);
	const inputSearchRef = React.useRef<HTMLInputElement>(null);
	const [selectedClient, setSelectedClient] = useState<Client|null>(null);
	const [filterSearch, setFilterSearch] = useState(localStorage.getItem("clientsScreenFilterSearch")??"");
	useEffect(() =>{
		localStorage.setItem("clientsScreenFilterSearch", filterSearch);
	}, [filterSearch]);
	
	const [showingClientPopup, setShowingClientPopup] = useState(false)

  return (
    <>
    <div className="screen ClientsScreen">
      <h1>Client Portal</h1>

			<NavigationButtons />

			<div className="buttonAdd" onClick={() =>{
				setShowingClientPopup(true);
			}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Client</div>


			<div className="filters">
				<h3>Filter</h3>
				<div className="row">
					<div className="col">
						<div className="label">search</div>
						<div className="row">
							<input type='text' 
								defaultValue={filterSearch}
								ref={inputSearchRef}
								className="search"
								onChange={(event) =>{
									if(slowSearchTimeout != null){
										clearTimeout(slowSearchTimeout);
									}
									slowSearchTimeout = setTimeout(() =>{
										setFilterSearch(event.target.value)
									}, 500);
								}}>
							</input>
							<div className='searchIcon'>
								<FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
							</div>
						</div>
					</div>
						<div className="col">
							<div className="label">Clear Filters</div>
							<div className='buttonClearFilters'
								onClick={() => {
									setFilterSearch("");
									inputSearchRef.current!.value = "";
								}}>
								Clear
							</div>
						</div>
				</div>
			</div>
			<ClientsScreenClientsTable
				filterSearch={filterSearch}
				onEditClientPressed={(client:Client) =>{
					setSelectedClient(client);
					setShowingClientPopup(true);
				}}
				// onClient={(person:Person) =>{
				// 	setSelectedPerson(person);
				// 	setShowingPersonTimesheetPopup(true);
				// }}
				// onEditPersonPressed={(person:Person) =>{
				// 	setSelectedPerson(person);
				// 	setShowingPersonPopup(true);
				// }}
				></ClientsScreenClientsTable>

    </div>
			{showingClientPopup && (
				<PopupAddClient 
					allowMultiple={true}
					client={selectedClient??undefined}
					onCancel={() =>{
						setSelectedClient(null);
						setShowingClientPopup(false);			
					}} onSave={(client) =>{
						setSelectedClient(null);
						setShowingClientPopup(false);	
					}}></PopupAddClient>
			)}

    </>
  )
}