


import React, { ReactNode, useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ClientProjectsTable.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { Role } from "../../../FirebaseModel/Role";
import { Person, personCalculatedRoleWeights, personCalculatedRoleWeightsFiltered, personRolesDescription } from "../../../FirebaseModel/Person";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faPencil, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { deletePerson, savePersonAttributeChange } from "../../../Utils/SavePersonFunctions";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { Tooltip } from "@mui/material";
import { Client } from "../../../FirebaseModel/Client";
import { deleteProject } from "../../../Utils/SaveProjectFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
interface ClientProjectsTableProps{
	client:Client,
	onEditProjectPressed:(project:Project)=>void
}

let slowSearchTimeout:NodeJS.Timeout|null = null;

export default function ClientProjectsTable(props:ClientProjectsTableProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const projects = useSelector((state:{projects:Project[]}) => state.projects)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)

	const [fitleredSortedProjects, setFilteredSortedProjects] = useState<Project[]>([]);
	const [filterEndYear, setFilterEndYear] = useState<string>("");
	const [filterStartYear, setFilterStartYear] = useState<string>("");
	const [filterStartMonth, setFilterStartMonth] = useState<string>("");
	const [filterEndMonth, setFilterEndMonth] = useState<string>("");
	const [filterSearch, setFilterSearch] = useState<string>("");
	const [filterStatus, setFilterStatus] = useState<string>("");

	useEffect(() =>{
		let tempProjects:Project[] = [];
		projects.forEach(proj =>{

			if(proj.clientID !== props.client.id){
				return;
			}

			// if the a filter is not "" then filter the projects based on filters and month and year closed won
			if((filterStartYear !== "" && proj.yearClosedWon < parseInt(filterStartYear))){
				return;
			}
			if(filterEndYear !== "" && proj.yearClosedWon > parseInt(filterEndYear)){
				return;
			}
			if(filterStartMonth !== "" && proj.monthClosedWon < parseInt(filterStartMonth) &&
				(filterStartYear === "" || proj.yearClosedWon <= parseInt(filterStartYear))){
				return;
			}
			if(filterEndMonth !== "" && proj.monthClosedWon > parseInt(filterEndMonth) &&
				(filterEndYear === "" || proj.yearClosedWon >= parseInt(filterEndYear))){
				return;
			}
			if(filterStatus !== "" && proj.status !== filterStatus){
				return;
			}

			if(filterSearch !== "" && persons){
				let lowerSearch = filterSearch.toLowerCase();
				let projNum = proj.projectNumber.toLowerCase();
				let projName = proj.projectName.toLowerCase();
				let projComments = proj.comments.toLowerCase();
				let AM:Person | undefined = persons.find(p => p.id === proj.accountManagerID);
				if(projNum.indexOf(lowerSearch) !== -1 || 
						projName.indexOf(lowerSearch) !== -1 ||
						projComments.indexOf(lowerSearch) !== -1 ||
						(AM && AM.name.toLowerCase().indexOf(lowerSearch) !== -1)){
						tempProjects.push(proj);
				 }
				 return;
			}
			tempProjects.push(proj);
		})
		tempProjects.sort((a,b) =>{
			if(a.status<b.status){
				return -1;
			}else if(a.status>b.status){
				return 1;
			}
			if(a.yearClosedWon === b.yearClosedWon){
				return b.monthClosedWon - a.monthClosedWon;
			}
			return b.yearClosedWon - a.yearClosedWon;
		})
		setFilteredSortedProjects(tempProjects);
	},[projects,persons,filterEndMonth,filterEndYear,filterStartMonth,filterStartYear,filterSearch,filterStatus])

  return (
		<>
			<div className="filters">
				<h3>Filter</h3>
				<div className="row">
					<div className="col">
						<div className="label">Start Month</div>
						<select value={filterStartMonth}
							onChange={(event) =>{
								setFilterStartMonth(event.target.value)
								if(filterStartYear == ""){
									setFilterStartYear(new Date().getFullYear().toString());
								}
								if(filterEndMonth != "" && parseInt(filterEndMonth) < parseInt(event.target.value) &&
									(filterEndYear == "" || parseInt(filterEndYear) <= parseInt(filterStartYear))){
									setFilterEndMonth("")
								}
							}}>
							<option value="">--</option>
							<option value="1">January</option>
							<option value="2">February</option>
							<option value="3">March</option>
							<option value="4">April</option>
							<option value="5">May</option>
							<option value="6">June</option>
							<option value="7">July</option>
							<option value="8">August</option>
							<option value="9">September</option>
							<option value="10">October</option>
							<option value="11">November</option>
							<option value="12">December</option>
						</select>
					</div>
					<div className="col">
						<div className="label">Start Year</div>
						<select value={filterStartYear}
							onChange={(event) =>{
								setFilterStartYear(event.target.value)
							}}>
							<option value="">----</option>
							{(() =>{
								let years = [];
								let currentYear = new Date().getFullYear();
								for(let i = currentYear; i > currentYear - 30; i--){
									years.push(<option key={`year_${i}`} value={i}>{i}</option>)
								}
								return years;
							})()}
						</select>
					</div>
					<div className="col">
						<div className="label">End Month</div>
						<select value={filterEndMonth}
							onChange={(event) =>{
								setFilterEndMonth(event.target.value)
								if(filterEndYear == ""){
									setFilterEndYear(new Date().getFullYear().toString());
								}
								if(filterStartMonth != "" && parseInt(filterStartMonth) > parseInt(event.target.value) &&
									(filterEndYear == "" || parseInt(filterEndYear) >= parseInt(filterStartYear))){
									setFilterEndMonth("")
								}
							}}>
							<option value="">--</option>
							<option value="1">January</option>
							<option value="2">February</option>
							<option value="3">March</option>
							<option value="4">April</option>
							<option value="5">May</option>
							<option value="6">June</option>
							<option value="7">July</option>
							<option value="8">August</option>
							<option value="9">September</option>
							<option value="10">October</option>
							<option value="11">November</option>
							<option value="12">December</option>
						</select>
					</div>
					<div className="col">
						<div className="label">End Year</div>
						<select value={filterEndYear}
							onChange={(event) =>{
								setFilterEndYear(event.target.value)

							}}>
							<option value="">----</option>
							{(() =>{
								let years = [];
								let currentYear = new Date().getFullYear();
								for(let i = currentYear; i > currentYear - 30; i--){
									years.push(<option key={`year_${i}`} value={i}>{i}</option>)
								}
								return years;
							})()}
						</select>
					</div>

					<div className="col">
						<div className="label">Project Status</div>
						<select className={`status`}
							defaultValue={filterStatus}
							onChange={(event) => {
								setFilterStatus(event.target.value)
							}}>
								<option value="">Select Status</option>
								{organization.projectStatusList.map((option) => {
									return <option value={option}>{option}</option>
								})}
						</select>
					</div>
					<div className="col">
						<div className="label">search</div>
						<div className="row">
							<input type='text' 
								defaultValue={filterSearch}
								className="search"
								onChange={(event) =>{
									if(slowSearchTimeout != null){
										clearTimeout(slowSearchTimeout);
									}
									slowSearchTimeout = setTimeout(() =>{
										setFilterSearch(event.target.value)
									}, 500);
								}}>
							</input>

							<div className='searchIcon'>
								<FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
							</div>
						</div>
					</div>
				</div>
			</div>
	    <table className={`ClientProjectsTable`}>
				<thead>
					<tr>
						<th>Month<br/>Closed Won</th>
						<th>Month<br/>Closed Year</th>
						<th>Project #</th>
						<th>Project Name</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					{fitleredSortedProjects.map((project:Project, index) =>{
						return <tr key={project.id}>
							<td>{project.monthClosedWon}</td>
							<td>{project.yearClosedWon}</td>
							<td>{project.projectNumber}</td>
							<td>{project.projectName}</td>
							<td>{project.status}</td>

							<Tooltip title={`Delete`} placement="bottom">
								<div className="buttonDelete"
									onClick={() =>{
										if(window.confirm("Are you sure you'd like to delete this project?")){
											deleteProject(project,actuals,(event) =>{
												AC.submitUndoStackEntry(event);
											});
										}
									}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
							</Tooltip>
							<Tooltip title={`Edit`} placement="bottom">
								<div className="buttonEdit"
									onClick={() =>{
										props.onEditProjectPressed(project);
									}}><FontAwesomeIcon icon={faPencil}></FontAwesomeIcon></div>
							</Tooltip>
						</tr>
					})}
				</tbody>
	    </table>
		</>
  )
}