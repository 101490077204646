
import "./BiddingWorksheet.css"
import { useDispatch, useSelector } from "react-redux";
import { faGear, faPeopleGroup, faAddressCard, faHouse, faBarChart, faPieChart, faCalendar, faFileImport, faPlus, faChevronDown, faArrowRight, faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Screens } from "../../Redux/Navigation";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { Tooltip } from "@mui/material";
import { Project, ProjectBidWorksheet } from "../../FirebaseModel/Project";
import { forceDecimal, prettyCurrency, prettyNum } from "../../Utils/formatting";
import { useEffect, useState } from "react";

interface BiddingWorksheetProps{
	projectStatus:string,
	useOverrideHourlyRate:boolean,
	overrideHourlyRate:number,
	bidWorksheet:ProjectBidWorksheet
	onBidWorksheetChange:(bidWorksheet:ProjectBidWorksheet) => void,
	onClosedWon:() => void,
}

export default function BiddingWorksheet(props:BiddingWorksheetProps){
  const currentScreen = useSelector((state:{screen:Screens}) => state.screen)

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const permissions = useSelector((state:{permissions:string[]}) => state.permissions)
	
  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setScreen:ActionCreators.setScreen},dispatch);

	const [rebuildingList, setRebuildingList] = useState<boolean>(false);

	useEffect(() => {
		if(rebuildingList){
			setTimeout(() => {setRebuildingList(false)},10)
		}
	},[rebuildingList])

	const recalculateTotals = (bidWorksheet:ProjectBidWorksheet) => {
		let totalSOWPrice = 0;
		let totalExtraMargin = 0;
		let totalProductionBudget = 0;
		let totalFixedCosts = 0;

		bidWorksheet.roles.forEach((roleRow) => {
			let role = roles.find((role) => role.id == roleRow.roleID)
			if(role == null){
				return
			}
			if(role.isBillable == false){
				return;
			}

			let hourlyRate= organization.hourlyRate;
			if(props.useOverrideHourlyRate){
				hourlyRate = props.overrideHourlyRate;
			}
			if(role.useOverrideHourlyRate){
				hourlyRate = role.overrideHourlyRate;
			}

			let prodBudget = roleRow.hours * hourlyRate;
			let extraMargin = roleRow.sowPrice - prodBudget;

			totalSOWPrice += roleRow.sowPrice;
			totalExtraMargin += extraMargin;
			totalProductionBudget += prodBudget;
		})

		bidWorksheet.fixedCosts.forEach((fixedCostItem) => {
			let extraMargin = fixedCostItem.sowPrice - fixedCostItem.cost;
			totalSOWPrice += fixedCostItem.sowPrice;
			totalExtraMargin += extraMargin;
			//totalProductionBudget += fixedCostItem.cost;
			totalFixedCosts += fixedCostItem.cost;
		})

		bidWorksheet.calculated.totalExtraMargin = totalExtraMargin - bidWorksheet.discount;
		bidWorksheet.calculated.totalProductionBudget = totalProductionBudget;
		bidWorksheet.calculated.totalSOWPrice = totalSOWPrice - bidWorksheet.discount;
		bidWorksheet.calculated.totalFixedCosts = totalFixedCosts;
	}

	const [expanded, setExpanded] = useState(false);

  let biddingStatuses = organization.projectStatusCategories["bidding"]


  return (
		<div className={`BiddingWorksheet ${(biddingStatuses.includes(props.projectStatus))?"":"closed"} ${expanded?"expanded":""}`}>
			<h1 onClick={() =>{
				setExpanded(!expanded);
			}}>SOW Price Breakout <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon></h1>

			
			<table>
				<thead>

				{props.projectStatus == "Bidding" && (
							<tr>
								<td></td>
								<td></td>
								<td>
									<Tooltip title="Copy Production Budget and Item Budget to SOW Price" placement="bottom">
										<div className="buttonConvert" onClick={() =>{

											let newBidWorksheet = {...props.bidWorksheet}
											for(var index in newBidWorksheet.roles){
												let role = roles.find((role) => role.id == newBidWorksheet.roles[index].roleID)
												if(role == null){
													continue;
												}
												if(role.isBillable == false){
													continue;
												}
												let hourlyRate= organization.hourlyRate;
												if(props.useOverrideHourlyRate){
													hourlyRate = props.overrideHourlyRate;
												}
												if(role.useOverrideHourlyRate){
													hourlyRate = role.overrideHourlyRate;
												}
												newBidWorksheet.roles[index].sowPrice = newBidWorksheet.roles[index].hours * hourlyRate;
											}
											for(var index in newBidWorksheet.fixedCosts){
												newBidWorksheet.fixedCosts[index].sowPrice = newBidWorksheet.fixedCosts[index].cost;
											}
											//newBidWorksheet.roles[index].hours = parseFloat(event.target.value.replace(/,/g,""));
											setRebuildingList(true);
											recalculateTotals(newBidWorksheet);
											props.onBidWorksheetChange(newBidWorksheet);
										}}><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon><FontAwesomeIcon icon={faSackDollar}></FontAwesomeIcon></div>
									</Tooltip>
								</td>
								<td></td>
								<td></td>
							</tr>
						)}
					<tr>
						<th>Roles</th>
						<th>Hours</th>
						<th>{organization.shortName}<br/>Production<br/>Budget</th>
						<th>Extra<br/>Margin</th>
						<th>SOW<br/>Price</th>
					</tr>	
				</thead>
				{rebuildingList == false && (
					<tbody>
						{props.bidWorksheet.roles.map((roleRow, index:number) => {
							let role = roles.find((role) => role.id == roleRow.roleID)
							if(role == null){
								return <></>
							}
							if(role.isBillable == false){
								return <></>
							}

							let hourlyRate= organization.hourlyRate;
							if(props.useOverrideHourlyRate){
								hourlyRate = props.overrideHourlyRate;
							}
							if(role.useOverrideHourlyRate){
								hourlyRate = role.overrideHourlyRate;
							}

							let prodBudget = roleRow.hours * hourlyRate;

							return (
								<tr key={role.id}>
									<td>{role.name}</td>
									<td>
										<input type='text'
											defaultValue={prettyNum(roleRow.hours)}
											disabled={props.projectStatus != "Bidding"}
											onChange={(event) => {
												if(event.target.value ==""){
													event.target.value = "0";
												}
												let newBidWorksheet = {...props.bidWorksheet}
												newBidWorksheet.roles[index].hours = parseFloat(event.target.value.replace(/,/g,""));
												recalculateTotals(newBidWorksheet);
												props.onBidWorksheetChange(newBidWorksheet);
											}}
											onBlur={(event) =>{
												if(event.target.value ==""){
													event.target.value = "0";
												}
												event.target.value = prettyNum(event.target.value);
												let newBidWorksheet = {...props.bidWorksheet}
												newBidWorksheet.roles[index].hours = parseFloat(event.target.value.replace(/,/g,""));
												recalculateTotals(newBidWorksheet);
												props.onBidWorksheetChange(newBidWorksheet);
											}}
											onFocus={(event)=>{
												let input = event.target as HTMLInputElement
												input.setSelectionRange(0,input.value.length)
											}}
											onKeyPress={(event) =>{
												forceDecimal(event);
											}}></input>
									</td>
									<td>{prettyCurrency(prodBudget)}</td>
									<td>{prettyCurrency(roleRow.sowPrice - prodBudget)}</td>
									<td>
										<input type='text'
											defaultValue={prettyCurrency(roleRow.sowPrice)}
											disabled={props.projectStatus != "Bidding"}
											onChange={(event) => {
												if(event.target.value ==""){
													event.target.value = "0";
												}
												let newBidWorksheet = {...props.bidWorksheet}
												newBidWorksheet.roles[index].sowPrice = parseFloat(event.target.value.replace("$","").replace(/,/g,""));
												recalculateTotals(newBidWorksheet);
												props.onBidWorksheetChange(newBidWorksheet);
											}}
											onFocus={(event)=>{
												let input = event.target as HTMLInputElement
												input.setSelectionRange(0,input.value.length)
											}}
											onBlur={(event) =>{
												if(event.target.value ==""){
													event.target.value = "0";
												}
												event.target.value = prettyCurrency(event.target.value);
												let newBidWorksheet = {...props.bidWorksheet}
												newBidWorksheet.roles[index].sowPrice = parseFloat(event.target.value.replace("$","").replace(/,/g,""));
												recalculateTotals(newBidWorksheet);
												props.onBidWorksheetChange(newBidWorksheet);
											}}
											onKeyPress={(event) =>{
												forceDecimal(event);
											}}></input>
									</td>
								</tr>
							)
						})}
					</tbody>	
				)}	
			</table>

			
			<table>
				<thead>
					<tr>
						<th>Fixed Cost Items</th>
						<th>Item<br/>Budget</th>
						<th>Extra<br/>Margin</th>
						<th>SOW<br/>Price</th>
					</tr>	
				</thead>
				{rebuildingList == false && (
				<tbody>
					{props.bidWorksheet.fixedCosts.map((fixedCostRow: { fixedCostItem: string | number | readonly string[] | undefined; cost: number; sowPrice: number; }, index:number) => {

						return (
							<tr key={`fixed_cost_${index}`}>
								<td>
									<input 
										type="text"
										defaultValue={fixedCostRow.fixedCostItem}
										disabled={props.projectStatus != "Bidding"}
										onChange={(event) =>{
											let newBidWorksheet = {...props.bidWorksheet}
											newBidWorksheet.fixedCosts[index].fixedCostItem = event.target.value;
											recalculateTotals(newBidWorksheet);
											props.onBidWorksheetChange(newBidWorksheet);
										}}></input>
								</td>
								<td>
									<input type='text'
										defaultValue={prettyCurrency(fixedCostRow.cost)}
										disabled={props.projectStatus != "Bidding"}
										onChange={(event) => {
											if(event.target.value ==""){
												event.target.value = "0";
											}
											let newBidWorksheet = {...props.bidWorksheet}
											newBidWorksheet.fixedCosts[index].cost = parseFloat(event.target.value.replace("$","").replace(/,/g,""));
											recalculateTotals(newBidWorksheet);
											props.onBidWorksheetChange(newBidWorksheet);
										}}
										onFocus={(event)=>{
											let input = event.target as HTMLInputElement
											input.setSelectionRange(0,input.value.length)
										}}
										onBlur={(event) =>{
											if(event.target.value ==""){
												event.target.value = "0";
											}
											event.target.value = prettyCurrency(event.target.value);
											let newBidWorksheet = {...props.bidWorksheet}
											newBidWorksheet.fixedCosts[index].cost = parseFloat(event.target.value.replace("$","").replace(/,/g,""));
											recalculateTotals(newBidWorksheet);
											props.onBidWorksheetChange(newBidWorksheet);
										}}
										onKeyPress={(event) =>{
											forceDecimal(event);
										}}></input>
								</td>
								<td>{prettyCurrency(fixedCostRow.sowPrice - fixedCostRow.cost)}</td>
								<td>
									<input type='text'
										defaultValue={prettyCurrency(fixedCostRow.sowPrice)}
										disabled={props.projectStatus != "Bidding"}
										onChange={(event) => {
											let newBidWorksheet = {...props.bidWorksheet}
											newBidWorksheet.fixedCosts[index].sowPrice = parseFloat(event.target.value.replace("$","").replace(/,/g,""));
											recalculateTotals(newBidWorksheet);
											props.onBidWorksheetChange(newBidWorksheet);
										}}
										onFocus={(event)=>{
											let input = event.target as HTMLInputElement
											input.setSelectionRange(0,input.value.length)
										}}
										onBlur={(event) =>{
											if(event.target.value ==""){
												event.target.value = "0";
											}
											event.target.value = prettyCurrency(event.target.value);
											let newBidWorksheet = {...props.bidWorksheet}
											newBidWorksheet.fixedCosts[index].sowPrice = parseFloat(event.target.value.replace("$","").replace(/,/g,""));
											recalculateTotals(newBidWorksheet);
											props.onBidWorksheetChange(newBidWorksheet);
										}}
										onKeyPress={(event) =>{
											forceDecimal(event);
										}}></input>
								</td>
							</tr>
						)
					})}
					{(props.projectStatus != "Bidding") == false && (
						<tr>
							<td colSpan={4}>
								<div className="buttonAdd" onClick={()=>{
									let newBidWorksheet = {...props.bidWorksheet}
									newBidWorksheet.fixedCosts.push({fixedCostItem:"",cost:0,sowPrice:0})
									recalculateTotals(newBidWorksheet);
									props.onBidWorksheetChange(newBidWorksheet);
								}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Fixed Cost Item</div>
							</td>
						</tr>
					)}
				</tbody>
				)}		
			</table>

			<div className="row">
				Discount:
				<input type='text'
					defaultValue={prettyCurrency(props.bidWorksheet.discount)}
					disabled={props.projectStatus != "Bidding"}
					onChange={(event) => {
						let newBidWorksheet = {...props.bidWorksheet}
						newBidWorksheet.discount = parseFloat(event.target.value.replace("$","").replace(/,/g,""));
						recalculateTotals(newBidWorksheet);
						props.onBidWorksheetChange(newBidWorksheet);
					}}
					onFocus={(event)=>{
						let input = event.target as HTMLInputElement
						input.setSelectionRange(0,input.value.length)
					}}
					onBlur={(event) =>{
						if(event.target.value ==""){
							event.target.value = "0";
						}
						event.target.value = prettyCurrency(event.target.value);

						let newBidWorksheet = {...props.bidWorksheet}
						newBidWorksheet.discount = parseFloat(event.target.value.replace("$","").replace(/,/g,""));
						recalculateTotals(newBidWorksheet);
						props.onBidWorksheetChange(newBidWorksheet);
					}}
					onKeyPress={(event) =>{
						forceDecimal(event);
					}}></input>
			</div>


			<table className="total">
				<thead>
					<tr>
						<th>Total {organization.shortName} Production Budget</th>
						<th>Total Fixed Cost Items</th>
						<th>Total Extra Margin</th>
						<th>Total SOW Price</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{prettyCurrency(props.bidWorksheet.calculated.totalProductionBudget)}</td>
						<td>{prettyCurrency(props.bidWorksheet.calculated.totalFixedCosts)}</td>
						<td>{prettyCurrency(props.bidWorksheet.calculated.totalExtraMargin)}</td>
						<td>{prettyCurrency(props.bidWorksheet.calculated.totalSOWPrice)}</td>
					</tr>
				</tbody>
			</table>

		<h3>Notes</h3>
		<textarea
			defaultValue={props.bidWorksheet.bidNotes}
			disabled={props.projectStatus != "Bidding"}
			onChange={(event) => {
				let newBidWorksheet = {...props.bidWorksheet}
				newBidWorksheet.bidNotes = event.target.value;
				props.onBidWorksheetChange(newBidWorksheet);
			}}></textarea>

			{(props.projectStatus != "Bidding") == false && (
				<div className="buttonClosedWon" onClick={()=>{
					props.onClosedWon();
				}}>Mark Bid as Closed Won</div>
			)}
		</div>
  )
}


