
import React, { useEffect, useState } from "react"
import "./ReportAnnualBSP.css"
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../FirebaseModel/Organization";
import SoldVSBilledTable from "../../Components/SoldVSBilled/SoldVSBilledTable/SoldVSBilledTable";
import { CalculateAnnualBSP } from "../../Utils/CalculateBSPFunctions";
import { MonthTotal } from "../../FirebaseModel/MonthTotal";
import { Role } from "../../FirebaseModel/Role";
import { Project } from "../../FirebaseModel/Project";
import { Person } from "../../FirebaseModel/Person";
import { getOrCreateYearTotal } from "../../Utils/SaveYearTotalFunctions";
import { YearTotal } from "../../FirebaseModel/YearTotal";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import AnnualBSPTable from "../../Components/AnnualBSP/AnnualBSPTable/AnnualBSPTable";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface ReportAnnualBSPProps{
	filterYear:string,
	onDismiss:()=>void;
}

export default function ReportAnnualBSP(props:ReportAnnualBSPProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const yearTotals = useSelector((state:{yearTotals:YearTotal[]}) => state.yearTotals)
  const monthTotals = useSelector((state:{monthTotals:MonthTotal[]}) => state.monthTotals)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)
  const persons = useSelector((state:{persons:Person[]}) => state.persons)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

 

	const [tableOffsetTop, setTableOffsetTop] = useState<number>(0);
	const tableRef = React.createRef<HTMLDivElement>();
	const resize = () =>{
		if(tableRef.current){
			setTableOffsetTop(tableRef.current!.offsetTop);
		}
	}

	useEffect(() => {
		let table = tableRef.current!;
		if(table){
			const overrideWheel = (e: WheelEvent) => {
				if(table.scrollLeft === 0 && e.deltaX < 0){
					e.preventDefault();
				}
			};
			table.addEventListener("wheel", overrideWheel);
			return () => {
				table.removeEventListener("wheel", overrideWheel);
			};
		}
	}, [tableRef]);

	const [year, setYear] = useState<number>(parseInt(props.filterYear));

	const [calculationComplete, setCalculationComplete] = useState<boolean>(false);
	const [yearTotal, setYearTotal] = useState<YearTotal|null>(null);

	useEffect(() =>{
		window.addEventListener("resize", resize);
		resize();

		getOrCreateYearTotal(year, yearTotals, organization, (entry) =>{
			AC.submitUndoStackEntry(entry);
		}).then((yearTotal) =>{
			setYearTotal(yearTotal);
			CalculateAnnualBSP(yearTotal, monthTotals,projects, roles, persons, organization, (entry) =>{
				AC.submitUndoStackEntry(entry);
			}).then(() =>{
				setCalculationComplete(true);
			});
		})

		return () =>{
			window.removeEventListener("resize", resize);
		}
	},[year])

  return (
		<>
	    <div className={`report ReportAnnualBSP`}>
				<h1>Annual BSP Report 
					<span onClick={() =>{
							setCalculationComplete(false);
							setYear(year-1);
						}} className="yearButton"><FontAwesomeIcon icon={faChevronLeft} /></span>

					({year})
					<span onClick={() =>{
							setCalculationComplete(false);
							setYear(year+1);
						}} className="yearButton"><FontAwesomeIcon icon={faChevronRight} /></span>
				</h1>
				{(calculationComplete && yearTotal) && (
					<div className="content"
						ref={tableRef}
						style={{maxHeight:`calc(100vh - ${tableOffsetTop}px)`}}>
							
							<AnnualBSPTable year={year} yearTotal={yearTotal}></AnnualBSPTable>

					</div>
				)}
				{calculationComplete == false && (
					<div className="loading">
						<div className="loadingText">Calculating...</div>
					</div>
				)}
				<div className="floatingButtons">
					<div className="buttonSave"
						onClick={() =>{
							props.onDismiss();
						}}>Done</div>
					</div>
			</div>
		</>
  )
}