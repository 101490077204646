

import React, { useEffect, useState } from "react"
import { addDoc, collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./PopupAddView.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import { Person } from "../../FirebaseModel/Person";
import { sharedFirebaseAuth, sharedFirestore } from "../../Utils/SharedFirebase";
import { forceDecimal, prettyCurrency } from "../../Utils/formatting";
import { saveOrganizationAttributeChange } from "../../Utils/SaveOrganizationFunctions";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { UndoStackEntry, UndoStackEntryType, UndoStackEntryObjectType } from "../../Utils/UndoStackEntry";
import { Department } from "../../FirebaseModel/Department";
import { View } from "../../FirebaseModel/View";
import DraggableTableBody from "../../Components/DraggableTableBody/DraggableTableBody";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
interface PopupAddViewProps{
	type:string;
	columns:string[];
	view?:View;

	onCancel:()=>void;
	onSave:(view:View) =>void;

	allowMultiple?:boolean;
}

export default function PopupAddView(props:PopupAddViewProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const departments = useSelector((state:{departments:Department[]}) => state.departments)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)

	const person = persons.find((person:Person) => person.email.toLowerCase() == sharedFirebaseAuth.currentUser!.email!.toLowerCase())!;

	const [name, setName] = useState(props.view?props.view.name:"");
	const [isPublic, setIsPublic] = useState(props.view?props.view.public:false);
	const [columns, setColumns] = useState(props.columns);
	const [selectedColumns, setSelectedColumns] = useState(props.view?props.view.selectedColumns:props.columns);
	
	useEffect(() =>{
		setColumns(props.columns);
		setName(props.view?props.view.name:"");
		setIsPublic(props.view?props.view.public:false);
		setSelectedColumns(props.view?props.view.selectedColumns:props.columns);
	},[props.columns,props.view]);

	const saveNewView = () =>{
		return new Promise((resolve:(view:View)=>void,reject) =>{
			let docData = {
				organizationID: organization.id,
				name: name,
				viewType: props.type,
				creatorPersonID: person.id,
				public: isPublic,
				columns: props.columns,
				selectedColumns: selectedColumns,
			}
			addDoc(collection(sharedFirestore, "View"),docData).then((docRef) =>{
				let newView:View = {id:docRef.id, ...docData} as View;
				let undoEntry:UndoStackEntry = {
					type: UndoStackEntryType.create,
					objectType: UndoStackEntryObjectType.view,
					objectID: newView.id,
					objectBeforeState: null,
					objectAfterState: newView,
					description: "Create View"
				}
				AC.submitUndoStackEntry(undoEntry);
				resolve(newView);
			});
		});
	}

	const updateView = () =>{
		return new Promise((resolve:(view:View)=>void,reject) =>{
			let docData = {
				name: name,
				public: isPublic,
				columns: props.columns,
				selectedColumns: selectedColumns,
			}
			updateDoc(doc(sharedFirestore, "View", props.view!.id),docData).then(() =>{
				let newView:View = {id:props.view!.id, ...doc} as View;
				let undoEntry:UndoStackEntry = {
					type: UndoStackEntryType.update,
					objectType: UndoStackEntryObjectType.view,
					objectID: newView.id,
					objectBeforeState: props.view,
					objectAfterState: newView,
					description: "Update View"
				}
				AC.submitUndoStackEntry(undoEntry);
				props.view!.selectedColumns = [...selectedColumns];
				props.view!.name = name;
				props.view!.public = isPublic;
				resolve(newView);
			});
		});
	}

	const [clearingForm, setClearingForm] = useState(false);
	return (
		<div className={`popup PopupAddView`}>
			<h1>New {props.type} View</h1>
			{clearingForm == false && (
				<div className="content">
					<div className="topArea">
						<div className="row">
							<div className="col">
								<div className="label">View Name</div>
								<input className={`name`} 
									defaultValue={name}
									onBlur={(event) =>{
										setName(event.target.value)
									}}></input>
							</div>
							<div className="col">
								<div className="label">Public</div>
								<input className={`public`} 
									type="checkbox"
									checked={isPublic}
									onChange={(event) =>{
										setIsPublic(event.target.checked)
									}}></input>
							</div>
						</div>
					</div>

					<h2>Included Columns</h2>
					<table>
						<DraggableTableBody
							childClassName="SelectedColumn"
							orderedItems={selectedColumns}
							onOrderUpdated={(newOrderedItems:string[]) =>{
								let newSelectedColumns = [...newOrderedItems];
								setSelectedColumns(newSelectedColumns);
							}}
							onOrderUpdateEnded={(newOrderedItems:string[]) =>{

							}}
							keyForIndex={(index: number) => {
								return selectedColumns[index];
							} }>
							{selectedColumns.map((column:string, index:number) =>{
								return <>
									<td>
										<FontAwesomeIcon icon={faBars} style={{pointerEvents:"none", fontSize:"50%"}}></FontAwesomeIcon>
										<input type='checkbox'
											checked={selectedColumns.includes(column)}
											onChange={(event) =>{
												if(event.target.checked){
													setSelectedColumns([...selectedColumns, column]);
												}else{
													setSelectedColumns(selectedColumns.filter((selectedColumn:string) => selectedColumn != column));
												}
											}}></input>
											{column}
									</td>
								</>
							})}
						</DraggableTableBody>
					</table>

					<h2>Disabled Columns</h2>

					<div className="bottomArea">
						{columns.map((column:string, index:number) =>{
							if(selectedColumns.includes(column)){
								return <></>
							}
							return <div className="row" key={index}>
								<input type='checkbox'
									checked={selectedColumns.includes(column)}
									onChange={(event) =>{
										if(event.target.checked){
											setSelectedColumns([...selectedColumns, column]);
										}else{
											setSelectedColumns(selectedColumns.filter((selectedColumn:string) => selectedColumn != column));
										}
									}}></input>
									{column}
							</div>
						})}
					</div>
				</div>
			)}

			<div className="floatingButtons">
				<div className="buttonCancel"
					onClick={() =>{
						props.onCancel();
					}}>Cancel</div>

				<div className="buttonSave"
					onClick={() =>{
						if(props.view){
							updateView().then((view:View) =>{
								props.onSave(view);
							});
						}else{
							saveNewView().then((view:View) =>{
								props.onSave(view);
							});
						}
					}}>Save and Close</div>
		</div>
	</div>
)
}