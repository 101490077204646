import React, { useEffect, useState } from "react"
import "./NonProjectTasksTableRow.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { saveOrganizationAttributeChange } from "../../../Utils/SaveOrganizationFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { faBars, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forceDecimal, forcePercentage, prettyNum, shortDate } from "../../../Utils/formatting";
import { PersonNonProjectTask } from "../../../FirebaseModel/Person";

const uuid = require('uuid');

interface NonProjectTasksTableRowProps{
	nonProjectTask:PersonNonProjectTask;
	personID:string;
	isReadOnly?:boolean;
	onNonProjectTaskChange:(nonProjectTasks:PersonNonProjectTask) => void;
	onDeleteProjectTask:(nonProjectTask:PersonNonProjectTask) => void;
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function NonProjectTasksTableRow(props:NonProjectTasksTableRowProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles},dispatch);

	const [nonProjectTask, setNonProjectTask] = useState<PersonNonProjectTask>(props.nonProjectTask);
	useEffect(() =>{
		setNonProjectTask(props.nonProjectTask);
	},[props.nonProjectTask]);

	
  return (
    <tr key={nonProjectTask.id}>
			{!props.isReadOnly ? (
				<>
					<td>
						<input type='text'
							className="taskName"
							defaultValue={nonProjectTask.name}
							onChange={(e) =>{
								nonProjectTask.name = e.target.value;
								props.onNonProjectTaskChange(nonProjectTask);
							}}></input>
					</td>
					<td>
						<input type='text'
							defaultValue={nonProjectTask.plannedHours}
							onKeyPress={(e) =>{
								forceDecimal(e);
							}}
							onFocus={(e) =>{
								//select all text in the input
								e.target.setSelectionRange(0, e.target.value.length);
								
							}}
							onChange={(e) =>{
								nonProjectTask.plannedHours = parseFloat(e.target.value);
								props.onNonProjectTaskChange(nonProjectTask);
							}}></input>
					</td>
					<td>
						<input type='text'
							defaultValue={Math.round(nonProjectTask.percenComplete*100)+"%"}
							onKeyPress={(e) =>{
								forcePercentage(e);
							}}
							onFocus={(e) =>{
								//select all text in the input
								e.target.setSelectionRange(0, e.target.value.length);

							}}
							onBlur={(e) =>{
								e.target.value = Math.round(parseFloat(e.target.value.replace(/%/g,"")))+"%";
							}}
							onChange={(e) =>{
								nonProjectTask.percenComplete = parseFloat(e.target.value.replace(/%/g,""))/100;
								props.onNonProjectTaskChange(nonProjectTask);
							}}></input>
					</td>
					<td>
						<DatePicker
						 	className="dueDate"
							selected={nonProjectTask.dueDate!=""?new Date(nonProjectTask.dueDate):null}
							onChange={(date:Date) =>{
								if(date){
									nonProjectTask.dueDate = shortDate(date);
									props.onNonProjectTaskChange(nonProjectTask);
								}else{
									nonProjectTask.dueDate = "";
									props.onNonProjectTaskChange(nonProjectTask);
								}
							}}
							/>
					</td>

					<div className="buttonDelete" onClick={() =>{
						props.onDeleteProjectTask(nonProjectTask);
					}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>

				</>
			):(
				<>
					<td>{nonProjectTask.name}</td>
					<td>{prettyNum(nonProjectTask.plannedHours)}</td>
					<td>{Math.round(nonProjectTask.percenComplete*100)}%</td>
					<td>{nonProjectTask.dueDate}</td>
				</>
			)}
		</tr>
  )
}