
import "./SoldVSBilledTable.css"
import { useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { Person } from "../../../FirebaseModel/Person";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { useEffect, useState } from "react";
import { Project } from "../../../FirebaseModel/Project";
import SoldVSBilledTableHeader, { SoldVSBilledTableHeaderType } from "../SoldVSBilledTableHeader/SoldVSBilledTableHeader";
import { Client } from "../../../FirebaseModel/Client";
import SoldVSBilledTableRow from "../SoldVSBilledTableRow/SoldVSBilledTableRow";
import { prettyNum } from "../../../Utils/formatting";
import DownloadableTable from "../../DownloadableTable/DownloadableTable";
interface SoldVSBilledTableProps{
	startYear:number,
	startMonth:number,
	endYear:number,
	endMonth:number,
	weeksPerYear:number,
	listProjects?:boolean,
}



export interface SoldVSBilledTableRowState{
	startDate:Date,
	endDate:Date,
	months:number,
	resourcesHaveByRole:{[roleID:string]:number},
	bshSoldByRole:{[roleID:string]:number},
	actualHrsByRole:{[roleID:string]:number},
	bspWeekByRole:{[roleID:string]:number},
	budgetBshByRole:{[roleID:string]:number},
	neededResourcesByRole:{[roleID:string]:number},
	total1:{
		bshSold:number,
		bshSoldPercent:number,
		actualHrs:number,
		actualHrsPercent:number,
		estVsAct:number,
		budgetBsh:number,
		bshMo:number,
		capacityMo:number,
		dollarMo:number,
		annualized:number,
		neededResources:number,
		resourcesHave:number,
		difference:number,
		bspWeek:number,
		avgAct:number,
		bspCapacityYr:number,
		resources:number,
		roleWeight:number,
		weightedNum:number,
	},
	total2:{
		budgetBsh:number,
		annualized:number,
		bspCapacityYr:number,
	},

}


export default function SoldVSBilledTable(props:SoldVSBilledTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const allRoles = useSelector((state:{roles:Role[]}) => state.roles)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const projects = useSelector((state:{projects:Project[]}) => state.projects)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)


	const [roles,setRoles] = useState<Role[]>(allRoles.filter((role)=>role.isBillable));

	useEffect(() =>{
		setRoles(allRoles.filter((role)=>role.isBillable));
	},[allRoles]);

	
	const [tableBodyElements,setTableBodyElements] = useState<JSX.Element[]>([]);


	const [state,setState] = useState<SoldVSBilledTableRowState|null>(null);
	

	const buildState = (state:SoldVSBilledTableRowState|null):SoldVSBilledTableRowState => {

		let startDate = new Date(`${props.startMonth}/01/${props.startYear}`);
		let endDate = new Date(`${props.endMonth}/28/${props.endYear}`);
		let newDate = new Date(endDate);
		newDate.setDate(newDate.getDate() + 1);
		while(newDate.getMonth() == endDate.getMonth()){
			endDate = new Date(newDate)
			newDate.setDate(newDate.getDate() + 1);
		}
		if(endDate.getTime() > new Date().getTime()){
			endDate = new Date(); // it ends today;
		}
		// the number of months in the range
		let months = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth() + 1);

		let tempState:SoldVSBilledTableRowState = {
			startDate: startDate,
			endDate: endDate,
			months: months,
			resourcesHaveByRole: {},
			bshSoldByRole: {},
			actualHrsByRole: {},
			bspWeekByRole: {},
			budgetBshByRole: {},
			neededResourcesByRole: {},
			total1: {
				bshSold: 0,
				bshSoldPercent: 1,
				actualHrs: 0,
				actualHrsPercent: 1,
				estVsAct: 0,
				budgetBsh: 0,
				bshMo: 0,
				capacityMo: 0,
				dollarMo: 0,
				annualized: 0,
				neededResources: 0,
				resourcesHave: 0,
				difference: 0,
				bspWeek: 0,
				avgAct: 0,
				bspCapacityYr: 0,
				resources: 0,
				roleWeight: 0,
				weightedNum: 0
			},
			total2: {
				budgetBsh: 0,
				annualized: 0,
				bspCapacityYr: 0
			}
		}

		roles.forEach((role)=>{
			tempState.resourcesHaveByRole[role.id] = 0;
			tempState.bspWeekByRole[role.id] = 0;
			tempState.bshSoldByRole[role.id] = 0;
			tempState.actualHrsByRole[role.id] = 0;
			tempState.budgetBshByRole[role.id] = 0;
		})

		if(state){
			tempState.resourcesHaveByRole = state.resourcesHaveByRole;
			tempState.bspWeekByRole = state.bspWeekByRole;
			// tempState.resourcesHaveByRole.forEach((role)=>{
			// 	tempState.total1.neededResources += 
			// })
		}else{
			roles.forEach((role)=>{
				tempState.bspWeekByRole[role.id] = parseFloat(localStorage.getItem("bsp_week_"+role.id) || "30");
				persons.forEach((person)=>{
					let personStartDate = new Date(person.startDate??undefined);
					let personEndDate = new Date(person.endDate??undefined);
					if(personStartDate > endDate || personEndDate < startDate){
						return;
					}
					if(person.roles == null){
						return;
					}
					for(let i =0 ; i < person.roles!.length; i++){
						if(person.roles![i].roleID === role.id){
							tempState.resourcesHaveByRole[role.id]+=person.roles![i].weight;
							tempState.total1.resources += person.roles![i].weight;
						}
					}
				})
			})
		}

		tempState.total1.neededResources = 0;
		for(let i in tempState.bspWeekByRole){
			let bspPerWeek = tempState.bspWeekByRole[i];
			let bshWeek = tempState.bshSoldByRole[i] / months * 12 / 52;
			let neededResources = bshWeek * 12 / 52 / bspPerWeek;
			tempState.neededResourcesByRole[i] = neededResources;
			tempState.total1.neededResources += neededResources;

		}

		projects.forEach((project)=>{
			//if(project.yearClosedWon == props.year){
			if(project.yearClosedWon >= props.startYear && project.yearClosedWon <= props.endYear){
				if((project.yearClosedWon === props.startYear && project.monthClosedWon < props.startMonth) ||
					(project.yearClosedWon === props.endYear && project.monthClosedWon > props.endMonth)){
					return;
				}

				tempState.total2.budgetBsh += project.sfClosedWon

				let hourlyRate = organization.hourlyRate;
				let client = clients.find((client)=>client.id === project.clientID);
				if(client && client.useOverrideHourlyRate){
					hourlyRate = client.overrideHourlyRate;
				}
				if(project.useOverrideHourlyRate){
					hourlyRate = project.overrideHourlyRate;
				}
				project.plannedHours.forEach((projectRole)=>{
					let tempHourlyRate = hourlyRate;
					let role = roles.find((role)=>role.id === projectRole.roleID);
					if(role){
						if(role.useOverrideHourlyRate){
							hourlyRate = role.overrideHourlyRate;
						}
						tempState.budgetBshByRole[projectRole.roleID] += projectRole.hours * hourlyRate;
						hourlyRate = tempHourlyRate;
					}
				});
				tempState.total1.budgetBsh += project.calculated.plannedHours * hourlyRate;
				project.plannedHours.forEach((projectRole)=>{
					let role = roles.find((role)=>role.id === projectRole.roleID);
					if(role && role?.isBillable){
						tempState.bshSoldByRole[projectRole.roleID] += projectRole.hours;
					}
				})
			}
		})


		actuals.forEach((actual)=>{
			if(actual.billable && actual.year >= props.startYear && actual.year <= props.endYear){
				//tempState.actualHrsByRole[actual.roleID] += actual.calculated.totalHours;
				actual.hours.forEach((actualEntry)=>{
					let date = new Date(actualEntry.date);
					if(date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()){
						tempState.actualHrsByRole[actual.roleID] += actualEntry.hours;
					}
				})
			}
		})

		
		for(let i in tempState.bshSoldByRole){
			tempState.total1.bshSold += tempState.bshSoldByRole[i]??0;
			tempState.total1.actualHrs += tempState.actualHrsByRole[i]??0;
			let bspCap = (tempState.resourcesHaveByRole[i] || 0) * tempState.bspWeekByRole[i] * props.weeksPerYear * organization.hourlyRate;
			tempState.total1.bspCapacityYr += bspCap
		}
		tempState.total1.estVsAct = tempState.total1.actualHrs!=0?(tempState.total1.bshSold / tempState.total1.actualHrs):0;
		tempState.total1.bshMo = tempState.total1.bshSold / months;
		tempState.total1.annualized = tempState.total1.bshMo * organization.hourlyRate * 12;

		tempState.total1.capacityMo = 0
		tempState.total1.dollarMo = 0
		tempState.total1.neededResources = 0
		tempState.total1.resourcesHave = 0
		tempState.total1.difference = 0

		let avgBSPWeek = 0;

		for(let roleID in tempState.resourcesHaveByRole){
			let capacity = tempState.resourcesHaveByRole[roleID] * tempState.bspWeekByRole[roleID] * organization.hourlyRate / 12 * 52;
			tempState.total1.capacityMo += capacity;
			let dollarsMo = organization.hourlyRate * (tempState.bshSoldByRole[roleID] / months || 0);
			tempState.total1.dollarMo += dollarsMo;
			let neededResources = (tempState.bshSoldByRole[roleID] / months || 0) * 12 / 52 / tempState.bspWeekByRole[roleID];
			tempState.total1.neededResources += neededResources;
			tempState.total1.resourcesHave += tempState.resourcesHaveByRole[roleID];
			tempState.total1.difference += neededResources - tempState.resourcesHaveByRole[roleID];
			avgBSPWeek += tempState.bspWeekByRole[roleID];
		}
		avgBSPWeek /= roles.length;
		tempState.total1.bspWeek = avgBSPWeek;
		let tempAvgAct = tempState.total1.resourcesHave > 0 ?(tempState.total1.actualHrs / months || 0)*12/52 / (tempState.total1.resourcesHave || 0):0;
		tempState.total1.avgAct = tempAvgAct;
			


		tempState.total2.annualized = tempState.total2.budgetBsh / months * 12;
		tempState.total2.bspCapacityYr = tempState.total1.bspCapacityYr / 12;
		return tempState;
	}


	useEffect(()=>{
		let tempState = buildState(state);
		setState(tempState);
	},[roles,props.endMonth,props.endYear,props.startMonth,props.startYear,props.weeksPerYear]);

	useEffect(()=>{
		let tableBody:JSX.Element[] = [];

		if(state){

			organization.roleIDOrder.forEach((roleID, index)=>{
				let role = roles.find((role)=>role.id === roleID);
				if(role){
					tableBody.push(
						<SoldVSBilledTableRow
							
							key={`role_${role.id}`}
							role={role} 
							reportState={state!} 
							isLastRow={index == roles.length}
							weeksPerYear={props.weeksPerYear}
							startDate={state.startDate}
							endDate={state.endDate}
							onResourcesHaveChange={function (roleID: string, resourcesHave: number): void {
								//throw new Error("Function not implemented.");
								state!.resourcesHaveByRole[roleID] = resourcesHave;
								let tempState = buildState(state);
								setState(tempState);
							} } 
							onBSPPerWeekChange={function (roleID: string, bspWeek: number): void {
								//throw new Error("Function not implemented.");
								state!.bspWeekByRole[roleID] = bspWeek;
								let tempState = buildState(state);
								for(var roleID in tempState.bspWeekByRole){
									localStorage.setItem("bsp_week_"+roleID,tempState.bspWeekByRole[roleID]+"")
								}
								setState(tempState);
							} } />
					)
				}
			})

			tableBody.push(
				<tr><td className="gap" colSpan={18}></td></tr>
			)

			tableBody.push(
				<SoldVSBilledTableHeader 
					key={`total1`}
					type={SoldVSBilledTableHeaderType.total1} 
					bshSold={state!.total1.bshSold}
					bshSoldPercent={state!.total1.bshSoldPercent}
					actualHrs={state!.total1.actualHrs}
					actualHrsPercent={state!.total1.actualHrsPercent}
					estVsAct={state!.total1.estVsAct}
					budgetBsh={state!.total1.budgetBsh}
					bshMo={state!.total1.bshMo}
					capacityMo={state!.total1.capacityMo}
					dollarMo={state!.total1.dollarMo}
					annualized={state!.total1.annualized}
					neededResources={state!.total1.neededResources}
					resourcesHave={state!.total1.resourcesHave}
					difference={state!.total1.difference}
					bspWeek={state!.total1.bspWeek}
					avgAct={state!.total1.avgAct}
					bspCapacityYr={state!.total1.bspCapacityYr}
					resources={state!.total1.resources}
					roleWeight={state!.total1.roleWeight}
					weightedNum={state!.total1.weightedNum}></SoldVSBilledTableHeader>
			)
			tableBody.push(
				<SoldVSBilledTableHeader 
					key={`label2`}
					type={SoldVSBilledTableHeaderType.label2} ></SoldVSBilledTableHeader>
			)
			tableBody.push(
				<SoldVSBilledTableHeader 
					type={SoldVSBilledTableHeaderType.total2} 
					key={`total2`}
					budgetBsh={state!.total2.budgetBsh}
					annualized={state!.total2.annualized}
					bspCapacityYr={state!.total2.bspCapacityYr}></SoldVSBilledTableHeader>
			)
		}

		setTableBodyElements(tableBody)
	},[state]);

  return (
		<div className={`SoldVSBilledTableContainer`}>
			{state && (
				<>
					<DownloadableTable className={`SoldVSBilledTable`} fileName="Sold Vs Billed">
						<thead>
							<SoldVSBilledTableHeader
								key={`label1`}
								type={SoldVSBilledTableHeaderType.label1}
							/>
						</thead>
						<tbody>
							{tableBodyElements && tableBodyElements.map((element,index) =>{
								return element;
							})}
						</tbody>
					</DownloadableTable>
					{/* {props.listProjects && (
						<table className='SoldVsBilledTableProjects'>
							<thead>
								<tr>
									<th>Client</th>
									<th>Project</th>
									{roles.map((role)=>{
										return <>
											<th key={`planned_${role.id}`}>{role.abbreviation} planned</th>
											<th key={`actual_${role.id}`}>{role.abbreviation} actual</th>
										</>
									})}
								</tr>
							</thead>
							<tbody>
								{projects.map((project)=>{
									let client = clients.find((client)=>client.id === project.clientID);
									if(project.yearClosedWon < props.startYear || project.yearClosedWon > props.endYear){
										return <></>;
									}

									if((project.yearClosedWon === props.startYear && project.monthClosedWon < props.startMonth) ||
										(project.yearClosedWon === props.endYear && project.monthClosedWon > props.endMonth)){
											return <></>;
									}

									return (
										<tr key={`project_${project.id}`}>
											<td>{client?client.name:""}</td>
											<td>{project.projectName}</td>
											{roles.map((role)=>{
												let plannedHours = project.plannedHours.find((plannedHours)=>plannedHours.roleID === role.id);
												let projectActuals = actuals.filter((actual)=>actual.projectID === project.id && actual.roleID === role.id);
												let actualHours = projectActuals.reduce((total,actual)=>{
													return total + actual.calculated.totalHours;
												},0);
												return <>
													<td key={`planned_${role.id}`}>{prettyNum(plannedHours?plannedHours.hours:0)}</td>
													<td key={`actual_${role.id}`}>{prettyNum(actualHours)}</td>
												</>
											})}
										</tr>
									)
								})}

								<tr className="total">
									<td>Total</td>
									<td></td>
									{roles.map((role)=>{
										let filteredProjects = projects.filter((project)=>{
											return (project.yearClosedWon >= props.startYear && project.yearClosedWon <= props.endYear) ||
											((project.yearClosedWon === props.startYear && project.monthClosedWon >= props.startMonth) ||
											(project.yearClosedWon === props.endYear && project.monthClosedWon <= props.endMonth));
										});
										let totalPlannedHours = filteredProjects.reduce((total,project)=>{
											let plannedHours = project.plannedHours.find((plannedHours)=>plannedHours.roleID === role.id);
											if(plannedHours){
												return total + plannedHours.hours;
											}
											return total;
										},0);
										let filteredActuals = actuals.filter((actual) => {
											return actual.billable && filteredProjects.find((project)=>project.id === actual.projectID)	
										});
										let totalActualHours = filteredActuals.reduce((total,actual)=>{
											if(actual.roleID === role.id){
												return total + actual.calculated.totalHours;
											}
											return total;
										},0);
										return <>
											<td key={`total_planned_${role.id}`}>{prettyNum(totalPlannedHours)}</td>
											<td key={`total_actual_${role.id}`}>{prettyNum(totalActualHours)}</td>
										</>
									})}
								</tr>
							</tbody>
						</table>
					)} */}
				</>
			)}
		</div>
  )
}