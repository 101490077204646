

import "./IndividualProfitSharingTable.css"
import { useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { Person } from "../../../FirebaseModel/Person";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { useEffect, useState } from "react";
import { Project, projectHourlyRate } from "../../../FirebaseModel/Project";
import { Client } from "../../../FirebaseModel/Client";
import { BSPProject, MonthTotal } from "../../../FirebaseModel/MonthTotal";
import IndividualProfitSharingHeader from "../IndividualProfitSharingHeader/IndividualProfitSharingHeader";
import IndividualProfitSharingRow from "../IndividualProfitSharingRow/IndividualProfitSharingRow";
import IndividualProfitSharingTotal from "../IndividualProfitSharingTotal/IndividualProfitSharingTotal";
import DownloadableTable from "../../DownloadableTable/DownloadableTable";
interface IndividualProfitSharingTableProps {
	year: number,
	personID: string,
}


interface IndividualProfitSharingTableProject {
	clientName: string,
	projectNumber: string,
	projectName: string,
	role: string,
	hoursAvailable: number,
	hoursCredited: number,
	quarterlyBonus: number,
	hoursActual: number,
	hoursActualDifferentPerson:number,
	dateCompleted: string,
	notes: string,
}

interface IndividualProfitSharingTableQuarter {
	quarter: number,
	year: number,
	numProjects: number,
	totalBonus: number,
	totalHours: number,
	totalHoursCredited: number,
	projects: IndividualProfitSharingTableProject[],
	notes: string,
}


export default function IndividualProfitSharingTable(props: IndividualProfitSharingTableProps) {

	const organizationID = useSelector((state: { organizationID: string }) => state.organizationID)
	const organization = useSelector((state: { organization: Organization }) => state.organization)
	const roles = useSelector((state: { roles: Role[] }) => state.roles)
	const persons = useSelector((state: { persons: Person[] }) => state.persons)
	const clients = useSelector((state: { clients: Client[] }) => state.clients)
	const projects = useSelector((state: { projects: Project[] }) => state.projects)
	const actuals = useSelector((state: { actuals: ProjectRoleActualHours[] }) => state.actuals)

	let blankQuarter: IndividualProfitSharingTableQuarter = {
		quarter: 0,
		year: props.year,
		numProjects: 0,
		totalBonus: 0,
		totalHours: 0,
		totalHoursCredited: 0,
		projects: [],
		notes: "",
	}

	const person = persons.find(person => person.id == props.personID);

	let tempQ1 = { ...blankQuarter, quarter: 1 };
	let tempQ2 = { ...blankQuarter, quarter: 2 };
	let tempQ3 = { ...blankQuarter, quarter: 3 };
	let tempQ4 = { ...blankQuarter, quarter: 4 };

	const [q1, setQ1] = useState(tempQ1)
	const [q2, setQ2] = useState(tempQ2)
	const [q3, setQ3] = useState(tempQ3)
	const [q4, setQ4] = useState(tempQ4)


	const buildQuarters = () => {

		let soldStatuses:string[] = (organization.projectStatusCategories as any)["sold"] as string[];
		let completedStatuses:string[] = (organization.projectStatusCategories as any)["completed"] as string[];
		const filterProjects = (startMonth:number, endMonth:number) => {
			return projects.filter(project => {
				for(var i in project.statusHistory){
					let entry = project.statusHistory[i];
					let entryDate = new Date(entry.date);
					if(entryDate.getFullYear() == props.year && 
						entryDate.getMonth() >= startMonth && 
						entryDate.getMonth() < endMonth && 
						soldStatuses.includes(entry.status) && 
						completedStatuses.includes(entry.status)){
						return true;
					}
				}
				return false;
			})
		}
		let projectsClosedQ1 = filterProjects(0,3)
		let projectsClosedQ2 = filterProjects(3,6)
		let projectsClosedQ3 = filterProjects(6,9)
		let projectsClosedQ4 = filterProjects(9,12)

		const findDateCompleted = (project:Project) =>{
			for(var i in project.statusHistory){
				let entry = project.statusHistory[i];
				if(soldStatuses.includes(entry.status) && 
					completedStatuses.includes(entry.status)){
					return entry.date;
				}
			}
			return "";
		}

		const buildQuarter = (projectsClosed:Project[], quarter:IndividualProfitSharingTableQuarter) =>{
			projectsClosed.forEach(project => {
				let projectPersonActuals = actuals.filter(actual => actual.projectID == project.id && actual.personID == props.personID)
				let uniqueRoleIDs = projectPersonActuals.map(actual => actual.roleID).filter((value, index, self) => self.indexOf(value) === index)
				quarter.numProjects++;
				uniqueRoleIDs.forEach(roleID => {
					let tempBSPProject: IndividualProfitSharingTableProject = {
						projectNumber: project.projectNumber,
						projectName: project.projectName,
						role: roles.find(role => role.id == roleID)?.abbreviation as string,
						clientName: clients.find(client => client.id == project.clientID)?.name as string,
						hoursAvailable: project.plannedHours.find(plannedHours => plannedHours.roleID == roleID)?.hours as number,
						hoursCredited: 0,
						quarterlyBonus: 0,
						hoursActual: 0,
						hoursActualDifferentPerson: 0,
						dateCompleted: findDateCompleted(project),
						notes: "",
					}
					let projectRoleActuals = actuals.filter(actual => actual.projectID == project.id && actual.roleID == roleID)
					projectRoleActuals.forEach(actual => {
						if(actual.personID == props.personID){
							tempBSPProject.hoursActual += actual.calculated.totalHours;
						}else{
							tempBSPProject.hoursActualDifferentPerson += actual.calculated.totalHours;
							let otherPerson = persons.find(person => person.id == actual.personID);
							if(otherPerson){
								if(tempBSPProject.notes == ""){
									tempBSPProject.notes = "Hours shared with " + otherPerson.name;
								}else if(tempBSPProject.notes.indexOf(otherPerson.name) == -1){
									tempBSPProject.notes += ", " + otherPerson.name;
								}
							}
						}
					})

					let percentDoneByPerson = tempBSPProject.hoursActual / (tempBSPProject.hoursActual + tempBSPProject.hoursActualDifferentPerson);
					tempBSPProject.hoursCredited = percentDoneByPerson * tempBSPProject.hoursAvailable
					tempBSPProject.quarterlyBonus = tempBSPProject.hoursCredited * projectHourlyRate(project,organization,clients) * organization.profitSharingPercentage;
					
					quarter.totalBonus+=tempBSPProject.quarterlyBonus;
					quarter.totalHours+=tempBSPProject.hoursActual;
					quarter.totalHoursCredited+=tempBSPProject.hoursCredited;
					quarter.projects.push(tempBSPProject);
				})
			})
		}



		let tempQ1 = { ...blankQuarter, projects: [], quarter: 1 };
		let tempQ2 = { ...blankQuarter, projects: [], quarter: 2 };
		let tempQ3 = { ...blankQuarter, projects: [], quarter: 3 };
		let tempQ4 = { ...blankQuarter, projects: [], quarter: 4 };

		buildQuarter(projectsClosedQ1, tempQ1)
		buildQuarter(projectsClosedQ2, tempQ2)
		buildQuarter(projectsClosedQ3, tempQ3)
		buildQuarter(projectsClosedQ4, tempQ4)

		setQ1(tempQ1)
		setQ2(tempQ2)
		setQ3(tempQ3)
		setQ4(tempQ4)

	}


	useEffect(() => {
		buildQuarters()
	}, [props.personID, props.year, organization, roles, persons, clients, projects, actuals])



	return (
		<div className={`IndividualProfitSharingTableContainer`}>
			<DownloadableTable className={`IndividualProfitSharingTable`} fileName={`Individual Profit Sharing - ${person!.name}`}>
				<thead>
					<IndividualProfitSharingHeader></IndividualProfitSharingHeader>
				</thead>
				<tbody>
					{q1.projects.map((project, index) => {
						return <IndividualProfitSharingRow key={project.projectNumber + "_" + project.role} {...project}></IndividualProfitSharingRow>
					})}
					<IndividualProfitSharingTotal
						title={"Q1 Totals"}
						numProjects={q1.numProjects}
						totalHoursCredited={q1.totalHoursCredited}
						totalBonus={q1.totalBonus}
						totalHours={q1.totalHours}></IndividualProfitSharingTotal>
					{q2.projects.map((project, index) => {
						return <IndividualProfitSharingRow key={project.projectNumber + "_" + project.role} {...project}></IndividualProfitSharingRow>
					})}
					<IndividualProfitSharingTotal
						title={"Q2 Totals"}
						numProjects={q2.numProjects}
						totalHoursCredited={q2.totalHoursCredited}
						totalBonus={q2.totalBonus}
						totalHours={q2.totalHours}></IndividualProfitSharingTotal>
					{q3.projects.map((project, index) => {
						return <IndividualProfitSharingRow key={project.projectNumber + "_" + project.role} {...project}></IndividualProfitSharingRow>
					})}
					<IndividualProfitSharingTotal
						title={"Q3 Totals"}
						numProjects={q3.numProjects}
						totalHoursCredited={q3.totalHoursCredited}
						totalBonus={q3.totalBonus}
						totalHours={q3.totalHours}></IndividualProfitSharingTotal>
					{q4.projects.map((project, index) => {
						return <IndividualProfitSharingRow key={project.projectNumber + "_" + project.role} {...project}></IndividualProfitSharingRow>
					})}
					<IndividualProfitSharingTotal
						title={"Q4 Totals"}
						numProjects={q4.numProjects}
						totalHoursCredited={q4.totalHoursCredited}
						totalBonus={q4.totalBonus}
						totalHours={q4.totalHours}></IndividualProfitSharingTotal>

					<IndividualProfitSharingTotal
						title={`Annual Total (${props.year})`}
						numProjects={q4.numProjects + q3.numProjects + q2.numProjects + q1.numProjects}
						totalHoursCredited={q4.totalHoursCredited + q3.totalHoursCredited + q2.totalHoursCredited + q1.totalHoursCredited}
						totalBonus={q4.totalBonus + q3.totalBonus + q2.totalBonus + q1.totalBonus}
						totalHours={q4.totalHours + q3.totalHours + q2.totalHours + q1.totalHours}></IndividualProfitSharingTotal>

				</tbody>
			</DownloadableTable>
		</div>
	)
}