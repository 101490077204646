

import "./IndividualProfitSharingTotal.css"
import { useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { Person } from "../../../FirebaseModel/Person";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";
import { TimeOff } from "../../../FirebaseModel/TimeOff";
import { useEffect, useState } from "react";
import { Project } from "../../../FirebaseModel/Project";
import { Client } from "../../../FirebaseModel/Client";
import { MonthTotal } from "../../../FirebaseModel/MonthTotal";
import IndividualProfitSharingHeader from "../IndividualProfitSharingHeader/IndividualProfitSharingHeader";
import { prettyCurrency, prettyNum } from "../../../Utils/formatting";
interface IndividualProfitSharingTotalProps{
	title:string,
	numProjects:number,
	totalHoursCredited:number,
	totalBonus:number,
	totalHours:number,
}



export default function IndividualProfitSharingTotal(props:IndividualProfitSharingTotalProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const roles = useSelector((state:{roles:Role[]}) => state.roles)
	const persons = useSelector((state:{persons:Person[]}) => state.persons)
	const clients = useSelector((state:{clients:Client[]}) => state.clients)
	const projects = useSelector((state:{projects:Project[]}) => state.projects)
	const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)


  return (
			<tr className={`IndividualProfitSharingTotal`}>
				<td>{props.title}</td>
				<td>{props.numProjects}</td>
				<td></td>
				<td></td>
				<td></td>
				<td>{prettyNum(props.totalHoursCredited)}</td>
				<td>{prettyCurrency(props.totalBonus)}</td>
				<td>{prettyNum(props.totalHours)}</td>
				<td></td>
				<td></td>
			</tr>
  )
}