

import "./ProjectMessageBoardWorksheet.css"
import { useDispatch, useSelector } from "react-redux";
import { faGear, faPeopleGroup, faAddressCard, faHouse, faBarChart, faPieChart, faCalendar, faFileImport, faPlus, faEnvelope, faChevronDown, faArrowRight, faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Screens } from "../../Redux/Navigation";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { CircularProgress, Tooltip } from "@mui/material";
import { Project, ProjectBidWorksheet, ProjectRetrospective } from "../../FirebaseModel/Project";
import { forceDecimal, prettyCurrency, prettyNum, shortDate } from "../../Utils/formatting";
import { useEffect, useState } from "react";
import { ClientContact } from "../../FirebaseModel/ClientContact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { savePersonAttributeChange } from "../../Utils/SavePersonFunctions";
import { Person } from "../../FirebaseModel/Person";
import ProjectMessageBoard, { ProjectMessageBoardPerson } from "../../FirebaseModel/ProjectMessageBoard";
import { addDoc, collection } from "firebase/firestore";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import { generateKeyPair, rsaCryptoKeyToString, encryptData, encryptWithKey, decryptData, stringToRsaCryptoKey, decryptWithKey } from "../../Utils/Encryption";
const uuid = require('uuid');

interface ProjectMessageBoardWorksheetProps {
	project: Project;
	projectContacts: { clientContactID: string, primaryContact: boolean }[];
}

export default function ProjectMessageBoardWorksheet(props: ProjectMessageBoardWorksheetProps) {
	const currentScreen = useSelector((state: { screen: Screens }) => state.screen)

	const organization = useSelector((state: { organization: Organization }) => state.organization)
	const clientContacts = useSelector((state: { clientContacts: ClientContact[] }) => state.clientContacts)
	const persons = useSelector((state: { persons: Person[] }) => state.persons)
	const projectMessageBoards = useSelector((state: { projectMessageBoards: ProjectMessageBoard[] }) => state.projectMessageBoards)
	const roles = useSelector((state: { roles: Role[] }) => state.roles)
	const permissions = useSelector((state: { permissions: string[] }) => state.permissions)

	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen
	}, dispatch);

	const buttonStyle = "" +
		"background-color: #e72b7c;" +
		"color: white;" +
		"padding: 10px 20px;" +
		"border-radius: 3px;";


	const [expanded, setExpanded] = useState(true);

	const [activePersons, setActivePersons] = useState<Person[]>([]);
	useEffect(() => {
		setActivePersons(persons.filter((person) => person.active));
	}, [persons]);

	const [projectMessageBoard, setProjectMessageBoard] = useState<ProjectMessageBoard | null>(null);
	useEffect(() => {
		let projectMessageBoard = projectMessageBoards.find((projectMessageBoard) => projectMessageBoard.projectID == props.project.id);
		if (projectMessageBoard) {
			setProjectMessageBoard(projectMessageBoard);
		} else {
			setProjectMessageBoard(null);
		}
	}, [projectMessageBoards, props.project.id]);

	const [attendingClientContactIDs, setAttendingClientContactIDs] = useState<string[]>([]);
	const [attendingPersonIDs, setAttendingPersonIDs] = useState<string[]>([]);

	const [creatingMessageBoard, setCreatingMessageBoard] = useState(false);

	let completedStatuses = organization.projectStatusCategories["completed"]


	const createProjectMessageBoard = () => {

		if(attendingClientContactIDs.length == 0 && attendingPersonIDs.length == 0) {
			alert("Please select at least one person to invite to the message board");
			return;
		}
		if(attendingPersonIDs.length == 0){
			alert("Please select at least one internal team member to invite to the message board");
			return;
		}

		setCreatingMessageBoard(true);

		let invitedPeople: ProjectMessageBoardPerson[] = [];
		let invitedEmails: string[] = [];

		attendingClientContactIDs.forEach((clientContactID) => {
			let clientContact = clientContacts.find((clientContact) => clientContact.id == clientContactID);
			if (clientContact) {
				invitedPeople.push({
					id: uuid.v4(),
					internal: false,
					name: clientContact.name,
					email: clientContact.email,
					profilePicUID: ""
				})
			}
		});

		attendingPersonIDs.forEach((personID) => {
			let person = persons.find((person) => person.id == personID);
			if (person) {
				invitedPeople.push({
					id: uuid.v4(),
					internal: true,
					name: person.name,
					email: person.email,
					profilePicUID: ""
				})
			}
		});

		invitedEmails = invitedPeople.map((person) => person.email);

		let projectMessageBoard: ProjectMessageBoard = {
			projectID: props.project.id,
			people: invitedPeople,
			id: "",
			uid: uuid.v4(),
			archived: false,
			organizationID: organization.id,
			projectName: props.project.projectName,
			lastUpdated: (new Date()).getTime(),
			dateCreated: (new Date()).getTime(),
			invitedEmails: invitedEmails
		}

		addDoc(collection(sharedFirestore, "ProjectMessageBoard"), projectMessageBoard).then((docRef) => {
			projectMessageBoard.id = docRef.id;
			setProjectMessageBoard(projectMessageBoard);
			sendInvitationEmails(invitedEmails, docRef.id);
		})

	}

	const sendInvitationEmails = async (invitedEmails: string[], id: string) => {
		const messageboardURL = "https://messageboards.lightview.io/?s=project&pid=" + id;
		const emailBody = "You have been invited to the message board for the project: " + props.project.projectName + ". You can access the message board by clicking the link below. \n";
		await invitedEmails.forEach(async (email) => {
			const docData = {
				to: email,
				message: {
					subject: "Invitation: " + props.project.projectName + " - Project Message Board",
					text: emailBody + " " + messageboardURL,
					html: emailBody + "<br><br> <a style='" + buttonStyle + "' href='" + messageboardURL + "'>Messageboard</a>"
				}
			}
			console.log("sending message board invitation to " + email)
			const docref = await addDoc(collection(sharedFirestore, "messages"), docData);
		})


		setCreatingMessageBoard(false);
	}


	return (
		<div className={`ProjectMessageBoardWorksheet ${expanded ? "expanded" : ""}`}>
		<h1 onClick={() => {
			setExpanded(!expanded);
		}}>Project Message Board <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon></h1>
			{creatingMessageBoard ? (
				<CircularProgress></CircularProgress>
			) : (
				<>
					{projectMessageBoard ? (
						<>
							<a href={"https://messageboards.lightview.io/?s=project&pid=" + projectMessageBoard.id} className="buttonMessageBoard" target="__blank">Open Project Message Board</a>
							{/* <div className='encryptTest' onClick={async () => {
								let keyPair = await generateKeyPair();
								let privateKeyString = await rsaCryptoKeyToString(keyPair.privateKey);
								let publicKeyString = await rsaCryptoKeyToString(keyPair.publicKey);

								let symmetricKeyPassphrase = "asdfasdfasdfasdfassdf"

								let encryptedPrivateKeyString = await encryptData(privateKeyString, "Jeffs password");
								let encryptedSymmetricKeyPassphrase = await encryptWithKey(symmetricKeyPassphrase, keyPair.publicKey);

								let message = "this is my secret message";
								let encryptedMessage = await encryptData(message, symmetricKeyPassphrase);

								let decryptedPrivateKeyString = await decryptData(encryptedPrivateKeyString, "Jeffs password");
								let restoredPrivateKey = await stringToRsaCryptoKey(decryptedPrivateKeyString);

								let decryptedSymmetricKeyPassphrase = await decryptWithKey(encryptedSymmetricKeyPassphrase, restoredPrivateKey);

								let decryptedMessage = await decryptData(encryptedMessage, decryptedSymmetricKeyPassphrase);
								console.log("decryptedMessage", decryptedMessage);
							}}>test encrypt</div> */}
						</>
					) : (
						<>

							<h2>Which client contacts should be invited?</h2>
							<div className='attendees'>
								{props.projectContacts.map((projectContact) => {
									let clientContact = clientContacts.find((clientContact) => clientContact.id == projectContact.clientContactID);
									if (clientContact) {
										return (
											<div className='attendee' key={"attendee_" + clientContact.id}>
												<input type='checkbox' checked={attendingClientContactIDs.includes(clientContact.id)} onChange={(event) => {
													let newAttendingClientContactIDs = [...attendingClientContactIDs]
													if (event.target.checked) {
														newAttendingClientContactIDs.push(clientContact!.id);
													} else {
														newAttendingClientContactIDs = newAttendingClientContactIDs.filter((id) => id != clientContact!.id);
													}
													setAttendingClientContactIDs(newAttendingClientContactIDs);
												}}></input>
												{clientContact.name}
											</div>
										)
									}
								})}
							</div>

							<h2>Which internal team members should be invited?</h2>
							<div className='attendees'>
								{activePersons.map((person) => {
									return (
										<div className='attendee' key={"attendee_" + person.id}>
											<input type='checkbox' checked={attendingPersonIDs.includes(person.id)} onChange={(event) => {
												let newAttendingPersonIDs = [...attendingPersonIDs]
												if (event.target.checked) {
													newAttendingPersonIDs.push(person.id);
												} else {
													newAttendingPersonIDs = newAttendingPersonIDs.filter((id) => id != person.id);
												}
												setAttendingPersonIDs(newAttendingPersonIDs);
											}}></input>
											{person.name}
										</div>
									)
								})}
							</div>

							<div className="buttonCreateCommunicationProject" onClick={() => {
								createProjectMessageBoard();
							}}>Create Project Message Board</div>

						</>
					)}
				</>
			)}
		</div>
	)
}


