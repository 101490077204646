
import { useCSVReader } from 'react-papaparse';
import React, { useEffect, useState } from "react"
import { addDoc, collection, deleteDoc, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where } from "firebase/firestore";
import "./PopupImportProjects.css"
import { useDispatch, useSelector } from "react-redux";
import { Project, ProjectBidWorksheet, ProjectInvoice, ProjectTask } from "../../FirebaseModel/Project";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Client } from "../../FirebaseModel/Client";
import ClientDropdown from "../../Components/ClientDropdown/ClientDropdown";
import PersonDropdown from "../../Components/PersonDropdown/PersonDropdown";
import { prettyNum, forceNumeric, prettyCurrency, forceDecimal, shortDate } from "../../Utils/formatting";
import { sharedFirestore } from "../../Utils/SharedFirebase";
import DropdownButton from "../../Components/DropdownButton/DropdownButton";
import { saveClientAttributeChange } from "../../Utils/SaveClientFunctions";
import { useRef } from 'react';
import { ProjectRoleActualHours } from '../../FirebaseModel/ProjectRoleActualHours';
import { Person, PersonNonProjectTask } from '../../FirebaseModel/Person';
import { saveProjectAttributeChange } from '../../Utils/SaveProjectFunctions';
import { faFileDownload, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../Redux/ActionCreators';
import { UndoStackEntry, UndoStackEntryObjectType, UndoStackEntryType } from '../../Utils/UndoStackEntry';
import { stringToBase64 } from '../../Utils/StringToBase64';
import { cleanCSVString } from '../../Utils/CleanCSVString';
interface PopupImportProjectsProps {
	onDismiss: () => void;
}


export default function PopupImportProjects(props: PopupImportProjectsProps) {

	const progressRef = useRef<HTMLProgressElement>(null);
	const organization = useSelector((state: { organization: Organization }) => state.organization)
	const roles = useSelector((state: { roles: Role[] }) => state.roles)
	const clients = useSelector((state: { clients: Client[] }) => state.clients)
	const projects = useSelector((state: { projects: Project[] }) => state.projects)
	const actuals = useSelector((state: { actuals: ProjectRoleActualHours[] }) => state.actuals)
	const persons = useSelector((state: { persons: Person[] }) => state.persons)

	const { CSVReader } = useCSVReader();

	const [errorMessage, setErrorMessage] = useState<string>("");

	const [allRows, setAllRows] = useState<any[]>([]);


	const dispatch = useDispatch();
	const AC = bindActionCreators({
		submitUndoStackEntry: ActionCreators.submitUndoStackEntry
	}, dispatch);

	const downloadTemplateCSV = () =>{
		// generate a csv file with the appropriate headers and download it.

		let csvContent = "data:text/csv;charset=utf-8,";
		csvContent += "Client Name,Project Number,Project Name,Month Closed Won,Year Closed Won,$ SF Closed Won,Account Manager,Project Manager,Custom Hourly Rate(leave blank to use organization rate),Project Status,Project Status Date,Project Type,";
		roles.forEach(r => {
			if(r.isBillable){
				csvContent += r.abbreviation+",";
			}
		})
		csvContent += "Comments\n";

		let encodedUri = encodeURI(csvContent);
		let link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "project_import_template.csv");
		document.body.appendChild(link); // Required for FF

		link.click(); // This will download the data file named "my_data.csv".

		document.body.removeChild(link);
	}

	const downloadCurrentProjectsCSV = () =>{
		// generate a csv file with the appropriate headers and download it.

		let csvContent = "";
		csvContent += "Client Name,Project Number,Project Name,Month Closed Won,Year Closed Won,$ SF Closed Won,Account Manager,Project Manager,Custom Hourly Rate(leave blank to use organization rate),Project Status,Project Status Date,Project Type,";
		roles.forEach(r => {
			if(r.isBillable){
				csvContent += r.abbreviation+",";
			}
		})
		csvContent += "Comments\n";


		for(let p in projects){
			let project = projects[p];
			let clientName = clients.find(c => c.id == project.clientID)?.name ?? "";
			let projectNumber = project.projectNumber;
			let projectName = project.projectName;
			let monthClosedWon = project.monthClosedWon;
			let yearClosedWon = project.yearClosedWon;
			let dollarsSF = project.sfClosedWon;
			let accountManager = persons.find(p => p.id == project.accountManagerID)?.name ?? "";
			let projectManager = persons.find(p => p.id == project.projectManagerID)?.name ?? "";
			let customHourlyRate = project.useOverrideHourlyRate? project.overrideHourlyRate : "";
			let projectStatus = project.status;
			let projectStatusDate = project.statusHistory.length > 0? project.statusHistory[project.statusHistory.length-1].date : "";
			let plannedHours = project.plannedHours;
			let comments = project.comments;

			csvContent += cleanCSVString(clientName)+",";
			csvContent += cleanCSVString(projectNumber)+",";
			csvContent += cleanCSVString(projectName)+",";
			csvContent += monthClosedWon+",";
			csvContent += yearClosedWon+",";
			csvContent += dollarsSF+",";
			csvContent += cleanCSVString(accountManager)+",";
			csvContent += cleanCSVString(projectManager)+",";
			csvContent += customHourlyRate+",";
			csvContent += cleanCSVString(projectStatus)+",";
			csvContent += projectStatusDate+",";
			csvContent += cleanCSVString(project.projectType)+",";
			for(let r in roles){
				let role = roles[r];
				if(role.isBillable){
					let plannedHoursForRole = plannedHours.find(ph => ph.roleID == role.id);
					if(plannedHoursForRole){
						csvContent += plannedHoursForRole.hours+",";
					}else{
						csvContent += "0,";
					}
				}
			}
			csvContent += cleanCSVString(comments)+"\n";
		}

		// convert content to base64 string and download as data uri
		let base64 = stringToBase64(csvContent);

		let dataUri = "data:text/csv;base64,"+base64;
		let link = document.createElement("a");
		link.setAttribute("href", dataUri);
		link.setAttribute("download", "current_projects.csv");
		
		document.body.appendChild(link); // Required for FF

		link.click(); // This will download the data file named "my_data.csv".

		document.body.removeChild(link);

	}

	const getClientIDForName = async (name: string) => {
		if (name == "") {
			return "";
		}
		let client = clients.find(c => c.name.toLowerCase() == name.toLowerCase());
		if (client) {
			return client.id;
		}


		let docData = {
			organizationID: organization.id,
			name: name,
			overrideHourlyRate: organization.hourlyRate,
			useOverrideHourlyRate: false,
		}
		let docref = await addDoc(collection(sharedFirestore, "Client"), docData)

		client = { ...docData, id: docref.id } as Client
		let undoEntry: UndoStackEntry = {
			type: UndoStackEntryType.create,
			objectType: UndoStackEntryObjectType.client,
			objectID: docref.id,
			objectBeforeState: undefined,
			objectAfterState: { ...client },
			description: "Create Client"
		}
		AC.submitUndoStackEntry(undoEntry);

		return client.id;
	}

	const getPersonIDForName = async (name: string) => {
		if (name == "") {
			return "";
		}
		let person = persons.find(p => p.name.toLowerCase() == name.toLowerCase());
		if (person) {
			return person.id;
		}

		let docData = {
			organizationID: organization.id,
			name: name,
			active: true,
			email: "",
			startDate: "",
			endDate: "",
			birthday: "",
			permissions: [] as string[],
			roles: [] as { roleID: string, weight: number }[],
			nonProjectTasks: [] as PersonNonProjectTask[],
			assignedTaskOrder: [] as string[],
		}
		let docref = await addDoc(collection(sharedFirestore, "Person"), docData)
		let newPerson = { ...docData, id: docref.id } as Person;

		AC.submitUndoStackEntry({
			type: UndoStackEntryType.create,
			objectType: UndoStackEntryObjectType.person,
			objectID: newPerson.id,
			objectBeforeState: undefined,
			objectAfterState: { ...newPerson },
			description: 'Create Person'
		})
		persons.push(newPerson);
		return newPerson.id;
	}

	const validateFormat = (rows: any) => {


		// <li>Client Name</li>
		// <li>Project Number</li>
		// <li>Project Name</li>
		// <li>Month Closed Won</li>
		// <li>Year Closed Won</li>
		// <li>$ SF Closed Won</li>
		// <li>Account Manager</li>
		// <li>Project Manager</li>
		// <li>Custom Hourly Rate(leave blank to use organization rate)</li>
		// <li>Project Status</li>
		// <li>Project Status Date</li>

		if (rows.length < 2) {
			setErrorMessage("File must have at least 2 rows");
			return false;
		}

		let headerRow = rows[0];
		if (headerRow.length < 12) {
			setErrorMessage("File must have at least 12 columns");
			return false;
		}

		if (headerRow[0].toLowerCase() != "client name") {
			setErrorMessage("First column must be 'Client Name'");
			return false;
		}

		if (headerRow[1].toLowerCase() != "project number") {
			setErrorMessage("Second column must be 'Project Number'");
			return false;
		}

		let duplicates:{[key:string]:number} = {};
		let duplicatesFound = false;
		for(let i = 1; i < rows.length; i++){
			let row = rows[i];
			let projectNumber = row[1];
			if(duplicates[projectNumber] == null){
				duplicates[projectNumber] = 1;
			}else{
				duplicates[projectNumber]++;
				duplicatesFound = true;
			}
		}
		if(duplicatesFound){
			setErrorMessage("Duplicate project numbers found: "+Object.keys(duplicates).filter(key => duplicates[key] > 1).join(", "));
			return false;
		}

		if (headerRow[2].toLowerCase() != "project name") {
			setErrorMessage("3rd column must be 'Project Name'");
			return false;
		}

		if (headerRow[3].toLowerCase() != "month closed won") {
			setErrorMessage("4th column must be 'Month Closed Won'");
			return false;
		}

		if (headerRow[4].toLowerCase() != "year closed won") {
			setErrorMessage("5th column must be 'Year Closed Won'");
			return false;
		}
		if (headerRow[5].toLowerCase() != "$ sf closed won") {
			setErrorMessage("6th column must be '$ SF Closed Won'");
			return false;
		}
		if (headerRow[6].toLowerCase() != "account manager") {
			setErrorMessage("7th column must be 'Account Manager'");
			return false;
		}
		if (headerRow[7].toLowerCase() != "project manager") {
			setErrorMessage("8th column must be 'Project Manager'");
			return false;
		}
		if (headerRow[8].toLowerCase().indexOf("custom hourly rate") == -1) {
			setErrorMessage("9th column must be 'Custom Hourly Rate(leave blank to use organization rate)'");
			return false;
		}
		if (headerRow[9].toLowerCase() != "project status") {
			setErrorMessage("10th column must be 'Project Status'");
			return false;
		}
		if (headerRow[10].toLowerCase() != "project status date") {
			setErrorMessage("11th column must be 'Project Status Date'");
			return false;
		}
		if (headerRow[11].toLowerCase() != "project type") {
			setErrorMessage("12th column must be 'Project Type'");
			return false;
		}

		for (var i = 12; i < headerRow.length-1; i++) {
			let abbrev = headerRow[i];
			let found = false;
			for (var r = 0; r < roles.length; r++) {
				if (roles[r].abbreviation.toLowerCase() == abbrev.toLowerCase()) {
					found = true;
					break;
				}
			}
			if (!found) {
				setErrorMessage("Role abbreviation '" + abbrev + "' not found");
				return false;
			}
		}
		if (headerRow[headerRow.length-1].toLowerCase() != "comments") {
			setErrorMessage(headerRow.length+"th column must be 'Comments'");
			return false;
		}

		return true;
	}


	const processFile = async (rows: any) => {
		setAllRows(rows);
	}
	const startImport = async (rows: any) => {


		AC.submitUndoStackEntry({
			type: UndoStackEntryType.batchStart,
			objectType: UndoStackEntryObjectType.nonApplicable,
			objectID: "",
			objectBeforeState: undefined,
			objectAfterState: undefined,
			description: 'Import Projects'
		})

		setErrorMessage("");

		let dates: Date[] = [];
		let currentName = "";
		let currentProjectName = "";
		let currentPerson: any | null = null;
		let currentProjectNumber = "";
		let currentTask = "";
		let currentTaskID = "";

		let currentProject: Project | null = null;

		let firstDate: Date | null = null;
		let lastDate: Date | null = null;

		let modifiedProjects: Project[] = [];


		progressRef.current!.max = rows.length;
		progressRef.current!.style.display = "block";

		function startNextRow(i: number) {
			setTimeout(() => {
				processNextRow(i);
			}, 100);
		}

		//for(let i = 0; i < rows.length; i++){
		async function processNextRow(i: number) {
			if (i >= rows.length) {
				console.log("Done processing file");
				progressRef.current!.style.display = "none";
				setTimeout(() => {
					//   updateProjectsCalculatedActuals(modifiedProjects);

					AC.submitUndoStackEntry({
						type: UndoStackEntryType.batchEnd,
						objectType: UndoStackEntryObjectType.nonApplicable,
						objectID: "",
						objectBeforeState: undefined,
						objectAfterState: undefined,
						description: 'Import Projects'
					})
					alert("done");
				}, 100);
				return;
			}
			let headerRow = rows[0];

			let row = rows[i];
			progressRef.current!.value = i;
			console.log(`Processing row ${i} of ${rows.length}...`);
			startNextRow(i + 1);

			let clientName = row[0];
			let projectNumber = row[1];
			let projectName = row[2];
			let monthClosedWon = row[3];
			let yearClosedWon = row[4];
			let dollarsSF = row[5].replace("$", "").replace(/,/g, "");
			let accountManager = row[6];
			let projectManager = row[7];
			let customHourlyRate = row[8].replace("$", "").replace(/,/g, "");
			let projectStatus = row[9];
			let projectStatusDate = row[10];
			let projectType = row[11];
			let plannedHours = [] as { roleID: string, hours: number }[];
			let totalPlannedHours = 0;

			if(dollarsSF == ""){
				dollarsSF = "0";
			}

			for(let j in roles){
				let role = roles[j];
				if(role.isBillable){
					plannedHours.push({ roleID: role.id, hours: 0 }) 
				}
			}

			for (var j = 12; j < row.length-1; j++) {
				row[j] = row[j].replace("$", "").replace(/,/g, "")
			}

			for (var j = 12; j < row.length-1; j++) {
				let roleAbbrev = headerRow[j];
				let role = roles.find(role => role.abbreviation.toLowerCase() == roleAbbrev.toLowerCase());
				if (role != null) {
					let found = false;
					for (var r = 0; r < plannedHours.length; r++) {
						if (plannedHours[r].roleID == role.id) {
							found = true;
							plannedHours[r].hours = isNaN(parseFloat(row[j]?? 0))? 0 : parseFloat(row[j]?? 0);
							if(role.isBillable){
								totalPlannedHours += isNaN(parseFloat(row[j]?? 0))? 0 : parseFloat(row[j]?? 0);
							}
							break;
						}
					}
					if (!found) {
						plannedHours.push({
							roleID: role.id,
							hours: isNaN(parseFloat(row[j]?? 0))? 0 : parseFloat(row[j]?? 0)
						});
						if(role.isBillable){
							totalPlannedHours += isNaN(parseFloat(row[j]?? 0))? 0 : parseFloat(row[j]?? 0);
						}
					}
				}
			}

			let comments = row[row.length-1];

			let bidWorksheet: ProjectBidWorksheet = {
				roles: [],
				fixedCosts: [],
				discount: 0,
				bidNotes:"",
				calculated: {
					totalHours: 0,
					totalSOWPrice: 0,
					totalFixedCosts: 0,
					totalProductionBudget: 0,
					totalExtraMargin: 0,
				}
			}

			roles.forEach(role => { bidWorksheet.roles.push({ roleID: role.id, hours: 0, sowPrice: 0 }) });
			organization!.fixedCostItemsList.forEach(fixedCost => { bidWorksheet.fixedCosts.push({ fixedCostItem: fixedCost, cost: 0, sowPrice: 0 }) })

			for (var j = 12; j < row.length-1; j++) {
				let roleAbbrev = headerRow[j];
				let role = roles.find(role => role.abbreviation.toLowerCase() == roleAbbrev.toLowerCase());
				if (role != null) {
					let found = false;
					for (var k = 0; k < bidWorksheet.roles.length; k++) {
						if (bidWorksheet.roles[k].roleID == role.id) {
							bidWorksheet.roles[k].hours = isNaN(parseFloat(row[j]?? 0))? 0 : parseFloat(row[j]?? 0);
							found = true;
							break;
						}
					}
					if (!found) {
						bidWorksheet.roles.push({
							roleID: role.id,
							hours: isNaN(parseFloat(row[j]?? 0))? 0 : parseFloat(row[j]?? 0),
							sowPrice: 0
						});
					}
				}
			}

			let clientID = await getClientIDForName(clientName);

			let projectManagerID = await getPersonIDForName(projectManager);

			let accountManagerID = await getPersonIDForName(accountManager);

			let project = projects.find(project => project.projectNumber === projectNumber);
			if (project == null) {
				let statusHistory: { date: string, status: string }[] = [];
				if (projectStatus != "") {
					statusHistory.push({
						date: projectStatusDate!=""?shortDate(new Date(projectStatusDate)):shortDate(new Date()),
						status: projectStatus
					});
				}
				let newProject = {
					organizationID: organization.id,
					projectNumber: projectNumber,
					projectName: projectName,
					clientID: clientID,
					monthClosedWon: parseInt(monthClosedWon),
					yearClosedWon: parseInt(yearClosedWon),
					sfClosedWon: parseFloat(dollarsSF),
					accountManagerID: accountManagerID,
					projectManagerID: projectManagerID,
					isContractSigned:false,
					regionalSalesManagerID: "",
					overrideHourlyRate: customHourlyRate != "" ? parseFloat(customHourlyRate) : 0,
					useOverrideHourlyRate: customHourlyRate != "",
					status: projectStatus,
					statusHistory: statusHistory,
					calculated: {
						actualHours: 0,
						plannedHours: totalPlannedHours,
						latestActivity:"",
						currentPlusMinus:0,
						differenceActualToBilled:0,
						totalInvoiced:0,
						invoicesCollected:0,
						invoicesOutstanding:0,
					},
					plannedHours: plannedHours,
					projectType: projectType,
					projectRoleTasks : [] as {roleID:string, tasks:ProjectTask[]}[],
					roleIDs: [] as string[],
					departmentIDs: [] as string[],
					comments: comments,
					bidWorksheet: bidWorksheet,
          retrospective:{
            date:"",
            attendingClientContactIDs:[] as string[],
            whatWentWell:"",
            whatCouldHaveGoneBetter:"",
            whatWillMakeTheNextProjectBetter:"",
            clientWhatChallengesAreYouLookingToSolve:"",
            clientWhatUpcomingEventsAreYouPreparingFor:"",
            clientWhoElseInYourOrganizationCouldWeHelp:"",
            notes:"",
          },
					pmEstimatedPercentComplete:0,
					amPercentToInvoice:0,
					pmNotes:"",
					amNotes:"",
					adminNotes:"",
					invoices:[] as ProjectInvoice[],
				}

				let newProjectDoc = await addDoc(collection(sharedFirestore, "Project"), newProject);
				let proj = { id: newProjectDoc.id, ...newProject } as Project;

				AC.submitUndoStackEntry({
					type: UndoStackEntryType.create,
					objectType: UndoStackEntryObjectType.project,
					objectID: proj.id,
					objectBeforeState: undefined,
					objectAfterState: { ...proj },
					description: 'Create Project'
				})

				projects.push(proj);
			} else {
				let updatedProject = {
					...project
				}
				updatedProject.projectName = projectName
				updatedProject.clientID = clientID;
				updatedProject.monthClosedWon = parseInt(monthClosedWon);
				updatedProject.yearClosedWon = parseInt(yearClosedWon);
				updatedProject.sfClosedWon = parseFloat(dollarsSF);
				updatedProject.plannedHours = plannedHours;
				updatedProject.accountManagerID = accountManagerID;
				updatedProject.projectManagerID = projectManagerID;
				updatedProject.overrideHourlyRate = customHourlyRate != "" ? parseFloat(customHourlyRate.replace("$", "").replace(/,/g, "")) : 0;
				updatedProject.useOverrideHourlyRate = customHourlyRate != "";
				updatedProject.status = projectStatus;
				updatedProject.projectType = projectType;
				updatedProject.bidWorksheet = bidWorksheet;
				updatedProject.calculated = {
					... updatedProject.calculated,
					plannedHours: totalPlannedHours
				}
				updatedProject.comments = comments;

				updatedProject.statusHistory.forEach(status => {
					if(status.status == projectStatus){
						status.date = projectStatusDate!=""?shortDate(new Date(projectStatusDate)):shortDate(new Date());
					}
				})

				AC.submitUndoStackEntry({
					type: UndoStackEntryType.update,
					objectType: UndoStackEntryObjectType.project,
					objectID: project.id,
					objectBeforeState: { ...project },
					objectAfterState: { ...updatedProject },
					description: 'Update Project'
				})

				await updateDoc(doc(sharedFirestore, "Project", project.id), updatedProject);

			}

		}
		if (validateFormat(rows)) {
			startNextRow(1);
		}
	}


	return (
		<div className={`popup PopupImportProjects`}>
			<h1>Import Projects from CSV</h1>

			{errorMessage != "" && <div className="errorMessage">{errorMessage}</div>}

			<div className='instructions'>
				The CSV file must be formatted with these headers in this order:
				<ul>
					<li>Client Name</li>
					<li>Project Number</li>
					<li>Project Name</li>
					<li>Month Closed Won</li>
					<li>Year Closed Won</li>
					<li>$ SF Closed Won</li>
					<li>Account Manager</li>
					<li>Project Manager</li>
					<li>Custom Hourly Rate(leave blank to use organization rate)</li>
					<li>Project Status</li>
					<li>Project Status Date</li>
					<li>Project Type</li>
					<li>From this point on the header should be the abbreviation for a role, and the column corresponds to the planned hours for that role</li>
					<li>Comments</li>
				</ul>
			</div>

			<CSVReader
				onUploadAccepted={async (results: any) => {
					console.log('---------------------------');
					console.log(results);
					console.log('---------------------------');
					processFile(results.data);
				}}
			>
				{({
					getRootProps,
					acceptedFile,
					ProgressBar,
					getRemoveFileProps,
				}: any) => (
					<>
						<div className='csvReader'>
							<button type='button' {...getRootProps()} className={"browseFile"}>
								Browse file
							</button>
							<div className={"acceptedFile"}>
								{acceptedFile && acceptedFile.name}
							</div>
							<button {...getRemoveFileProps()} className={"remove"}>
								Remove
							</button>
						</div>
						{/* <ProgressBar  className={"progressBarBackgroundColor"} /> */}
					</>
				)}
			</CSVReader>
			{allRows.length > 0 && (
				<div className='buttonImport'
					onClick={() => {
						startImport(allRows)
						setAllRows([]);
					}}><FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon> Start Import</div>
			)}
			<progress ref={progressRef} style={{ display: 'none' }} className={"progressBarBackgroundColor"} />

			<div className='buttonTemplate'
				onClick={downloadTemplateCSV}>
				<FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon> Download Template
				</div>

			<div className='buttonTemplate'
				onClick={downloadCurrentProjectsCSV}>
				<FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon> Download Current Projects CSV
				</div>

			<div className="floatingButtons">
				<div className="buttonSave"
					onClick={() => {
						projects.forEach(project => {
							let actualsForProject = actuals.filter(actual => actual.projectID === project.id);
							let totalHours = actualsForProject.reduce((total, actual) => total + actual.calculated.totalHours, 0);
							if (project.calculated.actualHours != totalHours) {
								let newCalcualted = { ...project.calculated }
								if (newCalcualted.actualHours != totalHours) {
									newCalcualted.actualHours = totalHours;
									saveProjectAttributeChange(project, organization,clients, "calculated", newCalcualted, (entry: UndoStackEntry) => {
										AC.submitUndoStackEntry(entry);
									})
								}
							}
						})
						props.onDismiss();
					}}>Done</div>
			</div>
		</div>
	)
}