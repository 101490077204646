

import React, { useEffect, useState } from "react"
import "./ProjectInvoiceTable.css"
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import DraggableTableBody from "../../DraggableTableBody/DraggableTableBody";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { saveOrganizationAttributeChange } from "../../../Utils/SaveOrganizationFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { faBars, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { prettyCurrency, shortDate } from "../../../Utils/formatting";
import { Project, ProjectInvoice, ProjectTask, ProjectTaskScheduleBlock } from "../../../FirebaseModel/Project";
import ProjectInvoiceTableRow from "../ProjectInvoiceTableRow/ProjectInvoiceTableRow";
import { Client } from "../../../FirebaseModel/Client";
import { ProjectRoleActualHours } from "../../../FirebaseModel/ProjectRoleActualHours";

// import * as uuid from "uuid";
const uuid = require('uuid');

interface ProjectInvoiceTableProps{
	project:Project;
	projectInvoices:ProjectInvoice[];
	isReadOnly?:boolean;
	clientID:string;
	useOverrideHourlyRate:boolean;
	overrideHourlyRate: number;
	totalPlannedHoursCombined:number;
	pmEstimatedPercentComplete:number;
	amPercentToInvoice:number;
	totalProjectPrice:number;
	onProjectInvoicesChanged:(projectInvoices:ProjectInvoice[]) => void;
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function ProjectInvoiceTable(props:ProjectInvoiceTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const clients = useSelector((state:{clients:Client[]}) => state.clients)
  const actuals = useSelector((state:{actuals:ProjectRoleActualHours[]}) => state.actuals)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles},dispatch);
	
	const [saving, setSaving] = useState(false);

	const [differenceActualToBilled, setDifferenceActualToBilled] = useState(0);
	const [currentPlusMinus, setCurrentPlusMinus] = useState(0);
	const [totalInvoiced, setTotalInvoiced] = useState(0);
	const [totalPaid, setTotalPaid] = useState(0);
	const recalculateTotals = () =>{

		let actualHours = actuals.filter(actual => actual.projectID == props.project.id).reduce((total:number, actual:ProjectRoleActualHours) => total + actual.calculated.totalHours, 0);
		let totalInvoiced = props.projectInvoices.reduce((total:number, invoice:ProjectInvoice) => total + invoice.amount, 0);
		let totalPaid = props.projectInvoices.reduce((total:number, invoice:ProjectInvoice) => total + (invoice.datePaid === "" ? 0 : invoice.amount), 0);
		let totalOutstanding = totalInvoiced - totalPaid;

		let hourlyRate = organization.hourlyRate;
		let client = clients.find((client:Client) => client.id == props.clientID);
		if(client !=null && client.useOverrideHourlyRate){
			hourlyRate = client.overrideHourlyRate;
		}
		if(props.useOverrideHourlyRate){
			hourlyRate = props.overrideHourlyRate;
		}
		let budget = props.totalPlannedHoursCombined * hourlyRate;
		let currentPlusMinus = (props.pmEstimatedPercentComplete*budget) - (actualHours * hourlyRate);
	
		let differenceActualToBilled = totalInvoiced - (props.amPercentToInvoice*props.totalProjectPrice);
		setCurrentPlusMinus(currentPlusMinus);
		setDifferenceActualToBilled(differenceActualToBilled);
		setTotalInvoiced(totalInvoiced);
		setTotalPaid(totalPaid);

	}

	useEffect(() =>{
		recalculateTotals();
	},[props.projectInvoices, props.pmEstimatedPercentComplete, props.amPercentToInvoice, props.totalProjectPrice, props.totalPlannedHoursCombined, props.useOverrideHourlyRate, props.overrideHourlyRate, actuals]);

	const sortByDateInvoiced = (a:ProjectInvoice, b:ProjectInvoice) =>{
		if(a.dateInvoiced === "" && b.dateInvoiced === ""){
			return 0;
		}
		if(a.dateInvoiced === ""){
			return 1;
		}
		if(b.dateInvoiced === ""){
			return -1;
		}
		let dateA = new Date(a.dateInvoiced);
		let dateB = new Date(b.dateInvoiced);
		
		if(dateA < dateB){
			return -1;
		}
		if(dateA > dateB){
			return 1;
		}
		return 0;
	}
	const [sortedInvoices, setSortedInvoices] = useState<ProjectInvoice[]>([... props.projectInvoices].sort(sortByDateInvoiced));

	useEffect(() =>{
		setSortedInvoices([... props.projectInvoices].sort(sortByDateInvoiced));
	},[props.projectInvoices]);

  return (
		<>
    <table 
			className={`ProjectInvoiceTable`}>
			{saving == false && (
				<>
					<thead>
						<tr>
							<th>Invoice Number</th>
							<th>Date Invoiced</th>
							<th>Amount</th>
							<th>Date Paid</th>
						</tr>
					</thead>
					{sortedInvoices.map((invoice:ProjectInvoice) => {
						return <ProjectInvoiceTableRow
							key={invoice.id}
							projectInvoice={invoice}
							isReadOnly={props.isReadOnly}
							onDeleteInvoice={(invoiceID:string) =>{
								let newInvoices = props.projectInvoices.filter((invoice) => invoice.id !== invoiceID).sort(sortByDateInvoiced);
								setSortedInvoices(newInvoices);
								props.onProjectInvoicesChanged(newInvoices);
							}}
							onUpdateInvoice={(invoiceID:string, invoiceNumber:string, invoiceAmount:number, invoiceDate:string, invoicePaid:string) =>{
								let newInvoices = props.projectInvoices.map((invoice) =>{
									if(invoice.id === invoiceID){
										return {
											... invoice,
											invoiceNumber:invoiceNumber,
											amount:invoiceAmount,
											dateInvoiced:invoiceDate,
											datePaid:invoicePaid
										}
									}
									return invoice;
								}
								).sort(sortByDateInvoiced);
								setSortedInvoices(newInvoices);
								props.onProjectInvoicesChanged(newInvoices);
							}}
							></ProjectInvoiceTableRow>
					})}
					{!props.isReadOnly && (
						<tr>
							<td colSpan={4}>
								<div className="buttonAdd" onClick={() =>{
									const newInvoice:ProjectInvoice = {
										id:uuid.v4(),
										projectID:props.project.id,
										invoiceNumber:"",
										dateInvoiced:"",
										amount:0,
										datePaid:""
									}
									let newInvoices = [... props.projectInvoices, newInvoice].sort(sortByDateInvoiced);
									setSortedInvoices(newInvoices);
									props.onProjectInvoicesChanged(newInvoices);

								}}>
									<FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>Add Invoice
								</div>
							</td>
						</tr>
					)}
				</>
			)}
    </table>
    <table
			className={`ProjectInvoiceTable total`}>
			<tr>
				<th>Current +/- Based on % Complete</th>
				<th>Diff Actual to Billed</th>
				<th>Total Invoiced</th>
				<th>Invoices Collected</th>
				<th>Invoices Outstanding</th>
			</tr>
			<tr>
				<td>{prettyCurrency(currentPlusMinus)}</td>
				<td>{prettyCurrency(differenceActualToBilled)}</td>
				<td>{prettyCurrency(totalInvoiced)}</td>
				<td>{prettyCurrency(totalPaid)}</td>
				<td>{prettyCurrency(totalInvoiced - totalPaid)}</td>
			</tr>
    </table>
		</>
  )
}