import { collection, deleteDoc, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where } from "firebase/firestore";
import { Organization } from "../FirebaseModel/Organization";
import { Project } from "../FirebaseModel/Project";
import { Role } from "../FirebaseModel/Role";
import { saveOrganizationAttributeChange } from "./SaveOrganizationFunctions";
import { sharedFirestore } from "./SharedFirebase";
import { UndoStackEntry, UndoStackEntryType, UndoStackEntryObjectType } from "./UndoStackEntry";
import { Department } from "../FirebaseModel/Department";

export function saveDepartmentAttributeChange(department:Department,  attributeKey:string|string[], value:any|any[],undoEntryHandler:(entry:UndoStackEntry)=>void){


	const projectRef = doc(sharedFirestore, "Department", department.id);

	let updatedDepartment:{ [x: string]: any; } = {};
	for(var key in department){
		if(attributeKey instanceof Array){
			for(var key2 in attributeKey){
				if(key == attributeKey[key2]){
					updatedDepartment[key] = value[key2];
					break;
				}else{
					updatedDepartment[key] = (department as any)[key];
				}
			}
		}else{
			if(attributeKey){
				if(key == attributeKey){
					updatedDepartment[key] = value;
				}else{
					updatedDepartment[key] = (department as any)[key];
				}
			}else{
				updatedDepartment[key] = (department as any)[key];
			}
		}
	}
	let undoEntry:UndoStackEntry = {
		type: UndoStackEntryType.update,
		objectType: UndoStackEntryObjectType.department,
		objectID: department.id,
		objectBeforeState: {...department},
		objectAfterState: {...updatedDepartment},
		description: "Update Department"
	}
	undoEntryHandler(undoEntry);

	return updateDoc(projectRef,updatedDepartment)
}

function deleteDepartmentFromAllProjects(department: Department,undoEntryHandler:(entry:UndoStackEntry)=>void) {
	return new Promise((resolve:(result:string)=>void) =>{
		let q = query(collection(sharedFirestore, "Project"),where("departmentIDs", "array-contains", department.id));
		getDocs(q).then((querySnapshot: QuerySnapshot<DocumentData>) => {
			querySnapshot.forEach(async (doc) => {
				let project = doc.data();
				let newProject = {...project, id:doc.id} as any;
				newProject.roleIDs = project.departmentIDs.filter((departmentID:string) => departmentID != department.id);
				let totalPlannedHours = 0;
				for(var i = 0; i < newProject.plannedHours.length; i++){
					if(newProject.plannedHours[i].roleID == department.id){
						newProject.plannedHours.splice(i,1);
						i--;
					}else{
						totalPlannedHours += newProject.plannedHours[i].hours;
					}
				}
				newProject.calculated.plannedHours = totalPlannedHours;

				let undoEntry:UndoStackEntry = {
					type: UndoStackEntryType.update,
					objectType: UndoStackEntryObjectType.project,
					objectID: newProject.id,
					objectBeforeState: {...project},
					objectAfterState: {...newProject},
					description: "Update Project"
				}
				undoEntryHandler(undoEntry);
				await updateDoc(doc.ref,newProject);
			})
			resolve("success");
		})
	})
}

export function deleteDepartment(department:Department, organization:Organization,undoEntryHandler:(entry:UndoStackEntry)=>void){
	return new Promise((resolve,reject) =>{
		let undoEntry:UndoStackEntry = {
			type: UndoStackEntryType.batchStart,
			objectType: UndoStackEntryObjectType.role,
			objectID: department.id,
			objectBeforeState: {...department},
			objectAfterState: undefined,
			description: "Delete Department"
		}
		undoEntryHandler(undoEntry);
		deleteDepartmentFromAllProjects(department,undoEntryHandler).then(() =>{
			let newDepartmentIDOrder = [...organization.departmentIDOrder];
			if(newDepartmentIDOrder.indexOf(department.id) > -1){
				newDepartmentIDOrder.splice(newDepartmentIDOrder.indexOf(department.id),1);
			}
			saveOrganizationAttributeChange(organization,"departmentIDOrder",newDepartmentIDOrder,undoEntryHandler)
				.then(() =>{
					let undoEntry:UndoStackEntry = {
						type: UndoStackEntryType.delete,
						objectType: UndoStackEntryObjectType.department,
						objectID: department.id,
						objectBeforeState: {...department},
						objectAfterState: undefined,
						description: "Delete Department"
					}
					undoEntryHandler(undoEntry);
					deleteDoc(doc(sharedFirestore, "Department", department.id)).then(() =>{
						let undoEntry:UndoStackEntry = {
							type: UndoStackEntryType.batchEnd,
							objectType: UndoStackEntryObjectType.department,
							objectID: department.id,
							objectBeforeState: {...department},
							objectAfterState: undefined,
							description: "Delete Department"
						}
						undoEntryHandler(undoEntry);
						resolve("success");
					});		
				});	
		}).catch((error: any) =>{
			reject(error)
		});
	});
}
