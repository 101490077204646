
import React, { useEffect, useState } from "react"
import "./OrganizationScreenProjectTypesTable.css"
import { useDispatch, useSelector } from "react-redux";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import OrganizationScreenRolesRow, { OrganizationScreenRolesRowProps } from "../OrganizationScreenRolesRow/OrganizationScreenRolesRow";
import DraggableTableBody from "../../DraggableTableBody/DraggableTableBody";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { saveOrganizationAttributeChange } from "../../../Utils/SaveOrganizationFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { faBars, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface OrganizationScreenProjectTypesTableProps{
}

let optionsTableTimeout:NodeJS.Timeout|null=null;

export default function OrganizationScreenProjectTypesTable(props:OrganizationScreenProjectTypesTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const permissionsWrite = useSelector((state:{permissionsWrite:string[]}) => state.permissionsWrite)

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setRoles:ActionCreators.setRoles,
		submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

	const [projectTypeList, setProjectTypeList] = useState<string[]>([... organization.projectTypeList]);
	
	const [savingOptionsReorder, setSavingOptionsReorder] = useState(false);
	
	useEffect(() =>{
		setSavingOptionsReorder(false);
		setProjectTypeList([... organization.projectTypeList]);
	},[organization,organization.projectTypeList]);
	
	
  return (
    <table 
			className={`OrganizationScreenProjectTypesTable`}>
			{savingOptionsReorder == false && (
				<>
					<thead>
						<tr>
							<th>Project Type</th>
						</tr>
					</thead>
					<DraggableTableBody
						childClassName=""
						keyForIndex={(index:number) =>{
							return `${projectTypeList[index]}`;
						}}
						orderedItems={organization.projectTypeList}
						onOrderUpdated={(newOrder:string[]) =>{

						}}
						onOrderUpdateEnded={(newOrder:string[]) =>{
							setProjectTypeList(newOrder);
							saveOrganizationAttributeChange(organization,"projectTypeList",newOrder,(entry) =>{
								AC.submitUndoStackEntry(entry);
							});
						}}>
						{organization.projectTypeList.map((option:string, index) =>{
							return <>
								{permissionsWrite.includes("Organization") ? (
									<tr key={`${option}`}>
										<td><FontAwesomeIcon icon={faBars} style={{pointerEvents:"none", fontSize:"50%"}}></FontAwesomeIcon></td>
										<td>
											<input type='text' onChange={(e) =>{
												const newOptions = [...organization.projectTypeList];
												newOptions[index] = e.target.value;
												if(optionsTableTimeout != null){
													clearTimeout(optionsTableTimeout);
													optionsTableTimeout = null;
												}
												optionsTableTimeout = setTimeout(() =>{
													setProjectTypeList(newOptions);
													saveOrganizationAttributeChange(organization,"projectTypeList",newOptions,(entry) =>{
														AC.submitUndoStackEntry(entry);
													});
												},1000);
											}} defaultValue={projectTypeList[index]}></input>
										</td>
										<div className="buttonDelete"
											onClick={() =>{
												if(window.confirm("Are you sure you'd like to delete this option?")){
													const newOptions = [...organization.projectTypeList];
													newOptions.splice(index,1)
													if(optionsTableTimeout != null){
														clearTimeout(optionsTableTimeout);
														optionsTableTimeout = null;
													}
													setSavingOptionsReorder(true);
													optionsTableTimeout = setTimeout(() =>{
														setProjectTypeList(newOptions);
														saveOrganizationAttributeChange(organization,"projectTypeList",newOptions,(entry) =>{
															AC.submitUndoStackEntry(entry);
														});
													},100);
												}
											}}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
									</tr>
								):(
									<tr key={`${option}_${index}`}>
											<td></td>
											<td>
												{projectTypeList[index]}
											</td>
										</tr>
								)}
							</>
						})}
					</DraggableTableBody>
				</>
			)}
    </table>
  )
}