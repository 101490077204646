
import "./ProjectRetrospectiveWorksheet.css"
import { useDispatch, useSelector } from "react-redux";
import { faGear, faPeopleGroup, faAddressCard, faHouse, faBarChart, faPieChart, faCalendar, faFileImport, faPlus, faEnvelope, faChevronDown, faArrowRight, faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Organization } from "../../FirebaseModel/Organization";
import { Role } from "../../FirebaseModel/Role";
import { Screens } from "../../Redux/Navigation";
import { bindActionCreators } from "redux";
import ActionCreators from "../../Redux/ActionCreators";
import { Tooltip } from "@mui/material";
import { Project, ProjectBidWorksheet, ProjectRetrospective } from "../../FirebaseModel/Project";
import { forceDecimal, prettyCurrency, prettyNum, shortDate } from "../../Utils/formatting";
import { useEffect, useState } from "react";
import { ClientContact } from "../../FirebaseModel/ClientContact";
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { savePersonAttributeChange } from "../../Utils/SavePersonFunctions";

interface ProjectRetrospectiveWorksheetProps{
	projectStatus:string,
	projectContacts:{clientContactID:string, primaryContact:boolean}[];
	retrospective:ProjectRetrospective
	onRetrospectiveChange:(retrospective:ProjectRetrospective) => void,
	onSendSurveyClicked:() => void
}

export default function ProjectRetrospectiveWorksheet(props:ProjectRetrospectiveWorksheetProps){
  const currentScreen = useSelector((state:{screen:Screens}) => state.screen)

  const organization = useSelector((state:{organization:Organization}) => state.organization)
  const clientContacts = useSelector((state:{clientContacts:ClientContact[]}) => state.clientContacts)
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const permissions = useSelector((state:{permissions:string[]}) => state.permissions)
	
  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setScreen:ActionCreators.setScreen},dispatch);


	const [expanded, setExpanded] = useState(true);


  let completedStatuses = organization.projectStatusCategories["completed"]


  return (
		<div className={`ProjectRetrospective ${(completedStatuses.includes(props.projectStatus))?"closed":""} ${expanded?"expanded":""}`}>
			<h1 onClick={() =>{
				setExpanded(!expanded);
			}}>Project Retrospective <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon></h1>

			<h2>Date of Retrospective</h2>
        <DatePicker 
          selected={props.retrospective.date!=""?(new Date(props.retrospective.date)):null}
          onChange={(date:Date) =>{
            if(date){
							let dateString = shortDate(date);//`${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
							let newRetrospective = {...props.retrospective}
							newRetrospective.date = dateString;
							props.onRetrospectiveChange(newRetrospective);

            }else{
							let newRetrospective = {...props.retrospective}
							newRetrospective.date = "";
							props.onRetrospectiveChange(newRetrospective);
						}
          }}></DatePicker>


			<h2>Who Attended?</h2>
			<div className='attendees'>
				{props.projectContacts.map((projectContact) =>{
					let clientContact = clientContacts.find((clientContact) => clientContact.id == projectContact.clientContactID);
					if(clientContact){
						return (
							<div className='attendee' key={"attendee_"+clientContact.id}>
								<input type='checkbox' checked={props.retrospective.attendingClientContactIDs.includes(clientContact.id)} onChange={(event) =>{
									let newRetrospective = {...props.retrospective}
									if(event.target.checked){
										newRetrospective.attendingClientContactIDs.push(clientContact!.id);
									}else{
										newRetrospective.attendingClientContactIDs = newRetrospective.attendingClientContactIDs.filter((id) => id != clientContact!.id);
									}
									props.onRetrospectiveChange(newRetrospective);
								}}></input>
								{clientContact.name}
							</div>
						)
					}
				})}
			</div>

			<h2>What Went Well?</h2>
			<textarea
				defaultValue={props.retrospective.whatWentWell}
				onChange={(event) => {
					let newRetrospective = {...props.retrospective}
					newRetrospective.whatWentWell = event.target.value;
					props.onRetrospectiveChange(newRetrospective);
				}}></textarea>

			<h2>What Could Have Gone Better?</h2>
			<textarea
				defaultValue={props.retrospective.whatCouldHaveGoneBetter}
				onChange={(event) => {
					let newRetrospective = {...props.retrospective}
					newRetrospective.whatCouldHaveGoneBetter = event.target.value;
					props.onRetrospectiveChange(newRetrospective);
				}}></textarea>

			<h2>What Will Make the Next Project Better?</h2>
			<textarea
				defaultValue={props.retrospective.whatWillMakeTheNextProjectBetter}
				onChange={(event) => {
					let newRetrospective = {...props.retrospective}
					newRetrospective.whatWillMakeTheNextProjectBetter = event.target.value;
					props.onRetrospectiveChange(newRetrospective);
				}}></textarea>

			<div className="clientArea">
				<h2>Client's Perspective</h2>

				<h3>What Challenges Are You Looking To Solve?</h3>
				<textarea
					defaultValue={props.retrospective.clientWhatChallengesAreYouLookingToSolve}
					onChange={(event) => {
						let newRetrospective = {...props.retrospective}
						newRetrospective.clientWhatChallengesAreYouLookingToSolve = event.target.value;
						props.onRetrospectiveChange(newRetrospective);
					}}></textarea>
				
				<h3>What Upcoming Challenges Are You Preparing For?</h3>
				<textarea
					defaultValue={props.retrospective.clientWhatUpcomingEventsAreYouPreparingFor}
					onChange={(event) => {
						let newRetrospective = {...props.retrospective}
						newRetrospective.clientWhatUpcomingEventsAreYouPreparingFor = event.target.value;
						props.onRetrospectiveChange(newRetrospective);
					}}></textarea>
				
				<h3>Who Else In Your Organization Could We Help?</h3>
				<textarea
					defaultValue={props.retrospective.clientWhoElseInYourOrganizationCouldWeHelp}
					onChange={(event) => {
						let newRetrospective = {...props.retrospective}
						newRetrospective.clientWhoElseInYourOrganizationCouldWeHelp = event.target.value;
						props.onRetrospectiveChange(newRetrospective);
					}}></textarea>

					
			</div>

			<h2>Notes:</h2>
			<textarea
				defaultValue={props.retrospective.notes}
				onChange={(event) => {
					let newRetrospective = {...props.retrospective}
					newRetrospective.notes = event.target.value;
					props.onRetrospectiveChange(newRetrospective);
				}}></textarea>

			<h2>Project Survey</h2>
			<div className='buttonSendSurvey' onClick={() =>{
				props.onSendSurveyClicked();
			}}><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> Send Survey</div>

		</div>
  )
}


