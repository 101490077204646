import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./MonthlyBSPInput.css"
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { Role } from "../../../FirebaseModel/Role";
import { forceDecimal, forceNumeric, forcePercentage, prettyCurrency, prettyNum } from "../../../Utils/formatting";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { saveProjectAttributeChange, saveProjectPlannedHoursChange } from "../../../Utils/SaveProjectFunctions";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../Redux/ActionCreators";
import { UndoStackEntry } from "../../../Utils/UndoStackEntry";
import { MonthTotal } from "../../../FirebaseModel/MonthTotal";
import { saveMonthTotalAttributeChange } from "../../../Utils/SaveMonthTotalFunctions";
interface MonthlyBSPInputProps{
	className?:string,
	monthTotal:MonthTotal,
	projectID:string,	
	attributeKey:string,
}

export default function MonthlyBSPInput(props:MonthlyBSPInputProps){

  const dispatch = useDispatch();
  const AC = bindActionCreators({
    submitUndoStackEntry:ActionCreators.submitUndoStackEntry},dispatch);

  const organization = useSelector((state:{organization:Organization}) => state.organization)

	const [defaultValue, setDefaultValue] = useState("");

	useEffect(() =>{
		let bspProject = props.monthTotal.bspProjects.find(p => p.projectID == props.projectID);
		if(bspProject){
			let value = (bspProject as any)[props.attributeKey] as number;
			setDefaultValue(Math.round(value*100)+"%")
		}
		
	},[props.monthTotal,props.attributeKey])

  return (
    <input className={`MonthlyBSPInput ${props.className??""} ${props.attributeKey??""}`}
			onBlur={(event) =>{

				if(event.target.value ==""){
					event.target.value = "0";
				}

				let value = parseInt(event.target.value.replace("%","").replace("$","").replace(/,/g,""))/100;

				event.target.value = Math.round(value*100)+"%";

				let newBSPProjects = [...props.monthTotal.bspProjects];
				let bspProject = newBSPProjects.find(p => p.projectID == props.projectID);
				if(bspProject){
					(bspProject as any)[props.attributeKey] = value;
				}

				saveMonthTotalAttributeChange(props.monthTotal,"bspProjects",newBSPProjects,(entry:UndoStackEntry) =>{
					AC.submitUndoStackEntry(entry);
				});

			}}
			onFocus={(event)=>{
				let input = event.target as HTMLInputElement
				input.setSelectionRange(0,input.value.length)
			}}
			onKeyPress={(event) =>{
				forcePercentage(event);
			}}
			defaultValue={defaultValue}>
    </input>
  )
}