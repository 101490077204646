


import React, { useEffect, useState } from "react"
import { collection, doc, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, updateDoc, where} from "firebase/firestore";
import "./ProjectTasksRoleSection.css"
import { useRef } from "react";
import { useSelector } from "react-redux";
import { Role } from "../../../FirebaseModel/Role";
import { Project, ProjectTask } from "../../../FirebaseModel/Project";
import ProjectTasksTaskRow from "../ProjectTasksTaskRow/ProjectTasksTaskRow";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface ProjectTasksRoleSectionProps{
	projectRoleTasks:{
		roleID:string,
		tasks:ProjectTask[]
	}[];
	plannedHours: { [roleID: string]: number; };
	projectID:string,
	roleID:string,
	className?:string,
	isReadOnly?:boolean,
	onAddTask:(roleID:string) => void
	onDeleteTask:(taskID:string) => void
	onUpdateTask:(taskID:string, taskName:string, plannedHours:number, percentComplete:number, persons:string[],dueDate:string) => void
}

export default function ProjectTasksRoleSection(props:ProjectTasksRoleSectionProps){
  const roles = useSelector((state:{roles:Role[]}) => state.roles)
  const projects = useSelector((state:{projects:Project[]}) => state.projects)

	let role = roles.find((role:Role) => role.id === props.roleID);

	const [roleName, setRoleName] = useState<string>(role?.name??"");
	const [roleAssignedHours, setRoleAssignedHours] = useState<number>(0);
	const [rolePercentComplete, setRolePercentComplete] = useState<number>(0);
	const [roleTasks, setRoleTasks] = useState<ProjectTask[]>([]);

	const [totalHoursForRole, setTotalHoursForRole] = useState<number>(0);
	useEffect(() => {
		let project = projects.find((project:Project) => project.id === props.projectID);
		if(project){
			if(project?.isChangeOrder){
				project = projects.find((project:Project) => (project.isChangeOrder == false  && project.projectNumber === project?.changeOrderParentProjectNumber));
	
			}
			let totalHoursForRole = project!.plannedHours.find((plannedHours) => plannedHours.roleID === props.roleID)?.hours??0;
			projects.filter((project2:Project) => project2.changeOrderParentProjectNumber === project!.projectNumber).forEach((project2:Project) => {
				if(project2.isChangeOrder){
					totalHoursForRole += project2.plannedHours.find((plannedHours) => plannedHours.roleID === props.roleID)?.hours??0;
				}
			});
			setTotalHoursForRole(totalHoursForRole);
		}
	}, [projects, props.projectID, props.roleID]);

	useEffect(() => {
		let role = roles.find((role:Role) => role.id === props.roleID);
		setRoleName(role?.name??"");
		let filteredTasks = props.projectRoleTasks.find((projectRoleTask) => projectRoleTask.roleID === props.roleID)?.tasks??[];
		setRoleTasks(filteredTasks);
		let totalHours = 0;
		let completedHours = 0;
		filteredTasks.forEach((task) => {
			totalHours += task.plannedHours;
			completedHours += task.plannedHours * task.percentComplete;
		})
		setRoleAssignedHours(totalHours);
		setRolePercentComplete(totalHours === 0 ? 0 : completedHours / totalHours);
	}, [props.projectRoleTasks, props.roleID, roles])

  return (
    <tbody className={`ProjectTasksRoleSection ${props.className??""}`}>
			<tr className="roleRow">
				<td>{roleName}</td>
				<td></td>
				<td>{roleAssignedHours} / {totalHoursForRole}</td>
				<td>{Math.round(rolePercentComplete*100)}%</td>
				<td></td>
				<td></td>
			</tr>
			{roleTasks.map((task) => {
				return (
					<ProjectTasksTaskRow 
						key={task.id}
						projectID={props.projectID}
						projectRoleTasks={props.projectRoleTasks} 
						isReadOnly={props.isReadOnly}
						roleID={props.roleID} 
						taskID={task.id} 
						onDeleteTask={(taskID: string) =>{
							props.onDeleteTask(taskID);
						} } onUpdateTask={(taskID: string, taskName: string, plannedHours: number,percentComplete: number, persons: string[],dueDate) =>{
							props.onUpdateTask(taskID, taskName, plannedHours, percentComplete, persons,dueDate);
						} }></ProjectTasksTaskRow>
				)
			})}
			{!props.isReadOnly && (
				<tr>
					<td></td>
					<td>
						<div className="buttonAdd" onClick={() =>{
								props.onAddTask(props.roleID)
						}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Task</div>
					</td>
					<td colSpan={4}></td>
				</tr>
			)}
		</tbody>
  )
}