import { shortDate } from "../Utils/formatting";
import { Client } from "./Client";
import { Organization } from "./Organization";


export interface Project{
	id:string;
  organizationID:string;
  monthClosedWon:number;
  yearClosedWon:number;
	sfClosedWon:number;
  clientID:string;
  projectName:string;
  projectNumber:string;
  accountManagerID:string;
	projectManagerID:string;
	regionalSalesManagerID:string;
  overrideHourlyRate:number;
  useOverrideHourlyRate:boolean;
	isContractSigned:boolean;
	pmEstimatedPercentComplete:number;
	amPercentToInvoice:number;
	projectType:string;
	pmNotes:string;
	amNotes:string;
	adminNotes:string;
	invoices:ProjectInvoice[],
	status:string;
	statusHistory:{
		status:string,
		date:string
	}[],
	projectClientContacts:{
		clientContactID:string,
		primaryContact:boolean
	}[],
	calculated:{
		actualHours:number,
		plannedHours:number,
		latestActivity:string,
		currentPlusMinus:number;
		differenceActualToBilled:number,
		totalInvoiced:number,
		invoicesCollected:number,
		invoicesOutstanding:number,
	},
	plannedHours:{
		roleID:string,
		hours:number
	}[],
	projectRoleTasks:{
		roleID:string,
		tasks:ProjectTask[]
	}[],
	fixedCosts:number,
	extraMargin:number,
	roleIDs:string[],
	departmentIDs:string[],
	comments:string,
	bidWorksheet:ProjectBidWorksheet,
  retrospective:ProjectRetrospective,
	isChangeOrder:boolean,
	changeOrderParentProjectNumber:string,
}

export interface ProjectTask{
	id:string;
	name:string;
	projectID:string;
	roleID:string;
	plannedHours:number;
	percentComplete:number;
	assignedPersonIDs:string[];
	scheduleBlocks:ProjectTaskScheduleBlock[];
	dueDate:string;
}

export interface ProjectTaskScheduleBlock{
	id:string;
	projectID:string;
	roleID:string;
	taskID:string;
	personID:string;
	startDate:string;
	endDate:string;
	dailyPlannedHours:number[];
}


export interface ProjectBidWorksheet{
	roles:{roleID:string, hours:number, sowPrice:number}[],
	fixedCosts:{fixedCostItem:string, cost:number, sowPrice:number}[],
	discount:number,
	bidNotes:string,
	calculated:{
		totalHours:number,
		totalSOWPrice:number,
		totalFixedCosts:number,
		totalExtraMargin:number,
		totalProductionBudget:number,
	}
}

export interface ProjectRetrospective{
  date:string,
  attendingClientContactIDs:string[],
  whatWentWell:string,
  whatCouldHaveGoneBetter:string,
  whatWillMakeTheNextProjectBetter:string,
  clientWhatChallengesAreYouLookingToSolve:string,
  clientWhatUpcomingEventsAreYouPreparingFor:string,
  clientWhoElseInYourOrganizationCouldWeHelp:string,
  notes:string,
}

export interface ProjectInvoice{
	id:string;
	projectID:string;
	invoiceNumber:string;
	dateInvoiced: string;
	amount: number;
	datePaid: string;
}

export function projectHourlyRate(project:Project, organization:Organization, clients:Client[]){
	let client = clients.find(c => c.id == project.clientID);
	let hourlyRate = organization.hourlyRate;
	if(client && client.useOverrideHourlyRate){
		hourlyRate = client.overrideHourlyRate;
	}
	if(project.useOverrideHourlyRate){
		hourlyRate = project.overrideHourlyRate;
	}

	return hourlyRate;
}


export function findScheduleBlockForTaskDate(project:Project, personID:string, roleID:string, task:ProjectTask, date:Date){
	let scheduleBlock = null;
	let roleTasks = project.projectRoleTasks.find(r => r.roleID == roleID);
	if(roleTasks){
		if(task){
			scheduleBlock = task.scheduleBlocks.find(sb => sb.personID == personID && new Date(sb.startDate) <= date && new Date(sb.endDate) >= date);
		}
	}
	return scheduleBlock;
}
export function findScheduleBlockForDate(project:Project, personID:string, roleID:string, date:Date){
	let scheduleBlock = null;
	let roleTasks = project.projectRoleTasks.find(r => r.roleID == roleID);
	if(roleTasks){
		let task = roleTasks.tasks.find(t => t.scheduleBlocks.find(sb => sb.personID == personID && new Date(sb.startDate) <= date && new Date(sb.endDate) >= date));
		if(task){
			scheduleBlock = task.scheduleBlocks.find(sb => sb.personID == personID && new Date(sb.startDate) <= date && new Date(sb.endDate) >= date);
		}
	}
	return scheduleBlock;
}

export function findProjectTasksFromDueDate(project:Project,roleID:string, date:Date){
	let tasks:ProjectTask[] = [];
	let roleTasks = project.projectRoleTasks.find(r => r.roleID == roleID);
	if(roleTasks){
		tasks = roleTasks.tasks.filter(t => t.dueDate == shortDate(date));
	}
	return tasks;
}