// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { browserLocalPersistence, getAuth, indexedDBLocalPersistence, initializeAuth, signInWithEmailAndPassword } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD8MnCtBofs2vkR7qvAxrck5cPnp1Yh3o0",
  authDomain: "infuse-profitability-light.firebaseapp.com",
  projectId: "infuse-profitability-light",
  storageBucket: "infuse-profitability-light.appspot.com",
  messagingSenderId: "355666971172",
  appId: "1:355666971172:web:7c8ec6e84f8869b6d5a6b1",
  measurementId: "G-YEDBY1E472"
};

// Initialize Firebase
export const sharedFirebaseApp = initializeApp(firebaseConfig);
export const sharedFirestore = getFirestore(sharedFirebaseApp);
export const sharedGoogleAnalytics = getAnalytics(sharedFirebaseApp);
export const sharedFirebaseAuth = initializeAuth(sharedFirebaseApp, {
  persistence: [browserLocalPersistence],
});