

import React, { ReactNode, useEffect, useState } from "react"
import { collection, DocumentData, getDocs, onSnapshot, query, QuerySnapshot, where} from "firebase/firestore";
import "./ClientsContactTable.css"
import { useSelector } from "react-redux";
import { Project } from "../../../FirebaseModel/Project";
import { Organization } from "../../../FirebaseModel/Organization";
import { sharedFirestore } from "../../../Utils/SharedFirebase";
import { Role } from "../../../FirebaseModel/Role";
import { Person } from "../../../FirebaseModel/Person";
import OrganizationScreenRolesInput from "../../OrganizationScreen/OrganizationScreenRolesInput/OrganizationScreenRolesInput";
import { saveRoleAttributeChange } from "../../../Utils/SaveRoleFunctions";
import { Client } from "../../../FirebaseModel/Client";
import ClientsScreenClientsInput from "../ClientsScreenClientsInput/ClientsScreenClientsInput";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientContact } from "../../../FirebaseModel/ClientContact";
import ClientContactTableRow from "../ClientContactTableRow/ClientContactTableRow";
interface ClientsContactTableProps{
	clientContacts:ClientContact[];
	onClientContactsChange:(clientContacts:ClientContact[]) => void;
}

export default function ClientsContactTable(props:ClientsContactTableProps){

  const organizationID = useSelector((state:{organizationID:string}) => state.organizationID)
  const organization = useSelector((state:{organization:Organization}) => state.organization)
	const allClients = useSelector((state:{clients:Client[]}) => state.clients)
	const clientContacts = useSelector((state:{clientContacts:ClientContact[]}) => state.clientContacts)

	const [contacts, setContacts] = useState<ClientContact[]>(props.clientContacts);
	const [updatingContacts, setUpdatingContacts] = useState<boolean>(false);

	useEffect(() =>{
		setContacts(props.clientContacts);
	},[props.clientContacts]);

	useEffect(() =>{
		if(updatingContacts == false){
			return;
		}
		setTimeout(() =>{
			setUpdatingContacts(false)
		},100)
	},[updatingContacts]);

  return (
    <table className={`ClientsContactTable`}>
			{(updatingContacts == false && contacts.length>0) && (
				<>
					<thead>
						<tr>
							<th>Name</th>
							<th>Title</th>
							<th>Email</th>
							<th>Phone</th>
						</tr>
					</thead>
					<tbody>
						{contacts.map((contact:ClientContact, index) =>{
							return <ClientContactTableRow 
								clientContact={contact} 
								onClientContactChange={(clientContact: ClientContact) => {
									let newContacts = [...contacts];
									newContacts[index] = {...clientContact};
									props.onClientContactsChange(newContacts);
								} } onClientContactDelete={(clientContact: ClientContact) => {
									let newContacts = [...contacts];
									newContacts.splice(index,1);
									props.onClientContactsChange(newContacts);
								} }></ClientContactTableRow>
						})}
					</tbody>
				</>
			)}
    </table>
  )
}